import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  ISO8601Date: any;
  ISO8601DateTime: any;
  JSON: any;
  Upload: any;
};

/** Autogenerated return type of AcceptInviteMutation. */
export type AcceptInviteMutationPayload = {
  __typename?: "AcceptInviteMutationPayload";
  errors: Array<UserError>;
  result?: Maybe<User>;
};

/** Finance account object */
export type Account = Destroyable &
  Discardable & {
    __typename?: "Account";
    /** Account number */
    accountNumber?: Maybe<Scalars["String"]>;
    /** Account balance */
    balance: Scalars["Float"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Account default currency */
    currency: Currency;
    /** Currency code */
    currencyCode: Scalars["String"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is group account */
    groupAccount: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Account kind */
    kind: AccountKind;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Account name */
    name: Scalars["String"];
    /** Account name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Account name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Account name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Account name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Parent account group */
    parentAccount?: Maybe<Account>;
    /** Parent account ID */
    parentAccountId?: Maybe<Scalars["ID"]>;
    /** Subaccounts */
    subaccounts?: Maybe<Array<Account>>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Account changes object */
export type AccountChanges = {
  /** Account number */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Currency code */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Is group account */
  groupAccount?: InputMaybe<Scalars["Boolean"]>;
  /** Account type */
  kindId?: InputMaybe<Scalars["ID"]>;
  /** Account name */
  name?: InputMaybe<Scalars["String"]>;
  /** Account name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of AccountCreate. */
export type AccountCreatePayload = {
  __typename?: "AccountCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Account>;
};

/** Autogenerated return type of AccountDestroy. */
export type AccountDestroyPayload = {
  __typename?: "AccountDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of AccountDiscard. */
export type AccountDiscardPayload = {
  __typename?: "AccountDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Account>;
};

/** Account field values */
export type AccountFields = {
  /** Account number */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Currency code */
  currencyCode: Scalars["String"];
  /** Is group account */
  groupAccount: Scalars["Boolean"];
  /** Account type */
  kindId: Scalars["ID"];
  /** Account name */
  name: Scalars["String"];
  /** Account name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<Scalars["ID"]>;
};

/** Finance account type object */
export type AccountKind = Discardable &
  Timestamps & {
    __typename?: "AccountKind";
    /** Sub account types */
    accountKinds: Array<AccountKind>;
    /** Allow opening balance */
    allowOpeningBalance: Scalars["Boolean"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** Account kind key */
    key: Scalars["String"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Account type name */
    name: Scalars["String"];
    /** Parent account kind */
    parentKind?: Maybe<AccountKind>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Collection of AccountKinds */
export type AccountKinds = {
  __typename?: "AccountKinds";
  /** Paginated collection of AccountKinds */
  items: Array<AccountKind>;
  /** Total count of AccountKinds */
  totalCount: Scalars["Int"];
};

/** AccountKinds filter */
export type AccountKindsFilterInput = {
  /** Allow opening balance */
  allowOpeningBalance?: InputMaybe<Scalars["Boolean"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Account kind key */
  key?: InputMaybe<StringMatchInput>;
  /** Account type name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** AccountKinds sorting options */
export type AccountKindsSortInput = {
  /** Allow opening balance */
  allowOpeningBalance?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Account kind key */
  key?: InputMaybe<SortDirection>;
  /** Account type name */
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export type AccountMapping = Destroyable & {
  __typename?: "AccountMapping";
  /** Accountable type */
  accountableId?: Maybe<Scalars["ID"]>;
  accountableName?: Maybe<Scalars["String"]>;
  /** Accountable type */
  accountableType?: Maybe<AccountableType>;
  /** Activities */
  activityIds: Array<Scalars["ID"]>;
  /** Activities */
  costCenterIds: Array<Scalars["ID"]>;
  /** Account */
  creditAccount?: Maybe<Account>;
  /** Account */
  debitAccount?: Maybe<Account>;
  id: Scalars["ID"];
  kind: Scalars["String"];
  /** Localities */
  localityIds: Array<Scalars["ID"]>;
  /** May destroy? */
  mayDestroy: Scalars["Boolean"];
};

/** AccountMapping changes object */
export type AccountMappingChanges = {
  /** Destroy mapping */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Accountable type */
  accountableId?: InputMaybe<Scalars["ID"]>;
  accountableName?: InputMaybe<Scalars["String"]>;
  /** Accountable type */
  accountableType?: InputMaybe<AccountableType>;
  /** Activities */
  activityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activities */
  costCenterIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Credit Account */
  creditAccountId?: InputMaybe<Scalars["ID"]>;
  /** Debit Account */
  debitAccountId?: InputMaybe<Scalars["ID"]>;
  /** ID */
  id?: InputMaybe<Scalars["ID"]>;
  kind?: InputMaybe<Scalars["String"]>;
  /** Localities */
  localityIds?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Autogenerated return type of AccountUpdate. */
export type AccountUpdatePayload = {
  __typename?: "AccountUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Account>;
};

export enum AccountableType {
  /** Finance Expense Category */
  FinanceExpenseCategory = "financeExpenseCategory",
  /** Finance Tax Component */
  FinanceTaxComponent = "financeTaxComponent",
  /** HR Salary Component */
  HrSalaryComponent = "hrSalaryComponent",
  /** Inventory Adjustment Type */
  InventoryAdjustmentType = "inventoryAdjustmentType",
  /** Inventory Counterparty */
  InventoryCounterparty = "inventoryCounterparty",
  /** Inventory Item Category */
  InventoryItemCategory = "inventoryItemCategory",
}

/** Collection of Accounts */
export type Accounts = {
  __typename?: "Accounts";
  /** Paginated collection of Accounts */
  items: Array<Account>;
  /** Total count of Accounts */
  totalCount: Scalars["Int"];
};

/** Accounts filter */
export type AccountsFilterInput = {
  /** Account number */
  accountNumber?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Currency code */
  currencyCode?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is group account */
  groupAccount?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Account type */
  kindId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Account kind keys */
  kindKey?: InputMaybe<Array<Scalars["String"]>>;
  /** Account name */
  name?: InputMaybe<StringMatchInput>;
  /** Account name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Accounts sorting options */
export type AccountsSortInput = {
  /** Account number */
  accountNumber?: InputMaybe<SortDirection>;
  /** Account balance */
  balance?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Currency code */
  currencyCode?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is group account */
  groupAccount?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Sorts by kind name */
  kindName?: InputMaybe<SortDirection>;
  /** Account name */
  name?: InputMaybe<SortDirection>;
  /** Account name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Collection of Activities */
export type Activities = {
  __typename?: "Activities";
  /** Paginated collection of Activities */
  items: Array<Activity>;
  /** Total count of Activities */
  totalCount: Scalars["Int"];
};

/** Activities filter */
export type ActivitiesFilterInput = {
  /** Activity category */
  categoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crops */
  cropId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stages */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Description */
  description?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal id */
  internalId?: InputMaybe<StringMatchInput>;
  laborForceType?: InputMaybe<Array<ActivityLaborForceType>>;
  /** Localities */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity name */
  name?: InputMaybe<StringMatchInput>;
  /** Activity Name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Activity Name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Activity Name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Activity Name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  progressMeasureType?: InputMaybe<Array<ActivityProgressMeasureType>>;
  /** Is Progress UOM locked */
  progressUnitLocked?: InputMaybe<Scalars["Boolean"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Default start time */
  startTime?: InputMaybe<DateRangeInput>;
  /** Should track activity cycles */
  trackCycles?: InputMaybe<Scalars["Boolean"]>;
  /** Should track time */
  trackTime?: InputMaybe<Scalars["Boolean"]>;
  /** Should track tools & equipment */
  trackTools?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Should use total topographical area */
  useTotalArea?: InputMaybe<Scalars["Boolean"]>;
  /** Work Order Category */
  workOrderCategory?: InputMaybe<Array<WorkOrderCategory>>;
  /** Work Order Type */
  workOrderType?: InputMaybe<Array<WorkOrderType>>;
  /** Workday hours */
  workdayHours?: InputMaybe<Array<Scalars["Int"]>>;
};

/** Activities sorting options */
export type ActivitiesSortInput = {
  /** Sorts by category name */
  categoryName?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Description */
  description?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal id */
  internalId?: InputMaybe<SortDirection>;
  laborForceType?: InputMaybe<SortDirection>;
  /** Activity name */
  name?: InputMaybe<SortDirection>;
  /** Activity Name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Activity Name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Activity Name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Activity Name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  progressMeasureType?: InputMaybe<SortDirection>;
  /** Is Progress UOM locked */
  progressUnitLocked?: InputMaybe<SortDirection>;
  /** Sorts by progress unit name */
  progressUnitName?: InputMaybe<SortDirection>;
  /** Default start time */
  startTime?: InputMaybe<SortDirection>;
  /** Should track activity cycles */
  trackCycles?: InputMaybe<SortDirection>;
  /** Should track time */
  trackTime?: InputMaybe<SortDirection>;
  /** Should track tools & equipment */
  trackTools?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Should use total topographical area */
  useTotalArea?: InputMaybe<SortDirection>;
  /** Work Order Category */
  workOrderCategory?: InputMaybe<SortDirection>;
  /** Work Order Type */
  workOrderType?: InputMaybe<SortDirection>;
  /** Workday hours */
  workdayHours?: InputMaybe<SortDirection>;
};

/** Activity object */
export type Activity = Discardable & {
  __typename?: "Activity";
  /** Activity metrics */
  activityMetrics: Array<ActivityMetric>;
  /** Activity category */
  category: ActivityCategory;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Activity crops */
  crops: Array<Crop>;
  /** Description */
  description?: Maybe<Scalars["String"]>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** Internal id */
  internalId?: Maybe<Scalars["String"]>;
  laborForceType: ActivityLaborForceType;
  /** Activity localities */
  localities: Array<Locality>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Activity name */
  name: Scalars["String"];
  /** Activity Name in En locale */
  nameEn?: Maybe<Scalars["String"]>;
  /** Activity Name in Es locale */
  nameEs?: Maybe<Scalars["String"]>;
  /** Activity Name in Pt locale */
  namePt?: Maybe<Scalars["String"]>;
  /** Activity Name in Ru locale */
  nameRu?: Maybe<Scalars["String"]>;
  /** Activity progress norms */
  payments: Array<ActivityPayment>;
  progressMeasureType: ActivityProgressMeasureType;
  /** Progress UOM */
  progressUnit: Unit;
  /** Is Progress UOM locked */
  progressUnitLocked?: Maybe<Scalars["Boolean"]>;
  /** Default start time */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Should track activity cycles */
  trackCycles?: Maybe<Scalars["Boolean"]>;
  /** Should track time */
  trackTime?: Maybe<Scalars["Boolean"]>;
  /** Should track tools & equipment */
  trackTools?: Maybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Should use total topographical area */
  useTotalArea?: Maybe<Scalars["Boolean"]>;
  /** Work Order Category */
  workOrderCategory: WorkOrderCategory;
  /** Work Order Type */
  workOrderType: WorkOrderType;
  /** Workday hours */
  workdayHours: Scalars["Int"];
};

/** Collection of ActivityCategories */
export type ActivityCategories = {
  __typename?: "ActivityCategories";
  /** Paginated collection of ActivityCategories */
  items: Array<ActivityCategory>;
  /** Total count of ActivityCategories */
  totalCount: Scalars["Int"];
};

/** ActivityCategories filter */
export type ActivityCategoriesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Category name */
  name?: InputMaybe<StringMatchInput>;
  /** Category name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ActivityCategories sorting options */
export type ActivityCategoriesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Category name */
  name?: InputMaybe<SortDirection>;
  /** Category name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Activity category tags */
  tags?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Activity category object */
export type ActivityCategory = Discardable &
  Global & {
    __typename?: "ActivityCategory";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Category name */
    name: Scalars["String"];
    /** Category name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Category name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Category name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Category name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Activity category tags */
    tags?: Maybe<Array<Scalars["String"]>>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** ActivityCategory changes object */
export type ActivityCategoryChanges = {
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Category name */
  name?: InputMaybe<Scalars["String"]>;
  /** Category name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Activity category tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** Autogenerated return type of ActivityCategoryCreate. */
export type ActivityCategoryCreatePayload = {
  __typename?: "ActivityCategoryCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<ActivityCategory>;
};

/** Autogenerated return type of ActivityCategoryDestroy. */
export type ActivityCategoryDestroyPayload = {
  __typename?: "ActivityCategoryDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of ActivityCategoryDiscard. */
export type ActivityCategoryDiscardPayload = {
  __typename?: "ActivityCategoryDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<ActivityCategory>;
};

/** ActivityCategory field values */
export type ActivityCategoryFields = {
  /** Is global? */
  global: Scalars["Boolean"];
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Is locked */
  locked: Scalars["Boolean"];
  /** Category name */
  name: Scalars["String"];
  /** Category name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Activity category tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** Autogenerated return type of ActivityCategoryUnlock. */
export type ActivityCategoryUnlockPayload = {
  __typename?: "ActivityCategoryUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<ActivityCategory>;
};

/** Autogenerated return type of ActivityCategoryUpdate. */
export type ActivityCategoryUpdatePayload = {
  __typename?: "ActivityCategoryUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<ActivityCategory>;
};

/** Activity changes object */
export type ActivityChanges = {
  /** Activity metrics */
  activityMetricsAttributes?: InputMaybe<Array<ActivityMetricChanges>>;
  /** Activity category */
  categoryId?: InputMaybe<Scalars["ID"]>;
  /** Activity crops */
  cropIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Description */
  description?: InputMaybe<Scalars["String"]>;
  /** Internal id */
  internalId?: InputMaybe<Scalars["String"]>;
  laborForceType?: InputMaybe<ActivityLaborForceType>;
  /** Activity localities */
  localityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity name */
  name?: InputMaybe<Scalars["String"]>;
  /** Activity Name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Activity Name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Activity Name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Activity Name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  progressMeasureType?: InputMaybe<ActivityProgressMeasureType>;
  /** Progress unit */
  progressUnitId?: InputMaybe<Scalars["ID"]>;
  /** Is Progress UOM locked */
  progressUnitLocked?: InputMaybe<Scalars["Boolean"]>;
  /** Default start time */
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Should track activity cycles */
  trackCycles?: InputMaybe<Scalars["Boolean"]>;
  /** Should track time */
  trackTime?: InputMaybe<Scalars["Boolean"]>;
  /** Should track tools & equipment */
  trackTools?: InputMaybe<Scalars["Boolean"]>;
  /** Should use total topographical area */
  useTotalArea?: InputMaybe<Scalars["Boolean"]>;
  /** Work Order Type */
  workOrderType?: InputMaybe<WorkOrderType>;
  /** Workday hours */
  workdayHours?: InputMaybe<Scalars["Int"]>;
};

/** Activity Cost Center Report result */
export type ActivityCostCenter = HasProfitable & {
  __typename?: "ActivityCostCenter";
  /** Avg workers */
  avgWorkers: Scalars["Float"];
  /** Cost Center */
  costCenter: CostCenter;
  /** Crop Field */
  cropField?: Maybe<CropField>;
  /** Ending date */
  endingDate: Scalars["ISO8601DateTime"];
  /** Total estimated progress */
  estimatedProgress: Scalars["Float"];
  /** Inventory Cost */
  inventoryCost: Scalars["Float"];
  /** Labor Cost */
  laborCost: Scalars["Float"];
  /** Labor Efficiency */
  laborEfficiency?: Maybe<Scalars["Float"]>;
  /** Labor Man-Days */
  laborManDays: Scalars["Float"];
  /** Machine Efficiency */
  machineEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine hours */
  machineHours?: Maybe<Scalars["Float"]>;
  /** Man-Days */
  manDays: Scalars["Float"];
  /** Metrics */
  metrics: Array<WorkOrderMetric>;
  /** Profitable extra info */
  profitableExtra?: Maybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: Maybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: Maybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: Maybe<ProfitableType>;
  /** Activity Progress */
  progress: Scalars["Float"];
  /** Starting date */
  startingDate: Scalars["ISO8601DateTime"];
  /** Total Cost */
  totalCost: Scalars["Float"];
  /** Total Cost per progress */
  totalCostPerProgress?: Maybe<Scalars["Float"]>;
  /** Business work days */
  workDays: Scalars["Int"];
};

/** Collection of ActivityCostCenterReport */
export type ActivityCostCenterReport = {
  __typename?: "ActivityCostCenterReport";
  /** Paginated collection of ActivityCostCenterReport */
  items: Array<ActivityCostCenter>;
  /** Total count of ActivityCostCenterReport */
  totalCount: Scalars["Int"];
};

/** ActivityCostCenterReport sorting options */
export type ActivityCostCenterReportSortInput = {
  /** Avg workers */
  avgWorkers?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Ending date */
  endingDate?: InputMaybe<SortDirection>;
  /** Total estimated progress */
  estimatedProgress?: InputMaybe<SortDirection>;
  /** Inventory Cost */
  inventoryCost?: InputMaybe<SortDirection>;
  /** Labor Cost */
  laborCost?: InputMaybe<SortDirection>;
  /** Labor Efficiency */
  laborEfficiency?: InputMaybe<SortDirection>;
  /** Labor Man-Days */
  laborManDays?: InputMaybe<SortDirection>;
  /** Machine Efficiency */
  machineEfficiency?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Man-Days */
  manDays?: InputMaybe<SortDirection>;
  /** Profitable extra info */
  profitableExtra?: InputMaybe<SortDirection>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<SortDirection>;
  /** Profitable name */
  profitableName?: InputMaybe<SortDirection>;
  /** Profitable type */
  profitableType?: InputMaybe<SortDirection>;
  /** Activity Progress */
  progress?: InputMaybe<SortDirection>;
  /** Starting date */
  startingDate?: InputMaybe<SortDirection>;
  /** Total Cost */
  totalCost?: InputMaybe<SortDirection>;
  /** Total Cost per progress */
  totalCostPerProgress?: InputMaybe<SortDirection>;
  /** Business work days */
  workDays?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of ActivityCreate. */
export type ActivityCreatePayload = {
  __typename?: "ActivityCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Activity>;
};

/** Autogenerated return type of ActivityDestroy. */
export type ActivityDestroyPayload = {
  __typename?: "ActivityDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of ActivityDiscard. */
export type ActivityDiscardPayload = {
  __typename?: "ActivityDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Activity>;
};

/** Activity field values */
export type ActivityFields = {
  /** Activity metrics */
  activityMetricsAttributes?: InputMaybe<Array<ActivityMetricFields>>;
  /** Activity category */
  categoryId: Scalars["ID"];
  /** Activity crops */
  cropIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Description */
  description?: InputMaybe<Scalars["String"]>;
  /** Internal id */
  internalId?: InputMaybe<Scalars["String"]>;
  laborForceType: ActivityLaborForceType;
  /** Activity localities */
  localityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity name */
  name: Scalars["String"];
  /** Activity Name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Activity Name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Activity Name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Activity Name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  progressMeasureType: ActivityProgressMeasureType;
  /** Progress unit */
  progressUnitId: Scalars["ID"];
  /** Is Progress UOM locked */
  progressUnitLocked?: InputMaybe<Scalars["Boolean"]>;
  /** Default start time */
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Should track activity cycles */
  trackCycles?: InputMaybe<Scalars["Boolean"]>;
  /** Should track time */
  trackTime?: InputMaybe<Scalars["Boolean"]>;
  /** Should track tools & equipment */
  trackTools?: InputMaybe<Scalars["Boolean"]>;
  /** Should use total topographical area */
  useTotalArea?: InputMaybe<Scalars["Boolean"]>;
  /** Work Order Type */
  workOrderType: WorkOrderType;
  /** Workday hours */
  workdayHours: Scalars["Int"];
};

export enum ActivityLaborForceType {
  /** Laborer */
  Laborer = "laborer",
  /** Machine */
  Machine = "machine",
}

/** Activity Metric Relation object */
export type ActivityMetric = {
  __typename?: "ActivityMetric";
  id: Scalars["ID"];
  /** Sort index */
  index: Scalars["Int"];
  /** Metric */
  metric: Metric;
  /** Scope */
  scope: ActivityMetricScope;
};

/** ActivityMetric changes object */
export type ActivityMetricChanges = {
  /** Remove employee */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Activity Metric ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Sort index */
  index?: InputMaybe<Scalars["Int"]>;
  /** Metric ID */
  metricId?: InputMaybe<Scalars["ID"]>;
  /** Scope */
  scope?: InputMaybe<ActivityMetricScope>;
};

/** ActivityMetric field values */
export type ActivityMetricFields = {
  /** Sort index */
  index: Scalars["Int"];
  /** Metric ID */
  metricId: Scalars["ID"];
  /** Scope */
  scope: ActivityMetricScope;
};

export enum ActivityMetricScope {
  /** Costcenters */
  CostCenter = "costCenter",
  /** Employees */
  Employee = "employee",
  /** Machines */
  Machine = "machine",
  /** Outputs */
  Output = "output",
}

/** Activity Overview Report result */
export type ActivityOverview = {
  __typename?: "ActivityOverview";
  /** Average Workers per Day */
  avgDayWorkers: Scalars["Float"];
  /** Metrics */
  metrics: Array<WorkOrderMetric>;
  /** Total Cost */
  totalCost: Scalars["Float"];
  /** Total Inventory Cost */
  totalInventoryCost: Scalars["Float"];
  /** Total Labor Cost */
  totalLaborCost: Scalars["Float"];
  /** Total Machine Hours */
  totalMachineHours: Scalars["Float"];
  /** Total Man Days */
  totalManDays: Scalars["Float"];
};

/** Activity payment object */
export type ActivityPayment = Discardable & {
  __typename?: "ActivityPayment";
  /** Activity */
  activity: Activity;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Crop */
  crop?: Maybe<Crop>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** Locality */
  locality?: Maybe<Locality>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Position */
  position?: Maybe<Position>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Payment wage */
  wage: Scalars["Float"];
  wageUnit: WorkUnit;
};

/** ActivityPayment changes object */
export type ActivityPaymentChanges = {
  /** Activity */
  activityId?: InputMaybe<Scalars["ID"]>;
  /** Crop */
  cropId?: InputMaybe<Scalars["ID"]>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Position */
  positionId?: InputMaybe<Scalars["ID"]>;
  /** Payment wage */
  wage?: InputMaybe<Scalars["Float"]>;
  wageUnit?: InputMaybe<WorkUnit>;
};

/** Autogenerated return type of ActivityPaymentCreate. */
export type ActivityPaymentCreatePayload = {
  __typename?: "ActivityPaymentCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<ActivityPayment>;
};

/** Autogenerated return type of ActivityPaymentDiscard. */
export type ActivityPaymentDiscardPayload = {
  __typename?: "ActivityPaymentDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<ActivityPayment>;
};

/** ActivityPayment field values */
export type ActivityPaymentFields = {
  /** Activity */
  activityId: Scalars["ID"];
  /** Crop */
  cropId?: InputMaybe<Scalars["ID"]>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Position */
  positionId?: InputMaybe<Scalars["ID"]>;
  /** Payment wage */
  wage: Scalars["Float"];
  wageUnit: WorkUnit;
};

/** Autogenerated return type of ActivityPaymentUpdate. */
export type ActivityPaymentUpdatePayload = {
  __typename?: "ActivityPaymentUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<ActivityPayment>;
};

export enum ActivityProgressMeasureType {
  /** Group */
  ByGroup = "byGroup",
  /** Individual */
  ByIndividual = "byIndividual",
}

/** Activity report filter input */
export type ActivityReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity category */
  categoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cost center */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop field */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stage */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stage type */
  cropStageType?: InputMaybe<CropStageType>;
  /** Crop variety */
  cropVarietyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Group by cycle number */
  showCycles?: InputMaybe<Scalars["Boolean"]>;
  /** Tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Work Order type */
  workOrderType?: InputMaybe<Array<WorkOrderType>>;
};

/** Activity object */
export type ActivitySeed = Discardable & {
  __typename?: "ActivitySeed";
  /** Activity metrics */
  activityMetrics: Array<ActivityMetric>;
  /** Activity category */
  category: ActivityCategory;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Activity crops */
  crops: Array<Crop>;
  /** Description */
  description?: Maybe<Scalars["String"]>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** Internal id */
  internalId?: Maybe<Scalars["String"]>;
  laborForceType: ActivityLaborForceType;
  /** Activity localities */
  localities: Array<Locality>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Activity name */
  name: Scalars["String"];
  /** Activity Name in En locale */
  nameEn?: Maybe<Scalars["String"]>;
  /** Activity Name in Es locale */
  nameEs?: Maybe<Scalars["String"]>;
  /** Activity Name in Pt locale */
  namePt?: Maybe<Scalars["String"]>;
  /** Activity Name in Ru locale */
  nameRu?: Maybe<Scalars["String"]>;
  /** Activity progress norms */
  payments: Array<ActivityPayment>;
  progressMeasureType: ActivityProgressMeasureType;
  /** Progress UOM */
  progressUnit: Unit;
  /** Is Progress UOM locked */
  progressUnitLocked?: Maybe<Scalars["Boolean"]>;
  /** Default start time */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Should track activity cycles */
  trackCycles?: Maybe<Scalars["Boolean"]>;
  /** Should track time */
  trackTime?: Maybe<Scalars["Boolean"]>;
  /** Should track tools & equipment */
  trackTools?: Maybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Should use total topographical area */
  useTotalArea?: Maybe<Scalars["Boolean"]>;
  /** Work Order Category */
  workOrderCategory: WorkOrderCategory;
  /** Work Order Type */
  workOrderType: WorkOrderType;
  /** Workday hours */
  workdayHours: Scalars["Int"];
};

/** Activity Report result */
export type ActivitySummary = {
  __typename?: "ActivitySummary";
  /** Activity */
  activity: Activity;
  /** Avg workers */
  avgWorkers: Scalars["Float"];
  /** Crop cycle */
  cropCycle?: Maybe<CropCycle>;
  /** Cycle number */
  cycleNumber?: Maybe<Scalars["Int"]>;
  /** Ending date */
  endingDate: Scalars["ISO8601DateTime"];
  /** Inventory Cost */
  inventoryCost: Scalars["Float"];
  /** Labor Cost */
  laborCost: Scalars["Float"];
  /** Labor Efficiency */
  laborEfficiency?: Maybe<Scalars["Float"]>;
  /** Labor Man-Days */
  laborManDays: Scalars["Float"];
  /** Locality */
  locality: Locality;
  /** Machine Efficiency */
  machineEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine hours */
  machineHours?: Maybe<Scalars["Float"]>;
  /** Man-Days */
  manDays: Scalars["Float"];
  /** Metrics */
  metrics: Array<WorkOrderMetric>;
  /** Activity Progress */
  progress: Scalars["Float"];
  /** Starting date */
  startingDate: Scalars["ISO8601DateTime"];
  /** Total Cost */
  totalCost: Scalars["Float"];
  /** Total Cost per progress */
  totalCostPerProgress?: Maybe<Scalars["Float"]>;
  /** Business work days */
  workDays: Scalars["Int"];
};

/** Collection of ActivitySummaryReport */
export type ActivitySummaryReport = {
  __typename?: "ActivitySummaryReport";
  /** Paginated collection of ActivitySummaryReport */
  items: Array<ActivitySummary>;
  /** Total count of ActivitySummaryReport */
  totalCount: Scalars["Int"];
};

/** ActivitySummaryReport sorting options */
export type ActivitySummaryReportSortInput = {
  /** Sorts by activity category */
  activityCategory?: InputMaybe<SortDirection>;
  /** Sorts by activity name */
  activityName?: InputMaybe<SortDirection>;
  /** Avg workers */
  avgWorkers?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Cycle number */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Ending date */
  endingDate?: InputMaybe<SortDirection>;
  /** Inventory Cost */
  inventoryCost?: InputMaybe<SortDirection>;
  /** Labor Cost */
  laborCost?: InputMaybe<SortDirection>;
  /** Labor Efficiency */
  laborEfficiency?: InputMaybe<SortDirection>;
  /** Labor Man-Days */
  laborManDays?: InputMaybe<SortDirection>;
  /** Machine Efficiency */
  machineEfficiency?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Man-Days */
  manDays?: InputMaybe<SortDirection>;
  /** Activity Progress */
  progress?: InputMaybe<SortDirection>;
  /** Starting date */
  startingDate?: InputMaybe<SortDirection>;
  /** Total Cost */
  totalCost?: InputMaybe<SortDirection>;
  /** Total Cost per progress */
  totalCostPerProgress?: InputMaybe<SortDirection>;
  /** Business work days */
  workDays?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of ActivityUpdate. */
export type ActivityUpdatePayload = {
  __typename?: "ActivityUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Activity>;
};

/** Address object */
export type Address = Discardable & {
  __typename?: "Address";
  /** Address Line */
  address?: Maybe<Scalars["String"]>;
  /** City */
  city?: Maybe<Scalars["String"]>;
  /** Country info */
  country: Country;
  /** Country code */
  countryCode: Scalars["String"];
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Phone code */
  phoneCode?: Maybe<Scalars["String"]>;
  /** Phone number */
  phoneNumber?: Maybe<Scalars["String"]>;
  /** Region */
  region?: Maybe<Scalars["String"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Address changes object */
export type AddressChanges = {
  /** Address Line */
  address?: InputMaybe<Scalars["String"]>;
  /** City */
  city?: InputMaybe<Scalars["String"]>;
  /** Country code */
  countryCode?: InputMaybe<Scalars["String"]>;
  /** Phone code */
  phoneCode?: InputMaybe<Scalars["String"]>;
  /** Phone number */
  phoneNumber?: InputMaybe<Scalars["String"]>;
  /** Region */
  region?: InputMaybe<Scalars["String"]>;
};

/** Address field values */
export type AddressFields = {
  /** Address Line */
  address?: InputMaybe<Scalars["String"]>;
  /** City */
  city?: InputMaybe<Scalars["String"]>;
  /** Country code */
  countryCode: Scalars["String"];
  /** Phone code */
  phoneCode?: InputMaybe<Scalars["String"]>;
  /** Phone number */
  phoneNumber?: InputMaybe<Scalars["String"]>;
  /** Region */
  region?: InputMaybe<Scalars["String"]>;
};

export enum AdjustmentKind {
  /** Decremental */
  Decremental = "decremental",
  /** Incremental */
  Incremental = "incremental",
  /** Other */
  Other = "other",
}

/** Adjustment type object */
export type AdjustmentType = Auditable &
  Destroyable &
  Discardable &
  Global & {
    __typename?: "AdjustmentType";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by */
    createdBy?: Maybe<User>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Inventory entry code */
    entryCode: Scalars["String"];
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Adjustment kind */
    kind: AdjustmentKind;
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Adjustment type name */
    name: Scalars["String"];
    /** Adjustment type name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Adjustment type name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Adjustment type name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Adjustment type name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
  };

/** AdjustmentType changes object */
export type AdjustmentTypeChanges = {
  /** Adjustment kind */
  kind?: InputMaybe<AdjustmentKind>;
  /** Adjustment type name */
  name?: InputMaybe<Scalars["String"]>;
  /** Adjustment type name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Adjustment type name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Adjustment type name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Adjustment type name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of AdjustmentTypeCreate. */
export type AdjustmentTypeCreatePayload = {
  __typename?: "AdjustmentTypeCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<AdjustmentType>;
};

/** Autogenerated return type of AdjustmentTypeDestroy. */
export type AdjustmentTypeDestroyPayload = {
  __typename?: "AdjustmentTypeDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of AdjustmentTypeDiscard. */
export type AdjustmentTypeDiscardPayload = {
  __typename?: "AdjustmentTypeDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<AdjustmentType>;
};

/** AdjustmentType field values */
export type AdjustmentTypeFields = {
  /** Adjustment kind */
  kind: AdjustmentKind;
  /** Adjustment type name */
  name: Scalars["String"];
  /** Adjustment type name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Adjustment type name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Adjustment type name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Adjustment type name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of AdjustmentTypeUpdate. */
export type AdjustmentTypeUpdatePayload = {
  __typename?: "AdjustmentTypeUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<AdjustmentType>;
};

/** Collection of AdjustmentTypes */
export type AdjustmentTypes = {
  __typename?: "AdjustmentTypes";
  /** Paginated collection of AdjustmentTypes */
  items: Array<AdjustmentType>;
  /** Total count of AdjustmentTypes */
  totalCount: Scalars["Int"];
};

/** AdjustmentTypes filter */
export type AdjustmentTypesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Inventory entry code */
  entryCode?: InputMaybe<StringMatchInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Adjustment kind */
  kind?: InputMaybe<Array<AdjustmentKind>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Adjustment type name */
  name?: InputMaybe<StringMatchInput>;
  /** Adjustment type name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Adjustment type name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Adjustment type name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Adjustment type name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** AdjustmentTypes sorting options */
export type AdjustmentTypesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Inventory entry code */
  entryCode?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Adjustment kind */
  kind?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Adjustment type name */
  name?: InputMaybe<SortDirection>;
  /** Adjustment type name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Adjustment type name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Adjustment type name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Adjustment type name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Object that has created by and updated by */
export type Auditable = {
  /** Created by */
  createdBy?: Maybe<User>;
  /** Updated by */
  updatedBy?: Maybe<User>;
};

export type AuthorizationResult = {
  __typename?: "AuthorizationResult";
  /** Human-readable error message */
  message?: Maybe<Scalars["String"]>;
  /** Reasons of check failure */
  reasons?: Maybe<FailureReasons>;
  /** Result of applying a policy rule */
  value: Scalars["Boolean"];
};

/** Balance Sheet Report result */
export type BalanceSheet = Destroyable &
  Discardable & {
    __typename?: "BalanceSheet";
    /** Account number */
    accountNumber?: Maybe<Scalars["String"]>;
    /** Account balance */
    balance: Scalars["Float"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Account default currency */
    currency: Currency;
    /** Currency code */
    currencyCode: Scalars["String"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is group account */
    groupAccount: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Account kind */
    kind: AccountKind;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Account name */
    name: Scalars["String"];
    /** Account name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Account name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Account name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Account name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Parent account group */
    parentAccount?: Maybe<Account>;
    /** Parent account ID */
    parentAccountId?: Maybe<Scalars["ID"]>;
    /** Subaccounts */
    subaccounts?: Maybe<Array<Account>>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Collection of BalanceSheetReport */
export type BalanceSheetReport = {
  __typename?: "BalanceSheetReport";
  /** Paginated collection of BalanceSheetReport */
  items: Array<BalanceSheet>;
  /** Total count of BalanceSheetReport */
  totalCount: Scalars["Int"];
};

/** BalanceSheetReport filter */
export type BalanceSheetReportFilterInput = {
  /** Account number */
  accountNumber?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Balance on date */
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is group account */
  groupAccount?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Account name */
  name?: InputMaybe<StringMatchInput>;
  /** Account name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** BalanceSheetReport sorting options */
export type BalanceSheetReportSortInput = {
  /** Account number */
  accountNumber?: InputMaybe<SortDirection>;
  /** Account balance */
  balance?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Currency code */
  currencyCode?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is group account */
  groupAccount?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Account kind */
  kind?: InputMaybe<SortDirection>;
  /** Account name */
  name?: InputMaybe<SortDirection>;
  /** Account name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Bank object */
export type Bank = Destroyable &
  Discardable &
  Global & {
    __typename?: "Bank";
    address: Address;
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    name?: Maybe<Scalars["String"]>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Bank account object */
export type BankAccount = Discardable & {
  __typename?: "BankAccount";
  /** Accounting account */
  account?: Maybe<Account>;
  /** Account number */
  accountNumber?: Maybe<Scalars["String"]>;
  /** Accountable type */
  accountableId?: Maybe<Scalars["ID"]>;
  /** Accountable type */
  accountableType?: Maybe<BankAccountableType>;
  /** Bank */
  bank: Bank;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Account default currency */
  currency: Currency;
  /** Currency code */
  currencyCode: Scalars["String"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** BankAccount changes object */
export type BankAccountChanges = {
  /** Accounting account */
  accountId?: InputMaybe<Scalars["ID"]>;
  /** Account number */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Bank */
  bankId?: InputMaybe<Scalars["ID"]>;
  /** Currency code */
  currencyCode?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of BankAccountCreate. */
export type BankAccountCreatePayload = {
  __typename?: "BankAccountCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<BankAccount>;
};

/** Autogenerated return type of BankAccountDestroy. */
export type BankAccountDestroyPayload = {
  __typename?: "BankAccountDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of BankAccountDiscard. */
export type BankAccountDiscardPayload = {
  __typename?: "BankAccountDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<BankAccount>;
};

/** BankAccount field values */
export type BankAccountFields = {
  /** Accounting account */
  accountId?: InputMaybe<Scalars["ID"]>;
  /** Account number */
  accountNumber?: InputMaybe<Scalars["String"]>;
  /** Accountable type */
  accountableId?: InputMaybe<Scalars["ID"]>;
  /** Accountable type */
  accountableType?: InputMaybe<BankAccountableType>;
  /** Bank */
  bankId?: InputMaybe<Scalars["ID"]>;
  /** Currency code */
  currencyCode: Scalars["String"];
};

/** Autogenerated return type of BankAccountUpdate. */
export type BankAccountUpdatePayload = {
  __typename?: "BankAccountUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<BankAccount>;
};

export enum BankAccountableType {
  /** Employee */
  Employee = "employee",
  /** Tenant */
  Tenant = "tenant",
}

/** Bank changes object */
export type BankChanges = {
  /** Address */
  addressAttributes?: InputMaybe<AddressChanges>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of BankCreate. */
export type BankCreatePayload = {
  __typename?: "BankCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Bank>;
};

/** Autogenerated return type of BankDestroy. */
export type BankDestroyPayload = {
  __typename?: "BankDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of BankDiscard. */
export type BankDiscardPayload = {
  __typename?: "BankDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Bank>;
};

/** Bank field values */
export type BankFields = {
  /** Address */
  addressAttributes: AddressFields;
  name?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of BankUnlock. */
export type BankUnlockPayload = {
  __typename?: "BankUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<Bank>;
};

/** Autogenerated return type of BankUpdate. */
export type BankUpdatePayload = {
  __typename?: "BankUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Bank>;
};

/** Collection of Banks */
export type Banks = {
  __typename?: "Banks";
  /** Paginated collection of Banks */
  items: Array<Bank>;
  /** Total count of Banks */
  totalCount: Scalars["Int"];
};

/** Banks filter */
export type BanksFilterInput = {
  /** Country code */
  countryCode?: InputMaybe<Array<Scalars["String"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Banks sorting options */
export type BanksSortInput = {
  /** Sort by country code */
  countryCode?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export type Brand = Discardable &
  Global & {
    __typename?: "Brand";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    name: Scalars["String"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Brand changes object */
export type BrandChanges = {
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of BrandCreate. */
export type BrandCreatePayload = {
  __typename?: "BrandCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Brand>;
};

/** Autogenerated return type of BrandDiscard. */
export type BrandDiscardPayload = {
  __typename?: "BrandDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Brand>;
};

/** Brand field values */
export type BrandFields = {
  /** Is global? */
  global: Scalars["Boolean"];
  /** Is locked */
  locked: Scalars["Boolean"];
  name: Scalars["String"];
};

/** Autogenerated return type of BrandUnlock. */
export type BrandUnlockPayload = {
  __typename?: "BrandUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<Brand>;
};

/** Autogenerated return type of BrandUpdate. */
export type BrandUpdatePayload = {
  __typename?: "BrandUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Brand>;
};

/** Collection of Brands */
export type Brands = {
  __typename?: "Brands";
  /** Paginated collection of Brands */
  items: Array<Brand>;
  /** Total count of Brands */
  totalCount: Scalars["Int"];
};

/** Brands filter */
export type BrandsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Brands sorting options */
export type BrandsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of BulkDestroy. */
export type BulkDestroyPayload = {
  __typename?: "BulkDestroyPayload";
  result: Array<BulkOperationResult>;
};

/** Autogenerated return type of BulkDiscard. */
export type BulkDiscardPayload = {
  __typename?: "BulkDiscardPayload";
  result: Array<BulkOperationResult>;
};

export type BulkOperationResult = {
  __typename?: "BulkOperationResult";
  errors: Array<UserError>;
  id: Scalars["ID"];
  result?: Maybe<Scalars["Boolean"]>;
};

/** Change log */
export type ChangeLog = Timestamps & {
  __typename?: "ChangeLog";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  event: Scalars["String"];
  id: Scalars["ID"];
  objectChanges?: Maybe<Scalars["JSON"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** User */
  user: User;
};

/** Collection of ChangeLogs */
export type ChangeLogs = {
  __typename?: "ChangeLogs";
  /** Paginated collection of ChangeLogs */
  items: Array<ChangeLog>;
  /** Total count of ChangeLogs */
  totalCount: Scalars["Int"];
};

/** ChangeLogs filter */
export type ChangeLogsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  event?: InputMaybe<StringMatchInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Object ID */
  objId?: InputMaybe<Scalars["Int"]>;
  /** Object type */
  objType?: InputMaybe<Scalars["String"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ChangeLogs sorting options */
export type ChangeLogsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  event?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  objectChanges?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Change_log permissions */
export type Change_LogInputPermissions = {
  read?: InputMaybe<Scalars["Boolean"]>;
};

/** Change_log permissions options */
export type Change_LogPermissions = {
  __typename?: "Change_logPermissions";
  read: Scalars["Boolean"];
};

/** Column result */
export type Column = {
  __typename?: "Column";
  /** Column name */
  name: Scalars["String"];
  /** Planned value of column */
  planned?: Maybe<Scalars["Float"]>;
  /** Value of column */
  value?: Maybe<Scalars["Float"]>;
};

/** Comment object */
export type Comment = Discardable & {
  __typename?: "Comment";
  commentableId: Scalars["ID"];
  /** Commentable type model */
  commentableType: CommentableType;
  /** Comment text */
  content: Scalars["String"];
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  files?: Maybe<Array<Scalars["Upload"]>>;
  id: Scalars["ID"];
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  mentions?: Maybe<Array<User>>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Comment User */
  user: User;
};

/** Comment changes object */
export type CommentChanges = {
  commentableId?: InputMaybe<Scalars["ID"]>;
  /** Commentable type model */
  commentableType?: InputMaybe<CommentableType>;
  /** Comment text */
  content?: InputMaybe<Scalars["String"]>;
  files?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Files to upload */
  newFiles?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Files to delete */
  purgeFiles?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Autogenerated return type of CommentCreate. */
export type CommentCreatePayload = {
  __typename?: "CommentCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Comment>;
};

/** Autogenerated return type of CommentDestroy. */
export type CommentDestroyPayload = {
  __typename?: "CommentDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CommentDiscard. */
export type CommentDiscardPayload = {
  __typename?: "CommentDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Comment>;
};

/** Comment field values */
export type CommentFields = {
  commentableId: Scalars["ID"];
  /** Commentable type model */
  commentableType: CommentableType;
  /** Comment text */
  content: Scalars["String"];
  files?: InputMaybe<Array<Scalars["Upload"]>>;
};

/** Autogenerated return type of CommentUpdate. */
export type CommentUpdatePayload = {
  __typename?: "CommentUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Comment>;
};

/** Object that has comments */
export type Commentable = {
  /** Comments */
  comments: Array<Comment>;
};

export enum CommentableType {
  /** AgroPlanning */
  AgroPlanning = "agroPlanning",
  /** AgroWorkOrder */
  AgroWorkOrder = "agroWorkOrder",
  /** FinanceJournalEntry */
  FinanceJournalEntry = "financeJournalEntry",
  /** FinanceOrder */
  FinanceOrder = "financeOrder",
  /** HRLeaveAssignment */
  HrLeaveAssignment = "hrLeaveAssignment",
  /** HRPayrollEntry */
  HrPayrollEntry = "hrPayrollEntry",
  /** InventoryOrder */
  InventoryOrder = "inventoryOrder",
  /** InventoryTransfer */
  InventoryTransfer = "inventoryTransfer",
}

/** Collection of Comments */
export type Comments = {
  __typename?: "Comments";
  /** Paginated collection of Comments */
  items: Array<Comment>;
  /** Total count of Comments */
  totalCount: Scalars["Int"];
};

/** Comments filter */
export type CommentsFilterInput = {
  /** Commentable id object */
  commentableId?: InputMaybe<Scalars["ID"]>;
  /** Commentable type model */
  commentableType?: InputMaybe<Array<CommentableType>>;
  /** Comment text */
  content?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Comments sorting options */
export type CommentsSortInput = {
  commentableId?: InputMaybe<SortDirection>;
  /** Commentable type model */
  commentableType?: InputMaybe<SortDirection>;
  /** Comment text */
  content?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  files?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum ContractType {
  /** By activity */
  ByActivity = "byActivity",
  /** Full time */
  FullTime = "fullTime",
}

/** Finance cost center object */
export type CostCenter = Destroyable &
  Discardable &
  Timestamps & {
    __typename?: "CostCenter";
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    internalId?: Maybe<Scalars["String"]>;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Account name */
    name: Scalars["String"];
    /** Cost center name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Cost center name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Cost center name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Cost center name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Parent cost center */
    parentCostCenter?: Maybe<CostCenter>;
    /** Default profitable type */
    profitableType?: Maybe<ProfitableType>;
    /** Subcost centers */
    subcostCenters: Array<CostCenter>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** CostCenter changes object */
export type CostCenterChanges = {
  internalId?: InputMaybe<Scalars["String"]>;
  /** Account name */
  name?: InputMaybe<Scalars["String"]>;
  /** Cost center name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Cost center name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Cost center name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Cost center name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Parent cost center */
  parentCostCenterId?: InputMaybe<Scalars["ID"]>;
  /** Default profitable type */
  profitableType?: InputMaybe<ProfitableType>;
};

/** Autogenerated return type of CostCenterCreate. */
export type CostCenterCreatePayload = {
  __typename?: "CostCenterCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<CostCenter>;
};

/** Autogenerated return type of CostCenterDestroy. */
export type CostCenterDestroyPayload = {
  __typename?: "CostCenterDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CostCenterDiscard. */
export type CostCenterDiscardPayload = {
  __typename?: "CostCenterDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<CostCenter>;
};

/** CostCenter field values */
export type CostCenterFields = {
  internalId?: InputMaybe<Scalars["String"]>;
  /** Account name */
  name: Scalars["String"];
  /** Cost center name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Cost center name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Cost center name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Cost center name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Parent cost center */
  parentCostCenterId?: InputMaybe<Scalars["ID"]>;
  /** Default profitable type */
  profitableType?: InputMaybe<ProfitableType>;
};

/** Autogenerated return type of CostCenterUpdate. */
export type CostCenterUpdatePayload = {
  __typename?: "CostCenterUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<CostCenter>;
};

/** Collection of CostCenters */
export type CostCenters = {
  __typename?: "CostCenters";
  /** Paginated collection of CostCenters */
  items: Array<CostCenter>;
  /** Total count of CostCenters */
  totalCount: Scalars["Int"];
};

/** CostCenters filter */
export type CostCentersFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  internalId?: InputMaybe<StringMatchInput>;
  /** Account name */
  name?: InputMaybe<StringMatchInput>;
  /** Cost center name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Cost center name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Cost center name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Cost center name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Parent cost center */
  parentCostCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Default profitable type */
  profitableType?: InputMaybe<Array<ProfitableType>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** CostCenters sorting options */
export type CostCentersSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  internalId?: InputMaybe<SortDirection>;
  /** Account name */
  name?: InputMaybe<SortDirection>;
  /** Cost center name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Cost center name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Cost center name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Cost center name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Default profitable type */
  profitableType?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Collection of Counterparties */
export type Counterparties = {
  __typename?: "Counterparties";
  /** Paginated collection of Counterparties */
  items: Array<Counterparty>;
  /** Total count of Counterparties */
  totalCount: Scalars["Int"];
};

/** Counterparties filter */
export type CounterpartiesFilterInput = {
  /** Counterparty legal business name */
  businessName?: InputMaybe<StringMatchInput>;
  /** Counterparty countries */
  countryCode?: InputMaybe<Array<Scalars["String"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Counterparty internal code */
  internalId?: InputMaybe<StringMatchInput>;
  /** Counterparty name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Counterparty internal code */
  taxIdNumber?: InputMaybe<StringMatchInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Counterparties sorting options */
export type CounterpartiesSortInput = {
  /** Counterparty legal business name */
  businessName?: InputMaybe<SortDirection>;
  /** Sort by country code */
  countryCode?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Counterparty internal code */
  internalId?: InputMaybe<SortDirection>;
  /** Counterparty name */
  name?: InputMaybe<SortDirection>;
  /** Counterparty internal code */
  taxIdNumber?: InputMaybe<SortDirection>;
  /** Default tax rate */
  taxRate?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Default withholding rate */
  withholdingRate?: InputMaybe<SortDirection>;
};

/** Counterparty object */
export type Counterparty = Discardable & {
  __typename?: "Counterparty";
  /** Counterparty address */
  address?: Maybe<Address>;
  /** Counterparty legal business name */
  businessName: Scalars["String"];
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Default customer payment method */
  customerPaymentMethod?: Maybe<PaymentMethod>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** Counterparty internal code */
  internalId?: Maybe<Scalars["String"]>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Counterparty name */
  name: Scalars["String"];
  /** Payable debit account */
  payableAccount?: Maybe<Account>;
  /** Receivable debit account */
  receivableAccount?: Maybe<Account>;
  /** Counterparty internal code */
  taxIdNumber?: Maybe<Scalars["String"]>;
  /** Assigned Tax Plan */
  taxPlan?: Maybe<TaxPlan>;
  /** Default tax rate */
  taxRate?: Maybe<Scalars["Float"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Vat debit account */
  vatAccount?: Maybe<Account>;
  /** Default vendor payment method */
  vendorPaymentMethod?: Maybe<PaymentMethod>;
  /** Withholding debit account */
  withholdingAccount?: Maybe<Account>;
  /** Default withholding rate */
  withholdingRate?: Maybe<Scalars["Float"]>;
};

/** Autogenerated return type of CounterpartyBulkBulkUpdate. */
export type CounterpartyBulkBulkUpdatePayload = {
  __typename?: "CounterpartyBulkBulkUpdatePayload";
  result: Array<BulkOperationResult>;
};

/** CounterpartyBulk changes object */
export type CounterpartyBulkChanges = {
  /** Tax Plan */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
};

/** Counterparty changes object */
export type CounterpartyChanges = {
  /** Address */
  addressAttributes?: InputMaybe<AddressChanges>;
  /** Counterparty legal business name */
  businessName?: InputMaybe<Scalars["String"]>;
  /** Customer payment method */
  customerPaymentMethodId?: InputMaybe<Scalars["ID"]>;
  /** Counterparty internal code */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Counterparty name */
  name?: InputMaybe<Scalars["String"]>;
  /** Payable account */
  payableAccountId?: InputMaybe<Scalars["ID"]>;
  /** Receivable account */
  receivableAccountId?: InputMaybe<Scalars["ID"]>;
  /** Counterparty internal code */
  taxIdNumber?: InputMaybe<Scalars["String"]>;
  /** Item tax plan */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
  /** Default tax rate */
  taxRate?: InputMaybe<Scalars["Float"]>;
  /** Taxes account */
  vatAccountId?: InputMaybe<Scalars["ID"]>;
  /** Vendor payment method */
  vendorPaymentMethodId?: InputMaybe<Scalars["ID"]>;
  /** Withholding account */
  withholdingAccountId?: InputMaybe<Scalars["ID"]>;
  /** Default withholding rate */
  withholdingRate?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of CounterpartyCreate. */
export type CounterpartyCreatePayload = {
  __typename?: "CounterpartyCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Counterparty>;
};

/** Autogenerated return type of CounterpartyDestroy. */
export type CounterpartyDestroyPayload = {
  __typename?: "CounterpartyDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CounterpartyDiscard. */
export type CounterpartyDiscardPayload = {
  __typename?: "CounterpartyDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Counterparty>;
};

/** Counterparty field values */
export type CounterpartyFields = {
  /** Address */
  addressAttributes?: InputMaybe<AddressFields>;
  /** Counterparty legal business name */
  businessName: Scalars["String"];
  /** Customer payment method */
  customerPaymentMethodId?: InputMaybe<Scalars["ID"]>;
  /** Counterparty internal code */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Counterparty name */
  name: Scalars["String"];
  /** Counterparty internal code */
  taxIdNumber?: InputMaybe<Scalars["String"]>;
  /** Default tax rate */
  taxRate?: InputMaybe<Scalars["Float"]>;
  /** Vendor payment method */
  vendorPaymentMethodId?: InputMaybe<Scalars["ID"]>;
  /** Default withholding rate */
  withholdingRate?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of CounterpartyUpdate. */
export type CounterpartyUpdatePayload = {
  __typename?: "CounterpartyUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Counterparty>;
};

/** Country object */
export type Country = {
  __typename?: "Country";
  /** Country alpha2 code */
  code: Scalars["ID"];
  /** Country currency */
  currency: Currency;
  /** Emoji flag */
  emojiFlag: Scalars["String"];
  /** Country name */
  name: Scalars["String"];
  /** National ID */
  nationalId: Scalars["String"];
  /** National ID format */
  nationalIdFormat?: Maybe<Scalars["String"]>;
  /** Phone code */
  phoneCode: Scalars["String"];
  /** Regions */
  regions: Array<Scalars["String"]>;
  /** SSN name */
  ssnName: Scalars["String"];
  /** Tax ID */
  taxId: Scalars["String"];
  /** Tax ID format */
  taxIdFormat?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of CreateActivities. */
export type CreateActivitiesPayload = {
  __typename?: "CreateActivitiesPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CreateItems. */
export type CreateItemsPayload = {
  __typename?: "CreateItemsPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CreateSalaryStructures. */
export type CreateSalaryStructuresPayload = {
  __typename?: "CreateSalaryStructuresPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Crop object */
export type Crop = Discardable &
  Global & {
    __typename?: "Crop";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Crop common names */
    commonNames?: Maybe<Array<Scalars["String"]>>;
    /** Crop common names in En locale */
    commonNamesEn?: Maybe<Array<Scalars["String"]>>;
    /** Crop common names in Es locale */
    commonNamesEs?: Maybe<Array<Scalars["String"]>>;
    /** Crop common names in Pt locale */
    commonNamesPt?: Maybe<Array<Scalars["String"]>>;
    /** Crop common names in Ru locale */
    commonNamesRu?: Maybe<Array<Scalars["String"]>>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Crop name */
    name: Scalars["String"];
    /** Crop name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Crop name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Crop name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Crop name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Crop changes object */
export type CropChanges = {
  /** Crop common names */
  commonNames?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop common names in En locale */
  commonNamesEn?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop common names in Es locale */
  commonNamesEs?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop common names in Pt locale */
  commonNamesPt?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop common names in Ru locale */
  commonNamesRu?: InputMaybe<Array<Scalars["String"]>>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Crop name */
  name?: InputMaybe<Scalars["String"]>;
  /** Crop name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Crop name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Crop name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Crop name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of CropCreate. */
export type CropCreatePayload = {
  __typename?: "CropCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Crop>;
};

/** Crop cycle object */
export type CropCycle = Destroyable &
  Discardable & {
    __typename?: "CropCycle";
    /** Crop cycle close date */
    closedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Crops */
    crops: Array<Crop>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Crop cycle estimated end date */
    estimatedEndDate?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Crop cycle harvest estimate */
    harvestEstimate?: Maybe<HarvestEstimate>;
    id: Scalars["ID"];
    /** Locality */
    locality: Locality;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Crop cycle name */
    name: Scalars["String"];
    /** Crop cycle start date */
    startedAt: Scalars["ISO8601DateTime"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** CropCycle changes object */
export type CropCycleChanges = {
  /** Crop cycle close date */
  closedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Crops */
  cropIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle estimated end date */
  estimatedEndDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Crop cycle name */
  name?: InputMaybe<Scalars["String"]>;
  /** Crop cycle start date */
  startedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** Autogenerated return type of CropCycleCloseMutation. */
export type CropCycleCloseMutationPayload = {
  __typename?: "CropCycleCloseMutationPayload";
  activeDocuments: Array<FinanceDocumentReference>;
  changes: Array<CropStageChange>;
  errors: Array<UserError>;
};

/** Autogenerated return type of CropCycleCreate. */
export type CropCycleCreatePayload = {
  __typename?: "CropCycleCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropCycle>;
};

/** Autogenerated return type of CropCycleDestroy. */
export type CropCycleDestroyPayload = {
  __typename?: "CropCycleDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CropCycleDiscard. */
export type CropCycleDiscardPayload = {
  __typename?: "CropCycleDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<CropCycle>;
};

/** CropCycle field values */
export type CropCycleFields = {
  /** Crop cycle close date */
  closedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Crops */
  cropIds: Array<Scalars["ID"]>;
  /** Crop cycle estimated end date */
  estimatedEndDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Locality */
  localityId: Scalars["ID"];
  /** Crop cycle name */
  name: Scalars["String"];
  /** Crop cycle start date */
  startedAt: Scalars["ISO8601DateTime"];
};

/** Autogenerated return type of CropCycleReopenMutation. */
export type CropCycleReopenMutationPayload = {
  __typename?: "CropCycleReopenMutationPayload";
  errors: Array<UserError>;
  result: CropCycle;
};

/** Autogenerated return type of CropCycleUpdate. */
export type CropCycleUpdatePayload = {
  __typename?: "CropCycleUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropCycle>;
};

/** Collection of CropCycles */
export type CropCycles = {
  __typename?: "CropCycles";
  /** Paginated collection of CropCycles */
  items: Array<CropCycle>;
  /** Total count of CropCycles */
  totalCount: Scalars["Int"];
};

/** CropCycles filter */
export type CropCyclesFilterInput = {
  /** Crop cycle close date */
  closedAt?: InputMaybe<DateRangeInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop */
  cropId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Crop cycle estimated end date */
  estimatedEndDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Crop cycle start date */
  startedAt?: InputMaybe<DateRangeInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** CropCycles sorting options */
export type CropCyclesSortInput = {
  /** Crop cycle close date */
  closedAt?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Crop cycle estimated end date */
  estimatedEndDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Crop cycle name */
  name?: InputMaybe<SortDirection>;
  /** Crop cycle start date */
  startedAt?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of CropDiscard. */
export type CropDiscardPayload = {
  __typename?: "CropDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Crop>;
};

/** Crop field object */
export type CropField = Discardable &
  Profitable & {
    __typename?: "CropField";
    /** Crop field progress */
    actualProgress?: Maybe<Scalars["Float"]>;
    /** Elevation above the sea */
    altitude?: Maybe<Scalars["Float"]>;
    /** Cost Center */
    costCenter?: Maybe<CostCenter>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Crop */
    crop: Crop;
    /** Crop field varieties */
    cropFieldVarieties: Array<CropFieldVariety>;
    /** Field crop stage */
    cropStage: CropStage;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Effective area */
    effectiveArea: Scalars["Float"];
    /** Effective plants quantity */
    effectivePlants: Scalars["Float"];
    /** Estimated harvest amount */
    estimatedHarvestAmount?: Maybe<Scalars["Float"]>;
    /** Estimated plants quantity */
    estimatedPlants: Scalars["Float"];
    /** GeoJSON object */
    geoJson?: Maybe<Scalars["JSON"]>;
    /** Crop field group */
    group?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    /** Internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Is field irrigated */
    irrigated: Scalars["Boolean"];
    /** Locality */
    locality: Locality;
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Crop field name */
    name: Scalars["String"];
    /** Crop field planned progress */
    plannedProgress?: Maybe<Scalars["Float"]>;
    /** Avg plant population density */
    plantDensity: Scalars["Float"];
    /** Field tags */
    tags?: Maybe<Array<Scalars["String"]>>;
    /** Topographical area */
    totalArea: Scalars["Float"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Crop field object */
export type CropFieldActualProgressArgs = {
  progressFilter?: InputMaybe<CropFieldProgressFilterInput>;
};

/** Crop field object */
export type CropFieldEstimatedHarvestAmountArgs = {
  estimationInput?: InputMaybe<CropFieldHarvestEstimationInput>;
};

/** Crop field object */
export type CropFieldPlannedProgressArgs = {
  progressFilter?: InputMaybe<CropFieldProgressFilterInput>;
};

/** Crop FieldActivity  Report result */
export type CropFieldActivity = {
  __typename?: "CropFieldActivity";
  /** Activity */
  activity: Activity;
  /** Cycle number */
  cycleNumber?: Maybe<Scalars["Int"]>;
  /** Ending date */
  endingDate: Scalars["ISO8601DateTime"];
  /** Inventory Cost */
  inventoryCost: Scalars["Float"];
  /** Labor Cost */
  laborCost: Scalars["Float"];
  /** Machine hours */
  machineHours: Scalars["Float"];
  /** Man-Days */
  manDays: Scalars["Float"];
  /** Metrics */
  metrics: Array<WorkOrderMetric>;
  /** Progress */
  progress: Scalars["Float"];
  /** Starting date */
  startingDate: Scalars["ISO8601DateTime"];
  /** Total cost */
  totalCost: Scalars["Float"];
  /** Business work days */
  workDays: Scalars["Int"];
};

/** Collection of CropFieldActivityReport */
export type CropFieldActivityReport = {
  __typename?: "CropFieldActivityReport";
  /** Paginated collection of CropFieldActivityReport */
  items: Array<CropFieldActivity>;
  /** Total count of CropFieldActivityReport */
  totalCount: Scalars["Int"];
};

/** CropFieldActivityReport sorting options */
export type CropFieldActivityReportSortInput = {
  /** Sorts by activity name */
  activityName?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Cycle number */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Ending date */
  endingDate?: InputMaybe<SortDirection>;
  /** Inventory Cost */
  inventoryCost?: InputMaybe<SortDirection>;
  /** Labor Cost */
  laborCost?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Man-Days */
  manDays?: InputMaybe<SortDirection>;
  /** Progress */
  progress?: InputMaybe<SortDirection>;
  /** Starting date */
  startingDate?: InputMaybe<SortDirection>;
  /** Total cost */
  totalCost?: InputMaybe<SortDirection>;
  /** Business work days */
  workDays?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of CropFieldBulkBulkUpdate. */
export type CropFieldBulkBulkUpdatePayload = {
  __typename?: "CropFieldBulkBulkUpdatePayload";
  result: Array<BulkOperationResult>;
};

/** CropFieldBulk changes object */
export type CropFieldBulkChanges = {
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Crop stages */
  cropStageId?: InputMaybe<Scalars["ID"]>;
  /** Crop field group */
  group?: InputMaybe<Scalars["String"]>;
  /** Field tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** CropField changes object */
export type CropFieldChanges = {
  /** Crop field progress */
  actualProgress?: InputMaybe<Scalars["Float"]>;
  /** Elevation above the sea */
  altitude?: InputMaybe<Scalars["Float"]>;
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Crop Field Variety */
  cropFieldVarietiesAttributes?: InputMaybe<Array<CropFieldVarietyChanges>>;
  /** Crop */
  cropId?: InputMaybe<Scalars["ID"]>;
  /** Crop stages */
  cropStageId?: InputMaybe<Scalars["ID"]>;
  /** Effective area */
  effectiveArea?: InputMaybe<Scalars["Float"]>;
  /** Effective plants quantity */
  effectivePlants?: InputMaybe<Scalars["Float"]>;
  /** Estimated harvest amount */
  estimatedHarvestAmount?: InputMaybe<Scalars["Float"]>;
  /** Estimated plants quantity */
  estimatedPlants?: InputMaybe<Scalars["Float"]>;
  /** GeoJSON object */
  geoJson?: InputMaybe<Scalars["JSON"]>;
  /** Crop field group */
  group?: InputMaybe<Scalars["String"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Is field irrigated */
  irrigated?: InputMaybe<Scalars["Boolean"]>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Crop field name */
  name?: InputMaybe<Scalars["String"]>;
  /** Crop field planned progress */
  plannedProgress?: InputMaybe<Scalars["Float"]>;
  /** Avg plant population density */
  plantDensity?: InputMaybe<Scalars["Float"]>;
  /** Field tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Topographical area */
  totalArea?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of CropFieldCreate. */
export type CropFieldCreatePayload = {
  __typename?: "CropFieldCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropField>;
};

/** Autogenerated return type of CropFieldDestroy. */
export type CropFieldDestroyPayload = {
  __typename?: "CropFieldDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CropFieldDiscard. */
export type CropFieldDiscardPayload = {
  __typename?: "CropFieldDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<CropField>;
};

/** CropField field values */
export type CropFieldFields = {
  /** Crop field progress */
  actualProgress?: InputMaybe<Scalars["Float"]>;
  /** Elevation above the sea */
  altitude?: InputMaybe<Scalars["Float"]>;
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Crop Field Varieties */
  cropFieldVarietiesAttributes: Array<CropFieldVarietyFields>;
  /** Crop */
  cropId: Scalars["ID"];
  /** Crop stage */
  cropStageId: Scalars["ID"];
  /** Estimated harvest amount */
  estimatedHarvestAmount?: InputMaybe<Scalars["Float"]>;
  /** GeoJSON object */
  geoJson?: InputMaybe<Scalars["JSON"]>;
  /** Crop field group */
  group?: InputMaybe<Scalars["String"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Is field irrigated */
  irrigated: Scalars["Boolean"];
  /** Locality */
  localityId: Scalars["ID"];
  /** Crop field name */
  name: Scalars["String"];
  /** Crop field planned progress */
  plannedProgress?: InputMaybe<Scalars["Float"]>;
  /** Field tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Topographical area */
  totalArea: Scalars["Float"];
};

export type CropFieldHarvestEstimate = Timestamps & {
  __typename?: "CropFieldHarvestEstimate";
  /** Estimation amount */
  amount: Scalars["Float"];
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Crop Field */
  cropField: CropField;
  /** Effective area */
  effectiveArea: Scalars["Float"];
  id: Scalars["ID"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** CropFieldHarvestEstimate changes object */
export type CropFieldHarvestEstimateChanges = {
  /** Remove estimation */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Estimation amount */
  amount?: InputMaybe<Scalars["Float"]>;
  /** Crop field */
  cropFieldId?: InputMaybe<Scalars["ID"]>;
  /** Crop field estimate ID */
  id?: InputMaybe<Scalars["ID"]>;
};

/** CropFieldHarvestEstimate field values */
export type CropFieldHarvestEstimateFields = {
  /** Estimation amount */
  amount: Scalars["Float"];
  /** Crop field */
  cropFieldId: Scalars["ID"];
};

/** Estimated harvest input */
export type CropFieldHarvestEstimationInput = {
  cropCycleId?: InputMaybe<Scalars["ID"]>;
};

/** Crop field machine object */
export type CropFieldMachine = {
  __typename?: "CropFieldMachine";
  /** Capacity Value */
  capacityValue?: Maybe<Scalars["Float"]>;
  /** Crop field */
  cropField: CropField;
  id: Scalars["ID"];
  /** Machine */
  machine: Machine;
};

/** CropFieldMachine changes object */
export type CropFieldMachineChanges = {
  /** Remove crop field */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Capacity Value */
  capacityValue?: InputMaybe<Scalars["Float"]>;
  /** Crop Field */
  cropFieldId?: InputMaybe<Scalars["ID"]>;
  /** Crop field machine ID */
  id?: InputMaybe<Scalars["ID"]>;
};

/** Crop Field Note object */
export type CropFieldNote = Discardable & {
  __typename?: "CropFieldNote";
  /** Client ID */
  clientId?: Maybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Created by */
  createdBy?: Maybe<User>;
  /** Crop field */
  cropField: CropField;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Document date */
  documentDate: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  images?: Maybe<Array<Scalars["Upload"]>>;
  /** Note type */
  kind: CropFieldNoteType;
  /** Latitude */
  latitude?: Maybe<Scalars["Float"]>;
  /** Longitude */
  longitude?: Maybe<Scalars["Float"]>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Note message */
  message?: Maybe<Scalars["String"]>;
  /** Sample diseases */
  sampleDiseases: Array<SampleDisease>;
  /** Thumbnail */
  thumbnail?: Maybe<Scalars["String"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** CropFieldNote changes object */
export type CropFieldNoteChanges = {
  /** Client ID */
  clientId?: InputMaybe<Scalars["String"]>;
  /** Document date */
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  images?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Note type */
  kind?: InputMaybe<CropFieldNoteType>;
  /** Latitude */
  latitude?: InputMaybe<Scalars["Float"]>;
  /** Longitude */
  longitude?: InputMaybe<Scalars["Float"]>;
  /** Note message */
  message?: InputMaybe<Scalars["String"]>;
  /** New images */
  newFiles?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Images to delete */
  purgeFiles?: InputMaybe<Array<Scalars["ID"]>>;
  /** Sample diseases */
  sampleDiseasesAttributes?: InputMaybe<Array<SampleDiseaseChanges>>;
  /** Thumbnail */
  thumbnail?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of CropFieldNoteCreate. */
export type CropFieldNoteCreatePayload = {
  __typename?: "CropFieldNoteCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropFieldNote>;
};

/** Autogenerated return type of CropFieldNoteDiscard. */
export type CropFieldNoteDiscardPayload = {
  __typename?: "CropFieldNoteDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<CropFieldNote>;
};

/** CropFieldNote field values */
export type CropFieldNoteFields = {
  /** Client ID */
  clientId?: InputMaybe<Scalars["String"]>;
  /** Crop field */
  cropFieldId: Scalars["ID"];
  /** Document date */
  documentDate: Scalars["ISO8601DateTime"];
  images?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Note type */
  kind: CropFieldNoteType;
  /** Latitude */
  latitude?: InputMaybe<Scalars["Float"]>;
  /** Longitude */
  longitude?: InputMaybe<Scalars["Float"]>;
  /** Note message */
  message?: InputMaybe<Scalars["String"]>;
  /** Sample diseases */
  sampleDiseasesAttributes?: InputMaybe<Array<SampleDiseaseFields>>;
};

export enum CropFieldNoteType {
  /** Disease */
  Disease = "disease",
  /** Other */
  Other = "other",
}

/** Autogenerated return type of CropFieldNoteUpdate. */
export type CropFieldNoteUpdatePayload = {
  __typename?: "CropFieldNoteUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropFieldNote>;
};

/** Collection of CropFieldNotes */
export type CropFieldNotes = {
  __typename?: "CropFieldNotes";
  /** Paginated collection of CropFieldNotes */
  items: Array<CropFieldNote>;
  /** Total count of CropFieldNotes */
  totalCount: Scalars["Int"];
};

/** CropFieldNotes filter */
export type CropFieldNotesFilterInput = {
  /** Client ID */
  clientId?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Note type */
  kind?: InputMaybe<Array<CropFieldNoteType>>;
  /** Farms */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Note message */
  message?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Thumbnail */
  thumbnail?: InputMaybe<StringMatchInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** CropFieldNotes sorting options */
export type CropFieldNotesSortInput = {
  /** Client ID */
  clientId?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Document date */
  documentDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  images?: InputMaybe<SortDirection>;
  /** Note type */
  kind?: InputMaybe<SortDirection>;
  /** Latitude */
  latitude?: InputMaybe<SortDirection>;
  /** Longitude */
  longitude?: InputMaybe<SortDirection>;
  /** Note message */
  message?: InputMaybe<SortDirection>;
  /** Thumbnail */
  thumbnail?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Crop field progress filter */
export type CropFieldProgressFilterInput = {
  activityId: Scalars["ID"];
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  localityId: Scalars["ID"];
};

/** Crop field report filter input */
export type CropFieldReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity category */
  categoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stage */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop variety */
  cropVarietyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Crop field group */
  group?: InputMaybe<Array<Scalars["String"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in estimation units */
  showInEstimationUnits?: InputMaybe<Scalars["Boolean"]>;
  /** Crop field tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Work Order type */
  workOrderType?: InputMaybe<Array<WorkOrderType>>;
};

/** Crop Field Report result */
export type CropFieldSummary = {
  __typename?: "CropFieldSummary";
  /** Crop Field */
  cropField: CropField;
  /** Estimated amount */
  estimatedAmount?: Maybe<Scalars["Float"]>;
  /** Estimated yield */
  estimatedYield?: Maybe<Scalars["Float"]>;
  /** Harvest unit */
  harvestUnit: Unit;
  /** Harvest yield */
  harvestYield: Scalars["Float"];
  /** Harvested amount */
  harvestedAmount: Scalars["Float"];
  /** Has Estimation */
  hasEstimation: Scalars["Boolean"];
  /** Inventory Cost */
  inventoryCost: Scalars["Float"];
  /** Labor Cost */
  laborCost: Scalars["Float"];
  /** Machine hours */
  machineHours: Scalars["Float"];
  /** Man-Days */
  manDays: Scalars["Float"];
  /** Metrics */
  metrics: Array<WorkOrderMetric>;
  /** Total cost */
  totalCost: Scalars["Float"];
};

/** Collection of CropFieldSummaryReport */
export type CropFieldSummaryReport = {
  __typename?: "CropFieldSummaryReport";
  /** Paginated collection of CropFieldSummaryReport */
  items: Array<CropFieldSummary>;
  /** Total count of CropFieldSummaryReport */
  totalCount: Scalars["Int"];
};

/** CropFieldSummaryReport sorting options */
export type CropFieldSummaryReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Estimated amount */
  estimatedAmount?: InputMaybe<SortDirection>;
  /** Estimated yield */
  estimatedYield?: InputMaybe<SortDirection>;
  /** Harvest yield */
  harvestYield?: InputMaybe<SortDirection>;
  /** Harvested amount */
  harvestedAmount?: InputMaybe<SortDirection>;
  /** Has Estimation */
  hasEstimation?: InputMaybe<SortDirection>;
  /** Inventory Cost */
  inventoryCost?: InputMaybe<SortDirection>;
  /** Labor Cost */
  laborCost?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Man-Days */
  manDays?: InputMaybe<SortDirection>;
  /** Total cost */
  totalCost?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of CropFieldUpdate. */
export type CropFieldUpdatePayload = {
  __typename?: "CropFieldUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropField>;
};

/** Crop field variety object */
export type CropFieldVariety = Discardable & {
  __typename?: "CropFieldVariety";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Crop variety */
  cropVariety: CropVariety;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Effective area */
  effectiveArea: Scalars["Float"];
  effectivePlants: Scalars["Int"];
  id: Scalars["ID"];
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  plantDensity: Scalars["Float"];
  plantedAt?: Maybe<Scalars["ISO8601Date"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** CropFieldVariety changes object */
export type CropFieldVarietyChanges = {
  /** Crop Variety */
  cropVarietyId?: InputMaybe<Scalars["ID"]>;
  /** Discard */
  discardedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  effectivePlants?: InputMaybe<Scalars["Int"]>;
  /** Crop field variety ID */
  id?: InputMaybe<Scalars["ID"]>;
  plantDensity?: InputMaybe<Scalars["Float"]>;
  plantedAt?: InputMaybe<Scalars["ISO8601Date"]>;
};

/** CropFieldVariety field values */
export type CropFieldVarietyFields = {
  /** Crop Variety */
  cropVarietyId: Scalars["ID"];
  effectivePlants: Scalars["Int"];
  plantDensity: Scalars["Float"];
  plantedAt?: InputMaybe<Scalars["ISO8601Date"]>;
};

/** Collection of CropFields */
export type CropFields = {
  __typename?: "CropFields";
  /** Paginated collection of CropFields */
  items: Array<CropField>;
  /** Total count of CropFields */
  totalCount: Scalars["Int"];
};

/** CropFields filter */
export type CropFieldsFilterInput = {
  /** Crop center id */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop */
  cropId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stage id */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Stage type */
  cropStageType?: InputMaybe<CropStageType>;
  /** Crop Variety */
  cropVarietyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Crop field zone */
  group?: InputMaybe<Array<Scalars["String"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Is field irrigated */
  irrigated?: InputMaybe<Scalars["Boolean"]>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field name */
  name?: InputMaybe<StringMatchInput>;
  /** Text query */
  query?: InputMaybe<Scalars["String"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** CropFields sorting options */
export type CropFieldsSortInput = {
  /** Crop field progress */
  actualProgress?: InputMaybe<SortDirection>;
  /** Elevation above the sea */
  altitude?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Sorts by crop stage name */
  cropStageName?: InputMaybe<SortDirection>;
  /** Sorts by the name of main (having most area) variety name */
  cropVarietyName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Effective area */
  effectiveArea?: InputMaybe<SortDirection>;
  /** Effective plants quantity */
  effectivePlants?: InputMaybe<SortDirection>;
  /** Estimated harvest amount */
  estimatedHarvestAmount?: InputMaybe<SortDirection>;
  /** Estimated plants quantity */
  estimatedPlants?: InputMaybe<SortDirection>;
  /** GeoJSON object */
  geoJson?: InputMaybe<SortDirection>;
  /** Crop field group */
  group?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Is field irrigated */
  irrigated?: InputMaybe<SortDirection>;
  /** Crop field name */
  name?: InputMaybe<SortDirection>;
  /** Crop field planned progress */
  plannedProgress?: InputMaybe<SortDirection>;
  /** Avg plant population density */
  plantDensity?: InputMaybe<SortDirection>;
  /** Field tags */
  tags?: InputMaybe<SortDirection>;
  /** Topographical area */
  totalArea?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Crop field values */
export type CropInputFields = {
  /** Crop common names */
  commonNames?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop common names in En locale */
  commonNamesEn?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop common names in Es locale */
  commonNamesEs?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop common names in Pt locale */
  commonNamesPt?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop common names in Ru locale */
  commonNamesRu?: InputMaybe<Array<Scalars["String"]>>;
  /** Is global? */
  global: Scalars["Boolean"];
  /** Is locked */
  locked: Scalars["Boolean"];
  /** Crop name */
  name: Scalars["String"];
  /** Crop name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Crop name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Crop name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Crop name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

/** Crop stage object */
export type CropStage = Discardable &
  Profitable & {
    __typename?: "CropStage";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Change date */
    changeDate?: Maybe<Scalars["ISO8601Date"]>;
    changeEvent: CropStageChangeEvent;
    /** Cost Center */
    costCenter?: Maybe<CostCenter>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Crop stage localities */
    cropStageLocalities: Array<CropStageLocality>;
    /** Crop stage crops */
    crops: Array<Crop>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** Crop stage type */
    kind: CropStageType;
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Stage name */
    name: Scalars["String"];
    /** Category name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Category name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Category name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Category name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Next crop stages */
    nextStages: Array<CropStage>;
    /** Previous crop stage */
    prevStage?: Maybe<CropStage>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Crop stage change type object */
export type CropStageChange = {
  __typename?: "CropStageChange";
  /** Crop Field */
  cropField: CropField;
  /** Next Stage */
  nextStage: CropStage;
};

export enum CropStageChangeEvent {
  /** Crop year */
  CropYear = "cropYear",
  /** Date */
  Date = "date",
  /** Manual */
  Manual = "manual",
}

/** CropStage changes object */
export type CropStageChanges = {
  /** Change date */
  changeDate?: InputMaybe<Scalars["ISO8601Date"]>;
  changeEvent?: InputMaybe<CropStageChangeEvent>;
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Crop stage crops */
  cropIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stage localities */
  cropStageLocalitiesAttributes?: InputMaybe<Array<CropStageLocalityChanges>>;
  /** Crop stage type */
  kind?: InputMaybe<CropStageType>;
  /** Stage name */
  name?: InputMaybe<Scalars["String"]>;
  /** Category name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Prev stage */
  prevStageId?: InputMaybe<Scalars["ID"]>;
  /** Reset all crop field CC */
  resetCostCenters?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CropStageCreate. */
export type CropStageCreatePayload = {
  __typename?: "CropStageCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropStage>;
};

/** Autogenerated return type of CropStageDestroy. */
export type CropStageDestroyPayload = {
  __typename?: "CropStageDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CropStageDiscard. */
export type CropStageDiscardPayload = {
  __typename?: "CropStageDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<CropStage>;
};

/** CropStage field values */
export type CropStageFields = {
  /** Change date */
  changeDate?: InputMaybe<Scalars["ISO8601Date"]>;
  changeEvent: CropStageChangeEvent;
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Crop stage crops */
  cropIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stage localities */
  cropStageLocalitiesAttributes?: InputMaybe<Array<CropStageLocalityFields>>;
  /** Crop stage type */
  kind: CropStageType;
  /** Stage name */
  name: Scalars["String"];
  /** Category name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Prev stage */
  prevStageId?: InputMaybe<Scalars["ID"]>;
};

/** Crop stage locality object */
export type CropStageLocality = Profitable & {
  __typename?: "CropStageLocality";
  /** Cost Center */
  costCenter?: Maybe<CostCenter>;
  id: Scalars["ID"];
  /** Locality */
  locality: Locality;
};

/** CropStageLocality changes object */
export type CropStageLocalityChanges = {
  /** Remove locality */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Crop stage locality ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Locality ID */
  localityId?: InputMaybe<Scalars["ID"]>;
};

/** CropStageLocality field values */
export type CropStageLocalityFields = {
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Locality ID */
  localityId: Scalars["ID"];
};

export enum CropStageType {
  /** Development */
  Development = "development",
  /** Production */
  Production = "production",
}

/** Autogenerated return type of CropStageUpdate. */
export type CropStageUpdatePayload = {
  __typename?: "CropStageUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropStage>;
};

/** Collection of CropStages */
export type CropStages = {
  __typename?: "CropStages";
  /** Paginated collection of CropStages */
  items: Array<CropStage>;
  /** Total count of CropStages */
  totalCount: Scalars["Int"];
};

/** CropStages filter */
export type CropStagesFilterInput = {
  /** Change date */
  changeDate?: InputMaybe<DateRangeInput>;
  changeEvent?: InputMaybe<Array<CropStageChangeEvent>>;
  /** Cost Centers */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crops */
  cropId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stage type */
  kind?: InputMaybe<Array<CropStageType>>;
  /** Localities */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Stage name */
  name?: InputMaybe<StringMatchInput>;
  /** Category name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** CropStages sorting options */
export type CropStagesSortInput = {
  /** Change date */
  changeDate?: InputMaybe<SortDirection>;
  changeEvent?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Crop stage type */
  kind?: InputMaybe<SortDirection>;
  /** Stage name */
  name?: InputMaybe<SortDirection>;
  /** Category name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of CropUnlock. */
export type CropUnlockPayload = {
  __typename?: "CropUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<Crop>;
};

/** Autogenerated return type of CropUpdate. */
export type CropUpdatePayload = {
  __typename?: "CropUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Crop>;
};

/** Collection of CropVarieties */
export type CropVarieties = {
  __typename?: "CropVarieties";
  /** Paginated collection of CropVarieties */
  items: Array<CropVariety>;
  /** Total count of CropVarieties */
  totalCount: Scalars["Int"];
};

/** CropVarieties filter */
export type CropVarietiesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop */
  cropId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  name?: InputMaybe<StringMatchInput>;
  scientificName?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** CropVarieties sorting options */
export type CropVarietiesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  scientificName?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Crop variety object */
export type CropVariety = Discardable & {
  __typename?: "CropVariety";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Crop */
  crop: Crop;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  name: Scalars["String"];
  scientificName?: Maybe<Scalars["String"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** CropVariety changes object */
export type CropVarietyChanges = {
  /** Crop */
  cropId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  scientificName?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of CropVarietyCreate. */
export type CropVarietyCreatePayload = {
  __typename?: "CropVarietyCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropVariety>;
};

/** Autogenerated return type of CropVarietyDestroy. */
export type CropVarietyDestroyPayload = {
  __typename?: "CropVarietyDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of CropVarietyDiscard. */
export type CropVarietyDiscardPayload = {
  __typename?: "CropVarietyDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<CropVariety>;
};

/** CropVariety field values */
export type CropVarietyFields = {
  /** Crop */
  cropId: Scalars["ID"];
  name: Scalars["String"];
  scientificName?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of CropVarietyUpdate. */
export type CropVarietyUpdatePayload = {
  __typename?: "CropVarietyUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<CropVariety>;
};

/** Collection of Crops */
export type Crops = {
  __typename?: "Crops";
  /** Paginated collection of Crops */
  items: Array<Crop>;
  /** Total count of Crops */
  totalCount: Scalars["Int"];
};

/** Crops filter */
export type CropsFilterInput = {
  /** Crop common names */
  commonNames?: InputMaybe<StringMatchInput>;
  /** Crop common names in En locale */
  commonNamesEn?: InputMaybe<StringMatchInput>;
  /** Crop common names in Es locale */
  commonNamesEs?: InputMaybe<StringMatchInput>;
  /** Crop common names in Pt locale */
  commonNamesPt?: InputMaybe<StringMatchInput>;
  /** Crop common names in Ru locale */
  commonNamesRu?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Crop name */
  name?: InputMaybe<StringMatchInput>;
  /** Crop name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Crop name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Crop name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Crop name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Crops sorting options */
export type CropsSortInput = {
  /** Crop common names */
  commonNames?: InputMaybe<SortDirection>;
  /** Crop common names in En locale */
  commonNamesEn?: InputMaybe<SortDirection>;
  /** Crop common names in Es locale */
  commonNamesEs?: InputMaybe<SortDirection>;
  /** Crop common names in Pt locale */
  commonNamesPt?: InputMaybe<SortDirection>;
  /** Crop common names in Ru locale */
  commonNamesRu?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Crop name */
  name?: InputMaybe<SortDirection>;
  /** Crop name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Crop name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Crop name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Crop name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Currency object */
export type Currency = {
  __typename?: "Currency";
  decimalMark: Scalars["String"];
  exponent: Scalars["Int"];
  htmlEntity: Scalars["String"];
  id: Scalars["ID"];
  isoCode: Scalars["String"];
  isoNumeric: Scalars["Int"];
  name: Scalars["String"];
  subunit: Scalars["String"];
  symbol: Scalars["String"];
  symbolFirst: Scalars["Boolean"];
  thousandsSeparator: Scalars["String"];
};

/** Current user object */
export type CurrentUser = Discardable &
  Timestamps & {
    __typename?: "CurrentUser";
    admin: Scalars["Boolean"];
    avatar?: Maybe<Scalars["Upload"]>;
    canDiscard: AuthorizationResult;
    canInvite: AuthorizationResult;
    canUpdate: AuthorizationResult;
    canUpdateLocalities: AuthorizationResult;
    canUpdateRoles: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    currentLocale: Scalars["String"];
    currentTenant: Tenant;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    email: Scalars["String"];
    /** @deprecated Removed from user */
    employee?: Maybe<Employee>;
    firstName?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    invitationAcceptedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    invitationSentAt?: Maybe<Scalars["ISO8601DateTime"]>;
    lastName?: Maybe<Scalars["String"]>;
    /** User localities */
    localities: Array<Locality>;
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    permissions: Permissions;
    roles: Array<Role>;
    settings: Scalars["JSON"];
    tenants: Array<Tenant>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    username?: Maybe<Scalars["String"]>;
  };

/** Current user object */
export type CurrentUserAvatarArgs = {
  variant?: InputMaybe<ImageVariant>;
};

/** Activity expense */
export type DashboardActivityExpense = {
  __typename?: "DashboardActivityExpense";
  /** Activity expenses */
  activities?: Maybe<Array<DashboardActivityExpense>>;
  /** Expenses */
  expenses: Scalars["Float"];
  /** Activity category name */
  name: Scalars["String"];
};

/** Crop field summary */
export type DashboardCropFieldSummary = {
  __typename?: "DashboardCropFieldSummary";
  /** Crop Field */
  cropField: CropField;
  /** Effective area */
  effectiveArea: Scalars["Float"];
  /** Effective plants */
  effectivePlants: Scalars["Int"];
};

/** Crop field summary */
export type DashboardCropFieldVarietySummary = {
  __typename?: "DashboardCropFieldVarietySummary";
  /** Ages */
  ages: Array<DashboardCropVarietyAge>;
  /** Crop Field */
  cropField: CropField;
  /** Effective area */
  effectiveArea: Scalars["Float"];
  /** Effective plants */
  effectivePlants: Scalars["Int"];
};

/** Crop stage summary */
export type DashboardCropStageSummary = {
  __typename?: "DashboardCropStageSummary";
  /** Crop Fields */
  cropFields: Array<DashboardCropFieldSummary>;
  /** Crop stage */
  cropStage: CropStage;
  /** Effective area */
  effectiveArea: Scalars["Float"];
  effectivePlants: Scalars["Int"];
};

/** Crop variety age */
export type DashboardCropVarietyAge = {
  __typename?: "DashboardCropVarietyAge";
  /** Age */
  age?: Maybe<Scalars["String"]>;
  /** Effective area */
  effectiveArea: Scalars["Float"];
  /** Effective plants */
  effectivePlants: Scalars["Int"];
};

/** Crop variety summary */
export type DashboardCropVarietySummary = {
  __typename?: "DashboardCropVarietySummary";
  /** Crop Fields */
  cropFields: Array<DashboardCropFieldVarietySummary>;
  /** Crop variety */
  cropVariety: CropVariety;
  /** Effective area */
  effectiveArea: Scalars["Float"];
  /** Effective plants */
  effectivePlants: Scalars["Int"];
};

/** Locality summary */
export type DashboardLocalitySummary = {
  __typename?: "DashboardLocalitySummary";
  /** Crop field count */
  cropFieldCount: Scalars["Int"];
  /** Effective area */
  effectiveArea: Scalars["Float"];
  /** Infrastructure count */
  infrastructureCount: Scalars["Int"];
  /** Locality */
  locality?: Maybe<Locality>;
  /** Machine count */
  machineCount: Scalars["Int"];
  /** Plant count */
  plantCount: Scalars["Int"];
  /** Worker count */
  workerCount: Scalars["Int"];
};

/** Dashboard Report result */
export type DashboardReport = {
  __typename?: "DashboardReport";
  /** Activity expenses */
  activityExpenses: Array<DashboardActivityExpense>;
  /** Crop cycle */
  cropCycleId?: Maybe<Scalars["ID"]>;
  /** Crop stage summary */
  cropStageSummary: Array<DashboardCropStageSummary>;
  /** Crop variety summary */
  cropVarietySummary: Array<DashboardCropVarietySummary>;
  /** Expenses */
  expenses: Array<ExpenseRecord>;
  /** Locality */
  localityId?: Maybe<Scalars["ID"]>;
  /** Locality summary */
  localitySummary: DashboardLocalitySummary;
  /** Count of awaiting inventory WOs */
  totalAwaitingInventoryWorkOrders: Scalars["Int"];
  /** Total expenses */
  totalExpenses: Scalars["Float"];
  /** Total machine hours */
  totalMachineHours: Scalars["Int"];
  /** Total man days */
  totalManDays: Scalars["Int"];
  /** Work order status summary */
  workOrderStatusSummary: Array<DashboardWorkOrderStatusSummary>;
  /** Work order type summary */
  workOrderTypeSummary: Array<DashboardWorkOrderTypeSummary>;
};

/** Work order status summary */
export type DashboardWorkOrderStatusSummary = {
  __typename?: "DashboardWorkOrderStatusSummary";
  /** Count */
  count: Scalars["Int"];
  /** Work order status */
  status: WorkOrderStatus;
};

/** Work order type summary */
export type DashboardWorkOrderTypeSummary = {
  __typename?: "DashboardWorkOrderTypeSummary";
  /** Count */
  count: Scalars["Int"];
  /** Work Order Category */
  workOrderCategory: WorkOrderCategory;
  /** Work order type */
  workOrderType: WorkOrderType;
};

/** Specifies date range */
export type DateRangeInput = {
  /** Date range */
  range?: InputMaybe<Array<Scalars["ISO8601DateTime"]>>;
  /** Range type */
  rangeType: DateRangeType;
};

export enum DateRangeType {
  /** Within 12 hours */
  H12 = "h12",
  /** Within 24 hours */
  H24 = "h24",
  /** This month */
  Month = "month",
  /** Select month period */
  MonthRange = "monthRange",
  /** Select date period */
  Range = "range",
  /** Today */
  Today = "today",
  /** This week */
  Week = "week",
  /** Yesterday */
  Yesterday = "yesterday",
}

export type DefaultCostCenter = {
  __typename?: "DefaultCostCenter";
  /** Cost Center */
  costCenter: CostCenter;
  id: Scalars["ID"];
  kind: Scalars["String"];
};

/** DefaultCostCenter changes object */
export type DefaultCostCenterChanges = {
  /** Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** ID */
  id?: InputMaybe<Scalars["ID"]>;
  kind?: InputMaybe<Scalars["String"]>;
};

/** Object that may be destroyed */
export type Destroyable = {
  /** May destroy? */
  mayDestroy: Scalars["Boolean"];
};

export enum DestroyableType {
  /** Account */
  Account = "account",
  /** Activity */
  Activity = "activity",
  /** Activity Category */
  ActivityCategory = "activityCategory",
  /** Adjustment Type */
  AdjustmentType = "adjustmentType",
  /** Bank */
  Bank = "bank",
  /** Bank Account */
  BankAccount = "bankAccount",
  /** Comment */
  Comment = "comment",
  /** Cost Center */
  CostCenter = "costCenter",
  /** Counterparty */
  Counterparty = "counterparty",
  /** Crop Cycle */
  CropCycle = "cropCycle",
  /** Crop Field */
  CropField = "cropField",
  /** Crop Stage */
  CropStage = "cropStage",
  /** Crop Variety */
  CropVariety = "cropVariety",
  /** Disease */
  Disease = "disease",
  /** Employee */
  Employee = "employee",
  /** Expense Category */
  ExpenseCategory = "expenseCategory",
  /** Expense Item */
  ExpenseItem = "expenseItem",
  /** Fiscal Year */
  FiscalYear = "fiscalYear",
  /** Harvest Estimate */
  HarvestEstimate = "harvestEstimate",
  /** Holiday */
  Holiday = "holiday",
  /** Holiday List */
  HolidayList = "holidayList",
  /** Infrastructure */
  Infrastructure = "infrastructure",
  /** Item */
  Item = "item",
  /** Item Category */
  ItemCategory = "itemCategory",
  /** Locality */
  Locality = "locality",
  /** Machine */
  Machine = "machine",
  /** Payroll Entry */
  PayrollEntry = "payrollEntry",
  /** Period */
  Period = "period",
  /** Position */
  Position = "position",
  /** Recipe */
  Recipe = "recipe",
  /** Salary Component */
  SalaryComponent = "salaryComponent",
  /** Salary Structure */
  SalaryStructure = "salaryStructure",
  /** Tax Component */
  TaxComponent = "taxComponent",
  /** Tax Plan */
  TaxPlan = "taxPlan",
  /** Variant */
  Variant = "variant",
  /** Warehouse */
  Warehouse = "warehouse",
  /** Weather Measurement */
  WeatherMeasurement = "weatherMeasurement",
}

/** Object that has creation/update/discard timestamps */
export type Discardable = {
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

export enum DiscardableType {
  /** Account */
  Account = "account",
  /** Account Kind */
  AccountKind = "accountKind",
  /** Activity */
  Activity = "activity",
  /** Activity Category */
  ActivityCategory = "activityCategory",
  /** Activity Payment */
  ActivityPayment = "activityPayment",
  /** Address */
  Address = "address",
  /** Adjustment Type */
  AdjustmentType = "adjustmentType",
  /** Bank */
  Bank = "bank",
  /** Bank Account */
  BankAccount = "bankAccount",
  /** Brand */
  Brand = "brand",
  /** Comment */
  Comment = "comment",
  /** Cost Center */
  CostCenter = "costCenter",
  /** Counterparty */
  Counterparty = "counterparty",
  /** Crop */
  Crop = "crop",
  /** Crop Cycle */
  CropCycle = "cropCycle",
  /** Crop Field */
  CropField = "cropField",
  /** Crop Field Note */
  CropFieldNote = "cropFieldNote",
  /** Crop Field Variety */
  CropFieldVariety = "cropFieldVariety",
  /** Crop Stage */
  CropStage = "cropStage",
  /** Crop Variety */
  CropVariety = "cropVariety",
  /** Disease */
  Disease = "disease",
  /** Employee */
  Employee = "employee",
  /** Employee Group */
  EmployeeGroup = "employeeGroup",
  /** Equipment */
  Equipment = "equipment",
  /** Expense Category */
  ExpenseCategory = "expenseCategory",
  /** Expense Item */
  ExpenseItem = "expenseItem",
  /** Infrastructure */
  Infrastructure = "infrastructure",
  /** Infrastructure Kind */
  InfrastructureKind = "infrastructureKind",
  /** Ingredient */
  Ingredient = "ingredient",
  /** Item */
  Item = "item",
  /** Item Category */
  ItemCategory = "itemCategory",
  /** Leave Type */
  LeaveType = "leaveType",
  /** Locality */
  Locality = "locality",
  /** Machine */
  Machine = "machine",
  /** Machine Kind */
  MachineKind = "machineKind",
  /** Metric */
  Metric = "metric",
  /** Payment */
  Payment = "payment",
  /** Payment Method */
  PaymentMethod = "paymentMethod",
  /** Position */
  Position = "position",
  /** Recipe */
  Recipe = "recipe",
  /** Role */
  Role = "role",
  /** Salary Component */
  SalaryComponent = "salaryComponent",
  /** Salary Structure */
  SalaryStructure = "salaryStructure",
  /** Salary Structure Assignment */
  SalaryStructureAssignment = "salaryStructureAssignment",
  /** Sample Disease */
  SampleDisease = "sampleDisease",
  /** Tenant */
  Tenant = "tenant",
  /** Tenant User */
  TenantUser = "tenantUser",
  /** Token */
  Token = "token",
  /** Unit */
  Unit = "unit",
  /** Variant */
  Variant = "variant",
  /** Warehouse */
  Warehouse = "warehouse",
  /** Water Source */
  WaterSource = "waterSource",
}

/** Disease object */
export type Disease = Destroyable &
  Discardable &
  Global & {
    __typename?: "Disease";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Applied crops */
    crops: Array<Crop>;
    /** Default source */
    defaultSource: DiseaseSource;
    /** Description */
    description?: Maybe<Scalars["String"]>;
    /** Decease description in En locale */
    descriptionEn?: Maybe<Scalars["String"]>;
    /** Decease description in Es locale */
    descriptionEs?: Maybe<Scalars["String"]>;
    /** Decease description in Pt locale */
    descriptionPt?: Maybe<Scalars["String"]>;
    /** Decease description in Ru locale */
    descriptionRu?: Maybe<Scalars["String"]>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    images?: Maybe<Array<Scalars["Upload"]>>;
    /** Disease type */
    kind: DiseaseType;
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Disease name */
    name: Scalars["String"];
    /** Disease name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Disease name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Disease name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Disease name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    riskMap: DiseaseRisk;
    /** Scientific name */
    scientificName?: Maybe<Scalars["String"]>;
    /** Thumbnail */
    thumbnail?: Maybe<Scalars["String"]>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Disease changes object */
export type DiseaseChanges = {
  /** Crop */
  cropIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Default source */
  defaultSource?: InputMaybe<DiseaseSource>;
  /** Description */
  description?: InputMaybe<Scalars["String"]>;
  /** Decease description in En locale */
  descriptionEn?: InputMaybe<Scalars["String"]>;
  /** Decease description in Es locale */
  descriptionEs?: InputMaybe<Scalars["String"]>;
  /** Decease description in Pt locale */
  descriptionPt?: InputMaybe<Scalars["String"]>;
  /** Decease description in Ru locale */
  descriptionRu?: InputMaybe<Scalars["String"]>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Disease type */
  kind?: InputMaybe<DiseaseType>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Disease name */
  name?: InputMaybe<Scalars["String"]>;
  /** Disease name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Disease name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Disease name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Disease name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** New images */
  newFiles?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Images to delete */
  purgeFiles?: InputMaybe<Array<Scalars["ID"]>>;
  /** Scientific name */
  scientificName?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of DiseaseCreate. */
export type DiseaseCreatePayload = {
  __typename?: "DiseaseCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Disease>;
};

/** Autogenerated return type of DiseaseDestroy. */
export type DiseaseDestroyPayload = {
  __typename?: "DiseaseDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of DiseaseDiscard. */
export type DiseaseDiscardPayload = {
  __typename?: "DiseaseDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Disease>;
};

/** Disease field values */
export type DiseaseFields = {
  /** Crop */
  cropIds: Array<Scalars["ID"]>;
  /** Default source */
  defaultSource: DiseaseSource;
  /** Description */
  description?: InputMaybe<Scalars["String"]>;
  /** Decease description in En locale */
  descriptionEn?: InputMaybe<Scalars["String"]>;
  /** Decease description in Es locale */
  descriptionEs?: InputMaybe<Scalars["String"]>;
  /** Decease description in Pt locale */
  descriptionPt?: InputMaybe<Scalars["String"]>;
  /** Decease description in Ru locale */
  descriptionRu?: InputMaybe<Scalars["String"]>;
  /** Is global? */
  global: Scalars["Boolean"];
  images?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Disease type */
  kind: DiseaseType;
  /** Is locked */
  locked: Scalars["Boolean"];
  /** Disease name */
  name: Scalars["String"];
  /** Disease name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Disease name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Disease name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Disease name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Risk map */
  riskMapAttributes: DiseaseRiskFields;
  /** Scientific name */
  scientificName?: InputMaybe<Scalars["String"]>;
};

/** Disease risk map object */
export type DiseaseRisk = {
  __typename?: "DiseaseRisk";
  /** High range */
  high: Scalars["Int"];
  /** High risk recommendation */
  highRecommendation?: Maybe<Scalars["String"]>;
  /** High Recommendation in En locale */
  highRecommendationEn?: Maybe<Scalars["String"]>;
  /** High Recommendation in Es locale */
  highRecommendationEs?: Maybe<Scalars["String"]>;
  /** High Recommendation in Pt locale */
  highRecommendationPt?: Maybe<Scalars["String"]>;
  /** High Recommendation in Ru locale */
  highRecommendationRu?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Low range */
  low: Scalars["Int"];
  /** Low risk recommendation */
  lowRecommendation?: Maybe<Scalars["String"]>;
  /** Low Recommendation in En locale */
  lowRecommendationEn?: Maybe<Scalars["String"]>;
  /** Low Recommendation in Es locale */
  lowRecommendationEs?: Maybe<Scalars["String"]>;
  /** Low Recommendation in Pt locale */
  lowRecommendationPt?: Maybe<Scalars["String"]>;
  /** Low Recommendation in Ru locale */
  lowRecommendationRu?: Maybe<Scalars["String"]>;
  /** Medium risk recommendation */
  mediumRecommendation?: Maybe<Scalars["String"]>;
  /** Medium Recommendation in En locale */
  mediumRecommendationEn?: Maybe<Scalars["String"]>;
  /** Medium Recommendation in Es locale */
  mediumRecommendationEs?: Maybe<Scalars["String"]>;
  /** Medium Recommendation in Pt locale */
  mediumRecommendationPt?: Maybe<Scalars["String"]>;
  /** Medium Recommendation in Ru locale */
  mediumRecommendationRu?: Maybe<Scalars["String"]>;
};

/** DiseaseRisk changes object */
export type DiseaseRiskChanges = {
  /** High range */
  high?: InputMaybe<Scalars["Int"]>;
  /** High risk recommendation */
  highRecommendation?: InputMaybe<Scalars["String"]>;
  /** High Recommendation in En locale */
  highRecommendationEn?: InputMaybe<Scalars["String"]>;
  /** High Recommendation in Es locale */
  highRecommendationEs?: InputMaybe<Scalars["String"]>;
  /** High Recommendation in Pt locale */
  highRecommendationPt?: InputMaybe<Scalars["String"]>;
  /** High Recommendation in Ru locale */
  highRecommendationRu?: InputMaybe<Scalars["String"]>;
  /** Low range */
  low?: InputMaybe<Scalars["Int"]>;
  /** Low risk recommendation */
  lowRecommendation?: InputMaybe<Scalars["String"]>;
  /** Low Recommendation in En locale */
  lowRecommendationEn?: InputMaybe<Scalars["String"]>;
  /** Low Recommendation in Es locale */
  lowRecommendationEs?: InputMaybe<Scalars["String"]>;
  /** Low Recommendation in Pt locale */
  lowRecommendationPt?: InputMaybe<Scalars["String"]>;
  /** Low Recommendation in Ru locale */
  lowRecommendationRu?: InputMaybe<Scalars["String"]>;
  /** Medium risk recommendation */
  mediumRecommendation?: InputMaybe<Scalars["String"]>;
  /** Medium Recommendation in En locale */
  mediumRecommendationEn?: InputMaybe<Scalars["String"]>;
  /** Medium Recommendation in Es locale */
  mediumRecommendationEs?: InputMaybe<Scalars["String"]>;
  /** Medium Recommendation in Pt locale */
  mediumRecommendationPt?: InputMaybe<Scalars["String"]>;
  /** Medium Recommendation in Ru locale */
  mediumRecommendationRu?: InputMaybe<Scalars["String"]>;
};

/** DiseaseRisk field values */
export type DiseaseRiskFields = {
  /** High range */
  high: Scalars["Int"];
  /** High risk recommendation */
  highRecommendation?: InputMaybe<Scalars["String"]>;
  /** High Recommendation in En locale */
  highRecommendationEn?: InputMaybe<Scalars["String"]>;
  /** High Recommendation in Es locale */
  highRecommendationEs?: InputMaybe<Scalars["String"]>;
  /** High Recommendation in Pt locale */
  highRecommendationPt?: InputMaybe<Scalars["String"]>;
  /** High Recommendation in Ru locale */
  highRecommendationRu?: InputMaybe<Scalars["String"]>;
  /** Low range */
  low: Scalars["Int"];
  /** Low risk recommendation */
  lowRecommendation?: InputMaybe<Scalars["String"]>;
  /** Low Recommendation in En locale */
  lowRecommendationEn?: InputMaybe<Scalars["String"]>;
  /** Low Recommendation in Es locale */
  lowRecommendationEs?: InputMaybe<Scalars["String"]>;
  /** Low Recommendation in Pt locale */
  lowRecommendationPt?: InputMaybe<Scalars["String"]>;
  /** Low Recommendation in Ru locale */
  lowRecommendationRu?: InputMaybe<Scalars["String"]>;
  /** Medium risk recommendation */
  mediumRecommendation?: InputMaybe<Scalars["String"]>;
  /** Medium Recommendation in En locale */
  mediumRecommendationEn?: InputMaybe<Scalars["String"]>;
  /** Medium Recommendation in Es locale */
  mediumRecommendationEs?: InputMaybe<Scalars["String"]>;
  /** Medium Recommendation in Pt locale */
  mediumRecommendationPt?: InputMaybe<Scalars["String"]>;
  /** Medium Recommendation in Ru locale */
  mediumRecommendationRu?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of DiseaseRiskUpdate. */
export type DiseaseRiskUpdatePayload = {
  __typename?: "DiseaseRiskUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<DiseaseRisk>;
};

export enum DiseaseSource {
  /** Branches */
  Branches = "branches",
  /** Flowers */
  Flowers = "flowers",
  /** Fruits */
  Fruits = "fruits",
  /** Leaves */
  Leaves = "leaves",
  /** Other */
  Other = "other",
  /** Roots */
  Roots = "roots",
  /** Soil */
  Soil = "soil",
  /** Stem */
  Stem = "stem",
}

export enum DiseaseType {
  /** Bacteria */
  Bacteria = "bacteria",
  /** Fungus */
  Fungus = "fungus",
  /** Insect */
  Insect = "insect",
  /** Mite */
  Mite = "mite",
  /** Nematode */
  Nematode = "nematode",
  /** Other */
  Other = "other",
  /** Virus */
  Virus = "virus",
  /** Weed */
  Weed = "weed",
}

/** Autogenerated return type of DiseaseUnlock. */
export type DiseaseUnlockPayload = {
  __typename?: "DiseaseUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<Disease>;
};

/** Autogenerated return type of DiseaseUpdate. */
export type DiseaseUpdatePayload = {
  __typename?: "DiseaseUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Disease>;
};

/** Collection of Diseases */
export type Diseases = {
  __typename?: "Diseases";
  /** Paginated collection of Diseases */
  items: Array<Disease>;
  /** Total count of Diseases */
  totalCount: Scalars["Int"];
};

/** Diseases filter */
export type DiseasesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop */
  cropId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Default source */
  defaultSource?: InputMaybe<Array<DiseaseSource>>;
  /** Description */
  description?: InputMaybe<StringMatchInput>;
  /** Decease description in En locale */
  descriptionEn?: InputMaybe<StringMatchInput>;
  /** Decease description in Es locale */
  descriptionEs?: InputMaybe<StringMatchInput>;
  /** Decease description in Pt locale */
  descriptionPt?: InputMaybe<StringMatchInput>;
  /** Decease description in Ru locale */
  descriptionRu?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Disease type */
  kind?: InputMaybe<Array<DiseaseType>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Disease name */
  name?: InputMaybe<StringMatchInput>;
  /** Disease name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Disease name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Disease name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Disease name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Scientific name */
  scientificName?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Thumbnail */
  thumbnail?: InputMaybe<StringMatchInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Diseases sorting options */
export type DiseasesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Default source */
  defaultSource?: InputMaybe<SortDirection>;
  /** Description */
  description?: InputMaybe<SortDirection>;
  /** Decease description in En locale */
  descriptionEn?: InputMaybe<SortDirection>;
  /** Decease description in Es locale */
  descriptionEs?: InputMaybe<SortDirection>;
  /** Decease description in Pt locale */
  descriptionPt?: InputMaybe<SortDirection>;
  /** Decease description in Ru locale */
  descriptionRu?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  images?: InputMaybe<SortDirection>;
  /** Disease type */
  kind?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Disease name */
  name?: InputMaybe<SortDirection>;
  /** Disease name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Disease name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Disease name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Disease name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Scientific name */
  scientificName?: InputMaybe<SortDirection>;
  /** Thumbnail */
  thumbnail?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Employee object */
export type Employee = Discardable & {
  __typename?: "Employee";
  /** Employee address */
  address: Address;
  /** Employee bank account */
  bankAccount?: Maybe<BankAccount>;
  basePayment?: Maybe<ActivityPayment>;
  /** Employee birthday */
  birthdate?: Maybe<Scalars["ISO8601Date"]>;
  conflictUsage?: Maybe<TimeConflict>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Drivers License ID Number */
  driverLicenseId?: Maybe<Scalars["String"]>;
  /** Employee Groups */
  employeeGroups: Array<EmployeeGroup>;
  /** First Name */
  firstName: Scalars["String"];
  /** Full Name */
  fullName: Scalars["String"];
  gender: Gender;
  id: Scalars["ID"];
  /** Internal ID */
  internalId?: Maybe<Scalars["String"]>;
  /** Last Name */
  lastName: Scalars["String"];
  /** Leave assignment */
  leave?: Maybe<LeaveAssignmentEmployee>;
  /** Employee locality */
  localities: Array<Locality>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** National ID Number */
  nationalId?: Maybe<Scalars["String"]>;
  /** Formatted National ID Number */
  nationalIdFormatted?: Maybe<Scalars["String"]>;
  /** Employee position */
  position: Position;
  /** Assigned Salary Structure */
  salaryStructure?: Maybe<SalaryStructure>;
  /** Social Security Number */
  socialSecurityNumber?: Maybe<Scalars["String"]>;
  /** Tax Number ID Number */
  taxIdNumber?: Maybe<Scalars["String"]>;
  /** Formatted Tax Number ID Number */
  taxIdNumberFormatted?: Maybe<Scalars["String"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /**
   * User
   * @deprecated Removed from employee
   */
  user?: Maybe<User>;
  /** Employee wage payment */
  wagePayment?: Maybe<ActivityPayment>;
};

/** Employee object */
export type EmployeeConflictUsageArgs = {
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** Employee object */
export type EmployeeLeaveArgs = {
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** Employee object */
export type EmployeeWagePaymentArgs = {
  wagePaymentFilter?: InputMaybe<EmployeeWageFilterInput>;
};

/** Autogenerated return type of EmployeeBulkBulkUpdate. */
export type EmployeeBulkBulkUpdatePayload = {
  __typename?: "EmployeeBulkBulkUpdatePayload";
  result: Array<BulkOperationResult>;
};

/** EmployeeBulk changes object */
export type EmployeeBulkChanges = {
  /** Salary Structure */
  salaryStructureId?: InputMaybe<Scalars["ID"]>;
};

/** Employee changes object */
export type EmployeeChanges = {
  /** Address */
  addressAttributes?: InputMaybe<AddressChanges>;
  /** Bank Account */
  bankAccountAttributes?: InputMaybe<BankAccountChanges>;
  /** Base daily wage */
  baseWage?: InputMaybe<Scalars["Float"]>;
  /** Employee birthday */
  birthdate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Drivers License ID Number */
  driverLicenseId?: InputMaybe<Scalars["String"]>;
  /** First Name */
  firstName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Gender>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Last Name */
  lastName?: InputMaybe<Scalars["String"]>;
  /** Employee localities */
  localityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** National ID Number */
  nationalId?: InputMaybe<Scalars["String"]>;
  /** Employee position */
  positionId?: InputMaybe<Scalars["ID"]>;
  /** Salary Structure */
  salaryStructureId?: InputMaybe<Scalars["ID"]>;
  /** Social Security Number */
  socialSecurityNumber?: InputMaybe<Scalars["String"]>;
  /** Tax Number ID Number */
  taxIdNumber?: InputMaybe<Scalars["String"]>;
  /** Employee user */
  userId?: InputMaybe<Scalars["ID"]>;
  /** Wage unit */
  wageUnit?: InputMaybe<WorkUnit>;
};

/** Autogenerated return type of EmployeeCreate. */
export type EmployeeCreatePayload = {
  __typename?: "EmployeeCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Employee>;
};

/** Autogenerated return type of EmployeeDestroy. */
export type EmployeeDestroyPayload = {
  __typename?: "EmployeeDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of EmployeeDiscard. */
export type EmployeeDiscardPayload = {
  __typename?: "EmployeeDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Employee>;
};

/** Employee field values */
export type EmployeeFields = {
  /** Address */
  addressAttributes: AddressFields;
  /** Base wage */
  baseWage?: InputMaybe<Scalars["Float"]>;
  /** Employee birthday */
  birthdate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Drivers License ID Number */
  driverLicenseId?: InputMaybe<Scalars["String"]>;
  /** First Name */
  firstName: Scalars["String"];
  gender: Gender;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Last Name */
  lastName: Scalars["String"];
  /** Employee localities */
  localityIds: Array<Scalars["ID"]>;
  /** National ID Number */
  nationalId?: InputMaybe<Scalars["String"]>;
  /** Employee position */
  positionId: Scalars["ID"];
  /** Salary Structure */
  salaryStructureId?: InputMaybe<Scalars["ID"]>;
  /** Social Security Number */
  socialSecurityNumber?: InputMaybe<Scalars["String"]>;
  /** Tax Number ID Number */
  taxIdNumber?: InputMaybe<Scalars["String"]>;
  /** Employee user */
  userId?: InputMaybe<Scalars["ID"]>;
  /** Wage unit */
  wageUnit?: InputMaybe<WorkUnit>;
};

/** Employee group object */
export type EmployeeGroup = Discardable & {
  __typename?: "EmployeeGroup";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Group employees */
  employees: Array<Employee>;
  /** Group employees count */
  employeesCount: Scalars["Int"];
  id: Scalars["ID"];
  /** Employee group locality */
  locality: Locality;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Employee group name */
  name: Scalars["String"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** EmployeeGroup changes object */
export type EmployeeGroupChanges = {
  /** Group employees */
  employeeIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Employee locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Employee group name */
  name?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of EmployeeGroupCreate. */
export type EmployeeGroupCreatePayload = {
  __typename?: "EmployeeGroupCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<EmployeeGroup>;
};

/** Autogenerated return type of EmployeeGroupDiscard. */
export type EmployeeGroupDiscardPayload = {
  __typename?: "EmployeeGroupDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<EmployeeGroup>;
};

/** EmployeeGroup field values */
export type EmployeeGroupFields = {
  /** Group employees */
  employeeIds: Array<Scalars["ID"]>;
  /** Employee locality */
  localityId: Scalars["ID"];
  /** Employee group name */
  name: Scalars["String"];
};

/** Autogenerated return type of EmployeeGroupUpdate. */
export type EmployeeGroupUpdatePayload = {
  __typename?: "EmployeeGroupUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<EmployeeGroup>;
};

/** Collection of EmployeeGroups */
export type EmployeeGroups = {
  __typename?: "EmployeeGroups";
  /** Paginated collection of EmployeeGroups */
  items: Array<EmployeeGroup>;
  /** Total count of EmployeeGroups */
  totalCount: Scalars["Int"];
};

/** EmployeeGroups filter */
export type EmployeeGroupsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Group employees count */
  employeesCount?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Employee group locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Employee group name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** EmployeeGroups sorting options */
export type EmployeeGroupsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Group employees count */
  employeesCount?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Employee group name */
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of EmployeeUpdate. */
export type EmployeeUpdatePayload = {
  __typename?: "EmployeeUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Employee>;
};

/** Employee wage filter */
export type EmployeeWageFilterInput = {
  activityId?: InputMaybe<Scalars["ID"]>;
  cropIds?: InputMaybe<Array<Scalars["ID"]>>;
  localityId?: InputMaybe<Scalars["ID"]>;
};

/** Collection of Employees */
export type Employees = {
  __typename?: "Employees";
  /** Paginated collection of Employees */
  items: Array<Employee>;
  /** Total count of Employees */
  totalCount: Scalars["Int"];
};

/** Employees filter */
export type EmployeesFilterInput = {
  /** Employee birthday */
  birthdate?: InputMaybe<DateRangeInput>;
  /** Country code */
  countryCode?: InputMaybe<Array<Scalars["String"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Drivers License ID Number */
  driverLicenseId?: InputMaybe<StringMatchInput>;
  /** First Name */
  firstName?: InputMaybe<StringMatchInput>;
  /** Employee full name */
  fullName?: InputMaybe<Scalars["String"]>;
  gender?: InputMaybe<Array<Gender>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Last Name */
  lastName?: InputMaybe<StringMatchInput>;
  /** Employee localities */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** National ID Number */
  nationalId?: InputMaybe<StringMatchInput>;
  /** Formatted National ID Number */
  nationalIdFormatted?: InputMaybe<StringMatchInput>;
  /** Employee positions */
  positionId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Position type */
  positionType?: InputMaybe<Array<PositionType>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Social Security Number */
  socialSecurityNumber?: InputMaybe<StringMatchInput>;
  /** Tax Number ID Number */
  taxIdNumber?: InputMaybe<StringMatchInput>;
  /** Formatted Tax Number ID Number */
  taxIdNumberFormatted?: InputMaybe<StringMatchInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Employees sorting options */
export type EmployeesSortInput = {
  /** Employee birthday */
  birthdate?: InputMaybe<SortDirection>;
  /** Sort by country code */
  countryCode?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Drivers License ID Number */
  driverLicenseId?: InputMaybe<SortDirection>;
  /** First Name */
  firstName?: InputMaybe<SortDirection>;
  /** Full Name */
  fullName?: InputMaybe<SortDirection>;
  gender?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Last Name */
  lastName?: InputMaybe<SortDirection>;
  /** National ID Number */
  nationalId?: InputMaybe<SortDirection>;
  /** Formatted National ID Number */
  nationalIdFormatted?: InputMaybe<SortDirection>;
  /** Sort by position name */
  positionName?: InputMaybe<SortDirection>;
  /** Social Security Number */
  socialSecurityNumber?: InputMaybe<SortDirection>;
  /** Tax Number ID Number */
  taxIdNumber?: InputMaybe<SortDirection>;
  /** Formatted Tax Number ID Number */
  taxIdNumberFormatted?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Finance entry cost center */
export type EntryCostCenter = HasProfitable & {
  __typename?: "EntryCostCenter";
  amount: Scalars["Float"];
  /** Cost Center */
  costCenter: CostCenter;
  /** Locality */
  locality?: Maybe<Locality>;
  /** Profitable extra info */
  profitableExtra?: Maybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: Maybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: Maybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: Maybe<ProfitableType>;
};

export type Equipment = Discardable &
  Global & {
    __typename?: "Equipment";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    name: Scalars["String"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Collection of EquipmentList */
export type EquipmentList = {
  __typename?: "EquipmentList";
  /** Paginated collection of EquipmentList */
  items: Array<Equipment>;
  /** Total count of EquipmentList */
  totalCount: Scalars["Int"];
};

/** EquipmentList filter */
export type EquipmentListFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** EquipmentList sorting options */
export type EquipmentListSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Collection of ExpenseCategories */
export type ExpenseCategories = {
  __typename?: "ExpenseCategories";
  /** Paginated collection of ExpenseCategories */
  items: Array<ExpenseCategory>;
  /** Total count of ExpenseCategories */
  totalCount: Scalars["Int"];
};

/** ExpenseCategories filter */
export type ExpenseCategoriesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Category name */
  name?: InputMaybe<StringMatchInput>;
  /** Category name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Parent category */
  parentCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ExpenseCategories sorting options */
export type ExpenseCategoriesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Category name */
  name?: InputMaybe<SortDirection>;
  /** Category name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Expense Category object */
export type ExpenseCategory = Discardable &
  Global & {
    __typename?: "ExpenseCategory";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Expense debit account */
    expenseAccount?: Maybe<Account>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Category name */
    name: Scalars["String"];
    /** Category name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Category name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Category name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Category name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Parent category */
    parentCategory?: Maybe<ExpenseCategory>;
    /** Subcategories */
    subcategories: Array<ExpenseCategory>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** ExpenseCategory changes object */
export type ExpenseCategoryChanges = {
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Category name */
  name?: InputMaybe<Scalars["String"]>;
  /** Category name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Parent category */
  parentCategoryId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of ExpenseCategoryCreate. */
export type ExpenseCategoryCreatePayload = {
  __typename?: "ExpenseCategoryCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<ExpenseCategory>;
};

/** Autogenerated return type of ExpenseCategoryDestroy. */
export type ExpenseCategoryDestroyPayload = {
  __typename?: "ExpenseCategoryDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of ExpenseCategoryDiscard. */
export type ExpenseCategoryDiscardPayload = {
  __typename?: "ExpenseCategoryDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<ExpenseCategory>;
};

/** ExpenseCategory field values */
export type ExpenseCategoryFields = {
  /** Is global? */
  global: Scalars["Boolean"];
  /** Is locked */
  locked: Scalars["Boolean"];
  /** Category name */
  name: Scalars["String"];
  /** Category name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Parent category */
  parentCategoryId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of ExpenseCategoryUnlock. */
export type ExpenseCategoryUnlockPayload = {
  __typename?: "ExpenseCategoryUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<ExpenseCategory>;
};

/** Autogenerated return type of ExpenseCategoryUpdate. */
export type ExpenseCategoryUpdatePayload = {
  __typename?: "ExpenseCategoryUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<ExpenseCategory>;
};

/** Collection of ExpenseInventoryReport */
export type ExpenseInventoryReport = {
  __typename?: "ExpenseInventoryReport";
  /** Paginated collection of ExpenseInventoryReport */
  items: Array<ExpenseRecord>;
  /** Total count of ExpenseInventoryReport */
  totalCount: Scalars["Int"];
};

/** ExpenseInventoryReport filter */
export type ExpenseInventoryReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle ID */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Record ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Parent ID */
  parentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Expense subject */
  subject?: InputMaybe<StringMatchInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ExpenseInventoryReport sorting options */
export type ExpenseInventoryReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Record ID */
  id?: InputMaybe<SortDirection>;
  /** Parent ID */
  parentId?: InputMaybe<SortDirection>;
  /** Planned value */
  planned?: InputMaybe<SortDirection>;
  /** Expense percentage */
  rate?: InputMaybe<SortDirection>;
  /** Expense subject */
  subject?: InputMaybe<SortDirection>;
  /** Total value */
  total?: InputMaybe<SortDirection>;
};

/** Expense Item */
export type ExpenseItem = Discardable & {
  __typename?: "ExpenseItem";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Item description */
  description?: Maybe<Scalars["String"]>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Expense Category */
  expenseCategory: ExpenseCategory;
  id: Scalars["ID"];
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Item name */
  name: Scalars["String"];
  /** Item name in En locale */
  nameEn?: Maybe<Scalars["String"]>;
  /** Item name in Es locale */
  nameEs?: Maybe<Scalars["String"]>;
  /** Item name in Pt locale */
  namePt?: Maybe<Scalars["String"]>;
  /** Item name in Ru locale */
  nameRu?: Maybe<Scalars["String"]>;
  /** Assigned Tax Plan */
  taxPlan?: Maybe<TaxPlan>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** ExpenseItem changes object */
export type ExpenseItemChanges = {
  /** Item description */
  description?: InputMaybe<Scalars["String"]>;
  /** Expense category */
  expenseCategoryId?: InputMaybe<Scalars["ID"]>;
  /** Item name */
  name?: InputMaybe<Scalars["String"]>;
  /** Item name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Item name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Item name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Item name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Item tax plan */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of ExpenseItemCreate. */
export type ExpenseItemCreatePayload = {
  __typename?: "ExpenseItemCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<ExpenseItem>;
};

/** Autogenerated return type of ExpenseItemDestroy. */
export type ExpenseItemDestroyPayload = {
  __typename?: "ExpenseItemDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of ExpenseItemDiscard. */
export type ExpenseItemDiscardPayload = {
  __typename?: "ExpenseItemDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<ExpenseItem>;
};

/** ExpenseItem field values */
export type ExpenseItemFields = {
  /** Item description */
  description?: InputMaybe<Scalars["String"]>;
  /** Expense category */
  expenseCategoryId: Scalars["ID"];
  /** Item name */
  name: Scalars["String"];
  /** Item name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Item name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Item name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Item name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Item tax plan */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of ExpenseItemUpdate. */
export type ExpenseItemUpdatePayload = {
  __typename?: "ExpenseItemUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<ExpenseItem>;
};

/** Collection of ExpenseItems */
export type ExpenseItems = {
  __typename?: "ExpenseItems";
  /** Paginated collection of ExpenseItems */
  items: Array<ExpenseItem>;
  /** Total count of ExpenseItems */
  totalCount: Scalars["Int"];
};

/** ExpenseItems filter */
export type ExpenseItemsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item description */
  description?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Expense category */
  expenseCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item name */
  name?: InputMaybe<StringMatchInput>;
  /** Item name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Item name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Item name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Item name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ExpenseItems sorting options */
export type ExpenseItemsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Item description */
  description?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Item name */
  name?: InputMaybe<SortDirection>;
  /** Item name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Item name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Item name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Item name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Expense Report result */
export type ExpenseRecord = {
  __typename?: "ExpenseRecord";
  /** Expenses */
  columns: Array<Column>;
  /** Record ID */
  id: Scalars["ID"];
  /** Parent ID */
  parentId?: Maybe<Scalars["ID"]>;
  /** Planned value */
  planned?: Maybe<Scalars["Float"]>;
  /** Expense percentage */
  rate: Scalars["Float"];
  /** Expense subject */
  subject: Scalars["String"];
  /** Total value */
  total: Scalars["Float"];
};

/** Collection of ExpenseReport */
export type ExpenseReport = {
  __typename?: "ExpenseReport";
  /** Paginated collection of ExpenseReport */
  items: Array<ExpenseRecord>;
  /** Total count of ExpenseReport */
  totalCount: Scalars["Int"];
};

/** ExpenseReport filter */
export type ExpenseReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle ID */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Record ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Parent ID */
  parentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Expense subject */
  subject?: InputMaybe<StringMatchInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ExpenseReport sorting options */
export type ExpenseReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Record ID */
  id?: InputMaybe<SortDirection>;
  /** Parent ID */
  parentId?: InputMaybe<SortDirection>;
  /** Planned value */
  planned?: InputMaybe<SortDirection>;
  /** Expense percentage */
  rate?: InputMaybe<SortDirection>;
  /** Expense subject */
  subject?: InputMaybe<SortDirection>;
  /** Total value */
  total?: InputMaybe<SortDirection>;
};

/** Collection of ExpenseSalaryReport */
export type ExpenseSalaryReport = {
  __typename?: "ExpenseSalaryReport";
  /** Paginated collection of ExpenseSalaryReport */
  items: Array<ExpenseRecord>;
  /** Total count of ExpenseSalaryReport */
  totalCount: Scalars["Int"];
};

/** ExpenseSalaryReport filter */
export type ExpenseSalaryReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle ID */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Record ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Parent ID */
  parentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Expense subject */
  subject?: InputMaybe<StringMatchInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ExpenseSalaryReport sorting options */
export type ExpenseSalaryReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Record ID */
  id?: InputMaybe<SortDirection>;
  /** Parent ID */
  parentId?: InputMaybe<SortDirection>;
  /** Planned value */
  planned?: InputMaybe<SortDirection>;
  /** Expense percentage */
  rate?: InputMaybe<SortDirection>;
  /** Expense subject */
  subject?: InputMaybe<SortDirection>;
  /** Total value */
  total?: InputMaybe<SortDirection>;
};

/** Collection of ExpenseServiceReport */
export type ExpenseServiceReport = {
  __typename?: "ExpenseServiceReport";
  /** Paginated collection of ExpenseServiceReport */
  items: Array<ExpenseRecord>;
  /** Total count of ExpenseServiceReport */
  totalCount: Scalars["Int"];
};

/** ExpenseServiceReport filter */
export type ExpenseServiceReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle ID */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Record ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Parent ID */
  parentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Expense subject */
  subject?: InputMaybe<StringMatchInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ExpenseServiceReport sorting options */
export type ExpenseServiceReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Record ID */
  id?: InputMaybe<SortDirection>;
  /** Parent ID */
  parentId?: InputMaybe<SortDirection>;
  /** Planned value */
  planned?: InputMaybe<SortDirection>;
  /** Expense percentage */
  rate?: InputMaybe<SortDirection>;
  /** Expense subject */
  subject?: InputMaybe<SortDirection>;
  /** Total value */
  total?: InputMaybe<SortDirection>;
};

export type FailureReasons = {
  __typename?: "FailureReasons";
  /** JSON-encoded map of reasons */
  details: Scalars["String"];
  /** Human-readable errors */
  fullMessages: Array<Scalars["String"]>;
};

/** Farm_map permissions */
export type Farm_MapInputPermissions = {
  activities?: InputMaybe<Scalars["Boolean"]>;
  costs?: InputMaybe<Scalars["Boolean"]>;
  cropStages?: InputMaybe<Scalars["Boolean"]>;
  yields?: InputMaybe<Scalars["Boolean"]>;
};

/** Farm_map permissions options */
export type Farm_MapPermissions = {
  __typename?: "Farm_mapPermissions";
  activities: Scalars["Boolean"];
  costs: Scalars["Boolean"];
  cropStages: Scalars["Boolean"];
  yields: Scalars["Boolean"];
};

/** File info object */
export type FileInfo = {
  __typename?: "FileInfo";
  filename: Scalars["String"];
  id: Scalars["String"];
  url: Scalars["String"];
};

/** Finance document reference */
export type FinanceDocumentReference = {
  __typename?: "FinanceDocumentReference";
  documentDate: Scalars["ISO8601DateTime"];
  documentId: Scalars["ID"];
  documentType: FinanceDocumentType;
  status: Scalars["String"];
};

export enum FinanceDocumentType {
  /** Agro Work Order */
  AgroWorkOrder = "agroWorkOrder",
  /** Finance Journal Entry */
  FinanceJournalEntry = "financeJournalEntry",
  /** Finance Order */
  FinanceOrder = "financeOrder",
  /** Finance Payment */
  FinancePayment = "financePayment",
  /** HR Leave Assignment */
  HrLeaveAssignment = "hrLeaveAssignment",
  /** HR Payroll Entry */
  HrPayrollEntry = "hrPayrollEntry",
  /** Inventory Order */
  InventoryOrder = "inventoryOrder",
  /** Inventory Transfer */
  InventoryTransfer = "inventoryTransfer",
}

/** Finance invoice object */
export type FinanceInvoice = Timestamps & {
  __typename?: "FinanceInvoice";
  /** Invoice account */
  account?: Maybe<Account>;
  /** Amount paid */
  amountPaid: Scalars["Float"];
  /** Amount to pay */
  amountToPay: Scalars["Float"];
  /** Counterparty */
  counterparty: Counterparty;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Invoice currency */
  currency: Currency;
  /** Currency code */
  currencyCode: Scalars["String"];
  /** Document date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Document date */
  dueDate: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** Invoice internal ID */
  internalId?: Maybe<Scalars["String"]>;
  /** Invoice status */
  invoiceStatus: InvoiceStatus;
  /** Payment method */
  paymentMethod: PaymentMethod;
  /** Payment status */
  paymentStatus: PaymentStatus;
  /** Invoice payments */
  payments: Array<FinancePayment>;
  sourceDocumentCode?: Maybe<Scalars["String"]>;
  sourceDocumentId?: Maybe<Scalars["ID"]>;
  sourceDocumentType?: Maybe<FinanceDocumentType>;
  /** Invoice total */
  total: Scalars["Float"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Amount to withhold */
  withholdAmount: Scalars["Float"];
};

export enum FinanceInvoiceStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** Open */
  Open = "open",
  /** Reverted */
  Reverted = "reverted",
}

/** Collection of FinanceInvoices */
export type FinanceInvoices = {
  __typename?: "FinanceInvoices";
  /** Paginated collection of FinanceInvoices */
  items: Array<FinanceInvoice>;
  /** Total count of FinanceInvoices */
  totalCount: Scalars["Int"];
};

/** FinanceInvoices filter */
export type FinanceInvoicesFilterInput = {
  /** Invoice Account */
  accountId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Counterparty */
  counterpartyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Counterparty Internal ID */
  counterpartyInternalId?: InputMaybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Document date */
  dueDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Invoice internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Status */
  invoiceStatus?: InputMaybe<Array<InvoiceStatus>>;
  /** Kind */
  kind?: InputMaybe<InventoryOrderKind>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Payment Internal Id */
  paymentInternalId?: InputMaybe<StringMatchInput>;
  /** Payment status */
  paymentStatus?: InputMaybe<Array<PaymentStatus>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  sourceDocumentCode?: InputMaybe<StringMatchInput>;
  sourceDocumentId?: InputMaybe<Array<Scalars["ID"]>>;
  sourceDocumentType?: InputMaybe<Array<FinanceDocumentType>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** FinanceInvoices sorting options */
export type FinanceInvoicesSortInput = {
  /** Amount paid */
  amountPaid?: InputMaybe<SortDirection>;
  /** Amount to pay */
  amountToPay?: InputMaybe<SortDirection>;
  /** Sort by counterparty name */
  counterpartyName?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Currency code */
  currencyCode?: InputMaybe<SortDirection>;
  /** Document date */
  documentDate?: InputMaybe<SortDirection>;
  /** Document date */
  dueDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Invoice internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Invoice status */
  invoiceStatus?: InputMaybe<SortDirection>;
  /** Payment status */
  paymentStatus?: InputMaybe<SortDirection>;
  sourceDocumentCode?: InputMaybe<SortDirection>;
  sourceDocumentId?: InputMaybe<SortDirection>;
  sourceDocumentType?: InputMaybe<SortDirection>;
  /** Invoice total */
  total?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Amount to withhold */
  withholdAmount?: InputMaybe<SortDirection>;
};

/** Finance order object */
export type FinanceOrder = Auditable &
  Timestamps & {
    __typename?: "FinanceOrder";
    /** Activity */
    activity?: Maybe<Activity>;
    /** Counterparty */
    counterparty: Counterparty;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by */
    createdBy?: Maybe<User>;
    /** Crop Cycle */
    cropCycle?: Maybe<CropCycle>;
    /** Order currency */
    currency: Currency;
    /** Currency code */
    currencyCode: Scalars["String"];
    /** Cycle number */
    cycleNumber?: Maybe<Scalars["Int"]>;
    /** Order discount amount */
    discount: Scalars["Float"];
    /** Order discount */
    discountRate: Scalars["Float"];
    /** Document date */
    documentDate: Scalars["ISO8601DateTime"];
    /** Order exchange rate */
    exchangeRate: Scalars["Float"];
    id: Scalars["ID"];
    /** Order internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Related invoices */
    invoices: Array<FinanceInvoice>;
    /** Order kind */
    kind: FinanceOrderKind;
    /** Order line items */
    lineItems: Array<FinanceOrderLineItem>;
    /** Payment account */
    paymentAccount?: Maybe<Account>;
    /** Payment method */
    paymentMethod: PaymentMethod;
    /** Order status */
    status: FinanceOrderStatus;
    /** Order sub total */
    subtotal: Scalars["Float"];
    /** Order tax amount */
    tax: Scalars["Float"];
    /** Order tax */
    taxRate: Scalars["Float"];
    /** Evaluated taxes */
    taxValues: Array<TaxValue>;
    /** Order total */
    total: Scalars["Float"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
  };

/** FinanceOrder changes object */
export type FinanceOrderChanges = {
  /** Activity */
  activityId?: InputMaybe<Scalars["ID"]>;
  /** Counterparty */
  counterpartyId?: InputMaybe<Scalars["ID"]>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Currency code */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  /** Order discount amount */
  discount?: InputMaybe<Scalars["Float"]>;
  /** Order discount */
  discountRate?: InputMaybe<Scalars["Float"]>;
  /** Document date */
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Order exchange rate */
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  /** Order internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Order kind */
  kind?: InputMaybe<FinanceOrderKind>;
  /** Order line items */
  lineItemsAttributes?: InputMaybe<Array<FinanceOrderLineItemChanges>>;
  /** Payment Account */
  paymentAccountId?: InputMaybe<Scalars["ID"]>;
  /** Payment method */
  paymentMethodId?: InputMaybe<Scalars["ID"]>;
  /** Order status */
  status?: InputMaybe<FinanceOrderStatus>;
  /** Order sub total */
  subtotal?: InputMaybe<Scalars["Float"]>;
  /** Order tax amount */
  tax?: InputMaybe<Scalars["Float"]>;
  /** Order tax */
  taxRate?: InputMaybe<Scalars["Float"]>;
  /** Order total */
  total?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of FinanceOrderClone. */
export type FinanceOrderClonePayload = {
  __typename?: "FinanceOrderClonePayload";
  errors: Array<UserError>;
  result?: Maybe<FinanceOrder>;
};

/** Autogenerated return type of FinanceOrderCreate. */
export type FinanceOrderCreatePayload = {
  __typename?: "FinanceOrderCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<FinanceOrder>;
};

/** FinanceOrder field values */
export type FinanceOrderFields = {
  /** Activity */
  activityId?: InputMaybe<Scalars["ID"]>;
  /** Counterparty */
  counterpartyId: Scalars["ID"];
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Currency code */
  currencyCode: Scalars["String"];
  /** Cycle number */
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  /** Order discount */
  discountRate: Scalars["Float"];
  /** Document date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Order exchange rate */
  exchangeRate: Scalars["Float"];
  /** Order internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Order kind */
  kind: FinanceOrderKind;
  /** Order line items */
  lineItemsAttributes: Array<FinanceOrderLineItemFields>;
  /** Payment Account */
  paymentAccountId?: InputMaybe<Scalars["ID"]>;
  /** Payment method */
  paymentMethodId: Scalars["ID"];
  /** Order status */
  status: FinanceOrderStatus;
  /** Order tax */
  taxRate: Scalars["Float"];
};

export enum FinanceOrderKind {
  /** Expense */
  Expense = "expense",
}

/** Finance order line item object */
export type FinanceOrderLineItem = Timestamps & {
  __typename?: "FinanceOrderLineItem";
  /** Line item amount */
  amount: Scalars["Float"];
  /** Cost Centers */
  costCenters: Array<FinanceOrderLineItemCostCenter>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Description */
  description?: Maybe<Scalars["String"]>;
  /** Expense Item */
  expenseItem: ExpenseItem;
  id: Scalars["ID"];
  /** Tax plan evaluated values */
  taxPlanValues: Array<TaxValue>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** FinanceOrderLineItem changes object */
export type FinanceOrderLineItemChanges = {
  /** Remove line item */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Cost Centers */
  costCentersAttributes?: InputMaybe<
    Array<FinanceOrderLineItemCostCenterChanges>
  >;
  /** Description */
  description?: InputMaybe<Scalars["String"]>;
  /** Expense Item ID */
  expenseItemId?: InputMaybe<Scalars["ID"]>;
  /** Order line item ID */
  id?: InputMaybe<Scalars["ID"]>;
};

/** Finance order line item cost center object */
export type FinanceOrderLineItemCostCenter = HasProfitable &
  Timestamps & {
    __typename?: "FinanceOrderLineItemCostCenter";
    /** CC amount */
    amount: Scalars["Float"];
    /** Cost Center */
    costCenter: CostCenter;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    /** Profitable extra info */
    profitableExtra?: Maybe<Scalars["String"]>;
    /** Profitable ID (sub cost center) */
    profitableId?: Maybe<Scalars["ID"]>;
    /** Profitable name */
    profitableName?: Maybe<Scalars["String"]>;
    /** Profitable type */
    profitableType?: Maybe<ProfitableType>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** FinanceOrderLineItemCostCenter changes object */
export type FinanceOrderLineItemCostCenterChanges = {
  /** Remove line item */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** CC amount */
  amount?: InputMaybe<Scalars["Float"]>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Order line item cost center ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Profitable extra info */
  profitableExtra?: InputMaybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: InputMaybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: InputMaybe<ProfitableType>;
};

/** FinanceOrderLineItemCostCenter field values */
export type FinanceOrderLineItemCostCenterFields = {
  /** CC amount */
  amount: Scalars["Float"];
  /** Cost Center ID */
  costCenterId: Scalars["ID"];
  /** Profitable extra info */
  profitableExtra?: InputMaybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: InputMaybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: InputMaybe<ProfitableType>;
};

/** FinanceOrderLineItem field values */
export type FinanceOrderLineItemFields = {
  /** Cost Centers */
  costCentersAttributes: Array<FinanceOrderLineItemCostCenterFields>;
  /** Description */
  description?: InputMaybe<Scalars["String"]>;
  /** Expense Item ID */
  expenseItemId: Scalars["ID"];
};

export enum FinanceOrderStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** Open */
  Open = "open",
  /** Reverted */
  Reverted = "reverted",
}

/** Autogenerated return type of FinanceOrderUpdate. */
export type FinanceOrderUpdatePayload = {
  __typename?: "FinanceOrderUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<FinanceOrder>;
};

/** Collection of FinanceOrders */
export type FinanceOrders = {
  __typename?: "FinanceOrders";
  /** Paginated collection of FinanceOrders */
  items: Array<FinanceOrder>;
  /** Total count of FinanceOrders */
  totalCount: Scalars["Int"];
};

/** FinanceOrders filter */
export type FinanceOrdersFilterInput = {
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Counterparty */
  counterpartyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Currency code */
  currencyCode?: InputMaybe<StringMatchInput>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Order internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Order kind */
  kind?: InputMaybe<Array<FinanceOrderKind>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Payment Account */
  paymentAccountId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Payment Method */
  paymentMethodId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Order status */
  status?: InputMaybe<Array<FinanceOrderStatus>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** FinanceOrders sorting options */
export type FinanceOrdersSortInput = {
  /** Sort by counterparty name */
  counterpartyName?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Sort by crop Cycle */
  cropCycleName?: InputMaybe<SortDirection>;
  /** Currency code */
  currencyCode?: InputMaybe<SortDirection>;
  /** Cycle number */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Order discount amount */
  discount?: InputMaybe<SortDirection>;
  /** Order discount */
  discountRate?: InputMaybe<SortDirection>;
  /** Document date */
  documentDate?: InputMaybe<SortDirection>;
  /** Order exchange rate */
  exchangeRate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Order internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Order kind */
  kind?: InputMaybe<SortDirection>;
  /** Order status */
  status?: InputMaybe<SortDirection>;
  /** Order sub total */
  subtotal?: InputMaybe<SortDirection>;
  /** Order tax amount */
  tax?: InputMaybe<SortDirection>;
  /** Order tax */
  taxRate?: InputMaybe<SortDirection>;
  /** Order total */
  total?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Finance payment object */
export type FinancePayment = Timestamps & {
  __typename?: "FinancePayment";
  /** Payment account */
  account?: Maybe<Account>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  exchangeRate: Scalars["Float"];
  id: Scalars["ID"];
  internalId?: Maybe<Scalars["String"]>;
  /** Invoice payment amount */
  invoiceAmount: Scalars["Float"];
  /** Related invoices */
  invoices: Array<FinanceInvoice>;
  /** Payment date */
  paymentDate: Scalars["ISO8601DateTime"];
  /** Payment status */
  status: FinancePaymentStatus;
  /** Total payment amount */
  totalAmount: Scalars["Float"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Withholding rate */
  withholdingRate: Scalars["Float"];
};

export enum FinancePaymentStatus {
  /** Completed */
  Completed = "completed",
  /** Reverted */
  Reverted = "reverted",
}

/** Collection of FinancePeriods */
export type FinancePeriods = {
  __typename?: "FinancePeriods";
  /** Paginated collection of FinancePeriods */
  items: Array<Period>;
  /** Total count of FinancePeriods */
  totalCount: Scalars["Int"];
};

/** FinancePeriods filter */
export type FinancePeriodsFilterInput = {
  /** Closed */
  closed?: InputMaybe<Scalars["Boolean"]>;
  /** Closed timestamp */
  closedAt?: InputMaybe<DateRangeInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** End date of fiscal year */
  endDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Period name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Start date of fiscal year */
  startDate?: InputMaybe<DateRangeInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** FinancePeriods sorting options */
export type FinancePeriodsSortInput = {
  /** Closed timestamp */
  closedAt?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** End date of fiscal year */
  endDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Period name */
  name?: InputMaybe<SortDirection>;
  /** Start date of fiscal year */
  startDate?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of FireEventMutation. */
export type FireEventMutationPayload = {
  __typename?: "FireEventMutationPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Fiscal Year object */
export type FiscalYear = Timestamps & {
  __typename?: "FiscalYear";
  /** Closed timestamp */
  closedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** End date of fiscal year */
  endDate: Scalars["ISO8601Date"];
  id: Scalars["ID"];
  /** Fiscal Year name */
  name: Scalars["String"];
  /** Accounting periods */
  periods: Array<Period>;
  /** Start date of fiscal year */
  startDate: Scalars["ISO8601Date"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** FiscalYear changes object */
export type FiscalYearChanges = {
  /** Closed timestamp */
  closedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** End date of fiscal year */
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Fiscal Year name */
  name?: InputMaybe<Scalars["String"]>;
  /** Accounting Periods */
  periodsAttributes?: InputMaybe<Array<PeriodChanges>>;
  /** Start date of fiscal year */
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
};

/** Autogenerated return type of FiscalYearCreate. */
export type FiscalYearCreatePayload = {
  __typename?: "FiscalYearCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<FiscalYear>;
};

/** Autogenerated return type of FiscalYearDestroy. */
export type FiscalYearDestroyPayload = {
  __typename?: "FiscalYearDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** FiscalYear field values */
export type FiscalYearFields = {
  /** Closed timestamp */
  closedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** End date of fiscal year */
  endDate: Scalars["ISO8601Date"];
  /** Accounting Periods */
  periodsAttributes: Array<PeriodFields>;
  /** Start date of fiscal year */
  startDate: Scalars["ISO8601Date"];
};

/** Autogenerated return type of FiscalYearUpdate. */
export type FiscalYearUpdatePayload = {
  __typename?: "FiscalYearUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<FiscalYear>;
};

/** Collection of FiscalYears */
export type FiscalYears = {
  __typename?: "FiscalYears";
  /** Paginated collection of FiscalYears */
  items: Array<FiscalYear>;
  /** Total count of FiscalYears */
  totalCount: Scalars["Int"];
};

/** FiscalYears filter */
export type FiscalYearsFilterInput = {
  /** Closed timestamp */
  closedAt?: InputMaybe<DateRangeInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** End date of fiscal year */
  endDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Fiscal Year name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Start date of fiscal year */
  startDate?: InputMaybe<DateRangeInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** FiscalYears sorting options */
export type FiscalYearsSortInput = {
  /** Closed timestamp */
  closedAt?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** End date of fiscal year */
  endDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Fiscal Year name */
  name?: InputMaybe<SortDirection>;
  /** Start date of fiscal year */
  startDate?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum FuelType {
  /** Diesel */
  Diesel = "diesel",
  /** Gasoline */
  Gasoline = "gasoline",
}

export enum Gender {
  /** Female */
  Female = "female",
  /** Male */
  Male = "male",
  /** Other */
  Other = "other",
}

/** General Ledger Report result */
export type GeneralLedger = Destroyable &
  Discardable & {
    __typename?: "GeneralLedger";
    /** Account number */
    accountNumber?: Maybe<Scalars["String"]>;
    /** Account balance */
    balance: Scalars["Float"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Sum of credits */
    credits: Scalars["Float"];
    /** Account default currency */
    currency: Currency;
    /** Currency code */
    currencyCode: Scalars["String"];
    /** Sum of debits */
    debits: Scalars["Float"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Ending balance */
    endingBalance: Scalars["Float"];
    /** Is group account */
    groupAccount: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Account kind */
    kind: AccountKind;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Account name */
    name: Scalars["String"];
    /** Account name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Account name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Account name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Account name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Opening balance */
    openingBalance: Scalars["Float"];
    /** Parent account group */
    parentAccount?: Maybe<Account>;
    /** Parent account ID */
    parentAccountId?: Maybe<Scalars["ID"]>;
    /** Debit - Credit */
    quantity: Scalars["Float"];
    /** Subaccounts */
    subaccounts?: Maybe<Array<Account>>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Collection of GeneralLedgerReport */
export type GeneralLedgerReport = {
  __typename?: "GeneralLedgerReport";
  /** Paginated collection of GeneralLedgerReport */
  items: Array<GeneralLedger>;
  /** Total count of GeneralLedgerReport */
  totalCount: Scalars["Int"];
};

/** GeneralLedgerReport filter */
export type GeneralLedgerReportFilterInput = {
  /** Account number */
  accountNumber?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date range */
  date?: InputMaybe<DateRangeInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is group account */
  groupAccount?: InputMaybe<Scalars["Boolean"]>;
  /** Account */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Account name */
  name?: InputMaybe<StringMatchInput>;
  /** Account name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** GeneralLedgerReport sorting options */
export type GeneralLedgerReportSortInput = {
  /** Account number */
  accountNumber?: InputMaybe<SortDirection>;
  /** Account balance */
  balance?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Sum of credits */
  credits?: InputMaybe<SortDirection>;
  /** Currency code */
  currencyCode?: InputMaybe<SortDirection>;
  /** Sum of debits */
  debits?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Ending balance */
  endingBalance?: InputMaybe<SortDirection>;
  /** Is group account */
  groupAccount?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Account kind */
  kind?: InputMaybe<SortDirection>;
  /** Account name */
  name?: InputMaybe<SortDirection>;
  /** Account name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Opening balance */
  openingBalance?: InputMaybe<SortDirection>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<SortDirection>;
  /** Debit - Credit */
  quantity?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of GenerateBillingPortalLinkMutation. */
export type GenerateBillingPortalLinkMutationPayload = {
  __typename?: "GenerateBillingPortalLinkMutationPayload";
  link: Scalars["String"];
};

/** Autogenerated return type of GenerateInvitationLinkMutation. */
export type GenerateInvitationLinkMutationPayload = {
  __typename?: "GenerateInvitationLinkMutationPayload";
  errors: Array<UserError>;
  link?: Maybe<Scalars["String"]>;
};

/** Autogenerated return type of GenerateResetPasswordLinkMutation. */
export type GenerateResetPasswordLinkMutationPayload = {
  __typename?: "GenerateResetPasswordLinkMutationPayload";
  errors: Array<UserError>;
  link?: Maybe<Scalars["String"]>;
};

/** Object that can be global */
export type Global = {
  /** Is global? */
  global: Scalars["Boolean"];
  /** Is locked */
  locked: Scalars["Boolean"];
};

/** Crop field harvest result */
export type HarvestCropField = {
  __typename?: "HarvestCropField";
  /** Activity */
  activityName: Scalars["String"];
  /** Crop field group */
  cropFieldGroup?: Maybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: Maybe<Scalars["Int"]>;
  /** Days between harvest */
  daysBetween?: Maybe<Scalars["Int"]>;
  /** Date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Harvest unit abbr */
  harvestUnitAbbr: Scalars["String"];
  /** Harvested amount */
  harvestedAmount: Scalars["Float"];
  /** Document ID */
  id: Scalars["ID"];
  /** Labor Efficiency */
  laborEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine Efficiency */
  machineEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine hours */
  machineHours: Scalars["Float"];
  /** Man Days */
  manDays: Scalars["Float"];
};

/** Collection of HarvestCropFieldReport */
export type HarvestCropFieldReport = {
  __typename?: "HarvestCropFieldReport";
  /** Paginated collection of HarvestCropFieldReport */
  items: Array<HarvestCropField>;
  /** Total count of HarvestCropFieldReport */
  totalCount: Scalars["Int"];
};

/** HarvestCropFieldReport filter */
export type HarvestCropFieldReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity */
  activityName?: InputMaybe<StringMatchInput>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Days between harvest */
  daysBetween?: InputMaybe<Array<Scalars["Int"]>>;
  /** Date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Document ID */
  documentId?: InputMaybe<Scalars["ID"]>;
  /** Harvest unit abbr */
  harvestUnitAbbr?: InputMaybe<StringMatchInput>;
  /** Document ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** HarvestCropFieldReport sorting options */
export type HarvestCropFieldReportSortInput = {
  /** Activity */
  activityName?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<SortDirection>;
  /** Cycle number */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Days between harvest */
  daysBetween?: InputMaybe<SortDirection>;
  /** Date */
  documentDate?: InputMaybe<SortDirection>;
  /** Harvest unit abbr */
  harvestUnitAbbr?: InputMaybe<SortDirection>;
  /** Harvested amount */
  harvestedAmount?: InputMaybe<SortDirection>;
  /** Document ID */
  id?: InputMaybe<SortDirection>;
  /** Labor Efficiency */
  laborEfficiency?: InputMaybe<SortDirection>;
  /** Machine Efficiency */
  machineEfficiency?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Man Days */
  manDays?: InputMaybe<SortDirection>;
};

/** Harvest daily report result */
export type HarvestDaily = {
  __typename?: "HarvestDaily";
  /** Accumulated harvest */
  accumulatedHarvest: Scalars["Float"];
  /** Accumulated machine hours */
  accumulatedMachineHours: Scalars["Float"];
  /** Accumulated Man Days */
  accumulatedManDays: Scalars["Float"];
  /** Activity */
  activity: Activity;
  /** Labor Efficiency */
  avgLaborEfficiency?: Maybe<Scalars["Float"]>;
  /** Avg sMachine Efficiency */
  avgMachineEfficiency?: Maybe<Scalars["Float"]>;
  /** Crop cycle */
  cropCycle: CropCycle;
  /** Crop field group */
  cropFieldGroup?: Maybe<Scalars["String"]>;
  /** Activity cycly */
  cycleNumber?: Maybe<Scalars["Int"]>;
  /** Date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Harvest unit abbr */
  harvestUnitAbbr: Scalars["String"];
  /** Harvested amount */
  harvestedAmount: Scalars["Float"];
  /** Document ID */
  id: Scalars["ID"];
  /** Labor Efficiency */
  laborEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine Efficiency */
  machineEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine hours */
  machineHours: Scalars["Float"];
  /** Man Days */
  manDays: Scalars["Float"];
};

/** Collection of HarvestDailyReport */
export type HarvestDailyReport = {
  __typename?: "HarvestDailyReport";
  /** Paginated collection of HarvestDailyReport */
  items: Array<HarvestDaily>;
  /** Total count of HarvestDailyReport */
  totalCount: Scalars["Int"];
};

/** HarvestDailyReport filter */
export type HarvestDailyReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crops */
  cropId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Harvest unit abbr */
  harvestUnitAbbr?: InputMaybe<StringMatchInput>;
  /** Document ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Show in estimation units */
  showInEstimationUnits?: InputMaybe<Scalars["Boolean"]>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** HarvestDailyReport sorting options */
export type HarvestDailyReportSortInput = {
  /** Accumulated harvest */
  accumulatedHarvest?: InputMaybe<SortDirection>;
  /** Accumulated machine hours */
  accumulatedMachineHours?: InputMaybe<SortDirection>;
  /** Accumulated Man Days */
  accumulatedManDays?: InputMaybe<SortDirection>;
  /** Labor Efficiency */
  avgLaborEfficiency?: InputMaybe<SortDirection>;
  /** Avg sMachine Efficiency */
  avgMachineEfficiency?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<SortDirection>;
  /** Activity cycly */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Date */
  documentDate?: InputMaybe<SortDirection>;
  /** Harvest unit abbr */
  harvestUnitAbbr?: InputMaybe<SortDirection>;
  /** Harvested amount */
  harvestedAmount?: InputMaybe<SortDirection>;
  /** Document ID */
  id?: InputMaybe<SortDirection>;
  /** Labor Efficiency */
  laborEfficiency?: InputMaybe<SortDirection>;
  /** Machine Efficiency */
  machineEfficiency?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Man Days */
  manDays?: InputMaybe<SortDirection>;
};

export type HarvestEstimate = Auditable &
  Timestamps & {
    __typename?: "HarvestEstimate";
    /** Conversion factor */
    conversionFactor: Scalars["Float"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created By */
    createdBy?: Maybe<User>;
    /** Estimation crop cycle */
    cropCycle: CropCycle;
    /** Crop field estimations */
    cropFieldEstimates: Array<CropFieldHarvestEstimate>;
    /** Estimate unit */
    estimateUnit: Unit;
    /** Harvest progress unit */
    harvestUnit: Unit;
    id: Scalars["ID"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
  };

/** HarvestEstimate changes object */
export type HarvestEstimateChanges = {
  /** Conversion factor */
  conversionFactor?: InputMaybe<Scalars["Float"]>;
  /** Crop Field Estimate */
  cropFieldEstimatesAttributes?: InputMaybe<
    Array<CropFieldHarvestEstimateChanges>
  >;
  /** Estimate Unit */
  estimateUnitId?: InputMaybe<Scalars["ID"]>;
  /** Harvest Unit */
  harvestUnitId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of HarvestEstimateCreate. */
export type HarvestEstimateCreatePayload = {
  __typename?: "HarvestEstimateCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<HarvestEstimate>;
};

/** Autogenerated return type of HarvestEstimateDestroy. */
export type HarvestEstimateDestroyPayload = {
  __typename?: "HarvestEstimateDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** HarvestEstimate field values */
export type HarvestEstimateFields = {
  /** Conversion factor */
  conversionFactor: Scalars["Float"];
  /** Crop Cycle */
  cropCycleId: Scalars["ID"];
  /** Crop Field Estimate */
  cropFieldEstimatesAttributes: Array<CropFieldHarvestEstimateFields>;
  /** Estimate Unit */
  estimateUnitId: Scalars["ID"];
  /** Harvest Unit */
  harvestUnitId: Scalars["ID"];
};

/** Autogenerated return type of HarvestEstimateUpdate. */
export type HarvestEstimateUpdatePayload = {
  __typename?: "HarvestEstimateUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<HarvestEstimate>;
};

/** Collection of HarvestEstimates */
export type HarvestEstimates = {
  __typename?: "HarvestEstimates";
  /** Paginated collection of HarvestEstimates */
  items: Array<HarvestEstimate>;
  /** Total count of HarvestEstimates */
  totalCount: Scalars["Int"];
};

/** HarvestEstimates filter */
export type HarvestEstimatesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** HarvestEstimates sorting options */
export type HarvestEstimatesSortInput = {
  /** Conversion factor */
  conversionFactor?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Harvest Overview Report result */
export type HarvestOverview = {
  __typename?: "HarvestOverview";
  /** Area unit abbr */
  areaUnitAbbr?: Maybe<Scalars["String"]>;
  /** Estimate harvest amount */
  estimateAmount?: Maybe<Scalars["Float"]>;
  /** Estimate yield */
  estimateYield?: Maybe<Scalars["Float"]>;
  /** Harvest unit abbr */
  harvestUnitAbbr?: Maybe<Scalars["String"]>;
  /** Harvest yield */
  harvestYield?: Maybe<Scalars["Float"]>;
  /** Harvested amount */
  harvestedAmount?: Maybe<Scalars["Float"]>;
  /** Labor Efficiency */
  laborEfficiency?: Maybe<Scalars["Float"]>;
  /** Accumulated Man Days */
  manDays?: Maybe<Scalars["Float"]>;
};

/** Harvest report filter input */
export type HarvestReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop field */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop stage */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop variety */
  cropVarietyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Document ID */
  documentId?: InputMaybe<Scalars["Int"]>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in estimation units */
  showInEstimationUnits?: InputMaybe<Scalars["Boolean"]>;
};

/** Harvest Summary Report result */
export type HarvestSummary = {
  __typename?: "HarvestSummary";
  /** Average days between harvest */
  avgHarvestDays?: Maybe<Scalars["Float"]>;
  /** Crop Field */
  cropField: CropField;
  /** Days since last harvest days */
  daysSinceLast: Scalars["Int"];
  /** Estimate harvest amount */
  estimateAmount?: Maybe<Scalars["Float"]>;
  /** Estimate harvest unit abbr */
  estimateUnitAbbr?: Maybe<Scalars["String"]>;
  /** Estimate yield */
  estimateYield?: Maybe<Scalars["Float"]>;
  /** How many days on crop field */
  frequency: Scalars["Int"];
  /** Harvest unit abbr */
  harvestUnitAbbr: Scalars["String"];
  /** Harvest yield */
  harvestYield: Scalars["Float"];
  /** Harvested amount */
  harvestedAmount: Scalars["Float"];
  /** Labor Efficiency */
  laborEfficiency?: Maybe<Scalars["Float"]>;
  /** Last harvest date */
  lastDate: Scalars["ISO8601DateTime"];
  /** Machine Efficiency */
  machineEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine hours */
  machineHours: Scalars["Float"];
  /** Accumulated Man Days */
  manDays: Scalars["Float"];
};

/** Collection of HarvestSummaryReport */
export type HarvestSummaryReport = {
  __typename?: "HarvestSummaryReport";
  /** Paginated collection of HarvestSummaryReport */
  items: Array<HarvestSummary>;
  /** Total count of HarvestSummaryReport */
  totalCount: Scalars["Int"];
};

/** HarvestSummaryReport sorting options */
export type HarvestSummaryReportSortInput = {
  /** Average days between harvest */
  avgHarvestDays?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Days since last harvest days */
  daysSinceLast?: InputMaybe<SortDirection>;
  /** Estimate harvest amount */
  estimateAmount?: InputMaybe<SortDirection>;
  /** Estimate harvest unit abbr */
  estimateUnitAbbr?: InputMaybe<SortDirection>;
  /** Estimate yield */
  estimateYield?: InputMaybe<SortDirection>;
  /** How many days on crop field */
  frequency?: InputMaybe<SortDirection>;
  /** Harvest unit abbr */
  harvestUnitAbbr?: InputMaybe<SortDirection>;
  /** Harvest yield */
  harvestYield?: InputMaybe<SortDirection>;
  /** Harvested amount */
  harvestedAmount?: InputMaybe<SortDirection>;
  /** Labor Efficiency */
  laborEfficiency?: InputMaybe<SortDirection>;
  /** Last harvest date */
  lastDate?: InputMaybe<SortDirection>;
  /** Machine Efficiency */
  machineEfficiency?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Accumulated Man Days */
  manDays?: InputMaybe<SortDirection>;
};

/** Object that has cost center and profitable */
export type HasProfitable = {
  /** Cost Center */
  costCenter: CostCenter;
  /** Profitable extra info */
  profitableExtra?: Maybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: Maybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: Maybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: Maybe<ProfitableType>;
};

/** Holiday object */
export type Holiday = Timestamps & {
  __typename?: "Holiday";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  date: Scalars["ISO8601Date"];
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Holiday changes object */
export type HolidayChanges = {
  /** Remove holiday */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  date?: InputMaybe<Scalars["ISO8601Date"]>;
  description?: InputMaybe<Scalars["String"]>;
  /** Holiday ID */
  id?: InputMaybe<Scalars["ID"]>;
};

/** Holiday field values */
export type HolidayFields = {
  date: Scalars["ISO8601Date"];
  description?: InputMaybe<Scalars["String"]>;
};

/** Holiday list object */
export type HolidayList = Timestamps & {
  __typename?: "HolidayList";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Holidays */
  holidays: Array<Holiday>;
  id: Scalars["ID"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  year: Scalars["Int"];
};

/** HolidayList changes object */
export type HolidayListChanges = {
  /** Holidays */
  holidaysAttributes?: InputMaybe<Array<HolidayChanges>>;
  year?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated return type of HolidayListCreate. */
export type HolidayListCreatePayload = {
  __typename?: "HolidayListCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<HolidayList>;
};

/** Autogenerated return type of HolidayListDestroy. */
export type HolidayListDestroyPayload = {
  __typename?: "HolidayListDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** HolidayList field values */
export type HolidayListFields = {
  /** Holidays */
  holidaysAttributes: Array<HolidayFields>;
  year: Scalars["Int"];
};

/** Autogenerated return type of HolidayListUpdate. */
export type HolidayListUpdatePayload = {
  __typename?: "HolidayListUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<HolidayList>;
};

/** Collection of HolidayLists */
export type HolidayLists = {
  __typename?: "HolidayLists";
  /** Paginated collection of HolidayLists */
  items: Array<HolidayList>;
  /** Total count of HolidayLists */
  totalCount: Scalars["Int"];
};

/** HolidayLists filter */
export type HolidayListsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  year?: InputMaybe<Array<Scalars["Int"]>>;
};

/** HolidayLists sorting options */
export type HolidayListsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  year?: InputMaybe<SortDirection>;
};

/** Collection of Holidays */
export type Holidays = {
  __typename?: "Holidays";
  /** Paginated collection of Holidays */
  items: Array<Holiday>;
  /** Total count of Holidays */
  totalCount: Scalars["Int"];
};

/** Holidays filter */
export type HolidaysFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  date?: InputMaybe<DateRangeInput>;
  description?: InputMaybe<StringMatchInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Year */
  year?: InputMaybe<Scalars["Int"]>;
};

/** Holidays sorting options */
export type HolidaysSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum ImageVariant {
  /** resize_to_limit: [1024, 1024] */
  Large = "large",
  /** resize_to_fill: [512, 512] */
  Medium = "medium",
  /** resize_to_fill: [256, 256] */
  Small = "small",
  /** resize_to_fill: [128, 128] */
  Thumb = "thumb",
  /** resize_to_limit: [2048, 2048] */
  Xlarge = "xlarge",
}

/** Import object */
export type Import = Timestamps & {
  __typename?: "Import";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  discardedCount?: Maybe<Scalars["Int"]>;
  file?: Maybe<Scalars["Upload"]>;
  fileInfo?: Maybe<FileInfo>;
  id: Scalars["ID"];
  /** Import Logs */
  importLogs: Array<ImportLog>;
  kind: ImportKindType;
  localeCode: Scalars["String"];
  missingRequiredEntities: Array<Scalars["String"]>;
  moduleName: ImportModulesType;
  restoredCount?: Maybe<Scalars["Int"]>;
  skippedCount: Scalars["Int"];
  status: ImportStatusType;
  totalCount: Scalars["Int"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Import User */
  user: User;
};

/** Import changes object */
export type ImportChanges = {
  discardedCount?: InputMaybe<Scalars["Int"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  localeCode?: InputMaybe<Scalars["String"]>;
  restoredCount?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<ImportStatusType>;
};

/** Autogenerated return type of ImportCreate. */
export type ImportCreatePayload = {
  __typename?: "ImportCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Import>;
};

export enum ImportEventType {
  /** Cancel */
  Cancel = "cancel",
  /** Process */
  Process = "process",
  /** Queue */
  Queue = "queue",
  /** Queue_preview */
  QueuePreview = "queuePreview",
}

/** Import field values */
export type ImportFields = {
  discardedCount?: InputMaybe<Scalars["Int"]>;
  file?: InputMaybe<Scalars["Upload"]>;
  kind: ImportKindType;
  localeCode: Scalars["String"];
  moduleName: ImportModulesType;
  restoredCount?: InputMaybe<Scalars["Int"]>;
};

export enum ImportKindType {
  /** Create */
  Create = "create",
  /** Update */
  Update = "update",
}

/** Import Log object */
export type ImportLog = Timestamps & {
  __typename?: "ImportLog";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** Import Object */
  import: Import;
  importErrors?: Maybe<Array<UserError>>;
  row?: Maybe<Scalars["Int"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Collection of ImportLogs */
export type ImportLogs = {
  __typename?: "ImportLogs";
  /** Paginated collection of ImportLogs */
  items: Array<ImportLog>;
  /** Total count of ImportLogs */
  totalCount: Scalars["Int"];
};

/** ImportLogs filter */
export type ImportLogsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Import ID */
  importId?: InputMaybe<Scalars["ID"]>;
  /** Row index */
  row?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ImportLogs sorting options */
export type ImportLogsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  row?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum ImportModulesType {
  /** Account */
  Account = "account",
  /** Activity */
  Activity = "activity",
  /** Counterparty */
  Counterparty = "counterparty",
  /** CropField */
  CropField = "cropField",
  /** Employee */
  Employee = "employee",
  /** Item */
  Item = "item",
  /** ItemCategory */
  ItemCategory = "itemCategory",
  /** Machine */
  Machine = "machine",
  /** Position */
  Position = "position",
  /** Variant */
  Variant = "variant",
  /** WeatherMeasurement */
  WeatherMeasurement = "weatherMeasurement",
}

export enum ImportStatusType {
  /** Canceled */
  Canceled = "canceled",
  /** Created */
  Created = "created",
  /** Done */
  Done = "done",
  /** Failed */
  Failed = "failed",
  /** In_progress */
  InProgress = "inProgress",
  /** Preview */
  Preview = "preview",
  /** Preview_in_progress */
  PreviewInProgress = "previewInProgress",
  /** Preview_queued */
  PreviewQueued = "previewQueued",
  /** Queued */
  Queued = "queued",
}

/** Autogenerated return type of ImportUpdate. */
export type ImportUpdatePayload = {
  __typename?: "ImportUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Import>;
};

/** Collection of Imports */
export type Imports = {
  __typename?: "Imports";
  /** Paginated collection of Imports */
  items: Array<Import>;
  /** Total count of Imports */
  totalCount: Scalars["Int"];
};

/** Imports filter */
export type ImportsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  discardedCount?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  kind?: InputMaybe<Array<ImportKindType>>;
  localeCode?: InputMaybe<StringMatchInput>;
  missingRequiredEntities?: InputMaybe<StringMatchInput>;
  moduleName?: InputMaybe<Array<ImportModulesType>>;
  restoredCount?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  skippedCount?: InputMaybe<Array<Scalars["Int"]>>;
  status?: InputMaybe<Array<ImportStatusType>>;
  totalCount?: InputMaybe<Array<Scalars["Int"]>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Imports sorting options */
export type ImportsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  discardedCount?: InputMaybe<SortDirection>;
  file?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  kind?: InputMaybe<SortDirection>;
  localeCode?: InputMaybe<SortDirection>;
  missingRequiredEntities?: InputMaybe<SortDirection>;
  moduleName?: InputMaybe<SortDirection>;
  restoredCount?: InputMaybe<SortDirection>;
  skippedCount?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  totalCount?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Income statement report result */
export type IncomeStatementRecord = {
  __typename?: "IncomeStatementRecord";
  /** Expenses */
  columns: Array<Column>;
  /** Record ID */
  id: Scalars["ID"];
  /** Account name */
  name: Scalars["String"];
  /** Parent ID */
  parentId?: Maybe<Scalars["ID"]>;
  /** Total value */
  total: Scalars["Float"];
};

/** Collection of IncomeStatementReport */
export type IncomeStatementReport = {
  __typename?: "IncomeStatementReport";
  /** Paginated collection of IncomeStatementReport */
  items: Array<IncomeStatementRecord>;
  /** Total count of IncomeStatementReport */
  totalCount: Scalars["Int"];
};

/** IncomeStatementReport filter */
export type IncomeStatementReportFilterInput = {
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Record ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Account name */
  name?: InputMaybe<StringMatchInput>;
  /** Parent ID */
  parentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** IncomeStatementReport sorting options */
export type IncomeStatementReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Record ID */
  id?: InputMaybe<SortDirection>;
  /** Account name */
  name?: InputMaybe<SortDirection>;
  /** Parent ID */
  parentId?: InputMaybe<SortDirection>;
  /** Total value */
  total?: InputMaybe<SortDirection>;
};

/** Infrastructure object */
export type Infrastructure = Destroyable &
  Discardable &
  Profitable & {
    __typename?: "Infrastructure";
    /** Cost Center */
    costCenter?: Maybe<CostCenter>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** Internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Infrastructure type */
    kind: InfrastructureKind;
    /** Locality */
    locality: Locality;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    name: Scalars["String"];
    total?: Maybe<Scalars["Float"]>;
    /** UOM */
    unit?: Maybe<Unit>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Related warehouse */
    warehouse?: Maybe<Warehouse>;
  };

export enum InfrastructureCategory {
  /** Buildings */
  Buildings = "buildings",
  /** Grid */
  Grid = "grid",
  /** Processing */
  Processing = "processing",
  /** Warehouses */
  Warehouses = "warehouses",
}

/** Infrastructure changes object */
export type InfrastructureChanges = {
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Infrastructure kind */
  kindId?: InputMaybe<Scalars["ID"]>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  total?: InputMaybe<Scalars["Float"]>;
  /** UOM */
  unitId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of InfrastructureCreate. */
export type InfrastructureCreatePayload = {
  __typename?: "InfrastructureCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Infrastructure>;
};

/** Autogenerated return type of InfrastructureDestroy. */
export type InfrastructureDestroyPayload = {
  __typename?: "InfrastructureDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of InfrastructureDiscard. */
export type InfrastructureDiscardPayload = {
  __typename?: "InfrastructureDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Infrastructure>;
};

/** Infrastructure field values */
export type InfrastructureFields = {
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Infrastructure kind */
  kindId: Scalars["ID"];
  /** Locality */
  localityId: Scalars["ID"];
  name: Scalars["String"];
  total?: InputMaybe<Scalars["Float"]>;
  /** UOM */
  unitId?: InputMaybe<Scalars["ID"]>;
};

/** Infrastructure Kind object */
export type InfrastructureKind = Discardable &
  Global & {
    __typename?: "InfrastructureKind";
    category: InfrastructureCategory;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    name: Scalars["String"];
    /** Unit name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Unit name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Unit name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Unit name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Collection of InfrastructureKinds */
export type InfrastructureKinds = {
  __typename?: "InfrastructureKinds";
  /** Paginated collection of InfrastructureKinds */
  items: Array<InfrastructureKind>;
  /** Total count of InfrastructureKinds */
  totalCount: Scalars["Int"];
};

/** InfrastructureKinds filter */
export type InfrastructureKindsFilterInput = {
  category?: InputMaybe<Array<InfrastructureCategory>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<StringMatchInput>;
  /** Unit name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Unit name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Unit name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Unit name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** InfrastructureKinds sorting options */
export type InfrastructureKindsSortInput = {
  category?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  /** Unit name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Unit name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Unit name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Unit name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of InfrastructureUpdate. */
export type InfrastructureUpdatePayload = {
  __typename?: "InfrastructureUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Infrastructure>;
};

/** Collection of Infrastructures */
export type Infrastructures = {
  __typename?: "Infrastructures";
  /** Paginated collection of Infrastructures */
  items: Array<Infrastructure>;
  /** Total count of Infrastructures */
  totalCount: Scalars["Int"];
};

/** Infrastructures filter */
export type InfrastructuresFilterInput = {
  /** Infrastructure categories */
  category?: InputMaybe<Array<InfrastructureCategory>>;
  /** Crop center id */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Infrastructure kinds */
  kindId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Infrastructures sorting options */
export type InfrastructuresSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal ID */
  internalId?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  total?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Active ingredient object */
export type Ingredient = Discardable & {
  __typename?: "Ingredient";
  /** Ingredient common names */
  commonNames?: Maybe<Array<Scalars["String"]>>;
  /** Ingredient common names in En locale */
  commonNamesEn?: Maybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Es locale */
  commonNamesEs?: Maybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Pt locale */
  commonNamesPt?: Maybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Ru locale */
  commonNamesRu?: Maybe<Array<Scalars["String"]>>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Ingredient Molecular Formula */
  formula: Scalars["String"];
  id: Scalars["ID"];
  itemCategories: Array<ItemCategory>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Ingredient preferred IUPAC Name (PIN) */
  name: Scalars["String"];
  /** Ingredient preferred IUPAC Name in En locale */
  nameEn?: Maybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Es locale */
  nameEs?: Maybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Pt locale */
  namePt?: Maybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Ru locale */
  nameRu?: Maybe<Scalars["String"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Ingredient changes object */
export type IngredientChanges = {
  /** Ingredient common names */
  commonNames?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient common names in En locale */
  commonNamesEn?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Es locale */
  commonNamesEs?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Pt locale */
  commonNamesPt?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Ru locale */
  commonNamesRu?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient Molecular Formula */
  formula?: InputMaybe<Scalars["String"]>;
  /** Associated categories */
  itemCategoryIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Ingredient preferred IUPAC Name (PIN) */
  name?: InputMaybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of IngredientCreate. */
export type IngredientCreatePayload = {
  __typename?: "IngredientCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Ingredient>;
};

/** Autogenerated return type of IngredientDiscard. */
export type IngredientDiscardPayload = {
  __typename?: "IngredientDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Ingredient>;
};

/** Ingredient field values */
export type IngredientFields = {
  /** Ingredient common names */
  commonNames?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient common names in En locale */
  commonNamesEn?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Es locale */
  commonNamesEs?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Pt locale */
  commonNamesPt?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient common names in Ru locale */
  commonNamesRu?: InputMaybe<Array<Scalars["String"]>>;
  /** Ingredient Molecular Formula */
  formula: Scalars["String"];
  /** Associated categories */
  itemCategoryIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Ingredient preferred IUPAC Name (PIN) */
  name: Scalars["String"];
  /** Ingredient preferred IUPAC Name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Ingredient preferred IUPAC Name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of IngredientUpdate. */
export type IngredientUpdatePayload = {
  __typename?: "IngredientUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Ingredient>;
};

/** Collection of Ingredients */
export type Ingredients = {
  __typename?: "Ingredients";
  /** Paginated collection of Ingredients */
  items: Array<Ingredient>;
  /** Total count of Ingredients */
  totalCount: Scalars["Int"];
};

/** Ingredients filter */
export type IngredientsFilterInput = {
  /** Ingredient common names */
  commonNames?: InputMaybe<StringMatchInput>;
  /** Ingredient common names in En locale */
  commonNamesEn?: InputMaybe<StringMatchInput>;
  /** Ingredient common names in Es locale */
  commonNamesEs?: InputMaybe<StringMatchInput>;
  /** Ingredient common names in Pt locale */
  commonNamesPt?: InputMaybe<StringMatchInput>;
  /** Ingredient common names in Ru locale */
  commonNamesRu?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Ingredient Molecular Formula */
  formula?: InputMaybe<StringMatchInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Ingredient preferred IUPAC Name (PIN) */
  name?: InputMaybe<StringMatchInput>;
  /** Ingredient preferred IUPAC Name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Ingredient preferred IUPAC Name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Ingredient preferred IUPAC Name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Ingredient preferred IUPAC Name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Ingredients sorting options */
export type IngredientsSortInput = {
  /** Ingredient common names */
  commonNames?: InputMaybe<SortDirection>;
  /** Ingredient common names in En locale */
  commonNamesEn?: InputMaybe<SortDirection>;
  /** Ingredient common names in Es locale */
  commonNamesEs?: InputMaybe<SortDirection>;
  /** Ingredient common names in Pt locale */
  commonNamesPt?: InputMaybe<SortDirection>;
  /** Ingredient common names in Ru locale */
  commonNamesRu?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Ingredient Molecular Formula */
  formula?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Ingredient preferred IUPAC Name (PIN) */
  name?: InputMaybe<SortDirection>;
  /** Ingredient preferred IUPAC Name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Ingredient preferred IUPAC Name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Ingredient preferred IUPAC Name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Ingredient preferred IUPAC Name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Input Cost Center Report result */
export type InputCostCenter = HasProfitable & {
  __typename?: "InputCostCenter";
  /** Amount consumed */
  consumedAmount: Scalars["Float"];
  /** Input cost */
  consumedCost?: Maybe<Scalars["Float"]>;
  /** Cost Center */
  costCenter: CostCenter;
  /** Cost per Progress */
  costPerProgress?: Maybe<Scalars["Float"]>;
  /** Dosage */
  dosage?: Maybe<Scalars["Float"]>;
  /** Profitable extra info */
  profitableExtra?: Maybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: Maybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: Maybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: Maybe<ProfitableType>;
  /** Progress */
  progress?: Maybe<Scalars["Float"]>;
  /** Return Warehouse */
  returnWarehouse: Warehouse;
  /** Source Warehouse */
  sourceWarehouse: Warehouse;
  /** Usage unit */
  unit: Unit;
  /** Variant */
  variant: Variant;
  /** Work Order */
  workOrder: WorkOrder;
};

/** Collection of InputCostCenterReport */
export type InputCostCenterReport = {
  __typename?: "InputCostCenterReport";
  /** Paginated collection of InputCostCenterReport */
  items: Array<InputCostCenter>;
  /** Total count of InputCostCenterReport */
  totalCount: Scalars["Int"];
};

/** InputCostCenterReport filter */
export type InputCostCenterReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Crop Cycle Number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Item Category */
  itemCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Profitable extra info */
  profitableExtra?: InputMaybe<StringMatchInput>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Profitable name */
  profitableName?: InputMaybe<StringMatchInput>;
  /** Profitable type */
  profitableType?: InputMaybe<Array<ProfitableType>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Variant */
  variantId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Work Order */
  workOrderId?: InputMaybe<Array<Scalars["ID"]>>;
};

/** InputCostCenterReport sorting options */
export type InputCostCenterReportSortInput = {
  /** Amount consumed */
  consumedAmount?: InputMaybe<SortDirection>;
  /** Input cost */
  consumedCost?: InputMaybe<SortDirection>;
  /** Cost per Progress */
  costPerProgress?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Dosage */
  dosage?: InputMaybe<SortDirection>;
  /** Profitable extra info */
  profitableExtra?: InputMaybe<SortDirection>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<SortDirection>;
  /** Profitable name */
  profitableName?: InputMaybe<SortDirection>;
  /** Profitable type */
  profitableType?: InputMaybe<SortDirection>;
  /** Progress */
  progress?: InputMaybe<SortDirection>;
};

export enum InventoryDocumentFilterType {
  /** Adjustment */
  Adjustment = "adjustment",
  /** Agro Work Order */
  AgroWorkOrder = "agroWorkOrder",
  /** Movement */
  Movement = "movement",
  /** Purchase */
  Purchase = "purchase",
  /** Reconciliation */
  Reconciliation = "reconciliation",
  /** Sale */
  Sale = "sale",
}

/** Inventory document reference */
export type InventoryDocumentReference = {
  __typename?: "InventoryDocumentReference";
  documentDate: Scalars["ISO8601DateTime"];
  documentId: Scalars["ID"];
  documentType: InventoryDocumentType;
};

export enum InventoryDocumentType {
  /** Agro Work Order */
  AgroWorkOrder = "agroWorkOrder",
  /** Inventory Order */
  InventoryOrder = "inventoryOrder",
  /** Inventory Transfer */
  InventoryTransfer = "inventoryTransfer",
}

/** Inventory entry report */
export type InventoryEntry = {
  __typename?: "InventoryEntry";
  amount: Scalars["Float"];
  balance: Scalars["Float"];
  balanceValue?: Maybe<Scalars["Float"]>;
  code: Scalars["String"];
  cost?: Maybe<Scalars["Float"]>;
  documentDate: Scalars["ISO8601DateTime"];
  documentId: Scalars["ID"];
  documentInternalId?: Maybe<Scalars["String"]>;
  documentType: InventoryDocumentType;
  documentValue?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Source location ID */
  locationId: Scalars["ID"];
  /** Source location type */
  locationType: LocationType;
  runningCost?: Maybe<Scalars["Float"]>;
  totalCost: Scalars["Float"];
  /** Variant */
  variant: Variant;
  /** Warehouse */
  warehouse?: Maybe<Warehouse>;
};

export enum InventoryEntryCodeType {
  /** Adjusted */
  Ac = "AC",
  /** Adjusted */
  Ad = "AD",
  /** Circulated */
  Cir = "CIR",
  /** Damaged */
  Dm = "DM",
  /** Expired */
  Ed = "ED",
  /** Found */
  F = "F",
  /** Lost */
  Ls = "LS",
  /** Missing */
  Ms = "MS",
  /** Moved */
  Mv = "MV",
  /** Open Stock */
  Os = "OS",
  /** Purchased */
  Pu = "PU",
  /** Reconciled */
  Rc = "RC",
  /** Translation missing: en.exports.inventory_codes.RV */
  Rv = "RV",
  /** Sale */
  So = "SO",
  /** Stolen */
  St = "ST",
  /** Redeemed */
  Tr = "TR",
  /** Reserved */
  Woa = "WOA",
  /** Consumed */
  Woc = "WOC",
  /** Produced */
  Wop = "WOP",
  /** Returned */
  Wor = "WOR",
  /** Processed */
  Wpr = "WPR",
}

/** Collection of InventoryEntryReport */
export type InventoryEntryReport = {
  __typename?: "InventoryEntryReport";
  /** Paginated collection of InventoryEntryReport */
  items: Array<InventoryEntry>;
  /** Total count of InventoryEntryReport */
  totalCount: Scalars["Int"];
};

/** InventoryEntryReport filter */
export type InventoryEntryReportFilterInput = {
  /** Entry code */
  code?: InputMaybe<Array<InventoryEntryCodeType>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field zones */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  documentDate?: InputMaybe<DateRangeInput>;
  documentId?: InputMaybe<Array<Scalars["ID"]>>;
  documentInternalId?: InputMaybe<StringMatchInput>;
  /** Document Type */
  documentType?: InputMaybe<Array<InventoryDocumentFilterType>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Active Ingredient */
  ingredientId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal Id */
  internalId?: InputMaybe<StringMatchInput>;
  /** Item category */
  itemCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item */
  itemId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Source location ID */
  locationId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Source location type */
  locationType?: InputMaybe<Array<LocationType>>;
  /** Report mode */
  mode?: InputMaybe<Scalars["String"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Variant */
  variantId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Warehouse */
  warehouseId?: InputMaybe<Array<Scalars["ID"]>>;
};

/** InventoryEntryReport sorting options */
export type InventoryEntryReportSortInput = {
  amount?: InputMaybe<SortDirection>;
  balance?: InputMaybe<SortDirection>;
  balanceValue?: InputMaybe<SortDirection>;
  code?: InputMaybe<SortDirection>;
  cost?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  documentDate?: InputMaybe<SortDirection>;
  documentId?: InputMaybe<SortDirection>;
  documentInternalId?: InputMaybe<SortDirection>;
  documentType?: InputMaybe<SortDirection>;
  documentValue?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Source location ID */
  locationId?: InputMaybe<SortDirection>;
  /** Source location type */
  locationType?: InputMaybe<SortDirection>;
  runningCost?: InputMaybe<SortDirection>;
  totalCost?: InputMaybe<SortDirection>;
};

/** Inventory Movement Report result */
export type InventoryMovement = {
  __typename?: "InventoryMovement";
  /** Document cost */
  documentCost: Scalars["Float"];
  documentDate: Scalars["ISO8601DateTime"];
  documentId: Scalars["ID"];
  documentInternalId?: Maybe<Scalars["String"]>;
  documentType: InventoryDocumentType;
  /** Entry code */
  entryCode: Scalars["String"];
  /** Record ID */
  id: Scalars["ID"];
  /** Movement locations */
  locations: Array<InventoryMovementLocation>;
  /** Unit cost */
  unitCost: Scalars["Float"];
  /** Variant */
  variant: Variant;
};

/** Inventory Movement Location */
export type InventoryMovementLocation = {
  __typename?: "InventoryMovementLocation";
  /** Movement amount */
  amount: Scalars["Float"];
  /** Counterparty */
  counterparty?: Maybe<Counterparty>;
  /** Stock after movement */
  endingStock?: Maybe<Scalars["Float"]>;
  /** Source location ID */
  locationId: Scalars["ID"];
  /** Source location type */
  locationType: LocationType;
  /** Stock before movement */
  startingStock?: Maybe<Scalars["Float"]>;
  /** Warehouse */
  warehouse?: Maybe<Warehouse>;
};

/** Collection of InventoryMovementReport */
export type InventoryMovementReport = {
  __typename?: "InventoryMovementReport";
  /** Paginated collection of InventoryMovementReport */
  items: Array<InventoryMovement>;
  /** Total count of InventoryMovementReport */
  totalCount: Scalars["Int"];
};

/** InventoryMovementReport filter */
export type InventoryMovementReportFilterInput = {
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field zones */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  documentDate?: InputMaybe<DateRangeInput>;
  documentId?: InputMaybe<Array<Scalars["ID"]>>;
  documentInternalId?: InputMaybe<StringMatchInput>;
  /** Document Type */
  documentType?: InputMaybe<Array<InventoryDocumentFilterType>>;
  /** Entry code */
  entryCode?: InputMaybe<Array<InventoryEntryCodeType>>;
  /** Record ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Active Ingredient */
  ingredientId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal Id */
  internalId?: InputMaybe<StringMatchInput>;
  /** Item category */
  itemCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item */
  itemId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Report mode */
  mode?: InputMaybe<Scalars["String"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Variant */
  variantId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Warehouse */
  warehouseId?: InputMaybe<Array<Scalars["ID"]>>;
};

/** InventoryMovementReport sorting options */
export type InventoryMovementReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Document cost */
  documentCost?: InputMaybe<SortDirection>;
  documentDate?: InputMaybe<SortDirection>;
  documentId?: InputMaybe<SortDirection>;
  documentInternalId?: InputMaybe<SortDirection>;
  documentType?: InputMaybe<SortDirection>;
  /** Entry code */
  entryCode?: InputMaybe<SortDirection>;
  /** Record ID */
  id?: InputMaybe<SortDirection>;
  /** Unit cost */
  unitCost?: InputMaybe<SortDirection>;
};

/** Inventory order object */
export type InventoryOrder = Auditable &
  Timestamps & {
    __typename?: "InventoryOrder";
    /** Payment account */
    account?: Maybe<Account>;
    /** Counterparty */
    counterparty: Counterparty;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by */
    createdBy?: Maybe<User>;
    /** Order currency */
    currency: Currency;
    /** Currency code */
    currencyCode: Scalars["String"];
    /** Order discount amount */
    discount: Scalars["Float"];
    /** Order discount */
    discountRate: Scalars["Float"];
    /** Document date */
    documentDate: Scalars["ISO8601DateTime"];
    /** Order exchange rate */
    exchangeRate: Scalars["Float"];
    id: Scalars["ID"];
    /** Order internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Related invoices */
    invoices: Array<FinanceInvoice>;
    /** Order kind */
    kind: InventoryOrderKind;
    /** Order line items */
    lineItems: Array<InventoryOrderLineItem>;
    /** Next related documents */
    nextDocuments: Array<InventoryDocumentReference>;
    /** Order notes */
    notes?: Maybe<Scalars["String"]>;
    /** Order other costs */
    otherCosts: Scalars["Float"];
    /** Payment method */
    paymentMethod: PaymentMethod;
    /** Order status */
    status: InventoryOrderStatus;
    /** Order sub total */
    subtotal: Scalars["Float"];
    /** Order tax amount */
    tax: Scalars["Float"];
    /** Order tax */
    taxRate: Scalars["Float"];
    /** Evaluated taxes */
    taxValues: Array<TaxValue>;
    /** Order total */
    total: Scalars["Float"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
    /** Warehouse */
    warehouse: Warehouse;
  };

/** InventoryOrder changes object */
export type InventoryOrderChanges = {
  /** Payment account */
  accountId?: InputMaybe<Scalars["ID"]>;
  /** Counterparty */
  counterpartyId?: InputMaybe<Scalars["ID"]>;
  /** Currency code */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Order discount amount */
  discount?: InputMaybe<Scalars["Float"]>;
  /** Order discount */
  discountRate?: InputMaybe<Scalars["Float"]>;
  /** Document date */
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Order exchange rate */
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  /** Order internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Order kind */
  kind?: InputMaybe<InventoryOrderKind>;
  /** Order line items */
  lineItemsAttributes?: InputMaybe<Array<InventoryOrderLineItemChanges>>;
  /** Order notes */
  notes?: InputMaybe<Scalars["String"]>;
  /** Order other costs */
  otherCosts?: InputMaybe<Scalars["Float"]>;
  /** Payment method */
  paymentMethodId?: InputMaybe<Scalars["ID"]>;
  /** Order status */
  status?: InputMaybe<InventoryOrderStatus>;
  /** Order sub total */
  subtotal?: InputMaybe<Scalars["Float"]>;
  /** Order tax amount */
  tax?: InputMaybe<Scalars["Float"]>;
  /** Order tax */
  taxRate?: InputMaybe<Scalars["Float"]>;
  /** Order total */
  total?: InputMaybe<Scalars["Float"]>;
  /** Warehouse */
  warehouseId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of InventoryOrderClone. */
export type InventoryOrderClonePayload = {
  __typename?: "InventoryOrderClonePayload";
  errors: Array<UserError>;
  result?: Maybe<InventoryOrder>;
};

/** Autogenerated return type of InventoryOrderCreate. */
export type InventoryOrderCreatePayload = {
  __typename?: "InventoryOrderCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<InventoryOrder>;
};

/** InventoryOrder field values */
export type InventoryOrderFields = {
  /** Payment account */
  accountId?: InputMaybe<Scalars["ID"]>;
  /** Counterparty */
  counterpartyId: Scalars["ID"];
  /** Currency code */
  currencyCode: Scalars["String"];
  /** Order discount */
  discountRate: Scalars["Float"];
  /** Document date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Order exchange rate */
  exchangeRate: Scalars["Float"];
  /** Order internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Order kind */
  kind: InventoryOrderKind;
  /** Order line items */
  lineItemsAttributes: Array<InventoryOrderLineItemFields>;
  /** Order notes */
  notes?: InputMaybe<Scalars["String"]>;
  /** Order other costs */
  otherCosts: Scalars["Float"];
  /** Payment method */
  paymentMethodId: Scalars["ID"];
  /** Order status */
  status: InventoryOrderStatus;
  /** Order tax */
  taxRate: Scalars["Float"];
  /** Warehouse */
  warehouseId: Scalars["ID"];
};

export enum InventoryOrderKind {
  /** Purchase */
  Purchase = "purchase",
  /** Sales */
  Sale = "sale",
}

/** Inventory order line item object */
export type InventoryOrderLineItem = Timestamps & {
  __typename?: "InventoryOrderLineItem";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Unit discount */
  discount?: Maybe<Scalars["Float"]>;
  /** Unit discount rate */
  discountRate?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Line item amount */
  quantity: Scalars["Float"];
  /** Subtotal */
  subtotal: Scalars["Float"];
  /** Tax plan evaluated values */
  taxPlanValues: Array<TaxValue>;
  /** Line item unit cost */
  unitCost: Scalars["Float"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Variant */
  variant: Variant;
};

/** InventoryOrderLineItem changes object */
export type InventoryOrderLineItemChanges = {
  /** Remove line item */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Unit discount */
  discount?: InputMaybe<Scalars["Float"]>;
  /** Unit discount rate */
  discountRate?: InputMaybe<Scalars["Float"]>;
  /** Order line item ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Line item amount */
  quantity?: InputMaybe<Scalars["Float"]>;
  /** Subtotal */
  subtotal?: InputMaybe<Scalars["Float"]>;
  /** Line item unit cost */
  unitCost?: InputMaybe<Scalars["Float"]>;
  /** Variant ID */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** InventoryOrderLineItem field values */
export type InventoryOrderLineItemFields = {
  /** Unit discount */
  discount?: InputMaybe<Scalars["Float"]>;
  /** Unit discount rate */
  discountRate?: InputMaybe<Scalars["Float"]>;
  /** Line item amount */
  quantity: Scalars["Float"];
  /** Line item unit cost */
  unitCost: Scalars["Float"];
  /** Variant ID */
  variantId: Scalars["ID"];
};

export enum InventoryOrderStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** Completing */
  Completing = "completing",
  /** Open */
  Open = "open",
  /** Reverted */
  Reverted = "reverted",
  /** Reverting */
  Reverting = "reverting",
}

/** Autogenerated return type of InventoryOrderUpdate. */
export type InventoryOrderUpdatePayload = {
  __typename?: "InventoryOrderUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<InventoryOrder>;
};

/** Collection of InventoryOrders */
export type InventoryOrders = {
  __typename?: "InventoryOrders";
  /** Paginated collection of InventoryOrders */
  items: Array<InventoryOrder>;
  /** Total count of InventoryOrders */
  totalCount: Scalars["Int"];
};

/** InventoryOrders filter */
export type InventoryOrdersFilterInput = {
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Counterparty */
  counterpartyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Currency code */
  currencyCode?: InputMaybe<StringMatchInput>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Order internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Order kind */
  kind?: InputMaybe<Array<InventoryOrderKind>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Order notes */
  notes?: InputMaybe<StringMatchInput>;
  /** Payment Method */
  paymentMethodId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Show in currency */
  showCurrencyCode?: InputMaybe<Scalars["String"]>;
  /** Order status */
  status?: InputMaybe<Array<InventoryOrderStatus>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Warehouse */
  warehouseId?: InputMaybe<Scalars["ID"]>;
};

/** InventoryOrders sorting options */
export type InventoryOrdersSortInput = {
  /** Sort by counterparty name */
  counterpartyName?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Currency code */
  currencyCode?: InputMaybe<SortDirection>;
  /** Order discount amount */
  discount?: InputMaybe<SortDirection>;
  /** Order discount */
  discountRate?: InputMaybe<SortDirection>;
  /** Document date */
  documentDate?: InputMaybe<SortDirection>;
  /** Order exchange rate */
  exchangeRate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Order internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Order kind */
  kind?: InputMaybe<SortDirection>;
  /** Order notes */
  notes?: InputMaybe<SortDirection>;
  /** Order other costs */
  otherCosts?: InputMaybe<SortDirection>;
  /** Order status */
  status?: InputMaybe<SortDirection>;
  /** Order sub total */
  subtotal?: InputMaybe<SortDirection>;
  /** Order tax amount */
  tax?: InputMaybe<SortDirection>;
  /** Order tax */
  taxRate?: InputMaybe<SortDirection>;
  /** Order total */
  total?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Sort by warehouse name */
  warehouseName?: InputMaybe<SortDirection>;
};

/** Inventory Stock Request */
export type InventoryRequest = Timestamps & {
  __typename?: "InventoryRequest";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Requestor user */
  createdBy: User;
  id: Scalars["ID"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Variants */
  variants: Array<WorkOrderVariant>;
  /** Work Order */
  workOrder: WorkOrder;
};

/** Inventory request changes object */
export type InventoryRequestChanges = {
  /** Complete request? */
  completeRequest?: InputMaybe<Scalars["Boolean"]>;
  /** Variants */
  variantsAttributes?: InputMaybe<Array<InventoryRequestVariantChanges>>;
};

/** Inventory request variant changes object */
export type InventoryRequestVariantChanges = {
  /** Assigned amount */
  assignedAmount?: InputMaybe<Scalars["Float"]>;
  confirmedReturnedAmount?: InputMaybe<Scalars["Float"]>;
  /** Work order variant ID */
  id: Scalars["ID"];
  /** Return warehouse */
  returnWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Source warehouse */
  sourceWarehouseId?: InputMaybe<Scalars["ID"]>;
};

/** Collection of InventoryRequests */
export type InventoryRequests = {
  __typename?: "InventoryRequests";
  /** Paginated collection of InventoryRequests */
  items: Array<InventoryRequest>;
  /** Total count of InventoryRequests */
  totalCount: Scalars["Int"];
};

/** InventoryRequests filter */
export type InventoryRequestsFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Status */
  status?: InputMaybe<Array<InventoryStatusFilter>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** InventoryRequests sorting options */
export type InventoryRequestsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum InventoryStatus {
  /** Assigned */
  Assigned = "assigned",
  /** Canceled */
  Canceled = "canceled",
  /** Not_requested */
  NotRequested = "notRequested",
  /** Produced */
  Produced = "produced",
  /** Requesting */
  Requesting = "requesting",
  /** Returning */
  Returning = "returning",
  /** Reverted */
  Reverted = "reverted",
  /** Used */
  Used = "used",
}

export enum InventoryStatusFilter {
  /** Assigned */
  Assigned = "assigned",
  /** Canceled */
  Canceled = "canceled",
  /** Not_requested */
  NotRequested = "notRequested",
  /** Requesting / Returning */
  RequestingReturning = "requestingReturning",
  /** Reverted */
  Reverted = "reverted",
  /** Used */
  Used = "used",
}

/** Autogenerated return type of InventoryTransferClone. */
export type InventoryTransferClonePayload = {
  __typename?: "InventoryTransferClonePayload";
  errors: Array<UserError>;
  result?: Maybe<Transfer>;
};

/** Invoice changes object */
export type InvoiceChanges = {
  /** Amount paid */
  amountPaid?: InputMaybe<Scalars["Float"]>;
  /** Amount to pay */
  amountToPay?: InputMaybe<Scalars["Float"]>;
  /** Currency code */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Document date */
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Document date */
  dueDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Invoice internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Invoice status */
  invoiceStatus?: InputMaybe<InvoiceStatus>;
  /** Payment status */
  paymentStatus?: InputMaybe<PaymentStatus>;
  sourceDocumentCode?: InputMaybe<Scalars["String"]>;
  sourceDocumentId?: InputMaybe<Scalars["ID"]>;
  sourceDocumentType?: InputMaybe<FinanceDocumentType>;
  /** Invoice status */
  status?: InputMaybe<FinanceInvoiceStatus>;
  /** Invoice total */
  total?: InputMaybe<Scalars["Float"]>;
};

export type InvoiceOverviewReportItem = {
  __typename?: "InvoiceOverviewReportItem";
  /** Total amount of invoices */
  totalAmount: Scalars["Float"];
  /** Total amount of paid invoices */
  totalAmountPaid: Scalars["Float"];
};

/** Invoice report filter input */
export type InvoiceReportFilterInput = {
  /** Vendor */
  counterpartyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Vendor Internal ID */
  counterpartyInternalId?: InputMaybe<Scalars["String"]>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Kind */
  kind?: InputMaybe<InventoryOrderKind>;
  /** Payment ID */
  paymentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Payment Internal ID */
  paymentInternalId?: InputMaybe<Scalars["String"]>;
  /** Document number */
  sourceDocumentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Document Internal ID */
  sourceDocumentInternalId?: InputMaybe<Scalars["String"]>;
};

export enum InvoiceStatus {
  /** Invoice canceled */
  Canceled = "canceled",
  /** Invoice competed */
  Completed = "completed",
  /** > 1 week from due date */
  Due = "due",
  /** < 1 week from due date */
  DueSoon = "due_soon",
  /** Invoice open */
  Open = "open",
  /** Any day after invoice was due */
  Overdue = "overdue",
  /** Invoice reverted */
  Reverted = "reverted",
}

/** Collection of InvoiceSummaryReport */
export type InvoiceSummaryReport = {
  __typename?: "InvoiceSummaryReport";
  /** Paginated collection of InvoiceSummaryReport */
  items: Array<InvoiceSummaryReportItem>;
  /** Total count of InvoiceSummaryReport */
  totalCount: Scalars["Int"];
};

export type InvoiceSummaryReportItem = {
  __typename?: "InvoiceSummaryReportItem";
  /** Vendor */
  counterparty: Counterparty;
  /** Number of invoices */
  invoicesCount: Scalars["Int"];
  /** Total amount invoices */
  totalAmount: Scalars["Float"];
  /** Total amount of paid invoices */
  totalAmountPaid: Scalars["Float"];
};

/** InvoiceSummaryReport sorting options */
export type InvoiceSummaryReportSortInput = {
  /** Sorts by counterparty name */
  counterpartyName?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Number of invoices */
  invoicesCount?: InputMaybe<SortDirection>;
  /** Total amount invoices */
  totalAmount?: InputMaybe<SortDirection>;
  /** Total amount of paid invoices */
  totalAmountPaid?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of InvoiceUpdate. */
export type InvoiceUpdatePayload = {
  __typename?: "InvoiceUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<FinanceInvoice>;
};

/** Inventory Item (Product) */
export type Item = Discardable & {
  __typename?: "Item";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Crop */
  crop?: Maybe<Crop>;
  /** Default Tax Plan */
  defaultTaxPlan?: Maybe<TaxPlan>;
  /** Item description */
  description?: Maybe<Scalars["String"]>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Target diseases */
  diseases: Array<Disease>;
  /** Re-entry period */
  entryPeriod?: Maybe<Scalars["Int"]>;
  /** Re-entry period unit */
  entryPeriodUnit?: Maybe<Unit>;
  /** Required PPE */
  equipment: Array<Equipment>;
  id: Scalars["ID"];
  /** Item category */
  itemCategory: ItemCategory;
  /** Item crop varieties */
  itemCropVarieties: Array<ItemCropVariety>;
  /** Item active ingrediants */
  itemIngredients: Array<ItemIngredient>;
  kind: ItemKind;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** May update variation unit or item kind */
  mayUpdateUnitKind: Scalars["Boolean"];
  /** Item name */
  name: Scalars["String"];
  /** Re-entry period */
  preHarvestInterval?: Maybe<Scalars["Int"]>;
  /** Re-entry period unit */
  preHarvestIntervalUnit?: Maybe<Unit>;
  /** Field tags */
  tags?: Maybe<Array<Scalars["String"]>>;
  /** Assigned Tax Plan */
  taxPlan?: Maybe<TaxPlan>;
  /** Toxicity category */
  toxicityCategory?: Maybe<ToxicityCategory>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Item variants */
  variants: Array<Variant>;
  /** Variation unit type */
  variationUnitType: UnitType;
};

/** Autogenerated return type of ItemBulkBulkUpdate. */
export type ItemBulkBulkUpdatePayload = {
  __typename?: "ItemBulkBulkUpdatePayload";
  result: Array<BulkOperationResult>;
};

/** ItemBulk changes object */
export type ItemBulkChanges = {
  /** Tax Plan */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
};

/** Collection of ItemCategories */
export type ItemCategories = {
  __typename?: "ItemCategories";
  /** Paginated collection of ItemCategories */
  items: Array<ItemCategory>;
  /** Total count of ItemCategories */
  totalCount: Scalars["Int"];
};

/** ItemCategories filter */
export type ItemCategoriesFilterInput = {
  /** Allow to assign ingredients */
  allowIngredients?: InputMaybe<Scalars["Boolean"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Default item kind */
  defaultItemKind?: InputMaybe<Array<ItemKind>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Category name */
  name?: InputMaybe<StringMatchInput>;
  /** Category name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Parent category */
  parentCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Sort position */
  sortPosition?: InputMaybe<Array<Scalars["Int"]>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** ItemCategories sorting options */
export type ItemCategoriesSortInput = {
  /** Allow to assign ingredients */
  allowIngredients?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Default item kind */
  defaultItemKind?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Category name */
  name?: InputMaybe<SortDirection>;
  /** Category name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Sort position */
  sortPosition?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Inventory Item Category object */
export type ItemCategory = Discardable &
  Global & {
    __typename?: "ItemCategory";
    /** Allow to assign ingredients */
    allowIngredients: Scalars["Boolean"];
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Default item kind */
    defaultItemKind?: Maybe<ItemKind>;
    /** Default Tax Plan */
    defaultTaxPlan?: Maybe<TaxPlan>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Category name */
    name: Scalars["String"];
    /** Category name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Category name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Category name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Category name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Parent category */
    parentCategory?: Maybe<ItemCategory>;
    /** Sort position */
    sortPosition?: Maybe<Scalars["Int"]>;
    /** Subcategories */
    subcategories: Array<ItemCategory>;
    /** Assigned Tax Plan */
    taxPlan?: Maybe<TaxPlan>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** ItemCategory changes object */
export type ItemCategoryChanges = {
  /** Default item kind */
  defaultItemKind?: InputMaybe<ItemKind>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Category name */
  name?: InputMaybe<Scalars["String"]>;
  /** Category name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Parent category */
  parentCategoryId?: InputMaybe<Scalars["ID"]>;
  /** Sort position */
  sortPosition?: InputMaybe<Scalars["Int"]>;
  /** Expense account */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of ItemCategoryCreate. */
export type ItemCategoryCreatePayload = {
  __typename?: "ItemCategoryCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<ItemCategory>;
};

/** Autogenerated return type of ItemCategoryDestroy. */
export type ItemCategoryDestroyPayload = {
  __typename?: "ItemCategoryDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of ItemCategoryDiscard. */
export type ItemCategoryDiscardPayload = {
  __typename?: "ItemCategoryDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<ItemCategory>;
};

/** ItemCategory field values */
export type ItemCategoryFields = {
  /** Default item kind */
  defaultItemKind?: InputMaybe<ItemKind>;
  /** Is global? */
  global: Scalars["Boolean"];
  /** Is locked */
  locked: Scalars["Boolean"];
  /** Category name */
  name: Scalars["String"];
  /** Category name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Category name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Category name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Category name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Parent category */
  parentCategoryId?: InputMaybe<Scalars["ID"]>;
  /** Sort position */
  sortPosition?: InputMaybe<Scalars["Int"]>;
  /** Tax Plan */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of ItemCategoryUnlock. */
export type ItemCategoryUnlockPayload = {
  __typename?: "ItemCategoryUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<ItemCategory>;
};

/** Autogenerated return type of ItemCategoryUpdate. */
export type ItemCategoryUpdatePayload = {
  __typename?: "ItemCategoryUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<ItemCategory>;
};

/** Item changes object */
export type ItemChanges = {
  /** Crop */
  cropId?: InputMaybe<Scalars["ID"]>;
  /** Item description */
  description?: InputMaybe<Scalars["String"]>;
  /** Target diseases */
  diseaseIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Re-entry period */
  entryPeriod?: InputMaybe<Scalars["Int"]>;
  /** Entry Period U/M */
  entryPeriodUnitId?: InputMaybe<Scalars["ID"]>;
  /** Equipment */
  equipmentIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item category */
  itemCategoryId?: InputMaybe<Scalars["ID"]>;
  /** Item crop varieties */
  itemCropVarietiesAttributes?: InputMaybe<Array<ItemCropVarietyChanges>>;
  /** Item ingredients */
  itemIngredientsAttributes?: InputMaybe<Array<ItemIngredientChanges>>;
  kind?: InputMaybe<ItemKind>;
  /** Item name */
  name?: InputMaybe<Scalars["String"]>;
  /** Re-entry period */
  preHarvestInterval?: InputMaybe<Scalars["Int"]>;
  /** Entry Period U/M */
  preHarvestIntervalUnitId?: InputMaybe<Scalars["ID"]>;
  /** Field tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Item tax plan */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
  /** Toxicity category */
  toxicityCategory?: InputMaybe<ToxicityCategory>;
  /** Item variants */
  variantsAttributes?: InputMaybe<Array<VariantChanges>>;
  /** Variation unit type */
  variationUnitType?: InputMaybe<UnitType>;
};

/** Autogenerated return type of ItemCreate. */
export type ItemCreatePayload = {
  __typename?: "ItemCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Item>;
};

/** Item crop variety */
export type ItemCropVariety = {
  __typename?: "ItemCropVariety";
  /** Crop variety */
  cropVariety: CropVariety;
  id: Scalars["ID"];
};

/** ItemCropVariety changes object */
export type ItemCropVarietyChanges = {
  /** Remove crop variety */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Crop variety ID */
  cropVarietyId?: InputMaybe<Scalars["ID"]>;
  /** Item Crop Variety ID */
  id?: InputMaybe<Scalars["ID"]>;
};

/** ItemCropVariety field values */
export type ItemCropVarietyFields = {
  /** Crop variety ID */
  cropVarietyId: Scalars["ID"];
};

/** Autogenerated return type of ItemDestroy. */
export type ItemDestroyPayload = {
  __typename?: "ItemDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of ItemDiscard. */
export type ItemDiscardPayload = {
  __typename?: "ItemDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Item>;
};

/** Item field values */
export type ItemFields = {
  /** Crop */
  cropId?: InputMaybe<Scalars["ID"]>;
  /** Item description */
  description?: InputMaybe<Scalars["String"]>;
  /** Re-entry period */
  entryPeriod?: InputMaybe<Scalars["Int"]>;
  /** Item category */
  itemCategoryId: Scalars["ID"];
  /** Item crop varieties */
  itemCropVarietiesAttributes?: InputMaybe<Array<ItemCropVarietyFields>>;
  /** Item ingredients */
  itemIngredientsAttributes?: InputMaybe<Array<ItemIngredientFields>>;
  kind: ItemKind;
  /** Item name */
  name: Scalars["String"];
  /** Re-entry period */
  preHarvestInterval?: InputMaybe<Scalars["Int"]>;
  /** Field tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Item tax plan */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
  /** Toxicity category */
  toxicityCategory?: InputMaybe<ToxicityCategory>;
  /** Item variants */
  variantsAttributes: Array<VariantFields>;
  /** Variation unit type */
  variationUnitType: UnitType;
};

/** Item active ingredient concentration */
export type ItemIngredient = {
  __typename?: "ItemIngredient";
  id: Scalars["ID"];
  /** Ingredient */
  ingredient: Ingredient;
  /** Ingredient concentration rate */
  rate: Scalars["Float"];
};

/** ItemIngredient changes object */
export type ItemIngredientChanges = {
  /** Remove ingredient */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Item Ingredient ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Ingredient ID */
  ingredientId?: InputMaybe<Scalars["ID"]>;
  /** Ingredient concentration rate */
  rate?: InputMaybe<Scalars["Float"]>;
};

/** ItemIngredient field values */
export type ItemIngredientFields = {
  /** Ingredient ID */
  ingredientId: Scalars["ID"];
  /** Ingredient concentration rate */
  rate: Scalars["Float"];
};

export enum ItemKind {
  /** Consumable */
  Consumable = "consumable",
  /** Producible */
  Producible = "producible",
  /** Reusable */
  Reusable = "reusable",
}

/** Inventory Item (Product) */
export type ItemSeed = Discardable & {
  __typename?: "ItemSeed";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Crop */
  crop?: Maybe<Crop>;
  /** Default Tax Plan */
  defaultTaxPlan?: Maybe<TaxPlan>;
  /** Item description */
  description?: Maybe<Scalars["String"]>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Target diseases */
  diseases: Array<Disease>;
  /** Re-entry period */
  entryPeriod?: Maybe<Scalars["Int"]>;
  /** Re-entry period unit */
  entryPeriodUnit?: Maybe<Unit>;
  /** Required PPE */
  equipment: Array<Equipment>;
  id: Scalars["ID"];
  /** Item category */
  itemCategory: ItemCategory;
  /** Item crop varieties */
  itemCropVarieties: Array<ItemCropVariety>;
  /** Item active ingrediants */
  itemIngredients: Array<ItemIngredient>;
  kind: ItemKind;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** May update variation unit or item kind */
  mayUpdateUnitKind: Scalars["Boolean"];
  /** Item name */
  name: Scalars["String"];
  persisted: Scalars["Boolean"];
  /** Re-entry period */
  preHarvestInterval?: Maybe<Scalars["Int"]>;
  /** Re-entry period unit */
  preHarvestIntervalUnit?: Maybe<Unit>;
  /** Field tags */
  tags?: Maybe<Array<Scalars["String"]>>;
  /** Assigned Tax Plan */
  taxPlan?: Maybe<TaxPlan>;
  /** Toxicity category */
  toxicityCategory?: Maybe<ToxicityCategory>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Item variants */
  variants: Array<Variant>;
  /** Variation unit type */
  variationUnitType: UnitType;
};

/** Autogenerated return type of ItemUpdate. */
export type ItemUpdatePayload = {
  __typename?: "ItemUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Item>;
};

/** Collection of Items */
export type Items = {
  __typename?: "Items";
  /** Paginated collection of Items */
  items: Array<Item>;
  /** Total count of Items */
  totalCount: Scalars["Int"];
};

/** Items filter */
export type ItemsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item description */
  description?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Re-entry period */
  entryPeriod?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Ingredient */
  ingredientId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal Id */
  internalId?: InputMaybe<StringMatchInput>;
  /** Item category */
  itemCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  kind?: InputMaybe<Array<ItemKind>>;
  /** May update variation unit or item kind */
  mayUpdateUnitKind?: InputMaybe<Scalars["Boolean"]>;
  /** Item name */
  name?: InputMaybe<StringMatchInput>;
  /** Re-entry period */
  preHarvestInterval?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Field tags */
  tags?: InputMaybe<StringMatchInput>;
  /** Toxicity category */
  toxicityCategory?: InputMaybe<Array<ToxicityCategory>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Variation unit type */
  variationUnitType?: InputMaybe<Array<UnitType>>;
};

/** Items sorting options */
export type ItemsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Item description */
  description?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Re-entry period */
  entryPeriod?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Sort by item category name */
  itemCategoryName?: InputMaybe<SortDirection>;
  kind?: InputMaybe<SortDirection>;
  /** May update variation unit or item kind */
  mayUpdateUnitKind?: InputMaybe<SortDirection>;
  /** Item name */
  name?: InputMaybe<SortDirection>;
  /** Re-entry period */
  preHarvestInterval?: InputMaybe<SortDirection>;
  /** Field tags */
  tags?: InputMaybe<SortDirection>;
  /** Toxicity category */
  toxicityCategory?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Variation unit type */
  variationUnitType?: InputMaybe<SortDirection>;
};

/** Collection of JournalEntries */
export type JournalEntries = {
  __typename?: "JournalEntries";
  /** Paginated collection of JournalEntries */
  items: Array<JournalEntry>;
  /** Total count of JournalEntries */
  totalCount: Scalars["Int"];
};

/** JournalEntries filter */
export type JournalEntriesFilterInput = {
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  internalId?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  sourceDocumentId?: InputMaybe<Array<Scalars["ID"]>>;
  sourceDocumentType?: InputMaybe<Array<FinanceDocumentType>>;
  /** Journal entry status */
  status?: InputMaybe<Array<JournalEntryStatus>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** JournalEntries sorting options */
export type JournalEntriesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Document date */
  documentDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  internalId?: InputMaybe<SortDirection>;
  sourceDocumentId?: InputMaybe<SortDirection>;
  sourceDocumentType?: InputMaybe<SortDirection>;
  /** Journal entry status */
  status?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Journal Entry object */
export type JournalEntry = Auditable &
  Timestamps & {
    __typename?: "JournalEntry";
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by */
    createdBy?: Maybe<User>;
    /** Document date */
    documentDate: Scalars["ISO8601DateTime"];
    /** Entry lines */
    entryLines: Array<JournalEntryLine>;
    id: Scalars["ID"];
    internalId?: Maybe<Scalars["String"]>;
    sourceDocumentId?: Maybe<Scalars["ID"]>;
    sourceDocumentType?: Maybe<FinanceDocumentType>;
    /** Journal entry status */
    status: JournalEntryStatus;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
  };

/** JournalEntry changes object */
export type JournalEntryChanges = {
  /** Document date */
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Entry lines */
  entryLinesAttributes?: InputMaybe<Array<JournalEntryLineChanges>>;
  internalId?: InputMaybe<Scalars["String"]>;
  sourceDocumentId?: InputMaybe<Scalars["ID"]>;
  sourceDocumentType?: InputMaybe<FinanceDocumentType>;
  /** Journal entry status */
  status?: InputMaybe<JournalEntryStatus>;
};

/** Autogenerated return type of JournalEntryClone. */
export type JournalEntryClonePayload = {
  __typename?: "JournalEntryClonePayload";
  errors: Array<UserError>;
  result?: Maybe<JournalEntry>;
};

/** Autogenerated return type of JournalEntryCreate. */
export type JournalEntryCreatePayload = {
  __typename?: "JournalEntryCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<JournalEntry>;
};

/** JournalEntry field values */
export type JournalEntryFields = {
  /** Document date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Transaction Accounts */
  entryLinesAttributes: Array<JournalEntryLineFields>;
  internalId?: InputMaybe<Scalars["String"]>;
  sourceDocumentId?: InputMaybe<Scalars["ID"]>;
  sourceDocumentType?: InputMaybe<FinanceDocumentType>;
  /** Journal entry status */
  status: JournalEntryStatus;
};

/** Journal entry line object */
export type JournalEntryLine = Timestamps & {
  __typename?: "JournalEntryLine";
  /** Associated account */
  account: Account;
  /** Transaction amount */
  amount?: Maybe<Scalars["Float"]>;
  /** Cost Center */
  costCenter?: Maybe<CostCenter>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Is debit */
  debit: Scalars["Boolean"];
  /** Transaction description */
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Profitable ID (sub cost center) */
  profitableId?: Maybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: Maybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: Maybe<ProfitableType>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** JournalEntryLine changes object */
export type JournalEntryLineChanges = {
  /** Remove transaction account */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Account */
  accountId?: InputMaybe<Scalars["ID"]>;
  /** Transaction amount */
  amount?: InputMaybe<Scalars["Float"]>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Is debit */
  debit?: InputMaybe<Scalars["Boolean"]>;
  /** Transaction description */
  description?: InputMaybe<Scalars["String"]>;
  /** Transaction account ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: InputMaybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: InputMaybe<ProfitableType>;
};

/** JournalEntryLine field values */
export type JournalEntryLineFields = {
  /** Account */
  accountId: Scalars["ID"];
  /** Transaction amount */
  amount?: InputMaybe<Scalars["Float"]>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Is debit */
  debit: Scalars["Boolean"];
  /** Transaction description */
  description?: InputMaybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: InputMaybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: InputMaybe<ProfitableType>;
};

export enum JournalEntryStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** Open */
  Open = "open",
  /** Reverted */
  Reverted = "reverted",
}

/** Autogenerated return type of JournalEntryUpdate. */
export type JournalEntryUpdatePayload = {
  __typename?: "JournalEntryUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<JournalEntry>;
};

/** Collection of JournalReport */
export type JournalReport = {
  __typename?: "JournalReport";
  /** Paginated collection of JournalReport */
  items: Array<JournalTransaction>;
  /** Total count of JournalReport */
  totalCount: Scalars["Int"];
};

export enum JournalReportEntryDocumentType {
  /** PU */
  Pu = "PU",
  /** SO */
  So = "SO",
  /** WOI */
  Woi = "WOI",
  /** WOW */
  Wow = "WOW",
  /** Agro Work Order */
  AgroWorkOrder = "agroWorkOrder",
  /** Finance Journal Entry */
  FinanceJournalEntry = "financeJournalEntry",
  /** Finance Order */
  FinanceOrder = "financeOrder",
  /** Finance Payment */
  FinancePayment = "financePayment",
  /** HR Leave Assignment */
  HrLeaveAssignment = "hrLeaveAssignment",
  /** HR Payroll Entry */
  HrPayrollEntry = "hrPayrollEntry",
  /** Inventory Order */
  InventoryOrder = "inventoryOrder",
  /** Inventory Transfer */
  InventoryTransfer = "inventoryTransfer",
}

/** JournalReport filter */
export type JournalReportFilterInput = {
  /** Account */
  accountId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Transaction code */
  code?: InputMaybe<StringMatchInput>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date */
  date?: InputMaybe<DateRangeInput>;
  /** Description */
  description?: InputMaybe<StringMatchInput>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Document ID */
  documentId?: InputMaybe<Array<Scalars["ID"]>>;
  documentInternalId?: InputMaybe<StringMatchInput>;
  /** Document type */
  documentType?: InputMaybe<Array<JournalReportEntryDocumentType>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Show cost centers */
  showCostCenters?: InputMaybe<Scalars["Boolean"]>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** JournalReport sorting options */
export type JournalReportSortInput = {
  /** Transaction code */
  code?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Date */
  date?: InputMaybe<SortDirection>;
  /** Description */
  description?: InputMaybe<SortDirection>;
  /** Document ID */
  documentId?: InputMaybe<SortDirection>;
  documentInternalId?: InputMaybe<SortDirection>;
  /** Document Type */
  documentType?: InputMaybe<SortDirection>;
  /** Total credit */
  totalCredit?: InputMaybe<SortDirection>;
  /** Total debit */
  totalDebit?: InputMaybe<SortDirection>;
};

/** Journal Report result */
export type JournalTransaction = {
  __typename?: "JournalTransaction";
  /** Transaction code */
  code: Scalars["String"];
  /** Date */
  date: Scalars["ISO8601DateTime"];
  /** Description */
  description?: Maybe<Scalars["String"]>;
  /** Document ID */
  documentId: Scalars["ID"];
  documentInternalId?: Maybe<Scalars["String"]>;
  /** Document Type */
  documentType: FinanceDocumentType;
  /** Transaction entries */
  entries: Array<JournalTransactionEntry>;
  /** Total credit */
  totalCredit: Scalars["Float"];
  /** Total debit */
  totalDebit: Scalars["Float"];
};

/** Journal Transaction Entry result */
export type JournalTransactionEntry = {
  __typename?: "JournalTransactionEntry";
  /** Account */
  account: Account;
  /** Amount */
  amount: Scalars["Float"];
  /** Cost Center */
  costCenter?: Maybe<CostCenter>;
};

/** Labor Activity Report Result */
export type LaborActivity = {
  __typename?: "LaborActivity";
  /** Absent man days */
  absentDays: Scalars["Float"];
  /** Activity */
  activity: Activity;
  /** Efficiency */
  efficiency?: Maybe<Scalars["Float"]>;
  /** Efficiency per $ */
  efficiencyCost?: Maybe<Scalars["Float"]>;
  /** Employee */
  employee: Employee;
  /** Hours Worked */
  hours: Scalars["Float"];
  /** Man days */
  manDays: Scalars["Float"];
  /** Overtime Hours Worked */
  overtimeHours: Scalars["Float"];
  /** Activity Progress */
  progress: Scalars["Float"];
  /** Total Cost */
  totalCost: Scalars["Float"];
};

/** Collection of LaborActivityReport */
export type LaborActivityReport = {
  __typename?: "LaborActivityReport";
  /** Paginated collection of LaborActivityReport */
  items: Array<LaborActivity>;
  /** Total count of LaborActivityReport */
  totalCount: Scalars["Int"];
};

/** LaborActivityReport sorting options */
export type LaborActivityReportSortInput = {
  /** Absent man days */
  absentDays?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Efficiency */
  efficiency?: InputMaybe<SortDirection>;
  /** Efficiency per $ */
  efficiencyCost?: InputMaybe<SortDirection>;
  /** Hours Worked */
  hours?: InputMaybe<SortDirection>;
  /** Man days */
  manDays?: InputMaybe<SortDirection>;
  /** Overtime Hours Worked */
  overtimeHours?: InputMaybe<SortDirection>;
  /** Activity Progress */
  progress?: InputMaybe<SortDirection>;
  /** Total Cost */
  totalCost?: InputMaybe<SortDirection>;
};

/** Labor report filter input */
export type LaborReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity category */
  categoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Employee */
  employeeId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Position */
  positionId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Work Order type */
  workOrderType?: InputMaybe<Array<WorkOrderType>>;
};

/** Labor Summary Report Result */
export type LaborSummary = {
  __typename?: "LaborSummary";
  /** Absence rate */
  absenceRate: Scalars["Float"];
  /** Absent man days */
  absentDays: Scalars["Float"];
  /** Activities Count */
  activitiesCount: Scalars["Int"];
  /** Total Cost per day */
  costPerDay: Scalars["Float"];
  /** Employee */
  employee: Employee;
  /** Man days */
  manDays: Scalars["Float"];
  /** Total Cost */
  totalCost: Scalars["Float"];
};

/** Collection of LaborSummaryReport */
export type LaborSummaryReport = {
  __typename?: "LaborSummaryReport";
  /** Paginated collection of LaborSummaryReport */
  items: Array<LaborSummary>;
  /** Total count of LaborSummaryReport */
  totalCount: Scalars["Int"];
};

/** LaborSummaryReport sorting options */
export type LaborSummaryReportSortInput = {
  /** Absence rate */
  absenceRate?: InputMaybe<SortDirection>;
  /** Absent man days */
  absentDays?: InputMaybe<SortDirection>;
  /** Activities Count */
  activitiesCount?: InputMaybe<SortDirection>;
  /** Total Cost per day */
  costPerDay?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Man days */
  manDays?: InputMaybe<SortDirection>;
  /** Total Cost */
  totalCost?: InputMaybe<SortDirection>;
};

/** Leave assignment object */
export type LeaveAssignment = Timestamps & {
  __typename?: "LeaveAssignment";
  /** Completed by user */
  completedBy?: Maybe<User>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Creator */
  createdBy: User;
  documentDate: Scalars["ISO8601DateTime"];
  /** Employees */
  employees: Array<LeaveAssignmentEmployee>;
  id: Scalars["ID"];
  /** Locality */
  locality: Locality;
  /** Status */
  status: LeaveAssignmentStatus;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Work Order Source */
  workOrder?: Maybe<WorkOrder>;
};

/** LeaveAssignment changes object */
export type LeaveAssignmentChanges = {
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Employees */
  employeesAttributes?: InputMaybe<Array<LeaveAssignmentEmployeeChanges>>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Status */
  status?: InputMaybe<LeaveAssignmentStatus>;
};

/** Autogenerated return type of LeaveAssignmentCreate. */
export type LeaveAssignmentCreatePayload = {
  __typename?: "LeaveAssignmentCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<LeaveAssignment>;
};

/** Leave assignment employee object */
export type LeaveAssignmentEmployee = Timestamps & {
  __typename?: "LeaveAssignmentEmployee";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Absent days */
  days?: Maybe<Scalars["Float"]>;
  /** Employee */
  employee: Employee;
  /** Leave end time */
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Absent hours */
  hours?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Leave Type */
  leaveType?: Maybe<LeaveType>;
  /** Employee wage payment */
  payment?: Maybe<ActivityPayment>;
  /** Leave start time */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** LeaveAssignmentEmployee changes object */
export type LeaveAssignmentEmployeeChanges = {
  /** Remove employee */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Absent days */
  days?: InputMaybe<Scalars["Float"]>;
  /** Employee */
  employeeId?: InputMaybe<Scalars["ID"]>;
  /** Leave end time */
  endTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Absent hours */
  hours?: InputMaybe<Scalars["Float"]>;
  /** Leave assignment employee ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Leave type */
  leaveTypeId?: InputMaybe<Scalars["ID"]>;
  /** Leave start time */
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** LeaveAssignmentEmployee field values */
export type LeaveAssignmentEmployeeFields = {
  /** Absent days */
  days?: InputMaybe<Scalars["Float"]>;
  /** Employee */
  employeeId: Scalars["ID"];
  /** Leave end time */
  endTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Absent hours */
  hours?: InputMaybe<Scalars["Float"]>;
  /** Leave type */
  leaveTypeId?: InputMaybe<Scalars["ID"]>;
  /** Leave start time */
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** LeaveAssignment field values */
export type LeaveAssignmentFields = {
  documentDate: Scalars["ISO8601DateTime"];
  /** Employees */
  employeesAttributes: Array<LeaveAssignmentEmployeeFields>;
  /** Locality */
  localityId: Scalars["ID"];
  /** Status */
  status: LeaveAssignmentStatus;
};

export enum LeaveAssignmentStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** Open */
  Open = "open",
  /** Reverted */
  Reverted = "reverted",
}

/** Autogenerated return type of LeaveAssignmentUpdate. */
export type LeaveAssignmentUpdatePayload = {
  __typename?: "LeaveAssignmentUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<LeaveAssignment>;
};

/** Collection of LeaveAssignments */
export type LeaveAssignments = {
  __typename?: "LeaveAssignments";
  /** Paginated collection of LeaveAssignments */
  items: Array<LeaveAssignment>;
  /** Total count of LeaveAssignments */
  totalCount: Scalars["Int"];
};

/** LeaveAssignments filter */
export type LeaveAssignmentsFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Completed by */
  completedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  documentDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Status */
  status?: InputMaybe<Array<LeaveAssignmentStatus>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Work Order */
  workOrderId?: InputMaybe<Array<Scalars["ID"]>>;
};

/** LeaveAssignments sorting options */
export type LeaveAssignmentsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  documentDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Status */
  status?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum LeaveKind {
  /** Compensation */
  Compensation = "compensation",
  /** Legal */
  Legal = "legal",
  /** Maternity */
  Maternity = "maternity",
  /** Medical */
  Medical = "medical",
  /** Paid */
  Paid = "paid",
  /** Pregnancy */
  Pregnancy = "pregnancy",
  /** Unpaid */
  Unpaid = "unpaid",
  /** Vacation */
  Vacation = "vacation",
}

/** Leave type object */
export type LeaveType = Discardable & {
  __typename?: "LeaveType";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  internalId?: Maybe<Scalars["String"]>;
  /** Kind */
  kind: LeaveKind;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  name: Scalars["String"];
  /** Leave type name in En locale */
  nameEn?: Maybe<Scalars["String"]>;
  /** Leave type name in Es locale */
  nameEs?: Maybe<Scalars["String"]>;
  /** Leave type name in Pt locale */
  namePt?: Maybe<Scalars["String"]>;
  /** Leave type name in Ru locale */
  nameRu?: Maybe<Scalars["String"]>;
  /** Is leave type payable? */
  payable: Scalars["Boolean"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** LeaveType changes object */
export type LeaveTypeChanges = {
  internalId?: InputMaybe<Scalars["String"]>;
  /** Kind */
  kind?: InputMaybe<LeaveKind>;
  name?: InputMaybe<Scalars["String"]>;
  /** Leave type name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Leave type name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Leave type name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Leave type name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Is leave type payable? */
  payable?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of LeaveTypeCreate. */
export type LeaveTypeCreatePayload = {
  __typename?: "LeaveTypeCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<LeaveType>;
};

/** Autogenerated return type of LeaveTypeDiscard. */
export type LeaveTypeDiscardPayload = {
  __typename?: "LeaveTypeDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<LeaveType>;
};

/** LeaveType field values */
export type LeaveTypeFields = {
  internalId?: InputMaybe<Scalars["String"]>;
  /** Kind */
  kind: LeaveKind;
  name: Scalars["String"];
  /** Leave type name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Leave type name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Leave type name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Leave type name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Is leave type payable? */
  payable: Scalars["Boolean"];
};

/** Autogenerated return type of LeaveTypeUpdate. */
export type LeaveTypeUpdatePayload = {
  __typename?: "LeaveTypeUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<LeaveType>;
};

/** Collection of LeaveTypes */
export type LeaveTypes = {
  __typename?: "LeaveTypes";
  /** Paginated collection of LeaveTypes */
  items: Array<LeaveType>;
  /** Total count of LeaveTypes */
  totalCount: Scalars["Int"];
};

/** LeaveTypes filter */
export type LeaveTypesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  internalId?: InputMaybe<StringMatchInput>;
  /** Kind */
  kind?: InputMaybe<Array<LeaveKind>>;
  name?: InputMaybe<StringMatchInput>;
  /** Leave type name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Leave type name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Leave type name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Leave type name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Is leave type payable? */
  payable?: InputMaybe<Scalars["Boolean"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** LeaveTypes sorting options */
export type LeaveTypesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  internalId?: InputMaybe<SortDirection>;
  /** Kind */
  kind?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  /** Leave type name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Leave type name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Leave type name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Leave type name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Is leave type payable? */
  payable?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Ledger Report result */
export type LedgerEntry = {
  __typename?: "LedgerEntry";
  account: Account;
  amount: Scalars["Float"];
  balance: Scalars["Float"];
  code: Scalars["String"];
  /** Credits */
  credit?: Maybe<Scalars["Float"]>;
  currencyCode: Scalars["String"];
  /** Debits */
  debit?: Maybe<Scalars["Float"]>;
  /** Entry description */
  description?: Maybe<Scalars["String"]>;
  documentDate: Scalars["ISO8601DateTime"];
  documentId: Scalars["ID"];
  documentType: FinanceDocumentType;
  entryCostCenters: Array<EntryCostCenter>;
  id: Scalars["ID"];
  /** Opening balance */
  openingBalance: Scalars["Float"];
};

/** Collection of LedgerReport */
export type LedgerReport = {
  __typename?: "LedgerReport";
  /** Paginated collection of LedgerReport */
  items: Array<LedgerEntry>;
  /** Total count of LedgerReport */
  totalCount: Scalars["Int"];
};

/** LedgerReport filter */
export type LedgerReportFilterInput = {
  /** Account */
  accountId?: InputMaybe<Array<Scalars["ID"]>>;
  code?: InputMaybe<StringMatchInput>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Entry description */
  description?: InputMaybe<StringMatchInput>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  documentId?: InputMaybe<Array<Scalars["ID"]>>;
  documentType?: InputMaybe<Array<FinanceDocumentType>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** LedgerReport sorting options */
export type LedgerReportSortInput = {
  amount?: InputMaybe<SortDirection>;
  balance?: InputMaybe<SortDirection>;
  code?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Credits */
  credit?: InputMaybe<SortDirection>;
  currencyCode?: InputMaybe<SortDirection>;
  /** Debits */
  debit?: InputMaybe<SortDirection>;
  /** Entry description */
  description?: InputMaybe<SortDirection>;
  documentDate?: InputMaybe<SortDirection>;
  documentId?: InputMaybe<SortDirection>;
  documentType?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Opening balance */
  openingBalance?: InputMaybe<SortDirection>;
};

/** LocalItemCategory changes object */
export type LocalItemCategoryChanges = {
  /** Expense account */
  taxPlanId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of LocalItemCategoryUpdate. */
export type LocalItemCategoryUpdatePayload = {
  __typename?: "LocalItemCategoryUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<ItemCategory>;
};

/** Collection of Localities */
export type Localities = {
  __typename?: "Localities";
  /** Paginated collection of Localities */
  items: Array<Locality>;
  /** Total count of Localities */
  totalCount: Scalars["Int"];
};

/** Localities filter */
export type LocalitiesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Whether the area unit can be updated */
  mayUpdateAreaUnit?: InputMaybe<Scalars["Boolean"]>;
  /** Locality name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Localities sorting options */
export type LocalitiesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Effective Area (area that is planted and may be productive) */
  effectiveArea?: InputMaybe<SortDirection>;
  /** GeoJSON object */
  geoJson?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Latitude */
  latitude?: InputMaybe<SortDirection>;
  /** Longitude */
  longitude?: InputMaybe<SortDirection>;
  /** Whether the area unit can be updated */
  mayUpdateAreaUnit?: InputMaybe<SortDirection>;
  /** Locality name */
  name?: InputMaybe<SortDirection>;
  /** Topographical Area (total geographical area) */
  totalArea?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Locality object */
export type Locality = Destroyable &
  Discardable & {
    __typename?: "Locality";
    /** Unit of measure for area */
    areaUnit: Unit;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Crop cycles */
    cropCycles: Array<CropCycle>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Effective Area (area that is planted and may be productive) */
    effectiveArea: Scalars["Float"];
    /** GeoJSON object */
    geoJson?: Maybe<Scalars["JSON"]>;
    id: Scalars["ID"];
    /** Internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Latitude */
    latitude?: Maybe<Scalars["Float"]>;
    /** Longitude */
    longitude?: Maybe<Scalars["Float"]>;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Whether the area unit can be updated */
    mayUpdateAreaUnit: Scalars["Boolean"];
    /** Locality name */
    name: Scalars["String"];
    /** Topographical Area (total geographical area) */
    totalArea: Scalars["Float"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Locality changes object */
export type LocalityChanges = {
  /** Area Unit */
  areaUnitId?: InputMaybe<Scalars["ID"]>;
  /** GeoJSON object */
  geoJson?: InputMaybe<Scalars["JSON"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Latitude */
  latitude?: InputMaybe<Scalars["Float"]>;
  /** Longitude */
  longitude?: InputMaybe<Scalars["Float"]>;
  /** Locality name */
  name?: InputMaybe<Scalars["String"]>;
  /** Topographical Area (total geographical area) */
  totalArea?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of LocalityCreate. */
export type LocalityCreatePayload = {
  __typename?: "LocalityCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Locality>;
};

/** Autogenerated return type of LocalityDestroy. */
export type LocalityDestroyPayload = {
  __typename?: "LocalityDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of LocalityDiscard. */
export type LocalityDiscardPayload = {
  __typename?: "LocalityDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Locality>;
};

/** Locality field values */
export type LocalityFields = {
  /** Area Unit */
  areaUnitId: Scalars["ID"];
  /** GeoJSON object */
  geoJson?: InputMaybe<Scalars["JSON"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Latitude */
  latitude?: InputMaybe<Scalars["Float"]>;
  /** Longitude */
  longitude?: InputMaybe<Scalars["Float"]>;
  /** Locality name */
  name: Scalars["String"];
  /** Topographical Area (total geographical area) */
  totalArea: Scalars["Float"];
};

/** Autogenerated return type of LocalityUpdate. */
export type LocalityUpdatePayload = {
  __typename?: "LocalityUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Locality>;
};

export enum LocationType {
  /** Cost Center */
  CostCenter = "costCenter",
  /** Counterparty */
  Counterparty = "counterparty",
  /** Crop Field */
  CropField = "cropField",
  /** Infrastructure */
  Infrastructure = "infrastructure",
  /** Locality */
  Locality = "locality",
  /** Warehouse */
  Warehouse = "warehouse",
}

/** Machine object */
export type Machine = Discardable & {
  __typename?: "Machine";
  /** Machine brand */
  brand: Brand;
  /** List of calibrations */
  calibrations?: Maybe<Array<Scalars["String"]>>;
  /** Capacity Time Value */
  capacityTime?: Maybe<Scalars["Float"]>;
  /** Capacity Time UOM */
  capacityTimeUnit?: Maybe<Unit>;
  /** Capacity UOM */
  capacityUnit?: Maybe<Unit>;
  /** Capacity Value */
  capacityValue?: Maybe<Scalars["Float"]>;
  /** Cost Center */
  costCenter?: Maybe<CostCenter>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  cropFieldMachines: Array<CropFieldMachine>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Fuel type */
  fuelType?: Maybe<FuelType>;
  /** Fuel UOM */
  fuelUnit?: Maybe<Unit>;
  /** Horometer UOM */
  horometerUnit?: Maybe<Unit>;
  /** Horometer Value (Last) */
  horometerValue?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Internal ID */
  internalId?: Maybe<Scalars["String"]>;
  /** Machine kind */
  kind: MachineKind;
  /** License Plate */
  licensePlate?: Maybe<Scalars["String"]>;
  /** Machine localities */
  machineLocalities: Array<MachineLocality>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Machine model */
  model: Scalars["String"];
  /** Machine name */
  name: Scalars["String"];
  /** Odometer UOM */
  odometerUnit?: Maybe<Unit>;
  /** Odometer Value (Last) */
  odometerValue?: Maybe<Scalars["Int"]>;
  photo?: Maybe<Scalars["Upload"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Chassis Number (VIN) */
  vin?: Maybe<Scalars["String"]>;
  /** Machine year */
  year: Scalars["Int"];
};

/** Machine object */
export type MachineCostCenterArgs = {
  localityId?: InputMaybe<Scalars["ID"]>;
};

/** Machine object */
export type MachinePhotoArgs = {
  variant?: InputMaybe<ImageVariant>;
};

/** Machine activity report result */
export type MachineActivity = {
  __typename?: "MachineActivity";
  /** Activity */
  activity: Activity;
  /** Ending date */
  endingDate: Scalars["ISO8601DateTime"];
  /** Machine efficiency */
  machineEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine hours */
  machineHours: Scalars["Float"];
  /** Progress */
  progress: Scalars["Float"];
  /** Starting date */
  startingDate: Scalars["ISO8601DateTime"];
  /** Business work days */
  workDays: Scalars["Int"];
};

/** Collection of MachineActivityReport */
export type MachineActivityReport = {
  __typename?: "MachineActivityReport";
  /** Paginated collection of MachineActivityReport */
  items: Array<MachineActivity>;
  /** Total count of MachineActivityReport */
  totalCount: Scalars["Int"];
};

/** MachineActivityReport sorting options */
export type MachineActivityReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Ending date */
  endingDate?: InputMaybe<SortDirection>;
  /** Machine efficiency */
  machineEfficiency?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Progress */
  progress?: InputMaybe<SortDirection>;
  /** Starting date */
  startingDate?: InputMaybe<SortDirection>;
  /** Business work days */
  workDays?: InputMaybe<SortDirection>;
};

export enum MachineCategory {
  /** Aerial */
  Aerial = "aerial",
  /** Agricultural */
  Agricultural = "agricultural",
  /** Construction */
  Construction = "construction",
  /** Generators */
  Generators = "generators",
  /** Implements */
  Implements = "implements",
  /** Irrigation */
  Irrigation = "irrigation",
  /** Maritime */
  Maritime = "maritime",
  /** Portables */
  Portables = "portables",
  /** Vehicles */
  Vehicles = "vehicles",
}

/** Machine changes object */
export type MachineChanges = {
  /** Brand */
  brandId?: InputMaybe<Scalars["ID"]>;
  /** List of calibrations */
  calibrations?: InputMaybe<Array<Scalars["String"]>>;
  /** Capacity Time Value */
  capacityTime?: InputMaybe<Scalars["Float"]>;
  /** Capacity time UOM */
  capacityTimeUnitId?: InputMaybe<Scalars["ID"]>;
  /** Capacity UOM */
  capacityUnitId?: InputMaybe<Scalars["ID"]>;
  /** Capacity Value */
  capacityValue?: InputMaybe<Scalars["Float"]>;
  /** Crop field machines */
  cropFieldMachinesAttributes?: InputMaybe<Array<CropFieldMachineChanges>>;
  /** Fuel type */
  fuelType?: InputMaybe<FuelType>;
  /** Fuel UOM */
  fuelUnitId?: InputMaybe<Scalars["ID"]>;
  /** Horometer UOM */
  horometerUnitId?: InputMaybe<Scalars["ID"]>;
  /** Horometer Value (Last) */
  horometerValue?: InputMaybe<Scalars["Float"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Machine kind */
  kindId?: InputMaybe<Scalars["ID"]>;
  /** License Plate */
  licensePlate?: InputMaybe<Scalars["String"]>;
  /** Machine localities */
  machineLocalitiesAttributes?: InputMaybe<Array<MachineLocalityChanges>>;
  /** Machine model */
  model?: InputMaybe<Scalars["String"]>;
  /** Machine name */
  name?: InputMaybe<Scalars["String"]>;
  /** Odometer UOM */
  odometerUnitId?: InputMaybe<Scalars["ID"]>;
  /** Odometer Value (Last) */
  odometerValue?: InputMaybe<Scalars["Int"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  /** Chassis Number (VIN) */
  vin?: InputMaybe<Scalars["String"]>;
  /** Machine year */
  year?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated return type of MachineCreate. */
export type MachineCreatePayload = {
  __typename?: "MachineCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Machine>;
};

/** Autogenerated return type of MachineDestroy. */
export type MachineDestroyPayload = {
  __typename?: "MachineDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of MachineDiscard. */
export type MachineDiscardPayload = {
  __typename?: "MachineDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Machine>;
};

/** Machine field values */
export type MachineFields = {
  /** Brand */
  brandId: Scalars["ID"];
  /** List of calibrations */
  calibrations?: InputMaybe<Array<Scalars["String"]>>;
  /** Capacity Time Value */
  capacityTime?: InputMaybe<Scalars["Float"]>;
  /** Capacity Value */
  capacityValue?: InputMaybe<Scalars["Float"]>;
  /** Fuel type */
  fuelType?: InputMaybe<FuelType>;
  /** Fuel UOM */
  fuelUnitId?: InputMaybe<Scalars["ID"]>;
  /** Horometer UOM */
  horometerUnitId?: InputMaybe<Scalars["ID"]>;
  /** Horometer Value (Last) */
  horometerValue?: InputMaybe<Scalars["Float"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Machine kind */
  kindId: Scalars["ID"];
  /** License Plate */
  licensePlate?: InputMaybe<Scalars["String"]>;
  /** Machine localities */
  machineLocalitiesAttributes?: InputMaybe<Array<MachineLocalityFields>>;
  /** Machine model */
  model: Scalars["String"];
  /** Machine name */
  name: Scalars["String"];
  /** Odometer UOM */
  odometerUnitId?: InputMaybe<Scalars["ID"]>;
  /** Odometer Value (Last) */
  odometerValue?: InputMaybe<Scalars["Int"]>;
  photo?: InputMaybe<Scalars["Upload"]>;
  /** Chassis Number (VIN) */
  vin?: InputMaybe<Scalars["String"]>;
  /** Machine year */
  year: Scalars["Int"];
};

/** Machine kind object */
export type MachineKind = Discardable &
  Global & {
    __typename?: "MachineKind";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    category: MachineCategory;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Kind name */
    name: Scalars["String"];
    /** Kind name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Kind name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Kind name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Kind name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** MachineKind changes object */
export type MachineKindChanges = {
  category?: InputMaybe<MachineCategory>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Kind name */
  name?: InputMaybe<Scalars["String"]>;
  /** Kind name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Kind name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Kind name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Kind name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of MachineKindCreate. */
export type MachineKindCreatePayload = {
  __typename?: "MachineKindCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<MachineKind>;
};

/** Autogenerated return type of MachineKindDiscard. */
export type MachineKindDiscardPayload = {
  __typename?: "MachineKindDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<MachineKind>;
};

/** MachineKind field values */
export type MachineKindFields = {
  category: MachineCategory;
  /** Is global? */
  global: Scalars["Boolean"];
  /** Is locked */
  locked: Scalars["Boolean"];
  /** Kind name */
  name: Scalars["String"];
  /** Kind name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Kind name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Kind name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Kind name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of MachineKindUnlock. */
export type MachineKindUnlockPayload = {
  __typename?: "MachineKindUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<MachineKind>;
};

/** Autogenerated return type of MachineKindUpdate. */
export type MachineKindUpdatePayload = {
  __typename?: "MachineKindUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<MachineKind>;
};

/** Collection of MachineKinds */
export type MachineKinds = {
  __typename?: "MachineKinds";
  /** Paginated collection of MachineKinds */
  items: Array<MachineKind>;
  /** Total count of MachineKinds */
  totalCount: Scalars["Int"];
};

/** MachineKinds filter */
export type MachineKindsFilterInput = {
  category?: InputMaybe<Array<MachineCategory>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Kind name */
  name?: InputMaybe<StringMatchInput>;
  /** Kind name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Kind name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Kind name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Kind name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** MachineKinds sorting options */
export type MachineKindsSortInput = {
  category?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Kind name */
  name?: InputMaybe<SortDirection>;
  /** Kind name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Kind name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Kind name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Kind name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Machine locality object */
export type MachineLocality = Profitable & {
  __typename?: "MachineLocality";
  /** Cost Center */
  costCenter?: Maybe<CostCenter>;
  id: Scalars["ID"];
  /** Locality */
  locality: Locality;
};

/** MachineLocality changes object */
export type MachineLocalityChanges = {
  /** Remove locality */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Machine locality ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Locality ID */
  localityId?: InputMaybe<Scalars["ID"]>;
};

/** MachineLocality field values */
export type MachineLocalityFields = {
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Locality ID */
  localityId: Scalars["ID"];
};

/** Machine report filter input */
export type MachineReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity category */
  categoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Work Order type */
  workOrderType?: InputMaybe<Array<WorkOrderType>>;
};

/** Machine summary report result */
export type MachineSummary = {
  __typename?: "MachineSummary";
  /** Machine */
  machine: Machine;
  /** Machine hours */
  machineHours: Scalars["Float"];
  /** Business work days */
  workDays: Scalars["Int"];
};

/** Collection of MachineSummaryReport */
export type MachineSummaryReport = {
  __typename?: "MachineSummaryReport";
  /** Paginated collection of MachineSummaryReport */
  items: Array<MachineSummary>;
  /** Total count of MachineSummaryReport */
  totalCount: Scalars["Int"];
};

/** MachineSummaryReport sorting options */
export type MachineSummaryReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Sorts by machine name */
  machineName?: InputMaybe<SortDirection>;
  /** Business work days */
  workDays?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of MachineUpdate. */
export type MachineUpdatePayload = {
  __typename?: "MachineUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Machine>;
};

/** Collection of Machines */
export type Machines = {
  __typename?: "Machines";
  /** Paginated collection of Machines */
  items: Array<Machine>;
  /** Total count of Machines */
  totalCount: Scalars["Int"];
};

/** Machines filter */
export type MachinesFilterInput = {
  /** Machine brand */
  brandId?: InputMaybe<Array<Scalars["ID"]>>;
  /** List of calibrations */
  calibrations?: InputMaybe<StringMatchInput>;
  /** Machine categories */
  category?: InputMaybe<Array<MachineCategory>>;
  /** Crop center id */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Fuel type */
  fuelType?: InputMaybe<Array<FuelType>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Machine kinds */
  kindId?: InputMaybe<Array<Scalars["ID"]>>;
  /** License Plate */
  licensePlate?: InputMaybe<StringMatchInput>;
  /** Machine localities */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine model */
  model?: InputMaybe<StringMatchInput>;
  /** Machine name */
  name?: InputMaybe<StringMatchInput>;
  /** Odometer Value (Last) */
  odometerValue?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Chassis Number (VIN) */
  vin?: InputMaybe<StringMatchInput>;
  /** Machine year */
  year?: InputMaybe<Array<Scalars["Int"]>>;
};

/** Machines sorting options */
export type MachinesSortInput = {
  /** Sorts by brand name */
  brandName?: InputMaybe<SortDirection>;
  /** List of calibrations */
  calibrations?: InputMaybe<SortDirection>;
  /** Capacity Time Value */
  capacityTime?: InputMaybe<SortDirection>;
  /** Capacity Value */
  capacityValue?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Fuel type */
  fuelType?: InputMaybe<SortDirection>;
  /** Horometer Value (Last) */
  horometerValue?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Sorts by kind name */
  kindName?: InputMaybe<SortDirection>;
  /** License Plate */
  licensePlate?: InputMaybe<SortDirection>;
  /** Machine model */
  model?: InputMaybe<SortDirection>;
  /** Machine name */
  name?: InputMaybe<SortDirection>;
  /** Odometer Value (Last) */
  odometerValue?: InputMaybe<SortDirection>;
  photo?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Chassis Number (VIN) */
  vin?: InputMaybe<SortDirection>;
  /** Machine year */
  year?: InputMaybe<SortDirection>;
};

/** Activity Metric object */
export type Metric = Destroyable &
  Discardable &
  Timestamps & {
    __typename?: "Metric";
    abbr?: Maybe<Scalars["String"]>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Metric description */
    description?: Maybe<Scalars["String"]>;
    /** Metric description in En locale */
    descriptionEn?: Maybe<Scalars["String"]>;
    /** Metric description in Es locale */
    descriptionEs?: Maybe<Scalars["String"]>;
    /** Metric description in Pt locale */
    descriptionPt?: Maybe<Scalars["String"]>;
    /** Metric description in Ru locale */
    descriptionRu?: Maybe<Scalars["String"]>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Metric name */
    name: Scalars["String"];
    /** Metric name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Metric name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Metric name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Metric name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Metric Unit */
    unit: Unit;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Metric changes object */
export type MetricChanges = {
  abbr?: InputMaybe<Scalars["String"]>;
  /** Metric description */
  description?: InputMaybe<Scalars["String"]>;
  /** Metric description in En locale */
  descriptionEn?: InputMaybe<Scalars["String"]>;
  /** Metric description in Es locale */
  descriptionEs?: InputMaybe<Scalars["String"]>;
  /** Metric description in Pt locale */
  descriptionPt?: InputMaybe<Scalars["String"]>;
  /** Metric description in Ru locale */
  descriptionRu?: InputMaybe<Scalars["String"]>;
  /** Metric name */
  name?: InputMaybe<Scalars["String"]>;
  /** Metric name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Metric name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Metric name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Metric name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Metric unit */
  unitId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of MetricCreate. */
export type MetricCreatePayload = {
  __typename?: "MetricCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Metric>;
};

/** Autogenerated return type of MetricDestroy. */
export type MetricDestroyPayload = {
  __typename?: "MetricDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of MetricDiscard. */
export type MetricDiscardPayload = {
  __typename?: "MetricDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Metric>;
};

/** Metric field values */
export type MetricFields = {
  abbr?: InputMaybe<Scalars["String"]>;
  /** Metric description */
  description?: InputMaybe<Scalars["String"]>;
  /** Metric description in En locale */
  descriptionEn?: InputMaybe<Scalars["String"]>;
  /** Metric description in Es locale */
  descriptionEs?: InputMaybe<Scalars["String"]>;
  /** Metric description in Pt locale */
  descriptionPt?: InputMaybe<Scalars["String"]>;
  /** Metric description in Ru locale */
  descriptionRu?: InputMaybe<Scalars["String"]>;
  /** Metric name */
  name: Scalars["String"];
  /** Metric name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Metric name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Metric name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Metric name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Metric unit */
  unitId: Scalars["ID"];
};

/** Autogenerated return type of MetricUpdate. */
export type MetricUpdatePayload = {
  __typename?: "MetricUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Metric>;
};

/** Collection of Metrics */
export type Metrics = {
  __typename?: "Metrics";
  /** Paginated collection of Metrics */
  items: Array<Metric>;
  /** Total count of Metrics */
  totalCount: Scalars["Int"];
};

/** Metrics filter */
export type MetricsFilterInput = {
  abbr?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Metric description */
  description?: InputMaybe<StringMatchInput>;
  /** Metric description in En locale */
  descriptionEn?: InputMaybe<StringMatchInput>;
  /** Metric description in Es locale */
  descriptionEs?: InputMaybe<StringMatchInput>;
  /** Metric description in Pt locale */
  descriptionPt?: InputMaybe<StringMatchInput>;
  /** Metric description in Ru locale */
  descriptionRu?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Metric name */
  name?: InputMaybe<StringMatchInput>;
  /** Metric name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Metric name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Metric name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Metric name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Metrics sorting options */
export type MetricsSortInput = {
  abbr?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Metric description */
  description?: InputMaybe<SortDirection>;
  /** Metric description in En locale */
  descriptionEn?: InputMaybe<SortDirection>;
  /** Metric description in Es locale */
  descriptionEs?: InputMaybe<SortDirection>;
  /** Metric description in Pt locale */
  descriptionPt?: InputMaybe<SortDirection>;
  /** Metric description in Ru locale */
  descriptionRu?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Metric name */
  name?: InputMaybe<SortDirection>;
  /** Metric name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Metric name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Metric name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Metric name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Accept invite */
  acceptInvite?: Maybe<AcceptInviteMutationPayload>;
  /** Creates Account object */
  accountCreate?: Maybe<AccountCreatePayload>;
  /** Destroys Account object permanently */
  accountDestroy?: Maybe<AccountDestroyPayload>;
  /** Discards/Undiscards Account object */
  accountDiscard?: Maybe<AccountDiscardPayload>;
  /** Updates Account */
  accountUpdate?: Maybe<AccountUpdatePayload>;
  /** Creates ActivityCategory object */
  activityCategoryCreate?: Maybe<ActivityCategoryCreatePayload>;
  /** Destroys ActivityCategory object permanently */
  activityCategoryDestroy?: Maybe<ActivityCategoryDestroyPayload>;
  /** Discards/Undiscards ActivityCategory object */
  activityCategoryDiscard?: Maybe<ActivityCategoryDiscardPayload>;
  /** Toggle ActivityCategory locked state */
  activityCategoryUnlock?: Maybe<ActivityCategoryUnlockPayload>;
  /** Updates ActivityCategory */
  activityCategoryUpdate?: Maybe<ActivityCategoryUpdatePayload>;
  /** Creates Activity object */
  activityCreate?: Maybe<ActivityCreatePayload>;
  /** Destroys Activity object permanently */
  activityDestroy?: Maybe<ActivityDestroyPayload>;
  /** Discards/Undiscards Activity object */
  activityDiscard?: Maybe<ActivityDiscardPayload>;
  /** Creates ActivityPayment object */
  activityPaymentCreate?: Maybe<ActivityPaymentCreatePayload>;
  /** Discards/Undiscards ActivityPayment object */
  activityPaymentDiscard?: Maybe<ActivityPaymentDiscardPayload>;
  /** Updates ActivityPayment */
  activityPaymentUpdate?: Maybe<ActivityPaymentUpdatePayload>;
  /** Updates Activity */
  activityUpdate?: Maybe<ActivityUpdatePayload>;
  /** Creates AdjustmentType object */
  adjustmentTypeCreate?: Maybe<AdjustmentTypeCreatePayload>;
  /** Destroys AdjustmentType object permanently */
  adjustmentTypeDestroy?: Maybe<AdjustmentTypeDestroyPayload>;
  /** Discards/Undiscards AdjustmentType object */
  adjustmentTypeDiscard?: Maybe<AdjustmentTypeDiscardPayload>;
  /** Updates AdjustmentType */
  adjustmentTypeUpdate?: Maybe<AdjustmentTypeUpdatePayload>;
  /** Creates BankAccount object */
  bankAccountCreate?: Maybe<BankAccountCreatePayload>;
  /** Destroys BankAccount object permanently */
  bankAccountDestroy?: Maybe<BankAccountDestroyPayload>;
  /** Discards/Undiscards BankAccount object */
  bankAccountDiscard?: Maybe<BankAccountDiscardPayload>;
  /** Updates BankAccount */
  bankAccountUpdate?: Maybe<BankAccountUpdatePayload>;
  /** Creates Bank object */
  bankCreate?: Maybe<BankCreatePayload>;
  /** Destroys Bank object permanently */
  bankDestroy?: Maybe<BankDestroyPayload>;
  /** Discards/Undiscards Bank object */
  bankDiscard?: Maybe<BankDiscardPayload>;
  /** Toggle Bank locked state */
  bankUnlock?: Maybe<BankUnlockPayload>;
  /** Updates Bank */
  bankUpdate?: Maybe<BankUpdatePayload>;
  /** Creates Brand object */
  brandCreate?: Maybe<BrandCreatePayload>;
  /** Discards/Undiscards Brand object */
  brandDiscard?: Maybe<BrandDiscardPayload>;
  /** Toggle Brand locked state */
  brandUnlock?: Maybe<BrandUnlockPayload>;
  /** Updates Brand */
  brandUpdate?: Maybe<BrandUpdatePayload>;
  /** Destroys objects permanently */
  bulkDestroy?: Maybe<BulkDestroyPayload>;
  /** Discards/restores objects */
  bulkDiscard?: Maybe<BulkDiscardPayload>;
  /** Creates Comment object */
  commentCreate?: Maybe<CommentCreatePayload>;
  /** Destroys Comment object permanently */
  commentDestroy?: Maybe<CommentDestroyPayload>;
  /** Discards/Undiscards Comment object */
  commentDiscard?: Maybe<CommentDiscardPayload>;
  /** Updates Comment */
  commentUpdate?: Maybe<CommentUpdatePayload>;
  /** Creates CostCenter object */
  costCenterCreate?: Maybe<CostCenterCreatePayload>;
  /** Destroys CostCenter object permanently */
  costCenterDestroy?: Maybe<CostCenterDestroyPayload>;
  /** Discards/Undiscards CostCenter object */
  costCenterDiscard?: Maybe<CostCenterDiscardPayload>;
  /** Updates CostCenter */
  costCenterUpdate?: Maybe<CostCenterUpdatePayload>;
  /** Updates CounterpartyBulk in bulk */
  counterpartyBulkUpdate?: Maybe<CounterpartyBulkBulkUpdatePayload>;
  /** Creates Counterparty object */
  counterpartyCreate?: Maybe<CounterpartyCreatePayload>;
  /** Destroys Counterparty object permanently */
  counterpartyDestroy?: Maybe<CounterpartyDestroyPayload>;
  /** Discards/Undiscards Counterparty object */
  counterpartyDiscard?: Maybe<CounterpartyDiscardPayload>;
  /** Updates Counterparty */
  counterpartyUpdate?: Maybe<CounterpartyUpdatePayload>;
  /** Creates Crop object */
  cropCreate?: Maybe<CropCreatePayload>;
  cropCycleClose?: Maybe<CropCycleCloseMutationPayload>;
  /** Creates CropCycle object */
  cropCycleCreate?: Maybe<CropCycleCreatePayload>;
  /** Destroys CropCycle object permanently */
  cropCycleDestroy?: Maybe<CropCycleDestroyPayload>;
  /** Discards/Undiscards CropCycle object */
  cropCycleDiscard?: Maybe<CropCycleDiscardPayload>;
  cropCycleReopen?: Maybe<CropCycleReopenMutationPayload>;
  /** Updates CropCycle */
  cropCycleUpdate?: Maybe<CropCycleUpdatePayload>;
  /** Discards/Undiscards Crop object */
  cropDiscard?: Maybe<CropDiscardPayload>;
  /** Updates CropFieldBulk in bulk */
  cropFieldBulkUpdate?: Maybe<CropFieldBulkBulkUpdatePayload>;
  /** Creates CropField object */
  cropFieldCreate?: Maybe<CropFieldCreatePayload>;
  /** Destroys CropField object permanently */
  cropFieldDestroy?: Maybe<CropFieldDestroyPayload>;
  /** Discards/Undiscards CropField object */
  cropFieldDiscard?: Maybe<CropFieldDiscardPayload>;
  /** Creates CropFieldNote object */
  cropFieldNoteCreate?: Maybe<CropFieldNoteCreatePayload>;
  /** Discards/Undiscards CropFieldNote object */
  cropFieldNoteDiscard?: Maybe<CropFieldNoteDiscardPayload>;
  /** Updates CropFieldNote */
  cropFieldNoteUpdate?: Maybe<CropFieldNoteUpdatePayload>;
  /** Updates CropField */
  cropFieldUpdate?: Maybe<CropFieldUpdatePayload>;
  /** Creates CropStage object */
  cropStageCreate?: Maybe<CropStageCreatePayload>;
  /** Destroys CropStage object permanently */
  cropStageDestroy?: Maybe<CropStageDestroyPayload>;
  /** Discards/Undiscards CropStage object */
  cropStageDiscard?: Maybe<CropStageDiscardPayload>;
  /** Updates CropStage */
  cropStageUpdate?: Maybe<CropStageUpdatePayload>;
  /** Toggle Crop locked state */
  cropUnlock?: Maybe<CropUnlockPayload>;
  /** Updates Crop */
  cropUpdate?: Maybe<CropUpdatePayload>;
  /** Creates CropVariety object */
  cropVarietyCreate?: Maybe<CropVarietyCreatePayload>;
  /** Destroys CropVariety object permanently */
  cropVarietyDestroy?: Maybe<CropVarietyDestroyPayload>;
  /** Discards/Undiscards CropVariety object */
  cropVarietyDiscard?: Maybe<CropVarietyDiscardPayload>;
  /** Updates CropVariety */
  cropVarietyUpdate?: Maybe<CropVarietyUpdatePayload>;
  /** Creates Disease object */
  diseaseCreate?: Maybe<DiseaseCreatePayload>;
  /** Destroys Disease object permanently */
  diseaseDestroy?: Maybe<DiseaseDestroyPayload>;
  /** Discards/Undiscards Disease object */
  diseaseDiscard?: Maybe<DiseaseDiscardPayload>;
  /** Updates DiseaseRisk */
  diseaseRiskUpdate?: Maybe<DiseaseRiskUpdatePayload>;
  /** Toggle Disease locked state */
  diseaseUnlock?: Maybe<DiseaseUnlockPayload>;
  /** Updates Disease */
  diseaseUpdate?: Maybe<DiseaseUpdatePayload>;
  /** Updates EmployeeBulk in bulk */
  employeeBulkUpdate?: Maybe<EmployeeBulkBulkUpdatePayload>;
  /** Creates Employee object */
  employeeCreate?: Maybe<EmployeeCreatePayload>;
  /** Destroys Employee object permanently */
  employeeDestroy?: Maybe<EmployeeDestroyPayload>;
  /** Discards/Undiscards Employee object */
  employeeDiscard?: Maybe<EmployeeDiscardPayload>;
  /** Creates EmployeeGroup object */
  employeeGroupCreate?: Maybe<EmployeeGroupCreatePayload>;
  /** Discards/Undiscards EmployeeGroup object */
  employeeGroupDiscard?: Maybe<EmployeeGroupDiscardPayload>;
  /** Updates EmployeeGroup */
  employeeGroupUpdate?: Maybe<EmployeeGroupUpdatePayload>;
  /** Updates Employee */
  employeeUpdate?: Maybe<EmployeeUpdatePayload>;
  /** Creates ExpenseCategory object */
  expenseCategoryCreate?: Maybe<ExpenseCategoryCreatePayload>;
  /** Destroys ExpenseCategory object permanently */
  expenseCategoryDestroy?: Maybe<ExpenseCategoryDestroyPayload>;
  /** Discards/Undiscards ExpenseCategory object */
  expenseCategoryDiscard?: Maybe<ExpenseCategoryDiscardPayload>;
  /** Toggle ExpenseCategory locked state */
  expenseCategoryUnlock?: Maybe<ExpenseCategoryUnlockPayload>;
  /** Updates ExpenseCategory */
  expenseCategoryUpdate?: Maybe<ExpenseCategoryUpdatePayload>;
  /** Creates ExpenseItem object */
  expenseItemCreate?: Maybe<ExpenseItemCreatePayload>;
  /** Destroys ExpenseItem object permanently */
  expenseItemDestroy?: Maybe<ExpenseItemDestroyPayload>;
  /** Discards/Undiscards ExpenseItem object */
  expenseItemDiscard?: Maybe<ExpenseItemDiscardPayload>;
  /** Updates ExpenseItem */
  expenseItemUpdate?: Maybe<ExpenseItemUpdatePayload>;
  /** Updates Invoice */
  financeInvoiceUpdate?: Maybe<InvoiceUpdatePayload>;
  /** Clones finance order */
  financeOrderClone?: Maybe<FinanceOrderClonePayload>;
  /** Creates FinanceOrder object */
  financeOrderCreate?: Maybe<FinanceOrderCreatePayload>;
  /** Updates FinanceOrder */
  financeOrderUpdate?: Maybe<FinanceOrderUpdatePayload>;
  /** Creates Payment object */
  financePaymentCreate?: Maybe<PaymentCreatePayload>;
  /** Updates Payment */
  financePaymentUpdate?: Maybe<PaymentUpdatePayload>;
  financePeriodClose?: Maybe<PeriodCloseMutationPayload>;
  financePeriodOpen?: Maybe<OpenMutationPayload>;
  /** Creates FiscalYear object */
  fiscalYearCreate?: Maybe<FiscalYearCreatePayload>;
  /** Destroys FiscalYear object permanently */
  fiscalYearDestroy?: Maybe<FiscalYearDestroyPayload>;
  /** Updates FiscalYear */
  fiscalYearUpdate?: Maybe<FiscalYearUpdatePayload>;
  /** Generate billing portal link */
  generateBillingPortalLink?: Maybe<GenerateBillingPortalLinkMutationPayload>;
  /** Generate invitation link */
  generateInvitationLink?: Maybe<GenerateInvitationLinkMutationPayload>;
  /** Generate reset password link */
  generateResetPasswordLink?: Maybe<GenerateResetPasswordLinkMutationPayload>;
  /** Creates HarvestEstimate object */
  harvestEstimateCreate?: Maybe<HarvestEstimateCreatePayload>;
  /** Destroys HarvestEstimate object permanently */
  harvestEstimateDestroy?: Maybe<HarvestEstimateDestroyPayload>;
  /** Updates HarvestEstimate */
  harvestEstimateUpdate?: Maybe<HarvestEstimateUpdatePayload>;
  /** Creates HolidayList object */
  holidayListCreate?: Maybe<HolidayListCreatePayload>;
  /** Destroys HolidayList object permanently */
  holidayListDestroy?: Maybe<HolidayListDestroyPayload>;
  /** Updates HolidayList */
  holidayListUpdate?: Maybe<HolidayListUpdatePayload>;
  /** Creates Import object */
  importCreate?: Maybe<ImportCreatePayload>;
  /** Fire import event mutation */
  importFireEvent?: Maybe<FireEventMutationPayload>;
  /** Updates Import */
  importUpdate?: Maybe<ImportUpdatePayload>;
  /** Creates Infrastructure object */
  infrastructureCreate?: Maybe<InfrastructureCreatePayload>;
  /** Destroys Infrastructure object permanently */
  infrastructureDestroy?: Maybe<InfrastructureDestroyPayload>;
  /** Discards/Undiscards Infrastructure object */
  infrastructureDiscard?: Maybe<InfrastructureDiscardPayload>;
  /** Updates Infrastructure */
  infrastructureUpdate?: Maybe<InfrastructureUpdatePayload>;
  /** Creates Ingredient object */
  ingredientCreate?: Maybe<IngredientCreatePayload>;
  /** Discards/Undiscards Ingredient object */
  ingredientDiscard?: Maybe<IngredientDiscardPayload>;
  /** Updates Ingredient */
  ingredientUpdate?: Maybe<IngredientUpdatePayload>;
  /** Clones inventory order */
  inventoryOrderClone?: Maybe<InventoryOrderClonePayload>;
  /** Creates InventoryOrder object */
  inventoryOrderCreate?: Maybe<InventoryOrderCreatePayload>;
  /** Updates InventoryOrder */
  inventoryOrderUpdate?: Maybe<InventoryOrderUpdatePayload>;
  /** Updates InventoryRequest */
  inventoryRequestUpdate?: Maybe<UpdateMutationPayload>;
  /** Updates ItemBulk in bulk */
  itemBulkUpdate?: Maybe<ItemBulkBulkUpdatePayload>;
  /** Creates ItemCategory object */
  itemCategoryCreate?: Maybe<ItemCategoryCreatePayload>;
  /** Destroys ItemCategory object permanently */
  itemCategoryDestroy?: Maybe<ItemCategoryDestroyPayload>;
  /** Discards/Undiscards ItemCategory object */
  itemCategoryDiscard?: Maybe<ItemCategoryDiscardPayload>;
  /** Updates LocalItemCategory */
  itemCategoryLocalUpdate?: Maybe<LocalItemCategoryUpdatePayload>;
  /** Toggle ItemCategory locked state */
  itemCategoryUnlock?: Maybe<ItemCategoryUnlockPayload>;
  /** Updates ItemCategory */
  itemCategoryUpdate?: Maybe<ItemCategoryUpdatePayload>;
  /** Creates Item object */
  itemCreate?: Maybe<ItemCreatePayload>;
  /** Destroys Item object permanently */
  itemDestroy?: Maybe<ItemDestroyPayload>;
  /** Discards/Undiscards Item object */
  itemDiscard?: Maybe<ItemDiscardPayload>;
  /** Updates Item */
  itemUpdate?: Maybe<ItemUpdatePayload>;
  /** Clones journal entry */
  journalEntryClone?: Maybe<JournalEntryClonePayload>;
  /** Creates JournalEntry object */
  journalEntryCreate?: Maybe<JournalEntryCreatePayload>;
  /** Updates JournalEntry */
  journalEntryUpdate?: Maybe<JournalEntryUpdatePayload>;
  /** Creates LeaveAssignment object */
  leaveAssignmentCreate?: Maybe<LeaveAssignmentCreatePayload>;
  /** Updates LeaveAssignment */
  leaveAssignmentUpdate?: Maybe<LeaveAssignmentUpdatePayload>;
  /** Creates LeaveType object */
  leaveTypeCreate?: Maybe<LeaveTypeCreatePayload>;
  /** Discards/Undiscards LeaveType object */
  leaveTypeDiscard?: Maybe<LeaveTypeDiscardPayload>;
  /** Updates LeaveType */
  leaveTypeUpdate?: Maybe<LeaveTypeUpdatePayload>;
  /** Creates Locality object */
  localityCreate?: Maybe<LocalityCreatePayload>;
  /** Destroys Locality object permanently */
  localityDestroy?: Maybe<LocalityDestroyPayload>;
  /** Discards/Undiscards Locality object */
  localityDiscard?: Maybe<LocalityDiscardPayload>;
  /** Updates Locality */
  localityUpdate?: Maybe<LocalityUpdatePayload>;
  /** Creates Machine object */
  machineCreate?: Maybe<MachineCreatePayload>;
  /** Destroys Machine object permanently */
  machineDestroy?: Maybe<MachineDestroyPayload>;
  /** Discards/Undiscards Machine object */
  machineDiscard?: Maybe<MachineDiscardPayload>;
  /** Creates MachineKind object */
  machineKindCreate?: Maybe<MachineKindCreatePayload>;
  /** Discards/Undiscards MachineKind object */
  machineKindDiscard?: Maybe<MachineKindDiscardPayload>;
  /** Toggle MachineKind locked state */
  machineKindUnlock?: Maybe<MachineKindUnlockPayload>;
  /** Updates MachineKind */
  machineKindUpdate?: Maybe<MachineKindUpdatePayload>;
  /** Updates Machine */
  machineUpdate?: Maybe<MachineUpdatePayload>;
  /** Creates Metric object */
  metricCreate?: Maybe<MetricCreatePayload>;
  /** Destroys Metric object permanently */
  metricDestroy?: Maybe<MetricDestroyPayload>;
  /** Discards/Undiscards Metric object */
  metricDiscard?: Maybe<MetricDiscardPayload>;
  /** Updates Metric */
  metricUpdate?: Maybe<MetricUpdatePayload>;
  /** Create activities from catalog */
  onboardingSeedActivities?: Maybe<CreateActivitiesPayload>;
  /** Create items from catalog */
  onboardingSeedItems?: Maybe<CreateItemsPayload>;
  /** Create salary structures from catalog */
  onboardingSeedSalaryStructures?: Maybe<CreateSalaryStructuresPayload>;
  /** Creates PaymentMethod object */
  paymentMethodCreate?: Maybe<PaymentMethodCreatePayload>;
  /** Discards/Undiscards PaymentMethod object */
  paymentMethodDiscard?: Maybe<PaymentMethodDiscardPayload>;
  /** Toggle PaymentMethod locked state */
  paymentMethodUnlock?: Maybe<PaymentMethodUnlockPayload>;
  /** Updates PaymentMethod */
  paymentMethodUpdate?: Maybe<PaymentMethodUpdatePayload>;
  /** Creates PayrollEntry object */
  payrollEntryCreate?: Maybe<PayrollEntryCreatePayload>;
  /** Destroys PayrollEntry object permanently */
  payrollEntryDestroy?: Maybe<PayrollEntryDestroyPayload>;
  /** Generates payroll entry */
  payrollEntryGenerate?: Maybe<PayrollEntryGeneratePayload>;
  /** Updates PayrollEntry */
  payrollEntryUpdate?: Maybe<PayrollEntryUpdatePayload>;
  /** Clones planning */
  planningClone?: Maybe<PlanningClonePayload>;
  /** Creates Planning object */
  planningCreate?: Maybe<PlanningCreatePayload>;
  /** Destroys Planning object permanently */
  planningDestroy?: Maybe<PlanningDestroyPayload>;
  /** Updates Planning */
  planningUpdate?: Maybe<PlanningUpdatePayload>;
  /** Creates Position object */
  positionCreate?: Maybe<PositionCreatePayload>;
  /** Destroys Position object permanently */
  positionDestroy?: Maybe<PositionDestroyPayload>;
  /** Discards/Undiscards Position object */
  positionDiscard?: Maybe<PositionDiscardPayload>;
  /** Updates Position */
  positionUpdate?: Maybe<PositionUpdatePayload>;
  /** Creates Recipe object */
  recipeCreate?: Maybe<RecipeCreatePayload>;
  /** Destroys Recipe object permanently */
  recipeDestroy?: Maybe<RecipeDestroyPayload>;
  /** Discards/Undiscards Recipe object */
  recipeDiscard?: Maybe<RecipeDiscardPayload>;
  /** Updates Recipe */
  recipeUpdate?: Maybe<RecipeUpdatePayload>;
  /** Resend invite */
  resendInvite?: Maybe<ResendInviteMutationPayload>;
  /** Reset user password */
  resetPassword?: Maybe<ResetPasswordMutationPayload>;
  /** Creates Role object */
  roleCreate?: Maybe<RoleCreatePayload>;
  /** Discards/Undiscards Role object */
  roleDiscard?: Maybe<RoleDiscardPayload>;
  /** Toggle Role locked state */
  roleUnlock?: Maybe<RoleUnlockPayload>;
  /** Updates Role */
  roleUpdate?: Maybe<RoleUpdatePayload>;
  /** Creates SalaryComponent object */
  salaryComponentCreate?: Maybe<SalaryComponentCreatePayload>;
  /** Destroys SalaryComponent object permanently */
  salaryComponentDestroy?: Maybe<SalaryComponentDestroyPayload>;
  /** Discards/Undiscards SalaryComponent object */
  salaryComponentDiscard?: Maybe<SalaryComponentDiscardPayload>;
  /** Updates SalaryComponent */
  salaryComponentUpdate?: Maybe<SalaryComponentUpdatePayload>;
  /** Creates SalaryStructure object */
  salaryStructureCreate?: Maybe<SalaryStructureCreatePayload>;
  /** Destroys SalaryStructure object permanently */
  salaryStructureDestroy?: Maybe<SalaryStructureDestroyPayload>;
  /** Discards/Undiscards SalaryStructure object */
  salaryStructureDiscard?: Maybe<SalaryStructureDiscardPayload>;
  /** Updates SalaryStructure */
  salaryStructureUpdate?: Maybe<SalaryStructureUpdatePayload>;
  /** Send reset password instructions to user */
  sendResetPassword?: Maybe<SendResetPasswordMutationPayload>;
  /** Authenticate user */
  signIn?: Maybe<SignInMutationPayload>;
  /** Logout user */
  signOut?: Maybe<SignOutMutationPayload>;
  /** Sign up user */
  signUp?: Maybe<SignUpMutationPayload>;
  /** Clones inventory transfer */
  stockTransferClone?: Maybe<InventoryTransferClonePayload>;
  /** Creates Transfer object */
  stockTransferCreate?: Maybe<TransferCreatePayload>;
  /** Updates Transfer */
  stockTransferUpdate?: Maybe<TransferUpdatePayload>;
  /** Creates TaxComponent object */
  taxComponentCreate?: Maybe<TaxComponentCreatePayload>;
  /** Destroys TaxComponent object permanently */
  taxComponentDestroy?: Maybe<TaxComponentDestroyPayload>;
  /** Discards/Undiscards TaxComponent object */
  taxComponentDiscard?: Maybe<TaxComponentDiscardPayload>;
  /** Updates TaxComponent */
  taxComponentUpdate?: Maybe<TaxComponentUpdatePayload>;
  /** Creates TaxPlan object */
  taxPlanCreate?: Maybe<TaxPlanCreatePayload>;
  /** Destroys TaxPlan object permanently */
  taxPlanDestroy?: Maybe<TaxPlanDestroyPayload>;
  /** Discards/Undiscards TaxPlan object */
  taxPlanDiscard?: Maybe<TaxPlanDiscardPayload>;
  /** Updates TaxPlan */
  taxPlanUpdate?: Maybe<TaxPlanUpdatePayload>;
  /** Clones current company */
  tenantClone?: Maybe<TenantClonePayload>;
  /** Creates Tenant object */
  tenantCreate?: Maybe<TenantCreatePayload>;
  /** Discards/Undiscards Tenant object */
  tenantDiscard?: Maybe<TenantDiscardPayload>;
  /** Updates Tenant */
  tenantUpdate?: Maybe<TenantUpdatePayload>;
  /** Creates Token object */
  tokenCreate?: Maybe<TokenCreatePayload>;
  /** Discards/Undiscards Token object */
  tokenDiscard?: Maybe<TokenDiscardPayload>;
  /** Updates Token */
  tokenUpdate?: Maybe<TokenUpdatePayload>;
  /** Creates Unit object */
  unitCreate?: Maybe<UnitCreatePayload>;
  /** Discards/Undiscards Unit object */
  unitDiscard?: Maybe<UnitDiscardPayload>;
  /** Toggle Unit locked state */
  unitUnlock?: Maybe<UnitUnlockPayload>;
  /** Updates Unit */
  unitUpdate?: Maybe<UnitUpdatePayload>;
  /** Creates User object */
  userCreate?: Maybe<UserCreatePayload>;
  /** Discards/Undiscards User object */
  userDiscard?: Maybe<UserDiscardPayload>;
  /** Updates User */
  userUpdate?: Maybe<UserUpdatePayload>;
  /** Creates Warehouse object */
  warehouseCreate?: Maybe<WarehouseCreatePayload>;
  /** Discards/Undiscards Warehouse object */
  warehouseDiscard?: Maybe<WarehouseDiscardPayload>;
  /** Updates Warehouse */
  warehouseUpdate?: Maybe<WarehouseUpdatePayload>;
  /** Creates WeatherMeasurement object */
  weatherMeasurementCreate?: Maybe<WeatherMeasurementCreatePayload>;
  /** Destroys WeatherMeasurement object permanently */
  weatherMeasurementDestroy?: Maybe<WeatherMeasurementDestroyPayload>;
  /** Updates WeatherMeasurement */
  weatherMeasurementUpdate?: Maybe<WeatherMeasurementUpdatePayload>;
  /** Clones work order */
  workOrderClone?: Maybe<WorkOrderClonePayload>;
  /** Creates WorkOrder object */
  workOrderCreate?: Maybe<WorkOrderCreatePayload>;
  /** Updates WorkOrder */
  workOrderUpdate?: Maybe<WorkOrderUpdatePayload>;
};

export type MutationAcceptInviteArgs = {
  invitationToken: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
};

export type MutationAccountCreateArgs = {
  fields: AccountFields;
};

export type MutationAccountDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationAccountDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationAccountUpdateArgs = {
  changes: AccountChanges;
  id: Scalars["ID"];
};

export type MutationActivityCategoryCreateArgs = {
  fields: ActivityCategoryFields;
};

export type MutationActivityCategoryDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationActivityCategoryDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationActivityCategoryUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationActivityCategoryUpdateArgs = {
  changes: ActivityCategoryChanges;
  id: Scalars["ID"];
};

export type MutationActivityCreateArgs = {
  fields: ActivityFields;
};

export type MutationActivityDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationActivityDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationActivityPaymentCreateArgs = {
  fields: ActivityPaymentFields;
};

export type MutationActivityPaymentDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationActivityPaymentUpdateArgs = {
  changes: ActivityPaymentChanges;
  id: Scalars["ID"];
};

export type MutationActivityUpdateArgs = {
  changes: ActivityChanges;
  id: Scalars["ID"];
};

export type MutationAdjustmentTypeCreateArgs = {
  fields: AdjustmentTypeFields;
};

export type MutationAdjustmentTypeDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationAdjustmentTypeDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationAdjustmentTypeUpdateArgs = {
  changes: AdjustmentTypeChanges;
  id: Scalars["ID"];
};

export type MutationBankAccountCreateArgs = {
  fields: BankAccountFields;
};

export type MutationBankAccountDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationBankAccountDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationBankAccountUpdateArgs = {
  changes: BankAccountChanges;
  id: Scalars["ID"];
};

export type MutationBankCreateArgs = {
  fields: BankFields;
};

export type MutationBankDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationBankDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationBankUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationBankUpdateArgs = {
  changes: BankChanges;
  id: Scalars["ID"];
};

export type MutationBrandCreateArgs = {
  fields: BrandFields;
};

export type MutationBrandDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationBrandUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationBrandUpdateArgs = {
  changes: BrandChanges;
  id: Scalars["ID"];
};

export type MutationBulkDestroyArgs = {
  destroyableType: DestroyableType;
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  ids: Array<Scalars["ID"]>;
};

export type MutationBulkDiscardArgs = {
  discard: Scalars["Boolean"];
  discardableType: DiscardableType;
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  ids: Array<Scalars["ID"]>;
};

export type MutationCommentCreateArgs = {
  fields: CommentFields;
};

export type MutationCommentDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCommentDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCommentUpdateArgs = {
  changes: CommentChanges;
  id: Scalars["ID"];
};

export type MutationCostCenterCreateArgs = {
  fields: CostCenterFields;
};

export type MutationCostCenterDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCostCenterDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCostCenterUpdateArgs = {
  changes: CostCenterChanges;
  id: Scalars["ID"];
};

export type MutationCounterpartyBulkUpdateArgs = {
  changes: CounterpartyBulkChanges;
  ids: Array<Scalars["ID"]>;
};

export type MutationCounterpartyCreateArgs = {
  fields: CounterpartyFields;
};

export type MutationCounterpartyDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCounterpartyDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCounterpartyUpdateArgs = {
  changes: CounterpartyChanges;
  id: Scalars["ID"];
};

export type MutationCropCreateArgs = {
  fields: CropInputFields;
};

export type MutationCropCycleCloseArgs = {
  closedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  dryRun?: Scalars["Boolean"];
  id: Scalars["ID"];
};

export type MutationCropCycleCreateArgs = {
  fields: CropCycleFields;
};

export type MutationCropCycleDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropCycleDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropCycleReopenArgs = {
  id: Scalars["ID"];
};

export type MutationCropCycleUpdateArgs = {
  changes: CropCycleChanges;
  id: Scalars["ID"];
};

export type MutationCropDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropFieldBulkUpdateArgs = {
  changes: CropFieldBulkChanges;
  ids: Array<Scalars["ID"]>;
};

export type MutationCropFieldCreateArgs = {
  fields: CropFieldFields;
};

export type MutationCropFieldDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropFieldDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropFieldNoteCreateArgs = {
  fields: CropFieldNoteFields;
};

export type MutationCropFieldNoteDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropFieldNoteUpdateArgs = {
  changes: CropFieldNoteChanges;
  id: Scalars["ID"];
};

export type MutationCropFieldUpdateArgs = {
  changes: CropFieldChanges;
  id: Scalars["ID"];
};

export type MutationCropStageCreateArgs = {
  fields: CropStageFields;
};

export type MutationCropStageDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropStageDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropStageUpdateArgs = {
  changes: CropStageChanges;
  id: Scalars["ID"];
};

export type MutationCropUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationCropUpdateArgs = {
  changes: CropChanges;
  id: Scalars["ID"];
};

export type MutationCropVarietyCreateArgs = {
  fields: CropVarietyFields;
};

export type MutationCropVarietyDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropVarietyDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationCropVarietyUpdateArgs = {
  changes: CropVarietyChanges;
  id: Scalars["ID"];
};

export type MutationDiseaseCreateArgs = {
  fields: DiseaseFields;
};

export type MutationDiseaseDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationDiseaseDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationDiseaseRiskUpdateArgs = {
  changes: DiseaseRiskChanges;
  id: Scalars["ID"];
};

export type MutationDiseaseUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationDiseaseUpdateArgs = {
  changes: DiseaseChanges;
  id: Scalars["ID"];
};

export type MutationEmployeeBulkUpdateArgs = {
  changes: EmployeeBulkChanges;
  ids: Array<Scalars["ID"]>;
};

export type MutationEmployeeCreateArgs = {
  fields: EmployeeFields;
};

export type MutationEmployeeDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationEmployeeDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationEmployeeGroupCreateArgs = {
  fields: EmployeeGroupFields;
};

export type MutationEmployeeGroupDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationEmployeeGroupUpdateArgs = {
  changes: EmployeeGroupChanges;
  id: Scalars["ID"];
};

export type MutationEmployeeUpdateArgs = {
  changes: EmployeeChanges;
  id: Scalars["ID"];
};

export type MutationExpenseCategoryCreateArgs = {
  fields: ExpenseCategoryFields;
};

export type MutationExpenseCategoryDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationExpenseCategoryDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationExpenseCategoryUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationExpenseCategoryUpdateArgs = {
  changes: ExpenseCategoryChanges;
  id: Scalars["ID"];
};

export type MutationExpenseItemCreateArgs = {
  fields: ExpenseItemFields;
};

export type MutationExpenseItemDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationExpenseItemDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationExpenseItemUpdateArgs = {
  changes: ExpenseItemChanges;
  id: Scalars["ID"];
};

export type MutationFinanceInvoiceUpdateArgs = {
  changes: InvoiceChanges;
  id: Scalars["ID"];
};

export type MutationFinanceOrderCloneArgs = {
  changes: FinanceOrderChanges;
  id: Scalars["ID"];
};

export type MutationFinanceOrderCreateArgs = {
  fields: FinanceOrderFields;
};

export type MutationFinanceOrderUpdateArgs = {
  changes: FinanceOrderChanges;
  id: Scalars["ID"];
};

export type MutationFinancePaymentCreateArgs = {
  fields: PaymentFields;
};

export type MutationFinancePaymentUpdateArgs = {
  changes: PaymentChanges;
  id: Scalars["ID"];
};

export type MutationFinancePeriodCloseArgs = {
  dryRun?: Scalars["Boolean"];
  id: Scalars["ID"];
};

export type MutationFinancePeriodOpenArgs = {
  id: Scalars["ID"];
};

export type MutationFiscalYearCreateArgs = {
  fields: FiscalYearFields;
};

export type MutationFiscalYearDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationFiscalYearUpdateArgs = {
  changes: FiscalYearChanges;
  id: Scalars["ID"];
};

export type MutationGenerateInvitationLinkArgs = {
  id: Scalars["ID"];
};

export type MutationGenerateResetPasswordLinkArgs = {
  id: Scalars["ID"];
};

export type MutationHarvestEstimateCreateArgs = {
  fields: HarvestEstimateFields;
};

export type MutationHarvestEstimateDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationHarvestEstimateUpdateArgs = {
  changes: HarvestEstimateChanges;
  id: Scalars["ID"];
};

export type MutationHolidayListCreateArgs = {
  fields: HolidayListFields;
};

export type MutationHolidayListDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationHolidayListUpdateArgs = {
  changes: HolidayListChanges;
  id: Scalars["ID"];
};

export type MutationImportCreateArgs = {
  fields: ImportFields;
};

export type MutationImportFireEventArgs = {
  event: ImportEventType;
  id: Scalars["ID"];
};

export type MutationImportUpdateArgs = {
  changes: ImportChanges;
  id: Scalars["ID"];
};

export type MutationInfrastructureCreateArgs = {
  fields: InfrastructureFields;
};

export type MutationInfrastructureDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationInfrastructureDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationInfrastructureUpdateArgs = {
  changes: InfrastructureChanges;
  id: Scalars["ID"];
};

export type MutationIngredientCreateArgs = {
  fields: IngredientFields;
};

export type MutationIngredientDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationIngredientUpdateArgs = {
  changes: IngredientChanges;
  id: Scalars["ID"];
};

export type MutationInventoryOrderCloneArgs = {
  changes: InventoryOrderChanges;
  id: Scalars["ID"];
};

export type MutationInventoryOrderCreateArgs = {
  fields: InventoryOrderFields;
};

export type MutationInventoryOrderUpdateArgs = {
  changes: InventoryOrderChanges;
  id: Scalars["ID"];
};

export type MutationInventoryRequestUpdateArgs = {
  changes: InventoryRequestChanges;
  id: Scalars["ID"];
};

export type MutationItemBulkUpdateArgs = {
  changes: ItemBulkChanges;
  ids: Array<Scalars["ID"]>;
};

export type MutationItemCategoryCreateArgs = {
  fields: ItemCategoryFields;
};

export type MutationItemCategoryDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationItemCategoryDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationItemCategoryLocalUpdateArgs = {
  changes: LocalItemCategoryChanges;
  id: Scalars["ID"];
};

export type MutationItemCategoryUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationItemCategoryUpdateArgs = {
  changes: ItemCategoryChanges;
  id: Scalars["ID"];
};

export type MutationItemCreateArgs = {
  fields: ItemFields;
};

export type MutationItemDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationItemDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationItemUpdateArgs = {
  changes: ItemChanges;
  id: Scalars["ID"];
};

export type MutationJournalEntryCloneArgs = {
  changes: JournalEntryChanges;
  id: Scalars["ID"];
};

export type MutationJournalEntryCreateArgs = {
  fields: JournalEntryFields;
};

export type MutationJournalEntryUpdateArgs = {
  changes: JournalEntryChanges;
  id: Scalars["ID"];
};

export type MutationLeaveAssignmentCreateArgs = {
  fields: LeaveAssignmentFields;
};

export type MutationLeaveAssignmentUpdateArgs = {
  changes: LeaveAssignmentChanges;
  id: Scalars["ID"];
};

export type MutationLeaveTypeCreateArgs = {
  fields: LeaveTypeFields;
};

export type MutationLeaveTypeDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationLeaveTypeUpdateArgs = {
  changes: LeaveTypeChanges;
  id: Scalars["ID"];
};

export type MutationLocalityCreateArgs = {
  fields: LocalityFields;
};

export type MutationLocalityDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationLocalityDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationLocalityUpdateArgs = {
  changes: LocalityChanges;
  id: Scalars["ID"];
};

export type MutationMachineCreateArgs = {
  fields: MachineFields;
};

export type MutationMachineDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationMachineDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationMachineKindCreateArgs = {
  fields: MachineKindFields;
};

export type MutationMachineKindDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationMachineKindUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationMachineKindUpdateArgs = {
  changes: MachineKindChanges;
  id: Scalars["ID"];
};

export type MutationMachineUpdateArgs = {
  changes: MachineChanges;
  id: Scalars["ID"];
};

export type MutationMetricCreateArgs = {
  fields: MetricFields;
};

export type MutationMetricDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationMetricDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationMetricUpdateArgs = {
  changes: MetricChanges;
  id: Scalars["ID"];
};

export type MutationOnboardingSeedActivitiesArgs = {
  names: Array<Scalars["String"]>;
};

export type MutationOnboardingSeedItemsArgs = {
  ids: Array<Scalars["ID"]>;
};

export type MutationOnboardingSeedSalaryStructuresArgs = {
  names: Array<Scalars["String"]>;
};

export type MutationPaymentMethodCreateArgs = {
  fields: PaymentMethodFields;
};

export type MutationPaymentMethodDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationPaymentMethodUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationPaymentMethodUpdateArgs = {
  changes: PaymentMethodChanges;
  id: Scalars["ID"];
};

export type MutationPayrollEntryCreateArgs = {
  fields: PayrollEntryFields;
};

export type MutationPayrollEntryDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationPayrollEntryGenerateArgs = {
  id: Scalars["ID"];
};

export type MutationPayrollEntryUpdateArgs = {
  changes: PayrollEntryChanges;
  id: Scalars["ID"];
};

export type MutationPlanningCloneArgs = {
  changes: PlanningChanges;
  id: Scalars["ID"];
};

export type MutationPlanningCreateArgs = {
  fields: PlanningFields;
};

export type MutationPlanningDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationPlanningUpdateArgs = {
  changes: PlanningChanges;
  id: Scalars["ID"];
};

export type MutationPositionCreateArgs = {
  fields: PositionFields;
};

export type MutationPositionDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationPositionDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationPositionUpdateArgs = {
  changes: PositionChanges;
  id: Scalars["ID"];
};

export type MutationRecipeCreateArgs = {
  fields: RecipeFields;
};

export type MutationRecipeDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationRecipeDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationRecipeUpdateArgs = {
  changes: RecipeChanges;
  id: Scalars["ID"];
};

export type MutationResendInviteArgs = {
  id: Scalars["ID"];
};

export type MutationResetPasswordArgs = {
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
  resetPasswordToken: Scalars["String"];
};

export type MutationRoleCreateArgs = {
  fields: RoleFields;
};

export type MutationRoleDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationRoleUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationRoleUpdateArgs = {
  changes: RoleChanges;
  id: Scalars["ID"];
};

export type MutationSalaryComponentCreateArgs = {
  fields: SalaryComponentFields;
};

export type MutationSalaryComponentDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationSalaryComponentDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationSalaryComponentUpdateArgs = {
  changes: SalaryComponentChanges;
  id: Scalars["ID"];
};

export type MutationSalaryStructureCreateArgs = {
  fields: SalaryStructureFields;
};

export type MutationSalaryStructureDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationSalaryStructureDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationSalaryStructureUpdateArgs = {
  changes: SalaryStructureChanges;
  id: Scalars["ID"];
  inputVariables?: InputMaybe<PayrollInputVariables>;
};

export type MutationSendResetPasswordArgs = {
  email: Scalars["String"];
};

export type MutationSignInArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
  rememberMe?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationSignUpArgs = {
  countryCode: Scalars["String"];
  cropId?: InputMaybe<Scalars["ID"]>;
  email: Scalars["String"];
  firstName: Scalars["String"];
  invitationToken?: InputMaybe<Scalars["String"]>;
  lastName: Scalars["String"];
  name: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
  tenant?: InputMaybe<Scalars["String"]>;
};

export type MutationStockTransferCloneArgs = {
  changes: TransferChanges;
  id: Scalars["ID"];
};

export type MutationStockTransferCreateArgs = {
  fields: TransferFields;
};

export type MutationStockTransferUpdateArgs = {
  changes: TransferChanges;
  id: Scalars["ID"];
};

export type MutationTaxComponentCreateArgs = {
  fields: TaxComponentFields;
};

export type MutationTaxComponentDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationTaxComponentDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationTaxComponentUpdateArgs = {
  changes: TaxComponentChanges;
  id: Scalars["ID"];
};

export type MutationTaxPlanCreateArgs = {
  fields: TaxPlanFields;
};

export type MutationTaxPlanDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationTaxPlanDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationTaxPlanUpdateArgs = {
  changes: TaxPlanChanges;
  id: Scalars["ID"];
  inputVariables?: InputMaybe<TaxPlanVariables>;
};

export type MutationTenantCloneArgs = {
  reset?: InputMaybe<Scalars["Boolean"]>;
};

export type MutationTenantCreateArgs = {
  fields: TenantFields;
};

export type MutationTenantDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationTenantUpdateArgs = {
  changes: TenantChanges;
  id: Scalars["ID"];
};

export type MutationTokenCreateArgs = {
  fields: TokenFields;
};

export type MutationTokenDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationTokenUpdateArgs = {
  changes: TokenChanges;
  id: Scalars["ID"];
};

export type MutationUnitCreateArgs = {
  fields: UnitFields;
};

export type MutationUnitDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationUnitUnlockArgs = {
  id: Scalars["ID"];
};

export type MutationUnitUpdateArgs = {
  changes: UnitChanges;
  id: Scalars["ID"];
};

export type MutationUserCreateArgs = {
  fields: UserFields;
};

export type MutationUserDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationUserUpdateArgs = {
  changes: UserChanges;
  id: Scalars["ID"];
};

export type MutationWarehouseCreateArgs = {
  fields: WarehouseFields;
};

export type MutationWarehouseDiscardArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationWarehouseUpdateArgs = {
  changes: WarehouseChanges;
  id: Scalars["ID"];
};

export type MutationWeatherMeasurementCreateArgs = {
  fields: WeatherMeasurementFields;
};

export type MutationWeatherMeasurementDestroyArgs = {
  dryRun?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationWeatherMeasurementUpdateArgs = {
  changes: WeatherMeasurementChanges;
  id: Scalars["ID"];
};

export type MutationWorkOrderCloneArgs = {
  changes: WorkOrderChanges;
  copyProgress?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["ID"];
};

export type MutationWorkOrderCreateArgs = {
  fields: WorkOrderFields;
};

export type MutationWorkOrderUpdateArgs = {
  changes: WorkOrderChanges;
  id: Scalars["ID"];
};

/** Collection of OnboardingSeedsItems */
export type OnboardingSeedsItems = {
  __typename?: "OnboardingSeedsItems";
  /** Paginated collection of OnboardingSeedsItems */
  items: Array<ItemSeed>;
  /** Total count of OnboardingSeedsItems */
  totalCount: Scalars["Int"];
};

/** OnboardingSeedsItems filter */
export type OnboardingSeedsItemsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Item description */
  description?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Re-entry period */
  entryPeriod?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Include existing items */
  includePersisted?: InputMaybe<Scalars["Boolean"]>;
  kind?: InputMaybe<Array<ItemKind>>;
  /** May update variation unit or item kind */
  mayUpdateUnitKind?: InputMaybe<Scalars["Boolean"]>;
  /** Item name */
  name?: InputMaybe<StringMatchInput>;
  persisted?: InputMaybe<Scalars["Boolean"]>;
  /** Re-entry period */
  preHarvestInterval?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Field tags */
  tags?: InputMaybe<StringMatchInput>;
  /** Toxicity category */
  toxicityCategory?: InputMaybe<Array<ToxicityCategory>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Variation unit type */
  variationUnitType?: InputMaybe<Array<UnitType>>;
};

/** OnboardingSeedsItems sorting options */
export type OnboardingSeedsItemsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Item description */
  description?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Re-entry period */
  entryPeriod?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  kind?: InputMaybe<SortDirection>;
  /** May update variation unit or item kind */
  mayUpdateUnitKind?: InputMaybe<SortDirection>;
  /** Item name */
  name?: InputMaybe<SortDirection>;
  persisted?: InputMaybe<SortDirection>;
  /** Re-entry period */
  preHarvestInterval?: InputMaybe<SortDirection>;
  /** Field tags */
  tags?: InputMaybe<SortDirection>;
  /** Toxicity category */
  toxicityCategory?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Variation unit type */
  variationUnitType?: InputMaybe<SortDirection>;
};

/** Onboarding status */
export type OnboardingStatus = {
  __typename?: "OnboardingStatus";
  status: Array<OnboardingStepStatus>;
};

export enum OnboardingStep {
  /** Activity creation */
  Activities = "activities",
  /** Company settings */
  Company = "company",
  /** Counterparty creation */
  Counterparties = "counterparties",
  /** Crop cycle creation */
  CropCycles = "crop_cycles",
  /** Create crop field */
  CropFields = "crop_fields",
  /** Employee creation */
  Employees = "employees",
  /** Expense order creation */
  ExpenseOrders = "expense_orders",
  /** Harvest estimate creation */
  HarvestEstimates = "harvest_estimates",
  /** Inputs creation */
  Inputs = "inputs",
  /** Item creation */
  Items = "items",
  /** Farm creation */
  Localities = "localities",
  /** Machine creation */
  Machines = "machines",
  /** Planning creation */
  Plannings = "plannings",
  /** Purchase order creation */
  PurchaseOrders = "purchase_orders",
  /** Sale order creation */
  SaleOrders = "sale_orders",
  /** User creation */
  Users = "users",
  /** Work order creation */
  WorkOrders = "work_orders",
}

/** Onboarding step status */
export type OnboardingStepStatus = {
  __typename?: "OnboardingStepStatus";
  finished: Scalars["Boolean"];
  permission: Scalars["String"];
  step: OnboardingStep;
};

/** Autogenerated return type of OpenMutation. */
export type OpenMutationPayload = {
  __typename?: "OpenMutationPayload";
  errors: Array<UserError>;
  result?: Maybe<Period>;
};

/** Payment changes object */
export type PaymentChanges = {
  exchangeRate?: InputMaybe<Scalars["Float"]>;
  internalId?: InputMaybe<Scalars["String"]>;
  /** Invoice payment amount */
  invoiceAmount?: InputMaybe<Scalars["Float"]>;
  /** Payment date */
  paymentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Payment status */
  status?: InputMaybe<FinancePaymentStatus>;
  /** Total payment amount */
  totalAmount?: InputMaybe<Scalars["Float"]>;
  /** Withholding rate */
  withholdingRate?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of PaymentCreate. */
export type PaymentCreatePayload = {
  __typename?: "PaymentCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<FinancePayment>;
};

/** Payment field values */
export type PaymentFields = {
  /** Payment Account */
  accountId?: InputMaybe<Scalars["ID"]>;
  /** Amounts paid */
  amounts: Array<Scalars["Float"]>;
  exchangeRate: Scalars["Float"];
  internalId?: InputMaybe<Scalars["String"]>;
  /** Invoices */
  invoiceIds: Array<Scalars["ID"]>;
  /** Payment date */
  paymentDate: Scalars["ISO8601DateTime"];
  /** Withholding rate */
  withholdingRate: Scalars["Float"];
};

/** Payment method object */
export type PaymentMethod = Discardable &
  Global & {
    __typename?: "PaymentMethod";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Days until credit must be paid */
    dueDays: Scalars["Int"];
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Payment method name */
    name: Scalars["String"];
    /** Payment method name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Payment method name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Payment method name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Payment method name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Payment method type */
    paymentMethodType: PaymentMethodType;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** PaymentMethod changes object */
export type PaymentMethodChanges = {
  /** Days until credit must be paid */
  dueDays?: InputMaybe<Scalars["Int"]>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Payment method name */
  name?: InputMaybe<Scalars["String"]>;
  /** Payment method name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Payment method name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Payment method name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Payment method name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Payment method type */
  paymentMethodType?: InputMaybe<PaymentMethodType>;
};

/** Autogenerated return type of PaymentMethodCreate. */
export type PaymentMethodCreatePayload = {
  __typename?: "PaymentMethodCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<PaymentMethod>;
};

/** Autogenerated return type of PaymentMethodDiscard. */
export type PaymentMethodDiscardPayload = {
  __typename?: "PaymentMethodDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<PaymentMethod>;
};

/** PaymentMethod field values */
export type PaymentMethodFields = {
  /** Days until credit must be paid */
  dueDays: Scalars["Int"];
  /** Is global? */
  global: Scalars["Boolean"];
  /** Is locked */
  locked: Scalars["Boolean"];
  /** Payment method name */
  name: Scalars["String"];
  /** Payment method name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Payment method name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Payment method name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Payment method name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Payment method type */
  paymentMethodType: PaymentMethodType;
};

export enum PaymentMethodType {
  /** Credit */
  Credit = "credit",
  /** Debit */
  Debit = "debit",
}

/** Autogenerated return type of PaymentMethodUnlock. */
export type PaymentMethodUnlockPayload = {
  __typename?: "PaymentMethodUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<PaymentMethod>;
};

/** Autogenerated return type of PaymentMethodUpdate. */
export type PaymentMethodUpdatePayload = {
  __typename?: "PaymentMethodUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<PaymentMethod>;
};

/** Collection of PaymentMethods */
export type PaymentMethods = {
  __typename?: "PaymentMethods";
  /** Paginated collection of PaymentMethods */
  items: Array<PaymentMethod>;
  /** Total count of PaymentMethods */
  totalCount: Scalars["Int"];
};

/** PaymentMethods filter */
export type PaymentMethodsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Days until credit must be paid */
  dueDays?: InputMaybe<Array<Scalars["Int"]>>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Payment method name */
  name?: InputMaybe<StringMatchInput>;
  /** Payment method name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Payment method name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Payment method name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Payment method name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Payment method type */
  paymentMethodType?: InputMaybe<Array<PaymentMethodType>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** PaymentMethods sorting options */
export type PaymentMethodsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Days until credit must be paid */
  dueDays?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Payment method name */
  name?: InputMaybe<SortDirection>;
  /** Payment method name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Payment method name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Payment method name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Payment method name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Payment method type */
  paymentMethodType?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum PaymentStatus {
  /** Not Paid */
  NotPaid = "not_paid",
  /** Paid */
  Paid = "paid",
  /** Partially Paid */
  PartiallyPaid = "partially_paid",
}

/** Autogenerated return type of PaymentUpdate. */
export type PaymentUpdatePayload = {
  __typename?: "PaymentUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<FinancePayment>;
};

export type PayrollComponentResult = {
  __typename?: "PayrollComponentResult";
  /** Salary Component */
  component: SalaryComponent;
  /** Error */
  error?: Maybe<Scalars["String"]>;
  /** Evaluated value */
  value?: Maybe<Scalars["Float"]>;
};

/** Collection of PayrollEntries */
export type PayrollEntries = {
  __typename?: "PayrollEntries";
  /** Paginated collection of PayrollEntries */
  items: Array<PayrollEntry>;
  /** Total count of PayrollEntries */
  totalCount: Scalars["Int"];
};

/** PayrollEntries filter */
export type PayrollEntriesFilterInput = {
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Document Date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Employee */
  employeeId?: InputMaybe<Array<Scalars["ID"]>>;
  endDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  internalId?: InputMaybe<StringMatchInput>;
  /** Localities */
  localityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  startDate?: InputMaybe<DateRangeInput>;
  status?: InputMaybe<Array<PayrollEntryStatus>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** PayrollEntries sorting options */
export type PayrollEntriesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Document Date */
  documentDate?: InputMaybe<SortDirection>;
  endDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  internalId?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Payroll entry */
export type PayrollEntry = Auditable &
  Timestamps & {
    __typename?: "PayrollEntry";
    /** Debit and Credit Components */
    accountingComponents: Array<PayrollComponentResult>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by */
    createdBy?: Maybe<User>;
    /** Document Date */
    documentDate: Scalars["ISO8601DateTime"];
    /** Employees */
    employees: Array<PayrollEntryEmployee>;
    endDate: Scalars["ISO8601Date"];
    id: Scalars["ID"];
    internalId?: Maybe<Scalars["String"]>;
    /** Entry locality */
    localities: Array<Locality>;
    /** Salary structure */
    salaryStructure?: Maybe<SalaryStructure>;
    startDate: Scalars["ISO8601Date"];
    status: PayrollEntryStatus;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
    /** Related Work Orders */
    workOrders: Array<PayrollEntryWorkOrder>;
  };

/** PayrollEntry changes object */
export type PayrollEntryChanges = {
  /** Document Date */
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Employees */
  employeesAttributes?: InputMaybe<Array<PayrollEntryEmployeeChanges>>;
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  internalId?: InputMaybe<Scalars["String"]>;
  /** Entry localities */
  localityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Salary structure ID */
  salaryStructureId?: InputMaybe<Scalars["ID"]>;
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
  status?: InputMaybe<PayrollEntryStatus>;
};

/** Autogenerated return type of PayrollEntryCreate. */
export type PayrollEntryCreatePayload = {
  __typename?: "PayrollEntryCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<PayrollEntry>;
};

/** Autogenerated return type of PayrollEntryDestroy. */
export type PayrollEntryDestroyPayload = {
  __typename?: "PayrollEntryDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Payroll employee */
export type PayrollEntryEmployee = Timestamps & {
  __typename?: "PayrollEntryEmployee";
  /** Components */
  components: Array<PayrollEntryEmployeeComponent>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Employee */
  employee: Employee;
  id: Scalars["ID"];
  inputVariables: Scalars["JSON"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** PayrollEntryEmployee changes object */
export type PayrollEntryEmployeeChanges = {
  /** Remove employee */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Components */
  componentsAttributes?: InputMaybe<
    Array<PayrollEntryEmployeeComponentChanges>
  >;
  /** Employee ID */
  employeeId?: InputMaybe<Scalars["ID"]>;
  /** Payroll employee ID */
  id?: InputMaybe<Scalars["ID"]>;
  inputVariables?: InputMaybe<Scalars["JSON"]>;
};

/** Payroll employee component */
export type PayrollEntryEmployeeComponent = Timestamps & {
  __typename?: "PayrollEntryEmployeeComponent";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Error */
  error?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Salary component */
  salaryComponent: SalaryComponent;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Evaluated value */
  value: Scalars["Float"];
};

/** PayrollEntryEmployeeComponent changes object */
export type PayrollEntryEmployeeComponentChanges = {
  /** Payroll employee component ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Evaluated value */
  value?: InputMaybe<Scalars["Float"]>;
};

/** PayrollEntryEmployee field values */
export type PayrollEntryEmployeeFields = {
  /** Employee ID */
  employeeId: Scalars["ID"];
};

/** PayrollEntry field values */
export type PayrollEntryFields = {
  /** Document Date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Employees */
  employeesAttributes: Array<PayrollEntryEmployeeFields>;
  endDate: Scalars["ISO8601Date"];
  internalId?: InputMaybe<Scalars["String"]>;
  /** Entry localities */
  localityIds: Array<Scalars["ID"]>;
  /** Salary structure ID */
  salaryStructureId: Scalars["ID"];
  startDate: Scalars["ISO8601Date"];
  status: PayrollEntryStatus;
};

/** Autogenerated return type of PayrollEntryGenerate. */
export type PayrollEntryGeneratePayload = {
  __typename?: "PayrollEntryGeneratePayload";
  jobId: Scalars["ID"];
};

export enum PayrollEntryStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** Open */
  Open = "open",
  /** Reverted */
  Reverted = "reverted",
}

/** Autogenerated return type of PayrollEntryUpdate. */
export type PayrollEntryUpdatePayload = {
  __typename?: "PayrollEntryUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<PayrollEntry>;
};

/** Payroll work order data */
export type PayrollEntryWorkOrder = {
  __typename?: "PayrollEntryWorkOrder";
  id: Scalars["ID"];
  /** Payroll Cost */
  payrollCost?: Maybe<Scalars["Float"]>;
  /** Work Order */
  workOrder: WorkOrder;
};

export type PayrollInputVariables = {
  /** Absences payed */
  absencePay?: InputMaybe<Scalars["Float"]>;
  /** Absent days */
  absentDays?: InputMaybe<Scalars["Int"]>;
  /** Attended days */
  attendedDays?: InputMaybe<Scalars["Int"]>;
  /** Base daily pay */
  basePay?: InputMaybe<Scalars["Float"]>;
  /** Employee contract daily wage */
  dailyWage?: InputMaybe<Scalars["Float"]>;
  /** Number of holidays */
  holidays?: InputMaybe<Scalars["Int"]>;
  /** Employee contract hourly wage */
  hourlyWage?: InputMaybe<Scalars["Float"]>;
  /** Hours worked */
  hours?: InputMaybe<Scalars["Float"]>;
  /** Leaves stats */
  leaves?: InputMaybe<Scalars["JSON"]>;
  /** Employee contract daily wage */
  monthlyWage?: InputMaybe<Scalars["Float"]>;
  /** Overtime hours worked */
  overtimeHours?: InputMaybe<Scalars["Float"]>;
  /** Overtime pay */
  overtimePay?: InputMaybe<Scalars["Float"]>;
  /** Number of seventh days */
  seventhDays?: InputMaybe<Scalars["Int"]>;
};

export type PayrollResult = {
  __typename?: "PayrollResult";
  /** Payroll components */
  components: Array<PayrollComponentResult>;
  /** Salary structure */
  salaryStructure: SalaryStructure;
};

/** Accounting period object */
export type Period = Timestamps & {
  __typename?: "Period";
  /** Closed timestamp */
  closedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** End date of fiscal year */
  endDate: Scalars["ISO8601Date"];
  /** Related fiscal year */
  fiscalYear: FiscalYear;
  id: Scalars["ID"];
  /** Period name */
  name: Scalars["String"];
  /** Start date of fiscal year */
  startDate: Scalars["ISO8601Date"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Period changes object */
export type PeriodChanges = {
  /** Remove accounting period */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Closed timestamp */
  closedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** End date of fiscal year */
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Period ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Period name */
  name?: InputMaybe<Scalars["String"]>;
  /** Start date of fiscal year */
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
};

/** Autogenerated return type of PeriodCloseMutation. */
export type PeriodCloseMutationPayload = {
  __typename?: "PeriodCloseMutationPayload";
  activeDocuments: Array<FinanceDocumentReference>;
  errors: Array<UserError>;
  result?: Maybe<Period>;
};

/** Period field values */
export type PeriodFields = {
  /** Closed timestamp */
  closedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** End date of fiscal year */
  endDate: Scalars["ISO8601Date"];
  /** Start date of fiscal year */
  startDate: Scalars["ISO8601Date"];
};

/** Permissions object */
export type Permissions = {
  __typename?: "Permissions";
  account?: Maybe<RwdPermissions>;
  activity?: Maybe<RwdPermissions>;
  activityCategory?: Maybe<RwdPermissions>;
  adjustmentType?: Maybe<RwdPermissions>;
  bank?: Maybe<RwdPermissions>;
  brand?: Maybe<RwdPermissions>;
  changeLog?: Maybe<Change_LogPermissions>;
  comment?: Maybe<RwdPermissions>;
  costCenter?: Maybe<RwdPermissions>;
  counterparty?: Maybe<RwdPermissions>;
  crop?: Maybe<RwdPermissions>;
  cropCycle?: Maybe<RwdPermissions>;
  cropField?: Maybe<RwdPermissions>;
  cropStage?: Maybe<RwdPermissions>;
  disease?: Maybe<RwdPermissions>;
  employee?: Maybe<RwdPermissions>;
  employeeGroup?: Maybe<RwdPermissions>;
  expenseCategory?: Maybe<RwdPermissions>;
  expenseItem?: Maybe<RwdPermissions>;
  farmMap?: Maybe<Farm_MapPermissions>;
  financeInvoice?: Maybe<RwdPermissions>;
  financeOrder?: Maybe<RwdPermissions>;
  fiscalYear?: Maybe<RwdPermissions>;
  harvestEstimate?: Maybe<RwdPermissions>;
  holiday?: Maybe<RwdPermissions>;
  infrastructure?: Maybe<RwdPermissions>;
  infrastructureKind?: Maybe<RwdPermissions>;
  ingredient?: Maybe<RwdPermissions>;
  inventoryRequest?: Maybe<RwdPermissions>;
  item?: Maybe<RwdPermissions>;
  itemCategory?: Maybe<RwdPermissions>;
  journalEntry?: Maybe<RwdPermissions>;
  leaveAssignment?: Maybe<RwdPermissions>;
  leaveType?: Maybe<RwdPermissions>;
  locality?: Maybe<RwdPermissions>;
  machine?: Maybe<RwdPermissions>;
  machineKind?: Maybe<RwdPermissions>;
  metric?: Maybe<RwdPermissions>;
  paymentMethod?: Maybe<RwdPermissions>;
  payrollEntry?: Maybe<RwdPermissions>;
  planning?: Maybe<RwdPermissions>;
  position?: Maybe<RwdPermissions>;
  purchase?: Maybe<RwdPermissions>;
  recipe?: Maybe<RwdPermissions>;
  report?: Maybe<ReportPermissions>;
  role?: Maybe<RwdPermissions>;
  salaryStructure?: Maybe<RwdPermissions>;
  sale?: Maybe<RwdPermissions>;
  settings?: Maybe<SettingsPermissions>;
  taxPlan?: Maybe<RwdPermissions>;
  tenant?: Maybe<RwdPermissions>;
  transfer?: Maybe<RwdPermissions>;
  unit?: Maybe<RwdPermissions>;
  user?: Maybe<RwdPermissions>;
  weatherMeasurement?: Maybe<RwdPermissions>;
  workOrder?: Maybe<RwdPermissions>;
};

/** Specifies permissions */
export type PermissionsInput = {
  account?: InputMaybe<RwdPermissionsInput>;
  activity?: InputMaybe<RwdPermissionsInput>;
  activityCategory?: InputMaybe<RwdPermissionsInput>;
  adjustmentType?: InputMaybe<RwdPermissionsInput>;
  bank?: InputMaybe<RwdPermissionsInput>;
  brand?: InputMaybe<RwdPermissionsInput>;
  changeLog?: InputMaybe<Change_LogInputPermissions>;
  comment?: InputMaybe<RwdPermissionsInput>;
  costCenter?: InputMaybe<RwdPermissionsInput>;
  counterparty?: InputMaybe<RwdPermissionsInput>;
  crop?: InputMaybe<RwdPermissionsInput>;
  cropCycle?: InputMaybe<RwdPermissionsInput>;
  cropField?: InputMaybe<RwdPermissionsInput>;
  cropStage?: InputMaybe<RwdPermissionsInput>;
  disease?: InputMaybe<RwdPermissionsInput>;
  employee?: InputMaybe<RwdPermissionsInput>;
  employeeGroup?: InputMaybe<RwdPermissionsInput>;
  expenseCategory?: InputMaybe<RwdPermissionsInput>;
  expenseItem?: InputMaybe<RwdPermissionsInput>;
  farmMap?: InputMaybe<Farm_MapInputPermissions>;
  financeInvoice?: InputMaybe<RwdPermissionsInput>;
  financeOrder?: InputMaybe<RwdPermissionsInput>;
  fiscalYear?: InputMaybe<RwdPermissionsInput>;
  harvestEstimate?: InputMaybe<RwdPermissionsInput>;
  holiday?: InputMaybe<RwdPermissionsInput>;
  infrastructure?: InputMaybe<RwdPermissionsInput>;
  infrastructureKind?: InputMaybe<RwdPermissionsInput>;
  inventoryRequest?: InputMaybe<RwdPermissionsInput>;
  item?: InputMaybe<RwdPermissionsInput>;
  itemCategory?: InputMaybe<RwdPermissionsInput>;
  journalEntry?: InputMaybe<RwdPermissionsInput>;
  leaveAssignment?: InputMaybe<RwdPermissionsInput>;
  leaveType?: InputMaybe<RwdPermissionsInput>;
  locality?: InputMaybe<RwdPermissionsInput>;
  machine?: InputMaybe<RwdPermissionsInput>;
  metric?: InputMaybe<RwdPermissionsInput>;
  paymentMethod?: InputMaybe<RwdPermissionsInput>;
  payrollEntry?: InputMaybe<RwdPermissionsInput>;
  planning?: InputMaybe<RwdPermissionsInput>;
  position?: InputMaybe<RwdPermissionsInput>;
  purchase?: InputMaybe<RwdPermissionsInput>;
  recipe?: InputMaybe<RwdPermissionsInput>;
  report?: InputMaybe<ReportInputPermissions>;
  role?: InputMaybe<RwdPermissionsInput>;
  salaryStructure?: InputMaybe<RwdPermissionsInput>;
  sale?: InputMaybe<RwdPermissionsInput>;
  settings?: InputMaybe<SettingsInputPermissions>;
  taxPlan?: InputMaybe<RwdPermissionsInput>;
  transfer?: InputMaybe<RwdPermissionsInput>;
  unit?: InputMaybe<RwdPermissionsInput>;
  user?: InputMaybe<RwdPermissionsInput>;
  weatherMeasurement?: InputMaybe<RwdPermissionsInput>;
  workOrder?: InputMaybe<RwdPermissionsInput>;
};

/** Work order object */
export type Planning = Auditable &
  Commentable &
  Timestamps & {
    __typename?: "Planning";
    /** Activity */
    activity: Activity;
    /** Activity progress */
    actualProgress: Scalars["Float"];
    /** Number of working days */
    actualWorkDays: Scalars["Int"];
    /** Comments */
    comments: Array<Comment>;
    /** Cost centers */
    costCenters: Array<PlanningCostCenter>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by */
    createdBy?: Maybe<User>;
    /** Crop cycle */
    cropCycle?: Maybe<CropCycle>;
    /** Zone */
    cropFieldGroup?: Maybe<Scalars["String"]>;
    /** Crop stages */
    cropStages: Array<CropStage>;
    /** Cycle Number */
    cycleNumber?: Maybe<Scalars["Int"]>;
    /** Estimated End Date */
    endDate: Scalars["ISO8601Date"];
    /** End week number */
    endWeek?: Maybe<Scalars["Int"]>;
    /** Expenses */
    expenseItems: Array<PlanningExpenseItem>;
    id: Scalars["ID"];
    /** Inputs */
    inputs: Array<PlanningVariant>;
    /** Locality */
    locality: Locality;
    /** Machinery */
    machines: Array<PlanningMachine>;
    /** Positions */
    positions: Array<PlanningPosition>;
    /** Start Date */
    startDate: Scalars["ISO8601Date"];
    /** Start week number */
    startWeek?: Maybe<Scalars["Int"]>;
    /** Planning status */
    status: PlanningStatus;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
    /** Related work orders */
    workOrders: Array<WorkOrder>;
  };

/** Planning changes object */
export type PlanningChanges = {
  /** Activity */
  activityId?: InputMaybe<Scalars["ID"]>;
  /** Cost centers */
  costCentersAttributes?: InputMaybe<Array<PlanningCostCenterChanges>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Zone */
  cropFieldGroup?: InputMaybe<Scalars["String"]>;
  /** Crop stages */
  cropStageIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cycle Number */
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  /** Estimated End Date */
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** End week number */
  endWeek?: InputMaybe<Scalars["Int"]>;
  /** Expenses */
  expenseItemsAttributes?: InputMaybe<Array<PlanningExpenseItemChanges>>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Machinery */
  machinesAttributes?: InputMaybe<Array<PlanningMachineChanges>>;
  /** Positions */
  positionsAttributes?: InputMaybe<Array<PlanningPositionChanges>>;
  /** Start Date */
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Start week number */
  startWeek?: InputMaybe<Scalars["Int"]>;
  /** Planning status */
  status?: InputMaybe<PlanningStatus>;
  /** Variants */
  variantsAttributes?: InputMaybe<Array<PlanningVariantChanges>>;
};

/** Autogenerated return type of PlanningClone. */
export type PlanningClonePayload = {
  __typename?: "PlanningClonePayload";
  errors: Array<UserError>;
  result?: Maybe<Planning>;
};

/** Planning cost center */
export type PlanningCostCenter = HasProfitable & {
  __typename?: "PlanningCostCenter";
  /** Actual input cost */
  actualInputCost: Scalars["Float"];
  /** Actual labor cost */
  actualLaborCost: Scalars["Float"];
  /** Number of machine hours */
  actualMachineHours: Scalars["Int"];
  /** Number of man days */
  actualManDays: Scalars["Float"];
  /** Current progress */
  actualProgress: Scalars["Float"];
  /** Number of working days */
  actualWorkDays: Scalars["Int"];
  /** Cost Center */
  costCenter: CostCenter;
  /** Crop Field */
  cropField?: Maybe<CropField>;
  /** Distribution rate */
  distributionRate: Scalars["Float"];
  /** Start Date */
  endDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Expenses */
  expenseItems: Array<PlanningCostCenterExpenseItem>;
  /** Goal */
  goal: Scalars["Float"];
  id?: Maybe<Scalars["ID"]>;
  /** Sort index */
  index: Scalars["Int"];
  /** Machine norms */
  machines: Array<PlanningCostCenterMachine>;
  /** Position norms */
  positions: Array<PlanningCostCenterPosition>;
  /** Profitable extra info */
  profitableExtra?: Maybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: Maybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: Maybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: Maybe<ProfitableType>;
  /** Start Date */
  startDate?: Maybe<Scalars["ISO8601Date"]>;
  /** Cost center dosages */
  variants: Array<PlanningCostCenterVariant>;
};

/** PlanningCostCenter changes object */
export type PlanningCostCenterChanges = {
  /** Remove cost center */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Cost center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Start Date */
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Expenses */
  expenseItemsAttributes?: InputMaybe<
    Array<PlanningCostCenterExpenseItemChanges>
  >;
  /** Goal */
  goal?: InputMaybe<Scalars["Float"]>;
  /** Planning crop field ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Sort index */
  index?: InputMaybe<Scalars["Int"]>;
  /** Machine norms */
  machinesAttributes?: InputMaybe<Array<PlanningCostCenterMachineChanges>>;
  /** Position norms */
  positionsAttributes?: InputMaybe<Array<PlanningCostCenterPositionChanges>>;
  /** Profitable extra info */
  profitableExtra?: InputMaybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: InputMaybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: InputMaybe<ProfitableType>;
  /** Start Date */
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Variant dosage */
  variantsAttributes?: InputMaybe<Array<PlanningCostCenterVariantChanges>>;
};

/** Planning cost center expense */
export type PlanningCostCenterExpenseItem = {
  __typename?: "PlanningCostCenterExpenseItem";
  /** Cost */
  cost: Scalars["Float"];
  /** Expense Item ID */
  expenseItemId: Scalars["ID"];
  id?: Maybe<Scalars["ID"]>;
};

/** PlanningCostCenterExpenseItem changes object */
export type PlanningCostCenterExpenseItemChanges = {
  /** Cost */
  cost?: InputMaybe<Scalars["Float"]>;
  /** Expense Item ID */
  expenseItemId?: InputMaybe<Scalars["ID"]>;
  /** Planning cost center expense item ID */
  id?: InputMaybe<Scalars["ID"]>;
};

/** PlanningCostCenterExpenseItem field values */
export type PlanningCostCenterExpenseItemFields = {
  /** Cost */
  cost: Scalars["Float"];
  /** Expense Item ID */
  expenseItemId: Scalars["ID"];
};

/** PlanningCostCenter field values */
export type PlanningCostCenterFields = {
  /** Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Start Date */
  endDate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Expenses */
  expenseItemsAttributes?: InputMaybe<
    Array<PlanningCostCenterExpenseItemFields>
  >;
  /** Goal */
  goal: Scalars["Float"];
  /** Sort index */
  index: Scalars["Int"];
  /** Machines norms */
  machinesAttributes?: InputMaybe<Array<PlanningCostCenterMachineFields>>;
  /** Positions norms */
  positionsAttributes?: InputMaybe<Array<PlanningCostCenterPositionFields>>;
  /** Profitable extra info */
  profitableExtra?: InputMaybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: InputMaybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: InputMaybe<ProfitableType>;
  /** Start Date */
  startDate?: InputMaybe<Scalars["ISO8601Date"]>;
  /** Variants dosage */
  variantsAttributes?: InputMaybe<Array<PlanningCostCenterVariantFields>>;
};

/** Planning cost center machine norm */
export type PlanningCostCenterMachine = {
  __typename?: "PlanningCostCenterMachine";
  id?: Maybe<Scalars["ID"]>;
  /** Machine ID */
  machineKindId: Scalars["ID"];
  /** Progress Norm */
  progressNorm: Scalars["Float"];
};

/** PlanningCostCenterMachine changes object */
export type PlanningCostCenterMachineChanges = {
  /** Planning cost center machine ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Machine ID */
  machineKindId?: InputMaybe<Scalars["ID"]>;
  /** Progress Norm */
  progressNorm?: InputMaybe<Scalars["Float"]>;
};

/** PlanningCostCenterMachine field values */
export type PlanningCostCenterMachineFields = {
  /** Machine ID */
  machineKindId: Scalars["ID"];
  /** Progress Norm */
  progressNorm: Scalars["Float"];
};

/** Planning cost center position norm */
export type PlanningCostCenterPosition = {
  __typename?: "PlanningCostCenterPosition";
  /** Actual cost */
  actualCost: Scalars["Float"];
  id?: Maybe<Scalars["ID"]>;
  /** Man Days */
  manDays?: Maybe<Scalars["Float"]>;
  /** Position ID */
  positionId: Scalars["ID"];
  /** Progress Norm */
  progressNorm: Scalars["Float"];
};

/** PlanningCostCenterPosition changes object */
export type PlanningCostCenterPositionChanges = {
  /** Planning cost center position ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Man Days */
  manDays?: InputMaybe<Scalars["Float"]>;
  /** Position ID */
  positionId?: InputMaybe<Scalars["ID"]>;
  /** Progress Norm */
  progressNorm?: InputMaybe<Scalars["Float"]>;
};

/** PlanningCostCenterPosition field values */
export type PlanningCostCenterPositionFields = {
  /** Man Days */
  manDays?: InputMaybe<Scalars["Float"]>;
  /** Position ID */
  positionId: Scalars["ID"];
  /** Progress Norm */
  progressNorm: Scalars["Float"];
};

/** Planning cost center variant dosage */
export type PlanningCostCenterVariant = {
  __typename?: "PlanningCostCenterVariant";
  /** Actual cost */
  actualCost: Scalars["Float"];
  /** Actual dosage */
  actualDosage: Scalars["Float"];
  /** Actual consumed amount */
  consumedAmount: Scalars["Float"];
  /** Dosage */
  dosage: Scalars["Float"];
  id?: Maybe<Scalars["ID"]>;
  /** Total amount */
  totalAmount?: Maybe<Scalars["Float"]>;
  /** Variant ID */
  variantId: Scalars["ID"];
};

/** PlanningCostCenterVariant changes object */
export type PlanningCostCenterVariantChanges = {
  /** Dosage */
  dosage?: InputMaybe<Scalars["Float"]>;
  /** Planning crop field variant ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Total amount */
  totalAmount?: InputMaybe<Scalars["Float"]>;
  /** Variant ID */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** PlanningCostCenterVariant field values */
export type PlanningCostCenterVariantFields = {
  /** Dosage */
  dosage: Scalars["Float"];
  /** Total amount */
  totalAmount?: InputMaybe<Scalars["Float"]>;
  /** Variant ID */
  variantId: Scalars["ID"];
};

/** Autogenerated return type of PlanningCreate. */
export type PlanningCreatePayload = {
  __typename?: "PlanningCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Planning>;
};

/** Autogenerated return type of PlanningDestroy. */
export type PlanningDestroyPayload = {
  __typename?: "PlanningDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Planning expense item */
export type PlanningExpenseItem = {
  __typename?: "PlanningExpenseItem";
  /** Expense Item */
  expenseItem: ExpenseItem;
  id?: Maybe<Scalars["ID"]>;
};

/** PlanningExpenseItem changes object */
export type PlanningExpenseItemChanges = {
  /** Remove expense item */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Expense Item */
  expenseItemId?: InputMaybe<Scalars["ID"]>;
  /** Planning expense item ID */
  id?: InputMaybe<Scalars["ID"]>;
};

/** PlanningExpenseItem field values */
export type PlanningExpenseItemFields = {
  /** Expense Item */
  expenseItemId: Scalars["ID"];
};

/** Planning field values */
export type PlanningFields = {
  /** Activity */
  activityId: Scalars["ID"];
  /** Cost Centers */
  costCentersAttributes: Array<PlanningCostCenterFields>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Zone */
  cropFieldGroup?: InputMaybe<Scalars["String"]>;
  /** Crop stages */
  cropStageIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cycle Number */
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  /** Estimated End Date */
  endDate: Scalars["ISO8601Date"];
  /** End week number */
  endWeek?: InputMaybe<Scalars["Int"]>;
  /** Expenses */
  expenseItemsAttributes: Array<PlanningExpenseItemFields>;
  /** Locality */
  localityId: Scalars["ID"];
  /** Machinery */
  machinesAttributes: Array<PlanningMachineFields>;
  /** Positions */
  positionsAttributes: Array<PlanningPositionFields>;
  /** Start Date */
  startDate: Scalars["ISO8601Date"];
  /** Start week number */
  startWeek?: InputMaybe<Scalars["Int"]>;
  /** Planning status */
  status: PlanningStatus;
  /** Variants */
  variantsAttributes: Array<PlanningVariantFields>;
};

/** Planning machine kind norms */
export type PlanningMachine = {
  __typename?: "PlanningMachine";
  actualEfficiency?: Maybe<Scalars["Float"]>;
  actualHours: Scalars["Float"];
  /** Avg actual count */
  avgActualCount: Scalars["Float"];
  id?: Maybe<Scalars["ID"]>;
  /** Machine kind */
  machineKind: MachineKind;
  /** Employee quantity */
  quantity: Scalars["Int"];
};

/** PlanningMachine changes object */
export type PlanningMachineChanges = {
  /** Remove machinery */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Planning machine ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Machine Kind */
  machineKindId?: InputMaybe<Scalars["ID"]>;
  /** Employee quantity */
  quantity?: InputMaybe<Scalars["Int"]>;
};

/** PlanningMachine field values */
export type PlanningMachineFields = {
  /** Machine Kind */
  machineKindId: Scalars["ID"];
  /** Employee quantity */
  quantity: Scalars["Int"];
};

/** Planning position norms */
export type PlanningPosition = {
  __typename?: "PlanningPosition";
  /** Actual expenses */
  actualCost: Scalars["Float"];
  /** Actual efficiency */
  actualEfficiency?: Maybe<Scalars["Float"]>;
  /** Actual man days */
  actualManDays: Scalars["Float"];
  /** Avg actual count */
  avgActualCount: Scalars["Float"];
  id?: Maybe<Scalars["ID"]>;
  /** Man Days */
  manDays?: Maybe<Scalars["Float"]>;
  /** Position */
  position: Position;
  /** Employee quantity */
  quantity: Scalars["Int"];
  /** Position wage */
  wage: Scalars["Float"];
  wageUnit: WorkUnit;
};

/** PlanningPosition changes object */
export type PlanningPositionChanges = {
  /** Remove position */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Planning position ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Position */
  positionId?: InputMaybe<Scalars["ID"]>;
  /** Employee quantity */
  quantity?: InputMaybe<Scalars["Int"]>;
  /** Position wage */
  wage?: InputMaybe<Scalars["Float"]>;
  wageUnit?: InputMaybe<WorkUnit>;
};

/** PlanningPosition field values */
export type PlanningPositionFields = {
  /** Position */
  positionId: Scalars["ID"];
  /** Employee quantity */
  quantity: Scalars["Int"];
  /** Position wage */
  wage: Scalars["Float"];
  wageUnit: WorkUnit;
};

export enum PlanningStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** In_progress */
  InProgress = "inProgress",
  /** Open */
  Open = "open",
  /** Planned */
  Planned = "planned",
}

/** Autogenerated return type of PlanningUpdate. */
export type PlanningUpdatePayload = {
  __typename?: "PlanningUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Planning>;
};

/** Planning variants */
export type PlanningVariant = {
  __typename?: "PlanningVariant";
  /** Actual input cost */
  actualCost: Scalars["Float"];
  /** Actual consumed amount */
  consumedAmount: Scalars["Float"];
  /** Variant cost */
  cost: Scalars["Float"];
  id?: Maybe<Scalars["ID"]>;
  /** Total amount */
  totalAmount?: Maybe<Scalars["Float"]>;
  /** Variant unit */
  unit: Unit;
  /** Variant */
  variant: Variant;
};

/** PlanningVariant changes object */
export type PlanningVariantChanges = {
  /** Remove variant */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Variant cost */
  cost?: InputMaybe<Scalars["Float"]>;
  /** Planning variant ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Unit */
  unitId?: InputMaybe<Scalars["ID"]>;
  /** Variant */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** PlanningVariant field values */
export type PlanningVariantFields = {
  /** Variant cost */
  cost: Scalars["Float"];
  /** Unit */
  unitId: Scalars["ID"];
  /** Variant */
  variantId: Scalars["ID"];
};

/** Collection of Plannings */
export type Plannings = {
  __typename?: "Plannings";
  /** Paginated collection of Plannings */
  items: Array<Planning>;
  /** Total count of Plannings */
  totalCount: Scalars["Int"];
};

/** Plannings filter */
export type PlanningsFilterInput = {
  /** Activity category */
  activityCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Number of working days */
  actualWorkDays?: InputMaybe<Array<Scalars["Int"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Zones */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop stages */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cycle Number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Estimated End Date */
  endDate?: InputMaybe<DateRangeInput>;
  /** End week number */
  endWeek?: InputMaybe<Array<Scalars["Int"]>>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Start Date */
  startDate?: InputMaybe<DateRangeInput>;
  /** Start week number */
  startWeek?: InputMaybe<Array<Scalars["Int"]>>;
  /** Planning status */
  status?: InputMaybe<Array<PlanningStatus>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Plannings sorting options */
export type PlanningsSortInput = {
  /** Activity progress */
  actualProgress?: InputMaybe<SortDirection>;
  /** Number of working days */
  actualWorkDays?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Zone */
  cropFieldGroup?: InputMaybe<SortDirection>;
  /** Cycle Number */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Estimated End Date */
  endDate?: InputMaybe<SortDirection>;
  /** End week number */
  endWeek?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Start Date */
  startDate?: InputMaybe<SortDirection>;
  /** Start week number */
  startWeek?: InputMaybe<SortDirection>;
  /** Planning status */
  status?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Employee position object */
export type Position = Destroyable &
  Discardable & {
    __typename?: "Position";
    basePayment?: Maybe<ActivityPayment>;
    contractType?: Maybe<ContractType>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Position name */
    name: Scalars["String"];
    positionType: PositionType;
    /** Assigned Salary Structure */
    salaryStructure?: Maybe<SalaryStructure>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Position changes object */
export type PositionChanges = {
  /** Base daily wage */
  baseWage?: InputMaybe<Scalars["Float"]>;
  contractType?: InputMaybe<ContractType>;
  /** Position name */
  name?: InputMaybe<Scalars["String"]>;
  positionType?: InputMaybe<PositionType>;
  /** Reset all employee structures */
  resetAllStructures?: InputMaybe<Scalars["Boolean"]>;
  /** Reset all employee wages */
  resetAllWages?: InputMaybe<Scalars["Boolean"]>;
  /** Salary Structure */
  salaryStructureId?: InputMaybe<Scalars["ID"]>;
  /** Wage unit */
  wageUnit?: InputMaybe<WorkUnit>;
};

/** Autogenerated return type of PositionCreate. */
export type PositionCreatePayload = {
  __typename?: "PositionCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Position>;
};

/** Autogenerated return type of PositionDestroy. */
export type PositionDestroyPayload = {
  __typename?: "PositionDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of PositionDiscard. */
export type PositionDiscardPayload = {
  __typename?: "PositionDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Position>;
};

/** Position field values */
export type PositionFields = {
  /** Base wage */
  baseWage?: InputMaybe<Scalars["Float"]>;
  contractType?: InputMaybe<ContractType>;
  /** Position name */
  name: Scalars["String"];
  positionType: PositionType;
  /** Salary Structure */
  salaryStructureId?: InputMaybe<Scalars["ID"]>;
  /** Wage unit */
  wageUnit?: InputMaybe<WorkUnit>;
};

export enum PositionType {
  /** Labor */
  Labor = "labor",
  /** Machinery operator */
  Machinery = "machinery",
  /** Office clerical */
  Office = "office",
  /** Labor supervision */
  Supervision = "supervision",
}

/** Autogenerated return type of PositionUpdate. */
export type PositionUpdatePayload = {
  __typename?: "PositionUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Position>;
};

/** Collection of Positions */
export type Positions = {
  __typename?: "Positions";
  /** Paginated collection of Positions */
  items: Array<Position>;
  /** Total count of Positions */
  totalCount: Scalars["Int"];
};

/** Positions filter */
export type PositionsFilterInput = {
  contractType?: InputMaybe<Array<ContractType>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Position name */
  name?: InputMaybe<StringMatchInput>;
  positionType?: InputMaybe<Array<PositionType>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Positions sorting options */
export type PositionsSortInput = {
  contractType?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Position name */
  name?: InputMaybe<SortDirection>;
  positionType?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Pre Payroll Overview Report result */
export type PrePayrollOverviewRecord = {
  __typename?: "PrePayrollOverviewRecord";
  /** Absence Pay */
  absencePay: Scalars["Float"];
  /** Absent days */
  absentDays: Scalars["Float"];
  /** Attended Days */
  attendedDays: Scalars["Float"];
  /** Base Pay */
  basePay: Scalars["Float"];
  /** Business days */
  businessDays: Scalars["Int"];
  /** End date */
  endDate: Scalars["ISO8601Date"];
  /** Man days */
  manDays: Scalars["Float"];
  /** Overtime Hours Pay */
  overtimePay: Scalars["Float"];
  /** Start date */
  startDate: Scalars["ISO8601Date"];
};

/** Pre Payroll Report result */
export type PrePayrollRecord = {
  __typename?: "PrePayrollRecord";
  /** Regular Hours Pay */
  absencePay: Scalars["Float"];
  attended: Scalars["Boolean"];
  /** Progress Pay */
  basePay: Scalars["Float"];
  /** WO Cost Center */
  costCenter?: Maybe<WorkOrderCostCenter>;
  /** Employee name */
  employee: Employee;
  /** Regular hours */
  hours: Scalars["Float"];
  /** Leave Assignment */
  leaveAssignment?: Maybe<LeaveAssignment>;
  /** Leave type */
  leaveType?: Maybe<LeaveType>;
  /** Overtime hours */
  overtimeHours: Scalars["Float"];
  /** Overtime Hours Pay */
  overtimePay: Scalars["Float"];
  /** Employee Progress */
  progress?: Maybe<Scalars["Float"]>;
  /** Total Pay */
  totalPay: Scalars["Float"];
  /** Wage */
  wage?: Maybe<Scalars["Float"]>;
  wageUnit?: Maybe<WorkUnit>;
  /** Work Order */
  workOrder?: Maybe<WorkOrder>;
};

/** Collection of PrePayrollReport */
export type PrePayrollReport = {
  __typename?: "PrePayrollReport";
  /** Paginated collection of PrePayrollReport */
  items: Array<PrePayrollRecord>;
  /** Total count of PrePayrollReport */
  totalCount: Scalars["Int"];
};

/** Pre-payroll report filter input */
export type PrePayrollReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop */
  cropId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Date range */
  date?: InputMaybe<DateRangeInput>;
  /** Document ID */
  documentId?: InputMaybe<Scalars["Int"]>;
  /** Employee Group */
  employeeGroupId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Employee */
  employeeId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Leave type */
  leaveTypeId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Position */
  positionId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show cost centers */
  showCostCenters?: InputMaybe<Scalars["Boolean"]>;
  /** Tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
};

/** PrePayrollReport sorting options */
export type PrePayrollReportSortInput = {
  /** Regular Hours Pay */
  absencePay?: InputMaybe<SortDirection>;
  attended?: InputMaybe<SortDirection>;
  /** Progress Pay */
  basePay?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Regular hours */
  hours?: InputMaybe<SortDirection>;
  /** Overtime hours */
  overtimeHours?: InputMaybe<SortDirection>;
  /** Overtime Hours Pay */
  overtimePay?: InputMaybe<SortDirection>;
  /** Employee Progress */
  progress?: InputMaybe<SortDirection>;
  /** Total Pay */
  totalPay?: InputMaybe<SortDirection>;
  /** Wage */
  wage?: InputMaybe<SortDirection>;
  wageUnit?: InputMaybe<SortDirection>;
};

/** Pre Payroll Summary Report result */
export type PrePayrollSummaryRecord = {
  __typename?: "PrePayrollSummaryRecord";
  /** Absence Pay */
  absencePay: Scalars["Float"];
  /** Absent days */
  absentDays: Scalars["Float"];
  /** Attended Days */
  attendedDays: Scalars["Float"];
  /** Regular Hours & Progress Pay */
  basePay: Scalars["Float"];
  /** Employee name */
  employee: Employee;
  /** Regular hours */
  hours: Scalars["Float"];
  /** Man days */
  manDays: Scalars["Float"];
  /** Overtime hours */
  overtimeHours: Scalars["Float"];
  /** Overtime Hours Pay */
  overtimePay: Scalars["Float"];
  /** Employee Progress */
  progress?: Maybe<Scalars["Float"]>;
  /** Progress Unit */
  progressUnit?: Maybe<Unit>;
  /** 7th day Pay */
  seventhDayPay: Scalars["Float"];
  /** 7th days */
  seventhDays: Scalars["Int"];
  /** Total Pay */
  totalPay: Scalars["Float"];
};

/** Collection of PrePayrollSummaryReport */
export type PrePayrollSummaryReport = {
  __typename?: "PrePayrollSummaryReport";
  /** Paginated collection of PrePayrollSummaryReport */
  items: Array<PrePayrollSummaryRecord>;
  /** Total count of PrePayrollSummaryReport */
  totalCount: Scalars["Int"];
};

/** PrePayrollSummaryReport sorting options */
export type PrePayrollSummaryReportSortInput = {
  /** Absence Pay */
  absencePay?: InputMaybe<SortDirection>;
  /** Absent days */
  absentDays?: InputMaybe<SortDirection>;
  /** Attended Days */
  attendedDays?: InputMaybe<SortDirection>;
  /** Regular Hours & Progress Pay */
  basePay?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Regular hours */
  hours?: InputMaybe<SortDirection>;
  /** Man days */
  manDays?: InputMaybe<SortDirection>;
  /** Overtime hours */
  overtimeHours?: InputMaybe<SortDirection>;
  /** Overtime Hours Pay */
  overtimePay?: InputMaybe<SortDirection>;
  /** Employee Progress */
  progress?: InputMaybe<SortDirection>;
  /** 7th day Pay */
  seventhDayPay?: InputMaybe<SortDirection>;
  /** 7th days */
  seventhDays?: InputMaybe<SortDirection>;
  /** Total Pay */
  totalPay?: InputMaybe<SortDirection>;
};

/** Object that has default cost center */
export type Profitable = {
  /** Cost Center */
  costCenter?: Maybe<CostCenter>;
};

export enum ProfitableType {
  /** Crop Field */
  CropField = "cropField",
  /** Crop Stage */
  CropStage = "cropStage",
  /** Crop Stage Locality */
  CropStageLocality = "cropStageLocality",
  /** Infrastructure */
  Infrastructure = "infrastructure",
  /** Machine */
  Machine = "machine",
  /** Machine Locality */
  MachineLocality = "machineLocality",
  /** Warehouse */
  Warehouse = "warehouse",
}

export type Query = {
  __typename?: "Query";
  /** FinanceAccount detailed info */
  account?: Maybe<Account>;
  /** Retrieves a collection of AccountKinds */
  accountKinds?: Maybe<AccountKinds>;
  /** Retrieves a collection of Accounts */
  accounts?: Maybe<Accounts>;
  /** Retrieves a collection of Activities */
  activities?: Maybe<Activities>;
  /** AgroActivity detailed info */
  activity?: Maybe<Activity>;
  /** Retrieves a collection of ActivityCategories */
  activityCategories?: Maybe<ActivityCategories>;
  /** AgroActivityCategory detailed info */
  activityCategory?: Maybe<ActivityCategory>;
  /** Retrieves a collection of ActivityCostCenterReport */
  activityCostCenterReport?: Maybe<ActivityCostCenterReport>;
  /** Returns ActivityOverviewReport */
  activityOverviewReport: ActivityOverview;
  /** Retrieves a collection of ActivitySummaryReport */
  activitySummaryReport?: Maybe<ActivitySummaryReport>;
  /** InventoryAdjustmentType detailed info */
  adjustmentType?: Maybe<AdjustmentType>;
  /** Retrieves a collection of AdjustmentTypes */
  adjustmentTypes?: Maybe<AdjustmentTypes>;
  /** Retrieves a collection of BalanceSheetReport */
  balanceSheetReport?: Maybe<BalanceSheetReport>;
  /** Retrieves a collection of Banks */
  banks?: Maybe<Banks>;
  /** Brand detailed info */
  brand?: Maybe<Brand>;
  /** Retrieves a collection of Brands */
  brands?: Maybe<Brands>;
  /** Retrieves a collection of ChangeLogs */
  changeLogs?: Maybe<ChangeLogs>;
  /** Comment detailed info */
  comment?: Maybe<Comment>;
  /** Retrieves a collection of Comments */
  comments?: Maybe<Comments>;
  /** FinanceCostCenter detailed info */
  costCenter?: Maybe<CostCenter>;
  /** Retrieves a collection of CostCenters */
  costCenters?: Maybe<CostCenters>;
  /** Retrieves a collection of Counterparties */
  counterparties?: Maybe<Counterparties>;
  /** InventoryCounterparty detailed info */
  counterparty?: Maybe<Counterparty>;
  /** List of available countries */
  countries?: Maybe<Array<Country>>;
  /** AgroCrop detailed info */
  crop?: Maybe<Crop>;
  /** AgroCropCycle detailed info */
  cropCycle?: Maybe<CropCycle>;
  /** Retrieves a collection of CropCycles */
  cropCycles?: Maybe<CropCycles>;
  /** AgroCropField detailed info */
  cropField?: Maybe<CropField>;
  /** Retrieves a collection of CropFieldActivityReport */
  cropFieldActivityReport?: Maybe<CropFieldActivityReport>;
  /** List of crop field groups */
  cropFieldGroups?: Maybe<Array<Scalars["String"]>>;
  /** AgroCropFieldNote detailed info */
  cropFieldNote?: Maybe<CropFieldNote>;
  /** Retrieves a collection of CropFieldNotes */
  cropFieldNotes?: Maybe<CropFieldNotes>;
  /** Retrieves a collection of CropFieldSummaryReport */
  cropFieldSummaryReport?: Maybe<CropFieldSummaryReport>;
  /** List of crop field tags */
  cropFieldTags?: Maybe<Array<Scalars["String"]>>;
  /** Retrieves a collection of CropFields */
  cropFields?: Maybe<CropFields>;
  /** AgroCropStage detailed info */
  cropStage?: Maybe<CropStage>;
  /** Retrieves a collection of CropStages */
  cropStages?: Maybe<CropStages>;
  /** Retrieves a collection of CropVarieties */
  cropVarieties?: Maybe<CropVarieties>;
  /** AgroCropVariety detailed info */
  cropVariety?: Maybe<CropVariety>;
  /** Retrieves a collection of Crops */
  crops?: Maybe<Crops>;
  /** Dashboard report */
  dashboardReport: DashboardReport;
  /** AgroDisease detailed info */
  disease?: Maybe<Disease>;
  /** Retrieves a collection of Diseases */
  diseases?: Maybe<Diseases>;
  /** Employee detailed info */
  employee?: Maybe<Employee>;
  /** EmployeeGroup detailed info */
  employeeGroup?: Maybe<EmployeeGroup>;
  /** Retrieves a collection of EmployeeGroups */
  employeeGroups?: Maybe<EmployeeGroups>;
  /** Retrieves a collection of Employees */
  employees?: Maybe<Employees>;
  /** Retrieves a collection of EquipmentList */
  equipmentList?: Maybe<EquipmentList>;
  /** Fetch exchange rate */
  exchangeRate?: Maybe<Scalars["Float"]>;
  /** Retrieves a collection of ExpenseCategories */
  expenseCategories?: Maybe<ExpenseCategories>;
  /** FinanceExpenseCategory detailed info */
  expenseCategory?: Maybe<ExpenseCategory>;
  /** Retrieves a collection of ExpenseInventoryReport */
  expenseInventoryReport?: Maybe<ExpenseInventoryReport>;
  /** FinanceExpenseItem detailed info */
  expenseItem?: Maybe<ExpenseItem>;
  /** Retrieves a collection of ExpenseItems */
  expenseItems?: Maybe<ExpenseItems>;
  /** Retrieves a collection of ExpenseReport */
  expenseReport?: Maybe<ExpenseReport>;
  /** Retrieves a collection of ExpenseSalaryReport */
  expenseSalaryReport?: Maybe<ExpenseSalaryReport>;
  /** Retrieves a collection of ExpenseServiceReport */
  expenseServiceReport?: Maybe<ExpenseServiceReport>;
  /** FinanceInvoice detailed info */
  financeInvoice?: Maybe<FinanceInvoice>;
  /** Retrieves a collection of FinanceInvoices */
  financeInvoices?: Maybe<FinanceInvoices>;
  /** FinanceOrder detailed info */
  financeOrder?: Maybe<FinanceOrder>;
  /** Retrieves a collection of FinanceOrders */
  financeOrders?: Maybe<FinanceOrders>;
  /** FinancePayment detailed info */
  financePayment?: Maybe<FinancePayment>;
  /** Retrieves a collection of FinancePeriods */
  financePeriods?: Maybe<FinancePeriods>;
  /** FinanceFiscalYear detailed info */
  fiscalYear?: Maybe<FiscalYear>;
  /** Retrieves a collection of FiscalYears */
  fiscalYears?: Maybe<FiscalYears>;
  /** Retrieves a collection of GeneralLedgerReport */
  generalLedgerReport?: Maybe<GeneralLedgerReport>;
  /** Retrieves a collection of HarvestCropFieldReport */
  harvestCropFieldReport?: Maybe<HarvestCropFieldReport>;
  /** Retrieves a collection of HarvestDailyReport */
  harvestDailyReport?: Maybe<HarvestDailyReport>;
  /** AgroHarvestEstimate detailed info */
  harvestEstimate?: Maybe<HarvestEstimate>;
  /** Retrieves a collection of HarvestEstimates */
  harvestEstimates?: Maybe<HarvestEstimates>;
  /** Harvest overview report */
  harvestOverviewReport: HarvestOverview;
  /** Retrieves a collection of HarvestSummaryReport */
  harvestSummaryReport?: Maybe<HarvestSummaryReport>;
  /** HRHolidayList detailed info */
  holidayList?: Maybe<HolidayList>;
  /** Retrieves a collection of HolidayLists */
  holidayLists?: Maybe<HolidayLists>;
  /** Retrieves a collection of Holidays */
  holidays?: Maybe<Holidays>;
  /** Import detailed info */
  import?: Maybe<Import>;
  /** Retrieves a collection of ImportLogs */
  importLogs?: Maybe<ImportLogs>;
  /** Retrieves a collection of Imports */
  imports?: Maybe<Imports>;
  /** Retrieves a collection of IncomeStatementReport */
  incomeStatementReport?: Maybe<IncomeStatementReport>;
  /** Infrastructure detailed info */
  infrastructure?: Maybe<Infrastructure>;
  /** Retrieves a collection of InfrastructureKinds */
  infrastructureKinds?: Maybe<InfrastructureKinds>;
  /** Retrieves a collection of Infrastructures */
  infrastructures?: Maybe<Infrastructures>;
  /** AgroIngredient detailed info */
  ingredient?: Maybe<Ingredient>;
  /** Retrieves a collection of Ingredients */
  ingredients?: Maybe<Ingredients>;
  /** Retrieves a collection of InputCostCenterReport */
  inputCostCenterReport?: Maybe<InputCostCenterReport>;
  /** Retrieves a collection of InventoryEntryReport */
  inventoryEntryReport?: Maybe<InventoryEntryReport>;
  /** Retrieves a collection of InventoryMovementReport */
  inventoryMovementReport?: Maybe<InventoryMovementReport>;
  /** InventoryOrder detailed info */
  inventoryOrder?: Maybe<InventoryOrder>;
  /** Retrieves a collection of InventoryOrders */
  inventoryOrders?: Maybe<InventoryOrders>;
  /** AgroWorkOrder detailed info */
  inventoryRequest?: Maybe<InventoryRequest>;
  /** Retrieves a collection of InventoryRequests */
  inventoryRequests?: Maybe<InventoryRequests>;
  /** Returns Invoices */
  invoiceOverviewReport: InvoiceOverviewReportItem;
  /** Retrieves a collection of InvoiceSummaryReport */
  invoiceSummaryReport?: Maybe<InvoiceSummaryReport>;
  /** InventoryItem detailed info */
  item?: Maybe<Item>;
  /** Retrieves a collection of ItemCategories */
  itemCategories?: Maybe<ItemCategories>;
  /** InventoryItemCategory detailed info */
  itemCategory?: Maybe<ItemCategory>;
  /** Retrieves a collection of Items */
  items?: Maybe<Items>;
  /** Job status info */
  jobStatus: Scalars["JSON"];
  /** Retrieves a collection of JournalEntries */
  journalEntries?: Maybe<JournalEntries>;
  /** FinanceJournalEntry detailed info */
  journalEntry?: Maybe<JournalEntry>;
  /** Retrieves a collection of JournalReport */
  journalReport?: Maybe<JournalReport>;
  /** Retrieves a collection of LaborActivityReport */
  laborActivityReport?: Maybe<LaborActivityReport>;
  /** Retrieves a collection of LaborSummaryReport */
  laborSummaryReport?: Maybe<LaborSummaryReport>;
  /** HRLeaveAssignment detailed info */
  leaveAssignment?: Maybe<LeaveAssignment>;
  /** Retrieves a collection of LeaveAssignments */
  leaveAssignments?: Maybe<LeaveAssignments>;
  /** LeaveType detailed info */
  leaveType?: Maybe<LeaveType>;
  /** Retrieves a collection of LeaveTypes */
  leaveTypes?: Maybe<LeaveTypes>;
  /** Retrieves a collection of LedgerReport */
  ledgerReport?: Maybe<LedgerReport>;
  /** Retrieves a collection of Localities */
  localities?: Maybe<Localities>;
  /** Locality detailed info */
  locality?: Maybe<Locality>;
  /** Machine detailed info */
  machine?: Maybe<Machine>;
  /** Retrieves a collection of MachineActivityReport */
  machineActivityReport?: Maybe<MachineActivityReport>;
  /** MachineKind detailed info */
  machineKind?: Maybe<MachineKind>;
  /** Retrieves a collection of MachineKinds */
  machineKinds?: Maybe<MachineKinds>;
  /** Retrieves a collection of MachineSummaryReport */
  machineSummaryReport?: Maybe<MachineSummaryReport>;
  /** Retrieves a collection of Machines */
  machines?: Maybe<Machines>;
  /** Current user's detailed info */
  me?: Maybe<CurrentUser>;
  /** AgroMetric detailed info */
  metric?: Maybe<Metric>;
  /** Retrieves a collection of Metrics */
  metrics?: Maybe<Metrics>;
  /** OnboardingSeedsActivities */
  onboardingSeedsActivities: Array<ActivitySeed>;
  /** Retrieves a collection of OnboardingSeedsItems */
  onboardingSeedsItems?: Maybe<OnboardingSeedsItems>;
  /** Salary Structures Catalog */
  onboardingSeedsSalaryStructures: Array<SalaryStructure>;
  /** Onboarding status */
  onboardingStatus: OnboardingStatus;
  /** InventoryPaymentMethod detailed info */
  paymentMethod?: Maybe<PaymentMethod>;
  /** Retrieves a collection of PaymentMethods */
  paymentMethods?: Maybe<PaymentMethods>;
  /** Retrieves a collection of PayrollEntries */
  payrollEntries?: Maybe<PayrollEntries>;
  /** HRPayrollEntry detailed info */
  payrollEntry?: Maybe<PayrollEntry>;
  /** AgroPlanning detailed info */
  planning?: Maybe<Planning>;
  /** Retrieves a collection of Plannings */
  plannings?: Maybe<Plannings>;
  /** Position detailed info */
  position?: Maybe<Position>;
  /** Retrieves a collection of Positions */
  positions?: Maybe<Positions>;
  /** Returns PrePayrollOverviewReport */
  prePayrollOverviewReport: PrePayrollOverviewRecord;
  /** Retrieves a collection of PrePayrollReport */
  prePayrollReport?: Maybe<PrePayrollReport>;
  /** Retrieves a collection of PrePayrollSummaryReport */
  prePayrollSummaryReport?: Maybe<PrePayrollSummaryReport>;
  /** InventoryRecipe detailed info */
  recipe?: Maybe<Recipe>;
  /** Retrieves a collection of Recipes */
  recipes?: Maybe<Recipes>;
  /** Role detailed info */
  role?: Maybe<Role>;
  /** Retrieves a collection of Roles */
  roles?: Maybe<Roles>;
  /** HRSalaryComponent detailed info */
  salaryComponent?: Maybe<SalaryComponent>;
  /** Retrieves a collection of SalaryComponents */
  salaryComponents?: Maybe<SalaryComponents>;
  /** HRSalaryStructure detailed info */
  salaryStructure?: Maybe<SalaryStructure>;
  /** Retrieves a collection of SalaryStructures */
  salaryStructures?: Maybe<SalaryStructures>;
  /** Retrieves a collection of StockReport */
  stockReport?: Maybe<StockReport>;
  /** InventoryTransfer detailed info */
  stockTransfer?: Maybe<Transfer>;
  /** Retrieves a collection of Transfers */
  stockTransfers?: Maybe<Transfers>;
  /** Retrieves a collection of Tags */
  tags?: Maybe<Tags>;
  /** FinanceTaxComponent detailed info */
  taxComponent?: Maybe<TaxComponent>;
  /** Retrieves a collection of TaxComponents */
  taxComponents?: Maybe<TaxComponents>;
  /** FinanceTaxPlan detailed info */
  taxPlan?: Maybe<TaxPlan>;
  /** Retrieves a collection of TaxPlans */
  taxPlans?: Maybe<TaxPlans>;
  /** Tenant detailed info */
  tenant?: Maybe<Tenant>;
  /** Retrieves a collection of Tenants */
  tenants?: Maybe<Tenants>;
  /** FinanceToken detailed info */
  token?: Maybe<Token>;
  /** Retrieves a collection of TokenPaidReport */
  tokenPaidReport?: Maybe<TokenPaidReport>;
  /** Retrieves a collection of TokenRedeemedReport */
  tokenRedeemedReport?: Maybe<TokenRedeemedReport>;
  /** Retrieves a collection of TokenSummaryReport */
  tokenSummaryReport?: Maybe<TokenSummaryReport>;
  /** Retrieves a collection of Tokens */
  tokens?: Maybe<Tokens>;
  /** Retrieves a collection of TrialBalanceReport */
  trialBalanceReport?: Maybe<TrialBalanceReport>;
  /** Unit detailed info */
  unit?: Maybe<Unit>;
  /** Retrieves a collection of Units */
  units?: Maybe<Units>;
  /** User detailed info */
  user?: Maybe<User>;
  /** Retrieves a collection of Users */
  users?: Maybe<Users>;
  /** InventoryVariant detailed info */
  variant?: Maybe<Variant>;
  /** Retrieves a collection of Variants */
  variants?: Maybe<Variants>;
  /** InventoryWarehouse detailed info */
  warehouse?: Maybe<Warehouse>;
  /** Retrieves a collection of Warehouses */
  warehouses?: Maybe<Warehouses>;
  /** Retrieves a collection of WaterSources */
  waterSources?: Maybe<WaterSources>;
  /** AgroWeatherMeasurement detailed info */
  weatherMeasurement?: Maybe<WeatherMeasurement>;
  /** Retrieves a collection of WeatherMeasurements */
  weatherMeasurements?: Maybe<WeatherMeasurements>;
  weatherMeasurementsAggregated?: Maybe<WeatherMeasurementsAggregatedResult>;
  /** Returns WeatherOverview */
  weatherOverview: WeatherOverview;
  /** Retrieves a collection of WeekDays */
  weekDays?: Maybe<WeekDays>;
  /** AgroWorkOrder detailed info */
  workOrder?: Maybe<WorkOrder>;
  /** List of work order categories */
  workOrderCategories?: Maybe<Array<WorkOrderCategoryTypes>>;
  /** Retrieves a collection of WorkOrderDailyReport */
  workOrderDailyReport?: Maybe<WorkOrderDailyReport>;
  /** Retrieves a collection of WorkOrders */
  workOrders?: Maybe<WorkOrders>;
};

export type QueryAccountArgs = {
  id: Scalars["ID"];
};

export type QueryAccountKindsArgs = {
  filter?: InputMaybe<AccountKindsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AccountKindsSortInput>;
};

export type QueryAccountsArgs = {
  filter?: InputMaybe<AccountsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AccountsSortInput>;
};

export type QueryActivitiesArgs = {
  filter?: InputMaybe<ActivitiesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ActivitiesSortInput>;
};

export type QueryActivityArgs = {
  id: Scalars["ID"];
};

export type QueryActivityCategoriesArgs = {
  filter?: InputMaybe<ActivityCategoriesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ActivityCategoriesSortInput>;
};

export type QueryActivityCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryActivityCostCenterReportArgs = {
  filter?: InputMaybe<ActivityReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ActivityCostCenterReportSortInput>;
};

export type QueryActivityOverviewReportArgs = {
  filter?: InputMaybe<ActivityReportFilterInput>;
};

export type QueryActivitySummaryReportArgs = {
  filter?: InputMaybe<ActivityReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ActivitySummaryReportSortInput>;
};

export type QueryAdjustmentTypeArgs = {
  id: Scalars["ID"];
};

export type QueryAdjustmentTypesArgs = {
  filter?: InputMaybe<AdjustmentTypesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<AdjustmentTypesSortInput>;
};

export type QueryBalanceSheetReportArgs = {
  filter?: InputMaybe<BalanceSheetReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BalanceSheetReportSortInput>;
};

export type QueryBanksArgs = {
  filter?: InputMaybe<BanksFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BanksSortInput>;
};

export type QueryBrandArgs = {
  id: Scalars["ID"];
};

export type QueryBrandsArgs = {
  filter?: InputMaybe<BrandsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<BrandsSortInput>;
};

export type QueryChangeLogsArgs = {
  filter?: InputMaybe<ChangeLogsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ChangeLogsSortInput>;
};

export type QueryCommentArgs = {
  id: Scalars["ID"];
};

export type QueryCommentsArgs = {
  filter?: InputMaybe<CommentsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CommentsSortInput>;
};

export type QueryCostCenterArgs = {
  id: Scalars["ID"];
};

export type QueryCostCentersArgs = {
  filter?: InputMaybe<CostCentersFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CostCentersSortInput>;
};

export type QueryCounterpartiesArgs = {
  filter?: InputMaybe<CounterpartiesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CounterpartiesSortInput>;
};

export type QueryCounterpartyArgs = {
  id: Scalars["ID"];
};

export type QueryCropArgs = {
  id: Scalars["ID"];
};

export type QueryCropCycleArgs = {
  id: Scalars["ID"];
};

export type QueryCropCyclesArgs = {
  filter?: InputMaybe<CropCyclesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CropCyclesSortInput>;
};

export type QueryCropFieldArgs = {
  id: Scalars["ID"];
};

export type QueryCropFieldActivityReportArgs = {
  filter?: InputMaybe<CropFieldReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CropFieldActivityReportSortInput>;
};

export type QueryCropFieldGroupsArgs = {
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryCropFieldNoteArgs = {
  id: Scalars["ID"];
};

export type QueryCropFieldNotesArgs = {
  filter?: InputMaybe<CropFieldNotesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CropFieldNotesSortInput>;
};

export type QueryCropFieldSummaryReportArgs = {
  filter?: InputMaybe<CropFieldReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CropFieldSummaryReportSortInput>;
};

export type QueryCropFieldTagsArgs = {
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
};

export type QueryCropFieldsArgs = {
  filter?: InputMaybe<CropFieldsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CropFieldsSortInput>;
};

export type QueryCropStageArgs = {
  id: Scalars["ID"];
};

export type QueryCropStagesArgs = {
  filter?: InputMaybe<CropStagesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CropStagesSortInput>;
};

export type QueryCropVarietiesArgs = {
  filter?: InputMaybe<CropVarietiesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CropVarietiesSortInput>;
};

export type QueryCropVarietyArgs = {
  id: Scalars["ID"];
};

export type QueryCropsArgs = {
  filter?: InputMaybe<CropsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<CropsSortInput>;
};

export type QueryDashboardReportArgs = {
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  localityId?: InputMaybe<Scalars["ID"]>;
};

export type QueryDiseaseArgs = {
  id: Scalars["ID"];
};

export type QueryDiseasesArgs = {
  filter?: InputMaybe<DiseasesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<DiseasesSortInput>;
};

export type QueryEmployeeArgs = {
  id: Scalars["ID"];
};

export type QueryEmployeeGroupArgs = {
  id: Scalars["ID"];
};

export type QueryEmployeeGroupsArgs = {
  filter?: InputMaybe<EmployeeGroupsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<EmployeeGroupsSortInput>;
};

export type QueryEmployeesArgs = {
  filter?: InputMaybe<EmployeesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<EmployeesSortInput>;
};

export type QueryEquipmentListArgs = {
  filter?: InputMaybe<EquipmentListFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<EquipmentListSortInput>;
};

export type QueryExchangeRateArgs = {
  date?: InputMaybe<Scalars["ISO8601Date"]>;
  from: Scalars["String"];
  to: Scalars["String"];
};

export type QueryExpenseCategoriesArgs = {
  filter?: InputMaybe<ExpenseCategoriesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExpenseCategoriesSortInput>;
};

export type QueryExpenseCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryExpenseInventoryReportArgs = {
  filter?: InputMaybe<ExpenseInventoryReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExpenseInventoryReportSortInput>;
};

export type QueryExpenseItemArgs = {
  id: Scalars["ID"];
};

export type QueryExpenseItemsArgs = {
  filter?: InputMaybe<ExpenseItemsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExpenseItemsSortInput>;
};

export type QueryExpenseReportArgs = {
  filter?: InputMaybe<ExpenseReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExpenseReportSortInput>;
};

export type QueryExpenseSalaryReportArgs = {
  filter?: InputMaybe<ExpenseSalaryReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExpenseSalaryReportSortInput>;
};

export type QueryExpenseServiceReportArgs = {
  filter?: InputMaybe<ExpenseServiceReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ExpenseServiceReportSortInput>;
};

export type QueryFinanceInvoiceArgs = {
  id: Scalars["ID"];
};

export type QueryFinanceInvoicesArgs = {
  filter?: InputMaybe<FinanceInvoicesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<FinanceInvoicesSortInput>;
};

export type QueryFinanceOrderArgs = {
  id: Scalars["ID"];
};

export type QueryFinanceOrdersArgs = {
  filter?: InputMaybe<FinanceOrdersFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<FinanceOrdersSortInput>;
};

export type QueryFinancePaymentArgs = {
  id: Scalars["ID"];
};

export type QueryFinancePeriodsArgs = {
  filter?: InputMaybe<FinancePeriodsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<FinancePeriodsSortInput>;
};

export type QueryFiscalYearArgs = {
  id: Scalars["ID"];
};

export type QueryFiscalYearsArgs = {
  filter?: InputMaybe<FiscalYearsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<FiscalYearsSortInput>;
};

export type QueryGeneralLedgerReportArgs = {
  filter?: InputMaybe<GeneralLedgerReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<GeneralLedgerReportSortInput>;
};

export type QueryHarvestCropFieldReportArgs = {
  filter?: InputMaybe<HarvestCropFieldReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<HarvestCropFieldReportSortInput>;
};

export type QueryHarvestDailyReportArgs = {
  filter?: InputMaybe<HarvestDailyReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<HarvestDailyReportSortInput>;
};

export type QueryHarvestEstimateArgs = {
  id: Scalars["ID"];
};

export type QueryHarvestEstimatesArgs = {
  filter?: InputMaybe<HarvestEstimatesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<HarvestEstimatesSortInput>;
};

export type QueryHarvestOverviewReportArgs = {
  filter?: InputMaybe<HarvestReportFilterInput>;
};

export type QueryHarvestSummaryReportArgs = {
  filter?: InputMaybe<HarvestReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<HarvestSummaryReportSortInput>;
};

export type QueryHolidayListArgs = {
  id: Scalars["ID"];
};

export type QueryHolidayListsArgs = {
  filter?: InputMaybe<HolidayListsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<HolidayListsSortInput>;
};

export type QueryHolidaysArgs = {
  filter?: InputMaybe<HolidaysFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<HolidaysSortInput>;
};

export type QueryImportArgs = {
  id: Scalars["ID"];
};

export type QueryImportLogsArgs = {
  filter?: InputMaybe<ImportLogsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ImportLogsSortInput>;
};

export type QueryImportsArgs = {
  filter?: InputMaybe<ImportsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ImportsSortInput>;
};

export type QueryIncomeStatementReportArgs = {
  filter?: InputMaybe<IncomeStatementReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<IncomeStatementReportSortInput>;
};

export type QueryInfrastructureArgs = {
  id: Scalars["ID"];
};

export type QueryInfrastructureKindsArgs = {
  filter?: InputMaybe<InfrastructureKindsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<InfrastructureKindsSortInput>;
};

export type QueryInfrastructuresArgs = {
  filter?: InputMaybe<InfrastructuresFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<InfrastructuresSortInput>;
};

export type QueryIngredientArgs = {
  id: Scalars["ID"];
};

export type QueryIngredientsArgs = {
  filter?: InputMaybe<IngredientsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<IngredientsSortInput>;
};

export type QueryInputCostCenterReportArgs = {
  filter?: InputMaybe<InputCostCenterReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<InputCostCenterReportSortInput>;
};

export type QueryInventoryEntryReportArgs = {
  filter?: InputMaybe<InventoryEntryReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<InventoryEntryReportSortInput>;
};

export type QueryInventoryMovementReportArgs = {
  filter?: InputMaybe<InventoryMovementReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<InventoryMovementReportSortInput>;
};

export type QueryInventoryOrderArgs = {
  id: Scalars["ID"];
};

export type QueryInventoryOrdersArgs = {
  filter?: InputMaybe<InventoryOrdersFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<InventoryOrdersSortInput>;
};

export type QueryInventoryRequestArgs = {
  id: Scalars["ID"];
};

export type QueryInventoryRequestsArgs = {
  filter?: InputMaybe<InventoryRequestsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<InventoryRequestsSortInput>;
};

export type QueryInvoiceOverviewReportArgs = {
  filter?: InputMaybe<InvoiceReportFilterInput>;
};

export type QueryInvoiceSummaryReportArgs = {
  filter?: InputMaybe<InvoiceReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<InvoiceSummaryReportSortInput>;
};

export type QueryItemArgs = {
  id: Scalars["ID"];
};

export type QueryItemCategoriesArgs = {
  filter?: InputMaybe<ItemCategoriesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ItemCategoriesSortInput>;
};

export type QueryItemCategoryArgs = {
  id: Scalars["ID"];
};

export type QueryItemsArgs = {
  filter?: InputMaybe<ItemsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<ItemsSortInput>;
};

export type QueryJobStatusArgs = {
  jobId: Scalars["ID"];
};

export type QueryJournalEntriesArgs = {
  filter?: InputMaybe<JournalEntriesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<JournalEntriesSortInput>;
};

export type QueryJournalEntryArgs = {
  id: Scalars["ID"];
};

export type QueryJournalReportArgs = {
  filter?: InputMaybe<JournalReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<JournalReportSortInput>;
};

export type QueryLaborActivityReportArgs = {
  filter?: InputMaybe<LaborReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<LaborActivityReportSortInput>;
};

export type QueryLaborSummaryReportArgs = {
  filter?: InputMaybe<LaborReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<LaborSummaryReportSortInput>;
};

export type QueryLeaveAssignmentArgs = {
  id: Scalars["ID"];
};

export type QueryLeaveAssignmentsArgs = {
  filter?: InputMaybe<LeaveAssignmentsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<LeaveAssignmentsSortInput>;
};

export type QueryLeaveTypeArgs = {
  id: Scalars["ID"];
};

export type QueryLeaveTypesArgs = {
  filter?: InputMaybe<LeaveTypesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<LeaveTypesSortInput>;
};

export type QueryLedgerReportArgs = {
  filter?: InputMaybe<LedgerReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<LedgerReportSortInput>;
};

export type QueryLocalitiesArgs = {
  filter?: InputMaybe<LocalitiesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<LocalitiesSortInput>;
};

export type QueryLocalityArgs = {
  id: Scalars["ID"];
};

export type QueryMachineArgs = {
  id: Scalars["ID"];
};

export type QueryMachineActivityReportArgs = {
  filter?: InputMaybe<MachineReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<MachineActivityReportSortInput>;
};

export type QueryMachineKindArgs = {
  id: Scalars["ID"];
};

export type QueryMachineKindsArgs = {
  filter?: InputMaybe<MachineKindsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<MachineKindsSortInput>;
};

export type QueryMachineSummaryReportArgs = {
  filter?: InputMaybe<MachineReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<MachineSummaryReportSortInput>;
};

export type QueryMachinesArgs = {
  filter?: InputMaybe<MachinesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<MachinesSortInput>;
};

export type QueryMetricArgs = {
  id: Scalars["ID"];
};

export type QueryMetricsArgs = {
  filter?: InputMaybe<MetricsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<MetricsSortInput>;
};

export type QueryOnboardingSeedsItemsArgs = {
  filter?: InputMaybe<OnboardingSeedsItemsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OnboardingSeedsItemsSortInput>;
};

export type QueryPaymentMethodArgs = {
  id: Scalars["ID"];
};

export type QueryPaymentMethodsArgs = {
  filter?: InputMaybe<PaymentMethodsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PaymentMethodsSortInput>;
};

export type QueryPayrollEntriesArgs = {
  filter?: InputMaybe<PayrollEntriesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PayrollEntriesSortInput>;
};

export type QueryPayrollEntryArgs = {
  id: Scalars["ID"];
};

export type QueryPlanningArgs = {
  id: Scalars["ID"];
};

export type QueryPlanningsArgs = {
  filter?: InputMaybe<PlanningsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PlanningsSortInput>;
};

export type QueryPositionArgs = {
  id: Scalars["ID"];
};

export type QueryPositionsArgs = {
  filter?: InputMaybe<PositionsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PositionsSortInput>;
};

export type QueryPrePayrollOverviewReportArgs = {
  filter?: InputMaybe<PrePayrollReportFilterInput>;
};

export type QueryPrePayrollReportArgs = {
  filter?: InputMaybe<PrePayrollReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PrePayrollReportSortInput>;
};

export type QueryPrePayrollSummaryReportArgs = {
  filter?: InputMaybe<PrePayrollReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<PrePayrollSummaryReportSortInput>;
};

export type QueryRecipeArgs = {
  id: Scalars["ID"];
};

export type QueryRecipesArgs = {
  filter?: InputMaybe<RecipesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<RecipesSortInput>;
};

export type QueryRoleArgs = {
  id: Scalars["ID"];
};

export type QueryRolesArgs = {
  filter?: InputMaybe<RolesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<RolesSortInput>;
};

export type QuerySalaryComponentArgs = {
  id: Scalars["ID"];
};

export type QuerySalaryComponentsArgs = {
  filter?: InputMaybe<SalaryComponentsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SalaryComponentsSortInput>;
};

export type QuerySalaryStructureArgs = {
  id: Scalars["ID"];
};

export type QuerySalaryStructuresArgs = {
  filter?: InputMaybe<SalaryStructuresFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<SalaryStructuresSortInput>;
};

export type QueryStockReportArgs = {
  filter?: InputMaybe<StockReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<StockReportSortInput>;
};

export type QueryStockTransferArgs = {
  id: Scalars["ID"];
};

export type QueryStockTransfersArgs = {
  filter?: InputMaybe<TransfersFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TransfersSortInput>;
};

export type QueryTagsArgs = {
  filter?: InputMaybe<TagsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TagsSortInput>;
};

export type QueryTaxComponentArgs = {
  id: Scalars["ID"];
};

export type QueryTaxComponentsArgs = {
  filter?: InputMaybe<TaxComponentsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TaxComponentsSortInput>;
};

export type QueryTaxPlanArgs = {
  id: Scalars["ID"];
};

export type QueryTaxPlansArgs = {
  filter?: InputMaybe<TaxPlansFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TaxPlansSortInput>;
};

export type QueryTenantArgs = {
  id: Scalars["ID"];
};

export type QueryTenantsArgs = {
  filter?: InputMaybe<TenantsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TenantsSortInput>;
};

export type QueryTokenArgs = {
  id: Scalars["ID"];
};

export type QueryTokenPaidReportArgs = {
  filter?: InputMaybe<TokenPaidReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TokenPaidReportSortInput>;
};

export type QueryTokenRedeemedReportArgs = {
  filter?: InputMaybe<TokenRedeemedReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TokenRedeemedReportSortInput>;
};

export type QueryTokenSummaryReportArgs = {
  filter?: InputMaybe<TokenSummaryReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TokenSummaryReportSortInput>;
};

export type QueryTokensArgs = {
  filter?: InputMaybe<TokensFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TokensSortInput>;
};

export type QueryTrialBalanceReportArgs = {
  filter?: InputMaybe<TrialBalanceReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<TrialBalanceReportSortInput>;
};

export type QueryUnitArgs = {
  id: Scalars["ID"];
};

export type QueryUnitsArgs = {
  filter?: InputMaybe<UnitsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<UnitsSortInput>;
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryUsersArgs = {
  filter?: InputMaybe<UsersFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<UsersSortInput>;
};

export type QueryVariantArgs = {
  id: Scalars["ID"];
};

export type QueryVariantsArgs = {
  filter?: InputMaybe<VariantsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<VariantsSortInput>;
};

export type QueryWarehouseArgs = {
  id: Scalars["ID"];
};

export type QueryWarehousesArgs = {
  filter?: InputMaybe<WarehousesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<WarehousesSortInput>;
};

export type QueryWaterSourcesArgs = {
  filter?: InputMaybe<WaterSourcesFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<WaterSourcesSortInput>;
};

export type QueryWeatherMeasurementArgs = {
  id: Scalars["ID"];
};

export type QueryWeatherMeasurementsArgs = {
  filter?: InputMaybe<WeatherMeasurementsFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<WeatherMeasurementsSortInput>;
};

export type QueryWeatherMeasurementsAggregatedArgs = {
  filter?: InputMaybe<WeatherMeasurementsAggregatedFilterInput>;
};

export type QueryWeatherOverviewArgs = {
  filter?: InputMaybe<WeatherMeasurementsFilterInput>;
};

export type QueryWeekDaysArgs = {
  filter?: InputMaybe<WeekDaysFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<WeekDaysSortInput>;
};

export type QueryWorkOrderArgs = {
  id: Scalars["ID"];
};

export type QueryWorkOrderDailyReportArgs = {
  filter?: InputMaybe<WorkOrderDailyReportFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<WorkOrderDailyReportSortInput>;
};

export type QueryWorkOrdersArgs = {
  filter?: InputMaybe<WorkOrdersFilterInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<WorkOrdersSortInput>;
};

/** Create/Read/Delete permission object */
export type RwdPermissions = {
  __typename?: "RWDPermissions";
  delete: Scalars["Boolean"];
  read: Scalars["Boolean"];
  write: Scalars["Boolean"];
};

/** Create/Read/Delete permission object */
export type RwdPermissionsInput = {
  delete?: InputMaybe<Scalars["Boolean"]>;
  read?: InputMaybe<Scalars["Boolean"]>;
  write?: InputMaybe<Scalars["Boolean"]>;
};

/** Product Recipe */
export type Recipe = Discardable & {
  __typename?: "Recipe";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Description */
  description?: Maybe<Scalars["String"]>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Recipe name */
  name: Scalars["String"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Recipe variants */
  variants: Array<RecipeVariant>;
};

/** Recipe changes object */
export type RecipeChanges = {
  /** Description */
  description?: InputMaybe<Scalars["String"]>;
  /** Recipe name */
  name?: InputMaybe<Scalars["String"]>;
  /** Recipe variants */
  variantsAttributes?: InputMaybe<Array<RecipeVariantChanges>>;
};

/** Autogenerated return type of RecipeCreate. */
export type RecipeCreatePayload = {
  __typename?: "RecipeCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Recipe>;
};

/** Autogenerated return type of RecipeDestroy. */
export type RecipeDestroyPayload = {
  __typename?: "RecipeDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of RecipeDiscard. */
export type RecipeDiscardPayload = {
  __typename?: "RecipeDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Recipe>;
};

/** Recipe field values */
export type RecipeFields = {
  /** Description */
  description?: InputMaybe<Scalars["String"]>;
  /** Recipe name */
  name: Scalars["String"];
  /** Recipe variants */
  variantsAttributes: Array<RecipeVariantFields>;
};

/** Autogenerated return type of RecipeUpdate. */
export type RecipeUpdatePayload = {
  __typename?: "RecipeUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Recipe>;
};

/** Product Recipe Variant */
export type RecipeVariant = {
  __typename?: "RecipeVariant";
  /** Application unit */
  applicationUnit?: Maybe<Unit>;
  id: Scalars["ID"];
  /** Application rate */
  rate: Scalars["Float"];
  /** U/M */
  unit: Unit;
  /** Variant */
  variant: Variant;
};

/** RecipeVariant changes object */
export type RecipeVariantChanges = {
  /** Remove recipe variant */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Application unit */
  applicationUnitId?: InputMaybe<Scalars["ID"]>;
  /** Recipe Variant ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Application rate */
  rate?: InputMaybe<Scalars["Float"]>;
  /** Unit ID */
  unitId?: InputMaybe<Scalars["ID"]>;
  /** Variant ID */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** RecipeVariant field values */
export type RecipeVariantFields = {
  /** Application unit */
  applicationUnitId?: InputMaybe<Scalars["ID"]>;
  /** Application rate */
  rate: Scalars["Float"];
  /** Unit ID */
  unitId: Scalars["ID"];
  /** Variant ID */
  variantId: Scalars["ID"];
};

/** Collection of Recipes */
export type Recipes = {
  __typename?: "Recipes";
  /** Paginated collection of Recipes */
  items: Array<Recipe>;
  /** Total count of Recipes */
  totalCount: Scalars["Int"];
};

/** Recipes filter */
export type RecipesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Description */
  description?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Recipe name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Recipes sorting options */
export type RecipesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Description */
  description?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Recipe name */
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Report permissions */
export type ReportInputPermissions = {
  activity?: InputMaybe<Scalars["Boolean"]>;
  balanceSheet?: InputMaybe<Scalars["Boolean"]>;
  cropField?: InputMaybe<Scalars["Boolean"]>;
  expense?: InputMaybe<Scalars["Boolean"]>;
  harvest?: InputMaybe<Scalars["Boolean"]>;
  incomeStatement?: InputMaybe<Scalars["Boolean"]>;
  inputCostCenter?: InputMaybe<Scalars["Boolean"]>;
  inventoryMovement?: InputMaybe<Scalars["Boolean"]>;
  journal?: InputMaybe<Scalars["Boolean"]>;
  labor?: InputMaybe<Scalars["Boolean"]>;
  ledger?: InputMaybe<Scalars["Boolean"]>;
  machine?: InputMaybe<Scalars["Boolean"]>;
  payroll?: InputMaybe<Scalars["Boolean"]>;
  stock?: InputMaybe<Scalars["Boolean"]>;
  token?: InputMaybe<Scalars["Boolean"]>;
  trialBalance?: InputMaybe<Scalars["Boolean"]>;
  workOrder?: InputMaybe<Scalars["Boolean"]>;
};

/** Report permissions options */
export type ReportPermissions = {
  __typename?: "ReportPermissions";
  activity: Scalars["Boolean"];
  balanceSheet: Scalars["Boolean"];
  cropField: Scalars["Boolean"];
  expense: Scalars["Boolean"];
  harvest: Scalars["Boolean"];
  incomeStatement: Scalars["Boolean"];
  inputCostCenter: Scalars["Boolean"];
  inventoryMovement: Scalars["Boolean"];
  journal: Scalars["Boolean"];
  labor: Scalars["Boolean"];
  ledger: Scalars["Boolean"];
  machine: Scalars["Boolean"];
  payroll: Scalars["Boolean"];
  stock: Scalars["Boolean"];
  token: Scalars["Boolean"];
  trialBalance: Scalars["Boolean"];
  workOrder: Scalars["Boolean"];
};

/** Autogenerated return type of ResendInviteMutation. */
export type ResendInviteMutationPayload = {
  __typename?: "ResendInviteMutationPayload";
  errors: Array<UserError>;
  result?: Maybe<User>;
};

/** Autogenerated return type of ResetPasswordMutation. */
export type ResetPasswordMutationPayload = {
  __typename?: "ResetPasswordMutationPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["String"]>;
};

/** Role object */
export type Role = Discardable & {
  __typename?: "Role";
  canDiscard: AuthorizationResult;
  canUpdate: AuthorizationResult;
  canUpdatePermissions: AuthorizationResult;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Role name */
  name: Scalars["String"];
  /** Role permissions */
  permissions: Permissions;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Role changes object */
export type RoleChanges = {
  /** Role name */
  name?: InputMaybe<Scalars["String"]>;
  /** Role permissions */
  permissions?: InputMaybe<PermissionsInput>;
};

/** Autogenerated return type of RoleCreate. */
export type RoleCreatePayload = {
  __typename?: "RoleCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Role>;
};

/** Autogenerated return type of RoleDiscard. */
export type RoleDiscardPayload = {
  __typename?: "RoleDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Role>;
};

/** Role field values */
export type RoleFields = {
  /** Role name */
  name: Scalars["String"];
  /** Role permissions */
  permissions: PermissionsInput;
};

/** Autogenerated return type of RoleUnlock. */
export type RoleUnlockPayload = {
  __typename?: "RoleUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<Role>;
};

/** Autogenerated return type of RoleUpdate. */
export type RoleUpdatePayload = {
  __typename?: "RoleUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Role>;
};

/** Collection of Roles */
export type Roles = {
  __typename?: "Roles";
  /** Paginated collection of Roles */
  items: Array<Role>;
  /** Total count of Roles */
  totalCount: Scalars["Int"];
};

/** Roles filter */
export type RolesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Role name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Roles sorting options */
export type RolesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Role name */
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Salary Component object */
export type SalaryComponent = Destroyable &
  Discardable &
  Timestamps & {
    __typename?: "SalaryComponent";
    abbr: Scalars["String"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Component description */
    description?: Maybe<Scalars["String"]>;
    /** Component description in En locale */
    descriptionEn?: Maybe<Scalars["String"]>;
    /** Component description in Es locale */
    descriptionEs?: Maybe<Scalars["String"]>;
    /** Component description in Pt locale */
    descriptionPt?: Maybe<Scalars["String"]>;
    /** Component description in Ru locale */
    descriptionRu?: Maybe<Scalars["String"]>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    formula?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    money: Scalars["Boolean"];
    /** Component name */
    name: Scalars["String"];
    /** Component name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Component name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Component name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Component name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Salary credit account */
    salaryCreditAccount?: Maybe<Account>;
    /** Salary debit account */
    salaryDebitAccount?: Maybe<Account>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    useInCost: Scalars["Boolean"];
  };

/** SalaryComponent changes object */
export type SalaryComponentChanges = {
  abbr?: InputMaybe<Scalars["String"]>;
  /** Component description */
  description?: InputMaybe<Scalars["String"]>;
  /** Component description in En locale */
  descriptionEn?: InputMaybe<Scalars["String"]>;
  /** Component description in Es locale */
  descriptionEs?: InputMaybe<Scalars["String"]>;
  /** Component description in Pt locale */
  descriptionPt?: InputMaybe<Scalars["String"]>;
  /** Component description in Ru locale */
  descriptionRu?: InputMaybe<Scalars["String"]>;
  formula?: InputMaybe<Scalars["String"]>;
  money?: InputMaybe<Scalars["Boolean"]>;
  /** Component name */
  name?: InputMaybe<Scalars["String"]>;
  /** Component name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Component name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Component name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Component name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Credit Account */
  salaryCreditAccountId?: InputMaybe<Scalars["ID"]>;
  /** Debit Account */
  salaryDebitAccountId?: InputMaybe<Scalars["ID"]>;
  useInCost?: InputMaybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of SalaryComponentCreate. */
export type SalaryComponentCreatePayload = {
  __typename?: "SalaryComponentCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<SalaryComponent>;
};

/** Autogenerated return type of SalaryComponentDestroy. */
export type SalaryComponentDestroyPayload = {
  __typename?: "SalaryComponentDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of SalaryComponentDiscard. */
export type SalaryComponentDiscardPayload = {
  __typename?: "SalaryComponentDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<SalaryComponent>;
};

/** SalaryComponent field values */
export type SalaryComponentFields = {
  abbr: Scalars["String"];
  /** Component description */
  description?: InputMaybe<Scalars["String"]>;
  /** Component description in En locale */
  descriptionEn?: InputMaybe<Scalars["String"]>;
  /** Component description in Es locale */
  descriptionEs?: InputMaybe<Scalars["String"]>;
  /** Component description in Pt locale */
  descriptionPt?: InputMaybe<Scalars["String"]>;
  /** Component description in Ru locale */
  descriptionRu?: InputMaybe<Scalars["String"]>;
  formula?: InputMaybe<Scalars["String"]>;
  money: Scalars["Boolean"];
  /** Component name */
  name: Scalars["String"];
  /** Component name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Component name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Component name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Component name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Credit Account */
  salaryCreditAccountId?: InputMaybe<Scalars["ID"]>;
  /** Debit Account */
  salaryDebitAccountId?: InputMaybe<Scalars["ID"]>;
  useInCost: Scalars["Boolean"];
};

/** Autogenerated return type of SalaryComponentUpdate. */
export type SalaryComponentUpdatePayload = {
  __typename?: "SalaryComponentUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<SalaryComponent>;
};

/** Collection of SalaryComponents */
export type SalaryComponents = {
  __typename?: "SalaryComponents";
  /** Paginated collection of SalaryComponents */
  items: Array<SalaryComponent>;
  /** Total count of SalaryComponents */
  totalCount: Scalars["Int"];
};

/** SalaryComponents filter */
export type SalaryComponentsFilterInput = {
  abbr?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Component description */
  description?: InputMaybe<StringMatchInput>;
  /** Component description in En locale */
  descriptionEn?: InputMaybe<StringMatchInput>;
  /** Component description in Es locale */
  descriptionEs?: InputMaybe<StringMatchInput>;
  /** Component description in Pt locale */
  descriptionPt?: InputMaybe<StringMatchInput>;
  /** Component description in Ru locale */
  descriptionRu?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  formula?: InputMaybe<StringMatchInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  money?: InputMaybe<Scalars["Boolean"]>;
  /** Component name */
  name?: InputMaybe<StringMatchInput>;
  /** Component name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Component name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Component name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Component name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  useInCost?: InputMaybe<Scalars["Boolean"]>;
};

/** SalaryComponents sorting options */
export type SalaryComponentsSortInput = {
  abbr?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Component description */
  description?: InputMaybe<SortDirection>;
  /** Component description in En locale */
  descriptionEn?: InputMaybe<SortDirection>;
  /** Component description in Es locale */
  descriptionEs?: InputMaybe<SortDirection>;
  /** Component description in Pt locale */
  descriptionPt?: InputMaybe<SortDirection>;
  /** Component description in Ru locale */
  descriptionRu?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  formula?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  money?: InputMaybe<SortDirection>;
  /** Component name */
  name?: InputMaybe<SortDirection>;
  /** Component name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Component name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Component name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Component name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  useInCost?: InputMaybe<SortDirection>;
};

/** Salary Structure object */
export type SalaryStructure = Destroyable &
  Discardable &
  Timestamps & {
    __typename?: "SalaryStructure";
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Salary structure name */
    name: Scalars["String"];
    /** Salary structure name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Salary structure name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Salary structure name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Salary structure name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Payroll result */
    payrollResult: PayrollResult;
    /** Components */
    salaryComponents: Array<SalaryComponent>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Salary Structure object */
export type SalaryStructurePayrollResultArgs = {
  inputVariables?: InputMaybe<PayrollInputVariables>;
};

/** SalaryStructure changes object */
export type SalaryStructureChanges = {
  /** Salary structure name */
  name?: InputMaybe<Scalars["String"]>;
  /** Salary structure name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Salary structure name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Salary structure name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Salary structure name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Salary Components */
  salaryComponentIds?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Autogenerated return type of SalaryStructureCreate. */
export type SalaryStructureCreatePayload = {
  __typename?: "SalaryStructureCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<SalaryStructure>;
};

/** Autogenerated return type of SalaryStructureDestroy. */
export type SalaryStructureDestroyPayload = {
  __typename?: "SalaryStructureDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of SalaryStructureDiscard. */
export type SalaryStructureDiscardPayload = {
  __typename?: "SalaryStructureDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<SalaryStructure>;
};

/** SalaryStructure field values */
export type SalaryStructureFields = {
  /** Salary structure name */
  name: Scalars["String"];
  /** Salary structure name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Salary structure name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Salary structure name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Salary structure name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Salary Components */
  salaryComponentIds: Array<Scalars["ID"]>;
};

/** Autogenerated return type of SalaryStructureUpdate. */
export type SalaryStructureUpdatePayload = {
  __typename?: "SalaryStructureUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<SalaryStructure>;
};

/** Collection of SalaryStructures */
export type SalaryStructures = {
  __typename?: "SalaryStructures";
  /** Paginated collection of SalaryStructures */
  items: Array<SalaryStructure>;
  /** Total count of SalaryStructures */
  totalCount: Scalars["Int"];
};

/** SalaryStructures filter */
export type SalaryStructuresFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Salary structure name */
  name?: InputMaybe<StringMatchInput>;
  /** Salary structure name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Salary structure name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Salary structure name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Salary structure name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** SalaryStructures sorting options */
export type SalaryStructuresSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Salary structure name */
  name?: InputMaybe<SortDirection>;
  /** Salary structure name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Salary structure name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Salary structure name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Salary structure name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Sample disease object */
export type SampleDisease = Discardable & {
  __typename?: "SampleDisease";
  /** Client ID */
  clientId?: Maybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Disease */
  disease: Disease;
  /** Diseases found */
  found: Scalars["Int"];
  id: Scalars["ID"];
  images?: Maybe<Array<Scalars["Upload"]>>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Sample section */
  section: SampleSection;
  /** Sample size */
  size: Scalars["Int"];
  /** Default source */
  source: DiseaseSource;
  /** Thumbnail */
  thumbnail?: Maybe<Scalars["String"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** SampleDisease changes object */
export type SampleDiseaseChanges = {
  /** Client ID */
  clientId?: InputMaybe<Scalars["String"]>;
  /** Discard */
  discardedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Disease */
  diseaseId?: InputMaybe<Scalars["ID"]>;
  /** Diseases found */
  found?: InputMaybe<Scalars["Int"]>;
  /** Sample disease ID */
  id?: InputMaybe<Scalars["ID"]>;
  images?: InputMaybe<Array<Scalars["Upload"]>>;
  /** New images */
  newFiles?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Images to delete */
  purgeFiles?: InputMaybe<Array<Scalars["ID"]>>;
  /** Sample section */
  section?: InputMaybe<SampleSection>;
  /** Sample size */
  size?: InputMaybe<Scalars["Int"]>;
  /** Default source */
  source?: InputMaybe<DiseaseSource>;
  /** Thumbnail */
  thumbnail?: InputMaybe<Scalars["String"]>;
};

/** SampleDisease field values */
export type SampleDiseaseFields = {
  /** Client ID */
  clientId?: InputMaybe<Scalars["String"]>;
  /** Disease */
  diseaseId: Scalars["ID"];
  /** Diseases found */
  found: Scalars["Int"];
  images?: InputMaybe<Array<Scalars["Upload"]>>;
  /** Sample section */
  section: SampleSection;
  /** Sample size */
  size: Scalars["Int"];
  /** Default source */
  source: DiseaseSource;
  /** Thumbnail */
  thumbnail?: InputMaybe<Scalars["String"]>;
};

export enum SampleSection {
  /** Bottom */
  Bottom = "bottom",
  /** General */
  General = "general",
  /** High */
  High = "high",
  /** Middle */
  Middle = "middle",
}

/** Autogenerated return type of SendResetPasswordMutation. */
export type SendResetPasswordMutationPayload = {
  __typename?: "SendResetPasswordMutationPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["String"]>;
};

/** Settings permissions */
export type SettingsInputPermissions = {
  billing?: InputMaybe<Scalars["Boolean"]>;
  company?: InputMaybe<Scalars["Boolean"]>;
  editPlanning?: InputMaybe<Scalars["Boolean"]>;
  import?: InputMaybe<Scalars["Boolean"]>;
  resetPassword?: InputMaybe<Scalars["Boolean"]>;
  resetTestMode?: InputMaybe<Scalars["Boolean"]>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
  showWage?: InputMaybe<Scalars["Boolean"]>;
  testMode?: InputMaybe<Scalars["Boolean"]>;
};

/** Settings permissions options */
export type SettingsPermissions = {
  __typename?: "SettingsPermissions";
  billing: Scalars["Boolean"];
  company: Scalars["Boolean"];
  editPlanning: Scalars["Boolean"];
  import: Scalars["Boolean"];
  resetPassword: Scalars["Boolean"];
  resetTestMode: Scalars["Boolean"];
  showCost: Scalars["Boolean"];
  showWage: Scalars["Boolean"];
  testMode: Scalars["Boolean"];
  updateTenants: Scalars["Boolean"];
};

/** Autogenerated return type of SignInMutation. */
export type SignInMutationPayload = {
  __typename?: "SignInMutationPayload";
  errors: Array<UserError>;
  user?: Maybe<CurrentUser>;
};

/** Autogenerated return type of SignOutMutation. */
export type SignOutMutationPayload = {
  __typename?: "SignOutMutationPayload";
  errors: Array<UserError>;
};

/** Autogenerated return type of SignUpMutation. */
export type SignUpMutationPayload = {
  __typename?: "SignUpMutationPayload";
  errors: Array<UserError>;
  user?: Maybe<CurrentUser>;
};

export enum SortDirection {
  /** Sort ascending */
  Asc = "asc",
  /** Sort descending */
  Desc = "desc",
}

/** Inventory Variant Stock Info */
export type Stock = {
  __typename?: "Stock";
  /** On Hand Quantity */
  onHand: Scalars["Float"];
  /** Reserved Quantity */
  reserved: Scalars["Float"];
  /** Total Quantity */
  total: Scalars["Float"];
  /** Warehouse */
  warehouse?: Maybe<Warehouse>;
};

/** Collection of StockReport */
export type StockReport = {
  __typename?: "StockReport";
  /** Paginated collection of StockReport */
  items: Array<StockReportRecord>;
  /** Total count of StockReport */
  totalCount: Scalars["Int"];
};

/** StockReport filter */
export type StockReportFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Stock on date */
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Variant ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Active Ingredient */
  ingredientId?: InputMaybe<Array<Scalars["ID"]>>;
  ingredients?: InputMaybe<StringMatchInput>;
  /** Internal Id */
  internalId?: InputMaybe<StringMatchInput>;
  /** Item category */
  itemCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item */
  itemId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** May update variation value */
  mayUpdateVariationValue?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  stockId?: InputMaybe<StringMatchInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  variantId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Warehouse */
  warehouseId?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Stock Report result */
export type StockReportRecord = Destroyable &
  Discardable & {
    __typename?: "StockReportRecord";
    /** Item Variant cost */
    cost: Scalars["Float"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    ingredients?: Maybe<Scalars["String"]>;
    internalId?: Maybe<Scalars["String"]>;
    /** Inventory item */
    item: Item;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** May update variation value */
    mayUpdateVariationValue: Scalars["Boolean"];
    /** Total missed quantity */
    missed: Scalars["Float"];
    name: Scalars["String"];
    /** Total on-hand quantity */
    onHand: Scalars["Float"];
    /** Total reserved quantity */
    reserved: Scalars["Float"];
    /** Variant stock */
    stock: Stock;
    stockId: Scalars["String"];
    /** Token attributes */
    token?: Maybe<Token>;
    /** Total stock quantity */
    total: Scalars["Float"];
    /** Total cost */
    totalCost: Scalars["Float"];
    /** Unit cost */
    unitCost: Scalars["Float"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    variantId: Scalars["ID"];
    /** Variation unit (weight, size, ...) */
    variationUnit: Unit;
    /** Variation value */
    variationValue: Scalars["Float"];
    /** Warehouse */
    warehouse: Warehouse;
  };

/** Stock Report result */
export type StockReportRecordCostArgs = {
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** Stock Report result */
export type StockReportRecordStockArgs = {
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  localityId?: InputMaybe<Scalars["ID"]>;
  locationId?: InputMaybe<Scalars["ID"]>;
  locationType?: InputMaybe<LocationType>;
  virtual?: InputMaybe<VirtualWarehouse>;
};

/** StockReport sorting options */
export type StockReportSortInput = {
  /** Item Variant cost */
  cost?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  ingredients?: InputMaybe<SortDirection>;
  internalId?: InputMaybe<SortDirection>;
  /** May update variation value */
  mayUpdateVariationValue?: InputMaybe<SortDirection>;
  /** Total missed quantity */
  missed?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  /** Total on-hand quantity */
  onHand?: InputMaybe<SortDirection>;
  /** Total reserved quantity */
  reserved?: InputMaybe<SortDirection>;
  stockId?: InputMaybe<SortDirection>;
  /** Total stock quantity */
  total?: InputMaybe<SortDirection>;
  /** Total cost */
  totalCost?: InputMaybe<SortDirection>;
  /** Unit cost */
  unitCost?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  variantId?: InputMaybe<SortDirection>;
  /** Variation value */
  variationValue?: InputMaybe<SortDirection>;
  /** Warehouse name */
  warehouseName?: InputMaybe<SortDirection>;
};

/** Specifies string matching */
export type StringMatchInput = {
  /** Should be any of */
  any?: InputMaybe<Array<Scalars["String"]>>;
  /** Should include value */
  match?: InputMaybe<Scalars["String"]>;
};

/** Tag object */
export type Tag = Timestamps & {
  __typename?: "Tag";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** Unit name */
  name: Scalars["String"];
  /** Taggings count */
  taggingsCount: Scalars["Int"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Collection of Tags */
export type Tags = {
  __typename?: "Tags";
  /** Paginated collection of Tags */
  items: Array<Tag>;
  /** Total count of Tags */
  totalCount: Scalars["Int"];
};

/** Tags filter */
export type TagsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Unit name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Taggings count */
  taggingsCount?: InputMaybe<Array<Scalars["Int"]>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
};

/** Tags sorting options */
export type TagsSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Unit name */
  name?: InputMaybe<SortDirection>;
  /** Taggings count */
  taggingsCount?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Tax component object */
export type TaxComponent = Destroyable &
  Discardable &
  Timestamps & {
    __typename?: "TaxComponent";
    abbr: Scalars["String"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    defaultFormula?: Maybe<Scalars["String"]>;
    /** Tax description */
    description?: Maybe<Scalars["String"]>;
    /** Tax description in En locale */
    descriptionEn?: Maybe<Scalars["String"]>;
    /** Tax description in Es locale */
    descriptionEs?: Maybe<Scalars["String"]>;
    /** Tax description in Pt locale */
    descriptionPt?: Maybe<Scalars["String"]>;
    /** Tax description in Ru locale */
    descriptionRu?: Maybe<Scalars["String"]>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Tax name */
    name: Scalars["String"];
    /** Tax name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Tax name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Tax name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Tax name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Tax credit account */
    taxCreditAccount?: Maybe<Account>;
    /** Tax debit account */
    taxDebitAccount?: Maybe<Account>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** TaxComponent changes object */
export type TaxComponentChanges = {
  abbr?: InputMaybe<Scalars["String"]>;
  defaultFormula?: InputMaybe<Scalars["String"]>;
  /** Tax description */
  description?: InputMaybe<Scalars["String"]>;
  /** Tax description in En locale */
  descriptionEn?: InputMaybe<Scalars["String"]>;
  /** Tax description in Es locale */
  descriptionEs?: InputMaybe<Scalars["String"]>;
  /** Tax description in Pt locale */
  descriptionPt?: InputMaybe<Scalars["String"]>;
  /** Tax description in Ru locale */
  descriptionRu?: InputMaybe<Scalars["String"]>;
  /** Tax name */
  name?: InputMaybe<Scalars["String"]>;
  /** Tax name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Tax name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Tax name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Tax name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Credit Account */
  taxCreditAccountId?: InputMaybe<Scalars["ID"]>;
  /** Debit Account */
  taxDebitAccountId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of TaxComponentCreate. */
export type TaxComponentCreatePayload = {
  __typename?: "TaxComponentCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<TaxComponent>;
};

/** Autogenerated return type of TaxComponentDestroy. */
export type TaxComponentDestroyPayload = {
  __typename?: "TaxComponentDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of TaxComponentDiscard. */
export type TaxComponentDiscardPayload = {
  __typename?: "TaxComponentDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<TaxComponent>;
};

/** TaxComponent field values */
export type TaxComponentFields = {
  abbr: Scalars["String"];
  defaultFormula?: InputMaybe<Scalars["String"]>;
  /** Tax description */
  description?: InputMaybe<Scalars["String"]>;
  /** Tax description in En locale */
  descriptionEn?: InputMaybe<Scalars["String"]>;
  /** Tax description in Es locale */
  descriptionEs?: InputMaybe<Scalars["String"]>;
  /** Tax description in Pt locale */
  descriptionPt?: InputMaybe<Scalars["String"]>;
  /** Tax description in Ru locale */
  descriptionRu?: InputMaybe<Scalars["String"]>;
  /** Tax name */
  name: Scalars["String"];
  /** Tax name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Tax name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Tax name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Tax name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Credit Account */
  taxCreditAccountId?: InputMaybe<Scalars["ID"]>;
  /** Debit Account */
  taxDebitAccountId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of TaxComponentUpdate. */
export type TaxComponentUpdatePayload = {
  __typename?: "TaxComponentUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<TaxComponent>;
};

/** Collection of TaxComponents */
export type TaxComponents = {
  __typename?: "TaxComponents";
  /** Paginated collection of TaxComponents */
  items: Array<TaxComponent>;
  /** Total count of TaxComponents */
  totalCount: Scalars["Int"];
};

/** TaxComponents filter */
export type TaxComponentsFilterInput = {
  abbr?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  defaultFormula?: InputMaybe<StringMatchInput>;
  /** Tax description */
  description?: InputMaybe<StringMatchInput>;
  /** Tax description in En locale */
  descriptionEn?: InputMaybe<StringMatchInput>;
  /** Tax description in Es locale */
  descriptionEs?: InputMaybe<StringMatchInput>;
  /** Tax description in Pt locale */
  descriptionPt?: InputMaybe<StringMatchInput>;
  /** Tax description in Ru locale */
  descriptionRu?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Tax name */
  name?: InputMaybe<StringMatchInput>;
  /** Tax name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Tax name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Tax name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Tax name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** TaxComponents sorting options */
export type TaxComponentsSortInput = {
  abbr?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  defaultFormula?: InputMaybe<SortDirection>;
  /** Tax description */
  description?: InputMaybe<SortDirection>;
  /** Tax description in En locale */
  descriptionEn?: InputMaybe<SortDirection>;
  /** Tax description in Es locale */
  descriptionEs?: InputMaybe<SortDirection>;
  /** Tax description in Pt locale */
  descriptionPt?: InputMaybe<SortDirection>;
  /** Tax description in Ru locale */
  descriptionRu?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Tax name */
  name?: InputMaybe<SortDirection>;
  /** Tax name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Tax name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Tax name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Tax name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Tax Plan object */
export type TaxPlan = Destroyable &
  Discardable &
  Timestamps & {
    __typename?: "TaxPlan";
    /** Tax Components */
    components: Array<TaxPlanComponent>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** Tax plan */
    kind: TaxPlanKind;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Tax plan name */
    name: Scalars["String"];
    /** Tax plan name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Tax plan name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Tax plan name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Tax plan name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Tax plan sample result */
    sample: Array<TaxValue>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Tax Plan object */
export type TaxPlanSampleArgs = {
  inputVariables?: InputMaybe<TaxPlanVariables>;
};

/** TaxPlan changes object */
export type TaxPlanChanges = {
  /** Tax Plan Components */
  componentsAttributes?: InputMaybe<Array<TaxPlanComponentChanges>>;
  /** Tax plan */
  kind?: InputMaybe<TaxPlanKind>;
  /** Tax plan name */
  name?: InputMaybe<Scalars["String"]>;
  /** Tax plan name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Tax plan name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Tax plan name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Tax plan name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

export type TaxPlanComponent = {
  __typename?: "TaxPlanComponent";
  formula?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  includeInUnitCost?: Maybe<Scalars["Boolean"]>;
  includedInPrice?: Maybe<Scalars["Boolean"]>;
  index: Scalars["Int"];
  taxComponent: TaxComponent;
};

/** TaxPlanComponent changes object */
export type TaxPlanComponentChanges = {
  /** Remove component */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  formula?: InputMaybe<Scalars["String"]>;
  /** Tax Plan Component ID */
  id?: InputMaybe<Scalars["ID"]>;
  includeInUnitCost?: InputMaybe<Scalars["Boolean"]>;
  includedInPrice?: InputMaybe<Scalars["Boolean"]>;
  index?: InputMaybe<Scalars["Int"]>;
  /** Tax Component */
  taxComponentId?: InputMaybe<Scalars["ID"]>;
};

/** TaxPlanComponent field values */
export type TaxPlanComponentFields = {
  formula?: InputMaybe<Scalars["String"]>;
  includeInUnitCost?: InputMaybe<Scalars["Boolean"]>;
  includedInPrice?: InputMaybe<Scalars["Boolean"]>;
  index: Scalars["Int"];
  /** Tax Component */
  taxComponentId: Scalars["ID"];
};

/** Autogenerated return type of TaxPlanCreate. */
export type TaxPlanCreatePayload = {
  __typename?: "TaxPlanCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<TaxPlan>;
};

/** Autogenerated return type of TaxPlanDestroy. */
export type TaxPlanDestroyPayload = {
  __typename?: "TaxPlanDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** Autogenerated return type of TaxPlanDiscard. */
export type TaxPlanDiscardPayload = {
  __typename?: "TaxPlanDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<TaxPlan>;
};

/** TaxPlan field values */
export type TaxPlanFields = {
  /** Tax Plan Components */
  componentsAttributes: Array<TaxPlanComponentFields>;
  /** Tax plan */
  kind: TaxPlanKind;
  /** Tax plan name */
  name: Scalars["String"];
  /** Tax plan name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Tax plan name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Tax plan name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Tax plan name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
};

export enum TaxPlanKind {
  /** Customer */
  Customer = "customer",
  /** Item */
  Item = "item",
  /** Vendor */
  Vendor = "vendor",
}

/** Autogenerated return type of TaxPlanUpdate. */
export type TaxPlanUpdatePayload = {
  __typename?: "TaxPlanUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<TaxPlan>;
};

export type TaxPlanVariables = {
  /** Item Subtotal */
  itemSubtotal?: InputMaybe<Scalars["Float"]>;
  /** Order Subtotal */
  orderSubtotal?: InputMaybe<Scalars["Float"]>;
  /** Item Quantity */
  quantity?: InputMaybe<Scalars["Float"]>;
  /** Item Subtotal Syn */
  subtotal?: InputMaybe<Scalars["Float"]>;
  /** Item Price */
  unitPrice?: InputMaybe<Scalars["Float"]>;
};

/** Collection of TaxPlans */
export type TaxPlans = {
  __typename?: "TaxPlans";
  /** Paginated collection of TaxPlans */
  items: Array<TaxPlan>;
  /** Total count of TaxPlans */
  totalCount: Scalars["Int"];
};

/** TaxPlans filter */
export type TaxPlansFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Tax plan */
  kind?: InputMaybe<Array<TaxPlanKind>>;
  /** Tax plan name */
  name?: InputMaybe<StringMatchInput>;
  /** Tax plan name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Tax plan name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Tax plan name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Tax plan name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** TaxPlans sorting options */
export type TaxPlansSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Tax plan */
  kind?: InputMaybe<SortDirection>;
  /** Tax plan name */
  name?: InputMaybe<SortDirection>;
  /** Tax plan name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Tax plan name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Tax plan name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Tax plan name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export type TaxValue = {
  __typename?: "TaxValue";
  /** Tax component */
  component: TaxComponent;
  /** Error */
  error?: Maybe<Scalars["String"]>;
  includeInUnitCost: Scalars["Boolean"];
  includedInPrice: Scalars["Boolean"];
  /** Evaluated value */
  value?: Maybe<Scalars["Float"]>;
};

/** Tenant/Company object */
export type Tenant = Discardable & {
  __typename?: "Tenant";
  /** Account Mappings */
  accountMappings: Array<AccountMapping>;
  /**
   * Is accounting disabled
   * @deprecated Use features
   */
  accountingDisabled?: Maybe<Scalars["Boolean"]>;
  /** Tenant address */
  address?: Maybe<Address>;
  /**
   * Use Agroprime export
   * @deprecated Use features
   */
  agroprimeEnabled?: Maybe<Scalars["Boolean"]>;
  /** Allow to use leave assignments */
  allowLeaveAssignments?: Maybe<Scalars["Boolean"]>;
  /**
   * Allow to use tokens
   * @deprecated Use features
   */
  allowTokens?: Maybe<Scalars["Boolean"]>;
  /** Is employee attendance enabled */
  attendanceEnabled?: Maybe<Scalars["Boolean"]>;
  /** Bank accounts */
  bankAccounts: Array<BankAccount>;
  canBilling: AuthorizationResult;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Company default currency */
  currency: Currency;
  /** Company default currency code */
  currencyCode: Scalars["String"];
  /** Default cost center settings */
  defaultCostCenters: Array<DefaultCostCenter>;
  /** Company description */
  description?: Maybe<Scalars["String"]>;
  /** Discarded timestamp */
  discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Company official email */
  email?: Maybe<Scalars["String"]>;
  /**
   * Use Exactus accounting export
   * @deprecated Use features
   */
  exactusEnabled?: Maybe<Scalars["Boolean"]>;
  /** Tenant features */
  features: TenantFeatures;
  /** Create finance entries? */
  financeEntriesDisabled?: Maybe<Scalars["Boolean"]>;
  /** Company full name */
  fullName?: Maybe<Scalars["String"]>;
  /** Holiday overtime factor */
  holidayOvertimeFactor?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /**
   * Disable inventory request
   * @deprecated Use inventory_request_enabled
   */
  inventoryRequestDisabled?: Maybe<Scalars["Boolean"]>;
  /** Enable inventory request */
  inventoryRequestEnabled?: Maybe<Scalars["Boolean"]>;
  /** Legal business name */
  legalName?: Maybe<Scalars["String"]>;
  /** Live Tenant ID */
  liveTenantId?: Maybe<Scalars["ID"]>;
  /** Company locale */
  localeCode: Scalars["String"];
  /** Company localities */
  localities: Array<Locality>;
  logo?: Maybe<Scalars["Upload"]>;
  /** May discard? */
  mayDiscard: Scalars["Boolean"];
  /** Whether currency can be updated */
  mayUpdateCurrency: Scalars["Boolean"];
  /** Enable multifarm inventory */
  multifarmInventoryEnabled?: Maybe<Scalars["Boolean"]>;
  /** Company name */
  name: Scalars["String"];
  /** Holiday overtime factor */
  nonWorkDayOvertimeFactor?: Maybe<Scalars["Float"]>;
  /** Onboarding progress */
  onboardingProgress: Scalars["Int"];
  /** Is overtime enabled */
  overtimeEnabled?: Maybe<Scalars["Boolean"]>;
  /** Extra overtime factor */
  overtimeFactor?: Maybe<Scalars["Float"]>;
  /** Company owner */
  owner?: Maybe<User>;
  /**
   * Enable planning
   * @deprecated Use features
   */
  planningEnabled?: Maybe<Scalars["Boolean"]>;
  /** Data reset at */
  resetAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /**
   * Enable custom salary structures
   * @deprecated Use features
   */
  salaryStructureEnabled?: Maybe<Scalars["Boolean"]>;
  /** Company secodnary currency */
  secondaryCurrency?: Maybe<Currency>;
  /** Company slug */
  slug: Scalars["String"];
  /** Tenant statistics */
  stats: TenantStats;
  /** Government Tax ID */
  taxId?: Maybe<Scalars["String"]>;
  /**
   * Enable custom tax plans
   * @deprecated Use features
   */
  taxPlanEnabled?: Maybe<Scalars["Boolean"]>;
  /**
   * Enable test mode
   * @deprecated Use features
   */
  testModeEnabled?: Maybe<Scalars["Boolean"]>;
  /** Test tenant ID */
  testTenantId?: Maybe<Scalars["ID"]>;
  /** Time tracking enabled */
  timeTrackingEnabled?: Maybe<Scalars["Boolean"]>;
  /** Company timezone */
  timeZone: Scalars["String"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Use journal entry accounting */
  useExternalPolicy?: Maybe<Scalars["Boolean"]>;
  /** User limit */
  userLimit?: Maybe<Scalars["Int"]>;
  /** Company users */
  users: Array<User>;
  /** Company website */
  website?: Maybe<Scalars["String"]>;
  /** Work order count */
  woCount: Scalars["Int"];
  /** Work order limit */
  woLimit?: Maybe<Scalars["Int"]>;
};

/** Tenant/Company object */
export type TenantLogoArgs = {
  variant?: InputMaybe<ImageVariant>;
};

/** Tenant changes object */
export type TenantChanges = {
  /** Account mappings */
  accountMappingsAttributes?: InputMaybe<Array<AccountMappingChanges>>;
  /** Is accounting disabled */
  accountingDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Address */
  addressAttributes?: InputMaybe<AddressChanges>;
  /** Allow to use leave assignments */
  allowLeaveAssignments?: InputMaybe<Scalars["Boolean"]>;
  /** Is employee attendance enabled */
  attendanceEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company default currency code */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Default cost centers */
  defaultCostCentersAttributes?: InputMaybe<Array<DefaultCostCenterChanges>>;
  /** Company description */
  description?: InputMaybe<Scalars["String"]>;
  /** Company official email */
  email?: InputMaybe<Scalars["String"]>;
  /** Tenant features */
  features?: InputMaybe<TenantFeaturesInput>;
  /** Create finance entries? */
  financeEntriesDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company full name */
  fullName?: InputMaybe<Scalars["String"]>;
  /** Holiday overtime factor */
  holidayOvertimeFactor?: InputMaybe<Scalars["Float"]>;
  /** Disable inventory request */
  inventoryRequestDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Enable inventory request */
  inventoryRequestEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Legal business name */
  legalName?: InputMaybe<Scalars["String"]>;
  /** Live Tenant ID */
  liveTenantId?: InputMaybe<Scalars["ID"]>;
  /** Company locale */
  localeCode?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["Upload"]>;
  /** Enable multifarm inventory */
  multifarmInventoryEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company name */
  name?: InputMaybe<Scalars["String"]>;
  /** Holiday overtime factor */
  nonWorkDayOvertimeFactor?: InputMaybe<Scalars["Float"]>;
  /** Is overtime enabled */
  overtimeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Extra overtime factor */
  overtimeFactor?: InputMaybe<Scalars["Float"]>;
  /** Owner User */
  ownerId?: InputMaybe<Scalars["ID"]>;
  /** Enable planning */
  planningEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Reset all activity time settings */
  resetActivityTimes?: InputMaybe<Scalars["Boolean"]>;
  /** Data reset at */
  resetAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Enable custom salary structures */
  salaryStructureEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company slug */
  slug?: InputMaybe<Scalars["String"]>;
  /** Government Tax ID */
  taxId?: InputMaybe<Scalars["String"]>;
  /** Enable custom tax plans */
  taxPlanEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Enable test mode */
  testModeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Test tenant ID */
  testTenantId?: InputMaybe<Scalars["ID"]>;
  /** Time tracking enabled */
  timeTrackingEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Use journal entry accounting */
  useExternalPolicy?: InputMaybe<Scalars["Boolean"]>;
  /** User limit */
  userLimit?: InputMaybe<Scalars["Int"]>;
  /** Company website */
  website?: InputMaybe<Scalars["String"]>;
  /** Week Day */
  weekDaysAttributes?: InputMaybe<Array<WeekDayChanges>>;
  /** Work order limit */
  woLimit?: InputMaybe<Scalars["Int"]>;
};

/** Autogenerated return type of TenantClone. */
export type TenantClonePayload = {
  __typename?: "TenantClonePayload";
  jobId: Scalars["ID"];
};

/** Autogenerated return type of TenantCreate. */
export type TenantCreatePayload = {
  __typename?: "TenantCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Tenant>;
};

/** Autogenerated return type of TenantDiscard. */
export type TenantDiscardPayload = {
  __typename?: "TenantDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Tenant>;
};

/** Tenant features */
export type TenantFeatures = {
  __typename?: "TenantFeatures";
  /** Accounting feature */
  accounting?: Maybe<Scalars["Boolean"]>;
  /** Enable advanced activity features */
  advancedActivities?: Maybe<Scalars["Boolean"]>;
  /** Use Agroprime export */
  agroprime?: Maybe<Scalars["Boolean"]>;
  /** Enable banks */
  banks?: Maybe<Scalars["Boolean"]>;
  /** Enable cost centers */
  costCenters?: Maybe<Scalars["Boolean"]>;
  /** Enable custom crop stages */
  customCropStages?: Maybe<Scalars["Boolean"]>;
  /** Enable custom crop varieties */
  customCropVarieties?: Maybe<Scalars["Boolean"]>;
  /** Enable employees */
  employees?: Maybe<Scalars["Boolean"]>;
  /** Enabled work order types */
  enabledWorkOrderTypes?: Maybe<Array<WorkOrderType>>;
  /** Use Exactus accounting export */
  exactus?: Maybe<Scalars["Boolean"]>;
  /** Enable expenses & finance orders */
  expenses?: Maybe<Scalars["Boolean"]>;
  /** Enable holidays */
  holidays?: Maybe<Scalars["Boolean"]>;
  /** Enable infrastructures */
  infrastructures?: Maybe<Scalars["Boolean"]>;
  /** Track inventory stock */
  inventoryStock?: Maybe<Scalars["Boolean"]>;
  /** Enable machinery */
  machinery?: Maybe<Scalars["Boolean"]>;
  /** Enable multiple farms */
  multipleFarms?: Maybe<Scalars["Boolean"]>;
  /** Payroll feature */
  payroll?: Maybe<Scalars["Boolean"]>;
  /** Planning feature */
  planning?: Maybe<Scalars["Boolean"]>;
  /** Enable reusable items */
  reusableItems?: Maybe<Scalars["Boolean"]>;
  /**
   * Enable spraying activities
   * @deprecated Useless
   */
  spraying?: Maybe<Scalars["Boolean"]>;
  /** Custom tax plans */
  taxPlan?: Maybe<Scalars["Boolean"]>;
  /** Test mode feature */
  testMode?: Maybe<Scalars["Boolean"]>;
  /** Allow to use tokens */
  tokens?: Maybe<Scalars["Boolean"]>;
  /**
   * Enable all work order types
   * @deprecated Use enabled_work_order_types
   */
  workOrderTypes?: Maybe<Scalars["Boolean"]>;
  /** Enable work schedules */
  workSchedules?: Maybe<Scalars["Boolean"]>;
};

/** Tenant feature input values */
export type TenantFeaturesInput = {
  /** Accounting feature */
  accounting?: InputMaybe<Scalars["Boolean"]>;
  /** Enable advanced activity features */
  advancedActivities?: InputMaybe<Scalars["Boolean"]>;
  /** Use Agroprime export */
  agroprime?: InputMaybe<Scalars["Boolean"]>;
  /** Enable banks */
  banks?: InputMaybe<Scalars["Boolean"]>;
  /** Enable cost centers */
  costCenters?: InputMaybe<Scalars["Boolean"]>;
  /** Enable custom crop stages */
  customCropStages?: InputMaybe<Scalars["Boolean"]>;
  /** Enable custom crop varieties */
  customCropVarieties?: InputMaybe<Scalars["Boolean"]>;
  /** Enable employees */
  employees?: InputMaybe<Scalars["Boolean"]>;
  /** Enabled work order types */
  enabledWorkOrderTypes?: InputMaybe<Array<WorkOrderType>>;
  /** Use Exactus accounting export */
  exactus?: InputMaybe<Scalars["Boolean"]>;
  /** Enable expenses & finance orders */
  expenses?: InputMaybe<Scalars["Boolean"]>;
  /** Enable holidays */
  holidays?: InputMaybe<Scalars["Boolean"]>;
  /** Enable infrastructures */
  infrastructures?: InputMaybe<Scalars["Boolean"]>;
  /** Track inventory stock */
  inventoryStock?: InputMaybe<Scalars["Boolean"]>;
  /** Enable machinery */
  machinery?: InputMaybe<Scalars["Boolean"]>;
  /** Enable multiple farms */
  multipleFarms?: InputMaybe<Scalars["Boolean"]>;
  /** Payroll feature */
  payroll?: InputMaybe<Scalars["Boolean"]>;
  /** Planning feature */
  planning?: InputMaybe<Scalars["Boolean"]>;
  /** Enable reusable items */
  reusableItems?: InputMaybe<Scalars["Boolean"]>;
  /** Enable spraying activities */
  spraying?: InputMaybe<Scalars["Boolean"]>;
  /** Custom tax plans */
  taxPlan?: InputMaybe<Scalars["Boolean"]>;
  /** Test mode feature */
  testMode?: InputMaybe<Scalars["Boolean"]>;
  /** Allow to use tokens */
  tokens?: InputMaybe<Scalars["Boolean"]>;
  /** Enable all work order types */
  workOrderTypes?: InputMaybe<Scalars["Boolean"]>;
  /** Enable work schedules */
  workSchedules?: InputMaybe<Scalars["Boolean"]>;
};

/** Tenant field values */
export type TenantFields = {
  /** Is accounting disabled */
  accountingDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Allow to use leave assignments */
  allowLeaveAssignments?: InputMaybe<Scalars["Boolean"]>;
  /** Is employee attendance enabled */
  attendanceEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company description */
  description?: InputMaybe<Scalars["String"]>;
  /** Company official email */
  email?: InputMaybe<Scalars["String"]>;
  /** Tenant features */
  features?: InputMaybe<TenantFeaturesInput>;
  /** Create finance entries? */
  financeEntriesDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company full name */
  fullName?: InputMaybe<Scalars["String"]>;
  /** Holiday overtime factor */
  holidayOvertimeFactor?: InputMaybe<Scalars["Float"]>;
  /** Disable inventory request */
  inventoryRequestDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Enable inventory request */
  inventoryRequestEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Legal business name */
  legalName?: InputMaybe<Scalars["String"]>;
  /** Live Tenant ID */
  liveTenantId?: InputMaybe<Scalars["ID"]>;
  /** Company locale */
  localeCode: Scalars["String"];
  logo?: InputMaybe<Scalars["Upload"]>;
  /** Enable multifarm inventory */
  multifarmInventoryEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company name */
  name: Scalars["String"];
  /** Holiday overtime factor */
  nonWorkDayOvertimeFactor?: InputMaybe<Scalars["Float"]>;
  /** Is overtime enabled */
  overtimeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Extra overtime factor */
  overtimeFactor?: InputMaybe<Scalars["Float"]>;
  /** Owner User */
  ownerAttributes?: InputMaybe<UserInvitationInput>;
  /** Enable planning */
  planningEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Data reset at */
  resetAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Enable custom salary structures */
  salaryStructureEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Government Tax ID */
  taxId?: InputMaybe<Scalars["String"]>;
  /** Enable custom tax plans */
  taxPlanEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Enable test mode */
  testModeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Test tenant ID */
  testTenantId?: InputMaybe<Scalars["ID"]>;
  /** Time tracking enabled */
  timeTrackingEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Use journal entry accounting */
  useExternalPolicy?: InputMaybe<Scalars["Boolean"]>;
  /** User limit */
  userLimit?: InputMaybe<Scalars["Int"]>;
  /** Company website */
  website?: InputMaybe<Scalars["String"]>;
  /** Work order limit */
  woLimit?: InputMaybe<Scalars["Int"]>;
};

/** Tenant statistics */
export type TenantStats = {
  __typename?: "TenantStats";
  /** Last sign in */
  lastSignIn?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Last WO */
  lastWorkOrderCreatedAt?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Count of WO */
  workOrderCount: Scalars["Int"];
};

/** Autogenerated return type of TenantUpdate. */
export type TenantUpdatePayload = {
  __typename?: "TenantUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Tenant>;
};

/** Collection of Tenants */
export type Tenants = {
  __typename?: "Tenants";
  /** Paginated collection of Tenants */
  items: Array<Tenant>;
  /** Total count of Tenants */
  totalCount: Scalars["Int"];
};

/** Tenants filter */
export type TenantsFilterInput = {
  /** Is accounting disabled */
  accountingDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Use Agroprime export */
  agroprimeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Allow to use leave assignments */
  allowLeaveAssignments?: InputMaybe<Scalars["Boolean"]>;
  /** Allow to use tokens */
  allowTokens?: InputMaybe<Scalars["Boolean"]>;
  /** Is employee attendance enabled */
  attendanceEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Company default currency code */
  currencyCode?: InputMaybe<StringMatchInput>;
  /** Company description */
  description?: InputMaybe<StringMatchInput>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Company official email */
  email?: InputMaybe<StringMatchInput>;
  /** Use Exactus accounting export */
  exactusEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Create finance entries? */
  financeEntriesDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company full name */
  fullName?: InputMaybe<StringMatchInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Disable inventory request */
  inventoryRequestDisabled?: InputMaybe<Scalars["Boolean"]>;
  /** Enable inventory request */
  inventoryRequestEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Legal business name */
  legalName?: InputMaybe<StringMatchInput>;
  /** Live Tenant ID */
  liveTenantId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Company locale */
  localeCode?: InputMaybe<StringMatchInput>;
  /** Whether currency can be updated */
  mayUpdateCurrency?: InputMaybe<Scalars["Boolean"]>;
  /** Enable multifarm inventory */
  multifarmInventoryEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company name */
  name?: InputMaybe<StringMatchInput>;
  /** Onboarding progress */
  onboardingProgress?: InputMaybe<Array<Scalars["Int"]>>;
  /** Is overtime enabled */
  overtimeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Enable planning */
  planningEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Data reset at */
  resetAt?: InputMaybe<DateRangeInput>;
  /** Enable custom salary structures */
  salaryStructureEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Company slug */
  slug?: InputMaybe<StringMatchInput>;
  /** Government Tax ID */
  taxId?: InputMaybe<StringMatchInput>;
  /** Enable custom tax plans */
  taxPlanEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Enable test mode */
  testModeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Test tenant ID */
  testTenantId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Time tracking enabled */
  timeTrackingEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Company timezone */
  timeZone?: InputMaybe<StringMatchInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Use journal entry accounting */
  useExternalPolicy?: InputMaybe<Scalars["Boolean"]>;
  /** User limit */
  userLimit?: InputMaybe<Array<Scalars["Int"]>>;
  /** Company website */
  website?: InputMaybe<StringMatchInput>;
  /** Work order count */
  woCount?: InputMaybe<Array<Scalars["Int"]>>;
  /** Work order limit */
  woLimit?: InputMaybe<Array<Scalars["Int"]>>;
};

/** Tenants sorting options */
export type TenantsSortInput = {
  /** Is accounting disabled */
  accountingDisabled?: InputMaybe<SortDirection>;
  /** Use Agroprime export */
  agroprimeEnabled?: InputMaybe<SortDirection>;
  /** Allow to use leave assignments */
  allowLeaveAssignments?: InputMaybe<SortDirection>;
  /** Allow to use tokens */
  allowTokens?: InputMaybe<SortDirection>;
  /** Is employee attendance enabled */
  attendanceEnabled?: InputMaybe<SortDirection>;
  /** Sort by country code */
  countryCode?: InputMaybe<SortDirection>;
  /** Sorts by country name */
  countryName?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Company default currency code */
  currencyCode?: InputMaybe<SortDirection>;
  /** Company description */
  description?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Company official email */
  email?: InputMaybe<SortDirection>;
  /** Use Exactus accounting export */
  exactusEnabled?: InputMaybe<SortDirection>;
  /** Create finance entries? */
  financeEntriesDisabled?: InputMaybe<SortDirection>;
  /** Company full name */
  fullName?: InputMaybe<SortDirection>;
  /** Holiday overtime factor */
  holidayOvertimeFactor?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Disable inventory request */
  inventoryRequestDisabled?: InputMaybe<SortDirection>;
  /** Enable inventory request */
  inventoryRequestEnabled?: InputMaybe<SortDirection>;
  /** Sorts by last sign in */
  lastSignIn?: InputMaybe<SortDirection>;
  /** Sorts by last WO created */
  lastWorkOrderCreatedAt?: InputMaybe<SortDirection>;
  /** Legal business name */
  legalName?: InputMaybe<SortDirection>;
  /** Live Tenant ID */
  liveTenantId?: InputMaybe<SortDirection>;
  /** Company locale */
  localeCode?: InputMaybe<SortDirection>;
  logo?: InputMaybe<SortDirection>;
  /** Whether currency can be updated */
  mayUpdateCurrency?: InputMaybe<SortDirection>;
  /** Enable multifarm inventory */
  multifarmInventoryEnabled?: InputMaybe<SortDirection>;
  /** Company name */
  name?: InputMaybe<SortDirection>;
  /** Holiday overtime factor */
  nonWorkDayOvertimeFactor?: InputMaybe<SortDirection>;
  /** Onboarding progress */
  onboardingProgress?: InputMaybe<SortDirection>;
  /** Is overtime enabled */
  overtimeEnabled?: InputMaybe<SortDirection>;
  /** Extra overtime factor */
  overtimeFactor?: InputMaybe<SortDirection>;
  /** Enable planning */
  planningEnabled?: InputMaybe<SortDirection>;
  /** Data reset at */
  resetAt?: InputMaybe<SortDirection>;
  /** Enable custom salary structures */
  salaryStructureEnabled?: InputMaybe<SortDirection>;
  /** Company slug */
  slug?: InputMaybe<SortDirection>;
  /** Government Tax ID */
  taxId?: InputMaybe<SortDirection>;
  /** Enable custom tax plans */
  taxPlanEnabled?: InputMaybe<SortDirection>;
  /** Enable test mode */
  testModeEnabled?: InputMaybe<SortDirection>;
  /** Test tenant ID */
  testTenantId?: InputMaybe<SortDirection>;
  /** Time tracking enabled */
  timeTrackingEnabled?: InputMaybe<SortDirection>;
  /** Company timezone */
  timeZone?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Use journal entry accounting */
  useExternalPolicy?: InputMaybe<SortDirection>;
  /** User limit */
  userLimit?: InputMaybe<SortDirection>;
  /** Company website */
  website?: InputMaybe<SortDirection>;
  /** Work order count */
  woCount?: InputMaybe<SortDirection>;
  /** Work order limit */
  woLimit?: InputMaybe<SortDirection>;
  /** Sorts by work order count */
  workOrderCount?: InputMaybe<SortDirection>;
};

/** Time conflict info object */
export type TimeConflict = {
  __typename?: "TimeConflict";
  /** End time */
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Start time */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
  workOrder: WorkOrder;
};

/** Object that has creation/update timestamps */
export type Timestamps = {
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
};

/** Finance token object */
export type Token = Discardable &
  Timestamps & {
    __typename?: "Token";
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    quantity: Scalars["Float"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    variant: Variant;
  };

/** Token changes object */
export type TokenChanges = {
  quantity?: InputMaybe<Scalars["Float"]>;
  /** Variant */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** Autogenerated return type of TokenCreate. */
export type TokenCreatePayload = {
  __typename?: "TokenCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Token>;
};

/** Token Denomination Report result */
export type TokenDenomination = {
  __typename?: "TokenDenomination";
  /** Amount of tokens */
  amount: Scalars["Float"];
  /** Denomination */
  denomination: Scalars["Float"];
};

/** Autogenerated return type of TokenDiscard. */
export type TokenDiscardPayload = {
  __typename?: "TokenDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Token>;
};

/** Token field values */
export type TokenFields = {
  quantity: Scalars["Float"];
  /** Variant */
  variantId: Scalars["ID"];
};

/** Token Paid Report result */
export type TokenPaid = {
  __typename?: "TokenPaid";
  /** Activity */
  activityName: Scalars["String"];
  /** Cycle number */
  cycleNumber?: Maybe<Scalars["Int"]>;
  /** Token denominations */
  denominations: Array<TokenDenomination>;
  /** Date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Total harvested amount */
  harvestedAmount: Scalars["Float"];
  /** Document ID */
  id: Scalars["ID"];
  /** Unit abbr */
  unitAbbr: Scalars["String"];
  /** Unit cost */
  unitCost: Scalars["Float"];
  /** Harvested value */
  value: Scalars["Float"];
};

/** Collection of TokenPaidReport */
export type TokenPaidReport = {
  __typename?: "TokenPaidReport";
  /** Paginated collection of TokenPaidReport */
  items: Array<TokenPaid>;
  /** Total count of TokenPaidReport */
  totalCount: Scalars["Int"];
};

/** TokenPaidReport filter */
export type TokenPaidReportFilterInput = {
  /** Activity ID */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity */
  activityName?: InputMaybe<StringMatchInput>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Date range */
  date?: InputMaybe<DateRangeInput>;
  /** Date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Document ID */
  documentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Document ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Unit abbr */
  unitAbbr?: InputMaybe<StringMatchInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Token variant */
  variantId?: InputMaybe<Array<Scalars["ID"]>>;
};

/** TokenPaidReport sorting options */
export type TokenPaidReportSortInput = {
  /** Activity */
  activityName?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Cycle number */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Date */
  documentDate?: InputMaybe<SortDirection>;
  /** Total harvested amount */
  harvestedAmount?: InputMaybe<SortDirection>;
  /** Document ID */
  id?: InputMaybe<SortDirection>;
  /** Unit abbr */
  unitAbbr?: InputMaybe<SortDirection>;
  /** Unit cost */
  unitCost?: InputMaybe<SortDirection>;
  /** Harvested value */
  value?: InputMaybe<SortDirection>;
};

/** Token Redeemed Report result */
export type TokenRedeemed = {
  __typename?: "TokenRedeemed";
  /** Token denominations */
  denominations: Array<TokenDenomination>;
  /** Date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Document ID */
  id: Scalars["ID"];
  /** Unit abbr */
  unitAbbr?: Maybe<Scalars["String"]>;
};

/** Collection of TokenRedeemedReport */
export type TokenRedeemedReport = {
  __typename?: "TokenRedeemedReport";
  /** Paginated collection of TokenRedeemedReport */
  items: Array<TokenRedeemed>;
  /** Total count of TokenRedeemedReport */
  totalCount: Scalars["Int"];
};

/** TokenRedeemedReport filter */
export type TokenRedeemedReportFilterInput = {
  /** Activity ID */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date range */
  date?: InputMaybe<DateRangeInput>;
  /** Date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Document ID */
  documentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Document ID */
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Unit abbr */
  unitAbbr?: InputMaybe<StringMatchInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Token variant */
  variantId?: InputMaybe<Array<Scalars["ID"]>>;
};

/** TokenRedeemedReport sorting options */
export type TokenRedeemedReportSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Date */
  documentDate?: InputMaybe<SortDirection>;
  /** Document ID */
  id?: InputMaybe<SortDirection>;
  /** Unit abbr */
  unitAbbr?: InputMaybe<SortDirection>;
};

/** Token Summary Report result */
export type TokenSummary = {
  __typename?: "TokenSummary";
  /** In-Circulation Tokens Value */
  circulationValue: Scalars["Int"];
  /** In-Circulation Tokens */
  employeeTokens: Scalars["Int"];
  /** Ending Stock */
  endingStock: Scalars["Int"];
  /** Harvested amount */
  harvestedAmount: Scalars["Float"];
  /** Lost Tokens */
  missingTokens: Scalars["Int"];
  /** Paid Tokens */
  paidTokens: Scalars["Int"];
  /** Denomination */
  quantity: Scalars["Float"];
  /** Redeemed Tokens */
  redeemedTokens: Scalars["Int"];
  /** Starting Stock */
  startingStock: Scalars["Int"];
  /** Harvested unit */
  unitAbbr: Scalars["String"];
  /** Harvested unit cost */
  unitCost: Scalars["Float"];
  /** Harvested value */
  value: Scalars["Float"];
  /** Variant */
  variant: Variant;
};

/** Collection of TokenSummaryReport */
export type TokenSummaryReport = {
  __typename?: "TokenSummaryReport";
  /** Paginated collection of TokenSummaryReport */
  items: Array<TokenSummary>;
  /** Total count of TokenSummaryReport */
  totalCount: Scalars["Int"];
};

/** TokenSummaryReport filter */
export type TokenSummaryReportFilterInput = {
  /** Activity ID */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** In-Circulation Tokens Value */
  circulationValue?: InputMaybe<Array<Scalars["Int"]>>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Date range */
  date?: InputMaybe<DateRangeInput>;
  /** Document ID */
  documentId?: InputMaybe<Array<Scalars["ID"]>>;
  /** In-Circulation Tokens */
  employeeTokens?: InputMaybe<Array<Scalars["Int"]>>;
  /** Ending Stock */
  endingStock?: InputMaybe<Array<Scalars["Int"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Lost Tokens */
  missingTokens?: InputMaybe<Array<Scalars["Int"]>>;
  /** Paid Tokens */
  paidTokens?: InputMaybe<Array<Scalars["Int"]>>;
  /** Redeemed Tokens */
  redeemedTokens?: InputMaybe<Array<Scalars["Int"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Starting Stock */
  startingStock?: InputMaybe<Array<Scalars["Int"]>>;
  /** Harvested unit */
  unitAbbr?: InputMaybe<StringMatchInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Token variant */
  variantId?: InputMaybe<Array<Scalars["ID"]>>;
};

/** TokenSummaryReport sorting options */
export type TokenSummaryReportSortInput = {
  /** In-Circulation Tokens Value */
  circulationValue?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** In-Circulation Tokens */
  employeeTokens?: InputMaybe<SortDirection>;
  /** Ending Stock */
  endingStock?: InputMaybe<SortDirection>;
  /** Harvested amount */
  harvestedAmount?: InputMaybe<SortDirection>;
  /** Lost Tokens */
  missingTokens?: InputMaybe<SortDirection>;
  /** Paid Tokens */
  paidTokens?: InputMaybe<SortDirection>;
  /** Denomination */
  quantity?: InputMaybe<SortDirection>;
  /** Redeemed Tokens */
  redeemedTokens?: InputMaybe<SortDirection>;
  /** Starting Stock */
  startingStock?: InputMaybe<SortDirection>;
  /** Harvested unit */
  unitAbbr?: InputMaybe<SortDirection>;
  /** Harvested unit cost */
  unitCost?: InputMaybe<SortDirection>;
  /** Harvested value */
  value?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of TokenUpdate. */
export type TokenUpdatePayload = {
  __typename?: "TokenUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Token>;
};

/** Collection of Tokens */
export type Tokens = {
  __typename?: "Tokens";
  /** Paginated collection of Tokens */
  items: Array<Token>;
  /** Total count of Tokens */
  totalCount: Scalars["Int"];
};

/** Tokens filter */
export type TokensFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Tokens sorting options */
export type TokensSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  quantity?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum ToxicityCategory {
  /** Highly */
  Highly = "highly",
  /** Moderately */
  Moderately = "moderately",
  /** Non_toxic */
  NonToxic = "nonToxic",
  /** Slightly */
  Slightly = "slightly",
}

/** Inventory transfer object */
export type Transfer = Auditable &
  Timestamps & {
    __typename?: "Transfer";
    /** Adjustment type */
    adjustmentType: AdjustmentType;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by */
    createdBy?: Maybe<User>;
    /** Document date */
    documentDate: Scalars["ISO8601DateTime"];
    id: Scalars["ID"];
    /** Transfer Internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Transfer notes */
    notes?: Maybe<Scalars["String"]>;
    /** Source warehouse */
    sourceWarehouse: Warehouse;
    /** Transfer status */
    status: TransferStatus;
    /** Source warehouse */
    targetWarehouse: Warehouse;
    /** Transfer items */
    transferVariants: Array<TransferVariant>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
  };

/** Transfer changes object */
export type TransferChanges = {
  /** Document date */
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Transfer Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Transfer notes */
  notes?: InputMaybe<Scalars["String"]>;
  /** Source warehouse */
  sourceWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Transfer status */
  status?: InputMaybe<TransferStatus>;
  /** Target warehouse */
  targetWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Transfer variants */
  transferVariantsAttributes?: InputMaybe<Array<TransferVariantChanges>>;
};

/** Autogenerated return type of TransferCreate. */
export type TransferCreatePayload = {
  __typename?: "TransferCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Transfer>;
};

/** Transfer field values */
export type TransferFields = {
  /** Adjustment type */
  adjustmentTypeId?: InputMaybe<Scalars["ID"]>;
  /** Document date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Transfer Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Transfer notes */
  notes?: InputMaybe<Scalars["String"]>;
  /** Source warehouse */
  sourceWarehouseId: Scalars["ID"];
  /** Transfer status */
  status: TransferStatus;
  /** Target warehouse */
  targetWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Transfer type */
  transferType: TransferType;
  /** Transfer variants */
  transferVariantsAttributes: Array<TransferVariantFields>;
};

export enum TransferStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** Completing */
  Completing = "completing",
  /** Open */
  Open = "open",
  /** Reverted */
  Reverted = "reverted",
  /** Reverting */
  Reverting = "reverting",
}

export enum TransferType {
  /** Adjustments */
  Adjustment = "adjustment",
  /** Movements */
  Movement = "movement",
  /** Reservations */
  Reservation = "reservation",
}

/** Autogenerated return type of TransferUpdate. */
export type TransferUpdatePayload = {
  __typename?: "TransferUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Transfer>;
};

/** Inventory transfer variant object */
export type TransferVariant = Timestamps & {
  __typename?: "TransferVariant";
  /** Variant cost */
  cost?: Maybe<Scalars["Float"]>;
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  id: Scalars["ID"];
  /** Transfer amount */
  quantity: Scalars["Float"];
  /** Source Warehouse stock */
  stock: Stock;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  /** Variant */
  variant: Variant;
};

/** TransferVariant changes object */
export type TransferVariantChanges = {
  /** Remove transfer variant */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Variant cost */
  cost?: InputMaybe<Scalars["Float"]>;
  /** Transfer Variant ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Transfer amount */
  quantity?: InputMaybe<Scalars["Float"]>;
  /** Variant ID */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** TransferVariant field values */
export type TransferVariantFields = {
  /** Variant cost */
  cost?: InputMaybe<Scalars["Float"]>;
  /** Transfer amount */
  quantity: Scalars["Float"];
  /** Variant ID */
  variantId: Scalars["ID"];
};

/** Collection of Transfers */
export type Transfers = {
  __typename?: "Transfers";
  /** Paginated collection of Transfers */
  items: Array<Transfer>;
  /** Total count of Transfers */
  totalCount: Scalars["Int"];
};

/** Transfers filter */
export type TransfersFilterInput = {
  /** Adjustment type */
  adjustmentTypeId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Transfer Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Transfer notes */
  notes?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Source warehouse type */
  sourceWarehouseId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Transfer status */
  status?: InputMaybe<Array<TransferStatus>>;
  /** Target warehouse type */
  targetWarehouseId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Transfer type */
  transferType?: InputMaybe<TransferType>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Transfers sorting options */
export type TransfersSortInput = {
  /** Sort by adjustment type name */
  adjustmentTypeName?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Document date */
  documentDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Transfer Internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Transfer notes */
  notes?: InputMaybe<SortDirection>;
  /** Sort by source warehouse name */
  sourceWarehouseName?: InputMaybe<SortDirection>;
  /** Transfer status */
  status?: InputMaybe<SortDirection>;
  /** Sort by target warehouse name */
  targetWarehouseName?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Trial Balance Report result */
export type TrialBalance = Destroyable &
  Discardable & {
    __typename?: "TrialBalance";
    /** Account number */
    accountNumber?: Maybe<Scalars["String"]>;
    /** Account balance */
    balance: Scalars["Float"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Credits */
    credit?: Maybe<Scalars["Float"]>;
    /** Account default currency */
    currency: Currency;
    /** Currency code */
    currencyCode: Scalars["String"];
    /** Debits */
    debit?: Maybe<Scalars["Float"]>;
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is group account */
    groupAccount: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Account kind */
    kind: AccountKind;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Account name */
    name: Scalars["String"];
    /** Account name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Account name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Account name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Account name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Parent account group */
    parentAccount?: Maybe<Account>;
    /** Parent account ID */
    parentAccountId?: Maybe<Scalars["ID"]>;
    /** Subaccounts */
    subaccounts?: Maybe<Array<Account>>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Collection of TrialBalanceReport */
export type TrialBalanceReport = {
  __typename?: "TrialBalanceReport";
  /** Paginated collection of TrialBalanceReport */
  items: Array<TrialBalance>;
  /** Total count of TrialBalanceReport */
  totalCount: Scalars["Int"];
};

/** TrialBalanceReport filter */
export type TrialBalanceReportFilterInput = {
  /** Account number */
  accountNumber?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Balance on date */
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is group account */
  groupAccount?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Account name */
  name?: InputMaybe<StringMatchInput>;
  /** Account name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** TrialBalanceReport sorting options */
export type TrialBalanceReportSortInput = {
  /** Account number */
  accountNumber?: InputMaybe<SortDirection>;
  /** Account balance */
  balance?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Credits */
  credit?: InputMaybe<SortDirection>;
  /** Currency code */
  currencyCode?: InputMaybe<SortDirection>;
  /** Debits */
  debit?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is group account */
  groupAccount?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Account kind */
  kind?: InputMaybe<SortDirection>;
  /** Account name */
  name?: InputMaybe<SortDirection>;
  /** Account name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Account name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Account name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Account name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Parent account ID */
  parentAccountId?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Unit of Measurement object */
export type Unit = Discardable &
  Global & {
    __typename?: "Unit";
    /** Unit abbreviation */
    abbr: Scalars["String"];
    /** Unit abbreviation in En locale */
    abbrEn?: Maybe<Scalars["String"]>;
    /** Unit abbreviation in Es locale */
    abbrEs?: Maybe<Scalars["String"]>;
    /** Unit abbreviation in Pt locale */
    abbrPt?: Maybe<Scalars["String"]>;
    /** Unit abbreviation in Ru locale */
    abbrRu?: Maybe<Scalars["String"]>;
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Conversion factor */
    conversionFactor?: Maybe<Scalars["Float"]>;
    /** Conversion primary unit */
    conversionUnit?: Maybe<Unit>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Unit name */
    name: Scalars["String"];
    /** Unit name in En locale */
    nameEn?: Maybe<Scalars["String"]>;
    /** Unit name in Es locale */
    nameEs?: Maybe<Scalars["String"]>;
    /** Unit name in Pt locale */
    namePt?: Maybe<Scalars["String"]>;
    /** Unit name in Ru locale */
    nameRu?: Maybe<Scalars["String"]>;
    /** Is unit primary? */
    primary: Scalars["Boolean"];
    /** Unit type */
    unitType: UnitType;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Unit changes object */
export type UnitChanges = {
  /** Unit abbreviation */
  abbr?: InputMaybe<Scalars["String"]>;
  /** Unit abbreviation in En locale */
  abbrEn?: InputMaybe<Scalars["String"]>;
  /** Unit abbreviation in Es locale */
  abbrEs?: InputMaybe<Scalars["String"]>;
  /** Unit abbreviation in Pt locale */
  abbrPt?: InputMaybe<Scalars["String"]>;
  /** Unit abbreviation in Ru locale */
  abbrRu?: InputMaybe<Scalars["String"]>;
  /** Conversion factor */
  conversionFactor?: InputMaybe<Scalars["Float"]>;
  /** Conversion unit */
  conversionUnitId?: InputMaybe<Scalars["ID"]>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Unit name */
  name?: InputMaybe<Scalars["String"]>;
  /** Unit name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Unit name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Unit name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Unit name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Is unit primary? */
  primary?: InputMaybe<Scalars["Boolean"]>;
  /** Unit type */
  unitType?: InputMaybe<UnitType>;
};

/** Autogenerated return type of UnitCreate. */
export type UnitCreatePayload = {
  __typename?: "UnitCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Unit>;
};

/** Autogenerated return type of UnitDiscard. */
export type UnitDiscardPayload = {
  __typename?: "UnitDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Unit>;
};

/** Unit field values */
export type UnitFields = {
  /** Unit abbreviation */
  abbr: Scalars["String"];
  /** Unit abbreviation in En locale */
  abbrEn?: InputMaybe<Scalars["String"]>;
  /** Unit abbreviation in Es locale */
  abbrEs?: InputMaybe<Scalars["String"]>;
  /** Unit abbreviation in Pt locale */
  abbrPt?: InputMaybe<Scalars["String"]>;
  /** Unit abbreviation in Ru locale */
  abbrRu?: InputMaybe<Scalars["String"]>;
  /** Conversion factor */
  conversionFactor?: InputMaybe<Scalars["Float"]>;
  /** Conversion unit */
  conversionUnitId?: InputMaybe<Scalars["ID"]>;
  /** Is global? */
  global: Scalars["Boolean"];
  /** Is locked */
  locked: Scalars["Boolean"];
  /** Unit name */
  name: Scalars["String"];
  /** Unit name in En locale */
  nameEn?: InputMaybe<Scalars["String"]>;
  /** Unit name in Es locale */
  nameEs?: InputMaybe<Scalars["String"]>;
  /** Unit name in Pt locale */
  namePt?: InputMaybe<Scalars["String"]>;
  /** Unit name in Ru locale */
  nameRu?: InputMaybe<Scalars["String"]>;
  /** Is unit primary? */
  primary: Scalars["Boolean"];
  /** Unit type */
  unitType: UnitType;
};

export enum UnitType {
  /** Area */
  Area = "area",
  /** Distance */
  Distance = "distance",
  /** Speed */
  Speed = "speed",
  /** Temperature */
  Temperature = "temperature",
  /** Time */
  Time = "time",
  /** Unitary */
  Unitary = "unitary",
  /** Volume */
  Volume = "volume",
  /** Weight */
  Weight = "weight",
}

/** Autogenerated return type of UnitUnlock. */
export type UnitUnlockPayload = {
  __typename?: "UnitUnlockPayload";
  errors: Array<UserError>;
  result?: Maybe<Unit>;
};

/** Autogenerated return type of UnitUpdate. */
export type UnitUpdatePayload = {
  __typename?: "UnitUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Unit>;
};

/** Collection of Units */
export type Units = {
  __typename?: "Units";
  /** Paginated collection of Units */
  items: Array<Unit>;
  /** Total count of Units */
  totalCount: Scalars["Int"];
};

/** Units filter */
export type UnitsFilterInput = {
  /** Unit abbreviation */
  abbr?: InputMaybe<StringMatchInput>;
  /** Unit abbreviation in En locale */
  abbrEn?: InputMaybe<StringMatchInput>;
  /** Unit abbreviation in Es locale */
  abbrEs?: InputMaybe<StringMatchInput>;
  /** Unit abbreviation in Pt locale */
  abbrPt?: InputMaybe<StringMatchInput>;
  /** Unit abbreviation in Ru locale */
  abbrRu?: InputMaybe<StringMatchInput>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  /** Unit name */
  name?: InputMaybe<StringMatchInput>;
  /** Unit name in En locale */
  nameEn?: InputMaybe<StringMatchInput>;
  /** Unit name in Es locale */
  nameEs?: InputMaybe<StringMatchInput>;
  /** Unit name in Pt locale */
  namePt?: InputMaybe<StringMatchInput>;
  /** Unit name in Ru locale */
  nameRu?: InputMaybe<StringMatchInput>;
  /** Is unit primary? */
  primary?: InputMaybe<Scalars["Boolean"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Unit type */
  unitType?: InputMaybe<Array<UnitType>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Units sorting options */
export type UnitsSortInput = {
  /** Unit abbreviation */
  abbr?: InputMaybe<SortDirection>;
  /** Unit abbreviation in En locale */
  abbrEn?: InputMaybe<SortDirection>;
  /** Unit abbreviation in Es locale */
  abbrEs?: InputMaybe<SortDirection>;
  /** Unit abbreviation in Pt locale */
  abbrPt?: InputMaybe<SortDirection>;
  /** Unit abbreviation in Ru locale */
  abbrRu?: InputMaybe<SortDirection>;
  /** Conversion factor */
  conversionFactor?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  /** Unit name */
  name?: InputMaybe<SortDirection>;
  /** Unit name in En locale */
  nameEn?: InputMaybe<SortDirection>;
  /** Unit name in Es locale */
  nameEs?: InputMaybe<SortDirection>;
  /** Unit name in Pt locale */
  namePt?: InputMaybe<SortDirection>;
  /** Unit name in Ru locale */
  nameRu?: InputMaybe<SortDirection>;
  /** Is unit primary? */
  primary?: InputMaybe<SortDirection>;
  /** Unit type */
  unitType?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

/** Autogenerated return type of UpdateMutation. */
export type UpdateMutationPayload = {
  __typename?: "UpdateMutationPayload";
  errors: Array<UserError>;
  jobId?: Maybe<Scalars["ID"]>;
  result?: Maybe<InventoryRequest>;
};

/** A single user object */
export type User = Discardable &
  Timestamps & {
    __typename?: "User";
    admin: Scalars["Boolean"];
    avatar?: Maybe<Scalars["Upload"]>;
    canDiscard: AuthorizationResult;
    canInvite: AuthorizationResult;
    canUpdate: AuthorizationResult;
    canUpdateLocalities: AuthorizationResult;
    canUpdateRoles: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    email: Scalars["String"];
    /** @deprecated Removed from user */
    employee?: Maybe<Employee>;
    firstName?: Maybe<Scalars["String"]>;
    id: Scalars["ID"];
    invitationAcceptedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    invitationSentAt?: Maybe<Scalars["ISO8601DateTime"]>;
    lastName?: Maybe<Scalars["String"]>;
    /** User localities */
    localities: Array<Locality>;
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    permissions: Permissions;
    roles: Array<Role>;
    settings: Scalars["JSON"];
    tenants: Array<Tenant>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    username?: Maybe<Scalars["String"]>;
  };

/** A single user object */
export type UserAvatarArgs = {
  variant?: InputMaybe<ImageVariant>;
};

/** User changes object */
export type UserChanges = {
  avatar?: InputMaybe<Scalars["Upload"]>;
  email?: InputMaybe<Scalars["String"]>;
  /** Employee */
  employeeId?: InputMaybe<Scalars["ID"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  invitationAcceptedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  invitationSentAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  /** User localities */
  localityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** List of user role ids */
  roleIds?: InputMaybe<Array<Scalars["ID"]>>;
  settings?: InputMaybe<Scalars["JSON"]>;
  /** List of tenants ids */
  tenantIds?: InputMaybe<Array<Scalars["ID"]>>;
  username?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UserCreate. */
export type UserCreatePayload = {
  __typename?: "UserCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<User>;
};

/** Autogenerated return type of UserDiscard. */
export type UserDiscardPayload = {
  __typename?: "UserDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<User>;
};

/** A user-readable error */
export type UserError = {
  __typename?: "UserError";
  /** Translated attribute name */
  attribute?: Maybe<Scalars["String"]>;
  /** Allowed value in validation (ex: greater_than_or_equal_to: 0) */
  count?: Maybe<Scalars["String"]>;
  /** A description of the error */
  message: Scalars["String"];
  /** Which input value this error came from */
  path: Scalars["String"];
  /** Value of input */
  value?: Maybe<Scalars["String"]>;
};

/** User field values */
export type UserFields = {
  avatar?: InputMaybe<Scalars["Upload"]>;
  email: Scalars["String"];
  /** Employee */
  employeeId?: InputMaybe<Scalars["ID"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  invitationAcceptedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  invitationSentAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  /** User localities */
  localityIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** List of user role ids */
  roleIds: Array<Scalars["ID"]>;
  username?: InputMaybe<Scalars["String"]>;
};

/** User invitation input values */
export type UserInvitationInput = {
  /** Email */
  email: Scalars["String"];
  /** Name */
  username?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of UserUpdate. */
export type UserUpdatePayload = {
  __typename?: "UserUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<User>;
};

/** Collection of Users */
export type Users = {
  __typename?: "Users";
  /** Paginated collection of Users */
  items: Array<User>;
  /** Total count of Users */
  totalCount: Scalars["Int"];
};

/** Users filter */
export type UsersFilterInput = {
  admin?: InputMaybe<Scalars["Boolean"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  email?: InputMaybe<StringMatchInput>;
  firstName?: InputMaybe<StringMatchInput>;
  /** User full name */
  fullName?: InputMaybe<Scalars["String"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  invitationAcceptedAt?: InputMaybe<DateRangeInput>;
  invitationSentAt?: InputMaybe<DateRangeInput>;
  lastName?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Tenant */
  tenantId?: InputMaybe<Scalars["ID"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  username?: InputMaybe<StringMatchInput>;
};

/** Users sorting options */
export type UsersSortInput = {
  admin?: InputMaybe<SortDirection>;
  avatar?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  firstName?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  invitationAcceptedAt?: InputMaybe<SortDirection>;
  invitationSentAt?: InputMaybe<SortDirection>;
  lastName?: InputMaybe<SortDirection>;
  settings?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

/** Inventory Item Variant */
export type Variant = Destroyable &
  Discardable & {
    __typename?: "Variant";
    /** Item Variant cost */
    cost: Scalars["Float"];
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    internalId?: Maybe<Scalars["String"]>;
    /** Variant item */
    item: Item;
    /** May destroy? */
    mayDestroy: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** May update variation value */
    mayUpdateVariationValue: Scalars["Boolean"];
    name: Scalars["String"];
    /** Variant stock */
    stock: Stock;
    /** Token attributes */
    token?: Maybe<Token>;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Variation unit (weight, size, ...) */
    variationUnit: Unit;
    /** Variation value */
    variationValue: Scalars["Float"];
  };

/** Inventory Item Variant */
export type VariantCostArgs = {
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** Inventory Item Variant */
export type VariantStockArgs = {
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  localityId?: InputMaybe<Scalars["ID"]>;
  locationId?: InputMaybe<Scalars["ID"]>;
  locationType?: InputMaybe<LocationType>;
  virtual?: InputMaybe<VirtualWarehouse>;
};

/** Variant changes object */
export type VariantChanges = {
  /** Remove variant */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Discard */
  discardedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Variant ID */
  id?: InputMaybe<Scalars["ID"]>;
  internalId?: InputMaybe<Scalars["String"]>;
  /** Variation unit */
  variationUnitId?: InputMaybe<Scalars["ID"]>;
  /** Variation value */
  variationValue?: InputMaybe<Scalars["Float"]>;
};

/** Variant field values */
export type VariantFields = {
  internalId?: InputMaybe<Scalars["String"]>;
  /** Variation unit */
  variationUnitId: Scalars["ID"];
  /** Variation value */
  variationValue: Scalars["Float"];
};

/** Collection of Variants */
export type Variants = {
  __typename?: "Variants";
  /** Paginated collection of Variants */
  items: Array<Variant>;
  /** Total count of Variants */
  totalCount: Scalars["Int"];
};

/** Variants filter */
export type VariantsFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Variant, item, categories name, internal id */
  fullName?: InputMaybe<StringMatchInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Ingredient */
  ingredientId?: InputMaybe<Array<Scalars["ID"]>>;
  internalId?: InputMaybe<StringMatchInput>;
  /** Item category */
  itemCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item */
  itemId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Item kinds */
  itemKind?: InputMaybe<Array<ItemKind>>;
  /** May update variation value */
  mayUpdateVariationValue?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<StringMatchInput>;
  /** Variant names */
  names?: InputMaybe<Array<Scalars["String"]>>;
  /** Not available in warehouse */
  noStockBalance?: InputMaybe<Scalars["ID"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Is token? */
  token?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Available in warehouse */
  warehouseId?: InputMaybe<Scalars["ID"]>;
};

/** Variants sorting options */
export type VariantsSortInput = {
  /** Item Variant cost */
  cost?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  internalId?: InputMaybe<SortDirection>;
  /** May update variation value */
  mayUpdateVariationValue?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Variation value */
  variationValue?: InputMaybe<SortDirection>;
};

export enum VirtualWarehouse {
  /** Employees */
  Employees = "Employees",
  /** Reserved */
  Reserved = "Reserved",
}

/** Warehouse object */
export type Warehouse = Discardable &
  Profitable & {
    __typename?: "Warehouse";
    /** Cost Center */
    costCenter?: Maybe<CostCenter>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    id: Scalars["ID"];
    /** Infrastructure */
    infrastructure?: Maybe<Infrastructure>;
    /** Locality */
    locality: Locality;
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    /** Warehouse name */
    name: Scalars["String"];
    /** Variant stock */
    stock: Stock;
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Warehouse object */
export type WarehouseStockArgs = {
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  unitId?: InputMaybe<Scalars["ID"]>;
  variantId: Scalars["ID"];
};

/** Warehouse changes object */
export type WarehouseChanges = {
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Warehouse name */
  name?: InputMaybe<Scalars["String"]>;
};

/** Autogenerated return type of WarehouseCreate. */
export type WarehouseCreatePayload = {
  __typename?: "WarehouseCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<Warehouse>;
};

/** Autogenerated return type of WarehouseDiscard. */
export type WarehouseDiscardPayload = {
  __typename?: "WarehouseDiscardPayload";
  errors: Array<UserError>;
  result?: Maybe<Warehouse>;
};

/** Warehouse field values */
export type WarehouseFields = {
  /** Default Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Locality */
  localityId: Scalars["ID"];
  /** Warehouse name */
  name: Scalars["String"];
};

/** Autogenerated return type of WarehouseUpdate. */
export type WarehouseUpdatePayload = {
  __typename?: "WarehouseUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<Warehouse>;
};

/** Collection of Warehouses */
export type Warehouses = {
  __typename?: "Warehouses";
  /** Paginated collection of Warehouses */
  items: Array<Warehouse>;
  /** Total count of Warehouses */
  totalCount: Scalars["Int"];
};

/** Warehouses filter */
export type WarehousesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Warehouse name */
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Skip scoping by localities */
  skipLocalityScope?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** Warehouses sorting options */
export type WarehousesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Warehouse name */
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export type WaterSource = Discardable &
  Global & {
    __typename?: "WaterSource";
    canDelete: AuthorizationResult;
    canUnlock: AuthorizationResult;
    canUpdate: AuthorizationResult;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Discarded timestamp */
    discardedAt?: Maybe<Scalars["ISO8601DateTime"]>;
    /** Is global? */
    global: Scalars["Boolean"];
    id: Scalars["ID"];
    /** Is locked */
    locked: Scalars["Boolean"];
    /** May discard? */
    mayDiscard: Scalars["Boolean"];
    name: Scalars["String"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
  };

/** Collection of WaterSources */
export type WaterSources = {
  __typename?: "WaterSources";
  /** Paginated collection of WaterSources */
  items: Array<WaterSource>;
  /** Total count of WaterSources */
  totalCount: Scalars["Int"];
};

/** WaterSources filter */
export type WaterSourcesFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<DateRangeInput>;
  /** Is global? */
  global?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Is locked */
  locked?: InputMaybe<Scalars["Boolean"]>;
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
};

/** WaterSources sorting options */
export type WaterSourcesSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Discarded timestamp */
  discardedAt?: InputMaybe<SortDirection>;
  /** Is global? */
  global?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Is locked */
  locked?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
};

export enum WeatherMeasurableType {
  /** Crop Field */
  CropField = "cropField",
  /** Infrastructure */
  Infrastructure = "infrastructure",
}

/** Weather measurement object */
export type WeatherMeasurement = Destroyable & {
  __typename?: "WeatherMeasurement";
  /**
   * Crop field
   * @deprecated Use measurable field instead
   */
  cropField?: Maybe<CropField>;
  /** Humidity */
  humidity?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Latitude */
  latitude?: Maybe<Scalars["Float"]>;
  /** Farm */
  locality: Locality;
  /** Longitude */
  longitude?: Maybe<Scalars["Float"]>;
  /** May destroy? */
  mayDestroy: Scalars["Boolean"];
  /** Measurable ID */
  measurableId?: Maybe<Scalars["ID"]>;
  /** Measurable name */
  measurableName?: Maybe<Scalars["String"]>;
  /** Measurable type */
  measurableType?: Maybe<WeatherMeasurableType>;
  /** Measured At */
  measuredAt: Scalars["ISO8601DateTime"];
  /** Notes */
  notes?: Maybe<Scalars["String"]>;
  /** Rainfall */
  rainfall?: Maybe<Scalars["Float"]>;
  /** Temperature */
  temperature?: Maybe<Scalars["Float"]>;
  /** UV index */
  uvIndex?: Maybe<Scalars["Float"]>;
  /** Wind speed */
  windSpeed?: Maybe<Scalars["Float"]>;
};

/** WeatherMeasurement changes object */
export type WeatherMeasurementChanges = {
  /** Crop Field */
  cropFieldId?: InputMaybe<Scalars["ID"]>;
  /** Humidity */
  humidity?: InputMaybe<Scalars["Float"]>;
  /** Latitude */
  latitude?: InputMaybe<Scalars["Float"]>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Longitude */
  longitude?: InputMaybe<Scalars["Float"]>;
  /** Measurable ID */
  measurableId?: InputMaybe<Scalars["ID"]>;
  /** Measurable name */
  measurableName?: InputMaybe<Scalars["String"]>;
  /** Measurable type */
  measurableType?: InputMaybe<WeatherMeasurableType>;
  /** Measured At */
  measuredAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Notes */
  notes?: InputMaybe<Scalars["String"]>;
  /** Rainfall */
  rainfall?: InputMaybe<Scalars["Float"]>;
  /** Temperature */
  temperature?: InputMaybe<Scalars["Float"]>;
  /** UV index */
  uvIndex?: InputMaybe<Scalars["Float"]>;
  /** Wind speed */
  windSpeed?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of WeatherMeasurementCreate. */
export type WeatherMeasurementCreatePayload = {
  __typename?: "WeatherMeasurementCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<WeatherMeasurement>;
};

/** Autogenerated return type of WeatherMeasurementDestroy. */
export type WeatherMeasurementDestroyPayload = {
  __typename?: "WeatherMeasurementDestroyPayload";
  errors: Array<UserError>;
  result?: Maybe<Scalars["Boolean"]>;
};

/** WeatherMeasurement field values */
export type WeatherMeasurementFields = {
  /** Crop Field */
  cropFieldId?: InputMaybe<Scalars["ID"]>;
  /** Humidity */
  humidity?: InputMaybe<Scalars["Float"]>;
  /** Latitude */
  latitude?: InputMaybe<Scalars["Float"]>;
  /** Locality */
  localityId: Scalars["ID"];
  /** Longitude */
  longitude?: InputMaybe<Scalars["Float"]>;
  /** Measurable ID */
  measurableId?: InputMaybe<Scalars["ID"]>;
  /** Measurable name */
  measurableName?: InputMaybe<Scalars["String"]>;
  /** Measurable type */
  measurableType?: InputMaybe<WeatherMeasurableType>;
  /** Measured At */
  measuredAt: Scalars["ISO8601DateTime"];
  /** Notes */
  notes?: InputMaybe<Scalars["String"]>;
  /** Rainfall */
  rainfall?: InputMaybe<Scalars["Float"]>;
  /** Temperature */
  temperature?: InputMaybe<Scalars["Float"]>;
  /** UV index */
  uvIndex?: InputMaybe<Scalars["Float"]>;
  /** Wind speed */
  windSpeed?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of WeatherMeasurementUpdate. */
export type WeatherMeasurementUpdatePayload = {
  __typename?: "WeatherMeasurementUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<WeatherMeasurement>;
};

/** Collection of WeatherMeasurements */
export type WeatherMeasurements = {
  __typename?: "WeatherMeasurements";
  /** Paginated collection of WeatherMeasurements */
  items: Array<WeatherMeasurement>;
  /** Total count of WeatherMeasurements */
  totalCount: Scalars["Int"];
};

/** Weather overview object */
export type WeatherMeasurementsAggregated = {
  __typename?: "WeatherMeasurementsAggregated";
  /** Measured at */
  measuredAt?: Maybe<Scalars["ISO8601Date"]>;
  /** Rainfall */
  rainfall?: Maybe<Scalars["Float"]>;
};

/** WeatherMeasurements series filter */
export type WeatherMeasurementsAggregatedFilterInput = {
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Measurable location */
  measurableLocation?: InputMaybe<
    Array<WeatherMeasurementsMeasurableLocationInput>
  >;
  /** Measured At */
  measuredAt?: InputMaybe<DateRangeInput>;
  /** Measured At */
  years?: InputMaybe<Array<Scalars["Int"]>>;
};

export type WeatherMeasurementsAggregatedResult = {
  __typename?: "WeatherMeasurementsAggregatedResult";
  items: Array<WeatherMeasurementsAggregated>;
  minYear?: Maybe<Scalars["Int"]>;
};

/** WeatherMeasurements filter */
export type WeatherMeasurementsFilterInput = {
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Measurable location */
  measurableLocation?: InputMaybe<
    Array<WeatherMeasurementsMeasurableLocationInput>
  >;
  /** Measured At */
  measuredAt?: InputMaybe<DateRangeInput>;
};

/** WeatherMeasurements measurable location */
export type WeatherMeasurementsMeasurableLocationInput = {
  /** Measurable ID */
  measurableId: Scalars["ID"];
  /** Measurable type */
  measurableType: WeatherMeasurableType;
};

/** WeatherMeasurements sorting options */
export type WeatherMeasurementsSortInput = {
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Humidity */
  humidity?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Latitude */
  latitude?: InputMaybe<SortDirection>;
  /** Longitude */
  longitude?: InputMaybe<SortDirection>;
  /** Measurable ID */
  measurableId?: InputMaybe<SortDirection>;
  /** Measurable name */
  measurableName?: InputMaybe<SortDirection>;
  /** Measurable type */
  measurableType?: InputMaybe<SortDirection>;
  /** Measured At */
  measuredAt?: InputMaybe<SortDirection>;
  /** Notes */
  notes?: InputMaybe<SortDirection>;
  /** Rainfall */
  rainfall?: InputMaybe<SortDirection>;
  /** Temperature */
  temperature?: InputMaybe<SortDirection>;
  /** UV index */
  uvIndex?: InputMaybe<SortDirection>;
  /** Wind speed */
  windSpeed?: InputMaybe<SortDirection>;
};

/** Weather overview object */
export type WeatherOverview = {
  __typename?: "WeatherOverview";
  /** Avg humidity */
  avgHumidity?: Maybe<Scalars["Float"]>;
  /** Avg rainfall */
  avgRainfall?: Maybe<Scalars["Float"]>;
  /** Avg temperature */
  avgTemperature?: Maybe<Scalars["Float"]>;
  /** Avg UV index */
  avgUvIndex?: Maybe<Scalars["Int"]>;
  /** Number of dry days */
  dryDays?: Maybe<Scalars["Int"]>;
  /** Max humidity */
  maxHumidity?: Maybe<Scalars["Float"]>;
  /** Max rainfall */
  maxRainfall?: Maybe<Scalars["Float"]>;
  /** Max temperature */
  maxTemperature?: Maybe<Scalars["Float"]>;
  /** Max UV index */
  maxUvIndex?: Maybe<Scalars["Int"]>;
  /** Min humidity */
  minHumidity?: Maybe<Scalars["Float"]>;
  /** Min rainfall */
  minRainfall?: Maybe<Scalars["Float"]>;
  /** Min temperature */
  minTemperature?: Maybe<Scalars["Float"]>;
  /** Min UV index */
  minUvIndex?: Maybe<Scalars["Int"]>;
  /** Number of rainy days */
  rainyDays?: Maybe<Scalars["Int"]>;
  /** Total rainfall */
  totalRainfall?: Maybe<Scalars["Float"]>;
};

/** Week day object */
export type WeekDay = Timestamps & {
  __typename?: "WeekDay";
  /** Creation timestamp */
  createdAt: Scalars["ISO8601DateTime"];
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  id: Scalars["ID"];
  name: Scalars["String"];
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Last update timestamp */
  updatedAt: Scalars["ISO8601DateTime"];
  working: Scalars["Boolean"];
};

/** WeekDay changes object */
export type WeekDayChanges = {
  endTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** ID */
  id?: InputMaybe<Scalars["ID"]>;
  name?: InputMaybe<Scalars["String"]>;
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  working?: InputMaybe<Scalars["Boolean"]>;
};

/** Collection of WeekDays */
export type WeekDays = {
  __typename?: "WeekDays";
  /** Paginated collection of WeekDays */
  items: Array<WeekDay>;
  /** Total count of WeekDays */
  totalCount: Scalars["Int"];
};

/** WeekDays filter */
export type WeekDaysFilterInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  endTime?: InputMaybe<DateRangeInput>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  name?: InputMaybe<StringMatchInput>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  startTime?: InputMaybe<DateRangeInput>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  working?: InputMaybe<Scalars["Boolean"]>;
};

/** WeekDays sorting options */
export type WeekDaysSortInput = {
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  endTime?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startTime?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  working?: InputMaybe<SortDirection>;
};

/** Work order object */
export type WorkOrder = Auditable &
  Commentable &
  Timestamps & {
    __typename?: "WorkOrder";
    /** Activity */
    activity: Activity;
    /** Area to progress unit conversion factor */
    areaConversionFactor: Scalars["Float"];
    /** Assigned to user */
    assignedTo?: Maybe<User>;
    /** Comments */
    comments: Array<Comment>;
    /** Cost centers */
    costCenters: Array<WorkOrderCostCenter>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by user */
    createdBy: User;
    /** Crop cycle */
    cropCycle?: Maybe<CropCycle>;
    /** Crop field group */
    cropFieldGroup?: Maybe<Scalars["String"]>;
    /** Cycle number */
    cycleNumber?: Maybe<Scalars["Int"]>;
    /** Distribute Cost Centers Manually */
    distributeManually?: Maybe<Scalars["Boolean"]>;
    /** Document date */
    documentDate: Scalars["ISO8601DateTime"];
    /** Employees attendance and progress */
    employees: Array<WorkOrderEmployee>;
    /** Equipment */
    equipment: Array<Equipment>;
    /** Estimated norm */
    estimatedNorm?: Maybe<Scalars["Float"]>;
    /** Outputs Harvest By Outputs */
    harvestByOutput?: Maybe<Scalars["Boolean"]>;
    id: Scalars["ID"];
    /** Inputs */
    inputs: Array<WorkOrderVariant>;
    /** Internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Inventory status */
    inventoryStatus: InventoryStatus;
    /** Locality */
    locality: Locality;
    /** Machines */
    machines: Array<WorkOrderMachine>;
    /** Work order metrics */
    metrics: Array<WorkOrderMetric>;
    /** Outputs */
    outputs: Array<WorkOrderVariant>;
    /** Related planning */
    planning?: Maybe<Planning>;
    /** Employee Progress By Employee */
    progressByEmployee?: Maybe<Scalars["Boolean"]>;
    /** Machine Progress By Machine */
    progressByMachineGroup?: Maybe<Scalars["Boolean"]>;
    /** Inputs Requests By Totals */
    requestByTotal?: Maybe<Scalars["Boolean"]>;
    /** Work order status */
    status: WorkOrderStatus;
    /** Work order summary */
    summary?: Maybe<WorkOrderSummary>;
    /** Tokens */
    tokens: Array<WorkOrderVariant>;
    /** Tools */
    tools: Array<WorkOrderVariant>;
    /** Total progress */
    totalProgress: Scalars["Float"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
    /** Inputs Usage Manually */
    usageByManual?: Maybe<Scalars["Boolean"]>;
    /** Water usage */
    waterUsage?: Maybe<WorkOrderWaterUsage>;
    /** Week number */
    weekNumber?: Maybe<Scalars["Int"]>;
    /** Manual worker labor cost */
    workerCost?: Maybe<Scalars["Float"]>;
    /** Worker count */
    workerCount?: Maybe<Scalars["Int"]>;
    /** Manual worker wage */
    workerWage?: Maybe<Scalars["Float"]>;
  };

export enum WorkOrderCategory {
  /** Agricultural Orders */
  Agricultural = "agricultural",
  /** General Work Orders */
  General = "general",
  /** Infrastructural Orders */
  Infrastructural = "infrastructural",
  /** Fleet & Machinery Orders */
  Machinery = "machinery",
}

/** Work Order Category Types */
export type WorkOrderCategoryTypes = {
  __typename?: "WorkOrderCategoryTypes";
  name: WorkOrderCategory;
  /** Work Order Types */
  workOrderTypes: Array<WorkOrderType>;
};

/** WorkOrder changes object */
export type WorkOrderChanges = {
  /** Activity */
  activityId?: InputMaybe<Scalars["ID"]>;
  /** Assigned To */
  assignedToId?: InputMaybe<Scalars["ID"]>;
  /** Cost centers */
  costCentersAttributes?: InputMaybe<Array<WorkOrderCostCenterChanges>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  /** Distribute Cost Centers Manually */
  distributeManually?: InputMaybe<Scalars["Boolean"]>;
  /** Document date */
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Employees */
  employeesAttributes?: InputMaybe<Array<WorkOrderEmployeeChanges>>;
  /** Equipment */
  equipmentIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Estimated norm */
  estimatedNorm?: InputMaybe<Scalars["Float"]>;
  /** Outputs Harvest By Outputs */
  harvestByOutput?: InputMaybe<Scalars["Boolean"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Inventory status */
  inventoryStatus?: InputMaybe<InventoryStatus>;
  /** Locality */
  localityId?: InputMaybe<Scalars["ID"]>;
  /** Machines */
  machinesAttributes?: InputMaybe<Array<WorkOrderMachineChanges>>;
  /** Employee Progress By Employee */
  progressByEmployee?: InputMaybe<Scalars["Boolean"]>;
  /** Machine Progress By Machine */
  progressByMachineGroup?: InputMaybe<Scalars["Boolean"]>;
  /** Inputs Requests By Totals */
  requestByTotal?: InputMaybe<Scalars["Boolean"]>;
  /** Work order status */
  status?: InputMaybe<WorkOrderStatus>;
  /** Inputs Usage Manually */
  usageByManual?: InputMaybe<Scalars["Boolean"]>;
  /** Variants */
  variantsAttributes?: InputMaybe<Array<WorkOrderVariantChanges>>;
  /** Water Usage */
  waterUsageAttributes?: InputMaybe<WorkOrderWaterUsageChanges>;
  /** Week number */
  weekNumber?: InputMaybe<Scalars["Int"]>;
  /** Manual worker labor cost */
  workerCost?: InputMaybe<Scalars["Float"]>;
  /** Worker count */
  workerCount?: InputMaybe<Scalars["Int"]>;
  /** Manual worker wage */
  workerWage?: InputMaybe<Scalars["Float"]>;
};

/** Autogenerated return type of WorkOrderClone. */
export type WorkOrderClonePayload = {
  __typename?: "WorkOrderClonePayload";
  jobId: Scalars["ID"];
};

/** Work order cost center progress and estimations */
export type WorkOrderCostCenter = HasProfitable & {
  __typename?: "WorkOrderCostCenter";
  /** Actual progress */
  actualProgress?: Maybe<Scalars["Float"]>;
  /** Cost Center */
  costCenter: CostCenter;
  /** Crop Field */
  cropField?: Maybe<CropField>;
  /** Day goal */
  dayGoal: Scalars["Float"];
  /** Manual Labor Total Hours */
  employeeHours?: Maybe<Scalars["Float"]>;
  /** Employee progress */
  employees: Array<WorkOrderCostCenterEmployee>;
  /** Harvest estimated amount */
  estimatedHarvestAmount?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Infrastructure */
  infrastructure?: Maybe<Infrastructure>;
  /** Machine */
  machine?: Maybe<Machine>;
  /** Fleet & Machinery Total Hours */
  machineHours?: Maybe<Scalars["Float"]>;
  /** Machine progress */
  machines: Array<WorkOrderCostCenterMachine>;
  /** Metrics */
  metrics: Array<WorkOrderCostCenterMetric>;
  /** Planned progress */
  plannedProgress: Scalars["Float"];
  /** Related planning */
  planning?: Maybe<Planning>;
  /** Profitable extra info */
  profitableExtra?: Maybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: Maybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: Maybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: Maybe<ProfitableType>;
  /** Total progress */
  totalProgress: Scalars["Float"];
  /** Crop field dosages */
  variants: Array<WorkOrderCostCenterVariant>;
};

/** WorkOrderCostCenter changes object */
export type WorkOrderCostCenterChanges = {
  /** Remove cost center */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Cost center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Crop Field */
  cropFieldId?: InputMaybe<Scalars["ID"]>;
  /** Day goal */
  dayGoal?: InputMaybe<Scalars["Float"]>;
  /** Manual Labor Total Hours */
  employeeHours?: InputMaybe<Scalars["Float"]>;
  /** Employee progress */
  employeeProgress?: InputMaybe<Scalars["Float"]>;
  /** Employees progress */
  employeesAttributes?: InputMaybe<Array<WorkOrderCostCenterEmployeeChanges>>;
  /** Harvest estimated amount */
  estimatedHarvestAmount?: InputMaybe<Scalars["Float"]>;
  /** Work order crop field ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Fleet & Machinery Total Hours */
  machineHours?: InputMaybe<Scalars["Float"]>;
  /** Employee progress */
  machineProgress?: InputMaybe<Scalars["Float"]>;
  /** Crop field progress */
  machinesAttributes?: InputMaybe<Array<WorkOrderCostCenterMachineChanges>>;
  /** Crop field metrics */
  metricsAttributes?: InputMaybe<Array<WorkOrderCostCenterMetricChanges>>;
  /** Profitable extra info */
  profitableExtra?: InputMaybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: InputMaybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: InputMaybe<ProfitableType>;
  /** Variant dosage */
  variantsAttributes?: InputMaybe<Array<WorkOrderCostCenterVariantChanges>>;
};

/** Work order cost center employee progress */
export type WorkOrderCostCenterEmployee = {
  __typename?: "WorkOrderCostCenterEmployee";
  /** Employee ID */
  employeeId: Scalars["ID"];
  id: Scalars["ID"];
  /** Employee progress */
  progress: Scalars["Float"];
};

/** WorkOrderCostCenterEmployee changes object */
export type WorkOrderCostCenterEmployeeChanges = {
  /** Remove employee */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Employee ID */
  employeeId?: InputMaybe<Scalars["ID"]>;
  /** Work order crop field employee ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Employee progress */
  progress?: InputMaybe<Scalars["Float"]>;
};

/** WorkOrderCostCenterEmployee field values */
export type WorkOrderCostCenterEmployeeFields = {
  /** Employee ID */
  employeeId: Scalars["ID"];
  /** Employee progress */
  progress: Scalars["Float"];
};

/** WorkOrderCostCenter field values */
export type WorkOrderCostCenterFields = {
  /** Cost Center */
  costCenterId?: InputMaybe<Scalars["ID"]>;
  /** Crop Field */
  cropFieldId?: InputMaybe<Scalars["ID"]>;
  /** Day goal */
  dayGoal: Scalars["Float"];
  /** Manual Labor Total Hours */
  employeeHours?: InputMaybe<Scalars["Float"]>;
  /** Employee progress */
  employeesAttributes?: InputMaybe<Array<WorkOrderCostCenterEmployeeFields>>;
  /** Harvest estimated amount */
  estimatedHarvestAmount?: InputMaybe<Scalars["Float"]>;
  /** Fleet & Machinery Total Hours */
  machineHours?: InputMaybe<Scalars["Float"]>;
  /** Machines usage */
  machinesAttributes?: InputMaybe<Array<WorkOrderCostCenterMachineFields>>;
  /** Metrics */
  metricsAttributes?: InputMaybe<Array<WorkOrderCostCenterMetricFields>>;
  /** Profitable extra info */
  profitableExtra?: InputMaybe<Scalars["String"]>;
  /** Profitable ID (sub cost center) */
  profitableId?: InputMaybe<Scalars["ID"]>;
  /** Profitable name */
  profitableName?: InputMaybe<Scalars["String"]>;
  /** Profitable type */
  profitableType?: InputMaybe<ProfitableType>;
  /** Variants dosage */
  variantsAttributes?: InputMaybe<Array<WorkOrderCostCenterVariantFields>>;
};

/** Work order cost center machine progress */
export type WorkOrderCostCenterMachine = {
  __typename?: "WorkOrderCostCenterMachine";
  /** Machine hours */
  hours?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Machine ID */
  machineId: Scalars["ID"];
  /** Machine progress */
  progress: Scalars["Float"];
};

/** WorkOrderCostCenterMachine changes object */
export type WorkOrderCostCenterMachineChanges = {
  /** Remove machine */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Machine hours */
  hours?: InputMaybe<Scalars["Float"]>;
  /** Work order crop field machine ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Machine ID */
  machineId?: InputMaybe<Scalars["ID"]>;
  /** Machine progress */
  progress?: InputMaybe<Scalars["Float"]>;
};

/** WorkOrderCostCenterMachine field values */
export type WorkOrderCostCenterMachineFields = {
  /** Machine hours */
  hours?: InputMaybe<Scalars["Float"]>;
  /** Machine ID */
  machineId: Scalars["ID"];
  /** Machine progress */
  progress: Scalars["Float"];
};

/** Work order cost center metric */
export type WorkOrderCostCenterMetric = {
  __typename?: "WorkOrderCostCenterMetric";
  /** Employee ID */
  employeeId?: Maybe<Scalars["ID"]>;
  id: Scalars["ID"];
  /** Machine ID */
  machineId?: Maybe<Scalars["ID"]>;
  /** Metric ID */
  metricId: Scalars["ID"];
  /** Metric value */
  value: Scalars["Float"];
  /** Variant ID */
  variantId?: Maybe<Scalars["ID"]>;
};

/** WorkOrderCostCenterMetric changes object */
export type WorkOrderCostCenterMetricChanges = {
  /** Remove metric */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Employee ID */
  employeeId?: InputMaybe<Scalars["ID"]>;
  /** Work order crop field metric ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Machine ID */
  machineId?: InputMaybe<Scalars["ID"]>;
  /** Metric ID */
  metricId?: InputMaybe<Scalars["ID"]>;
  /** Metric value */
  value?: InputMaybe<Scalars["Float"]>;
  /** Variant ID */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** WorkOrderCostCenterMetric field values */
export type WorkOrderCostCenterMetricFields = {
  /** Employee ID */
  employeeId?: InputMaybe<Scalars["ID"]>;
  /** Machine ID */
  machineId?: InputMaybe<Scalars["ID"]>;
  /** Metric ID */
  metricId: Scalars["ID"];
  /** Metric value */
  value: Scalars["Float"];
  /** Variant ID */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** Work order crop field variant dosage */
export type WorkOrderCostCenterVariant = {
  __typename?: "WorkOrderCostCenterVariant";
  /** Crop field assigned amount */
  amount: Scalars["Float"];
  /** Crop field consumed amount */
  consumedAmount?: Maybe<Scalars["Float"]>;
  /** Application rate */
  dosage?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Variant ID */
  variantId: Scalars["ID"];
};

/** WorkOrderCostCenterVariant changes object */
export type WorkOrderCostCenterVariantChanges = {
  /** Remove variant */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Crop field assigned amount */
  amount?: InputMaybe<Scalars["Float"]>;
  /** Crop field consumed amount */
  consumedAmount?: InputMaybe<Scalars["Float"]>;
  /** Application rate */
  dosage?: InputMaybe<Scalars["Float"]>;
  /** Work order crop field variant ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Variant ID */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** WorkOrderCostCenterVariant field values */
export type WorkOrderCostCenterVariantFields = {
  /** Crop field assigned amount */
  amount: Scalars["Float"];
  /** Crop field consumed amount */
  consumedAmount?: InputMaybe<Scalars["Float"]>;
  /** Application rate */
  dosage?: InputMaybe<Scalars["Float"]>;
  /** Variant ID */
  variantId: Scalars["ID"];
};

/** Autogenerated return type of WorkOrderCreate. */
export type WorkOrderCreatePayload = {
  __typename?: "WorkOrderCreatePayload";
  errors: Array<UserError>;
  result?: Maybe<WorkOrder>;
};

/** Work Order Daily Report result */
export type WorkOrderDaily = Auditable &
  Commentable &
  Timestamps & {
    __typename?: "WorkOrderDaily";
    /** Activity */
    activity: Activity;
    /** Area to progress unit conversion factor */
    areaConversionFactor: Scalars["Float"];
    /** Assigned to user */
    assignedTo?: Maybe<User>;
    /** Comments */
    comments: Array<Comment>;
    /** WO Cost Center */
    costCenter?: Maybe<WorkOrderCostCenter>;
    /** Cost centers */
    costCenters: Array<WorkOrderCostCenter>;
    /** Creation timestamp */
    createdAt: Scalars["ISO8601DateTime"];
    /** Created by user */
    createdBy: User;
    /** Crop cycle */
    cropCycle?: Maybe<CropCycle>;
    /** Crop field group */
    cropFieldGroup?: Maybe<Scalars["String"]>;
    /** Cycle number */
    cycleNumber?: Maybe<Scalars["Int"]>;
    /** Distribute Cost Centers Manually */
    distributeManually?: Maybe<Scalars["Boolean"]>;
    /** Document date */
    documentDate: Scalars["ISO8601DateTime"];
    /** Employees attendance and progress */
    employees: Array<WorkOrderEmployee>;
    /** Equipment */
    equipment: Array<Equipment>;
    /** Estimated norm */
    estimatedNorm?: Maybe<Scalars["Float"]>;
    /** Outputs Harvest By Outputs */
    harvestByOutput?: Maybe<Scalars["Boolean"]>;
    /** Regular hours */
    hours: Scalars["Float"];
    id: Scalars["ID"];
    /** Inputs */
    inputs: Array<WorkOrderVariant>;
    /** Internal ID */
    internalId?: Maybe<Scalars["String"]>;
    /** Inventory Cost */
    inventoryCost: Scalars["Float"];
    /** Inventory status */
    inventoryStatus: InventoryStatus;
    /** Labor Cost */
    laborCost: Scalars["Float"];
    /** Labor Efficiency */
    laborEfficiency?: Maybe<Scalars["Float"]>;
    /** Labor qty */
    laborQty: Scalars["Float"];
    /** Locality */
    locality: Locality;
    /** Machine Efficiency */
    machineEfficiency?: Maybe<Scalars["Float"]>;
    /** Machine hours */
    machineHours?: Maybe<Scalars["Float"]>;
    /** Machine qty */
    machineQty?: Maybe<Scalars["Float"]>;
    /** Machines */
    machines: Array<WorkOrderMachine>;
    /** Work order metrics */
    metrics: Array<WorkOrderMetric>;
    /** Outputs */
    outputs: Array<WorkOrderVariant>;
    /** Overtime hours */
    overtimeHours: Scalars["Float"];
    /** Related planning */
    planning?: Maybe<Planning>;
    /** Total Progress */
    progress: Scalars["Float"];
    /** Employee Progress By Employee */
    progressByEmployee?: Maybe<Scalars["Boolean"]>;
    /** Machine Progress By Machine */
    progressByMachineGroup?: Maybe<Scalars["Boolean"]>;
    /** Inputs Requests By Totals */
    requestByTotal?: Maybe<Scalars["Boolean"]>;
    /** Work order status */
    status: WorkOrderStatus;
    /** Work order summary */
    summary?: Maybe<WorkOrderSummary>;
    /** Tokens */
    tokens: Array<WorkOrderVariant>;
    /** Tools */
    tools: Array<WorkOrderVariant>;
    /** Total Cost */
    totalCost: Scalars["Float"];
    /** Total progress */
    totalProgress: Scalars["Float"];
    /** Last update timestamp */
    updatedAt: Scalars["ISO8601DateTime"];
    /** Updated by */
    updatedBy?: Maybe<User>;
    /** Inputs Usage Manually */
    usageByManual?: Maybe<Scalars["Boolean"]>;
    /** Water usage */
    waterUsage?: Maybe<WorkOrderWaterUsage>;
    /** Week number */
    weekNumber?: Maybe<Scalars["Int"]>;
    /** Manual worker labor cost */
    workerCost?: Maybe<Scalars["Float"]>;
    /** Worker count */
    workerCount?: Maybe<Scalars["Int"]>;
    /** Manual worker wage */
    workerWage?: Maybe<Scalars["Float"]>;
  };

/** Collection of WorkOrderDailyReport */
export type WorkOrderDailyReport = {
  __typename?: "WorkOrderDailyReport";
  /** Paginated collection of WorkOrderDailyReport */
  items: Array<WorkOrderDaily>;
  /** Total count of WorkOrderDailyReport */
  totalCount: Scalars["Int"];
};

/** WorkOrderDailyReport filter */
export type WorkOrderDailyReportFilterInput = {
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity category */
  categoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Cost Center ID */
  costCenterId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Field Zone */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Crop Field ID */
  cropFieldId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop Stage ID */
  cropStageId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Show in currency */
  currencyCode?: InputMaybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Distribute Cost Centers Manually */
  distributeManually?: InputMaybe<Scalars["Boolean"]>;
  /** Date range */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Outputs Harvest By Outputs */
  harvestByOutput?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Infrastructure ID */
  infrastructureId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Inventory status */
  inventoryStatus?: InputMaybe<Array<InventoryStatus>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Machine ID */
  machineId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Employee Progress By Employee */
  progressByEmployee?: InputMaybe<Scalars["Boolean"]>;
  /** Machine Progress By Machine */
  progressByMachineGroup?: InputMaybe<Scalars["Boolean"]>;
  /** Inputs Requests By Totals */
  requestByTotal?: InputMaybe<Scalars["Boolean"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Show cost centers */
  showCostCenters?: InputMaybe<Scalars["Boolean"]>;
  /** Work order status */
  status?: InputMaybe<Array<WorkOrderStatus>>;
  /** Tags */
  tags?: InputMaybe<Array<Scalars["String"]>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Inputs Usage Manually */
  usageByManual?: InputMaybe<Scalars["Boolean"]>;
  /** Week number */
  weekNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Work Order type */
  workOrderType?: InputMaybe<Array<WorkOrderType>>;
  /** Worker count */
  workerCount?: InputMaybe<Array<Scalars["Int"]>>;
};

/** WorkOrderDailyReport sorting options */
export type WorkOrderDailyReportSortInput = {
  /** Area to progress unit conversion factor */
  areaConversionFactor?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<SortDirection>;
  /** Cycle number */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Distribute Cost Centers Manually */
  distributeManually?: InputMaybe<SortDirection>;
  /** Document date */
  documentDate?: InputMaybe<SortDirection>;
  /** Estimated norm */
  estimatedNorm?: InputMaybe<SortDirection>;
  /** Outputs Harvest By Outputs */
  harvestByOutput?: InputMaybe<SortDirection>;
  /** Regular hours */
  hours?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Inventory Cost */
  inventoryCost?: InputMaybe<SortDirection>;
  /** Inventory status */
  inventoryStatus?: InputMaybe<SortDirection>;
  /** Labor Cost */
  laborCost?: InputMaybe<SortDirection>;
  /** Labor Efficiency */
  laborEfficiency?: InputMaybe<SortDirection>;
  /** Labor qty */
  laborQty?: InputMaybe<SortDirection>;
  /** Machine Efficiency */
  machineEfficiency?: InputMaybe<SortDirection>;
  /** Machine hours */
  machineHours?: InputMaybe<SortDirection>;
  /** Machine qty */
  machineQty?: InputMaybe<SortDirection>;
  /** Overtime hours */
  overtimeHours?: InputMaybe<SortDirection>;
  /** Total Progress */
  progress?: InputMaybe<SortDirection>;
  /** Employee Progress By Employee */
  progressByEmployee?: InputMaybe<SortDirection>;
  /** Machine Progress By Machine */
  progressByMachineGroup?: InputMaybe<SortDirection>;
  /** Inputs Requests By Totals */
  requestByTotal?: InputMaybe<SortDirection>;
  /** Work order status */
  status?: InputMaybe<SortDirection>;
  /** Total Cost */
  totalCost?: InputMaybe<SortDirection>;
  /** Total progress */
  totalProgress?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Inputs Usage Manually */
  usageByManual?: InputMaybe<SortDirection>;
  /** Week number */
  weekNumber?: InputMaybe<SortDirection>;
  /** Manual worker labor cost */
  workerCost?: InputMaybe<SortDirection>;
  /** Worker count */
  workerCount?: InputMaybe<SortDirection>;
  /** Manual worker wage */
  workerWage?: InputMaybe<SortDirection>;
};

/** Work order employee attendance and progress */
export type WorkOrderEmployee = {
  __typename?: "WorkOrderEmployee";
  /** Attended? */
  attended: Scalars["Boolean"];
  /** Time conflict usage */
  conflictUsage?: Maybe<TimeConflict>;
  /** Employee */
  employee: Employee;
  /** Employee group */
  employeeGroup?: Maybe<EmployeeGroup>;
  /** Work end time */
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Regular work hours */
  hours: Scalars["Float"];
  id: Scalars["ID"];
  /** Leave Type */
  leaveType?: Maybe<LeaveType>;
  /** Overtime work hours */
  overtimeHours: Scalars["Float"];
  /** Work start time */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Employee wage payment */
  wagePayment?: Maybe<ActivityPayment>;
};

/** WorkOrderEmployee changes object */
export type WorkOrderEmployeeChanges = {
  /** Remove employee */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Attended? */
  attended?: InputMaybe<Scalars["Boolean"]>;
  /** Employee Group */
  employeeGroupId?: InputMaybe<Scalars["ID"]>;
  /** Employee */
  employeeId?: InputMaybe<Scalars["ID"]>;
  /** Work end time */
  endTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Regular work hours */
  hours?: InputMaybe<Scalars["Float"]>;
  /** Work order employee ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Leave type */
  leaveTypeId?: InputMaybe<Scalars["ID"]>;
  /** Overtime work hours */
  overtimeHours?: InputMaybe<Scalars["Float"]>;
  /** Work start time */
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** WorkOrderEmployee field values */
export type WorkOrderEmployeeFields = {
  /** Attended? */
  attended: Scalars["Boolean"];
  /** Employee group */
  employeeGroupId?: InputMaybe<Scalars["ID"]>;
  /** Employee */
  employeeId: Scalars["ID"];
  /** Leave type */
  leaveTypeId?: InputMaybe<Scalars["ID"]>;
  /** Work start time */
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
};

/** WorkOrder field values */
export type WorkOrderFields = {
  /** Activity */
  activityId: Scalars["ID"];
  /** Assigned to */
  assignedToId?: InputMaybe<Scalars["ID"]>;
  /** Cost Centers */
  costCentersAttributes: Array<WorkOrderCostCenterFields>;
  /** Crop Cycle */
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<Scalars["String"]>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Scalars["Int"]>;
  /** Distribute Cost Centers Manually */
  distributeManually?: InputMaybe<Scalars["Boolean"]>;
  /** Document date */
  documentDate: Scalars["ISO8601DateTime"];
  /** Employees */
  employeesAttributes: Array<WorkOrderEmployeeFields>;
  /** Equipment */
  equipmentIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Estimated norm */
  estimatedNorm?: InputMaybe<Scalars["Float"]>;
  /** Outputs Harvest By Outputs */
  harvestByOutput?: InputMaybe<Scalars["Boolean"]>;
  /** Internal ID */
  internalId?: InputMaybe<Scalars["String"]>;
  /** Locality */
  localityId: Scalars["ID"];
  /** Machines */
  machinesAttributes: Array<WorkOrderMachineFields>;
  /** Employee Progress By Employee */
  progressByEmployee?: InputMaybe<Scalars["Boolean"]>;
  /** Machine Progress By Machine */
  progressByMachineGroup?: InputMaybe<Scalars["Boolean"]>;
  /** Inputs Requests By Totals */
  requestByTotal?: InputMaybe<Scalars["Boolean"]>;
  /** Work order status */
  status: WorkOrderStatus;
  /** Inputs Usage Manually */
  usageByManual?: InputMaybe<Scalars["Boolean"]>;
  /** Variants */
  variantsAttributes: Array<WorkOrderVariantFields>;
  /** Water Usage */
  waterUsageAttributes?: InputMaybe<WorkOrderWaterUsageFields>;
  /** Week number */
  weekNumber?: InputMaybe<Scalars["Int"]>;
  /** Manual worker labor cost */
  workerCost?: InputMaybe<Scalars["Float"]>;
  /** Worker count */
  workerCount?: InputMaybe<Scalars["Int"]>;
  /** Manual worker wage */
  workerWage?: InputMaybe<Scalars["Float"]>;
};

/** Work order machinery */
export type WorkOrderMachine = {
  __typename?: "WorkOrderMachine";
  /**
   * End horometer
   * @deprecated Use work_hours
   */
  endHorometer?: Maybe<Scalars["Float"]>;
  /** End odometer */
  endOdometer?: Maybe<Scalars["Int"]>;
  /** Work end time */
  endTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Machine gear instruction */
  gear?: Maybe<Scalars["Int"]>;
  id: Scalars["ID"];
  /** Implement */
  implement?: Maybe<Machine>;
  /** Machine */
  machine: Machine;
  /** Machine nozzle size instruction */
  nozzle?: Maybe<Scalars["Int"]>;
  /** Operator employee */
  operator?: Maybe<Employee>;
  /** Machine pressure instruction */
  pressure?: Maybe<Scalars["Int"]>;
  /** Machine RPM instruction */
  rpm?: Maybe<Scalars["Int"]>;
  /** Machine speed instruction */
  speed?: Maybe<Scalars["Int"]>;
  /**
   * Start horometer
   * @deprecated Use work_hours
   */
  startHorometer?: Maybe<Scalars["Float"]>;
  /** Start odometer */
  startOdometer?: Maybe<Scalars["Int"]>;
  /** Work start time */
  startTime?: Maybe<Scalars["ISO8601DateTime"]>;
  /** Work hours */
  workHours: Scalars["Float"];
};

/** WorkOrderMachine changes object */
export type WorkOrderMachineChanges = {
  /** Remove machine */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** End horometer */
  endHorometer?: InputMaybe<Scalars["Float"]>;
  /** End odometer */
  endOdometer?: InputMaybe<Scalars["Int"]>;
  /** Work end time */
  endTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Machine gear instruction */
  gear?: InputMaybe<Scalars["Int"]>;
  /** Work order machine ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Implement */
  implementId?: InputMaybe<Scalars["ID"]>;
  /** Machine */
  machineId?: InputMaybe<Scalars["ID"]>;
  /** Machine nozzle size instruction */
  nozzle?: InputMaybe<Scalars["Int"]>;
  /** Operator */
  operatorId?: InputMaybe<Scalars["ID"]>;
  /** Machine pressure instruction */
  pressure?: InputMaybe<Scalars["Int"]>;
  /** Machine RPM instruction */
  rpm?: InputMaybe<Scalars["Int"]>;
  /** Machine speed instruction */
  speed?: InputMaybe<Scalars["Int"]>;
  /** Start horometer */
  startHorometer?: InputMaybe<Scalars["Float"]>;
  /** Start odometer */
  startOdometer?: InputMaybe<Scalars["Int"]>;
  /** Work start time */
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Work hours */
  workHours?: InputMaybe<Scalars["Float"]>;
};

/** WorkOrderMachine field values */
export type WorkOrderMachineFields = {
  /** End horometer */
  endHorometer?: InputMaybe<Scalars["Float"]>;
  /** End odometer */
  endOdometer?: InputMaybe<Scalars["Int"]>;
  /** Work end time */
  endTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Machine gear instruction */
  gear?: InputMaybe<Scalars["Int"]>;
  /** Machine */
  implementId?: InputMaybe<Scalars["ID"]>;
  /** Machine */
  machineId: Scalars["ID"];
  /** Machine nozzle size instruction */
  nozzle?: InputMaybe<Scalars["Int"]>;
  /** Operator */
  operatorId?: InputMaybe<Scalars["ID"]>;
  /** Machine pressure instruction */
  pressure?: InputMaybe<Scalars["Int"]>;
  /** Machine RPM instruction */
  rpm?: InputMaybe<Scalars["Int"]>;
  /** Machine speed instruction */
  speed?: InputMaybe<Scalars["Int"]>;
  /** Start horometer */
  startHorometer?: InputMaybe<Scalars["Float"]>;
  /** Start odometer */
  startOdometer?: InputMaybe<Scalars["Int"]>;
  /** Work start time */
  startTime?: InputMaybe<Scalars["ISO8601DateTime"]>;
  /** Work hours */
  workHours: Scalars["Float"];
};

/** Work order metric */
export type WorkOrderMetric = {
  __typename?: "WorkOrderMetric";
  id: Scalars["ID"];
  /** Metric */
  metric: Metric;
  /** Metric value */
  value: Scalars["Float"];
};

export enum WorkOrderStatus {
  /** Canceled */
  Canceled = "canceled",
  /** Completed */
  Completed = "completed",
  /** Completing */
  Completing = "completing",
  /** In_progress */
  InProgress = "inProgress",
  /** Open */
  Open = "open",
  /** Reverted */
  Reverted = "reverted",
  /** Reverting */
  Reverting = "reverting",
  /** Scheduled */
  Scheduled = "scheduled",
}

/** Work order summary */
export type WorkOrderSummary = {
  __typename?: "WorkOrderSummary";
  /** Absent labor count */
  absentCount: Scalars["Int"];
  /** Attended labor count */
  attendedCount: Scalars["Int"];
  /** Indirect man days */
  indirectManDays: Scalars["Float"];
  /** Input cost */
  inputCost: Scalars["Float"];
  /** Labor cost */
  laborCost: Scalars["Float"];
  /** Labor count */
  laborCount: Scalars["Int"];
  /** Labor Efficiency */
  laborEfficiency?: Maybe<Scalars["Float"]>;
  /** Labor hours */
  laborHours: Scalars["Float"];
  /** Labor man days */
  laborManDays: Scalars["Float"];
  /** Labor overtime hours */
  laborOvertimeHours: Scalars["Float"];
  /** Machine count */
  machineCount: Scalars["Int"];
  /** Machine Efficiency */
  machineEfficiency?: Maybe<Scalars["Float"]>;
  /** Machine hours */
  machineHours: Scalars["Float"];
  /** Payroll cost */
  payrollCost?: Maybe<Scalars["Float"]>;
  /** Total cost */
  totalCost: Scalars["Float"];
  /** Total goal */
  totalGoal: Scalars["Float"];
  /** Total progress */
  totalProgress: Scalars["Float"];
  /** Unit cost */
  unitCost?: Maybe<Scalars["Float"]>;
};

export enum WorkOrderType {
  /** Buildings */
  Buildings = "buildings",
  /** Field_work */
  FieldWork = "fieldWork",
  /** Harvest */
  Harvest = "harvest",
  /** Irrigation */
  Irrigation = "irrigation",
  /** Nursery */
  Nursery = "nursery",
  /** Other */
  Other = "other",
  /** Processing */
  Processing = "processing",
  /** Repair */
  Repair = "repair",
  /** Roads */
  Roads = "roads",
  /** Spraying */
  Spraying = "spraying",
  /** Transport */
  Transport = "transport",
}

/** Autogenerated return type of WorkOrderUpdate. */
export type WorkOrderUpdatePayload = {
  __typename?: "WorkOrderUpdatePayload";
  errors: Array<UserError>;
  result?: Maybe<WorkOrder>;
};

/** Work order variants */
export type WorkOrderVariant = {
  __typename?: "WorkOrderVariant";
  /** Application unit */
  applicationUnit?: Maybe<Unit>;
  /** Confirmed returned amount */
  confirmedReturnedAmount?: Maybe<Scalars["Float"]>;
  /** Conversion factor */
  conversionFactor?: Maybe<Scalars["Float"]>;
  /** Application rate */
  dosage?: Maybe<Scalars["Float"]>;
  id: Scalars["ID"];
  /** Return warehouse */
  returnWarehouse?: Maybe<Warehouse>;
  /** Amount to return */
  returnedAmount?: Maybe<Scalars["Float"]>;
  /** Source warehouse */
  sourceWarehouse?: Maybe<Warehouse>;
  /** Sources */
  sources?: Maybe<Array<WorkOrderVariantSource>>;
  /** Status */
  status: InventoryStatus;
  /** Source Warehouse stock */
  stock?: Maybe<Stock>;
  /** Variant total */
  totalAmount: Scalars["Float"];
  /** Variant unit */
  unit: Unit;
  /** Variant */
  variant: Variant;
  /** Work Order */
  workOrder: WorkOrder;
};

/** WorkOrderVariant changes object */
export type WorkOrderVariantChanges = {
  /** Remove variant */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Application unit */
  applicationUnitId?: InputMaybe<Scalars["ID"]>;
  /** Confirmed returned amount */
  confirmedReturnedAmount?: InputMaybe<Scalars["Float"]>;
  /** Application rate */
  dosage?: InputMaybe<Scalars["Float"]>;
  /** Work order variant ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Destination warehouse */
  returnWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Amount to return */
  returnedAmount?: InputMaybe<Scalars["Float"]>;
  /** Source warehouse */
  sourceWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Sources */
  sourcesAttributes?: InputMaybe<Array<WorkOrderVariantSourceChanges>>;
  /** Variant total */
  totalAmount?: InputMaybe<Scalars["Float"]>;
  /** Unit */
  unitId?: InputMaybe<Scalars["ID"]>;
  /** Variant */
  variantId?: InputMaybe<Scalars["ID"]>;
};

/** WorkOrderVariant field values */
export type WorkOrderVariantFields = {
  /** Application unit */
  applicationUnitId?: InputMaybe<Scalars["ID"]>;
  /** Confirmed returned amount */
  confirmedReturnedAmount?: InputMaybe<Scalars["Float"]>;
  /** Application rate */
  dosage?: InputMaybe<Scalars["Float"]>;
  /** Destination warehouse */
  returnWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Amount to return */
  returnedAmount?: InputMaybe<Scalars["Float"]>;
  /** Source warehouse */
  sourceWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Sources */
  sourcesAttributes?: InputMaybe<Array<WorkOrderVariantSourceFields>>;
  /** Variant total */
  totalAmount: Scalars["Float"];
  /** Unit */
  unitId: Scalars["ID"];
  /** Variant */
  variantId: Scalars["ID"];
};

/** Work order variant source */
export type WorkOrderVariantSource = {
  __typename?: "WorkOrderVariantSource";
  /** Source amount */
  amount: Scalars["Float"];
  id: Scalars["ID"];
  /** Source */
  sourceWorkOrderVariant: WorkOrderVariant;
  /** Target */
  targetWorkOrderVariant: WorkOrderVariant;
};

/** WorkOrderVariantSource changes object */
export type WorkOrderVariantSourceChanges = {
  /** Remove source */
  _destroy?: InputMaybe<Scalars["Boolean"]>;
  /** Source amount */
  amount?: InputMaybe<Scalars["Float"]>;
  /** Work order variant source ID */
  id?: InputMaybe<Scalars["ID"]>;
  /** Source */
  sourceWorkOrderVariantId?: InputMaybe<Scalars["ID"]>;
};

/** WorkOrderVariantSource field values */
export type WorkOrderVariantSourceFields = {
  /** Source amount */
  amount: Scalars["Float"];
  /** Source */
  sourceWorkOrderVariantId: Scalars["ID"];
};

/** Work order water usage */
export type WorkOrderWaterUsage = {
  __typename?: "WorkOrderWaterUsage";
  /** Application unit */
  applicationUnit: Unit;
  id: Scalars["ID"];
  /** Output rate */
  outputRate: Scalars["Float"];
  /** Output unit */
  outputUnit: Unit;
  /** Tank capacity */
  tankCapacity: Scalars["Float"];
  /** Tanks required */
  tanksRequired: Scalars["Float"];
  /** Tanks used */
  tanksUsed?: Maybe<Scalars["Float"]>;
  /** Water amount */
  waterAmount: Scalars["Float"];
  /** Water sources */
  waterSources: Array<WaterSource>;
  /** Water unit */
  waterUnit: Unit;
};

/** WorkOrderWaterUsage changes object */
export type WorkOrderWaterUsageChanges = {
  /** Application Unit */
  applicationUnitId?: InputMaybe<Scalars["ID"]>;
  /** Output rate */
  outputRate?: InputMaybe<Scalars["Float"]>;
  /** Output Unit */
  outputUnitId?: InputMaybe<Scalars["ID"]>;
  /** Tank capacity */
  tankCapacity?: InputMaybe<Scalars["Float"]>;
  /** Tanks required */
  tanksRequired?: InputMaybe<Scalars["Float"]>;
  /** Tanks used */
  tanksUsed?: InputMaybe<Scalars["Float"]>;
  /** Water amount */
  waterAmount?: InputMaybe<Scalars["Float"]>;
  /** Water Sources */
  waterSourceIds?: InputMaybe<Array<Scalars["ID"]>>;
  /** Water Unit */
  waterUnitId?: InputMaybe<Scalars["ID"]>;
};

/** WorkOrderWaterUsage field values */
export type WorkOrderWaterUsageFields = {
  /** Application Unit */
  applicationUnitId: Scalars["ID"];
  /** Output rate */
  outputRate: Scalars["Float"];
  /** Output Unit */
  outputUnitId: Scalars["ID"];
  /** Tank capacity */
  tankCapacity: Scalars["Float"];
  /** Tanks required */
  tanksRequired: Scalars["Float"];
  /** Tanks used */
  tanksUsed?: InputMaybe<Scalars["Float"]>;
  /** Water amount */
  waterAmount: Scalars["Float"];
  /** Water Sources */
  waterSourceIds: Array<Scalars["ID"]>;
  /** Water Unit */
  waterUnitId?: InputMaybe<Scalars["ID"]>;
};

/** Collection of WorkOrders */
export type WorkOrders = {
  __typename?: "WorkOrders";
  /** Paginated collection of WorkOrders */
  items: Array<WorkOrder>;
  /** Total count of WorkOrders */
  totalCount: Scalars["Int"];
};

/** WorkOrders filter */
export type WorkOrdersFilterInput = {
  /** Activity category */
  activityCategoryId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Activity */
  activityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Content of comments */
  comments?: InputMaybe<Scalars["String"]>;
  /** Creation timestamp */
  createdAt?: InputMaybe<DateRangeInput>;
  /** Created by */
  createdById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Crop cycle */
  cropCycleId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Zones */
  cropFieldGroup?: InputMaybe<Array<Scalars["String"]>>;
  /** Cycle number */
  cycleNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Distribute Cost Centers Manually */
  distributeManually?: InputMaybe<Scalars["Boolean"]>;
  /** Document date */
  documentDate?: InputMaybe<DateRangeInput>;
  /** Outputs Harvest By Outputs */
  harvestByOutput?: InputMaybe<Scalars["Boolean"]>;
  id?: InputMaybe<Array<Scalars["ID"]>>;
  /** Internal ID */
  internalId?: InputMaybe<StringMatchInput>;
  /** Inventory status */
  inventoryStatus?: InputMaybe<Array<InventoryStatusFilter>>;
  /** Legacy ID */
  legacyId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Locality */
  localityId?: InputMaybe<Array<Scalars["ID"]>>;
  /** Output Warehouse */
  outputWarehouseId?: InputMaybe<Scalars["ID"]>;
  /** Employee Progress By Employee */
  progressByEmployee?: InputMaybe<Scalars["Boolean"]>;
  /** Machine Progress By Machine */
  progressByMachineGroup?: InputMaybe<Scalars["Boolean"]>;
  /** Inputs Requests By Totals */
  requestByTotal?: InputMaybe<Scalars["Boolean"]>;
  /** Returns all records (discarded) */
  showAll?: InputMaybe<Scalars["Boolean"]>;
  /** Work order status */
  status?: InputMaybe<Array<WorkOrderStatus>>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<DateRangeInput>;
  /** Updated by */
  updatedById?: InputMaybe<Array<Scalars["ID"]>>;
  /** Inputs Usage Manually */
  usageByManual?: InputMaybe<Scalars["Boolean"]>;
  /** Week number */
  weekNumber?: InputMaybe<Array<Scalars["Int"]>>;
  /** Work Order type */
  workOrderType?: InputMaybe<Array<WorkOrderType>>;
  /** Worker count */
  workerCount?: InputMaybe<Array<Scalars["Int"]>>;
};

/** WorkOrders sorting options */
export type WorkOrdersSortInput = {
  /** Area to progress unit conversion factor */
  areaConversionFactor?: InputMaybe<SortDirection>;
  /** Creation timestamp */
  createdAt?: InputMaybe<SortDirection>;
  /** Sort by the username/email of created user */
  createdByName?: InputMaybe<SortDirection>;
  /** Crop field group */
  cropFieldGroup?: InputMaybe<SortDirection>;
  /** Cycle number */
  cycleNumber?: InputMaybe<SortDirection>;
  /** Distribute Cost Centers Manually */
  distributeManually?: InputMaybe<SortDirection>;
  /** Document date */
  documentDate?: InputMaybe<SortDirection>;
  /** Estimated norm */
  estimatedNorm?: InputMaybe<SortDirection>;
  /** Outputs Harvest By Outputs */
  harvestByOutput?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  /** Internal ID */
  internalId?: InputMaybe<SortDirection>;
  /** Inventory status */
  inventoryStatus?: InputMaybe<SortDirection>;
  /** Employee Progress By Employee */
  progressByEmployee?: InputMaybe<SortDirection>;
  /** Machine Progress By Machine */
  progressByMachineGroup?: InputMaybe<SortDirection>;
  /** Inputs Requests By Totals */
  requestByTotal?: InputMaybe<SortDirection>;
  /** Work order status */
  status?: InputMaybe<SortDirection>;
  /** Total progress */
  totalProgress?: InputMaybe<SortDirection>;
  /** Last update timestamp */
  updatedAt?: InputMaybe<SortDirection>;
  /** Inputs Usage Manually */
  usageByManual?: InputMaybe<SortDirection>;
  /** Week number */
  weekNumber?: InputMaybe<SortDirection>;
  /** Manual worker labor cost */
  workerCost?: InputMaybe<SortDirection>;
  /** Worker count */
  workerCount?: InputMaybe<SortDirection>;
  /** Manual worker wage */
  workerWage?: InputMaybe<SortDirection>;
};

export enum WorkUnit {
  /** Day */
  Day = "day",
  /** Hour */
  Hour = "hour",
  /** Month */
  Month = "month",
  /** Piecework */
  Piecework = "piecework",
}

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: "__EnumValue";
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  isDeprecated: Scalars["Boolean"];
  deprecationReason?: Maybe<Scalars["String"]>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: "__Field";
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars["Boolean"];
  deprecationReason?: Maybe<Scalars["String"]>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars["Boolean"]>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: "__InputValue";
  name: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars["String"]>;
  isDeprecated: Scalars["Boolean"];
  deprecationReason?: Maybe<Scalars["String"]>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: "__Type";
  kind: __TypeKind;
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  specifiedByURL?: Maybe<Scalars["String"]>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars["Boolean"]>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars["Boolean"]>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = "SCALAR",
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = "OBJECT",
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = "INTERFACE",
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = "UNION",
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = "ENUM",
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = "INPUT_OBJECT",
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = "LIST",
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = "NON_NULL",
}

export type ActivityNameFragment = {
  __typename?: "Activity";
  id: string;
  name: string;
};

export type ActivityShortFragment = {
  __typename?: "Activity";
  workOrderType: WorkOrderType;
  workOrderCategory: WorkOrderCategory;
  category: { __typename?: "ActivityCategory"; id: string; name: string };
} & ActivityNameFragment;

export type ActivityFragment = {
  __typename?: "Activity";
  internalId?: string | null;
  description?: string | null;
  workdayHours: number;
  progressMeasureType: ActivityProgressMeasureType;
  laborForceType: ActivityLaborForceType;
  trackCycles?: boolean | null;
  trackTools?: boolean | null;
  trackTime?: boolean | null;
  startTime?: any | null;
  useTotalArea?: boolean | null;
  discardedAt?: any | null;
  progressUnit: {
    __typename?: "Unit";
    conversionFactor?: number | null;
  } & UnitShortFragment;
  activityMetrics: Array<
    { __typename?: "ActivityMetric" } & ActivityMetricFragment
  >;
} & ActivityShortFragment;

export type ActivityMetricFragment = {
  __typename?: "ActivityMetric";
  id: string;
  index: number;
  scope: ActivityMetricScope;
  metric: { __typename?: "Metric" } & MetricFragment;
};

export type ActivityDetailsFragment = {
  __typename?: "Activity";
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  progressUnitLocked?: boolean | null;
  localities: Array<{ __typename?: "Locality" } & LocalityNameFragment>;
  crops: Array<{ __typename?: "Crop" } & CropShortFragment>;
  payments: Array<{ __typename?: "ActivityPayment" } & ActivityPaymentFragment>;
} & ActivityFragment;

export type TimeUnitQueryVariables = Exact<{ [key: string]: never }>;

export type TimeUnitQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type WorkUnitQueryVariables = Exact<{ [key: string]: never }>;

export type WorkUnitQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type WorkOrderCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type WorkOrderCategoriesQuery = {
  __typename?: "Query";
  workOrderCategories?: Array<{
    __typename?: "WorkOrderCategoryTypes";
    name: WorkOrderCategory;
    workOrderTypes: Array<WorkOrderType>;
  }> | null;
};

export type ActivityProgressMeasureTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ActivityProgressMeasureTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type ActivityLaborForceTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ActivityLaborForceTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type ActivitiesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ActivitiesFilterInput>;
  sort?: InputMaybe<ActivitiesSortInput>;
}>;

export type ActivitiesQuery = {
  __typename?: "Query";
  activities?: {
    __typename?: "Activities";
    totalCount: number;
    items: Array<{ __typename?: "Activity" } & ActivityFragment>;
  } | null;
};

export type ActivityQueryVariables = Exact<{
  id: Scalars["ID"];
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityQuery = {
  __typename?: "Query";
  activity?: ({ __typename?: "Activity" } & ActivityDetailsFragment) | null;
};

export type ActivityCreateMutationVariables = Exact<{
  fields: ActivityFields;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityCreateMutation = {
  __typename?: "Mutation";
  activityCreate?: {
    __typename?: "ActivityCreatePayload";
    result?: ({ __typename?: "Activity" } & ActivityDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: ActivityChanges;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityUpdateMutation = {
  __typename?: "Mutation";
  activityUpdate?: {
    __typename?: "ActivityUpdatePayload";
    result?: ({ __typename?: "Activity" } & ActivityDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ActivityDiscardMutation = {
  __typename?: "Mutation";
  activityDiscard?: {
    __typename?: "ActivityDiscardPayload";
    result?: ({ __typename?: "Activity" } & ActivityFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityDestroyMutation = {
  __typename?: "Mutation";
  activityDestroy?: {
    __typename?: "ActivityDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityCategoryShortFragment = {
  __typename?: "ActivityCategory";
  id: string;
  name: string;
};

export type ActivityCategoryFragment = {
  __typename?: "ActivityCategory";
  id: string;
  name: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  global: boolean;
  locked: boolean;
  tags?: Array<string> | null;
  discardedAt?: any | null;
  internalId?: string | null;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type ActivityCategoriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ActivityCategoriesFilterInput>;
  sort?: InputMaybe<ActivityCategoriesSortInput>;
}>;

export type ActivityCategoriesQuery = {
  __typename?: "Query";
  activityCategories?: {
    __typename?: "ActivityCategories";
    totalCount: number;
    items: Array<
      { __typename?: "ActivityCategory" } & ActivityCategoryFragment
    >;
  } | null;
};

export type ActivityCategoryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ActivityCategoryQuery = {
  __typename?: "Query";
  activityCategory?:
    | ({ __typename?: "ActivityCategory" } & ActivityCategoryFragment)
    | null;
};

export type ActivityCategoryCreateMutationVariables = Exact<{
  fields: ActivityCategoryFields;
}>;

export type ActivityCategoryCreateMutation = {
  __typename?: "Mutation";
  activityCategoryCreate?: {
    __typename?: "ActivityCategoryCreatePayload";
    result?:
      | ({ __typename?: "ActivityCategory" } & ActivityCategoryFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityCategoryUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: ActivityCategoryChanges;
}>;

export type ActivityCategoryUpdateMutation = {
  __typename?: "Mutation";
  activityCategoryUpdate?: {
    __typename?: "ActivityCategoryUpdatePayload";
    result?:
      | ({ __typename?: "ActivityCategory" } & ActivityCategoryFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityCategoryDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ActivityCategoryDiscardMutation = {
  __typename?: "Mutation";
  activityCategoryDiscard?: {
    __typename?: "ActivityCategoryDiscardPayload";
    result?:
      | ({ __typename?: "ActivityCategory" } & ActivityCategoryFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityCategoryUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ActivityCategoryUnlockMutation = {
  __typename?: "Mutation";
  activityCategoryUnlock?: {
    __typename?: "ActivityCategoryUnlockPayload";
    result?:
      | ({ __typename?: "ActivityCategory" } & ActivityCategoryFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityCategoryDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityCategoryDestroyMutation = {
  __typename?: "Mutation";
  activityCategoryDestroy?: {
    __typename?: "ActivityCategoryDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityPaymentFragment = {
  __typename?: "ActivityPayment";
  id: string;
  wage?: number;
  wageUnit: WorkUnit;
  discardedAt?: any | null;
  position?: {
    __typename?: "Position";
    id: string;
    name: string;
    positionType: PositionType;
  } | null;
  locality?: ({ __typename?: "Locality" } & LocalityNameFragment) | null;
  crop?: { __typename?: "Crop"; id: string; name: string } | null;
};

export type ActivityPaymentShortFragment = {
  __typename?: "ActivityPayment";
  id: string;
  wage?: number;
  wageUnit: WorkUnit;
};

export type ActivityPaymentCreateMutationVariables = Exact<{
  fields: ActivityPaymentFields;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityPaymentCreateMutation = {
  __typename?: "Mutation";
  activityPaymentCreate?: {
    __typename?: "ActivityPaymentCreatePayload";
    result?:
      | ({ __typename?: "ActivityPayment" } & ActivityPaymentFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityPaymentUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: ActivityPaymentChanges;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityPaymentUpdateMutation = {
  __typename?: "Mutation";
  activityPaymentUpdate?: {
    __typename?: "ActivityPaymentUpdatePayload";
    result?:
      | ({ __typename?: "ActivityPayment" } & ActivityPaymentFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityPaymentDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityPaymentDiscardMutation = {
  __typename?: "Mutation";
  activityPaymentDiscard?: {
    __typename?: "ActivityPaymentDiscardPayload";
    result?:
      | ({ __typename?: "ActivityPayment" } & ActivityPaymentFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropCycleNameFragment = {
  __typename?: "CropCycle";
  id: string;
  name: string;
};

export type CropCycleShortFragment = {
  __typename?: "CropCycle";
  startedAt: any;
  estimatedEndDate?: any | null;
  closedAt?: any | null;
  discardedAt?: any | null;
  crops: Array<{ __typename?: "Crop"; id: string; name: string }>;
} & CropCycleNameFragment;

export type CropCycleFragment = {
  __typename?: "CropCycle";
  estimatedEndDate?: any | null;
  mayDiscard: boolean;
  mayDestroy: boolean;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
} & CropCycleShortFragment;

export type CropCyclesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropCyclesFilterInput>;
  sort?: InputMaybe<CropCyclesSortInput>;
}>;

export type CropCyclesQuery = {
  __typename?: "Query";
  cropCycles?: {
    __typename?: "CropCycles";
    totalCount: number;
    items: Array<{ __typename?: "CropCycle" } & CropCycleFragment>;
  } | null;
};

export type CropCycleQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropCycleQuery = {
  __typename?: "Query";
  cropCycle?: ({ __typename?: "CropCycle" } & CropCycleFragment) | null;
};

export type CropCycleCreateMutationVariables = Exact<{
  fields: CropCycleFields;
}>;

export type CropCycleCreateMutation = {
  __typename?: "Mutation";
  cropCycleCreate?: {
    __typename?: "CropCycleCreatePayload";
    result?: ({ __typename?: "CropCycle" } & CropCycleFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropCycleUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CropCycleChanges;
}>;

export type CropCycleUpdateMutation = {
  __typename?: "Mutation";
  cropCycleUpdate?: {
    __typename?: "CropCycleUpdatePayload";
    result?: ({ __typename?: "CropCycle" } & CropCycleFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropCycleDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropCycleDiscardMutation = {
  __typename?: "Mutation";
  cropCycleDiscard?: {
    __typename?: "CropCycleDiscardPayload";
    result?: ({ __typename?: "CropCycle" } & CropCycleFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropCycleDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CropCycleDestroyMutation = {
  __typename?: "Mutation";
  cropCycleDestroy?: {
    __typename?: "CropCycleDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActiveDocumentFragment = {
  __typename?: "FinanceDocumentReference";
  documentId: string;
  documentType: FinanceDocumentType;
  documentDate: any;
  status: string;
};

export type CropStageChangeFragment = {
  __typename?: "CropStageChange";
  cropField: { __typename?: "CropField" } & CropFieldShortFragment;
  nextStage: { __typename?: "CropStage" } & CropStageShortFragment;
};

export type CropCycleCloseMutationVariables = Exact<{
  id: Scalars["ID"];
  closedAt?: InputMaybe<Scalars["ISO8601DateTime"]>;
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CropCycleCloseMutation = {
  __typename?: "Mutation";
  cropCycleClose?: {
    __typename?: "CropCycleCloseMutationPayload";
    activeDocuments: Array<
      { __typename?: "FinanceDocumentReference" } & ActiveDocumentFragment
    >;
    changes: Array<
      { __typename?: "CropStageChange" } & CropStageChangeFragment
    >;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropCycleReopenMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropCycleReopenMutation = {
  __typename?: "Mutation";
  cropCycleReopen?: {
    __typename?: "CropCycleReopenMutationPayload";
    result: { __typename?: "CropCycle" } & CropCycleFragment;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropFieldNoteFragment = {
  __typename?: "CropFieldNote";
  id: string;
  documentDate: any;
  kind: CropFieldNoteType;
  latitude?: number | null;
  longitude?: number | null;
  message?: string | null;
  thumbnail?: string | null;
  images?: Array<any> | null;
  discardedAt?: any | null;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  sampleDiseases: Array<
    { __typename?: "SampleDisease" } & SampleDiseaseFragment
  >;
  cropField: {
    __typename?: "CropField";
    geoJson?: any | null;
  } & CropFieldShortFragment;
};

export type SampleDiseaseFragment = {
  __typename?: "SampleDisease";
  id: string;
  source: DiseaseSource;
  section: SampleSection;
  size: number;
  found: number;
  thumbnail?: string | null;
  images?: Array<any> | null;
  discardedAt?: any | null;
  disease: { __typename?: "Disease" } & DiseaseShortFragment;
};

export type CropFieldNoteTypeQueryVariables = Exact<{ [key: string]: never }>;

export type CropFieldNoteTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type SampleSectionQueryVariables = Exact<{ [key: string]: never }>;

export type SampleSectionQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type CropFieldNotesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropFieldNotesFilterInput>;
  sort?: InputMaybe<CropFieldNotesSortInput>;
}>;

export type CropFieldNotesQuery = {
  __typename?: "Query";
  cropFieldNotes?: {
    __typename?: "CropFieldNotes";
    totalCount: number;
    items: Array<{ __typename?: "CropFieldNote" } & CropFieldNoteFragment>;
  } | null;
};

export type CropFieldNoteQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropFieldNoteQuery = {
  __typename?: "Query";
  cropFieldNote?:
    | ({ __typename?: "CropFieldNote" } & CropFieldNoteFragment)
    | null;
};

export type CropFieldNoteCreateMutationVariables = Exact<{
  fields: CropFieldNoteFields;
}>;

export type CropFieldNoteCreateMutation = {
  __typename?: "Mutation";
  cropFieldNoteCreate?: {
    __typename?: "CropFieldNoteCreatePayload";
    result?: ({ __typename?: "CropFieldNote" } & CropFieldNoteFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropFieldNoteUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CropFieldNoteChanges;
}>;

export type CropFieldNoteUpdateMutation = {
  __typename?: "Mutation";
  cropFieldNoteUpdate?: {
    __typename?: "CropFieldNoteUpdatePayload";
    result?: ({ __typename?: "CropFieldNote" } & CropFieldNoteFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropFieldNoteDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropFieldNoteDiscardMutation = {
  __typename?: "Mutation";
  cropFieldNoteDiscard?: {
    __typename?: "CropFieldNoteDiscardPayload";
    result?: ({ __typename?: "CropFieldNote" } & CropFieldNoteFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropFieldFragment = {
  __typename?: "CropField";
  id: string;
  internalId?: string | null;
  name: string;
  group?: string | null;
  tags?: Array<string> | null;
  totalArea: number;
  plantDensity: number;
  effectivePlants: number;
  estimatedPlants: number;
  effectiveArea: number;
  discardedAt?: any | null;
  geoJson?: any | null;
  crop: { __typename?: "Crop"; id: string; name: string };
  cropFieldVarieties: Array<
    { __typename?: "CropFieldVariety" } & CropFieldVarietyFragment
  >;
  locality: { __typename?: "Locality" } & LocalityShortFragment;
  cropStage: { __typename?: "CropStage" } & CropStageShortFragment;
  costCenter?: ({ __typename?: "CostCenter" } & CostCenterShortFragment) | null;
};

export type CropFieldVarietyFragment = {
  __typename?: "CropFieldVariety";
  id: string;
  plantedAt?: any | null;
  plantDensity: number;
  effectivePlants: number;
  effectiveArea: number;
  discardedAt?: any | null;
  cropVariety: { __typename?: "CropVariety" } & CropVarietyShortFragment;
};

export type CropFieldShortFragment = {
  __typename?: "CropField";
  id: string;
  name: string;
  group?: string | null;
  effectiveArea: number;
  effectivePlants: number;
  totalArea: number;
  tags?: Array<string> | null;
  cropStage: {
    __typename?: "CropStage";
    id: string;
    name: string;
    kind: CropStageType;
  };
};

export type CropFieldWithVarietyShortFragment = {
  __typename?: "CropField";
  crop: { __typename?: "Crop"; id: string; name: string };
  cropFieldVarieties: Array<
    { __typename?: "CropFieldVariety" } & CropFieldVarietyFragment
  >;
} & CropFieldShortFragment;

export type CropFieldDetailsFragment = {
  __typename?: "CropField";
  altitude?: number | null;
  irrigated: boolean;
  locality: {
    __typename?: "Locality";
    geoJson?: any | null;
  } & LocalityShortFragment;
} & CropFieldFragment;

export type CropFieldProgressFragment = {
  __typename?: "CropField";
  actualProgress?: number | null;
  plannedProgress?: number | null;
  estimatedHarvestAmount?: number | null;
} & CropFieldFragment;

export type CropFieldsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropFieldsFilterInput>;
  sort?: InputMaybe<CropFieldsSortInput>;
  progressFilter?: InputMaybe<CropFieldProgressFilterInput>;
  estimationInput?: InputMaybe<CropFieldHarvestEstimationInput>;
}>;

export type CropFieldsQuery = {
  __typename?: "Query";
  cropFields?: {
    __typename?: "CropFields";
    totalCount: number;
    items: Array<{ __typename?: "CropField" } & CropFieldProgressFragment>;
  } | null;
};

export type CropFieldGroupsQueryVariables = Exact<{
  localityId?: InputMaybe<Array<Scalars["ID"]> | Scalars["ID"]>;
}>;

export type CropFieldGroupsQuery = {
  __typename?: "Query";
  cropFieldGroups?: Array<string> | null;
};

export type CropFieldQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropFieldQuery = {
  __typename?: "Query";
  cropField?: ({ __typename?: "CropField" } & CropFieldDetailsFragment) | null;
};

export type CropFieldCreateMutationVariables = Exact<{
  fields: CropFieldFields;
}>;

export type CropFieldCreateMutation = {
  __typename?: "Mutation";
  cropFieldCreate?: {
    __typename?: "CropFieldCreatePayload";
    result?: ({ __typename?: "CropField" } & CropFieldDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropFieldUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CropFieldChanges;
}>;

export type CropFieldUpdateMutation = {
  __typename?: "Mutation";
  cropFieldUpdate?: {
    __typename?: "CropFieldUpdatePayload";
    result?: ({ __typename?: "CropField" } & CropFieldDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropFieldBulkUpdateMutationVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
  changes: CropFieldBulkChanges;
}>;

export type CropFieldBulkUpdateMutation = {
  __typename?: "Mutation";
  cropFieldBulkUpdate?: {
    __typename?: "CropFieldBulkBulkUpdatePayload";
    result: Array<{
      __typename?: "BulkOperationResult";
      id: string;
      result?: boolean | null;
      errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
    }>;
  } | null;
};

export type CropFieldDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropFieldDiscardMutation = {
  __typename?: "Mutation";
  cropFieldDiscard?: {
    __typename?: "CropFieldDiscardPayload";
    result?: ({ __typename?: "CropField" } & CropFieldFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropFieldDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CropFieldDestroyMutation = {
  __typename?: "Mutation";
  cropFieldDestroy?: {
    __typename?: "CropFieldDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropStageShortFragment = {
  __typename?: "CropStage";
  id: string;
  name: string;
  kind: CropStageType;
};

export type CropStageFragment = {
  __typename?: "CropStage";
  id: string;
  name: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  kind: CropStageType;
  changeEvent: CropStageChangeEvent;
  changeDate?: any | null;
  discardedAt?: any | null;
  prevStage?: { __typename?: "CropStage"; id: string; name: string } | null;
  costCenter?: ({ __typename?: "CostCenter" } & CostCenterShortFragment) | null;
  cropStageLocalities: Array<{
    __typename?: "CropStageLocality";
    id: string;
    locality: { __typename?: "Locality" } & LocalityNameFragment;
    costCenter?:
      | ({ __typename?: "CostCenter" } & CostCenterShortFragment)
      | null;
  }>;
  crops: Array<{ __typename?: "Crop" } & CropShortFragment>;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type CropStageChangeEventsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CropStageChangeEventsQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type CropStagesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropStagesFilterInput>;
  sort?: InputMaybe<CropStagesSortInput>;
}>;

export type CropStagesQuery = {
  __typename?: "Query";
  cropStages?: {
    __typename?: "CropStages";
    totalCount: number;
    items: Array<{ __typename?: "CropStage" } & CropStageFragment>;
  } | null;
};

export type CropStageQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropStageQuery = {
  __typename?: "Query";
  cropStage?: ({ __typename?: "CropStage" } & CropStageFragment) | null;
};

export type CropStageCreateMutationVariables = Exact<{
  fields: CropStageFields;
}>;

export type CropStageCreateMutation = {
  __typename?: "Mutation";
  cropStageCreate?: {
    __typename?: "CropStageCreatePayload";
    result?: ({ __typename?: "CropStage" } & CropStageFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropStageUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CropStageChanges;
}>;

export type CropStageUpdateMutation = {
  __typename?: "Mutation";
  cropStageUpdate?: {
    __typename?: "CropStageUpdatePayload";
    result?: ({ __typename?: "CropStage" } & CropStageFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropStageDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropStageDiscardMutation = {
  __typename?: "Mutation";
  cropStageDiscard?: {
    __typename?: "CropStageDiscardPayload";
    result?: ({ __typename?: "CropStage" } & CropStageFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropStageDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CropStageDestroyMutation = {
  __typename?: "Mutation";
  cropStageDestroy?: {
    __typename?: "CropStageDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropVarietyFragment = {
  __typename?: "CropVariety";
  id: string;
  name: string;
  scientificName?: string | null;
  discardedAt?: any | null;
  crop: { __typename?: "Crop"; id: string; name: string };
};

export type CropVarietyShortFragment = {
  __typename?: "CropVariety";
  id: string;
  name: string;
};

export type CropVarietiesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropVarietiesFilterInput>;
  sort?: InputMaybe<CropVarietiesSortInput>;
}>;

export type CropVarietiesQuery = {
  __typename?: "Query";
  cropVarieties?: {
    __typename?: "CropVarieties";
    totalCount: number;
    items: Array<{ __typename?: "CropVariety" } & CropVarietyFragment>;
  } | null;
};

export type CropVarietyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropVarietyQuery = {
  __typename?: "Query";
  cropVariety?: ({ __typename?: "CropVariety" } & CropVarietyFragment) | null;
};

export type CropVarietyCreateMutationVariables = Exact<{
  fields: CropVarietyFields;
}>;

export type CropVarietyCreateMutation = {
  __typename?: "Mutation";
  cropVarietyCreate?: {
    __typename?: "CropVarietyCreatePayload";
    result?: ({ __typename?: "CropVariety" } & CropVarietyFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropVarietyUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CropVarietyChanges;
}>;

export type CropVarietyUpdateMutation = {
  __typename?: "Mutation";
  cropVarietyUpdate?: {
    __typename?: "CropVarietyUpdatePayload";
    result?: ({ __typename?: "CropVariety" } & CropVarietyFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropVarietyDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropVarietyDiscardMutation = {
  __typename?: "Mutation";
  cropVarietyDiscard?: {
    __typename?: "CropVarietyDiscardPayload";
    result?: ({ __typename?: "CropVariety" } & CropVarietyFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropVarietyDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CropVarietyDestroyMutation = {
  __typename?: "Mutation";
  cropVarietyDestroy?: {
    __typename?: "CropVarietyDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropFragment = {
  __typename?: "Crop";
  id: string;
  name: string;
  commonNames?: Array<string> | null;
  locked: boolean;
  global: boolean;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  commonNamesEn?: Array<string> | null;
  commonNamesEs?: Array<string> | null;
  commonNamesPt?: Array<string> | null;
  commonNamesRu?: Array<string> | null;
  discardedAt?: any | null;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type CropShortFragment = {
  __typename?: "Crop";
  id: string;
  name: string;
};

export type CropsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropsFilterInput>;
  sort?: InputMaybe<CropsSortInput>;
}>;

export type CropsQuery = {
  __typename?: "Query";
  crops?: {
    __typename?: "Crops";
    totalCount: number;
    items: Array<{ __typename?: "Crop" } & CropFragment>;
  } | null;
};

export type CropOptionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropsFilterInput>;
  sort?: InputMaybe<CropsSortInput>;
}>;

export type CropOptionsQuery = {
  __typename?: "Query";
  crops?: {
    __typename?: "Crops";
    totalCount: number;
    items: Array<{ __typename?: "Crop" } & CropShortFragment>;
  } | null;
};

export type CropQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropQuery = {
  __typename?: "Query";
  crop?: ({ __typename?: "Crop" } & CropFragment) | null;
};

export type CropCreateMutationVariables = Exact<{
  fields: CropInputFields;
}>;

export type CropCreateMutation = {
  __typename?: "Mutation";
  cropCreate?: {
    __typename?: "CropCreatePayload";
    result?: ({ __typename?: "Crop" } & CropFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CropChanges;
}>;

export type CropUpdateMutation = {
  __typename?: "Mutation";
  cropUpdate?: {
    __typename?: "CropUpdatePayload";
    result?: ({ __typename?: "Crop" } & CropFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropDiscardMutation = {
  __typename?: "Mutation";
  cropDiscard?: {
    __typename?: "CropDiscardPayload";
    result?: ({ __typename?: "Crop" } & CropFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CropUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CropUnlockMutation = {
  __typename?: "Mutation";
  cropUnlock?: {
    __typename?: "CropUnlockPayload";
    result?: ({ __typename?: "Crop" } & CropFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type DiseaseRiskFragment = {
  __typename?: "DiseaseRisk";
  id: string;
  low: number;
  high: number;
  lowRecommendation?: string | null;
  lowRecommendationEn?: string | null;
  lowRecommendationEs?: string | null;
  mediumRecommendation?: string | null;
  mediumRecommendationEn?: string | null;
  mediumRecommendationEs?: string | null;
  highRecommendation?: string | null;
  highRecommendationEn?: string | null;
  highRecommendationEs?: string | null;
};

export type DiseaseRiskShortFragment = {
  __typename?: "DiseaseRisk";
  id: string;
  low: number;
  high: number;
  lowRecommendation?: string | null;
  mediumRecommendation?: string | null;
  highRecommendation?: string | null;
};

export type DiseaseFragment = {
  __typename?: "Disease";
  id: string;
  name: string;
  scientificName?: string | null;
  description?: string | null;
  locked: boolean;
  global: boolean;
  thumbnail?: string | null;
  images?: Array<any> | null;
  kind: DiseaseType;
  defaultSource: DiseaseSource;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  descriptionEn?: string | null;
  descriptionEs?: string | null;
  descriptionPt?: string | null;
  descriptionRu?: string | null;
  discardedAt?: any | null;
  mayDestroy: boolean;
  crops: Array<{ __typename?: "Crop" } & CropShortFragment>;
  riskMap: { __typename?: "DiseaseRisk" } & DiseaseRiskFragment;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type DiseaseShortFragment = {
  __typename?: "Disease";
  id: string;
  name: string;
  kind: DiseaseType;
  thumbnail?: string | null;
  riskMap: { __typename?: "DiseaseRisk" } & DiseaseRiskShortFragment;
};

export type DiseaseTypeQueryVariables = Exact<{ [key: string]: never }>;

export type DiseaseTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type DiseaseSourceQueryVariables = Exact<{ [key: string]: never }>;

export type DiseaseSourceQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type DiseasesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<DiseasesFilterInput>;
  sort?: InputMaybe<DiseasesSortInput>;
}>;

export type DiseasesQuery = {
  __typename?: "Query";
  diseases?: {
    __typename?: "Diseases";
    totalCount: number;
    items: Array<{ __typename?: "Disease" } & DiseaseFragment>;
  } | null;
};

export type DiseaseQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DiseaseQuery = {
  __typename?: "Query";
  disease?: ({ __typename?: "Disease" } & DiseaseFragment) | null;
};

export type DiseaseCreateMutationVariables = Exact<{
  fields: DiseaseFields;
}>;

export type DiseaseCreateMutation = {
  __typename?: "Mutation";
  diseaseCreate?: {
    __typename?: "DiseaseCreatePayload";
    result?: ({ __typename?: "Disease" } & DiseaseFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type DiseaseUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: DiseaseChanges;
}>;

export type DiseaseUpdateMutation = {
  __typename?: "Mutation";
  diseaseUpdate?: {
    __typename?: "DiseaseUpdatePayload";
    result?: ({ __typename?: "Disease" } & DiseaseFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type DiseaseUpdateRisksMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: DiseaseRiskChanges;
}>;

export type DiseaseUpdateRisksMutation = {
  __typename?: "Mutation";
  diseaseRiskUpdate?: {
    __typename?: "DiseaseRiskUpdatePayload";
    result?: ({ __typename?: "DiseaseRisk" } & DiseaseRiskFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type DiseaseDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DiseaseDiscardMutation = {
  __typename?: "Mutation";
  diseaseDiscard?: {
    __typename?: "DiseaseDiscardPayload";
    result?: ({ __typename?: "Disease" } & DiseaseFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type DiseaseUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DiseaseUnlockMutation = {
  __typename?: "Mutation";
  diseaseUnlock?: {
    __typename?: "DiseaseUnlockPayload";
    result?: ({ __typename?: "Disease" } & DiseaseFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type DiseaseDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type DiseaseDestroyMutation = {
  __typename?: "Mutation";
  diseaseDestroy?: {
    __typename?: "DiseaseDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type HarvestEstimateFragment = {
  __typename?: "HarvestEstimate";
  id: string;
  createdAt: any;
  updatedAt: any;
  cropCycle: {
    __typename?: "CropCycle";
    crops: Array<{ __typename?: "Crop"; name: string }>;
  } & CropCycleShortFragment;
  estimateUnit: { __typename?: "Unit" } & UnitShortFragment;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  updatedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type CropFieldHarvestEstimateFragment = {
  __typename?: "CropFieldHarvestEstimate";
  id: string;
  effectiveArea: number;
  amount: number;
  cropField: { __typename?: "CropField" } & CropFieldWithVarietyShortFragment;
};

export type HarvestEstimateDetailsFragment = {
  __typename?: "HarvestEstimate";
  conversionFactor: number;
  updatedAt: any;
  harvestUnit: { __typename?: "Unit" } & UnitShortFragment;
  cropFieldEstimates: Array<
    {
      __typename?: "CropFieldHarvestEstimate";
    } & CropFieldHarvestEstimateFragment
  >;
} & HarvestEstimateFragment;

export type HarvestEstimatesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<HarvestEstimatesFilterInput>;
  sort?: InputMaybe<HarvestEstimatesSortInput>;
}>;

export type HarvestEstimatesQuery = {
  __typename?: "Query";
  harvestEstimates?: {
    __typename?: "HarvestEstimates";
    totalCount: number;
    items: Array<{ __typename?: "HarvestEstimate" } & HarvestEstimateFragment>;
  } | null;
};

export type HarvestEstimateQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type HarvestEstimateQuery = {
  __typename?: "Query";
  harvestEstimate?:
    | ({ __typename?: "HarvestEstimate" } & HarvestEstimateDetailsFragment)
    | null;
};

export type HarvestEstimateCreateMutationVariables = Exact<{
  fields: HarvestEstimateFields;
}>;

export type HarvestEstimateCreateMutation = {
  __typename?: "Mutation";
  harvestEstimateCreate?: {
    __typename?: "HarvestEstimateCreatePayload";
    result?:
      | ({ __typename?: "HarvestEstimate" } & HarvestEstimateDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type HarvestEstimateUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: HarvestEstimateChanges;
}>;

export type HarvestEstimateUpdateMutation = {
  __typename?: "Mutation";
  harvestEstimateUpdate?: {
    __typename?: "HarvestEstimateUpdatePayload";
    result?:
      | ({ __typename?: "HarvestEstimate" } & HarvestEstimateDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type HarvestEstimateDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type HarvestEstimateDestroyMutation = {
  __typename?: "Mutation";
  harvestEstimateDestroy?: {
    __typename?: "HarvestEstimateDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type IngredientFragment = {
  __typename?: "Ingredient";
  id: string;
  name: string;
  commonNames?: Array<string> | null;
  formula: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  commonNamesEn?: Array<string> | null;
  commonNamesEs?: Array<string> | null;
  commonNamesPt?: Array<string> | null;
  commonNamesRu?: Array<string> | null;
  discardedAt?: any | null;
  itemCategories: Array<{
    __typename?: "ItemCategory";
    id: string;
    name: string;
  }>;
};

export type IngredientShortFragment = {
  __typename?: "Ingredient";
  id: string;
  name: string;
};

export type IngredientsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<IngredientsFilterInput>;
  sort?: InputMaybe<IngredientsSortInput>;
}>;

export type IngredientsQuery = {
  __typename?: "Query";
  ingredients?: {
    __typename?: "Ingredients";
    totalCount: number;
    items: Array<{ __typename?: "Ingredient" } & IngredientFragment>;
  } | null;
};

export type IngredientQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IngredientQuery = {
  __typename?: "Query";
  ingredient?: ({ __typename?: "Ingredient" } & IngredientFragment) | null;
};

export type IngredientCreateMutationVariables = Exact<{
  fields: IngredientFields;
}>;

export type IngredientCreateMutation = {
  __typename?: "Mutation";
  ingredientCreate?: {
    __typename?: "IngredientCreatePayload";
    result?: ({ __typename?: "Ingredient" } & IngredientFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type IngredientUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: IngredientChanges;
}>;

export type IngredientUpdateMutation = {
  __typename?: "Mutation";
  ingredientUpdate?: {
    __typename?: "IngredientUpdatePayload";
    result?: ({ __typename?: "Ingredient" } & IngredientFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type IngredientDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type IngredientDiscardMutation = {
  __typename?: "Mutation";
  ingredientDiscard?: {
    __typename?: "IngredientDiscardPayload";
    result?: ({ __typename?: "Ingredient" } & IngredientFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type InventoryRequestFragment = {
  __typename?: "InventoryRequest";
  id: string;
  updatedAt: any;
  workOrder: { __typename?: "WorkOrder" } & WorkOrderFragment;
  createdBy: { __typename?: "User" } & UserShortFragment;
};

export type InventoryRequestVariantFragment = {
  __typename?: "WorkOrderVariant";
  confirmedReturnedAmount?: number | null;
  stock?: { __typename?: "Stock"; onHand: number } | null;
} & WorkOrderVariantFragment;

export type InventoryRequestDetailsFragment = {
  __typename?: "InventoryRequest";
  variants: Array<
    { __typename?: "WorkOrderVariant" } & InventoryRequestVariantFragment
  >;
} & InventoryRequestFragment;

export type InventoryRequestsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<InventoryRequestsFilterInput>;
  sort?: InputMaybe<InventoryRequestsSortInput>;
}>;

export type InventoryRequestsQuery = {
  __typename?: "Query";
  inventoryRequests?: {
    __typename?: "InventoryRequests";
    totalCount: number;
    items: Array<
      { __typename?: "InventoryRequest" } & InventoryRequestFragment
    >;
  } | null;
};

export type InventoryRequestQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InventoryRequestQuery = {
  __typename?: "Query";
  inventoryRequest?:
    | ({ __typename?: "InventoryRequest" } & InventoryRequestDetailsFragment)
    | null;
};

export type InventoryRequestUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: InventoryRequestChanges;
}>;

export type InventoryRequestUpdateMutation = {
  __typename?: "Mutation";
  inventoryRequestUpdate?: {
    __typename?: "UpdateMutationPayload";
    jobId?: string | null;
    result?:
      | ({ __typename?: "InventoryRequest" } & InventoryRequestDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MetricShortFragment = {
  __typename?: "Metric";
  id: string;
  name: string;
  description?: string | null;
  unit: { __typename?: "Unit" } & UnitShortFragment;
};

export type MetricValueFragment = {
  __typename?: "WorkOrderMetric";
  value: number;
  metric: { __typename?: "Metric" } & MetricShortFragment;
};

export type MetricFragment = {
  __typename?: "Metric";
  nameEn?: string | null;
  nameEs?: string | null;
  description?: string | null;
  descriptionEn?: string | null;
  descriptionEs?: string | null;
  abbr?: string | null;
  discardedAt?: any | null;
  mayDiscard: boolean;
  mayDestroy: boolean;
} & MetricShortFragment;

export type MetricsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<MetricsFilterInput>;
  sort?: InputMaybe<MetricsSortInput>;
}>;

export type MetricsQuery = {
  __typename?: "Query";
  metrics?: {
    __typename?: "Metrics";
    totalCount: number;
    items: Array<{ __typename?: "Metric" } & MetricFragment>;
  } | null;
};

export type MetricQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MetricQuery = {
  __typename?: "Query";
  metric?: ({ __typename?: "Metric" } & MetricFragment) | null;
};

export type MetricCreateMutationVariables = Exact<{
  fields: MetricFields;
}>;

export type MetricCreateMutation = {
  __typename?: "Mutation";
  metricCreate?: {
    __typename?: "MetricCreatePayload";
    result?: ({ __typename?: "Metric" } & MetricFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MetricUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: MetricChanges;
}>;

export type MetricUpdateMutation = {
  __typename?: "Mutation";
  metricUpdate?: {
    __typename?: "MetricUpdatePayload";
    result?: ({ __typename?: "Metric" } & MetricFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MetricDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MetricDiscardMutation = {
  __typename?: "Mutation";
  metricDiscard?: {
    __typename?: "MetricDiscardPayload";
    result?: ({ __typename?: "Metric" } & MetricFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MetricDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type MetricDestroyMutation = {
  __typename?: "Mutation";
  metricDestroy?: {
    __typename?: "MetricDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PlanningFragment = {
  __typename?: "Planning";
  id: string;
  startDate: any;
  endDate: any;
  startWeek?: number | null;
  endWeek?: number | null;
  cycleNumber?: number | null;
  cropFieldGroup?: string | null;
  status: PlanningStatus;
  actualProgress: number;
  activity: { __typename?: "Activity" } & ActivityNameFragment;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
  cropCycle?: ({ __typename?: "CropCycle" } & CropCycleNameFragment) | null;
  cropStages: Array<{ __typename?: "CropStage" } & CropStageShortFragment>;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type PlanningPositionFragment = {
  __typename?: "PlanningPosition";
  id?: string | null;
  quantity: number;
  wage?: number;
  wageUnit: WorkUnit;
  manDays?: number | null;
  actualCost: number;
  actualManDays: number;
  actualEfficiency?: number | null;
  avgActualCount: number;
  position: { __typename?: "Position" } & PositionShortFragment;
};

export type PlanningCostCenterPositionFragment = {
  __typename?: "PlanningCostCenterPosition";
  id?: string | null;
  positionId: string;
  progressNorm: number;
  manDays?: number | null;
  actualCost: number;
};

export type PlanningCostCenterFragment = {
  __typename?: "PlanningCostCenter";
  id?: string | null;
  profitableId?: string | null;
  profitableType?: ProfitableType | null;
  profitableName?: string | null;
  goal: number;
  index: number;
  startDate?: any | null;
  endDate?: any | null;
  distributionRate: number;
  actualProgress: number;
  actualWorkDays: number;
  actualManDays: number;
  actualMachineHours: number;
  actualLaborCost: number;
  actualInputCost: number;
  costCenter: {
    __typename?: "CostCenter";
    discardedAt?: any | null;
  } & CostCenterShortFragment;
  cropField?: ({ __typename?: "CropField" } & CropFieldFragment) | null;
  positions: Array<
    {
      __typename?: "PlanningCostCenterPosition";
    } & PlanningCostCenterPositionFragment
  >;
  variants: Array<
    {
      __typename?: "PlanningCostCenterVariant";
    } & PlanningCostCenterVariantFragment
  >;
  machines: Array<
    {
      __typename?: "PlanningCostCenterMachine";
    } & PlanningCostCenterMachineFragment
  >;
  expenseItems: Array<
    {
      __typename?: "PlanningCostCenterExpenseItem";
    } & PlanningCostCenterExpenseItemFragment
  >;
};

export type PlanningVariantFragment = {
  __typename?: "PlanningVariant";
  id?: string | null;
  cost: number;
  totalAmount?: number | null;
  actualCost: number;
  consumedAmount: number;
  variant: {
    __typename?: "Variant";
    stock: { __typename?: "Stock"; onHand: number };
  } & VariantShortFragment;
  unit: {
    __typename?: "Unit";
    conversionFactor?: number | null;
  } & UnitShortFragment;
};

export type PlanningCostCenterVariantFragment = {
  __typename?: "PlanningCostCenterVariant";
  id?: string | null;
  variantId: string;
  dosage: number;
  totalAmount?: number | null;
  actualDosage: number;
  actualCost: number;
  consumedAmount: number;
};

export type PlanningMachineFragment = {
  __typename?: "PlanningMachine";
  id?: string | null;
  quantity: number;
  actualHours: number;
  avgActualCount: number;
  actualEfficiency?: number | null;
  machineKind: { __typename?: "MachineKind" } & MachineKindShortFragment;
};

export type PlanningCostCenterMachineFragment = {
  __typename?: "PlanningCostCenterMachine";
  id?: string | null;
  machineKindId: string;
  progressNorm: number;
};

export type PlanningExpenseItemFragment = {
  __typename?: "PlanningExpenseItem";
  id?: string | null;
  expenseItem: { __typename?: "ExpenseItem" } & ExpenseItemShortFragment;
};

export type PlanningCostCenterExpenseItemFragment = {
  __typename?: "PlanningCostCenterExpenseItem";
  id?: string | null;
  expenseItemId: string;
  cost: number;
};

export type PlanningDetailsFragment = {
  __typename?: "Planning";
  createdAt: any;
  updatedAt: any;
  actualWorkDays: number;
  activity: { __typename?: "Activity" } & ActivityFragment;
  cropCycle?: ({ __typename?: "CropCycle" } & CropCycleShortFragment) | null;
  locality: { __typename?: "Locality" } & LocalityShortFragment;
  positions: Array<
    { __typename?: "PlanningPosition" } & PlanningPositionFragment
  >;
  costCenters: Array<
    { __typename?: "PlanningCostCenter" } & PlanningCostCenterFragment
  >;
  inputs: Array<{ __typename?: "PlanningVariant" } & PlanningVariantFragment>;
  machines: Array<{ __typename?: "PlanningMachine" } & PlanningMachineFragment>;
  expenseItems: Array<
    { __typename?: "PlanningExpenseItem" } & PlanningExpenseItemFragment
  >;
  updatedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  workOrders: Array<
    { __typename?: "WorkOrder"; totalProgress: number } & WorkOrderShortFragment
  >;
} & PlanningFragment;

export type PlanningStatusQueryVariables = Exact<{ [key: string]: never }>;

export type PlanningStatusQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type PlanningsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<PlanningsFilterInput>;
  sort?: InputMaybe<PlanningsSortInput>;
}>;

export type PlanningsQuery = {
  __typename?: "Query";
  plannings?: {
    __typename?: "Plannings";
    totalCount: number;
    items: Array<{ __typename?: "Planning" } & PlanningFragment>;
  } | null;
};

export type PlanningQueryVariables = Exact<{
  id: Scalars["ID"];
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PlanningQuery = {
  __typename?: "Query";
  planning?: ({ __typename?: "Planning" } & PlanningDetailsFragment) | null;
};

export type PlanningCreateMutationVariables = Exact<{
  fields: PlanningFields;
}>;

export type PlanningCreateMutation = {
  __typename?: "Mutation";
  planningCreate?: {
    __typename?: "PlanningCreatePayload";
    result?: {
      __typename?: "Planning";
      id: string;
      status: PlanningStatus;
    } | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PlanningUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: PlanningChanges;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PlanningUpdateMutation = {
  __typename?: "Mutation";
  planningUpdate?: {
    __typename?: "PlanningUpdatePayload";
    result?: ({ __typename?: "Planning" } & PlanningDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PlanningCloneMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: PlanningChanges;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PlanningCloneMutation = {
  __typename?: "Mutation";
  planningClone?: {
    __typename?: "PlanningClonePayload";
    result?: ({ __typename?: "Planning" } & PlanningDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PlanningDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PlanningDestroyMutation = {
  __typename?: "Mutation";
  planningDestroy?: {
    __typename?: "PlanningDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WaterSourceShortFragment = {
  __typename?: "WaterSource";
  id: string;
  name: string;
};

export type WaterSourcesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<WaterSourcesFilterInput>;
  sort?: InputMaybe<WaterSourcesSortInput>;
}>;

export type WaterSourcesQuery = {
  __typename?: "Query";
  waterSources?: {
    __typename?: "WaterSources";
    totalCount: number;
    items: Array<{ __typename?: "WaterSource" } & WaterSourceShortFragment>;
  } | null;
};

export type WeatherMeasurementFragment = {
  __typename?: "WeatherMeasurement";
  id: string;
  temperature?: number | null;
  rainfall?: number | null;
  windSpeed?: number | null;
  humidity?: number | null;
  uvIndex?: number | null;
  measuredAt: any;
  latitude?: number | null;
  longitude?: number | null;
  notes?: string | null;
  measurableId?: string | null;
  measurableType?: WeatherMeasurableType | null;
  measurableName?: string | null;
  mayDestroy: boolean;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
};

export type WeatherMeasurementsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<WeatherMeasurementsFilterInput>;
  sort?: InputMaybe<WeatherMeasurementsSortInput>;
}>;

export type WeatherMeasurementsQuery = {
  __typename?: "Query";
  weatherMeasurements?: {
    __typename?: "WeatherMeasurements";
    totalCount: number;
    items: Array<
      { __typename?: "WeatherMeasurement" } & WeatherMeasurementFragment
    >;
  } | null;
};

export type WeatherMeasurementQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type WeatherMeasurementQuery = {
  __typename?: "Query";
  weatherMeasurement?:
    | ({ __typename?: "WeatherMeasurement" } & WeatherMeasurementFragment)
    | null;
};

export type WeatherMeasurementCreateMutationVariables = Exact<{
  fields: WeatherMeasurementFields;
}>;

export type WeatherMeasurementCreateMutation = {
  __typename?: "Mutation";
  weatherMeasurementCreate?: {
    __typename?: "WeatherMeasurementCreatePayload";
    result?:
      | ({ __typename?: "WeatherMeasurement" } & WeatherMeasurementFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WeatherMeasurementUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: WeatherMeasurementChanges;
}>;

export type WeatherMeasurementUpdateMutation = {
  __typename?: "Mutation";
  weatherMeasurementUpdate?: {
    __typename?: "WeatherMeasurementUpdatePayload";
    result?:
      | ({ __typename?: "WeatherMeasurement" } & WeatherMeasurementFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WeatherMeasurementDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WeatherMeasurementDestroyMutation = {
  __typename?: "Mutation";
  weatherMeasurementDestroy?: {
    __typename?: "WeatherMeasurementDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WeatherOverviewFragment = {
  __typename?: "WeatherOverview";
  minHumidity?: number | null;
  avgHumidity?: number | null;
  maxHumidity?: number | null;
  minRainfall?: number | null;
  avgRainfall?: number | null;
  maxRainfall?: number | null;
  totalRainfall?: number | null;
  dryDays?: number | null;
  rainyDays?: number | null;
  minUvIndex?: number | null;
  avgUvIndex?: number | null;
  maxUvIndex?: number | null;
  minTemperature?: number | null;
  avgTemperature?: number | null;
  maxTemperature?: number | null;
};

export type WeatherOverviewQueryVariables = Exact<{
  filter?: InputMaybe<WeatherMeasurementsFilterInput>;
}>;

export type WeatherOverviewQuery = {
  __typename?: "Query";
  weatherOverview: { __typename?: "WeatherOverview" } & WeatherOverviewFragment;
};

export type WeatherMeasurementsAggregatedQueryVariables = Exact<{
  filter?: InputMaybe<WeatherMeasurementsAggregatedFilterInput>;
}>;

export type WeatherMeasurementsAggregatedQuery = {
  __typename?: "Query";
  weatherMeasurementsAggregated?: {
    __typename?: "WeatherMeasurementsAggregatedResult";
    minYear?: number | null;
    items: Array<{
      __typename?: "WeatherMeasurementsAggregated";
      measuredAt?: any | null;
      rainfall?: number | null;
    }>;
  } | null;
};

export type WorkOrderInfoFragment = {
  __typename?: "WorkOrder";
  id: string;
  cycleNumber?: number | null;
  documentDate: any;
  activity: { __typename?: "Activity" } & ActivityNameFragment;
};

export type WorkOrderShortFragment = {
  __typename?: "WorkOrder";
  id: string;
  internalId?: string | null;
  cycleNumber?: number | null;
  cropFieldGroup?: string | null;
  documentDate: any;
  status: WorkOrderStatus;
  inventoryStatus: InventoryStatus;
  weekNumber?: number | null;
  createdAt: any;
  updatedAt: any;
  activity: { __typename?: "Activity" } & ActivityNameFragment;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
  cropCycle?: ({ __typename?: "CropCycle" } & CropCycleNameFragment) | null;
  createdBy: { __typename?: "User" } & UserShortFragment;
  updatedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  assignedTo?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type WorkOrderFragment = {
  __typename?: "WorkOrder";
  activity: {
    __typename?: "Activity";
    trackCycles?: boolean | null;
    trackTools?: boolean | null;
    trackTime?: boolean | null;
    startTime?: any | null;
    useTotalArea?: boolean | null;
  } & ActivityShortFragment;
  locality: { __typename?: "Locality" } & LocalityShortFragment;
  cropCycle?:
    | ({
        __typename?: "CropCycle";
        crops: Array<{ __typename?: "Crop"; id: string }>;
      } & CropCycleShortFragment)
    | null;
} & WorkOrderShortFragment;

export type WorkOrderEmployeeFragment = {
  __typename?: "WorkOrderEmployee";
  id: string;
  hours: number;
  overtimeHours: number;
  startTime?: any | null;
  endTime?: any | null;
  attended: boolean;
  employee: {
    __typename?: "Employee";
    discardedAt?: any | null;
  } & EmployeeShortFragment;
  employeeGroup?:
    | ({ __typename?: "EmployeeGroup" } & EmployeeGroupShortFragment)
    | null;
  leaveType?: ({ __typename?: "LeaveType" } & LeaveTypeShortFragment) | null;
  wagePayment?: {
    __typename?: "ActivityPayment";
    wage?: number;
    wageUnit: WorkUnit;
  } | null;
  conflictUsage?:
    | ({ __typename?: "TimeConflict" } & TimeConflictFragment)
    | null;
};

export type WorkOrderCostCenterEmployeeFragment = {
  __typename?: "WorkOrderCostCenterEmployee";
  id: string;
  employeeId: string;
  progress: number;
};

export type WorkOrderCostCenterFragment = {
  __typename?: "WorkOrderCostCenter";
  id: string;
  profitableId?: string | null;
  profitableType?: ProfitableType | null;
  profitableName?: string | null;
  actualProgress?: number | null;
  plannedProgress: number;
  estimatedHarvestAmount?: number | null;
  dayGoal: number;
  employeeHours?: number | null;
  machineHours?: number | null;
  totalProgress: number;
  costCenter: {
    __typename?: "CostCenter";
    discardedAt?: any | null;
  } & CostCenterShortFragment;
  cropField?: ({ __typename?: "CropField" } & CropFieldFragment) | null;
  infrastructure?:
    | ({
        __typename?: "Infrastructure";
        warehouse?:
          | ({ __typename?: "Warehouse" } & WarehouseShortFragment)
          | null;
      } & InfrastructureShortFragment)
    | null;
  machine?: ({ __typename?: "Machine" } & MachineShortFragment) | null;
  employees: Array<
    {
      __typename?: "WorkOrderCostCenterEmployee";
    } & WorkOrderCostCenterEmployeeFragment
  >;
  variants: Array<
    {
      __typename?: "WorkOrderCostCenterVariant";
    } & WorkOrderCostCenterVariantFragment
  >;
  machines: Array<
    {
      __typename?: "WorkOrderCostCenterMachine";
    } & WorkOrderCostCenterMachineFragment
  >;
  metrics: Array<
    {
      __typename?: "WorkOrderCostCenterMetric";
    } & WorkOrderCostCenterMetricFragment
  >;
};

export type WorkOrderVariantFragment = {
  __typename?: "WorkOrderVariant";
  id: string;
  status: InventoryStatus;
  totalAmount: number;
  returnedAmount?: number | null;
  dosage?: number | null;
  conversionFactor?: number | null;
  variant: { __typename?: "Variant" } & VariantShortFragment;
  sourceWarehouse?:
    | ({ __typename?: "Warehouse" } & WarehouseLocalityFragment)
    | null;
  returnWarehouse?:
    | ({ __typename?: "Warehouse" } & WarehouseLocalityFragment)
    | null;
  unit: { __typename?: "Unit" } & UnitShortConversionFragment;
  applicationUnit?:
    | ({ __typename?: "Unit" } & UnitShortConversionFragment)
    | null;
};

export type WorkOrderCostCenterVariantFragment = {
  __typename?: "WorkOrderCostCenterVariant";
  id: string;
  variantId: string;
  amount: number;
  consumedAmount?: number | null;
  dosage?: number | null;
};

export type WorkOrderMachineFragment = {
  __typename?: "WorkOrderMachine";
  id: string;
  startOdometer?: number | null;
  endOdometer?: number | null;
  workHours: number;
  startTime?: any | null;
  endTime?: any | null;
  gear?: number | null;
  speed?: number | null;
  rpm?: number | null;
  pressure?: number | null;
  nozzle?: number | null;
  machine: {
    __typename?: "Machine";
    calibrations?: Array<string> | null;
    discardedAt?: any | null;
  } & MachineShortFragment &
    MachineIrrigationFragment;
  operator?: ({ __typename?: "Employee" } & EmployeeShortFragment) | null;
  implement?:
    | ({
        __typename?: "Machine";
        calibrations?: Array<string> | null;
      } & MachineShortFragment)
    | null;
};

export type WorkOrderWaterUsageFragment = {
  __typename?: "WorkOrderWaterUsage";
  tankCapacity: number;
  tanksRequired: number;
  tanksUsed?: number | null;
  outputRate: number;
  waterAmount: number;
  outputUnit: { __typename?: "Unit" } & UnitShortConversionFragment;
  applicationUnit: { __typename?: "Unit" } & UnitShortConversionFragment;
  waterUnit: { __typename?: "Unit" } & UnitShortConversionFragment;
  waterSources: Array<
    { __typename?: "WaterSource" } & WaterSourceShortFragment
  >;
};

export type WorkOrderMetricFragment = {
  __typename?: "WorkOrderMetric";
  id: string;
  value: number;
  metric: { __typename?: "Metric" } & MetricShortFragment;
};

export type WorkOrderCostCenterMachineFragment = {
  __typename?: "WorkOrderCostCenterMachine";
  id: string;
  machineId: string;
  progress: number;
  hours?: number | null;
};

export type WorkOrderCostCenterMetricFragment = {
  __typename?: "WorkOrderCostCenterMetric";
  id: string;
  metricId: string;
  employeeId?: string | null;
  machineId?: string | null;
  variantId?: string | null;
  value: number;
};

export type WorkOrderVariantStockFragment = {
  __typename?: "WorkOrderVariant";
  variant: {
    __typename?: "Variant";
    stock: { __typename?: "Stock"; onHand: number };
  } & VariantShortFragment;
} & WorkOrderVariantFragment;

export type WorkOrderVariantTokenFragment = {
  __typename?: "WorkOrderVariant";
  variant: {
    __typename?: "Variant";
    token?: { __typename?: "Token"; quantity: number } | null;
  };
} & WorkOrderVariantFragment;

export type WorkOrderVariantSourceFragment = {
  __typename?: "WorkOrderVariantSource";
  id: string;
  amount: number;
  sourceWorkOrderVariant: {
    __typename?: "WorkOrderVariant";
    id: string;
    workOrder: { __typename?: "WorkOrder" } & WorkOrderInfoFragment;
  };
};

export type WorkOrderOutputFragment = {
  __typename?: "WorkOrderVariant";
  stock?: { __typename?: "Stock"; onHand: number } | null;
  sources?: Array<
    { __typename?: "WorkOrderVariantSource" } & WorkOrderVariantSourceFragment
  > | null;
} & WorkOrderVariantFragment;

export type WorkOrderSummaryFragment = {
  __typename?: "WorkOrderSummary";
  attendedCount: number;
  absentCount: number;
  laborCost: number;
  laborManDays: number;
  indirectManDays: number;
  machineCount: number;
  machineHours: number;
  inputCost: number;
  totalGoal: number;
  totalProgress: number;
  laborEfficiency?: number | null;
  machineEfficiency?: number | null;
  totalCost: number;
  payrollCost?: number | null;
};

export type WorkOrderDetailsFragment = {
  __typename?: "WorkOrder";
  progressByEmployee?: boolean | null;
  progressByMachineGroup?: boolean | null;
  requestByTotal?: boolean | null;
  usageByManual?: boolean | null;
  distributeManually?: boolean | null;
  harvestByOutput?: boolean | null;
  workerCount?: number | null;
  workerCost?: number | null;
  workerWage?: number | null;
  estimatedNorm?: number | null;
  areaConversionFactor: number;
  activity: {
    __typename?: "Activity";
    workdayHours: number;
    progressMeasureType: ActivityProgressMeasureType;
    laborForceType: ActivityLaborForceType;
    progressUnit: {
      __typename?: "Unit";
      conversionFactor?: number | null;
    } & UnitShortFragment;
    activityMetrics: Array<
      { __typename?: "ActivityMetric" } & ActivityMetricFragment
    >;
  };
  employees: Array<
    { __typename?: "WorkOrderEmployee" } & WorkOrderEmployeeFragment
  >;
  costCenters: Array<
    { __typename?: "WorkOrderCostCenter" } & WorkOrderCostCenterFragment
  >;
  inputs: Array<{ __typename?: "WorkOrderVariant" } & WorkOrderVariantFragment>;
  tools: Array<{ __typename?: "WorkOrderVariant" } & WorkOrderVariantFragment>;
  outputs: Array<{ __typename?: "WorkOrderVariant" } & WorkOrderOutputFragment>;
  tokens: Array<
    { __typename?: "WorkOrderVariant" } & WorkOrderVariantTokenFragment
  >;
  machines: Array<
    { __typename?: "WorkOrderMachine" } & WorkOrderMachineFragment
  >;
  waterUsage?:
    | ({ __typename?: "WorkOrderWaterUsage" } & WorkOrderWaterUsageFragment)
    | null;
  equipment: Array<{ __typename?: "Equipment" } & EquipmentShortFragment>;
  metrics: Array<{ __typename?: "WorkOrderMetric" } & WorkOrderMetricFragment>;
  summary?:
    | ({ __typename?: "WorkOrderSummary" } & WorkOrderSummaryFragment)
    | null;
} & WorkOrderFragment;

export type WorkOrderStatusQueryVariables = Exact<{ [key: string]: never }>;

export type WorkOrderStatusQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type InventoryStatusFilterQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InventoryStatusFilterQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type WorkOrdersQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<WorkOrdersFilterInput>;
  sort?: InputMaybe<WorkOrdersSortInput>;
}>;

export type WorkOrdersQuery = {
  __typename?: "Query";
  workOrders?: {
    __typename?: "WorkOrders";
    totalCount: number;
    items: Array<{ __typename?: "WorkOrder" } & WorkOrderShortFragment>;
  } | null;
};

export type WorkOrderOutputsQueryVariables = Exact<{
  localityId?: InputMaybe<Scalars["ID"]>;
  locationId?: InputMaybe<Scalars["ID"]>;
  locationType?: InputMaybe<LocationType>;
  virtual?: InputMaybe<VirtualWarehouse>;
  fetchCost?: InputMaybe<Scalars["Boolean"]>;
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<WorkOrdersFilterInput>;
  sort?: InputMaybe<WorkOrdersSortInput>;
}>;

export type WorkOrderOutputsQuery = {
  __typename?: "Query";
  workOrders?: {
    __typename?: "WorkOrders";
    totalCount: number;
    items: Array<
      {
        __typename?: "WorkOrder";
        outputs: Array<
          {
            __typename?: "WorkOrderVariant";
            variant: { __typename?: "Variant" } & StockVariantFragment;
          } & WorkOrderVariantFragment
        >;
      } & WorkOrderInfoFragment
    >;
  } | null;
};

export type WorkOrderQueryVariables = Exact<{
  id: Scalars["ID"];
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WorkOrderQuery = {
  __typename?: "Query";
  workOrder?: ({ __typename?: "WorkOrder" } & WorkOrderDetailsFragment) | null;
};

export type WorkOrderCreateMutationVariables = Exact<{
  fields: WorkOrderFields;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WorkOrderCreateMutation = {
  __typename?: "Mutation";
  workOrderCreate?: {
    __typename?: "WorkOrderCreatePayload";
    result?: ({ __typename?: "WorkOrder" } & WorkOrderDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WorkOrderUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: WorkOrderChanges;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WorkOrderUpdateMutation = {
  __typename?: "Mutation";
  workOrderUpdate?: {
    __typename?: "WorkOrderUpdatePayload";
    result?: ({ __typename?: "WorkOrder" } & WorkOrderDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WorkOrderCloneMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: WorkOrderChanges;
  copyProgress?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WorkOrderCloneMutation = {
  __typename?: "Mutation";
  workOrderClone?: {
    __typename?: "WorkOrderClonePayload";
    jobId: string;
  } | null;
};

export type AddressFragment = {
  __typename?: "Address";
  id: string;
  region?: string | null;
  city?: string | null;
  address?: string | null;
  phoneCode?: string | null;
  phoneNumber?: string | null;
  country: { __typename?: "Country" } & CountryFragment;
};

export type ErrorFragment = {
  __typename?: "UserError";
  path: string;
  message: string;
  value?: string | null;
  count?: string | null;
};

export type DateRangeTypeQueryVariables = Exact<{ [key: string]: never }>;

export type DateRangeTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type BrandFragment = {
  __typename?: "Brand";
  id: string;
  name: string;
  discardedAt?: any | null;
  locked: boolean;
  global: boolean;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type BrandShortFragment = {
  __typename?: "Brand";
  id: string;
  name: string;
};

export type BrandsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<BrandsFilterInput>;
  sort?: InputMaybe<BrandsSortInput>;
}>;

export type BrandsQuery = {
  __typename?: "Query";
  brands?: {
    __typename?: "Brands";
    totalCount: number;
    items: Array<{ __typename?: "Brand" } & BrandFragment>;
  } | null;
};

export type BrandQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BrandQuery = {
  __typename?: "Query";
  brand?: ({ __typename?: "Brand" } & BrandFragment) | null;
};

export type BrandCreateMutationVariables = Exact<{
  fields: BrandFields;
}>;

export type BrandCreateMutation = {
  __typename?: "Mutation";
  brandCreate?: {
    __typename?: "BrandCreatePayload";
    result?: ({ __typename?: "Brand" } & BrandFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BrandUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: BrandChanges;
}>;

export type BrandUpdateMutation = {
  __typename?: "Mutation";
  brandUpdate?: {
    __typename?: "BrandUpdatePayload";
    result?: ({ __typename?: "Brand" } & BrandFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BrandDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BrandDiscardMutation = {
  __typename?: "Mutation";
  brandDiscard?: {
    __typename?: "BrandDiscardPayload";
    result?: ({ __typename?: "Brand" } & BrandFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BrandUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BrandUnlockMutation = {
  __typename?: "Mutation";
  brandUnlock?: {
    __typename?: "BrandUnlockPayload";
    result?: ({ __typename?: "Brand" } & BrandFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BulkDestroyMutationVariables = Exact<{
  destroyableType: DestroyableType;
  ids: Array<Scalars["ID"]> | Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type BulkDestroyMutation = {
  __typename?: "Mutation";
  bulkDestroy?: {
    __typename?: "BulkDestroyPayload";
    result: Array<{
      __typename?: "BulkOperationResult";
      id: string;
      result?: boolean | null;
      errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
    }>;
  } | null;
};

export type BulkDiscardMutationVariables = Exact<{
  discardableType: DiscardableType;
  ids: Array<Scalars["ID"]> | Scalars["ID"];
  discard: Scalars["Boolean"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type BulkDiscardMutation = {
  __typename?: "Mutation";
  bulkDiscard?: {
    __typename?: "BulkDiscardPayload";
    result: Array<{
      __typename?: "BulkOperationResult";
      id: string;
      result?: boolean | null;
      errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
    }>;
  } | null;
};

export type ChangeLogFragment = {
  __typename?: "ChangeLog";
  id: string;
  event: string;
  objectChanges?: any | null;
  createdAt: any;
  user: { __typename?: "User"; avatar?: any | null } & UserShortFragment;
};

export type ChangeLogsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ChangeLogsFilterInput>;
  sort?: InputMaybe<ChangeLogsSortInput>;
}>;

export type ChangeLogsQuery = {
  __typename?: "Query";
  changeLogs?: {
    __typename?: "ChangeLogs";
    totalCount: number;
    items: Array<{ __typename?: "ChangeLog" } & ChangeLogFragment>;
  } | null;
};

export type CommentFragment = {
  __typename?: "Comment";
  id: string;
  content: string;
  files?: Array<any> | null;
  commentableType: CommentableType;
  commentableId: string;
  discardedAt?: any | null;
  createdAt: any;
  updatedAt: any;
  user: { __typename?: "User" } & UserShortFragment;
  mentions?: Array<{ __typename?: "User"; id: string; email: string }> | null;
};

export type CommentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CommentsFilterInput>;
  sort?: InputMaybe<CommentsSortInput>;
}>;

export type CommentsQuery = {
  __typename?: "Query";
  comments?: {
    __typename?: "Comments";
    totalCount: number;
    items: Array<{ __typename?: "Comment" } & CommentFragment>;
  } | null;
};

export type CommentQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CommentQuery = {
  __typename?: "Query";
  comment?: ({ __typename?: "Comment" } & CommentFragment) | null;
};

export type CommentCreateMutationVariables = Exact<{
  fields: CommentFields;
}>;

export type CommentCreateMutation = {
  __typename?: "Mutation";
  commentCreate?: {
    __typename?: "CommentCreatePayload";
    result?: ({ __typename?: "Comment" } & CommentFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CommentUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CommentChanges;
}>;

export type CommentUpdateMutation = {
  __typename?: "Mutation";
  commentUpdate?: {
    __typename?: "CommentUpdatePayload";
    result?: ({ __typename?: "Comment" } & CommentFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CommentDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CommentDiscardMutation = {
  __typename?: "Mutation";
  commentDiscard?: {
    __typename?: "CommentDiscardPayload";
    result?: ({ __typename?: "Comment" } & CommentFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CounterpartyFragment = {
  __typename?: "Counterparty";
  id: string;
  name: string;
  businessName: string;
  internalId?: string | null;
  taxIdNumber?: string | null;
  taxRate?: number | null;
  discardedAt?: any | null;
  address?: ({ __typename?: "Address" } & AddressFragment) | null;
  vendorPaymentMethod?: {
    __typename?: "PaymentMethod";
    id: string;
    name: string;
  } | null;
  customerPaymentMethod?: {
    __typename?: "PaymentMethod";
    id: string;
    name: string;
  } | null;
};

export type CounterpartyDetailsFragment = {
  __typename?: "Counterparty";
  withholdingRate?: number | null;
  payableAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
  receivableAccount?:
    | ({ __typename?: "Account" } & AccountShortFragment)
    | null;
  withholdingAccount?:
    | ({ __typename?: "Account" } & AccountShortFragment)
    | null;
  vatAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
  taxPlan?: ({ __typename?: "TaxPlan" } & TaxPlanWithComponentsFragment) | null;
} & CounterpartyFragment;

export type CounterpartiesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CounterpartiesFilterInput>;
  sort?: InputMaybe<CounterpartiesSortInput>;
  fetchTaxPlan?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CounterpartiesQuery = {
  __typename?: "Query";
  counterparties?: {
    __typename?: "Counterparties";
    totalCount: number;
    items: Array<
      {
        __typename?: "Counterparty";
        taxPlan?:
          | ({ __typename?: "TaxPlan" } & TaxPlanWithComponentsFragment)
          | null;
      } & CounterpartyFragment
    >;
  } | null;
};

export type CounterpartyQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CounterpartyQuery = {
  __typename?: "Query";
  counterparty?:
    | ({ __typename?: "Counterparty" } & CounterpartyDetailsFragment)
    | null;
};

export type CounterpartyCreateMutationVariables = Exact<{
  fields: CounterpartyFields;
}>;

export type CounterpartyCreateMutation = {
  __typename?: "Mutation";
  counterpartyCreate?: {
    __typename?: "CounterpartyCreatePayload";
    result?:
      | ({ __typename?: "Counterparty" } & CounterpartyDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CounterpartyUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CounterpartyChanges;
}>;

export type CounterpartyUpdateMutation = {
  __typename?: "Mutation";
  counterpartyUpdate?: {
    __typename?: "CounterpartyUpdatePayload";
    result?:
      | ({ __typename?: "Counterparty" } & CounterpartyDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CounterpartyDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CounterpartyDiscardMutation = {
  __typename?: "Mutation";
  counterpartyDiscard?: {
    __typename?: "CounterpartyDiscardPayload";
    result?: ({ __typename?: "Counterparty" } & CounterpartyFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CounterpartyDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CounterpartyDestroyMutation = {
  __typename?: "Mutation";
  counterpartyDestroy?: {
    __typename?: "CounterpartyDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CounterpartyBulkUpdateMutationVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
  changes: CounterpartyBulkChanges;
}>;

export type CounterpartyBulkUpdateMutation = {
  __typename?: "Mutation";
  counterpartyBulkUpdate?: {
    __typename?: "CounterpartyBulkBulkUpdatePayload";
    result: Array<{
      __typename?: "BulkOperationResult";
      id: string;
      result?: boolean | null;
      errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
    }>;
  } | null;
};

export type CurrencyFragment = {
  __typename?: "Currency";
  id: string;
  isoCode: string;
  name: string;
  symbol: string;
};

export type CountryFragment = {
  __typename?: "Country";
  code: string;
  name: string;
  emojiFlag: string;
  phoneCode: string;
  ssnName: string;
  taxId: string;
  taxIdFormat?: string | null;
  nationalId: string;
  nationalIdFormat?: string | null;
};

export type CountriesQueryVariables = Exact<{ [key: string]: never }>;

export type CountriesQuery = {
  __typename?: "Query";
  countries?: Array<
    {
      __typename?: "Country";
      regions: Array<string>;
      currency: { __typename?: "Currency" } & CurrencyFragment;
    } & CountryFragment
  > | null;
};

export type EmployeeGroupFragment = {
  __typename?: "EmployeeGroup";
  id: string;
  name: string;
  employeesCount: number;
  discardedAt?: any | null;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
  employees: Array<{ __typename?: "Employee" } & EmployeeShortFragment>;
};

export type EmployeeGroupShortFragment = {
  __typename?: "EmployeeGroup";
  id: string;
  name: string;
  employeesCount: number;
};

export type EmployeeGroupsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<EmployeeGroupsFilterInput>;
  sort?: InputMaybe<EmployeeGroupsSortInput>;
}>;

export type EmployeeGroupsQuery = {
  __typename?: "Query";
  employeeGroups?: {
    __typename?: "EmployeeGroups";
    totalCount: number;
    items: Array<{ __typename?: "EmployeeGroup" } & EmployeeGroupFragment>;
  } | null;
};

export type EmployeeGroupOptionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<EmployeeGroupsFilterInput>;
  sort?: InputMaybe<EmployeeGroupsSortInput>;
}>;

export type EmployeeGroupOptionsQuery = {
  __typename?: "Query";
  employeeGroups?: {
    __typename?: "EmployeeGroups";
    items: Array<
      {
        __typename?: "EmployeeGroup";
        locality: { __typename?: "Locality" } & LocalityNameFragment;
      } & EmployeeGroupShortFragment
    >;
  } | null;
};

export type EmployeeGroupOptionsWithWagesQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<EmployeeGroupsFilterInput>;
  sort?: InputMaybe<EmployeeGroupsSortInput>;
  wagePaymentFilter?: InputMaybe<EmployeeWageFilterInput>;
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EmployeeGroupOptionsWithWagesQuery = {
  __typename?: "Query";
  employeeGroups?: {
    __typename?: "EmployeeGroups";
    items: Array<
      {
        __typename?: "EmployeeGroup";
        employees: Array<
          { __typename?: "Employee" } & EmployeeShortWithWageFragment
        >;
      } & EmployeeGroupShortFragment
    >;
  } | null;
};

export type EmployeeGroupQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EmployeeGroupQuery = {
  __typename?: "Query";
  employeeGroup?:
    | ({ __typename?: "EmployeeGroup" } & EmployeeGroupFragment)
    | null;
};

export type EmployeeGroupCreateMutationVariables = Exact<{
  fields: EmployeeGroupFields;
}>;

export type EmployeeGroupCreateMutation = {
  __typename?: "Mutation";
  employeeGroupCreate?: {
    __typename?: "EmployeeGroupCreatePayload";
    result?: ({ __typename?: "EmployeeGroup" } & EmployeeGroupFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type EmployeeGroupUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: EmployeeGroupChanges;
}>;

export type EmployeeGroupUpdateMutation = {
  __typename?: "Mutation";
  employeeGroupUpdate?: {
    __typename?: "EmployeeGroupUpdatePayload";
    result?: ({ __typename?: "EmployeeGroup" } & EmployeeGroupFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type EmployeeGroupDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type EmployeeGroupDiscardMutation = {
  __typename?: "Mutation";
  employeeGroupDiscard?: {
    __typename?: "EmployeeGroupDiscardPayload";
    result?: ({ __typename?: "EmployeeGroup" } & EmployeeGroupFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type EmployeeFragment = {
  __typename?: "Employee";
  id: string;
  internalId?: string | null;
  firstName: string;
  lastName: string;
  gender: Gender;
  birthdate?: any | null;
  nationalId?: string | null;
  nationalIdFormatted?: string | null;
  driverLicenseId?: string | null;
  taxIdNumber?: string | null;
  taxIdNumberFormatted?: string | null;
  socialSecurityNumber?: string | null;
  discardedAt?: any | null;
  position: { __typename?: "Position" } & PositionFragment;
  localities: Array<{ __typename?: "Locality"; id: string; name: string }>;
  address: { __typename?: "Address" } & AddressFragment;
  employeeGroups: Array<{
    __typename?: "EmployeeGroup";
    id: string;
    name: string;
  }>;
  basePayment?:
    | ({ __typename?: "ActivityPayment" } & ActivityPaymentShortFragment)
    | null;
  salaryStructure?:
    | ({ __typename?: "SalaryStructure" } & SalaryStructureShortFragment)
    | null;
  bankAccount?: ({ __typename?: "BankAccount" } & BankAccountFragment) | null;
};

export type EmployeeShortFragment = {
  __typename?: "Employee";
  id: string;
  internalId?: string | null;
  firstName: string;
  lastName: string;
  position: {
    __typename?: "Position";
    id: string;
    name: string;
    positionType: PositionType;
  };
};

export type EmployeesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<EmployeesFilterInput>;
  sort?: InputMaybe<EmployeesSortInput>;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EmployeesQuery = {
  __typename?: "Query";
  employees?: {
    __typename?: "Employees";
    totalCount: number;
    items: Array<{ __typename?: "Employee" } & EmployeeFragment>;
  } | null;
};

export type EmployeeQueryVariables = Exact<{
  id: Scalars["ID"];
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EmployeeQuery = {
  __typename?: "Query";
  employee?: ({ __typename?: "Employee" } & EmployeeFragment) | null;
};

export type TimeConflictFragment = {
  __typename?: "TimeConflict";
  startTime?: any | null;
  endTime?: any | null;
  workOrder: {
    __typename?: "WorkOrder";
    id: string;
    cycleNumber?: number | null;
    activity: { __typename?: "Activity" } & ActivityNameFragment;
  };
};

export type EmployeeShortWithWageFragment = {
  __typename?: "Employee";
  wagePayment?: {
    __typename?: "ActivityPayment";
    id: string;
    wage?: number;
    wageUnit: WorkUnit;
  } | null;
  conflictUsage?:
    | ({ __typename?: "TimeConflict" } & TimeConflictFragment)
    | null;
  leave?:
    | ({ __typename?: "LeaveAssignmentEmployee" } & EmployeeLeaveFragment)
    | null;
} & EmployeeShortFragment;

export type EmployeeOptionsWithWagesQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<EmployeesFilterInput>;
  sort?: InputMaybe<EmployeesSortInput>;
  wagePaymentFilter?: InputMaybe<EmployeeWageFilterInput>;
  documentDate?: InputMaybe<Scalars["ISO8601DateTime"]>;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EmployeeOptionsWithWagesQuery = {
  __typename?: "Query";
  employees?: {
    __typename?: "Employees";
    items: Array<{ __typename?: "Employee" } & EmployeeShortWithWageFragment>;
  } | null;
};

export type EmployeeCreateMutationVariables = Exact<{
  fields: EmployeeFields;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EmployeeCreateMutation = {
  __typename?: "Mutation";
  employeeCreate?: {
    __typename?: "EmployeeCreatePayload";
    result?: ({ __typename?: "Employee" } & EmployeeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type EmployeeUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: EmployeeChanges;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EmployeeUpdateMutation = {
  __typename?: "Mutation";
  employeeUpdate?: {
    __typename?: "EmployeeUpdatePayload";
    result?: ({ __typename?: "Employee" } & EmployeeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type EmployeeDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EmployeeDiscardMutation = {
  __typename?: "Mutation";
  employeeDiscard?: {
    __typename?: "EmployeeDiscardPayload";
    result?: ({ __typename?: "Employee" } & EmployeeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type EmployeeDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type EmployeeDestroyMutation = {
  __typename?: "Mutation";
  employeeDestroy?: {
    __typename?: "EmployeeDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type EmployeeBulkUpdateMutationVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
  changes: EmployeeBulkChanges;
}>;

export type EmployeeBulkUpdateMutation = {
  __typename?: "Mutation";
  employeeBulkUpdate?: {
    __typename?: "EmployeeBulkBulkUpdatePayload";
    result: Array<{
      __typename?: "BulkOperationResult";
      id: string;
      result?: boolean | null;
      errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
    }>;
  } | null;
};

export type ExchangeRateQueryVariables = Exact<{
  from: Scalars["String"];
  to: Scalars["String"];
  date?: InputMaybe<Scalars["ISO8601Date"]>;
}>;

export type ExchangeRateQuery = {
  __typename?: "Query";
  exchangeRate?: number | null;
};

export type ImportLogFragment = {
  __typename?: "ImportLog";
  id: string;
  createdAt: any;
  row?: number | null;
  importErrors?: Array<
    { __typename?: "UserError"; attribute?: string | null } & ErrorFragment
  > | null;
};

export type ImportLogsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ImportLogsFilterInput>;
  sort?: InputMaybe<ImportLogsSortInput>;
}>;

export type ImportLogsQuery = {
  __typename?: "Query";
  importLogs?: {
    __typename?: "ImportLogs";
    totalCount: number;
    items: Array<{ __typename?: "ImportLog" } & ImportLogFragment>;
  } | null;
};

export type ImportFragment = {
  __typename?: "Import";
  id: string;
  moduleName: ImportModulesType;
  file?: any | null;
  status: ImportStatusType;
  kind: ImportKindType;
  createdAt: any;
  updatedAt: any;
  totalCount: number;
  skippedCount: number;
  discardedCount?: number | null;
  restoredCount?: number | null;
  localeCode: string;
  user: { __typename?: "User" } & UserShortFragment;
  fileInfo?: {
    __typename?: "FileInfo";
    id: string;
    url: string;
    filename: string;
  } | null;
};

export type ImportStatusTypeQueryVariables = Exact<{ [key: string]: never }>;

export type ImportStatusTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type ImportsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ImportsFilterInput>;
  sort?: InputMaybe<ImportsSortInput>;
}>;

export type ImportsQuery = {
  __typename?: "Query";
  imports?: {
    __typename?: "Imports";
    totalCount: number;
    items: Array<{ __typename?: "Import" } & ImportFragment>;
  } | null;
};

export type ImportQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ImportQuery = {
  __typename?: "Query";
  import?:
    | ({
        __typename?: "Import";
        missingRequiredEntities: Array<string>;
      } & ImportFragment)
    | null;
};

export type ImportCreateMutationVariables = Exact<{
  fields: ImportFields;
}>;

export type ImportCreateMutation = {
  __typename?: "Mutation";
  importCreate?: {
    __typename?: "ImportCreatePayload";
    result?: ({ __typename?: "Import" } & ImportFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ImportUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: ImportChanges;
}>;

export type ImportUpdateMutation = {
  __typename?: "Mutation";
  importUpdate?: {
    __typename?: "ImportUpdatePayload";
    result?: ({ __typename?: "Import" } & ImportFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ImportFireEventMutationVariables = Exact<{
  id: Scalars["ID"];
  event: ImportEventType;
}>;

export type ImportFireEventMutation = {
  __typename?: "Mutation";
  importFireEvent?: {
    __typename?: "FireEventMutationPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type InfrastructureKindFragment = {
  __typename?: "InfrastructureKind";
  id: string;
  name: string;
  category: InfrastructureCategory;
};

export type InfrastructureFragment = {
  __typename?: "Infrastructure";
  id: string;
  name: string;
  total?: number | null;
  discardedAt?: any | null;
  internalId?: string | null;
  kind: { __typename?: "InfrastructureKind" } & InfrastructureKindFragment;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
  unit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
  costCenter?: ({ __typename?: "CostCenter" } & CostCenterShortFragment) | null;
  warehouse?: ({ __typename?: "Warehouse" } & WarehouseShortFragment) | null;
};

export type InfrastructureShortFragment = {
  __typename?: "Infrastructure";
  id: string;
  name: string;
  total?: number | null;
  kind: { __typename?: "InfrastructureKind" } & InfrastructureKindFragment;
  unit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
};

export type InfrastructureKindsQueryVariables = Exact<{
  filter?: InputMaybe<InfrastructureKindsFilterInput>;
  sort?: InputMaybe<InfrastructureKindsSortInput>;
}>;

export type InfrastructureKindsQuery = {
  __typename?: "Query";
  infrastructureKinds?: {
    __typename?: "InfrastructureKinds";
    totalCount: number;
    items: Array<
      { __typename?: "InfrastructureKind" } & InfrastructureKindFragment
    >;
  } | null;
};

export type InfrastructuresQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<InfrastructuresFilterInput>;
  sort?: InputMaybe<InfrastructuresSortInput>;
  fetchActions?: InputMaybe<Scalars["Boolean"]>;
}>;

export type InfrastructuresQuery = {
  __typename?: "Query";
  infrastructures?: {
    __typename?: "Infrastructures";
    totalCount: number;
    items: Array<
      {
        __typename?: "Infrastructure";
        mayDiscard?: boolean;
        mayDestroy?: boolean;
      } & InfrastructureFragment
    >;
  } | null;
};

export type InfrastructureQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InfrastructureQuery = {
  __typename?: "Query";
  infrastructure?:
    | ({ __typename?: "Infrastructure" } & InfrastructureFragment)
    | null;
};

export type InfrastructureCreateMutationVariables = Exact<{
  fields: InfrastructureFields;
}>;

export type InfrastructureCreateMutation = {
  __typename?: "Mutation";
  infrastructureCreate?: {
    __typename?: "InfrastructureCreatePayload";
    result?:
      | ({ __typename?: "Infrastructure" } & InfrastructureFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type InfrastructureUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: InfrastructureChanges;
}>;

export type InfrastructureUpdateMutation = {
  __typename?: "Mutation";
  infrastructureUpdate?: {
    __typename?: "InfrastructureUpdatePayload";
    result?:
      | ({ __typename?: "Infrastructure" } & InfrastructureFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type InfrastructureDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type InfrastructureDiscardMutation = {
  __typename?: "Mutation";
  infrastructureDiscard?: {
    __typename?: "InfrastructureDiscardPayload";
    result?:
      | ({ __typename?: "Infrastructure" } & InfrastructureFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type InfrastructureDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type InfrastructureDestroyMutation = {
  __typename?: "Mutation";
  infrastructureDestroy?: {
    __typename?: "InfrastructureDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type JobStatusQueryVariables = Exact<{
  jobId: Scalars["ID"];
}>;

export type JobStatusQuery = { __typename?: "Query"; jobStatus: any };

export type LeaveTypeFragment = {
  __typename?: "LeaveType";
  id: string;
  name: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  kind: LeaveKind;
  internalId?: string | null;
  payable: boolean;
  discardedAt?: any | null;
};

export type LeaveTypeShortFragment = {
  __typename?: "LeaveType";
  id: string;
  name: string;
  kind: LeaveKind;
  payable: boolean;
};

export type LeaveKindQueryVariables = Exact<{ [key: string]: never }>;

export type LeaveKindQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type LeaveTypesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<LeaveTypesFilterInput>;
  sort?: InputMaybe<LeaveTypesSortInput>;
}>;

export type LeaveTypesQuery = {
  __typename?: "Query";
  leaveTypes?: {
    __typename?: "LeaveTypes";
    totalCount: number;
    items: Array<{ __typename?: "LeaveType" } & LeaveTypeFragment>;
  } | null;
};

export type LeaveTypeQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LeaveTypeQuery = {
  __typename?: "Query";
  leaveType?: ({ __typename?: "LeaveType" } & LeaveTypeFragment) | null;
};

export type LeaveTypeCreateMutationVariables = Exact<{
  fields: LeaveTypeFields;
}>;

export type LeaveTypeCreateMutation = {
  __typename?: "Mutation";
  leaveTypeCreate?: {
    __typename?: "LeaveTypeCreatePayload";
    result?: ({ __typename?: "LeaveType" } & LeaveTypeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type LeaveTypeUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: LeaveTypeChanges;
}>;

export type LeaveTypeUpdateMutation = {
  __typename?: "Mutation";
  leaveTypeUpdate?: {
    __typename?: "LeaveTypeUpdatePayload";
    result?: ({ __typename?: "LeaveType" } & LeaveTypeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type LeaveTypeDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LeaveTypeDiscardMutation = {
  __typename?: "Mutation";
  leaveTypeDiscard?: {
    __typename?: "LeaveTypeDiscardPayload";
    result?: ({ __typename?: "LeaveType" } & LeaveTypeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type LocalityNameFragment = {
  __typename?: "Locality";
  id: string;
  name: string;
};

export type LocalityShortFragment = {
  __typename?: "Locality";
  areaUnit: {
    __typename?: "Unit";
    conversionFactor?: number | null;
  } & UnitShortFragment;
} & LocalityNameFragment;

export type LocalityFragment = {
  __typename?: "Locality";
  effectiveArea: number;
  internalId?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  geoJson?: any | null;
  totalArea: number;
  discardedAt?: any | null;
  mayUpdateAreaUnit: boolean;
} & LocalityShortFragment;

export type LocalitiesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<LocalitiesFilterInput>;
  sort?: InputMaybe<LocalitiesSortInput>;
  index?: InputMaybe<Scalars["Boolean"]>;
}>;

export type LocalitiesQuery = {
  __typename?: "Query";
  localities?: {
    __typename?: "Localities";
    totalCount: number;
    items: Array<
      {
        __typename?: "Locality";
        mayDiscard?: boolean;
        mayDestroy?: boolean;
      } & LocalityFragment
    >;
  } | null;
};

export type LocalityQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LocalityQuery = {
  __typename?: "Query";
  locality?: ({ __typename?: "Locality" } & LocalityFragment) | null;
};

export type LocalityCreateMutationVariables = Exact<{
  fields: LocalityFields;
}>;

export type LocalityCreateMutation = {
  __typename?: "Mutation";
  localityCreate?: {
    __typename?: "LocalityCreatePayload";
    result?: ({ __typename?: "Locality" } & LocalityFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type LocalityUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: LocalityChanges;
}>;

export type LocalityUpdateMutation = {
  __typename?: "Mutation";
  localityUpdate?: {
    __typename?: "LocalityUpdatePayload";
    result?: ({ __typename?: "Locality" } & LocalityFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type LocalityDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LocalityDiscardMutation = {
  __typename?: "Mutation";
  localityDiscard?: {
    __typename?: "LocalityDiscardPayload";
    result?: ({ __typename?: "Locality" } & LocalityFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type LocalityDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type LocalityDestroyMutation = {
  __typename?: "Mutation";
  localityDestroy?: {
    __typename?: "LocalityDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MachineKindFragment = {
  __typename?: "MachineKind";
  id: string;
  name: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  category: MachineCategory;
  locked: boolean;
  global: boolean;
  discardedAt?: any | null;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type MachineKindShortFragment = {
  __typename?: "MachineKind";
  id: string;
  name: string;
  category: MachineCategory;
};

export type MachineCategoryQueryVariables = Exact<{ [key: string]: never }>;

export type MachineCategoryQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type MachineKindsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<MachineKindsFilterInput>;
  sort?: InputMaybe<MachineKindsSortInput>;
}>;

export type MachineKindsQuery = {
  __typename?: "Query";
  machineKinds?: {
    __typename?: "MachineKinds";
    totalCount: number;
    items: Array<{ __typename?: "MachineKind" } & MachineKindFragment>;
  } | null;
};

export type MachineKindQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MachineKindQuery = {
  __typename?: "Query";
  machineKind?: ({ __typename?: "MachineKind" } & MachineKindFragment) | null;
};

export type MachineKindCreateMutationVariables = Exact<{
  fields: MachineKindFields;
}>;

export type MachineKindCreateMutation = {
  __typename?: "Mutation";
  machineKindCreate?: {
    __typename?: "MachineKindCreatePayload";
    result?: ({ __typename?: "MachineKind" } & MachineKindFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MachineKindUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: MachineKindChanges;
}>;

export type MachineKindUpdateMutation = {
  __typename?: "Mutation";
  machineKindUpdate?: {
    __typename?: "MachineKindUpdatePayload";
    result?: ({ __typename?: "MachineKind" } & MachineKindFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MachineKindDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MachineKindDiscardMutation = {
  __typename?: "Mutation";
  machineKindDiscard?: {
    __typename?: "MachineKindDiscardPayload";
    result?: ({ __typename?: "MachineKind" } & MachineKindFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MachineKindUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MachineKindUnlockMutation = {
  __typename?: "Mutation";
  machineKindUnlock?: {
    __typename?: "MachineKindUnlockPayload";
    result?: ({ __typename?: "MachineKind" } & MachineKindFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MachineFragment = {
  __typename?: "Machine";
  id: string;
  name: string;
  model: string;
  year: number;
  licensePlate?: string | null;
  odometerValue?: number | null;
  horometerValue?: number | null;
  fuelType?: FuelType | null;
  calibrations?: Array<string> | null;
  photo?: any | null;
  discardedAt?: any | null;
  internalId?: string | null;
  kind: { __typename?: "MachineKind" } & MachineKindShortFragment;
  brand: { __typename?: "Brand" } & BrandShortFragment;
  machineLocalities: Array<{
    __typename?: "MachineLocality";
    id: string;
    locality: { __typename?: "Locality" } & LocalityNameFragment;
    costCenter?:
      | ({ __typename?: "CostCenter" } & CostCenterShortFragment)
      | null;
  }>;
  odometerUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
  horometerUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
  fuelUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
} & MachineIrrigationFragment;

export type MachineIrrigationFragment = {
  __typename?: "Machine";
  capacityValue?: number | null;
  capacityTime?: number | null;
  capacityUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
  capacityTimeUnit?:
    | ({ __typename?: "Unit" } & UnitShortConversionFragment)
    | null;
  cropFieldMachines: Array<
    { __typename?: "CropFieldMachine" } & CropFieldMachineFragment
  >;
};

export type CropFieldMachineFragment = {
  __typename?: "CropFieldMachine";
  id: string;
  capacityValue?: number | null;
  cropField: { __typename?: "CropField" } & CropFieldFragment;
};

export type MachineDetailsFragment = {
  __typename?: "Machine";
  vin?: string | null;
  fuelUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
} & MachineFragment;

export type MachineShortFragment = {
  __typename?: "Machine";
  id: string;
  name: string;
  odometerValue?: number | null;
  horometerValue?: number | null;
  kind: { __typename?: "MachineKind" } & MachineKindShortFragment;
  odometerUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
  horometerUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
};

export type FuelTypesQueryVariables = Exact<{ [key: string]: never }>;

export type FuelTypesQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type MachinesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<MachinesFilterInput>;
  sort?: InputMaybe<MachinesSortInput>;
  fetchCostCenter?: InputMaybe<Scalars["Boolean"]>;
  localityId?: InputMaybe<Scalars["ID"]>;
}>;

export type MachinesQuery = {
  __typename?: "Query";
  machines?: {
    __typename?: "Machines";
    totalCount: number;
    items: Array<
      {
        __typename?: "Machine";
        costCenter?:
          | ({ __typename?: "CostCenter" } & CostCenterShortFragment)
          | null;
      } & MachineFragment
    >;
  } | null;
};

export type MachineQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MachineQuery = {
  __typename?: "Query";
  machine?: ({ __typename?: "Machine" } & MachineDetailsFragment) | null;
};

export type MachineCreateMutationVariables = Exact<{
  fields: MachineFields;
}>;

export type MachineCreateMutation = {
  __typename?: "Mutation";
  machineCreate?: {
    __typename?: "MachineCreatePayload";
    result?: ({ __typename?: "Machine" } & MachineDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MachineUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: MachineChanges;
}>;

export type MachineUpdateMutation = {
  __typename?: "Mutation";
  machineUpdate?: {
    __typename?: "MachineUpdatePayload";
    result?: ({ __typename?: "Machine" } & MachineDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MachineDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MachineDiscardMutation = {
  __typename?: "Mutation";
  machineDiscard?: {
    __typename?: "MachineDiscardPayload";
    result?: ({ __typename?: "Machine" } & MachineDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type MachineDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type MachineDestroyMutation = {
  __typename?: "Mutation";
  machineDestroy?: {
    __typename?: "MachineDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type OnboardingStatusQueryVariables = Exact<{ [key: string]: never }>;

export type OnboardingStatusQuery = {
  __typename?: "Query";
  onboardingStatus: {
    __typename?: "OnboardingStatus";
    status: Array<{
      __typename?: "OnboardingStepStatus";
      finished: boolean;
      step: OnboardingStep;
      permission: string;
    }>;
  };
};

export type RwdPermissionsFragment = {
  __typename?: "RWDPermissions";
  read: boolean;
  write: boolean;
  delete: boolean;
};

export type PermissionsFragment = {
  __typename?: "Permissions";
  tenant?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  user?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  role?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  unit?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  locality?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  position?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  employee?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  employeeGroup?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  machineKind?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  machine?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  infrastructure?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  infrastructureKind?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  comment?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  itemCategory?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  item?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  recipe?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  paymentMethod?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  counterparty?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  transfer?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  purchase?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  sale?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  ingredient?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  crop?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  disease?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  cropCycle?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  cropField?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  activityCategory?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  cropStage?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  activity?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  metric?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  workOrder?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  account?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  costCenter?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  journalEntry?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  financeOrder?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  financeInvoice?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  leaveType?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  brand?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  inventoryRequest?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  fiscalYear?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  holiday?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  harvestEstimate?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  leaveAssignment?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  planning?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  expenseCategory?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  expenseItem?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  weatherMeasurement?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  changeLog?: { __typename?: "Change_logPermissions"; read: boolean } | null;
  settings?: {
    __typename?: "SettingsPermissions";
    company: boolean;
    import: boolean;
    updateTenants: boolean;
    editPlanning: boolean;
    showCost: boolean;
    showWage: boolean;
    testMode: boolean;
    resetTestMode: boolean;
    resetPassword: boolean;
    billing: boolean;
  } | null;
  salaryStructure?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  payrollEntry?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  adjustmentType?:
    | ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment)
    | null;
  taxPlan?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  bank?: ({ __typename?: "RWDPermissions" } & RwdPermissionsFragment) | null;
  report?: {
    __typename?: "ReportPermissions";
    stock: boolean;
    journal: boolean;
    inventoryMovement: boolean;
    ledger: boolean;
    activity: boolean;
    labor: boolean;
    cropField: boolean;
    trialBalance: boolean;
    balanceSheet: boolean;
    token: boolean;
    harvest: boolean;
    payroll: boolean;
    workOrder: boolean;
    expense: boolean;
    inputCostCenter: boolean;
    incomeStatement: boolean;
    machine: boolean;
  } | null;
  farmMap?: {
    __typename?: "Farm_mapPermissions";
    cropStages: boolean;
    yields: boolean;
    costs: boolean;
    activities: boolean;
  } | null;
};

export type PositionFragment = {
  __typename?: "Position";
  id: string;
  name: string;
  positionType: PositionType;
  discardedAt?: any | null;
  contractType?: ContractType | null;
  mayDestroy: boolean;
  basePayment?:
    | ({ __typename?: "ActivityPayment" } & ActivityPaymentShortFragment)
    | null;
  salaryStructure?:
    | ({ __typename?: "SalaryStructure" } & SalaryStructureShortFragment)
    | null;
};

export type PositionShortFragment = {
  __typename?: "Position";
  id: string;
  name: string;
  positionType: PositionType;
};

export type PositionTypeQueryVariables = Exact<{ [key: string]: never }>;

export type PositionTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type PositionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<PositionsFilterInput>;
  sort?: InputMaybe<PositionsSortInput>;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PositionsQuery = {
  __typename?: "Query";
  positions?: {
    __typename?: "Positions";
    totalCount: number;
    items: Array<{ __typename?: "Position" } & PositionFragment>;
  } | null;
};

export type PositionQueryVariables = Exact<{
  id: Scalars["ID"];
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PositionQuery = {
  __typename?: "Query";
  position?: ({ __typename?: "Position" } & PositionFragment) | null;
};

export type PositionCreateMutationVariables = Exact<{
  fields: PositionFields;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PositionCreateMutation = {
  __typename?: "Mutation";
  positionCreate?: {
    __typename?: "PositionCreatePayload";
    result?: ({ __typename?: "Position" } & PositionFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PositionUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: PositionChanges;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PositionUpdateMutation = {
  __typename?: "Mutation";
  positionUpdate?: {
    __typename?: "PositionUpdatePayload";
    result?: ({ __typename?: "Position" } & PositionFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PositionDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PositionDiscardMutation = {
  __typename?: "Mutation";
  positionDiscard?: {
    __typename?: "PositionDiscardPayload";
    result?: ({ __typename?: "Position" } & PositionFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PositionDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PositionDestroyMutation = {
  __typename?: "Mutation";
  positionDestroy?: {
    __typename?: "PositionDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type RoleShortFragment = {
  __typename?: "Role";
  id: string;
  name: string;
  createdAt: any;
  discardedAt?: any | null;
  permissions: { __typename?: "Permissions" } & PermissionsFragment;
};

export type RoleAuthorizedFragment = {
  __typename?: "Role";
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canUpdatePermissions: { __typename?: "AuthorizationResult"; value: boolean };
  canDiscard: { __typename?: "AuthorizationResult"; value: boolean };
};

export type RoleFragment = { __typename?: "Role" } & RoleShortFragment &
  RoleAuthorizedFragment;

export type RolesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<RolesFilterInput>;
  sort?: InputMaybe<RolesSortInput>;
  public?: InputMaybe<Scalars["Boolean"]>;
}>;

export type RolesQuery = {
  __typename?: "Query";
  roles?: {
    __typename?: "Roles";
    totalCount: number;
    items: Array<
      { __typename?: "Role" } & RoleShortFragment & RoleAuthorizedFragment
    >;
  } | null;
};

export type RoleQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RoleQuery = {
  __typename?: "Query";
  role?: ({ __typename?: "Role" } & RoleFragment) | null;
};

export type RoleCreateMutationVariables = Exact<{
  fields: RoleFields;
}>;

export type RoleCreateMutation = {
  __typename?: "Mutation";
  roleCreate?: {
    __typename?: "RoleCreatePayload";
    result?: ({ __typename?: "Role" } & RoleFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type RoleUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: RoleChanges;
}>;

export type RoleUpdateMutation = {
  __typename?: "Mutation";
  roleUpdate?: {
    __typename?: "RoleUpdatePayload";
    result?: ({ __typename?: "Role" } & RoleFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type RoleDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RoleDiscardMutation = {
  __typename?: "Mutation";
  roleDiscard?: {
    __typename?: "RoleDiscardPayload";
    result?: ({ __typename?: "Role" } & RoleFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TagFragment = { __typename?: "Tag"; id: string; name: string };

export type TagsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TagsFilterInput>;
  sort?: InputMaybe<TagsSortInput>;
}>;

export type TagsQuery = {
  __typename?: "Query";
  tags?: {
    __typename?: "Tags";
    totalCount: number;
    items: Array<{ __typename?: "Tag" } & TagFragment>;
  } | null;
};

export type TenantSettingsFragment = {
  __typename?: "Tenant";
  allowLeaveAssignments?: boolean | null;
  financeEntriesDisabled?: boolean | null;
  useExternalPolicy?: boolean | null;
  inventoryRequestEnabled?: boolean | null;
  overtimeEnabled?: boolean | null;
  overtimeFactor?: number | null;
  holidayOvertimeFactor?: number | null;
  nonWorkDayOvertimeFactor?: number | null;
  attendanceEnabled?: boolean | null;
  timeTrackingEnabled?: boolean | null;
  multifarmInventoryEnabled?: boolean | null;
  userLimit?: number | null;
  woLimit?: number | null;
};

export type TenantFeaturesFragment = {
  __typename?: "TenantFeatures";
  accounting?: boolean | null;
  planning?: boolean | null;
  tokens?: boolean | null;
  exactus?: boolean | null;
  agroprime?: boolean | null;
  payroll?: boolean | null;
  testMode?: boolean | null;
  taxPlan?: boolean | null;
  inventoryStock?: boolean | null;
  costCenters?: boolean | null;
  workOrderTypes?: boolean | null;
  enabledWorkOrderTypes?: Array<WorkOrderType> | null;
  customCropVarieties?: boolean | null;
  advancedActivities?: boolean | null;
  machinery?: boolean | null;
  infrastructures?: boolean | null;
  reusableItems?: boolean | null;
  customCropStages?: boolean | null;
  multipleFarms?: boolean | null;
  employees?: boolean | null;
  holidays?: boolean | null;
  workSchedules?: boolean | null;
  expenses?: boolean | null;
  banks?: boolean | null;
};

export type TenantFragment = {
  __typename?: "Tenant";
  id: string;
  name: string;
  slug: string;
  fullName?: string | null;
  email?: string | null;
  localeCode: string;
  logo?: any | null;
  description?: string | null;
  legalName?: string | null;
  taxId?: string | null;
  createdAt: any;
  discardedAt?: any | null;
  website?: string | null;
  mayUpdateCurrency: boolean;
  currency: { __typename?: "Currency" } & CurrencyFragment;
  address?: ({ __typename?: "Address" } & AddressFragment) | null;
  owner?: { __typename?: "User"; id: string; email: string } | null;
  features: { __typename?: "TenantFeatures" } & TenantFeaturesFragment;
} & TenantSettingsFragment;

export type CurrentTenantLocalityFragment = {
  __typename?: "Locality";
  effectiveArea: number;
  latitude?: number | null;
  longitude?: number | null;
  cropCycles: Array<
    {
      __typename?: "CropCycle";
      harvestEstimate?: {
        __typename?: "HarvestEstimate";
        id: string;
        estimateUnit: { __typename?: "Unit" } & UnitShortFragment;
        harvestUnit: { __typename?: "Unit" } & UnitShortFragment;
      } | null;
    } & CropCycleShortFragment
  >;
} & LocalityShortFragment;

export type CurrentTenantFragment = {
  __typename?: "Tenant";
  id: string;
  name: string;
  fullName?: string | null;
  localeCode: string;
  currencyCode: string;
  logo?: any | null;
  createdAt: any;
  onboardingProgress: number;
  timeZone: string;
  overtimeFactor?: number | null;
  testTenantId?: string | null;
  liveTenantId?: string | null;
  resetAt?: any | null;
  woLimit?: number | null;
  woCount: number;
  features: { __typename?: "TenantFeatures" } & TenantFeaturesFragment;
  currency: { __typename?: "Currency" } & CurrencyFragment;
  secondaryCurrency?: ({ __typename?: "Currency" } & CurrencyFragment) | null;
  address?: ({ __typename?: "Address" } & AddressFragment) | null;
  localities: Array<
    { __typename?: "Locality" } & CurrentTenantLocalityFragment
  >;
  owner?: ({ __typename?: "User" } & UserShortFragment) | null;
  canBilling: { __typename?: "AuthorizationResult"; value: boolean };
} & TenantSettingsFragment;

export type AccountMappingFragment = {
  __typename?: "AccountMapping";
  id: string;
  kind: string;
  accountableType?: AccountableType | null;
  accountableId?: string | null;
  accountableName?: string | null;
  activityIds: Array<string>;
  costCenterIds: Array<string>;
  localityIds: Array<string>;
  mayDestroy: boolean;
  debitAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
  creditAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
};

export type TenantFormValueFragment = {
  __typename?: "Tenant";
  localities?: Array<{ __typename?: "Locality" } & LocalityFragment>;
  accountMappings?: Array<
    { __typename?: "AccountMapping" } & AccountMappingFragment
  >;
  defaultCostCenters?: Array<{
    __typename?: "DefaultCostCenter";
    id: string;
    kind: string;
    costCenter: { __typename?: "CostCenter" } & CostCenterShortFragment;
  }>;
  bankAccounts?: Array<{ __typename?: "BankAccount" } & BankAccountFragment>;
} & TenantFragment;

export type TenantsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TenantsFilterInput>;
  sort?: InputMaybe<TenantsSortInput>;
}>;

export type TenantsQuery = {
  __typename?: "Query";
  tenants?: {
    __typename?: "Tenants";
    totalCount: number;
    items: Array<{ __typename?: "Tenant" } & TenantFragment>;
  } | null;
};

export type TenantStatsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TenantsFilterInput>;
  sort?: InputMaybe<TenantsSortInput>;
}>;

export type TenantStatsQuery = {
  __typename?: "Query";
  tenants?: {
    __typename?: "Tenants";
    totalCount: number;
    items: Array<
      {
        __typename?: "Tenant";
        stats: {
          __typename?: "TenantStats";
          lastSignIn?: any | null;
          workOrderCount: number;
          lastWorkOrderCreatedAt?: any | null;
        };
      } & TenantFragment
    >;
  } | null;
};

export type TenantQueryVariables = Exact<{
  id: Scalars["ID"];
  withLocalities?: InputMaybe<Scalars["Boolean"]>;
  accountingSettings?: InputMaybe<Scalars["Boolean"]>;
  bankAccounts?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TenantQuery = {
  __typename?: "Query";
  tenant?: ({ __typename?: "Tenant" } & TenantFormValueFragment) | null;
};

export type TenantCreateMutationVariables = Exact<{
  fields: TenantFields;
}>;

export type TenantCreateMutation = {
  __typename?: "Mutation";
  tenantCreate?: {
    __typename?: "TenantCreatePayload";
    result?: ({ __typename?: "Tenant" } & TenantFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TenantUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: TenantChanges;
}>;

export type TenantUpdateMutation = {
  __typename?: "Mutation";
  tenantUpdate?: {
    __typename?: "TenantUpdatePayload";
    result?: ({ __typename?: "Tenant" } & TenantFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TenantDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TenantDiscardMutation = {
  __typename?: "Mutation";
  tenantDiscard?: {
    __typename?: "TenantDiscardPayload";
    result?: ({ __typename?: "Tenant" } & TenantFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TenantCloneMutationVariables = Exact<{
  reset?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TenantCloneMutation = {
  __typename?: "Mutation";
  tenantClone?: { __typename?: "TenantClonePayload"; jobId: string } | null;
};

export type GenerateBillingPortalLinkMutationVariables = Exact<{
  [key: string]: never;
}>;

export type GenerateBillingPortalLinkMutation = {
  __typename?: "Mutation";
  generateBillingPortalLink?: {
    __typename?: "GenerateBillingPortalLinkMutationPayload";
    link: string;
  } | null;
};

export type UnitShortFragment = {
  __typename?: "Unit";
  id: string;
  name: string;
  abbr: string;
  abbrEn?: string | null;
  unitType: UnitType;
};

export type UnitShortConversionFragment = {
  __typename?: "Unit";
  conversionFactor?: number | null;
  conversionUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
} & UnitShortFragment;

export type UnitFragment = {
  __typename?: "Unit";
  id: string;
  name: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  abbr: string;
  abbrEn?: string | null;
  abbrEs?: string | null;
  abbrPt?: string | null;
  abbrRu?: string | null;
  unitType: UnitType;
  primary: boolean;
  locked: boolean;
  global: boolean;
  conversionFactor?: number | null;
  discardedAt?: any | null;
  conversionUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type UnitTypeQueryVariables = Exact<{ [key: string]: never }>;

export type UnitTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type UnitsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<UnitsFilterInput>;
  sort?: InputMaybe<UnitsSortInput>;
}>;

export type UnitsQuery = {
  __typename?: "Query";
  units?: {
    __typename?: "Units";
    totalCount: number;
    items: Array<{ __typename?: "Unit" } & UnitFragment>;
  } | null;
};

export type UnitQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnitQuery = {
  __typename?: "Query";
  unit?: ({ __typename?: "Unit" } & UnitFragment) | null;
};

export type UnitCreateMutationVariables = Exact<{
  fields: UnitFields;
}>;

export type UnitCreateMutation = {
  __typename?: "Mutation";
  unitCreate?: {
    __typename?: "UnitCreatePayload";
    result?: ({ __typename?: "Unit" } & UnitFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type UnitUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: UnitChanges;
}>;

export type UnitUpdateMutation = {
  __typename?: "Mutation";
  unitUpdate?: {
    __typename?: "UnitUpdatePayload";
    result?: ({ __typename?: "Unit" } & UnitFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type UnitDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnitDiscardMutation = {
  __typename?: "Mutation";
  unitDiscard?: {
    __typename?: "UnitDiscardPayload";
    result?: ({ __typename?: "Unit" } & UnitFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type UnitUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UnitUnlockMutation = {
  __typename?: "Mutation";
  unitUnlock?: {
    __typename?: "UnitUnlockPayload";
    result?: ({ __typename?: "Unit" } & UnitFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type UserShortFragment = {
  __typename?: "User";
  id: string;
  username?: string | null;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
};

export type UserFragment = {
  __typename?: "User";
  avatar?: any | null;
  createdAt: any;
  discardedAt?: any | null;
  invitationSentAt?: any | null;
  invitationAcceptedAt?: any | null;
  roles: Array<{ __typename?: "Role"; id: string; name: string }>;
  tenants: Array<{
    __typename?: "Tenant";
    id: string;
    fullName?: string | null;
    owner?: { __typename?: "User"; id: string } | null;
  }>;
  localities: Array<{ __typename?: "Locality" } & LocalityNameFragment>;
  canDiscard: { __typename?: "AuthorizationResult"; value: boolean };
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canUpdateRoles: { __typename?: "AuthorizationResult"; value: boolean };
  canUpdateLocalities: { __typename?: "AuthorizationResult"; value: boolean };
  canInvite: { __typename?: "AuthorizationResult"; value: boolean };
} & UserShortFragment;

export type CurrentUserFragment = {
  __typename?: "CurrentUser";
  id: string;
  email: string;
  username?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  admin: boolean;
  avatar?: any | null;
  currentLocale: string;
  settings: any;
  currentTenant: { __typename?: "Tenant" } & CurrentTenantFragment;
  tenants: Array<{
    __typename?: "Tenant";
    id: string;
    name: string;
    fullName?: string | null;
    slug: string;
    liveTenantId?: string | null;
  }>;
  localities: Array<{ __typename?: "Locality" } & LocalityNameFragment>;
  permissions: { __typename?: "Permissions" } & PermissionsFragment;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me?: ({ __typename?: "CurrentUser" } & CurrentUserFragment) | null;
};

export type UsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<UsersFilterInput>;
  sort?: InputMaybe<UsersSortInput>;
}>;

export type UsersQuery = {
  __typename?: "Query";
  users?: {
    __typename?: "Users";
    totalCount: number;
    items: Array<{ __typename?: "User" } & UserFragment>;
  } | null;
};

export type UserOptionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<UsersFilterInput>;
}>;

export type UserOptionsQuery = {
  __typename?: "Query";
  users?: {
    __typename?: "Users";
    items: Array<{ __typename?: "User" } & UserShortFragment>;
  } | null;
};

export type UserQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UserQuery = {
  __typename?: "Query";
  user?: ({ __typename?: "User" } & UserFragment) | null;
};

export type SignInMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  rememberMe: Scalars["Boolean"];
}>;

export type SignInMutation = {
  __typename?: "Mutation";
  signIn?: {
    __typename?: "SignInMutationPayload";
    user?: ({ __typename?: "CurrentUser" } & CurrentUserFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SignUpMutationVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
  countryCode: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  name: Scalars["String"];
  invitationToken?: InputMaybe<Scalars["String"]>;
  tenant?: InputMaybe<Scalars["String"]>;
}>;

export type SignUpMutation = {
  __typename?: "Mutation";
  signUp?: {
    __typename?: "SignUpMutationPayload";
    user?: ({ __typename?: "CurrentUser" } & CurrentUserFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SignOutMutationVariables = Exact<{ [key: string]: never }>;

export type SignOutMutation = {
  __typename?: "Mutation";
  signOut?: {
    __typename?: "SignOutMutationPayload";
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SendResetPasswordMutationVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendResetPasswordMutation = {
  __typename?: "Mutation";
  sendResetPassword?: {
    __typename?: "SendResetPasswordMutationPayload";
    result?: string | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
  resetPasswordToken: Scalars["String"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword?: {
    __typename?: "ResetPasswordMutationPayload";
    result?: string | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type UserUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: UserChanges;
}>;

export type UserUpdateMutation = {
  __typename?: "Mutation";
  userUpdate?: {
    __typename?: "UserUpdatePayload";
    result?: ({ __typename?: "User" } & UserFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type UserCreateMutationVariables = Exact<{
  fields: UserFields;
}>;

export type UserCreateMutation = {
  __typename?: "Mutation";
  userCreate?: {
    __typename?: "UserCreatePayload";
    result?: ({ __typename?: "User" } & UserFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type UserDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type UserDiscardMutation = {
  __typename?: "Mutation";
  userDiscard?: {
    __typename?: "UserDiscardPayload";
    result?: ({ __typename?: "User" } & UserFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type AcceptInviteMutationVariables = Exact<{
  password: Scalars["String"];
  passwordConfirmation: Scalars["String"];
  invitationToken: Scalars["String"];
}>;

export type AcceptInviteMutation = {
  __typename?: "Mutation";
  acceptInvite?: {
    __typename?: "AcceptInviteMutationPayload";
    result?: ({ __typename?: "User" } & UserFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ResendInviteMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ResendInviteMutation = {
  __typename?: "Mutation";
  resendInvite?: {
    __typename?: "ResendInviteMutationPayload";
    result?: ({ __typename?: "User" } & UserFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type GenerateInvitationLinkMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GenerateInvitationLinkMutation = {
  __typename?: "Mutation";
  generateInvitationLink?: {
    __typename?: "GenerateInvitationLinkMutationPayload";
    link?: string | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type GenerateResetPasswordLinkMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type GenerateResetPasswordLinkMutation = {
  __typename?: "Mutation";
  generateResetPasswordLink?: {
    __typename?: "GenerateResetPasswordLinkMutationPayload";
    link?: string | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type AccountKindFragment = {
  __typename?: "AccountKind";
  id: string;
  name: string;
  allowOpeningBalance: boolean;
  discardedAt?: any | null;
  accountKinds: Array<{
    __typename?: "AccountKind";
    id: string;
    name: string;
    allowOpeningBalance: boolean;
    accountKinds: Array<{
      __typename?: "AccountKind";
      id: string;
      name: string;
      allowOpeningBalance: boolean;
    }>;
  }>;
};

export type AccountKindShortFragment = {
  __typename?: "AccountKind";
  id: string;
  name: string;
  key: string;
  parentKind?: {
    __typename?: "AccountKind";
    id: string;
    name: string;
    key: string;
    parentKind?: {
      __typename?: "AccountKind";
      id: string;
      name: string;
      key: string;
    } | null;
  } | null;
};

export type AccountKindsQueryVariables = Exact<{ [key: string]: never }>;

export type AccountKindsQuery = {
  __typename?: "Query";
  accountKinds?: {
    __typename?: "AccountKinds";
    totalCount: number;
    items: Array<{ __typename?: "AccountKind" } & AccountKindFragment>;
  } | null;
};

export type AccountFragment = {
  __typename?: "Account";
  id: string;
  name: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  accountNumber?: string | null;
  groupAccount: boolean;
  balance: number;
  discardedAt?: any | null;
  mayDiscard: boolean;
  mayDestroy: boolean;
  kind: {
    __typename?: "AccountKind";
    allowOpeningBalance: boolean;
  } & AccountKindShortFragment;
  currency: { __typename?: "Currency" } & CurrencyFragment;
  parentAccount?: {
    __typename?: "Account";
    id: string;
    name: string;
    accountNumber?: string | null;
  } | null;
};

export type AccountShortFragment = {
  __typename?: "Account";
  id: string;
  groupAccount: boolean;
  name: string;
  accountNumber?: string | null;
  kind: { __typename?: "AccountKind" } & AccountKindShortFragment;
};

export type AccountsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<AccountsFilterInput>;
  sort?: InputMaybe<AccountsSortInput>;
}>;

export type AccountsQuery = {
  __typename?: "Query";
  accounts?: {
    __typename?: "Accounts";
    totalCount: number;
    items: Array<{ __typename?: "Account" } & AccountFragment>;
  } | null;
};

export type AccountOptionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<AccountsFilterInput>;
  sort?: InputMaybe<AccountsSortInput>;
}>;

export type AccountOptionsQuery = {
  __typename?: "Query";
  accounts?: {
    __typename?: "Accounts";
    items: Array<{ __typename?: "Account" } & AccountShortFragment>;
  } | null;
};

export type AccountQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AccountQuery = {
  __typename?: "Query";
  account?:
    | ({
        __typename?: "Account";
        subaccounts?: Array<
          { __typename?: "Account" } & AccountFragment
        > | null;
      } & AccountFragment)
    | null;
};

export type AccountCreateMutationVariables = Exact<{
  fields: AccountFields;
}>;

export type AccountCreateMutation = {
  __typename?: "Mutation";
  accountCreate?: {
    __typename?: "AccountCreatePayload";
    result?: ({ __typename?: "Account" } & AccountFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type AccountUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: AccountChanges;
}>;

export type AccountUpdateMutation = {
  __typename?: "Mutation";
  accountUpdate?: {
    __typename?: "AccountUpdatePayload";
    result?: ({ __typename?: "Account" } & AccountFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type AccountDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AccountDiscardMutation = {
  __typename?: "Mutation";
  accountDiscard?: {
    __typename?: "AccountDiscardPayload";
    result?: ({ __typename?: "Account" } & AccountFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type AccountDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AccountDestroyMutation = {
  __typename?: "Mutation";
  accountDestroy?: {
    __typename?: "AccountDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankAccountFragment = {
  __typename?: "BankAccount";
  id: string;
  accountNumber?: string | null;
  currencyCode: string;
  discardedAt?: any | null;
  bank: { __typename?: "Bank" } & BankShortFragment;
  account?: ({ __typename?: "Account" } & AccountShortFragment) | null;
};

export type BankAccountCreateMutationVariables = Exact<{
  fields: BankAccountFields;
}>;

export type BankAccountCreateMutation = {
  __typename?: "Mutation";
  bankAccountCreate?: {
    __typename?: "BankAccountCreatePayload";
    result?: ({ __typename?: "BankAccount" } & BankAccountFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankAccountUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: BankAccountChanges;
}>;

export type BankAccountUpdateMutation = {
  __typename?: "Mutation";
  bankAccountUpdate?: {
    __typename?: "BankAccountUpdatePayload";
    result?: ({ __typename?: "BankAccount" } & BankAccountFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankAccountDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BankAccountDiscardMutation = {
  __typename?: "Mutation";
  bankAccountDiscard?: {
    __typename?: "BankAccountDiscardPayload";
    result?: ({ __typename?: "BankAccount" } & BankAccountFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankAccountDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type BankAccountDestroyMutation = {
  __typename?: "Mutation";
  bankAccountDestroy?: {
    __typename?: "BankAccountDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankShortFragment = {
  __typename?: "Bank";
  id: string;
  name?: string | null;
};

export type BankFragment = {
  __typename?: "Bank";
  discardedAt?: any | null;
  locked: boolean;
  global: boolean;
  mayDestroy: boolean;
  address: { __typename?: "Address" } & AddressFragment;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
} & BankShortFragment;

export type BanksQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<BanksFilterInput>;
  sort?: InputMaybe<BanksSortInput>;
}>;

export type BanksQuery = {
  __typename?: "Query";
  banks?: {
    __typename?: "Banks";
    totalCount: number;
    items: Array<{ __typename?: "Bank" } & BankFragment>;
  } | null;
};

export type BankCreateMutationVariables = Exact<{
  fields: BankFields;
}>;

export type BankCreateMutation = {
  __typename?: "Mutation";
  bankCreate?: {
    __typename?: "BankCreatePayload";
    result?: ({ __typename?: "Bank" } & BankFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: BankChanges;
}>;

export type BankUpdateMutation = {
  __typename?: "Mutation";
  bankUpdate?: {
    __typename?: "BankUpdatePayload";
    result?: ({ __typename?: "Bank" } & BankFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BankDiscardMutation = {
  __typename?: "Mutation";
  bankDiscard?: {
    __typename?: "BankDiscardPayload";
    result?: ({ __typename?: "Bank" } & BankFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type BankDestroyMutation = {
  __typename?: "Mutation";
  bankDestroy?: {
    __typename?: "BankDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type BankUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type BankUnlockMutation = {
  __typename?: "Mutation";
  bankUnlock?: {
    __typename?: "BankUnlockPayload";
    result?: ({ __typename?: "Bank" } & BankFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CostCenterFragment = {
  __typename?: "CostCenter";
  id: string;
  name: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  internalId?: string | null;
  profitableType?: ProfitableType | null;
  discardedAt?: any | null;
  parentCostCenter?: {
    __typename?: "CostCenter";
    id: string;
    name: string;
  } | null;
};

export type CostCenterTreeFragment = {
  __typename?: "CostCenter";
  subcostCenters: Array<{ __typename?: "CostCenter"; id: string }>;
} & CostCenterFragment;

export type CostCenterShortFragment = {
  __typename?: "CostCenter";
  id: string;
  name: string;
  internalId?: string | null;
  parentCostCenter?: {
    __typename?: "CostCenter";
    id: string;
    name: string;
  } | null;
};

export type ProfitableTypeQueryVariables = Exact<{ [key: string]: never }>;

export type ProfitableTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type CostCentersQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CostCentersFilterInput>;
  sort?: InputMaybe<CostCentersSortInput>;
}>;

export type CostCentersQuery = {
  __typename?: "Query";
  costCenters?: {
    __typename?: "CostCenters";
    totalCount: number;
    items: Array<{ __typename?: "CostCenter" } & CostCenterTreeFragment>;
  } | null;
};

export type CostCenterQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CostCenterQuery = {
  __typename?: "Query";
  costCenter?:
    | ({
        __typename?: "CostCenter";
        subcostCenters: Array<
          { __typename?: "CostCenter" } & CostCenterFragment
        >;
      } & CostCenterFragment)
    | null;
};

export type CostCenterCreateMutationVariables = Exact<{
  fields: CostCenterFields;
}>;

export type CostCenterCreateMutation = {
  __typename?: "Mutation";
  costCenterCreate?: {
    __typename?: "CostCenterCreatePayload";
    result?: ({ __typename?: "CostCenter" } & CostCenterTreeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CostCenterUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: CostCenterChanges;
}>;

export type CostCenterUpdateMutation = {
  __typename?: "Mutation";
  costCenterUpdate?: {
    __typename?: "CostCenterUpdatePayload";
    result?: ({ __typename?: "CostCenter" } & CostCenterTreeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CostCenterDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type CostCenterDiscardMutation = {
  __typename?: "Mutation";
  costCenterDiscard?: {
    __typename?: "CostCenterDiscardPayload";
    result?: ({ __typename?: "CostCenter" } & CostCenterTreeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type CostCenterDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CostCenterDestroyMutation = {
  __typename?: "Mutation";
  costCenterDestroy?: {
    __typename?: "CostCenterDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseCategoryFragment = {
  __typename?: "ExpenseCategory";
  id: string;
  name: string;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  locked: boolean;
  global: boolean;
  discardedAt?: any | null;
  parentCategory?: {
    __typename?: "ExpenseCategory";
    id: string;
    name: string;
  } | null;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type ExpenseCategoryShortFragment = {
  __typename?: "ExpenseCategory";
  id: string;
  name: string;
  parentCategory?: {
    __typename?: "ExpenseCategory";
    id: string;
    name: string;
  } | null;
};

export type ExpenseCategoryTreeFragment = {
  __typename?: "ExpenseCategory";
  subcategories: Array<{ __typename?: "ExpenseCategory"; id: string }>;
} & ExpenseCategoryFragment;

export type ExpenseCategoriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ExpenseCategoriesFilterInput>;
  sort?: InputMaybe<ExpenseCategoriesSortInput>;
}>;

export type ExpenseCategoriesQuery = {
  __typename?: "Query";
  expenseCategories?: {
    __typename?: "ExpenseCategories";
    totalCount: number;
    items: Array<
      { __typename?: "ExpenseCategory" } & ExpenseCategoryTreeFragment
    >;
  } | null;
};

export type ExpenseCategoryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExpenseCategoryQuery = {
  __typename?: "Query";
  expenseCategory?:
    | ({
        __typename?: "ExpenseCategory";
        subcategories: Array<
          { __typename?: "ExpenseCategory" } & ExpenseCategoryFragment
        >;
      } & ExpenseCategoryFragment)
    | null;
};

export type ExpenseCategoryCreateMutationVariables = Exact<{
  fields: ExpenseCategoryFields;
}>;

export type ExpenseCategoryCreateMutation = {
  __typename?: "Mutation";
  expenseCategoryCreate?: {
    __typename?: "ExpenseCategoryCreatePayload";
    result?:
      | ({ __typename?: "ExpenseCategory" } & ExpenseCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseCategoryUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: ExpenseCategoryChanges;
}>;

export type ExpenseCategoryUpdateMutation = {
  __typename?: "Mutation";
  expenseCategoryUpdate?: {
    __typename?: "ExpenseCategoryUpdatePayload";
    result?:
      | ({ __typename?: "ExpenseCategory" } & ExpenseCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseCategoryDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExpenseCategoryDiscardMutation = {
  __typename?: "Mutation";
  expenseCategoryDiscard?: {
    __typename?: "ExpenseCategoryDiscardPayload";
    result?:
      | ({ __typename?: "ExpenseCategory" } & ExpenseCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseCategoryDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ExpenseCategoryDestroyMutation = {
  __typename?: "Mutation";
  expenseCategoryDestroy?: {
    __typename?: "ExpenseCategoryDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseCategoryUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExpenseCategoryUnlockMutation = {
  __typename?: "Mutation";
  expenseCategoryUnlock?: {
    __typename?: "ExpenseCategoryUnlockPayload";
    result?:
      | ({ __typename?: "ExpenseCategory" } & ExpenseCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseItemShortFragment = {
  __typename?: "ExpenseItem";
  id: string;
  name: string;
  expenseCategory: {
    __typename?: "ExpenseCategory";
    expenseAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
  } & ExpenseCategoryShortFragment;
};

export type ExpenseItemFragment = {
  __typename?: "ExpenseItem";
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  description?: string | null;
  discardedAt?: any | null;
  taxPlan?: ({ __typename?: "TaxPlan" } & TaxPlanShortFragment) | null;
} & ExpenseItemShortFragment;

export type ExpenseItemsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ExpenseItemsFilterInput>;
  sort?: InputMaybe<ExpenseItemsSortInput>;
}>;

export type ExpenseItemsQuery = {
  __typename?: "Query";
  expenseItems?: {
    __typename?: "ExpenseItems";
    totalCount: number;
    items: Array<{ __typename?: "ExpenseItem" } & ExpenseItemFragment>;
  } | null;
};

export type ExpenseItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ExpenseItemQuery = {
  __typename?: "Query";
  expenseItem?: ({ __typename?: "ExpenseItem" } & ExpenseItemFragment) | null;
};

export type ExpenseItemCreateMutationVariables = Exact<{
  fields: ExpenseItemFields;
}>;

export type ExpenseItemCreateMutation = {
  __typename?: "Mutation";
  expenseItemCreate?: {
    __typename?: "ExpenseItemCreatePayload";
    result?: ({ __typename?: "ExpenseItem" } & ExpenseItemFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseItemUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: ExpenseItemChanges;
}>;

export type ExpenseItemUpdateMutation = {
  __typename?: "Mutation";
  expenseItemUpdate?: {
    __typename?: "ExpenseItemUpdatePayload";
    result?: ({ __typename?: "ExpenseItem" } & ExpenseItemFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseItemDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ExpenseItemDiscardMutation = {
  __typename?: "Mutation";
  expenseItemDiscard?: {
    __typename?: "ExpenseItemDiscardPayload";
    result?: ({ __typename?: "ExpenseItem" } & ExpenseItemFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ExpenseItemDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ExpenseItemDestroyMutation = {
  __typename?: "Mutation";
  expenseItemDestroy?: {
    __typename?: "ExpenseItemDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FinanceInvoiceFragment = {
  __typename?: "FinanceInvoice";
  id: string;
  invoiceStatus: InvoiceStatus;
  currencyCode: string;
  total: number;
  documentDate: any;
  dueDate: any;
  sourceDocumentId?: string | null;
  sourceDocumentType?: FinanceDocumentType | null;
  sourceDocumentCode?: string | null;
  internalId?: string | null;
  amountPaid: number;
  amountToPay: number;
  counterparty: { __typename?: "Counterparty"; id: string; name: string };
};

export type FinanceInvoicePaymentDetailsFragment = {
  __typename?: "FinancePayment";
  invoiceAmount: number;
} & FinancePaymentFragment;

export type FinanceInvoiceDetailsFragment = {
  __typename?: "FinanceInvoice";
  paymentStatus: PaymentStatus;
  withholdAmount: number;
  account?: ({ __typename?: "Account" } & AccountShortFragment) | null;
  paymentMethod: { __typename?: "PaymentMethod"; id: string; name: string };
  currency: { __typename?: "Currency" } & CurrencyFragment;
  counterparty: {
    __typename?: "Counterparty";
    id: string;
    name: string;
    withholdingRate?: number | null;
  };
  payments: Array<
    { __typename?: "FinancePayment" } & FinanceInvoicePaymentDetailsFragment
  >;
} & FinanceInvoiceFragment;

export type InvoiceStatusQueryVariables = Exact<{ [key: string]: never }>;

export type InvoiceStatusQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type FinanceInvoicesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<FinanceInvoicesFilterInput>;
  sort?: InputMaybe<FinanceInvoicesSortInput>;
  includePaymentMethods?: InputMaybe<Scalars["Boolean"]>;
  includePayments?: InputMaybe<Scalars["Boolean"]>;
}>;

export type FinanceInvoicesQuery = {
  __typename?: "Query";
  financeInvoices?: {
    __typename?: "FinanceInvoices";
    totalCount: number;
    items: Array<
      {
        __typename?: "FinanceInvoice";
        paymentMethod?: {
          __typename?: "PaymentMethod";
          id: string;
          name: string;
        };
        payments?: Array<
          {
            __typename?: "FinancePayment";
          } & FinanceInvoicePaymentDetailsFragment
        >;
      } & FinanceInvoiceFragment
    >;
  } | null;
};

export type FinanceInvoiceQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FinanceInvoiceQuery = {
  __typename?: "Query";
  financeInvoice?:
    | ({ __typename?: "FinanceInvoice" } & FinanceInvoiceDetailsFragment)
    | null;
};

export type FinanceInvoiceUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: InvoiceChanges;
}>;

export type FinanceInvoiceUpdateMutation = {
  __typename?: "Mutation";
  financeInvoiceUpdate?: {
    __typename?: "InvoiceUpdatePayload";
    result?:
      | ({ __typename?: "FinanceInvoice" } & FinanceInvoiceDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FinanceOrderLineItemCostCenterFragment = {
  __typename?: "FinanceOrderLineItemCostCenter";
  id: string;
  profitableId?: string | null;
  profitableType?: ProfitableType | null;
  profitableName?: string | null;
  amount: number;
  costCenter: { __typename?: "CostCenter" } & CostCenterShortFragment;
};

export type FinanceOrderLineItemFragment = {
  __typename?: "FinanceOrderLineItem";
  id: string;
  amount: number;
  description?: string | null;
  expenseItem: {
    __typename?: "ExpenseItem";
    taxPlan?:
      | ({ __typename?: "TaxPlan" } & TaxPlanWithComponentsFragment)
      | null;
  } & ExpenseItemShortFragment;
  costCenters: Array<
    {
      __typename?: "FinanceOrderLineItemCostCenter";
    } & FinanceOrderLineItemCostCenterFragment
  >;
  taxPlanValues: Array<{ __typename?: "TaxValue" } & TaxValueFragment>;
};

export type FinanceOrderFragment = {
  __typename?: "FinanceOrder";
  id: string;
  status: FinanceOrderStatus;
  kind: FinanceOrderKind;
  currencyCode: string;
  total: number;
  subtotal: number;
  tax: number;
  documentDate: any;
  internalId?: string | null;
  cycleNumber?: number | null;
  createdAt: any;
  updatedAt: any;
  counterparty: { __typename?: "Counterparty"; id: string; name: string };
  paymentAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
  paymentMethod: { __typename?: "PaymentMethod"; id: string; name: string };
  currency: { __typename?: "Currency" } & CurrencyFragment;
  cropCycle?: ({ __typename?: "CropCycle" } & CropCycleShortFragment) | null;
  activity?: ({ __typename?: "Activity" } & ActivityNameFragment) | null;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  updatedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type FinanceOrderDetailsFragment = {
  __typename?: "FinanceOrder";
  discount: number;
  discountRate: number;
  tax: number;
  taxRate: number;
  exchangeRate: number;
  subtotal: number;
  counterparty: {
    __typename?: "Counterparty";
    taxPlan?:
      | ({ __typename?: "TaxPlan" } & TaxPlanWithComponentsFragment)
      | null;
  };
  lineItems: Array<
    { __typename?: "FinanceOrderLineItem" } & FinanceOrderLineItemFragment
  >;
  taxValues: Array<{ __typename?: "TaxValue" } & TaxValueFragment>;
} & FinanceOrderFragment;

export type FinanceOrdersQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<FinanceOrdersFilterInput>;
  sort?: InputMaybe<FinanceOrdersSortInput>;
}>;

export type FinanceOrdersQuery = {
  __typename?: "Query";
  financeOrders?: {
    __typename?: "FinanceOrders";
    totalCount: number;
    items: Array<{ __typename?: "FinanceOrder" } & FinanceOrderFragment>;
  } | null;
};

export type FinanceOrderQueryVariables = Exact<{
  id: Scalars["ID"];
  nextDocuments?: InputMaybe<Scalars["Boolean"]>;
}>;

export type FinanceOrderQuery = {
  __typename?: "Query";
  financeOrder?:
    | ({
        __typename?: "FinanceOrder";
        invoices?: Array<{
          __typename?: "FinanceInvoice";
          id: string;
          documentDate: any;
          invoiceStatus: InvoiceStatus;
        }>;
      } & FinanceOrderDetailsFragment)
    | null;
};

export type FinanceOrderCreateMutationVariables = Exact<{
  fields: FinanceOrderFields;
}>;

export type FinanceOrderCreateMutation = {
  __typename?: "Mutation";
  financeOrderCreate?: {
    __typename?: "FinanceOrderCreatePayload";
    result?:
      | ({ __typename?: "FinanceOrder" } & FinanceOrderDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FinanceOrderUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: FinanceOrderChanges;
}>;

export type FinanceOrderUpdateMutation = {
  __typename?: "Mutation";
  financeOrderUpdate?: {
    __typename?: "FinanceOrderUpdatePayload";
    result?:
      | ({ __typename?: "FinanceOrder" } & FinanceOrderDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FinanceOrderCloneMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: FinanceOrderChanges;
}>;

export type FinanceOrderCloneMutation = {
  __typename?: "Mutation";
  financeOrderClone?: {
    __typename?: "FinanceOrderClonePayload";
    result?:
      | ({ __typename?: "FinanceOrder" } & FinanceOrderDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FinancePaymentFragment = {
  __typename?: "FinancePayment";
  id: string;
  internalId?: string | null;
  paymentDate: any;
  exchangeRate: number;
  withholdingRate: number;
  status: FinancePaymentStatus;
  account?: ({ __typename?: "Account" } & AccountShortFragment) | null;
};

export type FinancePaymentQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FinancePaymentQuery = {
  __typename?: "Query";
  financePayment?:
    | ({
        __typename?: "FinancePayment";
        invoices: Array<{ __typename?: "FinanceInvoice"; id: string }>;
      } & FinancePaymentFragment)
    | null;
};

export type FinancePaymentCreateMutationVariables = Exact<{
  fields: PaymentFields;
}>;

export type FinancePaymentCreateMutation = {
  __typename?: "Mutation";
  financePaymentCreate?: {
    __typename?: "PaymentCreatePayload";
    result?:
      | ({ __typename?: "FinancePayment" } & FinancePaymentFragment)
      | null;
    errors: Array<{ __typename?: "UserError"; path: string; message: string }>;
  } | null;
};

export type FinancePaymentUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: PaymentChanges;
}>;

export type FinancePaymentUpdateMutation = {
  __typename?: "Mutation";
  financePaymentUpdate?: {
    __typename?: "PaymentUpdatePayload";
    result?:
      | ({ __typename?: "FinancePayment" } & FinancePaymentFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FinancePeriodFragment = {
  __typename?: "Period";
  id: string;
  name: string;
  startDate: any;
  endDate: any;
  closedAt?: any | null;
};

export type FinancePeriodsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<FinancePeriodsFilterInput>;
  sort?: InputMaybe<FinancePeriodsSortInput>;
}>;

export type FinancePeriodsQuery = {
  __typename?: "Query";
  financePeriods?: {
    __typename?: "FinancePeriods";
    totalCount: number;
    items: Array<{ __typename?: "Period" } & FinancePeriodFragment>;
  } | null;
};

export type FinancePeriodCloseMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type FinancePeriodCloseMutation = {
  __typename?: "Mutation";
  financePeriodClose?: {
    __typename?: "PeriodCloseMutationPayload";
    result?: ({ __typename?: "Period" } & FinancePeriodFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
    activeDocuments: Array<
      { __typename?: "FinanceDocumentReference" } & ActiveDocumentFragment
    >;
  } | null;
};

export type FinancePeriodOpenMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FinancePeriodOpenMutation = {
  __typename?: "Mutation";
  financePeriodOpen?: {
    __typename?: "OpenMutationPayload";
    result?: ({ __typename?: "Period" } & FinancePeriodFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FiscalYearFragment = {
  __typename?: "FiscalYear";
  id: string;
  name: string;
  startDate: any;
  endDate: any;
  closedAt?: any | null;
  periods: Array<{ __typename?: "Period" } & FinancePeriodFragment>;
};

export type FiscalYearsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<FiscalYearsFilterInput>;
  sort?: InputMaybe<FiscalYearsSortInput>;
}>;

export type FiscalYearsQuery = {
  __typename?: "Query";
  fiscalYears?: {
    __typename?: "FiscalYears";
    totalCount: number;
    items: Array<{ __typename?: "FiscalYear" } & FiscalYearFragment>;
  } | null;
};

export type FiscalYearQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type FiscalYearQuery = {
  __typename?: "Query";
  fiscalYear?: ({ __typename?: "FiscalYear" } & FiscalYearFragment) | null;
};

export type FiscalYearCreateMutationVariables = Exact<{
  fields: FiscalYearFields;
}>;

export type FiscalYearCreateMutation = {
  __typename?: "Mutation";
  fiscalYearCreate?: {
    __typename?: "FiscalYearCreatePayload";
    result?: ({ __typename?: "FiscalYear" } & FiscalYearFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FiscalYearUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: FiscalYearChanges;
}>;

export type FiscalYearUpdateMutation = {
  __typename?: "Mutation";
  fiscalYearUpdate?: {
    __typename?: "FiscalYearUpdatePayload";
    result?: ({ __typename?: "FiscalYear" } & FiscalYearFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type FiscalYearDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type FiscalYearDestroyMutation = {
  __typename?: "Mutation";
  fiscalYearDestroy?: {
    __typename?: "FiscalYearDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type JournalEntryLineFragment = {
  __typename?: "JournalEntryLine";
  id: string;
  debit: boolean;
  amount?: number | null;
  description?: string | null;
  profitableId?: string | null;
  profitableType?: ProfitableType | null;
  profitableName?: string | null;
  account: { __typename?: "Account" } & AccountShortFragment;
  costCenter?: ({ __typename?: "CostCenter" } & CostCenterShortFragment) | null;
};

export type JournalEntryFragment = {
  __typename?: "JournalEntry";
  id: string;
  status: JournalEntryStatus;
  documentDate: any;
  sourceDocumentId?: string | null;
  sourceDocumentType?: FinanceDocumentType | null;
  internalId?: string | null;
  createdAt: any;
  updatedAt: any;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  updatedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type JournalEntryDetailsFragment = {
  __typename?: "JournalEntry";
  entryLines: Array<
    { __typename?: "JournalEntryLine" } & JournalEntryLineFragment
  >;
} & JournalEntryFragment;

export type JournalEntriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<JournalEntriesFilterInput>;
  sort?: InputMaybe<JournalEntriesSortInput>;
}>;

export type JournalEntriesQuery = {
  __typename?: "Query";
  journalEntries?: {
    __typename?: "JournalEntries";
    totalCount: number;
    items: Array<
      {
        __typename?: "JournalEntry";
        entryLines: Array<{
          __typename?: "JournalEntryLine";
          id: string;
          account: { __typename?: "Account" } & AccountShortFragment;
        }>;
      } & JournalEntryFragment
    >;
  } | null;
};

export type JournalEntryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type JournalEntryQuery = {
  __typename?: "Query";
  journalEntry?:
    | ({ __typename?: "JournalEntry" } & JournalEntryDetailsFragment)
    | null;
};

export type JournalEntryCreateMutationVariables = Exact<{
  fields: JournalEntryFields;
}>;

export type JournalEntryCreateMutation = {
  __typename?: "Mutation";
  journalEntryCreate?: {
    __typename?: "JournalEntryCreatePayload";
    result?:
      | ({ __typename?: "JournalEntry" } & JournalEntryDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type JournalEntryUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: JournalEntryChanges;
}>;

export type JournalEntryUpdateMutation = {
  __typename?: "Mutation";
  journalEntryUpdate?: {
    __typename?: "JournalEntryUpdatePayload";
    result?:
      | ({ __typename?: "JournalEntry" } & JournalEntryDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type JournalEntryCloneMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: JournalEntryChanges;
}>;

export type JournalEntryCloneMutation = {
  __typename?: "Mutation";
  journalEntryClone?: {
    __typename?: "JournalEntryClonePayload";
    result?:
      | ({ __typename?: "JournalEntry" } & JournalEntryDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TaxComponentShortFragment = {
  __typename?: "TaxComponent";
  id: string;
  name: string;
  abbr: string;
  defaultFormula?: string | null;
};

export type TaxComponentFragment = {
  __typename?: "TaxComponent";
  nameEn?: string | null;
  nameEs?: string | null;
  description?: string | null;
  descriptionEn?: string | null;
  descriptionEs?: string | null;
  abbr: string;
  defaultFormula?: string | null;
  discardedAt?: any | null;
  mayDiscard: boolean;
  mayDestroy: boolean;
  taxDebitAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
  taxCreditAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
} & TaxComponentShortFragment;

export type TaxComponentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TaxComponentsFilterInput>;
  sort?: InputMaybe<TaxComponentsSortInput>;
}>;

export type TaxComponentsQuery = {
  __typename?: "Query";
  taxComponents?: {
    __typename?: "TaxComponents";
    totalCount: number;
    items: Array<{ __typename?: "TaxComponent" } & TaxComponentFragment>;
  } | null;
};

export type TaxComponentQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TaxComponentQuery = {
  __typename?: "Query";
  taxComponent?:
    | ({ __typename?: "TaxComponent" } & TaxComponentFragment)
    | null;
};

export type TaxComponentCreateMutationVariables = Exact<{
  fields: TaxComponentFields;
}>;

export type TaxComponentCreateMutation = {
  __typename?: "Mutation";
  taxComponentCreate?: {
    __typename?: "TaxComponentCreatePayload";
    result?: ({ __typename?: "TaxComponent" } & TaxComponentFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TaxComponentUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: TaxComponentChanges;
}>;

export type TaxComponentUpdateMutation = {
  __typename?: "Mutation";
  taxComponentUpdate?: {
    __typename?: "TaxComponentUpdatePayload";
    result?: ({ __typename?: "TaxComponent" } & TaxComponentFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TaxComponentDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TaxComponentDiscardMutation = {
  __typename?: "Mutation";
  taxComponentDiscard?: {
    __typename?: "TaxComponentDiscardPayload";
    result?: ({ __typename?: "TaxComponent" } & TaxComponentFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TaxComponentDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TaxComponentDestroyMutation = {
  __typename?: "Mutation";
  taxComponentDestroy?: {
    __typename?: "TaxComponentDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TaxPlanShortFragment = {
  __typename?: "TaxPlan";
  id: string;
  name: string;
  kind: TaxPlanKind;
};

export type TaxPlanComponentFragment = {
  __typename?: "TaxPlanComponent";
  id: string;
  formula?: string | null;
  includedInPrice?: boolean | null;
  includeInUnitCost?: boolean | null;
  index: number;
  taxComponent: {
    __typename?: "TaxComponent";
    nameEn?: string | null;
    nameEs?: string | null;
    taxDebitAccount?:
      | ({ __typename?: "Account" } & AccountShortFragment)
      | null;
    taxCreditAccount?:
      | ({ __typename?: "Account" } & AccountShortFragment)
      | null;
  } & TaxComponentShortFragment;
};

export type TaxPlanFragment = {
  __typename?: "TaxPlan";
  nameEn?: string | null;
  nameEs?: string | null;
  discardedAt?: any | null;
  mayDiscard: boolean;
  mayDestroy: boolean;
  components: Array<
    { __typename?: "TaxPlanComponent" } & TaxPlanComponentFragment
  >;
} & TaxPlanShortFragment;

export type TaxPlanWithComponentsFragment = {
  __typename?: "TaxPlan";
  components: Array<
    { __typename?: "TaxPlanComponent" } & TaxPlanComponentFragment
  >;
} & TaxPlanShortFragment;

export type TaxValueFragment = {
  __typename?: "TaxValue";
  includedInPrice: boolean;
  includeInUnitCost: boolean;
  value?: number | null;
  error?: string | null;
  component: { __typename?: "TaxComponent" } & TaxComponentShortFragment;
};

export type TaxPlanWithSampleFragment = {
  __typename?: "TaxPlan";
  sample: Array<{ __typename?: "TaxValue" } & TaxValueFragment>;
} & TaxPlanFragment;

export type TaxPlansQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TaxPlansFilterInput>;
  sort?: InputMaybe<TaxPlansSortInput>;
}>;

export type TaxPlansQuery = {
  __typename?: "Query";
  taxPlans?: {
    __typename?: "TaxPlans";
    totalCount: number;
    items: Array<{ __typename?: "TaxPlan" } & TaxPlanFragment>;
  } | null;
};

export type TaxPlanQueryVariables = Exact<{
  id: Scalars["ID"];
  inputVariables: TaxPlanVariables;
}>;

export type TaxPlanQuery = {
  __typename?: "Query";
  taxPlan?: ({ __typename?: "TaxPlan" } & TaxPlanWithSampleFragment) | null;
};

export type TaxPlanCreateMutationVariables = Exact<{
  fields: TaxPlanFields;
}>;

export type TaxPlanCreateMutation = {
  __typename?: "Mutation";
  taxPlanCreate?: {
    __typename?: "TaxPlanCreatePayload";
    result?: ({ __typename?: "TaxPlan" } & TaxPlanFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TaxPlanUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  inputVariables: TaxPlanVariables;
  changes: TaxPlanChanges;
}>;

export type TaxPlanUpdateMutation = {
  __typename?: "Mutation";
  taxPlanUpdate?: {
    __typename?: "TaxPlanUpdatePayload";
    result?: ({ __typename?: "TaxPlan" } & TaxPlanWithSampleFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TaxPlanDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TaxPlanDiscardMutation = {
  __typename?: "Mutation";
  taxPlanDiscard?: {
    __typename?: "TaxPlanDiscardPayload";
    result?: ({ __typename?: "TaxPlan" } & TaxPlanFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TaxPlanDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TaxPlanDestroyMutation = {
  __typename?: "Mutation";
  taxPlanDestroy?: {
    __typename?: "TaxPlanDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TokenFragment = {
  __typename?: "Token";
  id: string;
  quantity: number;
  discardedAt?: any | null;
  variant: { __typename?: "Variant" } & VariantShortFragment;
};

export type TokensQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TokensFilterInput>;
  sort?: InputMaybe<TokensSortInput>;
}>;

export type TokensQuery = {
  __typename?: "Query";
  tokens?: {
    __typename?: "Tokens";
    totalCount: number;
    items: Array<{ __typename?: "Token" } & TokenFragment>;
  } | null;
};

export type TokenQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TokenQuery = {
  __typename?: "Query";
  token?: ({ __typename?: "Token" } & TokenFragment) | null;
};

export type TokenCreateMutationVariables = Exact<{
  fields: TokenFields;
}>;

export type TokenCreateMutation = {
  __typename?: "Mutation";
  tokenCreate?: {
    __typename?: "TokenCreatePayload";
    result?: ({ __typename?: "Token" } & TokenFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TokenUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: TokenChanges;
}>;

export type TokenUpdateMutation = {
  __typename?: "Mutation";
  tokenUpdate?: {
    __typename?: "TokenUpdatePayload";
    result?: ({ __typename?: "Token" } & TokenFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TokenDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TokenDiscardMutation = {
  __typename?: "Mutation";
  tokenDiscard?: {
    __typename?: "TokenDiscardPayload";
    result?: ({ __typename?: "Token" } & TokenFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type HolidayFragment = {
  __typename?: "Holiday";
  id: string;
  description?: string | null;
  date: any;
};

export type HolidayListFragment = {
  __typename?: "HolidayList";
  id: string;
  year: number;
  holidays: Array<{ __typename?: "Holiday" } & HolidayFragment>;
};

export type HolidaysQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<HolidaysFilterInput>;
  sort?: InputMaybe<HolidaysSortInput>;
}>;

export type HolidaysQuery = {
  __typename?: "Query";
  holidays?: {
    __typename?: "Holidays";
    totalCount: number;
    items: Array<{ __typename?: "Holiday" } & HolidayFragment>;
  } | null;
};

export type HolidayListQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type HolidayListQuery = {
  __typename?: "Query";
  holidayList?: ({ __typename?: "HolidayList" } & HolidayListFragment) | null;
};

export type HolidayListsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<HolidayListsFilterInput>;
  sort?: InputMaybe<HolidayListsSortInput>;
}>;

export type HolidayListsQuery = {
  __typename?: "Query";
  holidayLists?: {
    __typename?: "HolidayLists";
    totalCount: number;
    items: Array<{ __typename?: "HolidayList" } & HolidayListFragment>;
  } | null;
};

export type HolidayListCreateMutationVariables = Exact<{
  fields: HolidayListFields;
}>;

export type HolidayListCreateMutation = {
  __typename?: "Mutation";
  holidayListCreate?: {
    __typename?: "HolidayListCreatePayload";
    result?: ({ __typename?: "HolidayList" } & HolidayListFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type HolidayListUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: HolidayListChanges;
}>;

export type HolidayListUpdateMutation = {
  __typename?: "Mutation";
  holidayListUpdate?: {
    __typename?: "HolidayListUpdatePayload";
    result?: ({ __typename?: "HolidayList" } & HolidayListFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type HolidayListDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type HolidayListDestroyMutation = {
  __typename?: "Mutation";
  holidayListDestroy?: {
    __typename?: "HolidayListDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type LeaveAssignmentFragment = {
  __typename?: "LeaveAssignment";
  id: string;
  status: LeaveAssignmentStatus;
  documentDate: any;
  createdAt: any;
  updatedAt: any;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
  workOrder?: {
    __typename?: "WorkOrder";
    id: string;
    cycleNumber?: number | null;
    activity: { __typename?: "Activity" } & ActivityNameFragment;
    cropCycle?: ({ __typename?: "CropCycle" } & CropCycleNameFragment) | null;
  } | null;
  createdBy: { __typename?: "User" } & UserShortFragment;
  completedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type EmployeeLeaveFragment = {
  __typename?: "LeaveAssignmentEmployee";
  id: string;
  startTime?: any | null;
  endTime?: any | null;
  leaveType?: ({ __typename?: "LeaveType" } & LeaveTypeShortFragment) | null;
};

export type LeaveAssignmentEmployeeFragment = {
  __typename?: "LeaveAssignmentEmployee";
  id: string;
  hours?: number | null;
  days?: number | null;
  startTime?: any | null;
  endTime?: any | null;
  employee: {
    __typename?: "Employee";
    discardedAt?: any | null;
  } & EmployeeShortFragment;
  leaveType?: ({ __typename?: "LeaveType" } & LeaveTypeShortFragment) | null;
  payment?: {
    __typename?: "ActivityPayment";
    wage: number;
    wageUnit: WorkUnit;
  } | null;
};

export type LeaveAssignmentDetailsFragment = {
  __typename?: "LeaveAssignment";
  employees: Array<
    { __typename?: "LeaveAssignmentEmployee" } & LeaveAssignmentEmployeeFragment
  >;
  workOrder?: {
    __typename?: "WorkOrder";
    id: string;
    cycleNumber?: number | null;
    cropCycle?: ({ __typename?: "CropCycle" } & CropCycleNameFragment) | null;
    activity: { __typename?: "Activity" } & ActivityNameFragment;
  } | null;
} & LeaveAssignmentFragment;

export type LeaveAssignmentStatusQueryVariables = Exact<{
  [key: string]: never;
}>;

export type LeaveAssignmentStatusQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type LeaveAssignmentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<LeaveAssignmentsFilterInput>;
  sort?: InputMaybe<LeaveAssignmentsSortInput>;
}>;

export type LeaveAssignmentsQuery = {
  __typename?: "Query";
  leaveAssignments?: {
    __typename?: "LeaveAssignments";
    totalCount: number;
    items: Array<{ __typename?: "LeaveAssignment" } & LeaveAssignmentFragment>;
  } | null;
};

export type LeaveAssignmentQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type LeaveAssignmentQuery = {
  __typename?: "Query";
  leaveAssignment?:
    | ({ __typename?: "LeaveAssignment" } & LeaveAssignmentDetailsFragment)
    | null;
};

export type LeaveAssignmentCreateMutationVariables = Exact<{
  fields: LeaveAssignmentFields;
}>;

export type LeaveAssignmentCreateMutation = {
  __typename?: "Mutation";
  leaveAssignmentCreate?: {
    __typename?: "LeaveAssignmentCreatePayload";
    result?: {
      __typename?: "LeaveAssignment";
      id: string;
      status: LeaveAssignmentStatus;
    } | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type LeaveAssignmentUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: LeaveAssignmentChanges;
}>;

export type LeaveAssignmentUpdateMutation = {
  __typename?: "Mutation";
  leaveAssignmentUpdate?: {
    __typename?: "LeaveAssignmentUpdatePayload";
    result?:
      | ({ __typename?: "LeaveAssignment" } & LeaveAssignmentDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PayrollEntryEmployeeComponentFragment = {
  __typename?: "PayrollEntryEmployeeComponent";
  id: string;
  value: number;
  error?: string | null;
  salaryComponent: {
    __typename?: "SalaryComponent";
  } & SalaryComponentShortFragment;
};

export type PayrollEntryEmployeeFragment = {
  __typename?: "PayrollEntryEmployee";
  id: string;
  inputVariables: any;
  employee: { __typename?: "Employee" } & EmployeeShortFragment;
  components: Array<
    {
      __typename?: "PayrollEntryEmployeeComponent";
    } & PayrollEntryEmployeeComponentFragment
  >;
};

export type PayrollEntryWorkOrderFragment = {
  __typename?: "PayrollEntryWorkOrder";
  id: string;
  payrollCost?: number | null;
  workOrder: {
    __typename?: "WorkOrder";
    summary?: { __typename?: "WorkOrderSummary"; laborCost: number } | null;
  } & WorkOrderInfoFragment;
};

export type PayrollEntryFragment = {
  __typename?: "PayrollEntry";
  id: string;
  status: PayrollEntryStatus;
  documentDate: any;
  startDate: any;
  endDate: any;
  internalId?: string | null;
  createdAt: any;
  localities: Array<{ __typename?: "Locality" } & LocalityNameFragment>;
  salaryStructure?:
    | ({ __typename?: "SalaryStructure" } & SalaryStructureShortFragment)
    | null;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type PayrollEntryDetailsFragment = {
  __typename?: "PayrollEntry";
  createdAt: any;
  updatedAt: any;
  employees: Array<
    { __typename?: "PayrollEntryEmployee" } & PayrollEntryEmployeeFragment
  >;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  updatedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
} & PayrollEntryFragment;

export type PayrollEntriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<PayrollEntriesFilterInput>;
  sort?: InputMaybe<PayrollEntriesSortInput>;
}>;

export type PayrollEntriesQuery = {
  __typename?: "Query";
  payrollEntries?: {
    __typename?: "PayrollEntries";
    totalCount: number;
    items: Array<{ __typename?: "PayrollEntry" } & PayrollEntryFragment>;
  } | null;
};

export type PayrollEntryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PayrollEntryQuery = {
  __typename?: "Query";
  payrollEntry?:
    | ({ __typename?: "PayrollEntry" } & PayrollEntryDetailsFragment)
    | null;
};

export type PayrollEntryWorkOrdersQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PayrollEntryWorkOrdersQuery = {
  __typename?: "Query";
  payrollEntry?: {
    __typename?: "PayrollEntry";
    id: string;
    workOrders: Array<
      { __typename?: "PayrollEntryWorkOrder" } & PayrollEntryWorkOrderFragment
    >;
  } | null;
};

export type PayrollEntryAccountingComponentsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PayrollEntryAccountingComponentsQuery = {
  __typename?: "Query";
  payrollEntry?: {
    __typename?: "PayrollEntry";
    id: string;
    accountingComponents: Array<{
      __typename?: "PayrollComponentResult";
      value?: number | null;
      component: {
        __typename?: "SalaryComponent";
        salaryDebitAccount?:
          | ({ __typename?: "Account" } & AccountShortFragment)
          | null;
        salaryCreditAccount?:
          | ({ __typename?: "Account" } & AccountShortFragment)
          | null;
      } & SalaryComponentShortFragment;
    }>;
  } | null;
};

export type PayrollEntryCreateMutationVariables = Exact<{
  fields: PayrollEntryFields;
}>;

export type PayrollEntryCreateMutation = {
  __typename?: "Mutation";
  payrollEntryCreate?: {
    __typename?: "PayrollEntryCreatePayload";
    result?:
      | ({ __typename?: "PayrollEntry" } & PayrollEntryDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PayrollEntryUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: PayrollEntryChanges;
}>;

export type PayrollEntryUpdateMutation = {
  __typename?: "Mutation";
  payrollEntryUpdate?: {
    __typename?: "PayrollEntryUpdatePayload";
    result?:
      | ({ __typename?: "PayrollEntry" } & PayrollEntryDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PayrollEntryGenerateMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PayrollEntryGenerateMutation = {
  __typename?: "Mutation";
  payrollEntryGenerate?: {
    __typename?: "PayrollEntryGeneratePayload";
    jobId: string;
  } | null;
};

export type PayrollEntryDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PayrollEntryDestroyMutation = {
  __typename?: "Mutation";
  payrollEntryDestroy?: {
    __typename?: "PayrollEntryDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryComponentShortFragment = {
  __typename?: "SalaryComponent";
  id: string;
  name: string;
  money: boolean;
  formula?: string | null;
};

export type SalaryComponentFragment = {
  __typename?: "SalaryComponent";
  nameEn?: string | null;
  nameEs?: string | null;
  description?: string | null;
  descriptionEn?: string | null;
  descriptionEs?: string | null;
  abbr: string;
  formula?: string | null;
  useInCost: boolean;
  discardedAt?: any | null;
  mayDiscard: boolean;
  mayDestroy: boolean;
  salaryDebitAccount?:
    | ({ __typename?: "Account" } & AccountShortFragment)
    | null;
  salaryCreditAccount?:
    | ({ __typename?: "Account" } & AccountShortFragment)
    | null;
} & SalaryComponentShortFragment;

export type SalaryComponentsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<SalaryComponentsFilterInput>;
  sort?: InputMaybe<SalaryComponentsSortInput>;
}>;

export type SalaryComponentsQuery = {
  __typename?: "Query";
  salaryComponents?: {
    __typename?: "SalaryComponents";
    totalCount: number;
    items: Array<{ __typename?: "SalaryComponent" } & SalaryComponentFragment>;
  } | null;
};

export type SalaryComponentQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SalaryComponentQuery = {
  __typename?: "Query";
  salaryComponent?:
    | ({ __typename?: "SalaryComponent" } & SalaryComponentFragment)
    | null;
};

export type SalaryComponentCreateMutationVariables = Exact<{
  fields: SalaryComponentFields;
}>;

export type SalaryComponentCreateMutation = {
  __typename?: "Mutation";
  salaryComponentCreate?: {
    __typename?: "SalaryComponentCreatePayload";
    result?:
      | ({ __typename?: "SalaryComponent" } & SalaryComponentFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryComponentUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: SalaryComponentChanges;
}>;

export type SalaryComponentUpdateMutation = {
  __typename?: "Mutation";
  salaryComponentUpdate?: {
    __typename?: "SalaryComponentUpdatePayload";
    result?:
      | ({ __typename?: "SalaryComponent" } & SalaryComponentFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryComponentDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SalaryComponentDiscardMutation = {
  __typename?: "Mutation";
  salaryComponentDiscard?: {
    __typename?: "SalaryComponentDiscardPayload";
    result?:
      | ({ __typename?: "SalaryComponent" } & SalaryComponentFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryComponentDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SalaryComponentDestroyMutation = {
  __typename?: "Mutation";
  salaryComponentDestroy?: {
    __typename?: "SalaryComponentDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryStructureShortFragment = {
  __typename?: "SalaryStructure";
  id: string;
  name: string;
};

export type SalaryStructureFragment = {
  __typename?: "SalaryStructure";
  nameEn?: string | null;
  nameEs?: string | null;
  discardedAt?: any | null;
  mayDiscard: boolean;
  mayDestroy: boolean;
  salaryComponents: Array<
    { __typename?: "SalaryComponent" } & SalaryComponentFragment
  >;
} & SalaryStructureShortFragment;

export type PayrollResultFragment = {
  __typename?: "PayrollResult";
  components: Array<{
    __typename?: "PayrollComponentResult";
    value?: number | null;
    error?: string | null;
    component: { __typename?: "SalaryComponent"; id: string; money: boolean };
  }>;
};

export type SalaryStructureWithPayrollFragment = {
  __typename?: "SalaryStructure";
  payrollResult: { __typename?: "PayrollResult" } & PayrollResultFragment;
} & SalaryStructureFragment;

export type SalaryStructuresQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<SalaryStructuresFilterInput>;
  sort?: InputMaybe<SalaryStructuresSortInput>;
}>;

export type SalaryStructuresQuery = {
  __typename?: "Query";
  salaryStructures?: {
    __typename?: "SalaryStructures";
    totalCount: number;
    items: Array<{ __typename?: "SalaryStructure" } & SalaryStructureFragment>;
  } | null;
};

export type SalaryStructureQueryVariables = Exact<{
  id: Scalars["ID"];
  inputVariables: PayrollInputVariables;
}>;

export type SalaryStructureQuery = {
  __typename?: "Query";
  salaryStructure?:
    | ({ __typename?: "SalaryStructure" } & SalaryStructureWithPayrollFragment)
    | null;
};

export type SalaryStructureCreateMutationVariables = Exact<{
  fields: SalaryStructureFields;
}>;

export type SalaryStructureCreateMutation = {
  __typename?: "Mutation";
  salaryStructureCreate?: {
    __typename?: "SalaryStructureCreatePayload";
    result?:
      | ({ __typename?: "SalaryStructure" } & SalaryStructureFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryStructureUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  inputVariables: PayrollInputVariables;
  changes: SalaryStructureChanges;
}>;

export type SalaryStructureUpdateMutation = {
  __typename?: "Mutation";
  salaryStructureUpdate?: {
    __typename?: "SalaryStructureUpdatePayload";
    result?:
      | ({
          __typename?: "SalaryStructure";
        } & SalaryStructureWithPayrollFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryStructureDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type SalaryStructureDiscardMutation = {
  __typename?: "Mutation";
  salaryStructureDiscard?: {
    __typename?: "SalaryStructureDiscardPayload";
    result?:
      | ({ __typename?: "SalaryStructure" } & SalaryStructureFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryStructureDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type SalaryStructureDestroyMutation = {
  __typename?: "Mutation";
  salaryStructureDestroy?: {
    __typename?: "SalaryStructureDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WeekDayFragment = {
  __typename?: "WeekDay";
  id: string;
  name: string;
  working: boolean;
  startTime?: any | null;
  endTime?: any | null;
};

export type WeekDaysQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<WeekDaysFilterInput>;
  sort?: InputMaybe<WeekDaysSortInput>;
}>;

export type WeekDaysQuery = {
  __typename?: "Query";
  weekDays?: {
    __typename?: "WeekDays";
    totalCount: number;
    items: Array<{ __typename?: "WeekDay" } & WeekDayFragment>;
  } | null;
};

export type AdjustmentKindQueryVariables = Exact<{ [key: string]: never }>;

export type AdjustmentKindQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type AdjustmentTypeFragment = {
  __typename?: "AdjustmentType";
  id: string;
  name: string;
  kind: AdjustmentKind;
  entryCode: string;
  discardedAt?: any | null;
  locked: boolean;
  global: boolean;
  mayDiscard: boolean;
  mayDestroy: boolean;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type AdjustmentTypesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<AdjustmentTypesFilterInput>;
  sort?: InputMaybe<AdjustmentTypesSortInput>;
}>;

export type AdjustmentTypesQuery = {
  __typename?: "Query";
  adjustmentTypes?: {
    __typename?: "AdjustmentTypes";
    totalCount: number;
    items: Array<{ __typename?: "AdjustmentType" } & AdjustmentTypeFragment>;
  } | null;
};

export type AdjustmentTypeQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AdjustmentTypeQuery = {
  __typename?: "Query";
  adjustmentType?:
    | ({ __typename?: "AdjustmentType" } & AdjustmentTypeFragment)
    | null;
};

export type AdjustmentTypeCreateMutationVariables = Exact<{
  fields: AdjustmentTypeFields;
}>;

export type AdjustmentTypeCreateMutation = {
  __typename?: "Mutation";
  adjustmentTypeCreate?: {
    __typename?: "AdjustmentTypeCreatePayload";
    result?:
      | ({ __typename?: "AdjustmentType" } & AdjustmentTypeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type AdjustmentTypeUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: AdjustmentTypeChanges;
}>;

export type AdjustmentTypeUpdateMutation = {
  __typename?: "Mutation";
  adjustmentTypeUpdate?: {
    __typename?: "AdjustmentTypeUpdatePayload";
    result?:
      | ({ __typename?: "AdjustmentType" } & AdjustmentTypeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type AdjustmentTypeDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type AdjustmentTypeDiscardMutation = {
  __typename?: "Mutation";
  adjustmentTypeDiscard?: {
    __typename?: "AdjustmentTypeDiscardPayload";
    result?:
      | ({ __typename?: "AdjustmentType" } & AdjustmentTypeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type AdjustmentTypeDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type AdjustmentTypeDestroyMutation = {
  __typename?: "Mutation";
  adjustmentTypeDestroy?: {
    __typename?: "AdjustmentTypeDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type EquipmentShortFragment = {
  __typename?: "Equipment";
  id: string;
  name: string;
};

export type EquipmentListQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<EquipmentListFilterInput>;
  sort?: InputMaybe<EquipmentListSortInput>;
}>;

export type EquipmentListQuery = {
  __typename?: "Query";
  equipmentList?: {
    __typename?: "EquipmentList";
    totalCount: number;
    items: Array<{ __typename?: "Equipment" } & EquipmentShortFragment>;
  } | null;
};

export type InventoryOrderLineItemFragment = {
  __typename?: "InventoryOrderLineItem";
  id: string;
  quantity: number;
  unitCost: number;
  discountRate?: number | null;
  discount?: number | null;
  subtotal: number;
  variant: {
    __typename?: "Variant";
    item: {
      __typename?: "Item";
      defaultTaxPlan?:
        | ({ __typename?: "TaxPlan" } & TaxPlanWithComponentsFragment)
        | null;
    };
  } & VariantShortFragment;
  taxPlanValues: Array<{ __typename?: "TaxValue" } & TaxValueFragment>;
};

export type InventoryOrderFragment = {
  __typename?: "InventoryOrder";
  id: string;
  status: InventoryOrderStatus;
  kind: InventoryOrderKind;
  currencyCode: string;
  discount: number;
  discountRate: number;
  tax: number;
  taxRate: number;
  exchangeRate: number;
  otherCosts: number;
  subtotal: number;
  total: number;
  documentDate: any;
  internalId?: string | null;
  notes?: string | null;
  createdAt: any;
  updatedAt: any;
  counterparty: { __typename?: "Counterparty"; id: string; name: string };
  warehouse: { __typename?: "Warehouse" } & WarehouseLocalityFragment;
  paymentMethod: { __typename?: "PaymentMethod"; id: string; name: string };
  currency: { __typename?: "Currency" } & CurrencyFragment;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  updatedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type InventoryOrderDetailsFragment = {
  __typename?: "InventoryOrder";
  counterparty: {
    __typename?: "Counterparty";
    taxPlan?:
      | ({ __typename?: "TaxPlan" } & TaxPlanWithComponentsFragment)
      | null;
  };
  account?: ({ __typename?: "Account" } & AccountShortFragment) | null;
  lineItems: Array<
    { __typename?: "InventoryOrderLineItem" } & InventoryOrderLineItemFragment
  >;
  taxValues: Array<{ __typename?: "TaxValue" } & TaxValueFragment>;
} & InventoryOrderFragment;

export type InventoryOrdersQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<InventoryOrdersFilterInput>;
  sort?: InputMaybe<InventoryOrdersSortInput>;
}>;

export type InventoryOrdersQuery = {
  __typename?: "Query";
  inventoryOrders?: {
    __typename?: "InventoryOrders";
    totalCount: number;
    items: Array<{ __typename?: "InventoryOrder" } & InventoryOrderFragment>;
  } | null;
};

export type InventoryOrderQueryVariables = Exact<{
  id: Scalars["ID"];
  nextDocuments?: InputMaybe<Scalars["Boolean"]>;
}>;

export type InventoryOrderQuery = {
  __typename?: "Query";
  inventoryOrder?:
    | ({
        __typename?: "InventoryOrder";
        nextDocuments?: Array<{
          __typename?: "InventoryDocumentReference";
          documentId: string;
          documentType: InventoryDocumentType;
          documentDate: any;
        }>;
        invoices?: Array<{
          __typename?: "FinanceInvoice";
          id: string;
          documentDate: any;
          invoiceStatus: InvoiceStatus;
        }>;
      } & InventoryOrderDetailsFragment)
    | null;
};

export type InventoryOrderCreateMutationVariables = Exact<{
  fields: InventoryOrderFields;
}>;

export type InventoryOrderCreateMutation = {
  __typename?: "Mutation";
  inventoryOrderCreate?: {
    __typename?: "InventoryOrderCreatePayload";
    result?:
      | ({ __typename?: "InventoryOrder" } & InventoryOrderDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type InventoryOrderUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: InventoryOrderChanges;
}>;

export type InventoryOrderUpdateMutation = {
  __typename?: "Mutation";
  inventoryOrderUpdate?: {
    __typename?: "InventoryOrderUpdatePayload";
    result?:
      | ({ __typename?: "InventoryOrder" } & InventoryOrderDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type InventoryOrderCloneMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: InventoryOrderChanges;
}>;

export type InventoryOrderCloneMutation = {
  __typename?: "Mutation";
  inventoryOrderClone?: {
    __typename?: "InventoryOrderClonePayload";
    result?:
      | ({ __typename?: "InventoryOrder" } & InventoryOrderDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemCategoryNameFragment = {
  __typename?: "ItemCategory";
  id: string;
  name: string;
};

export type ItemCategoryFragment = {
  __typename?: "ItemCategory";
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  locked: boolean;
  global: boolean;
  defaultItemKind?: ItemKind | null;
  discardedAt?: any | null;
  parentCategory?: {
    __typename?: "ItemCategory";
    id: string;
    name: string;
  } | null;
  taxPlan?: ({ __typename?: "TaxPlan" } & TaxPlanShortFragment) | null;
  defaultTaxPlan?: ({ __typename?: "TaxPlan" } & TaxPlanShortFragment) | null;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
} & ItemCategoryNameFragment;

export type ItemCategoryTreeFragment = {
  __typename?: "ItemCategory";
  subcategories: Array<{ __typename?: "ItemCategory"; id: string }>;
} & ItemCategoryFragment;

export type ItemCategoryOptionFragment = {
  __typename?: "ItemCategory";
  defaultItemKind?: ItemKind | null;
  allowIngredients: boolean;
  parentCategory?: {
    __typename?: "ItemCategory";
    id: string;
    name: string;
  } | null;
  subcategories: Array<{ __typename?: "ItemCategory"; id: string }>;
} & ItemCategoryNameFragment;

export type ItemCategoriesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ItemCategoriesFilterInput>;
  sort?: InputMaybe<ItemCategoriesSortInput>;
}>;

export type ItemCategoriesQuery = {
  __typename?: "Query";
  itemCategories?: {
    __typename?: "ItemCategories";
    totalCount: number;
    items: Array<{ __typename?: "ItemCategory" } & ItemCategoryTreeFragment>;
  } | null;
};

export type ItemCategoryOptionsQueryVariables = Exact<{
  filter?: InputMaybe<ItemCategoriesFilterInput>;
  sort?: InputMaybe<ItemCategoriesSortInput>;
}>;

export type ItemCategoryOptionsQuery = {
  __typename?: "Query";
  itemCategories?: {
    __typename?: "ItemCategories";
    items: Array<{ __typename?: "ItemCategory" } & ItemCategoryOptionFragment>;
  } | null;
};

export type ItemCategoryQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemCategoryQuery = {
  __typename?: "Query";
  itemCategory?:
    | ({
        __typename?: "ItemCategory";
        subcategories: Array<
          { __typename?: "ItemCategory" } & ItemCategoryFragment
        >;
      } & ItemCategoryFragment)
    | null;
};

export type ItemCategoryCreateMutationVariables = Exact<{
  fields: ItemCategoryFields;
}>;

export type ItemCategoryCreateMutation = {
  __typename?: "Mutation";
  itemCategoryCreate?: {
    __typename?: "ItemCategoryCreatePayload";
    result?:
      | ({ __typename?: "ItemCategory" } & ItemCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemCategoryUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: ItemCategoryChanges;
}>;

export type ItemCategoryUpdateMutation = {
  __typename?: "Mutation";
  itemCategoryUpdate?: {
    __typename?: "ItemCategoryUpdatePayload";
    result?:
      | ({ __typename?: "ItemCategory" } & ItemCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemCategoryLocalUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: LocalItemCategoryChanges;
}>;

export type ItemCategoryLocalUpdateMutation = {
  __typename?: "Mutation";
  itemCategoryLocalUpdate?: {
    __typename?: "LocalItemCategoryUpdatePayload";
    result?:
      | ({ __typename?: "ItemCategory" } & ItemCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemCategoryDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemCategoryDiscardMutation = {
  __typename?: "Mutation";
  itemCategoryDiscard?: {
    __typename?: "ItemCategoryDiscardPayload";
    result?:
      | ({ __typename?: "ItemCategory" } & ItemCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemCategoryDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ItemCategoryDestroyMutation = {
  __typename?: "Mutation";
  itemCategoryDestroy?: {
    __typename?: "ItemCategoryDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemCategoryUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemCategoryUnlockMutation = {
  __typename?: "Mutation";
  itemCategoryUnlock?: {
    __typename?: "ItemCategoryUnlockPayload";
    result?:
      | ({ __typename?: "ItemCategory" } & ItemCategoryTreeFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemIngredientFragment = {
  __typename?: "ItemIngredient";
  id: string;
  rate: number;
  ingredient: { __typename?: "Ingredient" } & IngredientShortFragment;
};

export type ItemCropVarietyFragment = {
  __typename?: "ItemCropVariety";
  id: string;
  cropVariety: { __typename?: "CropVariety" } & CropVarietyShortFragment;
};

export type ItemFragment = {
  __typename?: "Item";
  id: string;
  name: string;
  kind: ItemKind;
  description?: string | null;
  variationUnitType: UnitType;
  discardedAt?: any | null;
  toxicityCategory?: ToxicityCategory | null;
  entryPeriod?: number | null;
  preHarvestInterval?: number | null;
  tags?: Array<string> | null;
  itemCategory: {
    __typename?: "ItemCategory";
    id: string;
    name: string;
    parentCategory?: {
      __typename?: "ItemCategory";
      id: string;
      name: string;
    } | null;
  };
  itemIngredients: Array<
    { __typename?: "ItemIngredient" } & ItemIngredientFragment
  >;
  taxPlan?: ({ __typename?: "TaxPlan" } & TaxPlanShortFragment) | null;
  defaultTaxPlan?: ({ __typename?: "TaxPlan" } & TaxPlanShortFragment) | null;
  crop?: ({ __typename?: "Crop" } & CropShortFragment) | null;
  itemCropVarieties: Array<
    { __typename?: "ItemCropVariety" } & ItemCropVarietyFragment
  >;
  entryPeriodUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
  preHarvestIntervalUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
  equipment: Array<{ __typename?: "Equipment" } & EquipmentShortFragment>;
  diseases: Array<{ __typename?: "Disease" } & DiseaseShortFragment>;
};

export type ItemShortFragment = {
  __typename?: "Item";
  id: string;
  name: string;
  kind: ItemKind;
  itemCategory: {
    __typename?: "ItemCategory";
    id: string;
    name: string;
    parentCategory?: {
      __typename?: "ItemCategory";
      id: string;
      name: string;
    } | null;
  };
  itemIngredients: Array<
    { __typename?: "ItemIngredient" } & ItemIngredientFragment
  >;
  itemCropVarieties: Array<
    { __typename?: "ItemCropVariety" } & ItemCropVarietyFragment
  >;
};

export type ItemDetailsFragment = {
  __typename?: "Item";
  mayUpdateUnitKind: boolean;
  variants: Array<
    {
      __typename?: "Variant";
      token?: { __typename?: "Token"; quantity: number } | null;
    } & VariantDetailsFragment
  >;
} & ItemFragment;

export type ItemKindQueryVariables = Exact<{ [key: string]: never }>;

export type ItemKindQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type ToxicityCategoryQueryVariables = Exact<{ [key: string]: never }>;

export type ToxicityCategoryQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type ItemsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ItemsFilterInput>;
  sort?: InputMaybe<ItemsSortInput>;
}>;

export type ItemsQuery = {
  __typename?: "Query";
  items?: {
    __typename?: "Items";
    totalCount: number;
    items: Array<
      {
        __typename?: "Item";
        variants: Array<
          {
            __typename?: "Variant";
            discardedAt?: any | null;
          } & VariantShortFragment
        >;
      } & ItemFragment
    >;
  } | null;
};

export type ItemQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ItemQuery = {
  __typename?: "Query";
  item?: ({ __typename?: "Item" } & ItemDetailsFragment) | null;
};

export type ItemCreateMutationVariables = Exact<{
  fields: ItemFields;
}>;

export type ItemCreateMutation = {
  __typename?: "Mutation";
  itemCreate?: {
    __typename?: "ItemCreatePayload";
    result?: ({ __typename?: "Item" } & ItemDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: ItemChanges;
}>;

export type ItemUpdateMutation = {
  __typename?: "Mutation";
  itemUpdate?: {
    __typename?: "ItemUpdatePayload";
    result?: ({ __typename?: "Item" } & ItemDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ItemDiscardMutation = {
  __typename?: "Mutation";
  itemDiscard?: {
    __typename?: "ItemDiscardPayload";
    result?: ({ __typename?: "Item" } & ItemDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ItemDestroyMutation = {
  __typename?: "Mutation";
  itemDestroy?: {
    __typename?: "ItemDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemBulkUpdateMutationVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
  changes: ItemBulkChanges;
}>;

export type ItemBulkUpdateMutation = {
  __typename?: "Mutation";
  itemBulkUpdate?: {
    __typename?: "ItemBulkBulkUpdatePayload";
    result: Array<{
      __typename?: "BulkOperationResult";
      id: string;
      result?: boolean | null;
      errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
    }>;
  } | null;
};

export type PaymentMethodFragment = {
  __typename?: "PaymentMethod";
  id: string;
  name: string;
  paymentMethodType: PaymentMethodType;
  nameEn?: string | null;
  nameEs?: string | null;
  namePt?: string | null;
  nameRu?: string | null;
  dueDays: number;
  locked: boolean;
  global: boolean;
  discardedAt?: any | null;
  canUpdate: { __typename?: "AuthorizationResult"; value: boolean };
  canDelete: { __typename?: "AuthorizationResult"; value: boolean };
  canUnlock: { __typename?: "AuthorizationResult"; value: boolean };
};

export type PaymentMethodTypeQueryVariables = Exact<{ [key: string]: never }>;

export type PaymentMethodTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type PaymentMethodsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<PaymentMethodsFilterInput>;
  sort?: InputMaybe<PaymentMethodsSortInput>;
}>;

export type PaymentMethodsQuery = {
  __typename?: "Query";
  paymentMethods?: {
    __typename?: "PaymentMethods";
    totalCount: number;
    items: Array<{ __typename?: "PaymentMethod" } & PaymentMethodFragment>;
  } | null;
};

export type PaymentMethodQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PaymentMethodQuery = {
  __typename?: "Query";
  paymentMethod?:
    | ({ __typename?: "PaymentMethod" } & PaymentMethodFragment)
    | null;
};

export type PaymentMethodCreateMutationVariables = Exact<{
  fields: PaymentMethodFields;
}>;

export type PaymentMethodCreateMutation = {
  __typename?: "Mutation";
  paymentMethodCreate?: {
    __typename?: "PaymentMethodCreatePayload";
    result?: ({ __typename?: "PaymentMethod" } & PaymentMethodFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PaymentMethodUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: PaymentMethodChanges;
}>;

export type PaymentMethodUpdateMutation = {
  __typename?: "Mutation";
  paymentMethodUpdate?: {
    __typename?: "PaymentMethodUpdatePayload";
    result?: ({ __typename?: "PaymentMethod" } & PaymentMethodFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PaymentMethodDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PaymentMethodDiscardMutation = {
  __typename?: "Mutation";
  paymentMethodDiscard?: {
    __typename?: "PaymentMethodDiscardPayload";
    result?: ({ __typename?: "PaymentMethod" } & PaymentMethodFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type PaymentMethodUnlockMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type PaymentMethodUnlockMutation = {
  __typename?: "Mutation";
  paymentMethodUnlock?: {
    __typename?: "PaymentMethodUnlockPayload";
    result?: ({ __typename?: "PaymentMethod" } & PaymentMethodFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type RecipeFragment = {
  __typename?: "Recipe";
  id: string;
  name: string;
  description?: string | null;
  discardedAt?: any | null;
  variants: Array<{
    __typename?: "RecipeVariant";
    id: string;
    rate: number;
    variant: { __typename?: "Variant" } & VariantShortFragment;
    unit: { __typename?: "Unit" } & UnitShortConversionFragment;
    applicationUnit?:
      | ({ __typename?: "Unit" } & UnitShortConversionFragment)
      | null;
  }>;
};

export type RecipesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<RecipesFilterInput>;
  sort?: InputMaybe<RecipesSortInput>;
}>;

export type RecipesQuery = {
  __typename?: "Query";
  recipes?: {
    __typename?: "Recipes";
    totalCount: number;
    items: Array<{ __typename?: "Recipe" } & RecipeFragment>;
  } | null;
};

export type RecipeQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RecipeQuery = {
  __typename?: "Query";
  recipe?: ({ __typename?: "Recipe" } & RecipeFragment) | null;
};

export type RecipeCreateMutationVariables = Exact<{
  fields: RecipeFields;
}>;

export type RecipeCreateMutation = {
  __typename?: "Mutation";
  recipeCreate?: {
    __typename?: "RecipeCreatePayload";
    result?: ({ __typename?: "Recipe" } & RecipeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type RecipeUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: RecipeChanges;
}>;

export type RecipeUpdateMutation = {
  __typename?: "Mutation";
  recipeUpdate?: {
    __typename?: "RecipeUpdatePayload";
    result?: ({ __typename?: "Recipe" } & RecipeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type RecipeDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type RecipeDiscardMutation = {
  __typename?: "Mutation";
  recipeDiscard?: {
    __typename?: "RecipeDiscardPayload";
    result?: ({ __typename?: "Recipe" } & RecipeFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type RecipeDestroyMutationVariables = Exact<{
  id: Scalars["ID"];
  dryRun?: InputMaybe<Scalars["Boolean"]>;
}>;

export type RecipeDestroyMutation = {
  __typename?: "Mutation";
  recipeDestroy?: {
    __typename?: "RecipeDestroyPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TransferVariantFragment = {
  __typename?: "TransferVariant";
  id: string;
  quantity: number;
  cost?: number | null;
  variant: {
    __typename?: "Variant";
    id: string;
    name: string;
    variationValue: number;
    variationUnit: {
      __typename?: "Unit";
      id: string;
      name: string;
      abbr: string;
    };
  };
};

export type TransferFragment = {
  __typename?: "Transfer";
  id: string;
  status: TransferStatus;
  documentDate: any;
  internalId?: string | null;
  notes?: string | null;
  createdAt: any;
  updatedAt: any;
  adjustmentType: { __typename?: "AdjustmentType" } & AdjustmentTypeFragment;
  sourceWarehouse: { __typename?: "Warehouse" } & WarehouseLocalityFragment;
  targetWarehouse: { __typename?: "Warehouse" } & WarehouseLocalityFragment;
  createdBy?: ({ __typename?: "User" } & UserShortFragment) | null;
  updatedBy?: ({ __typename?: "User" } & UserShortFragment) | null;
};

export type TransferDetailsFragment = {
  __typename?: "Transfer";
  transferVariants: Array<
    {
      __typename?: "TransferVariant";
      variant: { __typename?: "Variant" } & VariantShortFragment;
    } & TransferVariantFragment
  >;
} & TransferFragment;

export type TransfersQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TransfersFilterInput>;
  sort?: InputMaybe<TransfersSortInput>;
}>;

export type TransfersQuery = {
  __typename?: "Query";
  stockTransfers?: {
    __typename?: "Transfers";
    totalCount: number;
    items: Array<{ __typename?: "Transfer" } & TransferFragment>;
  } | null;
};

export type TransferQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type TransferQuery = {
  __typename?: "Query";
  stockTransfer?:
    | ({ __typename?: "Transfer" } & TransferDetailsFragment)
    | null;
};

export type StockTransferVariantFragment = {
  __typename?: "TransferVariant";
  variant: { __typename?: "Variant"; cost: number } & VariantShortFragment;
  stock: { __typename?: "Stock" } & StockFragment;
} & TransferVariantFragment;

export type StockTransferQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type StockTransferQuery = {
  __typename?: "Query";
  stockTransfer?:
    | ({
        __typename?: "Transfer";
        transferVariants: Array<
          { __typename?: "TransferVariant" } & StockTransferVariantFragment
        >;
      } & TransferDetailsFragment)
    | null;
};

export type TransferCreateMutationVariables = Exact<{
  fields: TransferFields;
}>;

export type TransferCreateMutation = {
  __typename?: "Mutation";
  stockTransferCreate?: {
    __typename?: "TransferCreatePayload";
    result?: { __typename?: "Transfer"; id: string } | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TransferUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: TransferChanges;
}>;

export type TransferUpdateMutation = {
  __typename?: "Mutation";
  stockTransferUpdate?: {
    __typename?: "TransferUpdatePayload";
    result?:
      | ({
          __typename?: "Transfer";
          transferVariants: Array<
            { __typename?: "TransferVariant" } & StockTransferVariantFragment
          >;
        } & TransferDetailsFragment)
      | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type TransferCloneMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: TransferChanges;
}>;

export type TransferCloneMutation = {
  __typename?: "Mutation";
  stockTransferClone?: {
    __typename?: "InventoryTransferClonePayload";
    result?: ({ __typename?: "Transfer" } & TransferDetailsFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type StockFragment = {
  __typename?: "Stock";
  total: number;
  onHand: number;
  reserved: number;
};

export type VariantShortFragment = {
  __typename?: "Variant";
  id: string;
  name: string;
  internalId?: string | null;
  variationValue: number;
  variationUnit: { __typename?: "Unit" } & UnitShortConversionFragment;
  item: { __typename?: "Item" } & ItemShortFragment;
};

export type VariantDetailsFragment = {
  __typename?: "Variant";
  mayUpdateVariationValue: boolean;
  mayDiscard: boolean;
  mayDestroy: boolean;
  discardedAt?: any | null;
} & VariantShortFragment;

export type VariantsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<VariantsFilterInput>;
  sort?: InputMaybe<VariantsSortInput>;
}>;

export type VariantsQuery = {
  __typename?: "Query";
  variants?: {
    __typename?: "Variants";
    totalCount: number;
    items: Array<
      {
        __typename?: "Variant";
        item: { __typename?: "Item" } & ItemFragment;
      } & VariantShortFragment
    >;
  } | null;
};

export type StockVariantFragment = {
  __typename?: "Variant";
  cost?: number;
  stock: { __typename?: "Stock"; onHand: number };
} & VariantShortFragment;

export type StockVariantsQueryVariables = Exact<{
  localityId?: InputMaybe<Scalars["ID"]>;
  locationId?: InputMaybe<Scalars["ID"]>;
  locationType?: InputMaybe<LocationType>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<VariantsFilterInput>;
  sort?: InputMaybe<VariantsSortInput>;
  token?: InputMaybe<Scalars["Boolean"]>;
  virtual?: InputMaybe<VirtualWarehouse>;
  fetchCost?: InputMaybe<Scalars["Boolean"]>;
  fetchTaxPlan?: InputMaybe<Scalars["Boolean"]>;
  fetchEquipment?: InputMaybe<Scalars["Boolean"]>;
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
}>;

export type StockVariantsQuery = {
  __typename?: "Query";
  variants?: {
    __typename?: "Variants";
    totalCount: number;
    items: Array<
      {
        __typename?: "Variant";
        token?: { __typename?: "Token"; quantity: number } | null;
        item: {
          __typename?: "Item";
          defaultTaxPlan?:
            | ({ __typename?: "TaxPlan" } & TaxPlanWithComponentsFragment)
            | null;
          equipment?: Array<
            { __typename?: "Equipment" } & EquipmentShortFragment
          >;
        };
      } & StockVariantFragment
    >;
  } | null;
};

export type VariantQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type VariantQuery = {
  __typename?: "Query";
  variant?:
    | ({
        __typename?: "Variant";
        item: { __typename?: "Item" } & ItemFragment;
      } & VariantDetailsFragment)
    | null;
};

export type WarehouseFragment = {
  __typename?: "Warehouse";
  id: string;
  name: string;
  discardedAt?: any | null;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
  costCenter?: ({ __typename?: "CostCenter" } & CostCenterShortFragment) | null;
  infrastructure?: {
    __typename?: "Infrastructure";
    id: string;
    kind: {
      __typename?: "InfrastructureKind";
      name: string;
      category: InfrastructureCategory;
    };
  } | null;
};

export type WarehouseShortFragment = {
  __typename?: "Warehouse";
  id: string;
  name: string;
};

export type WarehouseLocalityFragment = {
  __typename?: "Warehouse";
  locality: { __typename?: "Locality" } & LocalityNameFragment;
} & WarehouseShortFragment;

export type WarehousesQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<WarehousesFilterInput>;
  sort?: InputMaybe<WarehousesSortInput>;
}>;

export type WarehousesQuery = {
  __typename?: "Query";
  warehouses?: {
    __typename?: "Warehouses";
    totalCount: number;
    items: Array<{ __typename?: "Warehouse" } & WarehouseFragment>;
  } | null;
};

export type StockWarehousesQueryVariables = Exact<{
  variantId: Scalars["ID"];
  unitId?: InputMaybe<Scalars["ID"]>;
  date?: InputMaybe<Scalars["ISO8601DateTime"]>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<WarehousesFilterInput>;
  sort?: InputMaybe<WarehousesSortInput>;
}>;

export type StockWarehousesQuery = {
  __typename?: "Query";
  warehouses?: {
    __typename?: "Warehouses";
    totalCount: number;
    items: Array<
      {
        __typename?: "Warehouse";
        stock: { __typename?: "Stock"; onHand: number };
      } & WarehouseFragment
    >;
  } | null;
};

export type WarehouseQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type WarehouseQuery = {
  __typename?: "Query";
  warehouse?: ({ __typename?: "Warehouse" } & WarehouseFragment) | null;
};

export type WarehouseCreateMutationVariables = Exact<{
  fields: WarehouseFields;
}>;

export type WarehouseCreateMutation = {
  __typename?: "Mutation";
  warehouseCreate?: {
    __typename?: "WarehouseCreatePayload";
    result?: ({ __typename?: "Warehouse" } & WarehouseFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WarehouseUpdateMutationVariables = Exact<{
  id: Scalars["ID"];
  changes: WarehouseChanges;
}>;

export type WarehouseUpdateMutation = {
  __typename?: "Mutation";
  warehouseUpdate?: {
    __typename?: "WarehouseUpdatePayload";
    result?: ({ __typename?: "Warehouse" } & WarehouseFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type WarehouseDiscardMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type WarehouseDiscardMutation = {
  __typename?: "Mutation";
  warehouseDiscard?: {
    __typename?: "WarehouseDiscardPayload";
    result?: ({ __typename?: "Warehouse" } & WarehouseFragment) | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivitySeedFragment = {
  __typename?: "ActivitySeed";
  id: string;
  name: string;
  category: { __typename?: "ActivityCategory"; id: string; name: string };
};

export type OnboardingSeedsActivitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type OnboardingSeedsActivitiesQuery = {
  __typename?: "Query";
  onboardingSeedsActivities: Array<
    { __typename?: "ActivitySeed" } & ActivitySeedFragment
  >;
};

export type OnboardingSeedActivitiesMutationVariables = Exact<{
  names: Array<Scalars["String"]> | Scalars["String"];
}>;

export type OnboardingSeedActivitiesMutation = {
  __typename?: "Mutation";
  onboardingSeedActivities?: {
    __typename?: "CreateActivitiesPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ItemSeedFragment = {
  __typename?: "ItemSeed";
  id: string;
  name: string;
  persisted: boolean;
  variationUnitType: UnitType;
  itemCategory: { __typename?: "ItemCategory"; id: string; name: string };
  itemIngredients: Array<{
    __typename?: "ItemIngredient";
    id: string;
    rate: number;
    ingredient: { __typename?: "Ingredient" } & IngredientShortFragment;
  }>;
};

export type OnboardingSeedsItemsQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  sort?: InputMaybe<OnboardingSeedsItemsSortInput>;
  filter?: InputMaybe<OnboardingSeedsItemsFilterInput>;
}>;

export type OnboardingSeedsItemsQuery = {
  __typename?: "Query";
  onboardingSeedsItems?: {
    __typename?: "OnboardingSeedsItems";
    totalCount: number;
    items: Array<{ __typename?: "ItemSeed" } & ItemSeedFragment>;
  } | null;
};

export type OnboardingSeedItemsMutationVariables = Exact<{
  ids: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type OnboardingSeedItemsMutation = {
  __typename?: "Mutation";
  onboardingSeedItems?: {
    __typename?: "CreateItemsPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type SalaryStructureSeedFragment = {
  __typename?: "SalaryStructure";
  id: string;
  name: string;
  salaryComponents: Array<
    { __typename?: "SalaryComponent" } & SalaryComponentFragment
  >;
  payrollResult: { __typename?: "PayrollResult" } & PayrollResultFragment;
};

export type OnboardingSeedsSalaryStructuresQueryVariables = Exact<{
  inputVariables: PayrollInputVariables;
}>;

export type OnboardingSeedsSalaryStructuresQuery = {
  __typename?: "Query";
  onboardingSeedsSalaryStructures: Array<
    { __typename?: "SalaryStructure" } & SalaryStructureSeedFragment
  >;
};

export type OnboardingSeedSalaryStructuresMutationVariables = Exact<{
  names: Array<Scalars["String"]> | Scalars["String"];
}>;

export type OnboardingSeedSalaryStructuresMutation = {
  __typename?: "Mutation";
  onboardingSeedSalaryStructures?: {
    __typename?: "CreateSalaryStructuresPayload";
    result?: boolean | null;
    errors: Array<{ __typename?: "UserError" } & ErrorFragment>;
  } | null;
};

export type ActivityOverviewReportFragment = {
  __typename?: "ActivityOverview";
  totalLaborCost?: number;
  totalInventoryCost?: number;
  totalCost?: number;
  totalManDays: number;
  totalMachineHours: number;
  avgDayWorkers: number;
  metrics: Array<{ __typename?: "WorkOrderMetric" } & MetricValueFragment>;
};

export type ActivitySummaryReportFragment = {
  __typename?: "ActivitySummary";
  cycleNumber?: number | null;
  startingDate: any;
  endingDate: any;
  workDays: number;
  progress: number;
  manDays: number;
  laborManDays: number;
  avgWorkers: number;
  laborEfficiency?: number | null;
  machineHours?: number | null;
  machineEfficiency?: number | null;
  laborCost?: number;
  inventoryCost?: number;
  totalCost?: number;
  totalCostPerProgress?: number | null;
  activity: {
    __typename?: "Activity";
    progressUnit: { __typename?: "Unit" } & UnitShortFragment;
  } & ActivityShortFragment;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
  cropCycle?: ({ __typename?: "CropCycle" } & CropCycleNameFragment) | null;
  metrics: Array<{ __typename?: "WorkOrderMetric" } & MetricValueFragment>;
};

export type ActivityCostCenterReportFragment = {
  __typename?: "ActivityCostCenter";
  startingDate: any;
  endingDate: any;
  workDays: number;
  progress: number;
  manDays: number;
  laborManDays: number;
  avgWorkers: number;
  laborEfficiency?: number | null;
  machineHours?: number | null;
  machineEfficiency?: number | null;
  laborCost?: number;
  inventoryCost?: number;
  totalCost?: number;
  profitableId?: string | null;
  profitableName?: string | null;
  profitableExtra?: string | null;
  totalCostPerProgress?: number | null;
  estimatedProgress: number;
  metrics: Array<{ __typename?: "WorkOrderMetric" } & MetricValueFragment>;
  costCenter: { __typename?: "CostCenter"; id: string; name: string };
  cropField?:
    | ({ __typename?: "CropField" } & CropFieldWithVarietyShortFragment)
    | null;
};

export type ActivityOverviewReportQueryVariables = Exact<{
  filter?: InputMaybe<ActivityReportFilterInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityOverviewReportQuery = {
  __typename?: "Query";
  activityOverviewReport: {
    __typename?: "ActivityOverview";
  } & ActivityOverviewReportFragment;
};

export type ActivitySummaryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<ActivityReportFilterInput>;
  sort?: InputMaybe<ActivitySummaryReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivitySummaryReportQuery = {
  __typename?: "Query";
  activitySummaryReport?: {
    __typename?: "ActivitySummaryReport";
    totalCount: number;
    items: Array<
      { __typename?: "ActivitySummary" } & ActivitySummaryReportFragment
    >;
  } | null;
};

export type ActivityCostCenterReportQueryVariables = Exact<{
  filter?: InputMaybe<ActivityReportFilterInput>;
  sort?: InputMaybe<ActivityCostCenterReportSortInput>;
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type ActivityCostCenterReportQuery = {
  __typename?: "Query";
  activityCostCenterReport?: {
    __typename?: "ActivityCostCenterReport";
    totalCount: number;
    items: Array<
      { __typename?: "ActivityCostCenter" } & ActivityCostCenterReportFragment
    >;
  } | null;
};

export type BalanceSheetReportFragment = {
  __typename?: "BalanceSheet";
  id: string;
  groupAccount: boolean;
  name: string;
  accountNumber?: string | null;
  balance: number;
  currencyCode: string;
  kind: { __typename?: "AccountKind" } & AccountKindShortFragment;
  parentAccount?: { __typename?: "Account"; id: string } | null;
};

export type BalanceSheetReportQueryVariables = Exact<{
  filter?: InputMaybe<BalanceSheetReportFilterInput>;
  sort?: InputMaybe<BalanceSheetReportSortInput>;
}>;

export type BalanceSheetReportQuery = {
  __typename?: "Query";
  balanceSheetReport?: {
    __typename?: "BalanceSheetReport";
    totalCount: number;
    items: Array<{ __typename?: "BalanceSheet" } & BalanceSheetReportFragment>;
  } | null;
};

export type CropFieldSummaryReportFragment = {
  __typename?: "CropFieldSummary";
  harvestedAmount: number;
  harvestYield: number;
  manDays: number;
  machineHours: number;
  laborCost?: number;
  inventoryCost?: number;
  totalCost?: number;
  hasEstimation: boolean;
  estimatedAmount?: number | null;
  estimatedYield?: number | null;
  cropField: {
    __typename?: "CropField";
    locality: { __typename?: "Locality" } & LocalityShortFragment;
  } & CropFieldWithVarietyShortFragment;
  harvestUnit: { __typename?: "Unit" } & UnitShortFragment;
  metrics: Array<{ __typename?: "WorkOrderMetric" } & MetricValueFragment>;
};

export type CropFieldActivityReportFragment = {
  __typename?: "CropFieldActivity";
  startingDate: any;
  endingDate: any;
  workDays: number;
  cycleNumber?: number | null;
  progress: number;
  manDays: number;
  machineHours: number;
  laborCost?: number;
  inventoryCost?: number;
  totalCost?: number;
  activity: {
    __typename?: "Activity";
    progressUnit: { __typename?: "Unit" } & UnitShortFragment;
  } & ActivityShortFragment;
  metrics: Array<{ __typename?: "WorkOrderMetric" } & MetricValueFragment>;
};

export type CropFieldSummaryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropFieldReportFilterInput>;
  sort?: InputMaybe<CropFieldSummaryReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CropFieldSummaryReportQuery = {
  __typename?: "Query";
  cropFieldSummaryReport?: {
    __typename?: "CropFieldSummaryReport";
    totalCount: number;
    items: Array<
      { __typename?: "CropFieldSummary" } & CropFieldSummaryReportFragment
    >;
  } | null;
};

export type CropFieldActivityReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<CropFieldReportFilterInput>;
  sort?: InputMaybe<CropFieldActivityReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type CropFieldActivityReportQuery = {
  __typename?: "Query";
  cropFieldActivityReport?: {
    __typename?: "CropFieldActivityReport";
    totalCount: number;
    items: Array<
      { __typename?: "CropFieldActivity" } & CropFieldActivityReportFragment
    >;
  } | null;
};

export type DashboardReportFragment = {
  __typename?: "DashboardReport";
  totalExpenses?: number;
  totalManDays: number;
  totalMachineHours: number;
  totalAwaitingInventoryWorkOrders: number;
  activityExpenses: Array<{
    __typename?: "DashboardActivityExpense";
    name: string;
    expenses: number;
    activities?: Array<{
      __typename?: "DashboardActivityExpense";
      name: string;
      expenses: number;
    }> | null;
  }>;
  workOrderStatusSummary: Array<{
    __typename?: "DashboardWorkOrderStatusSummary";
    status: WorkOrderStatus;
    count: number;
  }>;
  workOrderTypeSummary: Array<{
    __typename?: "DashboardWorkOrderTypeSummary";
    workOrderType: WorkOrderType;
    workOrderCategory: WorkOrderCategory;
    count: number;
  }>;
  localitySummary: {
    __typename?: "DashboardLocalitySummary";
    effectiveArea: number;
    cropFieldCount: number;
    plantCount: number;
    workerCount: number;
    machineCount: number;
    infrastructureCount: number;
  };
  cropStageSummary: Array<{
    __typename?: "DashboardCropStageSummary";
    effectiveArea: number;
    effectivePlants: number;
    cropStage: { __typename?: "CropStage" } & CropStageShortFragment;
    cropFields: Array<{
      __typename?: "DashboardCropFieldSummary";
      effectiveArea: number;
      effectivePlants: number;
      cropField: { __typename?: "CropField"; id: string; name: string };
    }>;
  }>;
  cropVarietySummary: Array<{
    __typename?: "DashboardCropVarietySummary";
    effectiveArea: number;
    effectivePlants: number;
    cropVariety: { __typename?: "CropVariety" } & CropVarietyShortFragment;
    cropFields: Array<{
      __typename?: "DashboardCropFieldVarietySummary";
      effectiveArea: number;
      effectivePlants: number;
      cropField: { __typename?: "CropField"; id: string; name: string };
      ages: Array<{
        __typename?: "DashboardCropVarietyAge";
        age?: string | null;
        effectiveArea: number;
        effectivePlants: number;
      }>;
    }>;
  }>;
  expenses: Array<{
    __typename?: "ExpenseRecord";
    id: string;
    subject: string;
    total: number;
    rate: number;
    parentId?: string | null;
  }>;
};

export type DashboardReportQueryVariables = Exact<{
  cropCycleId?: InputMaybe<Scalars["ID"]>;
  localityId?: InputMaybe<Scalars["ID"]>;
  currencyCode?: InputMaybe<Scalars["String"]>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type DashboardReportQuery = {
  __typename?: "Query";
  dashboardReport: { __typename?: "DashboardReport" } & DashboardReportFragment;
};

export type ExpenseRecordFragment = {
  __typename?: "ExpenseRecord";
  id: string;
  subject: string;
  rate: number;
  total: number;
  planned?: number | null;
  parentId?: string | null;
  columns: Array<{
    __typename?: "Column";
    name: string;
    value?: number | null;
    planned?: number | null;
  }>;
};

export type ExpenseReportQueryVariables = Exact<{
  filter?: InputMaybe<ExpenseReportFilterInput>;
}>;

export type ExpenseReportQuery = {
  __typename?: "Query";
  expenseReport?: {
    __typename?: "ExpenseReport";
    totalCount: number;
    items: Array<{ __typename?: "ExpenseRecord" } & ExpenseRecordFragment>;
  } | null;
};

export type ExpenseSalaryReportQueryVariables = Exact<{
  filter?: InputMaybe<ExpenseSalaryReportFilterInput>;
}>;

export type ExpenseSalaryReportQuery = {
  __typename?: "Query";
  expenseSalaryReport?: {
    __typename?: "ExpenseSalaryReport";
    totalCount: number;
    items: Array<{ __typename?: "ExpenseRecord" } & ExpenseRecordFragment>;
  } | null;
};

export type ExpenseInventoryReportQueryVariables = Exact<{
  filter?: InputMaybe<ExpenseInventoryReportFilterInput>;
}>;

export type ExpenseInventoryReportQuery = {
  __typename?: "Query";
  expenseInventoryReport?: {
    __typename?: "ExpenseInventoryReport";
    totalCount: number;
    items: Array<{ __typename?: "ExpenseRecord" } & ExpenseRecordFragment>;
  } | null;
};

export type ExpenseServiceReportQueryVariables = Exact<{
  filter?: InputMaybe<ExpenseServiceReportFilterInput>;
}>;

export type ExpenseServiceReportQuery = {
  __typename?: "Query";
  expenseServiceReport?: {
    __typename?: "ExpenseServiceReport";
    totalCount: number;
    items: Array<{ __typename?: "ExpenseRecord" } & ExpenseRecordFragment>;
  } | null;
};

export type InvoiceSummaryReportItemFragment = {
  __typename?: "InvoiceSummaryReportItem";
  invoicesCount: number;
  totalAmount: number;
  totalAmountPaid: number;
  counterparty: {
    __typename?: "Counterparty";
    id: string;
    name: string;
    internalId?: string | null;
    payableAccount?: ({ __typename?: "Account" } & AccountShortFragment) | null;
    receivableAccount?:
      | ({ __typename?: "Account" } & AccountShortFragment)
      | null;
  };
};

export type InvoiceSummaryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<InvoiceReportFilterInput>;
  sort?: InputMaybe<InvoiceSummaryReportSortInput>;
}>;

export type InvoiceSummaryReportQuery = {
  __typename?: "Query";
  invoiceSummaryReport?: {
    __typename?: "InvoiceSummaryReport";
    totalCount: number;
    items: Array<
      {
        __typename?: "InvoiceSummaryReportItem";
      } & InvoiceSummaryReportItemFragment
    >;
  } | null;
};

export type InvoiceOverviewReportQueryVariables = Exact<{
  filter?: InputMaybe<InvoiceReportFilterInput>;
}>;

export type InvoiceOverviewReportQuery = {
  __typename?: "Query";
  invoiceOverviewReport: {
    __typename?: "InvoiceOverviewReportItem";
    totalAmount: number;
    totalAmountPaid: number;
  };
};

export type GeneralLedgerReportFragment = {
  __typename?: "GeneralLedger";
  id: string;
  name: string;
  accountNumber?: string | null;
  openingBalance: number;
  endingBalance: number;
  credits: number;
  debits: number;
  quantity: number;
  currencyCode: string;
  kind: { __typename?: "AccountKind" } & AccountKindShortFragment;
};

export type GeneralLedgerReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<GeneralLedgerReportFilterInput>;
  sort?: InputMaybe<GeneralLedgerReportSortInput>;
}>;

export type GeneralLedgerReportQuery = {
  __typename?: "Query";
  generalLedgerReport?: {
    __typename?: "GeneralLedgerReport";
    totalCount: number;
    items: Array<
      { __typename?: "GeneralLedger" } & GeneralLedgerReportFragment
    >;
  } | null;
};

export type HarvestSummaryFragment = {
  __typename?: "HarvestSummary";
  harvestedAmount: number;
  harvestUnitAbbr: string;
  estimateUnitAbbr?: string | null;
  harvestYield: number;
  estimateAmount?: number | null;
  estimateYield?: number | null;
  manDays: number;
  laborEfficiency?: number | null;
  machineHours: number;
  machineEfficiency?: number | null;
  frequency: number;
  avgHarvestDays?: number | null;
  lastDate: any;
  daysSinceLast: number;
  cropField: {
    __typename?: "CropField";
    locality: { __typename?: "Locality" } & LocalityShortFragment;
  } & CropFieldWithVarietyShortFragment;
};

export type HarvestCropFieldFragment = {
  __typename?: "HarvestCropField";
  id: string;
  documentDate: any;
  activityName: string;
  cycleNumber?: number | null;
  harvestedAmount: number;
  harvestUnitAbbr: string;
  manDays: number;
  laborEfficiency?: number | null;
  machineHours: number;
  machineEfficiency?: number | null;
  daysBetween?: number | null;
  cropFieldGroup?: string | null;
};

export type HarvestDailyFragment = {
  __typename?: "HarvestDaily";
  id: string;
  documentDate: any;
  cycleNumber?: number | null;
  harvestedAmount: number;
  harvestUnitAbbr: string;
  accumulatedHarvest: number;
  manDays: number;
  accumulatedManDays: number;
  laborEfficiency?: number | null;
  avgLaborEfficiency?: number | null;
  machineHours: number;
  accumulatedMachineHours: number;
  machineEfficiency?: number | null;
  avgMachineEfficiency?: number | null;
  cropFieldGroup?: string | null;
  activity: { __typename?: "Activity" } & ActivityShortFragment;
  cropCycle: { __typename?: "CropCycle" } & CropCycleShortFragment;
};

export type HarvestSummaryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<HarvestReportFilterInput>;
  sort?: InputMaybe<HarvestSummaryReportSortInput>;
}>;

export type HarvestSummaryReportQuery = {
  __typename?: "Query";
  harvestSummaryReport?: {
    __typename?: "HarvestSummaryReport";
    totalCount: number;
    items: Array<{ __typename?: "HarvestSummary" } & HarvestSummaryFragment>;
  } | null;
};

export type HarvestCropFieldReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<HarvestCropFieldReportFilterInput>;
  sort?: InputMaybe<HarvestCropFieldReportSortInput>;
}>;

export type HarvestCropFieldReportQuery = {
  __typename?: "Query";
  harvestCropFieldReport?: {
    __typename?: "HarvestCropFieldReport";
    totalCount: number;
    items: Array<
      { __typename?: "HarvestCropField" } & HarvestCropFieldFragment
    >;
  } | null;
};

export type HarvestDailyReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<HarvestDailyReportFilterInput>;
  sort?: InputMaybe<HarvestDailyReportSortInput>;
}>;

export type HarvestDailyReportQuery = {
  __typename?: "Query";
  harvestDailyReport?: {
    __typename?: "HarvestDailyReport";
    totalCount: number;
    items: Array<{ __typename?: "HarvestDaily" } & HarvestDailyFragment>;
  } | null;
};

export type HarvestOverviewFragment = {
  __typename?: "HarvestOverview";
  estimateAmount?: number | null;
  estimateYield?: number | null;
  harvestedAmount?: number | null;
  harvestYield?: number | null;
  manDays?: number | null;
  laborEfficiency?: number | null;
  harvestUnitAbbr?: string | null;
  areaUnitAbbr?: string | null;
};

export type HarvestOverviewReportQueryVariables = Exact<{
  filter?: InputMaybe<HarvestReportFilterInput>;
}>;

export type HarvestOverviewReportQuery = {
  __typename?: "Query";
  harvestOverviewReport: {
    __typename?: "HarvestOverview";
  } & HarvestOverviewFragment;
};

export type IncomeStatementRecordFragment = {
  __typename?: "IncomeStatementRecord";
  id: string;
  name: string;
  total: number;
  parentId?: string | null;
  columns: Array<{
    __typename?: "Column";
    name: string;
    value?: number | null;
  }>;
};

export type IncomeStatementReportQueryVariables = Exact<{
  filter?: InputMaybe<IncomeStatementReportFilterInput>;
}>;

export type IncomeStatementReportQuery = {
  __typename?: "Query";
  incomeStatementReport?: {
    __typename?: "IncomeStatementReport";
    items: Array<
      { __typename?: "IncomeStatementRecord" } & IncomeStatementRecordFragment
    >;
  } | null;
};

export type InputCostCenterFragment = {
  __typename?: "InputCostCenter";
  profitableId?: string | null;
  profitableName?: string | null;
  consumedAmount: number;
  dosage?: number | null;
  consumedCost?: number | null;
  progress?: number | null;
  costPerProgress?: number | null;
  workOrder: {
    __typename?: "WorkOrder";
    id: string;
    documentDate: any;
    internalId?: string | null;
    cropFieldGroup?: string | null;
    cycleNumber?: number | null;
    cropCycle?: ({ __typename?: "CropCycle" } & CropCycleNameFragment) | null;
    activity: {
      __typename?: "Activity";
      category: { __typename?: "ActivityCategory"; name: string };
      progressUnit: { __typename?: "Unit"; abbr: string };
    } & ActivityNameFragment;
  };
  variant: { __typename?: "Variant" } & VariantShortFragment;
  unit: { __typename?: "Unit" } & UnitShortFragment;
  costCenter: { __typename?: "CostCenter" } & CostCenterShortFragment;
  sourceWarehouse: { __typename?: "Warehouse" } & WarehouseLocalityFragment;
};

export type InputCostCenterReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<InputCostCenterReportFilterInput>;
  sort?: InputMaybe<InputCostCenterReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type InputCostCenterReportQuery = {
  __typename?: "Query";
  inputCostCenterReport?: {
    __typename?: "InputCostCenterReport";
    totalCount: number;
    items: Array<{ __typename?: "InputCostCenter" } & InputCostCenterFragment>;
  } | null;
};

export type InventoryEntryFragment = {
  __typename?: "InventoryEntry";
  id: string;
  locationId: string;
  locationType: LocationType;
  amount: number;
  balance: number;
  documentId: string;
  documentType: InventoryDocumentType;
  documentDate: any;
  documentInternalId?: string | null;
  code: string;
  cost?: number | null;
  runningCost?: number | null;
  documentValue?: number | null;
  totalCost?: number;
  variant: { __typename?: "Variant"; cost: number } & VariantShortFragment;
  warehouse?: ({ __typename?: "Warehouse" } & WarehouseLocalityFragment) | null;
};

export type InventoryEntryCodeTypeQueryVariables = Exact<{
  [key: string]: never;
}>;

export type InventoryEntryCodeTypeQuery = {
  __typename?: "Query";
  __type?: {
    __typename?: "__Type";
    name?: string | null;
    enumValues?: Array<{
      __typename?: "__EnumValue";
      name: string;
      description?: string | null;
    }> | null;
  } | null;
};

export type InventoryEntryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<InventoryEntryReportFilterInput>;
  sort?: InputMaybe<InventoryEntryReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type InventoryEntryReportQuery = {
  __typename?: "Query";
  inventoryEntryReport?: {
    __typename?: "InventoryEntryReport";
    totalCount: number;
    items: Array<{ __typename?: "InventoryEntry" } & InventoryEntryFragment>;
  } | null;
};

export type InventoryMovementFragment = {
  __typename?: "InventoryMovement";
  id: string;
  documentId: string;
  documentType: InventoryDocumentType;
  documentDate: any;
  documentInternalId?: string | null;
  entryCode: string;
  unitCost?: number;
  documentCost?: number;
  variant: { __typename?: "Variant" } & VariantShortFragment;
  locations: Array<
    {
      __typename?: "InventoryMovementLocation";
    } & InventoryMovementLocationFragment
  >;
};

export type InventoryMovementLocationFragment = {
  __typename?: "InventoryMovementLocation";
  amount: number;
  startingStock?: number | null;
  endingStock?: number | null;
  warehouse?: ({ __typename?: "Warehouse" } & WarehouseLocalityFragment) | null;
  counterparty?:
    | ({ __typename?: "Counterparty" } & CounterpartyFragment)
    | null;
};

export type InventoryMovementReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<InventoryMovementReportFilterInput>;
  sort?: InputMaybe<InventoryMovementReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type InventoryMovementReportQuery = {
  __typename?: "Query";
  inventoryMovementReport?: {
    __typename?: "InventoryMovementReport";
    totalCount: number;
    items: Array<
      { __typename?: "InventoryMovement" } & InventoryMovementFragment
    >;
  } | null;
};

export type JournalReportFragment = {
  __typename?: "JournalTransaction";
  documentId: string;
  documentType: FinanceDocumentType;
  documentInternalId?: string | null;
  date: any;
  code: string;
  totalDebit: number;
  totalCredit: number;
  description?: string | null;
  entries: Array<{
    __typename?: "JournalTransactionEntry";
    amount: number;
    account: {
      __typename?: "Account";
      name: string;
      accountNumber?: string | null;
    };
    costCenter?: {
      __typename?: "CostCenter";
      internalId?: string | null;
      name: string;
    } | null;
  }>;
};

export type JournalReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<JournalReportFilterInput>;
  sort?: InputMaybe<JournalReportSortInput>;
}>;

export type JournalReportQuery = {
  __typename?: "Query";
  journalReport?: {
    __typename?: "JournalReport";
    totalCount: number;
    items: Array<{ __typename?: "JournalTransaction" } & JournalReportFragment>;
  } | null;
};

export type LaborSummaryReportFragment = {
  __typename?: "LaborSummary";
  manDays: number;
  absentDays: number;
  absenceRate: number;
  activitiesCount: number;
  totalCost?: number;
  costPerDay?: number;
  employee: { __typename?: "Employee" } & EmployeeShortFragment;
};

export type LaborActivityReportFragment = {
  __typename?: "LaborActivity";
  hours: number;
  overtimeHours: number;
  manDays: number;
  absentDays: number;
  progress: number;
  efficiency?: number | null;
  totalCost?: number;
  efficiencyCost?: number | null;
  employee: { __typename?: "Employee" } & EmployeeShortFragment;
  activity: {
    __typename?: "Activity";
    progressUnit: { __typename?: "Unit" } & UnitShortFragment;
  } & ActivityShortFragment;
};

export type LaborSummaryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<LaborReportFilterInput>;
  sort?: InputMaybe<LaborSummaryReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type LaborSummaryReportQuery = {
  __typename?: "Query";
  laborSummaryReport?: {
    __typename?: "LaborSummaryReport";
    totalCount: number;
    items: Array<{ __typename?: "LaborSummary" } & LaborSummaryReportFragment>;
  } | null;
};

export type LaborActivityReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<LaborReportFilterInput>;
  sort?: InputMaybe<LaborActivityReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type LaborActivityReportQuery = {
  __typename?: "Query";
  laborActivityReport?: {
    __typename?: "LaborActivityReport";
    totalCount: number;
    items: Array<
      { __typename?: "LaborActivity" } & LaborActivityReportFragment
    >;
  } | null;
};

export type LedgerEntryReportFragment = {
  __typename?: "LedgerEntry";
  id: string;
  code: string;
  currencyCode: string;
  documentDate: any;
  documentId: string;
  documentType: FinanceDocumentType;
  openingBalance: number;
  debit?: number | null;
  credit?: number | null;
  balance: number;
  description?: string | null;
  account: { __typename?: "Account" } & AccountShortFragment;
};

export type LedgerReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<LedgerReportFilterInput>;
  sort?: InputMaybe<LedgerReportSortInput>;
}>;

export type LedgerReportQuery = {
  __typename?: "Query";
  ledgerReport?: {
    __typename?: "LedgerReport";
    totalCount: number;
    items: Array<{ __typename?: "LedgerEntry" } & LedgerEntryReportFragment>;
  } | null;
};

export type MachineSummaryReportFragment = {
  __typename?: "MachineSummary";
  machineHours: number;
  workDays: number;
  machine: {
    __typename?: "Machine";
    machineLocalities: Array<{
      __typename?: "MachineLocality";
      locality: { __typename?: "Locality" } & LocalityNameFragment;
    }>;
  } & MachineShortFragment;
};

export type MachineActivityReportFragment = {
  __typename?: "MachineActivity";
  startingDate: any;
  endingDate: any;
  workDays: number;
  progress: number;
  machineHours: number;
  machineEfficiency?: number | null;
  activity: {
    __typename?: "Activity";
    progressUnit: { __typename?: "Unit" } & UnitShortFragment;
  } & ActivityShortFragment;
};

export type MachineSummaryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<MachineReportFilterInput>;
  sort?: InputMaybe<MachineSummaryReportSortInput>;
}>;

export type MachineSummaryReportQuery = {
  __typename?: "Query";
  machineSummaryReport?: {
    __typename?: "MachineSummaryReport";
    totalCount: number;
    items: Array<
      { __typename?: "MachineSummary" } & MachineSummaryReportFragment
    >;
  } | null;
};

export type MachineActivityReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<MachineReportFilterInput>;
  sort?: InputMaybe<MachineActivityReportSortInput>;
}>;

export type MachineActivityReportQuery = {
  __typename?: "Query";
  machineActivityReport?: {
    __typename?: "MachineActivityReport";
    totalCount: number;
    items: Array<
      { __typename?: "MachineActivity" } & MachineActivityReportFragment
    >;
  } | null;
};

export type PrePayrollOverviewRecordFragment = {
  __typename?: "PrePayrollOverviewRecord";
  businessDays: number;
  startDate: any;
  endDate: any;
  manDays: number;
  attendedDays: number;
  absentDays: number;
  basePay?: number;
  overtimePay?: number;
  absencePay?: number;
};

export type PrePayrollSummaryRecordFragment = {
  __typename?: "PrePayrollSummaryRecord";
  manDays: number;
  attendedDays: number;
  absentDays: number;
  seventhDays: number;
  hours: number;
  overtimeHours: number;
  progress?: number | null;
  basePay?: number;
  overtimePay?: number;
  absencePay?: number;
  seventhDayPay?: number;
  totalPay?: number;
  employee: { __typename?: "Employee" } & EmployeeShortFragment;
  progressUnit?: ({ __typename?: "Unit" } & UnitShortFragment) | null;
};

export type PrePayrollRecordFragment = {
  __typename?: "PrePayrollRecord";
  hours: number;
  overtimeHours: number;
  progress?: number | null;
  attended: boolean;
  wage?: number | null;
  wageUnit?: WorkUnit | null;
  basePay?: number;
  overtimePay?: number;
  absencePay?: number;
  totalPay?: number;
  workOrder?: {
    __typename?: "WorkOrder";
    id: string;
    documentDate: any;
    activity: {
      __typename?: "Activity";
      internalId?: string | null;
      progressUnit: { __typename?: "Unit" } & UnitShortFragment;
    } & ActivityNameFragment;
  } | null;
  leaveAssignment?: {
    __typename?: "LeaveAssignment";
    id: string;
    documentDate: any;
  } | null;
  employee: {
    __typename?: "Employee";
    id: string;
    internalId?: string | null;
    fullName: string;
    position: { __typename?: "Position"; name: string };
  };
  costCenter?: {
    __typename?: "WorkOrderCostCenter";
    id: string;
    profitableName?: string | null;
    costCenter: { __typename?: "CostCenter"; name: string };
  } | null;
  leaveType?: {
    __typename?: "LeaveType";
    name: string;
    internalId?: string | null;
  } | null;
};

export type PrePayrollOverviewReportQueryVariables = Exact<{
  filter?: InputMaybe<PrePayrollReportFilterInput>;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PrePayrollOverviewReportQuery = {
  __typename?: "Query";
  prePayrollOverviewReport: {
    __typename?: "PrePayrollOverviewRecord";
  } & PrePayrollOverviewRecordFragment;
};

export type PrePayrollSummaryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<PrePayrollReportFilterInput>;
  sort?: InputMaybe<PrePayrollSummaryReportSortInput>;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PrePayrollSummaryReportQuery = {
  __typename?: "Query";
  prePayrollSummaryReport?: {
    __typename?: "PrePayrollSummaryReport";
    totalCount: number;
    items: Array<
      {
        __typename?: "PrePayrollSummaryRecord";
      } & PrePayrollSummaryRecordFragment
    >;
  } | null;
};

export type PrePayrollReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<PrePayrollReportFilterInput>;
  sort?: InputMaybe<PrePayrollReportSortInput>;
  showWage?: InputMaybe<Scalars["Boolean"]>;
}>;

export type PrePayrollReportQuery = {
  __typename?: "Query";
  prePayrollReport?: {
    __typename?: "PrePayrollReport";
    totalCount: number;
    items: Array<
      { __typename?: "PrePayrollRecord" } & PrePayrollRecordFragment
    >;
  } | null;
};

export type StockReportRecordFragment = {
  __typename?: "StockReportRecord";
  stockId: string;
  variantId: string;
  name: string;
  internalId?: string | null;
  ingredients?: string | null;
  variationValue: number;
  total: number;
  onHand: number;
  reserved: number;
  missed: number;
  unitCost?: number;
  totalCost?: number;
  item: { __typename?: "Item" } & ItemShortFragment;
  warehouse: {
    __typename?: "Warehouse";
    discardedAt?: any | null;
  } & WarehouseLocalityFragment;
  variationUnit: { __typename?: "Unit" } & UnitShortFragment;
};

export type StockReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<StockReportFilterInput>;
  sort?: InputMaybe<StockReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type StockReportQuery = {
  __typename?: "Query";
  stockReport?: {
    __typename?: "StockReport";
    totalCount: number;
    items: Array<
      { __typename?: "StockReportRecord" } & StockReportRecordFragment
    >;
  } | null;
};

export type TokenSummaryFragment = {
  __typename?: "TokenSummary";
  quantity: number;
  startingStock: number;
  endingStock: number;
  paidTokens: number;
  harvestedAmount: number;
  unitAbbr: string;
  redeemedTokens: number;
  employeeTokens: number;
  missingTokens: number;
  unitCost?: number;
  value?: number;
  circulationValue?: number;
  variant: { __typename?: "Variant" } & VariantShortFragment;
};

export type TokenPaidFragment = {
  __typename?: "TokenPaid";
  id: string;
  documentDate: any;
  activityName: string;
  cycleNumber?: number | null;
  harvestedAmount: number;
  unitAbbr: string;
  unitCost?: number;
  value?: number;
  denominations: Array<{
    __typename?: "TokenDenomination";
    denomination: number;
    amount: number;
  }>;
};

export type TokenRedeemedFragment = {
  __typename?: "TokenRedeemed";
  id: string;
  documentDate: any;
  unitAbbr?: string | null;
  denominations: Array<{
    __typename?: "TokenDenomination";
    denomination: number;
    amount: number;
  }>;
};

export type TokenSummaryReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TokenSummaryReportFilterInput>;
  sort?: InputMaybe<TokenSummaryReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TokenSummaryReportQuery = {
  __typename?: "Query";
  tokenSummaryReport?: {
    __typename?: "TokenSummaryReport";
    totalCount: number;
    items: Array<{ __typename?: "TokenSummary" } & TokenSummaryFragment>;
  } | null;
};

export type TokenPaidReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TokenPaidReportFilterInput>;
  sort?: InputMaybe<TokenPaidReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type TokenPaidReportQuery = {
  __typename?: "Query";
  tokenPaidReport?: {
    __typename?: "TokenPaidReport";
    totalCount: number;
    items: Array<{ __typename?: "TokenPaid" } & TokenPaidFragment>;
  } | null;
};

export type TokenRedeemedReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TokenRedeemedReportFilterInput>;
  sort?: InputMaybe<TokenRedeemedReportSortInput>;
}>;

export type TokenRedeemedReportQuery = {
  __typename?: "Query";
  tokenRedeemedReport?: {
    __typename?: "TokenRedeemedReport";
    totalCount: number;
    items: Array<{ __typename?: "TokenRedeemed" } & TokenRedeemedFragment>;
  } | null;
};

export type TrialBalanceReportFragment = {
  __typename?: "TrialBalance";
  id: string;
  accountNumber?: string | null;
  name: string;
  credit?: number | null;
  debit?: number | null;
  currencyCode: string;
  kind: { __typename?: "AccountKind" } & AccountKindShortFragment;
};

export type TrialBalanceReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<TrialBalanceReportFilterInput>;
  sort?: InputMaybe<TrialBalanceReportSortInput>;
}>;

export type TrialBalanceReportQuery = {
  __typename?: "Query";
  trialBalanceReport?: {
    __typename?: "TrialBalanceReport";
    totalCount: number;
    items: Array<{ __typename?: "TrialBalance" } & TrialBalanceReportFragment>;
  } | null;
};

export type WorkOrderDailyFragment = {
  __typename?: "WorkOrderDaily";
  id: string;
  documentDate: any;
  internalId?: string | null;
  cycleNumber?: number | null;
  weekNumber?: number | null;
  cropFieldGroup?: string | null;
  progress: number;
  laborQty: number;
  hours: number;
  overtimeHours: number;
  laborEfficiency?: number | null;
  machineQty?: number | null;
  machineHours?: number | null;
  machineEfficiency?: number | null;
  laborCost?: number;
  inventoryCost?: number;
  totalCost?: number;
  locality: { __typename?: "Locality" } & LocalityNameFragment;
  cropCycle?: ({ __typename?: "CropCycle" } & CropCycleNameFragment) | null;
  costCenter?: {
    __typename?: "WorkOrderCostCenter";
    id: string;
    profitableName?: string | null;
    costCenter: { __typename?: "CostCenter"; name: string };
  } | null;
  activity: {
    __typename?: "Activity";
    internalId?: string | null;
    laborForceType: ActivityLaborForceType;
    progressUnit: { __typename?: "Unit"; abbr: string };
    category: { __typename?: "ActivityCategory"; name: string };
  } & ActivityNameFragment;
  metrics: Array<{ __typename?: "WorkOrderMetric" } & MetricValueFragment>;
};

export type WorkOrderDailyReportQueryVariables = Exact<{
  page?: InputMaybe<Scalars["Int"]>;
  pageSize?: InputMaybe<Scalars["Int"]>;
  filter?: InputMaybe<WorkOrderDailyReportFilterInput>;
  sort?: InputMaybe<WorkOrderDailyReportSortInput>;
  showCost?: InputMaybe<Scalars["Boolean"]>;
}>;

export type WorkOrderDailyReportQuery = {
  __typename?: "Query";
  workOrderDailyReport?: {
    __typename?: "WorkOrderDailyReport";
    totalCount: number;
    items: Array<{ __typename?: "WorkOrderDaily" } & WorkOrderDailyFragment>;
  } | null;
};

export const ActivityNameFragmentDoc = gql`
  fragment ActivityName on Activity {
    id
    name
  }
`;
export const ActivityShortFragmentDoc = gql`
  fragment ActivityShort on Activity {
    ...ActivityName
    category {
      id
      name
    }
    workOrderType
    workOrderCategory
  }
  ${ActivityNameFragmentDoc}
`;
export const UnitShortFragmentDoc = gql`
  fragment UnitShort on Unit {
    id
    name
    abbr
    abbrEn
    unitType
  }
`;
export const MetricShortFragmentDoc = gql`
  fragment MetricShort on Metric {
    id
    name
    unit {
      ...UnitShort
    }
    description
  }
  ${UnitShortFragmentDoc}
`;
export const MetricFragmentDoc = gql`
  fragment Metric on Metric {
    ...MetricShort
    nameEn
    nameEs
    description
    descriptionEn
    descriptionEs
    abbr
    discardedAt
    mayDiscard
    mayDestroy
  }
  ${MetricShortFragmentDoc}
`;
export const ActivityMetricFragmentDoc = gql`
  fragment ActivityMetric on ActivityMetric {
    id
    index
    metric {
      ...Metric
    }
    scope
  }
  ${MetricFragmentDoc}
`;
export const ActivityFragmentDoc = gql`
  fragment Activity on Activity {
    ...ActivityShort
    internalId
    description
    workdayHours
    progressUnit {
      ...UnitShort
      conversionFactor
    }
    activityMetrics {
      ...ActivityMetric
    }
    progressMeasureType
    laborForceType
    trackCycles
    trackTools
    trackTime
    startTime
    useTotalArea
    discardedAt
  }
  ${ActivityShortFragmentDoc}
  ${UnitShortFragmentDoc}
  ${ActivityMetricFragmentDoc}
`;
export const LocalityNameFragmentDoc = gql`
  fragment LocalityName on Locality {
    id
    name
  }
`;
export const CropShortFragmentDoc = gql`
  fragment CropShort on Crop {
    id
    name
  }
`;
export const ActivityPaymentFragmentDoc = gql`
  fragment ActivityPayment on ActivityPayment {
    id
    position {
      id
      name
      positionType
    }
    locality {
      ...LocalityName
    }
    crop {
      id
      name
    }
    wage @include(if: $showWage)
    wageUnit
    discardedAt
  }
  ${LocalityNameFragmentDoc}
`;
export const ActivityDetailsFragmentDoc = gql`
  fragment ActivityDetails on Activity {
    ...Activity
    nameEn
    nameEs
    namePt
    nameRu
    progressUnitLocked
    localities {
      ...LocalityName
    }
    crops {
      ...CropShort
    }
    payments {
      ...ActivityPayment
    }
  }
  ${ActivityFragmentDoc}
  ${LocalityNameFragmentDoc}
  ${CropShortFragmentDoc}
  ${ActivityPaymentFragmentDoc}
`;
export const ActivityCategoryShortFragmentDoc = gql`
  fragment ActivityCategoryShort on ActivityCategory {
    id
    name
  }
`;
export const ActivityCategoryFragmentDoc = gql`
  fragment ActivityCategory on ActivityCategory {
    id
    name
    nameEn
    nameEs
    namePt
    nameRu
    global
    locked
    tags
    discardedAt
    internalId
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
`;
export const CropCycleNameFragmentDoc = gql`
  fragment CropCycleName on CropCycle {
    id
    name
  }
`;
export const CropCycleShortFragmentDoc = gql`
  fragment CropCycleShort on CropCycle {
    ...CropCycleName
    crops {
      id
      name
    }
    startedAt
    estimatedEndDate
    closedAt
    discardedAt
  }
  ${CropCycleNameFragmentDoc}
`;
export const CropCycleFragmentDoc = gql`
  fragment CropCycle on CropCycle {
    ...CropCycleShort
    locality {
      ...LocalityName
    }
    estimatedEndDate
    mayDiscard
    mayDestroy
  }
  ${CropCycleShortFragmentDoc}
  ${LocalityNameFragmentDoc}
`;
export const ActiveDocumentFragmentDoc = gql`
  fragment ActiveDocument on FinanceDocumentReference {
    documentId
    documentType
    documentDate
    status
  }
`;
export const CropFieldShortFragmentDoc = gql`
  fragment CropFieldShort on CropField {
    id
    name
    group
    effectiveArea
    effectivePlants
    totalArea
    cropStage {
      id
      name
      kind
    }
    tags
  }
`;
export const CropStageShortFragmentDoc = gql`
  fragment CropStageShort on CropStage {
    id
    name
    kind
  }
`;
export const CropStageChangeFragmentDoc = gql`
  fragment CropStageChange on CropStageChange {
    cropField {
      ...CropFieldShort
    }
    nextStage {
      ...CropStageShort
    }
  }
  ${CropFieldShortFragmentDoc}
  ${CropStageShortFragmentDoc}
`;
export const UserShortFragmentDoc = gql`
  fragment UserShort on User {
    id
    username
    email
    firstName
    lastName
  }
`;
export const DiseaseRiskShortFragmentDoc = gql`
  fragment DiseaseRiskShort on DiseaseRisk {
    id
    low
    high
    lowRecommendation
    mediumRecommendation
    highRecommendation
  }
`;
export const DiseaseShortFragmentDoc = gql`
  fragment DiseaseShort on Disease {
    id
    name
    kind
    thumbnail
    riskMap {
      ...DiseaseRiskShort
    }
  }
  ${DiseaseRiskShortFragmentDoc}
`;
export const SampleDiseaseFragmentDoc = gql`
  fragment SampleDisease on SampleDisease {
    id
    disease {
      ...DiseaseShort
    }
    source
    section
    size
    found
    thumbnail
    images
    discardedAt
  }
  ${DiseaseShortFragmentDoc}
`;
export const CropFieldNoteFragmentDoc = gql`
  fragment CropFieldNote on CropFieldNote {
    id
    documentDate
    kind
    latitude
    longitude
    message
    thumbnail
    images
    discardedAt
    createdBy {
      ...UserShort
    }
    sampleDiseases {
      ...SampleDisease
    }
    cropField {
      ...CropFieldShort
      geoJson
    }
  }
  ${UserShortFragmentDoc}
  ${SampleDiseaseFragmentDoc}
  ${CropFieldShortFragmentDoc}
`;
export const CropVarietyShortFragmentDoc = gql`
  fragment CropVarietyShort on CropVariety {
    id
    name
  }
`;
export const CropFieldVarietyFragmentDoc = gql`
  fragment CropFieldVariety on CropFieldVariety {
    id
    cropVariety {
      ...CropVarietyShort
    }
    plantedAt
    plantDensity
    effectivePlants
    effectiveArea
    discardedAt
  }
  ${CropVarietyShortFragmentDoc}
`;
export const LocalityShortFragmentDoc = gql`
  fragment LocalityShort on Locality {
    ...LocalityName
    areaUnit {
      ...UnitShort
      conversionFactor
    }
  }
  ${LocalityNameFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const CostCenterShortFragmentDoc = gql`
  fragment CostCenterShort on CostCenter {
    id
    name
    internalId
    parentCostCenter {
      id
      name
    }
  }
`;
export const CropFieldFragmentDoc = gql`
  fragment CropField on CropField {
    id
    internalId
    name
    crop {
      id
      name
    }
    cropFieldVarieties {
      ...CropFieldVariety
    }
    locality {
      ...LocalityShort
    }
    cropStage {
      ...CropStageShort
    }
    costCenter {
      ...CostCenterShort
    }
    group
    tags
    totalArea
    plantDensity
    effectivePlants
    estimatedPlants
    effectiveArea
    discardedAt
    geoJson
  }
  ${CropFieldVarietyFragmentDoc}
  ${LocalityShortFragmentDoc}
  ${CropStageShortFragmentDoc}
  ${CostCenterShortFragmentDoc}
`;
export const CropFieldDetailsFragmentDoc = gql`
  fragment CropFieldDetails on CropField {
    ...CropField
    locality {
      ...LocalityShort
      geoJson
    }
    altitude
    irrigated
  }
  ${CropFieldFragmentDoc}
  ${LocalityShortFragmentDoc}
`;
export const CropFieldProgressFragmentDoc = gql`
  fragment CropFieldProgress on CropField {
    ...CropField
    actualProgress(progressFilter: $progressFilter)
    plannedProgress(progressFilter: $progressFilter)
    estimatedHarvestAmount(estimationInput: $estimationInput)
  }
  ${CropFieldFragmentDoc}
`;
export const CropStageFragmentDoc = gql`
  fragment CropStage on CropStage {
    id
    name
    nameEn
    nameEs
    namePt
    nameRu
    kind
    prevStage {
      id
      name
    }
    changeEvent
    changeDate
    discardedAt
    costCenter {
      ...CostCenterShort
    }
    cropStageLocalities {
      id
      locality {
        ...LocalityName
      }
      costCenter {
        ...CostCenterShort
      }
    }
    crops {
      ...CropShort
    }
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
  ${CostCenterShortFragmentDoc}
  ${LocalityNameFragmentDoc}
  ${CropShortFragmentDoc}
`;
export const CropVarietyFragmentDoc = gql`
  fragment CropVariety on CropVariety {
    id
    name
    scientificName
    crop {
      id
      name
    }
    discardedAt
  }
`;
export const CropFragmentDoc = gql`
  fragment Crop on Crop {
    id
    name
    commonNames
    locked
    global
    nameEn
    nameEs
    namePt
    nameRu
    commonNamesEn
    commonNamesEs
    commonNamesPt
    commonNamesRu
    discardedAt
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
`;
export const DiseaseRiskFragmentDoc = gql`
  fragment DiseaseRisk on DiseaseRisk {
    id
    low
    high
    lowRecommendation
    lowRecommendationEn
    lowRecommendationEs
    mediumRecommendation
    mediumRecommendationEn
    mediumRecommendationEs
    highRecommendation
    highRecommendationEn
    highRecommendationEs
  }
`;
export const DiseaseFragmentDoc = gql`
  fragment Disease on Disease {
    id
    name
    scientificName
    description
    locked
    global
    thumbnail
    images
    crops {
      ...CropShort
    }
    riskMap {
      ...DiseaseRisk
    }
    kind
    defaultSource
    nameEn
    nameEs
    namePt
    nameRu
    descriptionEn
    descriptionEs
    descriptionPt
    descriptionRu
    discardedAt
    mayDestroy
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
  ${CropShortFragmentDoc}
  ${DiseaseRiskFragmentDoc}
`;
export const HarvestEstimateFragmentDoc = gql`
  fragment HarvestEstimate on HarvestEstimate {
    id
    cropCycle {
      ...CropCycleShort
      crops {
        name
      }
    }
    estimateUnit {
      ...UnitShort
    }
    createdAt
    updatedAt
    createdBy {
      ...UserShort
    }
    updatedBy {
      ...UserShort
    }
  }
  ${CropCycleShortFragmentDoc}
  ${UnitShortFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const CropFieldWithVarietyShortFragmentDoc = gql`
  fragment CropFieldWithVarietyShort on CropField {
    ...CropFieldShort
    crop {
      id
      name
    }
    cropFieldVarieties {
      ...CropFieldVariety
    }
  }
  ${CropFieldShortFragmentDoc}
  ${CropFieldVarietyFragmentDoc}
`;
export const CropFieldHarvestEstimateFragmentDoc = gql`
  fragment CropFieldHarvestEstimate on CropFieldHarvestEstimate {
    id
    cropField {
      ...CropFieldWithVarietyShort
    }
    effectiveArea
    amount
  }
  ${CropFieldWithVarietyShortFragmentDoc}
`;
export const HarvestEstimateDetailsFragmentDoc = gql`
  fragment HarvestEstimateDetails on HarvestEstimate {
    ...HarvestEstimate
    conversionFactor
    updatedAt
    harvestUnit {
      ...UnitShort
    }
    cropFieldEstimates {
      ...CropFieldHarvestEstimate
    }
  }
  ${HarvestEstimateFragmentDoc}
  ${UnitShortFragmentDoc}
  ${CropFieldHarvestEstimateFragmentDoc}
`;
export const IngredientFragmentDoc = gql`
  fragment Ingredient on Ingredient {
    id
    name
    commonNames
    formula
    itemCategories {
      id
      name
    }
    nameEn
    nameEs
    namePt
    nameRu
    commonNamesEn
    commonNamesEs
    commonNamesPt
    commonNamesRu
    discardedAt
  }
`;
export const WorkOrderShortFragmentDoc = gql`
  fragment WorkOrderShort on WorkOrder {
    id
    internalId
    cycleNumber
    cropFieldGroup
    documentDate
    status
    inventoryStatus
    activity {
      ...ActivityName
    }
    locality {
      ...LocalityName
    }
    cropCycle {
      ...CropCycleName
    }
    weekNumber
    createdAt
    updatedAt
    createdBy {
      ...UserShort
    }
    updatedBy {
      ...UserShort
    }
    assignedTo {
      ...UserShort
    }
  }
  ${ActivityNameFragmentDoc}
  ${LocalityNameFragmentDoc}
  ${CropCycleNameFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const WorkOrderFragmentDoc = gql`
  fragment WorkOrder on WorkOrder {
    ...WorkOrderShort
    activity {
      ...ActivityShort
      trackCycles
      trackTools
      trackTime
      startTime
      useTotalArea
    }
    locality {
      ...LocalityShort
    }
    cropCycle {
      ...CropCycleShort
      crops {
        id
      }
    }
  }
  ${WorkOrderShortFragmentDoc}
  ${ActivityShortFragmentDoc}
  ${LocalityShortFragmentDoc}
  ${CropCycleShortFragmentDoc}
`;
export const InventoryRequestFragmentDoc = gql`
  fragment InventoryRequest on InventoryRequest {
    id
    workOrder {
      ...WorkOrder
    }
    createdBy {
      ...UserShort
    }
    updatedAt
  }
  ${WorkOrderFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const UnitShortConversionFragmentDoc = gql`
  fragment UnitShortConversion on Unit {
    ...UnitShort
    conversionUnit {
      ...UnitShort
    }
    conversionFactor
  }
  ${UnitShortFragmentDoc}
`;
export const IngredientShortFragmentDoc = gql`
  fragment IngredientShort on Ingredient {
    id
    name
  }
`;
export const ItemIngredientFragmentDoc = gql`
  fragment ItemIngredient on ItemIngredient {
    id
    rate
    ingredient {
      ...IngredientShort
    }
  }
  ${IngredientShortFragmentDoc}
`;
export const ItemCropVarietyFragmentDoc = gql`
  fragment ItemCropVariety on ItemCropVariety {
    id
    cropVariety {
      ...CropVarietyShort
    }
  }
  ${CropVarietyShortFragmentDoc}
`;
export const ItemShortFragmentDoc = gql`
  fragment ItemShort on Item {
    id
    name
    kind
    itemCategory {
      id
      name
      parentCategory {
        id
        name
      }
    }
    itemIngredients {
      ...ItemIngredient
    }
    itemCropVarieties {
      ...ItemCropVariety
    }
  }
  ${ItemIngredientFragmentDoc}
  ${ItemCropVarietyFragmentDoc}
`;
export const VariantShortFragmentDoc = gql`
  fragment VariantShort on Variant {
    id
    name
    internalId
    variationUnit {
      ...UnitShortConversion
    }
    variationValue
    item {
      ...ItemShort
    }
  }
  ${UnitShortConversionFragmentDoc}
  ${ItemShortFragmentDoc}
`;
export const WarehouseShortFragmentDoc = gql`
  fragment WarehouseShort on Warehouse {
    id
    name
  }
`;
export const WarehouseLocalityFragmentDoc = gql`
  fragment WarehouseLocality on Warehouse {
    ...WarehouseShort
    locality {
      ...LocalityName
    }
  }
  ${WarehouseShortFragmentDoc}
  ${LocalityNameFragmentDoc}
`;
export const WorkOrderVariantFragmentDoc = gql`
  fragment WorkOrderVariant on WorkOrderVariant {
    id
    variant {
      ...VariantShort
    }
    status
    totalAmount
    returnedAmount
    sourceWarehouse {
      ...WarehouseLocality
    }
    returnWarehouse {
      ...WarehouseLocality
    }
    unit {
      ...UnitShortConversion
    }
    dosage
    applicationUnit {
      ...UnitShortConversion
    }
    conversionFactor
  }
  ${VariantShortFragmentDoc}
  ${WarehouseLocalityFragmentDoc}
  ${UnitShortConversionFragmentDoc}
`;
export const InventoryRequestVariantFragmentDoc = gql`
  fragment InventoryRequestVariant on WorkOrderVariant {
    ...WorkOrderVariant
    confirmedReturnedAmount
    stock {
      onHand
    }
  }
  ${WorkOrderVariantFragmentDoc}
`;
export const InventoryRequestDetailsFragmentDoc = gql`
  fragment InventoryRequestDetails on InventoryRequest {
    ...InventoryRequest
    variants {
      ...InventoryRequestVariant
    }
  }
  ${InventoryRequestFragmentDoc}
  ${InventoryRequestVariantFragmentDoc}
`;
export const PlanningFragmentDoc = gql`
  fragment Planning on Planning {
    id
    activity {
      ...ActivityName
    }
    locality {
      ...LocalityName
    }
    cropCycle {
      ...CropCycleName
    }
    cropStages {
      ...CropStageShort
    }
    startDate
    endDate
    startWeek
    endWeek
    cycleNumber
    cropFieldGroup
    status
    actualProgress
    createdBy {
      ...UserShort
    }
  }
  ${ActivityNameFragmentDoc}
  ${LocalityNameFragmentDoc}
  ${CropCycleNameFragmentDoc}
  ${CropStageShortFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const PositionShortFragmentDoc = gql`
  fragment PositionShort on Position {
    id
    name
    positionType
  }
`;
export const PlanningPositionFragmentDoc = gql`
  fragment PlanningPosition on PlanningPosition {
    id
    position {
      ...PositionShort
    }
    quantity
    wage @include(if: $showWage)
    wageUnit
    manDays
    actualCost
    actualManDays
    actualEfficiency
    avgActualCount
  }
  ${PositionShortFragmentDoc}
`;
export const PlanningCostCenterPositionFragmentDoc = gql`
  fragment PlanningCostCenterPosition on PlanningCostCenterPosition {
    id
    positionId
    progressNorm
    manDays
    actualCost
  }
`;
export const PlanningCostCenterVariantFragmentDoc = gql`
  fragment PlanningCostCenterVariant on PlanningCostCenterVariant {
    id
    variantId
    dosage
    totalAmount
    actualDosage
    actualCost
    consumedAmount
  }
`;
export const PlanningCostCenterMachineFragmentDoc = gql`
  fragment PlanningCostCenterMachine on PlanningCostCenterMachine {
    id
    machineKindId
    progressNorm
  }
`;
export const PlanningCostCenterExpenseItemFragmentDoc = gql`
  fragment PlanningCostCenterExpenseItem on PlanningCostCenterExpenseItem {
    id
    expenseItemId
    cost
  }
`;
export const PlanningCostCenterFragmentDoc = gql`
  fragment PlanningCostCenter on PlanningCostCenter {
    id
    costCenter {
      ...CostCenterShort
      discardedAt
    }
    cropField {
      ...CropField
    }
    profitableId
    profitableType
    profitableName
    positions {
      ...PlanningCostCenterPosition
    }
    variants {
      ...PlanningCostCenterVariant
    }
    machines {
      ...PlanningCostCenterMachine
    }
    expenseItems {
      ...PlanningCostCenterExpenseItem
    }
    goal
    index
    startDate
    endDate
    distributionRate
    actualProgress
    actualWorkDays
    actualManDays
    actualMachineHours
    actualLaborCost
    actualInputCost
  }
  ${CostCenterShortFragmentDoc}
  ${CropFieldFragmentDoc}
  ${PlanningCostCenterPositionFragmentDoc}
  ${PlanningCostCenterVariantFragmentDoc}
  ${PlanningCostCenterMachineFragmentDoc}
  ${PlanningCostCenterExpenseItemFragmentDoc}
`;
export const PlanningVariantFragmentDoc = gql`
  fragment PlanningVariant on PlanningVariant {
    id
    variant {
      ...VariantShort
      stock {
        onHand
      }
    }
    unit {
      ...UnitShort
      conversionFactor
    }
    cost
    totalAmount
    actualCost
    consumedAmount
  }
  ${VariantShortFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const MachineKindShortFragmentDoc = gql`
  fragment MachineKindShort on MachineKind {
    id
    name
    category
  }
`;
export const PlanningMachineFragmentDoc = gql`
  fragment PlanningMachine on PlanningMachine {
    id
    machineKind {
      ...MachineKindShort
    }
    quantity
    actualHours
    avgActualCount
    actualEfficiency
  }
  ${MachineKindShortFragmentDoc}
`;
export const ExpenseCategoryShortFragmentDoc = gql`
  fragment ExpenseCategoryShort on ExpenseCategory {
    id
    name
    parentCategory {
      id
      name
    }
  }
`;
export const AccountKindShortFragmentDoc = gql`
  fragment AccountKindShort on AccountKind {
    id
    name
    key
    parentKind {
      id
      name
      key
      parentKind {
        id
        name
        key
      }
    }
  }
`;
export const AccountShortFragmentDoc = gql`
  fragment AccountShort on Account {
    id
    kind {
      ...AccountKindShort
    }
    groupAccount
    name
    accountNumber
  }
  ${AccountKindShortFragmentDoc}
`;
export const ExpenseItemShortFragmentDoc = gql`
  fragment ExpenseItemShort on ExpenseItem {
    id
    name
    expenseCategory {
      ...ExpenseCategoryShort
      expenseAccount {
        ...AccountShort
      }
    }
  }
  ${ExpenseCategoryShortFragmentDoc}
  ${AccountShortFragmentDoc}
`;
export const PlanningExpenseItemFragmentDoc = gql`
  fragment PlanningExpenseItem on PlanningExpenseItem {
    id
    expenseItem {
      ...ExpenseItemShort
    }
  }
  ${ExpenseItemShortFragmentDoc}
`;
export const PlanningDetailsFragmentDoc = gql`
  fragment PlanningDetails on Planning {
    ...Planning
    activity {
      ...Activity
    }
    cropCycle {
      ...CropCycleShort
    }
    locality {
      ...LocalityShort
    }
    positions {
      ...PlanningPosition
    }
    costCenters {
      ...PlanningCostCenter
    }
    inputs {
      ...PlanningVariant
    }
    machines {
      ...PlanningMachine
    }
    expenseItems {
      ...PlanningExpenseItem
    }
    createdAt
    updatedAt
    updatedBy {
      ...UserShort
    }
    actualWorkDays
    workOrders {
      ...WorkOrderShort
      totalProgress
    }
  }
  ${PlanningFragmentDoc}
  ${ActivityFragmentDoc}
  ${CropCycleShortFragmentDoc}
  ${LocalityShortFragmentDoc}
  ${PlanningPositionFragmentDoc}
  ${PlanningCostCenterFragmentDoc}
  ${PlanningVariantFragmentDoc}
  ${PlanningMachineFragmentDoc}
  ${PlanningExpenseItemFragmentDoc}
  ${UserShortFragmentDoc}
  ${WorkOrderShortFragmentDoc}
`;
export const WeatherMeasurementFragmentDoc = gql`
  fragment WeatherMeasurement on WeatherMeasurement {
    id
    temperature
    rainfall
    windSpeed
    humidity
    uvIndex
    measuredAt
    latitude
    longitude
    notes
    locality {
      ...LocalityName
    }
    measurableId
    measurableType
    measurableName
    mayDestroy
  }
  ${LocalityNameFragmentDoc}
`;
export const WeatherOverviewFragmentDoc = gql`
  fragment WeatherOverview on WeatherOverview {
    minHumidity
    avgHumidity
    maxHumidity
    minRainfall
    avgRainfall
    maxRainfall
    totalRainfall
    dryDays
    rainyDays
    minUvIndex
    avgUvIndex
    maxUvIndex
    minTemperature
    avgTemperature
    maxTemperature
  }
`;
export const WorkOrderVariantStockFragmentDoc = gql`
  fragment WorkOrderVariantStock on WorkOrderVariant {
    ...WorkOrderVariant
    variant {
      ...VariantShort
      stock {
        onHand
      }
    }
  }
  ${WorkOrderVariantFragmentDoc}
  ${VariantShortFragmentDoc}
`;
export const EmployeeShortFragmentDoc = gql`
  fragment EmployeeShort on Employee {
    id
    position {
      id
      name
      positionType
    }
    internalId
    firstName
    lastName
  }
`;
export const EmployeeGroupShortFragmentDoc = gql`
  fragment EmployeeGroupShort on EmployeeGroup {
    id
    name
    employeesCount
  }
`;
export const LeaveTypeShortFragmentDoc = gql`
  fragment LeaveTypeShort on LeaveType {
    id
    name
    kind
    payable
  }
`;
export const TimeConflictFragmentDoc = gql`
  fragment TimeConflict on TimeConflict {
    workOrder {
      id
      activity {
        ...ActivityName
      }
      cycleNumber
    }
    startTime
    endTime
  }
  ${ActivityNameFragmentDoc}
`;
export const WorkOrderEmployeeFragmentDoc = gql`
  fragment WorkOrderEmployee on WorkOrderEmployee {
    id
    employee {
      ...EmployeeShort
      discardedAt
    }
    employeeGroup {
      ...EmployeeGroupShort
    }
    hours
    overtimeHours
    startTime
    endTime
    leaveType {
      ...LeaveTypeShort
    }
    attended
    wagePayment {
      wage @include(if: $showWage)
      wageUnit
    }
    conflictUsage {
      ...TimeConflict
    }
  }
  ${EmployeeShortFragmentDoc}
  ${EmployeeGroupShortFragmentDoc}
  ${LeaveTypeShortFragmentDoc}
  ${TimeConflictFragmentDoc}
`;
export const InfrastructureKindFragmentDoc = gql`
  fragment InfrastructureKind on InfrastructureKind {
    id
    name
    category
  }
`;
export const InfrastructureShortFragmentDoc = gql`
  fragment InfrastructureShort on Infrastructure {
    id
    kind {
      ...InfrastructureKind
    }
    name
    unit {
      ...UnitShort
    }
    total
  }
  ${InfrastructureKindFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const MachineShortFragmentDoc = gql`
  fragment MachineShort on Machine {
    id
    kind {
      ...MachineKindShort
    }
    name
    odometerValue
    odometerUnit {
      ...UnitShort
    }
    horometerValue
    horometerUnit {
      ...UnitShort
    }
  }
  ${MachineKindShortFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const WorkOrderCostCenterEmployeeFragmentDoc = gql`
  fragment WorkOrderCostCenterEmployee on WorkOrderCostCenterEmployee {
    id
    employeeId
    progress
  }
`;
export const WorkOrderCostCenterVariantFragmentDoc = gql`
  fragment WorkOrderCostCenterVariant on WorkOrderCostCenterVariant {
    id
    variantId
    amount
    consumedAmount
    dosage
  }
`;
export const WorkOrderCostCenterMachineFragmentDoc = gql`
  fragment WorkOrderCostCenterMachine on WorkOrderCostCenterMachine {
    id
    machineId
    progress
    hours
  }
`;
export const WorkOrderCostCenterMetricFragmentDoc = gql`
  fragment WorkOrderCostCenterMetric on WorkOrderCostCenterMetric {
    id
    metricId
    employeeId
    machineId
    variantId
    value
  }
`;
export const WorkOrderCostCenterFragmentDoc = gql`
  fragment WorkOrderCostCenter on WorkOrderCostCenter {
    id
    costCenter {
      ...CostCenterShort
      discardedAt
    }
    profitableId
    profitableType
    profitableName
    actualProgress
    plannedProgress
    estimatedHarvestAmount
    cropField {
      ...CropField
    }
    infrastructure {
      ...InfrastructureShort
      warehouse {
        ...WarehouseShort
      }
    }
    machine {
      ...MachineShort
    }
    employees {
      ...WorkOrderCostCenterEmployee
    }
    variants {
      ...WorkOrderCostCenterVariant
    }
    machines {
      ...WorkOrderCostCenterMachine
    }
    metrics {
      ...WorkOrderCostCenterMetric
    }
    dayGoal
    employeeHours
    machineHours
    totalProgress
  }
  ${CostCenterShortFragmentDoc}
  ${CropFieldFragmentDoc}
  ${InfrastructureShortFragmentDoc}
  ${WarehouseShortFragmentDoc}
  ${MachineShortFragmentDoc}
  ${WorkOrderCostCenterEmployeeFragmentDoc}
  ${WorkOrderCostCenterVariantFragmentDoc}
  ${WorkOrderCostCenterMachineFragmentDoc}
  ${WorkOrderCostCenterMetricFragmentDoc}
`;
export const WorkOrderInfoFragmentDoc = gql`
  fragment WorkOrderInfo on WorkOrder {
    id
    cycleNumber
    documentDate
    activity {
      ...ActivityName
    }
  }
  ${ActivityNameFragmentDoc}
`;
export const WorkOrderVariantSourceFragmentDoc = gql`
  fragment WorkOrderVariantSource on WorkOrderVariantSource {
    id
    sourceWorkOrderVariant {
      id
      workOrder {
        ...WorkOrderInfo
      }
    }
    amount
  }
  ${WorkOrderInfoFragmentDoc}
`;
export const WorkOrderOutputFragmentDoc = gql`
  fragment WorkOrderOutput on WorkOrderVariant {
    ...WorkOrderVariant
    stock {
      onHand
    }
    sources {
      ...WorkOrderVariantSource
    }
  }
  ${WorkOrderVariantFragmentDoc}
  ${WorkOrderVariantSourceFragmentDoc}
`;
export const WorkOrderVariantTokenFragmentDoc = gql`
  fragment WorkOrderVariantToken on WorkOrderVariant {
    ...WorkOrderVariant
    variant {
      token {
        quantity
      }
    }
  }
  ${WorkOrderVariantFragmentDoc}
`;
export const CropFieldMachineFragmentDoc = gql`
  fragment CropFieldMachine on CropFieldMachine {
    id
    cropField {
      ...CropField
    }
    capacityValue
  }
  ${CropFieldFragmentDoc}
`;
export const MachineIrrigationFragmentDoc = gql`
  fragment MachineIrrigation on Machine {
    capacityValue
    capacityUnit {
      ...UnitShort
    }
    capacityTime
    capacityTimeUnit {
      ...UnitShortConversion
    }
    cropFieldMachines {
      ...CropFieldMachine
    }
  }
  ${UnitShortFragmentDoc}
  ${UnitShortConversionFragmentDoc}
  ${CropFieldMachineFragmentDoc}
`;
export const WorkOrderMachineFragmentDoc = gql`
  fragment WorkOrderMachine on WorkOrderMachine {
    id
    machine {
      ...MachineShort
      ...MachineIrrigation
      calibrations
      discardedAt
    }
    operator {
      ...EmployeeShort
    }
    implement {
      ...MachineShort
      calibrations
    }
    startOdometer
    endOdometer
    workHours
    startTime
    endTime
    gear
    speed
    rpm
    pressure
    nozzle
  }
  ${MachineShortFragmentDoc}
  ${MachineIrrigationFragmentDoc}
  ${EmployeeShortFragmentDoc}
`;
export const WaterSourceShortFragmentDoc = gql`
  fragment WaterSourceShort on WaterSource {
    id
    name
  }
`;
export const WorkOrderWaterUsageFragmentDoc = gql`
  fragment WorkOrderWaterUsage on WorkOrderWaterUsage {
    tankCapacity
    tanksRequired
    tanksUsed
    outputRate
    outputUnit {
      ...UnitShortConversion
    }
    applicationUnit {
      ...UnitShortConversion
    }
    waterAmount
    waterUnit {
      ...UnitShortConversion
    }
    waterSources {
      ...WaterSourceShort
    }
  }
  ${UnitShortConversionFragmentDoc}
  ${WaterSourceShortFragmentDoc}
`;
export const EquipmentShortFragmentDoc = gql`
  fragment EquipmentShort on Equipment {
    id
    name
  }
`;
export const WorkOrderMetricFragmentDoc = gql`
  fragment WorkOrderMetric on WorkOrderMetric {
    id
    metric {
      ...MetricShort
    }
    value
  }
  ${MetricShortFragmentDoc}
`;
export const WorkOrderSummaryFragmentDoc = gql`
  fragment WorkOrderSummary on WorkOrderSummary {
    attendedCount
    absentCount
    laborCost
    laborManDays
    indirectManDays
    machineCount
    machineHours
    inputCost
    totalGoal
    totalProgress
    laborEfficiency
    machineEfficiency
    totalCost
    payrollCost
  }
`;
export const WorkOrderDetailsFragmentDoc = gql`
  fragment WorkOrderDetails on WorkOrder {
    ...WorkOrder
    activity {
      workdayHours
      progressMeasureType
      laborForceType
      progressUnit {
        ...UnitShort
        conversionFactor
      }
      activityMetrics {
        ...ActivityMetric
      }
    }
    employees {
      ...WorkOrderEmployee
    }
    costCenters {
      ...WorkOrderCostCenter
    }
    inputs {
      ...WorkOrderVariant
    }
    tools {
      ...WorkOrderVariant
    }
    outputs {
      ...WorkOrderOutput
    }
    tokens {
      ...WorkOrderVariantToken
    }
    machines {
      ...WorkOrderMachine
    }
    waterUsage {
      ...WorkOrderWaterUsage
    }
    equipment {
      ...EquipmentShort
    }
    metrics {
      ...WorkOrderMetric
    }
    progressByEmployee
    progressByMachineGroup
    requestByTotal
    usageByManual
    distributeManually
    harvestByOutput
    workerCount
    workerCost
    workerWage
    estimatedNorm
    areaConversionFactor
    summary {
      ...WorkOrderSummary
    }
  }
  ${WorkOrderFragmentDoc}
  ${UnitShortFragmentDoc}
  ${ActivityMetricFragmentDoc}
  ${WorkOrderEmployeeFragmentDoc}
  ${WorkOrderCostCenterFragmentDoc}
  ${WorkOrderVariantFragmentDoc}
  ${WorkOrderOutputFragmentDoc}
  ${WorkOrderVariantTokenFragmentDoc}
  ${WorkOrderMachineFragmentDoc}
  ${WorkOrderWaterUsageFragmentDoc}
  ${EquipmentShortFragmentDoc}
  ${WorkOrderMetricFragmentDoc}
  ${WorkOrderSummaryFragmentDoc}
`;
export const BrandFragmentDoc = gql`
  fragment Brand on Brand {
    id
    name
    discardedAt
    locked
    global
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
`;
export const ChangeLogFragmentDoc = gql`
  fragment ChangeLog on ChangeLog {
    id
    event
    user {
      ...UserShort
      avatar(variant: thumb)
    }
    objectChanges
    createdAt
  }
  ${UserShortFragmentDoc}
`;
export const CommentFragmentDoc = gql`
  fragment Comment on Comment {
    id
    content
    user {
      ...UserShort
    }
    files
    commentableType
    commentableId
    discardedAt
    createdAt
    updatedAt
    mentions {
      id
      email
    }
  }
  ${UserShortFragmentDoc}
`;
export const CountryFragmentDoc = gql`
  fragment Country on Country {
    code
    name
    emojiFlag
    phoneCode
    ssnName
    taxId
    taxIdFormat
    nationalId
    nationalIdFormat
  }
`;
export const AddressFragmentDoc = gql`
  fragment Address on Address {
    id
    country {
      ...Country
    }
    region
    city
    address
    phoneCode
    phoneNumber
  }
  ${CountryFragmentDoc}
`;
export const CounterpartyFragmentDoc = gql`
  fragment Counterparty on Counterparty {
    id
    name
    businessName
    internalId
    address {
      ...Address
    }
    vendorPaymentMethod {
      id
      name
    }
    customerPaymentMethod {
      id
      name
    }
    taxIdNumber
    taxRate
    discardedAt
  }
  ${AddressFragmentDoc}
`;
export const TaxPlanShortFragmentDoc = gql`
  fragment TaxPlanShort on TaxPlan {
    id
    name
    kind
  }
`;
export const TaxComponentShortFragmentDoc = gql`
  fragment TaxComponentShort on TaxComponent {
    id
    name
    abbr
    defaultFormula
  }
`;
export const TaxPlanComponentFragmentDoc = gql`
  fragment TaxPlanComponent on TaxPlanComponent {
    id
    taxComponent {
      ...TaxComponentShort
      nameEn
      nameEs
      taxDebitAccount {
        ...AccountShort
      }
      taxCreditAccount {
        ...AccountShort
      }
    }
    formula
    includedInPrice
    includeInUnitCost
    index
  }
  ${TaxComponentShortFragmentDoc}
  ${AccountShortFragmentDoc}
`;
export const TaxPlanWithComponentsFragmentDoc = gql`
  fragment TaxPlanWithComponents on TaxPlan {
    ...TaxPlanShort
    components {
      ...TaxPlanComponent
    }
  }
  ${TaxPlanShortFragmentDoc}
  ${TaxPlanComponentFragmentDoc}
`;
export const CounterpartyDetailsFragmentDoc = gql`
  fragment CounterpartyDetails on Counterparty {
    ...Counterparty
    payableAccount {
      ...AccountShort
    }
    receivableAccount {
      ...AccountShort
    }
    withholdingAccount {
      ...AccountShort
    }
    withholdingRate
    vatAccount {
      ...AccountShort
    }
    taxPlan {
      ...TaxPlanWithComponents
    }
  }
  ${CounterpartyFragmentDoc}
  ${AccountShortFragmentDoc}
  ${TaxPlanWithComponentsFragmentDoc}
`;
export const EmployeeGroupFragmentDoc = gql`
  fragment EmployeeGroup on EmployeeGroup {
    id
    name
    locality {
      ...LocalityName
    }
    employees {
      ...EmployeeShort
    }
    employeesCount
    discardedAt
  }
  ${LocalityNameFragmentDoc}
  ${EmployeeShortFragmentDoc}
`;
export const ActivityPaymentShortFragmentDoc = gql`
  fragment ActivityPaymentShort on ActivityPayment {
    id
    wage @include(if: $showWage)
    wageUnit
  }
`;
export const SalaryStructureShortFragmentDoc = gql`
  fragment SalaryStructureShort on SalaryStructure {
    id
    name
  }
`;
export const PositionFragmentDoc = gql`
  fragment Position on Position {
    id
    name
    positionType
    discardedAt
    contractType
    basePayment {
      ...ActivityPaymentShort
    }
    salaryStructure {
      ...SalaryStructureShort
    }
    mayDestroy
  }
  ${ActivityPaymentShortFragmentDoc}
  ${SalaryStructureShortFragmentDoc}
`;
export const BankShortFragmentDoc = gql`
  fragment BankShort on Bank {
    id
    name
  }
`;
export const BankAccountFragmentDoc = gql`
  fragment BankAccount on BankAccount {
    id
    accountNumber
    currencyCode
    discardedAt
    bank {
      ...BankShort
    }
    account {
      ...AccountShort
    }
  }
  ${BankShortFragmentDoc}
  ${AccountShortFragmentDoc}
`;
export const EmployeeFragmentDoc = gql`
  fragment Employee on Employee {
    id
    position {
      ...Position
    }
    localities {
      id
      name
    }
    internalId
    firstName
    lastName
    gender
    birthdate
    nationalId
    nationalIdFormatted
    driverLicenseId
    taxIdNumber
    taxIdNumberFormatted
    socialSecurityNumber
    address {
      ...Address
    }
    discardedAt
    employeeGroups {
      id
      name
    }
    basePayment {
      ...ActivityPaymentShort
    }
    salaryStructure {
      ...SalaryStructureShort
    }
    bankAccount {
      ...BankAccount
    }
  }
  ${PositionFragmentDoc}
  ${AddressFragmentDoc}
  ${ActivityPaymentShortFragmentDoc}
  ${SalaryStructureShortFragmentDoc}
  ${BankAccountFragmentDoc}
`;
export const EmployeeLeaveFragmentDoc = gql`
  fragment EmployeeLeave on LeaveAssignmentEmployee {
    id
    startTime
    endTime
    leaveType {
      ...LeaveTypeShort
    }
  }
  ${LeaveTypeShortFragmentDoc}
`;
export const EmployeeShortWithWageFragmentDoc = gql`
  fragment EmployeeShortWithWage on Employee {
    ...EmployeeShort
    wagePayment(wagePaymentFilter: $wagePaymentFilter) {
      id
      wage @include(if: $showWage)
      wageUnit
    }
    conflictUsage(documentDate: $documentDate) {
      ...TimeConflict
    }
    leave(documentDate: $documentDate) {
      ...EmployeeLeave
    }
  }
  ${EmployeeShortFragmentDoc}
  ${TimeConflictFragmentDoc}
  ${EmployeeLeaveFragmentDoc}
`;
export const ErrorFragmentDoc = gql`
  fragment Error on UserError {
    path
    message
    value
    count
  }
`;
export const ImportLogFragmentDoc = gql`
  fragment ImportLog on ImportLog {
    id
    createdAt
    row
    importErrors {
      ...Error
      attribute
    }
  }
  ${ErrorFragmentDoc}
`;
export const ImportFragmentDoc = gql`
  fragment Import on Import {
    id
    moduleName
    user {
      ...UserShort
    }
    file
    fileInfo {
      id
      url
      filename
    }
    status
    kind
    createdAt
    updatedAt
    totalCount
    skippedCount
    discardedCount
    restoredCount
    localeCode
  }
  ${UserShortFragmentDoc}
`;
export const InfrastructureFragmentDoc = gql`
  fragment Infrastructure on Infrastructure {
    id
    kind {
      ...InfrastructureKind
    }
    locality {
      ...LocalityName
    }
    name
    unit {
      ...UnitShort
    }
    total
    costCenter {
      ...CostCenterShort
    }
    warehouse {
      ...WarehouseShort
    }
    discardedAt
    internalId
  }
  ${InfrastructureKindFragmentDoc}
  ${LocalityNameFragmentDoc}
  ${UnitShortFragmentDoc}
  ${CostCenterShortFragmentDoc}
  ${WarehouseShortFragmentDoc}
`;
export const LeaveTypeFragmentDoc = gql`
  fragment LeaveType on LeaveType {
    id
    name
    nameEn
    nameEs
    namePt
    nameRu
    kind
    internalId
    payable
    discardedAt
  }
`;
export const MachineKindFragmentDoc = gql`
  fragment MachineKind on MachineKind {
    id
    name
    nameEn
    nameEs
    namePt
    nameRu
    category
    locked
    global
    discardedAt
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
`;
export const BrandShortFragmentDoc = gql`
  fragment BrandShort on Brand {
    id
    name
  }
`;
export const MachineFragmentDoc = gql`
  fragment Machine on Machine {
    id
    kind {
      ...MachineKindShort
    }
    name
    brand {
      ...BrandShort
    }
    machineLocalities {
      id
      locality {
        ...LocalityName
      }
      costCenter {
        ...CostCenterShort
      }
    }
    model
    year
    licensePlate
    odometerValue
    odometerUnit {
      ...UnitShort
    }
    horometerValue
    horometerUnit {
      ...UnitShort
    }
    fuelType
    fuelUnit {
      ...UnitShort
    }
    ...MachineIrrigation
    calibrations
    photo(variant: thumb)
    discardedAt
    internalId
  }
  ${MachineKindShortFragmentDoc}
  ${BrandShortFragmentDoc}
  ${LocalityNameFragmentDoc}
  ${CostCenterShortFragmentDoc}
  ${UnitShortFragmentDoc}
  ${MachineIrrigationFragmentDoc}
`;
export const MachineDetailsFragmentDoc = gql`
  fragment MachineDetails on Machine {
    ...Machine
    vin
    fuelUnit {
      ...UnitShort
    }
  }
  ${MachineFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const RwdPermissionsFragmentDoc = gql`
  fragment RWDPermissions on RWDPermissions {
    read
    write
    delete
  }
`;
export const PermissionsFragmentDoc = gql`
  fragment Permissions on Permissions {
    tenant {
      ...RWDPermissions
    }
    user {
      ...RWDPermissions
    }
    role {
      ...RWDPermissions
    }
    unit {
      ...RWDPermissions
    }
    locality {
      ...RWDPermissions
    }
    position {
      ...RWDPermissions
    }
    employee {
      ...RWDPermissions
    }
    employeeGroup {
      ...RWDPermissions
    }
    machineKind {
      ...RWDPermissions
    }
    machine {
      ...RWDPermissions
    }
    infrastructure {
      ...RWDPermissions
    }
    infrastructureKind {
      ...RWDPermissions
    }
    comment {
      ...RWDPermissions
    }
    itemCategory {
      ...RWDPermissions
    }
    item {
      ...RWDPermissions
    }
    recipe {
      ...RWDPermissions
    }
    paymentMethod {
      ...RWDPermissions
    }
    counterparty {
      ...RWDPermissions
    }
    transfer {
      ...RWDPermissions
    }
    purchase {
      ...RWDPermissions
    }
    sale {
      ...RWDPermissions
    }
    ingredient {
      ...RWDPermissions
    }
    crop {
      ...RWDPermissions
    }
    disease {
      ...RWDPermissions
    }
    cropCycle {
      ...RWDPermissions
    }
    cropField {
      ...RWDPermissions
    }
    activityCategory {
      ...RWDPermissions
    }
    cropStage {
      ...RWDPermissions
    }
    activity {
      ...RWDPermissions
    }
    metric {
      ...RWDPermissions
    }
    workOrder {
      ...RWDPermissions
    }
    account {
      ...RWDPermissions
    }
    costCenter {
      ...RWDPermissions
    }
    journalEntry {
      ...RWDPermissions
    }
    financeOrder {
      ...RWDPermissions
    }
    financeInvoice {
      ...RWDPermissions
    }
    leaveType {
      ...RWDPermissions
    }
    brand {
      ...RWDPermissions
    }
    inventoryRequest {
      ...RWDPermissions
    }
    fiscalYear {
      ...RWDPermissions
    }
    holiday {
      ...RWDPermissions
    }
    harvestEstimate {
      ...RWDPermissions
    }
    leaveAssignment {
      ...RWDPermissions
    }
    planning {
      ...RWDPermissions
    }
    expenseCategory {
      ...RWDPermissions
    }
    expenseItem {
      ...RWDPermissions
    }
    weatherMeasurement {
      ...RWDPermissions
    }
    changeLog {
      read
    }
    settings {
      company
      import
      updateTenants
      editPlanning
      showCost
      showWage
      testMode
      resetTestMode
      resetPassword
      billing
    }
    salaryStructure {
      ...RWDPermissions
    }
    payrollEntry {
      ...RWDPermissions
    }
    adjustmentType {
      ...RWDPermissions
    }
    taxPlan {
      ...RWDPermissions
    }
    bank {
      ...RWDPermissions
    }
    report {
      stock
      journal
      inventoryMovement
      ledger
      activity
      labor
      cropField
      trialBalance
      balanceSheet
      token
      harvest
      payroll
      workOrder
      expense
      inputCostCenter
      incomeStatement
      machine
    }
    farmMap {
      cropStages
      yields
      costs
      activities
    }
  }
  ${RwdPermissionsFragmentDoc}
`;
export const RoleShortFragmentDoc = gql`
  fragment RoleShort on Role {
    id
    name
    createdAt
    discardedAt
    permissions {
      ...Permissions
    }
  }
  ${PermissionsFragmentDoc}
`;
export const RoleAuthorizedFragmentDoc = gql`
  fragment RoleAuthorized on Role {
    canUpdate {
      value
    }
    canUpdatePermissions {
      value
    }
    canDiscard {
      value
    }
  }
`;
export const RoleFragmentDoc = gql`
  fragment Role on Role {
    ...RoleShort
    ...RoleAuthorized
  }
  ${RoleShortFragmentDoc}
  ${RoleAuthorizedFragmentDoc}
`;
export const TagFragmentDoc = gql`
  fragment Tag on Tag {
    id
    name
  }
`;
export const CurrencyFragmentDoc = gql`
  fragment Currency on Currency {
    id
    isoCode
    name
    symbol
  }
`;
export const TenantSettingsFragmentDoc = gql`
  fragment TenantSettings on Tenant {
    allowLeaveAssignments
    financeEntriesDisabled
    useExternalPolicy
    inventoryRequestEnabled
    overtimeEnabled
    overtimeFactor
    holidayOvertimeFactor
    nonWorkDayOvertimeFactor
    attendanceEnabled
    timeTrackingEnabled
    multifarmInventoryEnabled
    userLimit
    woLimit
  }
`;
export const TenantFeaturesFragmentDoc = gql`
  fragment TenantFeatures on TenantFeatures {
    accounting
    planning
    tokens
    exactus
    agroprime
    payroll
    testMode
    taxPlan
    inventoryStock
    costCenters
    workOrderTypes
    enabledWorkOrderTypes
    customCropVarieties
    advancedActivities
    machinery
    infrastructures
    reusableItems
    customCropStages
    multipleFarms
    employees
    holidays
    workSchedules
    expenses
    banks
  }
`;
export const TenantFragmentDoc = gql`
  fragment Tenant on Tenant {
    id
    name
    slug
    fullName
    email
    localeCode
    currency {
      ...Currency
    }
    address {
      ...Address
    }
    owner {
      id
      email
    }
    logo(variant: thumb)
    description
    legalName
    taxId
    createdAt
    discardedAt
    ...TenantSettings
    features {
      ...TenantFeatures
    }
    website
    mayUpdateCurrency
  }
  ${CurrencyFragmentDoc}
  ${AddressFragmentDoc}
  ${TenantSettingsFragmentDoc}
  ${TenantFeaturesFragmentDoc}
`;
export const LocalityFragmentDoc = gql`
  fragment Locality on Locality {
    ...LocalityShort
    effectiveArea
    internalId
    latitude
    longitude
    geoJson
    totalArea
    discardedAt
    mayUpdateAreaUnit
  }
  ${LocalityShortFragmentDoc}
`;
export const AccountMappingFragmentDoc = gql`
  fragment AccountMapping on AccountMapping {
    id
    kind
    accountableType
    accountableId
    accountableName
    debitAccount {
      ...AccountShort
    }
    creditAccount {
      ...AccountShort
    }
    activityIds
    costCenterIds
    localityIds
    mayDestroy
  }
  ${AccountShortFragmentDoc}
`;
export const TenantFormValueFragmentDoc = gql`
  fragment TenantFormValue on Tenant {
    ...Tenant
    localities @include(if: $withLocalities) {
      ...Locality
    }
    accountMappings @include(if: $accountingSettings) {
      ...AccountMapping
    }
    defaultCostCenters @include(if: $accountingSettings) {
      id
      kind
      costCenter {
        ...CostCenterShort
      }
    }
    bankAccounts @include(if: $bankAccounts) {
      ...BankAccount
    }
  }
  ${TenantFragmentDoc}
  ${LocalityFragmentDoc}
  ${AccountMappingFragmentDoc}
  ${CostCenterShortFragmentDoc}
  ${BankAccountFragmentDoc}
`;
export const UnitFragmentDoc = gql`
  fragment Unit on Unit {
    id
    name
    nameEn
    nameEs
    namePt
    nameRu
    abbr
    abbrEn
    abbrEs
    abbrPt
    abbrRu
    unitType
    primary
    locked
    global
    conversionUnit {
      ...UnitShort
    }
    conversionFactor
    discardedAt
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
  ${UnitShortFragmentDoc}
`;
export const UserFragmentDoc = gql`
  fragment User on User {
    ...UserShort
    avatar(variant: thumb)
    roles {
      id
      name
    }
    tenants {
      id
      fullName
      owner {
        id
      }
    }
    localities {
      ...LocalityName
    }
    createdAt
    discardedAt
    invitationSentAt
    invitationAcceptedAt
    canDiscard {
      value
    }
    canUpdate {
      value
    }
    canUpdateRoles {
      value
    }
    canUpdateLocalities {
      value
    }
    canInvite {
      value
    }
  }
  ${UserShortFragmentDoc}
  ${LocalityNameFragmentDoc}
`;
export const CurrentTenantLocalityFragmentDoc = gql`
  fragment CurrentTenantLocality on Locality {
    ...LocalityShort
    effectiveArea
    latitude
    longitude
    cropCycles {
      ...CropCycleShort
      harvestEstimate {
        id
        estimateUnit {
          ...UnitShort
        }
        harvestUnit {
          ...UnitShort
        }
      }
    }
  }
  ${LocalityShortFragmentDoc}
  ${CropCycleShortFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const CurrentTenantFragmentDoc = gql`
  fragment CurrentTenant on Tenant {
    id
    name
    fullName
    localeCode
    currencyCode
    logo(variant: thumb)
    createdAt
    ...TenantSettings
    features {
      ...TenantFeatures
    }
    onboardingProgress
    currency {
      ...Currency
    }
    secondaryCurrency {
      ...Currency
    }
    address {
      ...Address
    }
    localities {
      ...CurrentTenantLocality
    }
    owner {
      ...UserShort
    }
    timeZone
    overtimeFactor
    testTenantId
    liveTenantId
    resetAt
    canBilling {
      value
    }
    woLimit
    woCount
  }
  ${TenantSettingsFragmentDoc}
  ${TenantFeaturesFragmentDoc}
  ${CurrencyFragmentDoc}
  ${AddressFragmentDoc}
  ${CurrentTenantLocalityFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const CurrentUserFragmentDoc = gql`
  fragment CurrentUser on CurrentUser {
    id
    email
    username
    firstName
    lastName
    admin
    avatar(variant: thumb)
    currentLocale
    currentTenant {
      ...CurrentTenant
    }
    tenants {
      id
      name
      fullName
      slug
      liveTenantId
    }
    localities {
      ...LocalityName
    }
    permissions {
      ...Permissions
    }
    settings
  }
  ${CurrentTenantFragmentDoc}
  ${LocalityNameFragmentDoc}
  ${PermissionsFragmentDoc}
`;
export const AccountKindFragmentDoc = gql`
  fragment AccountKind on AccountKind {
    id
    name
    accountKinds {
      id
      name
      allowOpeningBalance
      accountKinds {
        id
        name
        allowOpeningBalance
      }
    }
    allowOpeningBalance
    discardedAt
  }
`;
export const AccountFragmentDoc = gql`
  fragment Account on Account {
    id
    name
    nameEn
    nameEs
    namePt
    nameRu
    accountNumber
    kind {
      ...AccountKindShort
      allowOpeningBalance
    }
    groupAccount
    currency {
      ...Currency
    }
    parentAccount {
      id
      name
      accountNumber
    }
    balance
    discardedAt
    mayDiscard
    mayDestroy
  }
  ${AccountKindShortFragmentDoc}
  ${CurrencyFragmentDoc}
`;
export const BankFragmentDoc = gql`
  fragment Bank on Bank {
    ...BankShort
    discardedAt
    address {
      ...Address
    }
    locked
    global
    mayDestroy
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
  ${BankShortFragmentDoc}
  ${AddressFragmentDoc}
`;
export const CostCenterFragmentDoc = gql`
  fragment CostCenter on CostCenter {
    id
    name
    nameEn
    nameEs
    namePt
    nameRu
    internalId
    parentCostCenter {
      id
      name
    }
    profitableType
    discardedAt
  }
`;
export const CostCenterTreeFragmentDoc = gql`
  fragment CostCenterTree on CostCenter {
    ...CostCenter
    subcostCenters {
      id
    }
  }
  ${CostCenterFragmentDoc}
`;
export const ExpenseCategoryFragmentDoc = gql`
  fragment ExpenseCategory on ExpenseCategory {
    id
    name
    nameEn
    nameEs
    namePt
    nameRu
    locked
    global
    parentCategory {
      id
      name
    }
    discardedAt
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
`;
export const ExpenseCategoryTreeFragmentDoc = gql`
  fragment ExpenseCategoryTree on ExpenseCategory {
    ...ExpenseCategory
    subcategories {
      id
    }
  }
  ${ExpenseCategoryFragmentDoc}
`;
export const ExpenseItemFragmentDoc = gql`
  fragment ExpenseItem on ExpenseItem {
    ...ExpenseItemShort
    taxPlan {
      ...TaxPlanShort
    }
    nameEn
    nameEs
    namePt
    nameRu
    description
    discardedAt
  }
  ${ExpenseItemShortFragmentDoc}
  ${TaxPlanShortFragmentDoc}
`;
export const FinanceInvoiceFragmentDoc = gql`
  fragment FinanceInvoice on FinanceInvoice {
    id
    counterparty {
      id
      name
    }
    invoiceStatus
    currencyCode
    total
    documentDate
    dueDate
    sourceDocumentId
    sourceDocumentType
    sourceDocumentCode
    internalId
    amountPaid
    amountToPay
  }
`;
export const FinancePaymentFragmentDoc = gql`
  fragment FinancePayment on FinancePayment {
    id
    account {
      ...AccountShort
    }
    internalId
    paymentDate
    exchangeRate
    withholdingRate
    status
  }
  ${AccountShortFragmentDoc}
`;
export const FinanceInvoicePaymentDetailsFragmentDoc = gql`
  fragment FinanceInvoicePaymentDetails on FinancePayment {
    ...FinancePayment
    invoiceAmount
  }
  ${FinancePaymentFragmentDoc}
`;
export const FinanceInvoiceDetailsFragmentDoc = gql`
  fragment FinanceInvoiceDetails on FinanceInvoice {
    ...FinanceInvoice
    account {
      ...AccountShort
    }
    paymentMethod {
      id
      name
    }
    currency {
      ...Currency
    }
    counterparty {
      id
      name
      withholdingRate
    }
    payments {
      ...FinanceInvoicePaymentDetails
    }
    paymentStatus
    withholdAmount
  }
  ${FinanceInvoiceFragmentDoc}
  ${AccountShortFragmentDoc}
  ${CurrencyFragmentDoc}
  ${FinanceInvoicePaymentDetailsFragmentDoc}
`;
export const FinanceOrderFragmentDoc = gql`
  fragment FinanceOrder on FinanceOrder {
    id
    counterparty {
      id
      name
    }
    paymentAccount {
      ...AccountShort
    }
    paymentMethod {
      id
      name
    }
    status
    kind
    currencyCode
    currency {
      ...Currency
    }
    total
    subtotal
    tax
    documentDate
    internalId
    cropCycle {
      ...CropCycleShort
    }
    activity {
      ...ActivityName
    }
    cycleNumber
    createdAt
    updatedAt
    createdBy {
      ...UserShort
    }
    updatedBy {
      ...UserShort
    }
  }
  ${AccountShortFragmentDoc}
  ${CurrencyFragmentDoc}
  ${CropCycleShortFragmentDoc}
  ${ActivityNameFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const FinanceOrderLineItemCostCenterFragmentDoc = gql`
  fragment FinanceOrderLineItemCostCenter on FinanceOrderLineItemCostCenter {
    id
    costCenter {
      ...CostCenterShort
    }
    profitableId
    profitableType
    profitableName
    amount
  }
  ${CostCenterShortFragmentDoc}
`;
export const TaxValueFragmentDoc = gql`
  fragment TaxValue on TaxValue {
    component {
      ...TaxComponentShort
    }
    includedInPrice
    includeInUnitCost
    value
    error
  }
  ${TaxComponentShortFragmentDoc}
`;
export const FinanceOrderLineItemFragmentDoc = gql`
  fragment FinanceOrderLineItem on FinanceOrderLineItem {
    id
    expenseItem {
      ...ExpenseItemShort
      taxPlan {
        ...TaxPlanWithComponents
      }
    }
    amount
    description
    costCenters {
      ...FinanceOrderLineItemCostCenter
    }
    taxPlanValues {
      ...TaxValue
    }
  }
  ${ExpenseItemShortFragmentDoc}
  ${TaxPlanWithComponentsFragmentDoc}
  ${FinanceOrderLineItemCostCenterFragmentDoc}
  ${TaxValueFragmentDoc}
`;
export const FinanceOrderDetailsFragmentDoc = gql`
  fragment FinanceOrderDetails on FinanceOrder {
    ...FinanceOrder
    counterparty {
      taxPlan {
        ...TaxPlanWithComponents
      }
    }
    discount
    discountRate
    tax
    taxRate
    exchangeRate
    subtotal
    lineItems {
      ...FinanceOrderLineItem
    }
    taxValues {
      ...TaxValue
    }
  }
  ${FinanceOrderFragmentDoc}
  ${TaxPlanWithComponentsFragmentDoc}
  ${FinanceOrderLineItemFragmentDoc}
  ${TaxValueFragmentDoc}
`;
export const FinancePeriodFragmentDoc = gql`
  fragment FinancePeriod on Period {
    id
    name
    startDate
    endDate
    closedAt
  }
`;
export const FiscalYearFragmentDoc = gql`
  fragment FiscalYear on FiscalYear {
    id
    name
    startDate
    endDate
    closedAt
    periods {
      ...FinancePeriod
    }
  }
  ${FinancePeriodFragmentDoc}
`;
export const JournalEntryFragmentDoc = gql`
  fragment JournalEntry on JournalEntry {
    id
    status
    documentDate
    sourceDocumentId
    sourceDocumentType
    internalId
    createdAt
    updatedAt
    createdBy {
      ...UserShort
    }
    updatedBy {
      ...UserShort
    }
  }
  ${UserShortFragmentDoc}
`;
export const JournalEntryLineFragmentDoc = gql`
  fragment JournalEntryLine on JournalEntryLine {
    id
    account {
      ...AccountShort
    }
    debit
    amount
    description
    costCenter {
      ...CostCenterShort
    }
    profitableId
    profitableType
    profitableName
  }
  ${AccountShortFragmentDoc}
  ${CostCenterShortFragmentDoc}
`;
export const JournalEntryDetailsFragmentDoc = gql`
  fragment JournalEntryDetails on JournalEntry {
    ...JournalEntry
    entryLines {
      ...JournalEntryLine
    }
  }
  ${JournalEntryFragmentDoc}
  ${JournalEntryLineFragmentDoc}
`;
export const TaxComponentFragmentDoc = gql`
  fragment TaxComponent on TaxComponent {
    ...TaxComponentShort
    nameEn
    nameEs
    description
    descriptionEn
    descriptionEs
    abbr
    defaultFormula
    discardedAt
    mayDiscard
    mayDestroy
    taxDebitAccount {
      ...AccountShort
    }
    taxCreditAccount {
      ...AccountShort
    }
  }
  ${TaxComponentShortFragmentDoc}
  ${AccountShortFragmentDoc}
`;
export const TaxPlanFragmentDoc = gql`
  fragment TaxPlan on TaxPlan {
    ...TaxPlanShort
    nameEn
    nameEs
    components {
      ...TaxPlanComponent
    }
    discardedAt
    mayDiscard
    mayDestroy
  }
  ${TaxPlanShortFragmentDoc}
  ${TaxPlanComponentFragmentDoc}
`;
export const TaxPlanWithSampleFragmentDoc = gql`
  fragment TaxPlanWithSample on TaxPlan {
    ...TaxPlan
    sample(inputVariables: $inputVariables) {
      ...TaxValue
    }
  }
  ${TaxPlanFragmentDoc}
  ${TaxValueFragmentDoc}
`;
export const TokenFragmentDoc = gql`
  fragment Token on Token {
    id
    variant {
      ...VariantShort
    }
    quantity
    discardedAt
  }
  ${VariantShortFragmentDoc}
`;
export const HolidayFragmentDoc = gql`
  fragment Holiday on Holiday {
    id
    description
    date
  }
`;
export const HolidayListFragmentDoc = gql`
  fragment HolidayList on HolidayList {
    id
    year
    holidays {
      ...Holiday
    }
  }
  ${HolidayFragmentDoc}
`;
export const LeaveAssignmentFragmentDoc = gql`
  fragment LeaveAssignment on LeaveAssignment {
    id
    locality {
      ...LocalityName
    }
    workOrder {
      id
      cycleNumber
      activity {
        ...ActivityName
      }
      cropCycle {
        ...CropCycleName
      }
    }
    createdBy {
      ...UserShort
    }
    completedBy {
      ...UserShort
    }
    status
    documentDate
    createdAt
    updatedAt
  }
  ${LocalityNameFragmentDoc}
  ${ActivityNameFragmentDoc}
  ${CropCycleNameFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const LeaveAssignmentEmployeeFragmentDoc = gql`
  fragment LeaveAssignmentEmployee on LeaveAssignmentEmployee {
    id
    employee {
      ...EmployeeShort
      discardedAt
    }
    hours
    days
    startTime
    endTime
    leaveType {
      ...LeaveTypeShort
    }
    payment {
      wage
      wageUnit
    }
  }
  ${EmployeeShortFragmentDoc}
  ${LeaveTypeShortFragmentDoc}
`;
export const LeaveAssignmentDetailsFragmentDoc = gql`
  fragment LeaveAssignmentDetails on LeaveAssignment {
    ...LeaveAssignment
    employees {
      ...LeaveAssignmentEmployee
    }
    workOrder {
      id
      cycleNumber
      cropCycle {
        ...CropCycleName
      }
      activity {
        ...ActivityName
      }
    }
  }
  ${LeaveAssignmentFragmentDoc}
  ${LeaveAssignmentEmployeeFragmentDoc}
  ${CropCycleNameFragmentDoc}
  ${ActivityNameFragmentDoc}
`;
export const PayrollEntryWorkOrderFragmentDoc = gql`
  fragment PayrollEntryWorkOrder on PayrollEntryWorkOrder {
    id
    workOrder {
      ...WorkOrderInfo
      summary {
        laborCost
      }
    }
    payrollCost
  }
  ${WorkOrderInfoFragmentDoc}
`;
export const PayrollEntryFragmentDoc = gql`
  fragment PayrollEntry on PayrollEntry {
    id
    localities {
      ...LocalityName
    }
    salaryStructure {
      ...SalaryStructureShort
    }
    status
    documentDate
    startDate
    endDate
    internalId
    createdAt
    createdBy {
      ...UserShort
    }
  }
  ${LocalityNameFragmentDoc}
  ${SalaryStructureShortFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const SalaryComponentShortFragmentDoc = gql`
  fragment SalaryComponentShort on SalaryComponent {
    id
    name
    money
    formula
  }
`;
export const PayrollEntryEmployeeComponentFragmentDoc = gql`
  fragment PayrollEntryEmployeeComponent on PayrollEntryEmployeeComponent {
    id
    salaryComponent {
      ...SalaryComponentShort
    }
    value
    error
  }
  ${SalaryComponentShortFragmentDoc}
`;
export const PayrollEntryEmployeeFragmentDoc = gql`
  fragment PayrollEntryEmployee on PayrollEntryEmployee {
    id
    employee {
      ...EmployeeShort
    }
    components {
      ...PayrollEntryEmployeeComponent
    }
    inputVariables
  }
  ${EmployeeShortFragmentDoc}
  ${PayrollEntryEmployeeComponentFragmentDoc}
`;
export const PayrollEntryDetailsFragmentDoc = gql`
  fragment PayrollEntryDetails on PayrollEntry {
    ...PayrollEntry
    employees {
      ...PayrollEntryEmployee
    }
    createdAt
    updatedAt
    createdBy {
      ...UserShort
    }
    updatedBy {
      ...UserShort
    }
  }
  ${PayrollEntryFragmentDoc}
  ${PayrollEntryEmployeeFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const SalaryComponentFragmentDoc = gql`
  fragment SalaryComponent on SalaryComponent {
    ...SalaryComponentShort
    nameEn
    nameEs
    description
    descriptionEn
    descriptionEs
    abbr
    formula
    useInCost
    discardedAt
    mayDiscard
    mayDestroy
    salaryDebitAccount {
      ...AccountShort
    }
    salaryCreditAccount {
      ...AccountShort
    }
  }
  ${SalaryComponentShortFragmentDoc}
  ${AccountShortFragmentDoc}
`;
export const SalaryStructureFragmentDoc = gql`
  fragment SalaryStructure on SalaryStructure {
    ...SalaryStructureShort
    nameEn
    nameEs
    salaryComponents {
      ...SalaryComponent
    }
    discardedAt
    mayDiscard
    mayDestroy
  }
  ${SalaryStructureShortFragmentDoc}
  ${SalaryComponentFragmentDoc}
`;
export const PayrollResultFragmentDoc = gql`
  fragment PayrollResult on PayrollResult {
    components {
      component {
        id
        money
      }
      value
      error
    }
  }
`;
export const SalaryStructureWithPayrollFragmentDoc = gql`
  fragment SalaryStructureWithPayroll on SalaryStructure {
    ...SalaryStructure
    payrollResult(inputVariables: $inputVariables) {
      ...PayrollResult
    }
  }
  ${SalaryStructureFragmentDoc}
  ${PayrollResultFragmentDoc}
`;
export const WeekDayFragmentDoc = gql`
  fragment WeekDay on WeekDay {
    id
    name
    working
    startTime
    endTime
  }
`;
export const InventoryOrderFragmentDoc = gql`
  fragment InventoryOrder on InventoryOrder {
    id
    counterparty {
      id
      name
    }
    warehouse {
      ...WarehouseLocality
    }
    paymentMethod {
      id
      name
    }
    status
    kind
    currencyCode
    currency {
      ...Currency
    }
    discount
    discountRate
    tax
    taxRate
    exchangeRate
    otherCosts
    subtotal
    total
    documentDate
    internalId
    notes
    createdAt
    updatedAt
    createdBy {
      ...UserShort
    }
    updatedBy {
      ...UserShort
    }
  }
  ${WarehouseLocalityFragmentDoc}
  ${CurrencyFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const InventoryOrderLineItemFragmentDoc = gql`
  fragment InventoryOrderLineItem on InventoryOrderLineItem {
    id
    variant {
      ...VariantShort
      item {
        defaultTaxPlan {
          ...TaxPlanWithComponents
        }
      }
    }
    quantity
    unitCost
    discountRate
    discount
    subtotal
    taxPlanValues {
      ...TaxValue
    }
  }
  ${VariantShortFragmentDoc}
  ${TaxPlanWithComponentsFragmentDoc}
  ${TaxValueFragmentDoc}
`;
export const InventoryOrderDetailsFragmentDoc = gql`
  fragment InventoryOrderDetails on InventoryOrder {
    ...InventoryOrder
    counterparty {
      taxPlan {
        ...TaxPlanWithComponents
      }
    }
    account {
      ...AccountShort
    }
    lineItems {
      ...InventoryOrderLineItem
    }
    taxValues {
      ...TaxValue
    }
  }
  ${InventoryOrderFragmentDoc}
  ${TaxPlanWithComponentsFragmentDoc}
  ${AccountShortFragmentDoc}
  ${InventoryOrderLineItemFragmentDoc}
  ${TaxValueFragmentDoc}
`;
export const ItemCategoryNameFragmentDoc = gql`
  fragment ItemCategoryName on ItemCategory {
    id
    name
  }
`;
export const ItemCategoryFragmentDoc = gql`
  fragment ItemCategory on ItemCategory {
    ...ItemCategoryName
    nameEn
    nameEs
    namePt
    nameRu
    locked
    global
    defaultItemKind
    parentCategory {
      id
      name
    }
    taxPlan {
      ...TaxPlanShort
    }
    defaultTaxPlan {
      ...TaxPlanShort
    }
    discardedAt
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
  ${ItemCategoryNameFragmentDoc}
  ${TaxPlanShortFragmentDoc}
`;
export const ItemCategoryTreeFragmentDoc = gql`
  fragment ItemCategoryTree on ItemCategory {
    ...ItemCategory
    subcategories {
      id
    }
  }
  ${ItemCategoryFragmentDoc}
`;
export const ItemCategoryOptionFragmentDoc = gql`
  fragment ItemCategoryOption on ItemCategory {
    ...ItemCategoryName
    defaultItemKind
    parentCategory {
      id
      name
    }
    subcategories {
      id
    }
    allowIngredients
  }
  ${ItemCategoryNameFragmentDoc}
`;
export const ItemFragmentDoc = gql`
  fragment Item on Item {
    id
    name
    kind
    itemCategory {
      id
      name
      parentCategory {
        id
        name
      }
    }
    itemIngredients {
      ...ItemIngredient
    }
    taxPlan {
      ...TaxPlanShort
    }
    defaultTaxPlan {
      ...TaxPlanShort
    }
    description
    variationUnitType
    discardedAt
    crop {
      ...CropShort
    }
    itemCropVarieties {
      ...ItemCropVariety
    }
    toxicityCategory
    entryPeriod
    entryPeriodUnit {
      ...UnitShort
    }
    preHarvestInterval
    preHarvestIntervalUnit {
      ...UnitShort
    }
    equipment {
      ...EquipmentShort
    }
    diseases {
      ...DiseaseShort
    }
    tags
  }
  ${ItemIngredientFragmentDoc}
  ${TaxPlanShortFragmentDoc}
  ${CropShortFragmentDoc}
  ${ItemCropVarietyFragmentDoc}
  ${UnitShortFragmentDoc}
  ${EquipmentShortFragmentDoc}
  ${DiseaseShortFragmentDoc}
`;
export const VariantDetailsFragmentDoc = gql`
  fragment VariantDetails on Variant {
    ...VariantShort
    mayUpdateVariationValue
    mayDiscard
    mayDestroy
    discardedAt
  }
  ${VariantShortFragmentDoc}
`;
export const ItemDetailsFragmentDoc = gql`
  fragment ItemDetails on Item {
    ...Item
    mayUpdateUnitKind
    variants {
      ...VariantDetails
      token {
        quantity
      }
    }
  }
  ${ItemFragmentDoc}
  ${VariantDetailsFragmentDoc}
`;
export const PaymentMethodFragmentDoc = gql`
  fragment PaymentMethod on PaymentMethod {
    id
    name
    paymentMethodType
    nameEn
    nameEs
    namePt
    nameRu
    dueDays
    locked
    global
    discardedAt
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
`;
export const RecipeFragmentDoc = gql`
  fragment Recipe on Recipe {
    id
    name
    description
    variants {
      id
      variant {
        ...VariantShort
      }
      rate
      unit {
        ...UnitShortConversion
      }
      applicationUnit {
        ...UnitShortConversion
      }
    }
    discardedAt
  }
  ${VariantShortFragmentDoc}
  ${UnitShortConversionFragmentDoc}
`;
export const AdjustmentTypeFragmentDoc = gql`
  fragment AdjustmentType on AdjustmentType {
    id
    name
    kind
    entryCode
    discardedAt
    locked
    global
    mayDiscard
    mayDestroy
    canUpdate {
      value
    }
    canDelete {
      value
    }
    canUnlock {
      value
    }
  }
`;
export const TransferFragmentDoc = gql`
  fragment Transfer on Transfer {
    id
    adjustmentType {
      ...AdjustmentType
    }
    sourceWarehouse {
      ...WarehouseLocality
    }
    targetWarehouse {
      ...WarehouseLocality
    }
    status
    documentDate
    internalId
    notes
    createdAt
    updatedAt
    createdBy {
      ...UserShort
    }
    updatedBy {
      ...UserShort
    }
  }
  ${AdjustmentTypeFragmentDoc}
  ${WarehouseLocalityFragmentDoc}
  ${UserShortFragmentDoc}
`;
export const TransferVariantFragmentDoc = gql`
  fragment TransferVariant on TransferVariant {
    id
    variant {
      id
      name
      variationUnit {
        id
        name
        abbr
      }
      variationValue
    }
    quantity
    cost
  }
`;
export const TransferDetailsFragmentDoc = gql`
  fragment TransferDetails on Transfer {
    ...Transfer
    transferVariants {
      ...TransferVariant
      variant {
        ...VariantShort
      }
    }
  }
  ${TransferFragmentDoc}
  ${TransferVariantFragmentDoc}
  ${VariantShortFragmentDoc}
`;
export const StockFragmentDoc = gql`
  fragment Stock on Stock {
    total
    onHand
    reserved
  }
`;
export const StockTransferVariantFragmentDoc = gql`
  fragment StockTransferVariant on TransferVariant {
    ...TransferVariant
    variant {
      ...VariantShort
      cost
    }
    stock {
      ...Stock
    }
  }
  ${TransferVariantFragmentDoc}
  ${VariantShortFragmentDoc}
  ${StockFragmentDoc}
`;
export const StockVariantFragmentDoc = gql`
  fragment StockVariant on Variant {
    ...VariantShort
    stock(
      localityId: $localityId
      locationId: $locationId
      locationType: $locationType
      virtual: $virtual
      date: $date
    ) {
      onHand
    }
    cost(date: $date) @include(if: $fetchCost)
  }
  ${VariantShortFragmentDoc}
`;
export const WarehouseFragmentDoc = gql`
  fragment Warehouse on Warehouse {
    id
    name
    locality {
      ...LocalityName
    }
    costCenter {
      ...CostCenterShort
    }
    infrastructure {
      id
      kind {
        name
        category
      }
    }
    discardedAt
  }
  ${LocalityNameFragmentDoc}
  ${CostCenterShortFragmentDoc}
`;
export const ActivitySeedFragmentDoc = gql`
  fragment ActivitySeed on ActivitySeed {
    id
    name
    category {
      id
      name
    }
  }
`;
export const ItemSeedFragmentDoc = gql`
  fragment ItemSeed on ItemSeed {
    id
    name
    persisted
    variationUnitType
    itemCategory {
      id
      name
    }
    itemIngredients {
      id
      rate
      ingredient {
        ...IngredientShort
      }
    }
  }
  ${IngredientShortFragmentDoc}
`;
export const SalaryStructureSeedFragmentDoc = gql`
  fragment SalaryStructureSeed on SalaryStructure {
    id
    name
    salaryComponents {
      ...SalaryComponent
    }
    payrollResult(inputVariables: $inputVariables) {
      ...PayrollResult
    }
  }
  ${SalaryComponentFragmentDoc}
  ${PayrollResultFragmentDoc}
`;
export const MetricValueFragmentDoc = gql`
  fragment MetricValue on WorkOrderMetric {
    metric {
      ...MetricShort
    }
    value
  }
  ${MetricShortFragmentDoc}
`;
export const ActivityOverviewReportFragmentDoc = gql`
  fragment ActivityOverviewReport on ActivityOverview {
    totalLaborCost @include(if: $showCost)
    totalInventoryCost @include(if: $showCost)
    totalCost @include(if: $showCost)
    totalManDays
    totalMachineHours
    avgDayWorkers
    metrics {
      ...MetricValue
    }
  }
  ${MetricValueFragmentDoc}
`;
export const ActivitySummaryReportFragmentDoc = gql`
  fragment ActivitySummaryReport on ActivitySummary {
    activity {
      ...ActivityShort
      progressUnit {
        ...UnitShort
      }
    }
    locality {
      ...LocalityName
    }
    cropCycle {
      ...CropCycleName
    }
    cycleNumber
    startingDate
    endingDate
    workDays
    progress
    manDays
    laborManDays
    avgWorkers
    laborEfficiency
    machineHours
    machineEfficiency
    metrics {
      ...MetricValue
    }
    laborCost @include(if: $showCost)
    inventoryCost @include(if: $showCost)
    totalCost @include(if: $showCost)
    totalCostPerProgress @include(if: $showCost)
  }
  ${ActivityShortFragmentDoc}
  ${UnitShortFragmentDoc}
  ${LocalityNameFragmentDoc}
  ${CropCycleNameFragmentDoc}
  ${MetricValueFragmentDoc}
`;
export const ActivityCostCenterReportFragmentDoc = gql`
  fragment ActivityCostCenterReport on ActivityCostCenter {
    startingDate
    endingDate
    workDays
    progress
    manDays
    laborManDays
    avgWorkers
    laborEfficiency
    machineHours
    machineEfficiency
    metrics {
      ...MetricValue
    }
    laborCost @include(if: $showCost)
    inventoryCost @include(if: $showCost)
    totalCost @include(if: $showCost)
    profitableId
    profitableName
    profitableExtra
    totalCostPerProgress @include(if: $showCost)
    estimatedProgress
    costCenter {
      id
      name
    }
    cropField {
      ...CropFieldWithVarietyShort
    }
  }
  ${MetricValueFragmentDoc}
  ${CropFieldWithVarietyShortFragmentDoc}
`;
export const BalanceSheetReportFragmentDoc = gql`
  fragment BalanceSheetReport on BalanceSheet {
    id
    kind {
      ...AccountKindShort
    }
    groupAccount
    name
    accountNumber
    parentAccount {
      id
    }
    balance
    currencyCode
  }
  ${AccountKindShortFragmentDoc}
`;
export const CropFieldSummaryReportFragmentDoc = gql`
  fragment CropFieldSummaryReport on CropFieldSummary {
    cropField {
      ...CropFieldWithVarietyShort
      locality {
        ...LocalityShort
      }
    }
    harvestedAmount
    harvestYield
    harvestUnit {
      ...UnitShort
    }
    manDays
    machineHours
    metrics {
      ...MetricValue
    }
    laborCost @include(if: $showCost)
    inventoryCost @include(if: $showCost)
    totalCost @include(if: $showCost)
    hasEstimation
    estimatedAmount
    estimatedYield
  }
  ${CropFieldWithVarietyShortFragmentDoc}
  ${LocalityShortFragmentDoc}
  ${UnitShortFragmentDoc}
  ${MetricValueFragmentDoc}
`;
export const CropFieldActivityReportFragmentDoc = gql`
  fragment CropFieldActivityReport on CropFieldActivity {
    startingDate
    endingDate
    workDays
    activity {
      ...ActivityShort
      progressUnit {
        ...UnitShort
      }
    }
    cycleNumber
    progress
    manDays
    machineHours
    metrics {
      ...MetricValue
    }
    laborCost @include(if: $showCost)
    inventoryCost @include(if: $showCost)
    totalCost @include(if: $showCost)
  }
  ${ActivityShortFragmentDoc}
  ${UnitShortFragmentDoc}
  ${MetricValueFragmentDoc}
`;
export const DashboardReportFragmentDoc = gql`
  fragment DashboardReport on DashboardReport {
    totalExpenses @include(if: $showCost)
    totalManDays
    totalMachineHours
    activityExpenses {
      name
      expenses
      activities {
        name
        expenses
      }
    }
    totalAwaitingInventoryWorkOrders
    workOrderStatusSummary {
      status
      count
    }
    workOrderTypeSummary {
      workOrderType
      workOrderCategory
      count
    }
    localitySummary {
      effectiveArea
      cropFieldCount
      plantCount
      workerCount
      machineCount
      infrastructureCount
    }
    cropStageSummary {
      effectiveArea
      effectivePlants
      cropStage {
        ...CropStageShort
      }
      cropFields {
        cropField {
          id
          name
        }
        effectiveArea
        effectivePlants
      }
    }
    cropVarietySummary {
      cropVariety {
        ...CropVarietyShort
      }
      effectiveArea
      effectivePlants
      cropFields {
        cropField {
          id
          name
        }
        effectiveArea
        effectivePlants
        ages {
          age
          effectiveArea
          effectivePlants
        }
      }
    }
    expenses {
      id
      subject
      total
      rate
      parentId
    }
  }
  ${CropStageShortFragmentDoc}
  ${CropVarietyShortFragmentDoc}
`;
export const ExpenseRecordFragmentDoc = gql`
  fragment ExpenseRecord on ExpenseRecord {
    id
    subject
    rate
    columns {
      name
      value
      planned
    }
    total
    planned
    parentId
  }
`;
export const InvoiceSummaryReportItemFragmentDoc = gql`
  fragment InvoiceSummaryReportItem on InvoiceSummaryReportItem {
    counterparty {
      id
      name
      internalId
      payableAccount {
        ...AccountShort
      }
      receivableAccount {
        ...AccountShort
      }
    }
    invoicesCount
    totalAmount
    totalAmountPaid
  }
  ${AccountShortFragmentDoc}
`;
export const GeneralLedgerReportFragmentDoc = gql`
  fragment GeneralLedgerReport on GeneralLedger {
    id
    kind {
      ...AccountKindShort
    }
    name
    accountNumber
    openingBalance
    endingBalance
    credits
    debits
    quantity
    currencyCode
  }
  ${AccountKindShortFragmentDoc}
`;
export const HarvestSummaryFragmentDoc = gql`
  fragment HarvestSummary on HarvestSummary {
    cropField {
      ...CropFieldWithVarietyShort
      locality {
        ...LocalityShort
      }
    }
    harvestedAmount
    harvestUnitAbbr
    estimateUnitAbbr
    harvestYield
    estimateAmount
    estimateYield
    manDays
    laborEfficiency
    machineHours
    machineEfficiency
    frequency
    avgHarvestDays
    lastDate
    daysSinceLast
  }
  ${CropFieldWithVarietyShortFragmentDoc}
  ${LocalityShortFragmentDoc}
`;
export const HarvestCropFieldFragmentDoc = gql`
  fragment HarvestCropField on HarvestCropField {
    id
    documentDate
    activityName
    cycleNumber
    harvestedAmount
    harvestUnitAbbr
    manDays
    laborEfficiency
    machineHours
    machineEfficiency
    daysBetween
    cropFieldGroup
  }
`;
export const HarvestDailyFragmentDoc = gql`
  fragment HarvestDaily on HarvestDaily {
    id
    documentDate
    activity {
      ...ActivityShort
    }
    cropCycle {
      ...CropCycleShort
    }
    cycleNumber
    harvestedAmount
    harvestUnitAbbr
    accumulatedHarvest
    manDays
    accumulatedManDays
    laborEfficiency
    avgLaborEfficiency
    machineHours
    accumulatedMachineHours
    machineEfficiency
    avgMachineEfficiency
    cropFieldGroup
  }
  ${ActivityShortFragmentDoc}
  ${CropCycleShortFragmentDoc}
`;
export const HarvestOverviewFragmentDoc = gql`
  fragment HarvestOverview on HarvestOverview {
    estimateAmount
    estimateYield
    harvestedAmount
    harvestYield
    manDays
    laborEfficiency
    harvestUnitAbbr
    areaUnitAbbr
  }
`;
export const IncomeStatementRecordFragmentDoc = gql`
  fragment IncomeStatementRecord on IncomeStatementRecord {
    id
    name
    columns {
      name
      value
    }
    total
    parentId
  }
`;
export const InputCostCenterFragmentDoc = gql`
  fragment InputCostCenter on InputCostCenter {
    workOrder {
      id
      documentDate
      internalId
      cropCycle {
        ...CropCycleName
      }
      cropFieldGroup
      activity {
        ...ActivityName
        category {
          name
        }
        progressUnit {
          abbr
        }
      }
      cycleNumber
    }
    variant {
      ...VariantShort
    }
    unit {
      ...UnitShort
    }
    costCenter {
      ...CostCenterShort
    }
    profitableId
    profitableName
    sourceWarehouse {
      ...WarehouseLocality
    }
    consumedAmount
    dosage
    consumedCost @include(if: $showCost)
    progress
    costPerProgress @include(if: $showCost)
  }
  ${CropCycleNameFragmentDoc}
  ${ActivityNameFragmentDoc}
  ${VariantShortFragmentDoc}
  ${UnitShortFragmentDoc}
  ${CostCenterShortFragmentDoc}
  ${WarehouseLocalityFragmentDoc}
`;
export const InventoryEntryFragmentDoc = gql`
  fragment InventoryEntry on InventoryEntry {
    id
    locationId
    locationType
    variant {
      ...VariantShort
      cost
    }
    warehouse {
      ...WarehouseLocality
    }
    amount
    balance
    documentId
    documentType
    documentDate
    documentInternalId
    code
    cost @include(if: $showCost)
    runningCost @include(if: $showCost)
    documentValue @include(if: $showCost)
    totalCost @include(if: $showCost)
  }
  ${VariantShortFragmentDoc}
  ${WarehouseLocalityFragmentDoc}
`;
export const InventoryMovementLocationFragmentDoc = gql`
  fragment InventoryMovementLocation on InventoryMovementLocation {
    warehouse {
      ...WarehouseLocality
    }
    counterparty {
      ...Counterparty
    }
    amount
    startingStock
    endingStock
  }
  ${WarehouseLocalityFragmentDoc}
  ${CounterpartyFragmentDoc}
`;
export const InventoryMovementFragmentDoc = gql`
  fragment InventoryMovement on InventoryMovement {
    id
    documentId
    documentType
    documentDate
    documentInternalId
    variant {
      ...VariantShort
    }
    entryCode
    unitCost @include(if: $showCost)
    documentCost @include(if: $showCost)
    locations {
      ...InventoryMovementLocation
    }
  }
  ${VariantShortFragmentDoc}
  ${InventoryMovementLocationFragmentDoc}
`;
export const JournalReportFragmentDoc = gql`
  fragment JournalReport on JournalTransaction {
    documentId
    documentType
    documentInternalId
    date
    code
    entries {
      account {
        name
        accountNumber
      }
      amount
      costCenter {
        internalId
        name
      }
    }
    totalDebit
    totalCredit
    description
  }
`;
export const LaborSummaryReportFragmentDoc = gql`
  fragment LaborSummaryReport on LaborSummary {
    employee {
      ...EmployeeShort
    }
    manDays
    absentDays
    absenceRate
    activitiesCount
    totalCost @include(if: $showCost)
    costPerDay @include(if: $showCost)
  }
  ${EmployeeShortFragmentDoc}
`;
export const LaborActivityReportFragmentDoc = gql`
  fragment LaborActivityReport on LaborActivity {
    employee {
      ...EmployeeShort
    }
    activity {
      ...ActivityShort
      progressUnit {
        ...UnitShort
      }
    }
    hours
    overtimeHours
    manDays
    absentDays
    progress
    efficiency
    totalCost @include(if: $showCost)
    efficiencyCost @include(if: $showCost)
  }
  ${EmployeeShortFragmentDoc}
  ${ActivityShortFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const LedgerEntryReportFragmentDoc = gql`
  fragment LedgerEntryReport on LedgerEntry {
    id
    account {
      ...AccountShort
    }
    code
    currencyCode
    documentDate
    documentId
    documentType
    openingBalance
    debit
    credit
    balance
    description
  }
  ${AccountShortFragmentDoc}
`;
export const MachineSummaryReportFragmentDoc = gql`
  fragment MachineSummaryReport on MachineSummary {
    machine {
      ...MachineShort
      machineLocalities {
        locality {
          ...LocalityName
        }
      }
    }
    machineHours
    workDays
  }
  ${MachineShortFragmentDoc}
  ${LocalityNameFragmentDoc}
`;
export const MachineActivityReportFragmentDoc = gql`
  fragment MachineActivityReport on MachineActivity {
    startingDate
    endingDate
    activity {
      ...ActivityShort
      progressUnit {
        ...UnitShort
      }
    }
    workDays
    progress
    machineHours
    machineEfficiency
  }
  ${ActivityShortFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const PrePayrollOverviewRecordFragmentDoc = gql`
  fragment PrePayrollOverviewRecord on PrePayrollOverviewRecord {
    businessDays
    startDate
    endDate
    manDays
    attendedDays
    absentDays
    basePay @include(if: $showWage)
    overtimePay @include(if: $showWage)
    absencePay @include(if: $showWage)
  }
`;
export const PrePayrollSummaryRecordFragmentDoc = gql`
  fragment PrePayrollSummaryRecord on PrePayrollSummaryRecord {
    employee {
      ...EmployeeShort
    }
    manDays
    attendedDays
    absentDays
    seventhDays
    hours
    overtimeHours
    progress
    progressUnit {
      ...UnitShort
    }
    basePay @include(if: $showWage)
    overtimePay @include(if: $showWage)
    absencePay @include(if: $showWage)
    seventhDayPay @include(if: $showWage)
    totalPay @include(if: $showWage)
  }
  ${EmployeeShortFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const PrePayrollRecordFragmentDoc = gql`
  fragment PrePayrollRecord on PrePayrollRecord {
    workOrder {
      id
      documentDate
      activity {
        ...ActivityName
        internalId
        progressUnit {
          ...UnitShort
        }
      }
    }
    leaveAssignment {
      id
      documentDate
    }
    employee {
      id
      internalId
      fullName
      position {
        name
      }
    }
    costCenter {
      id
      costCenter {
        name
      }
      profitableName
    }
    hours
    overtimeHours
    progress
    leaveType {
      name
      internalId
    }
    attended
    wage @include(if: $showWage)
    wageUnit
    basePay @include(if: $showWage)
    overtimePay @include(if: $showWage)
    absencePay @include(if: $showWage)
    totalPay @include(if: $showWage)
  }
  ${ActivityNameFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const StockReportRecordFragmentDoc = gql`
  fragment StockReportRecord on StockReportRecord {
    stockId
    variantId
    name
    internalId
    item {
      ...ItemShort
    }
    warehouse {
      ...WarehouseLocality
      discardedAt
    }
    ingredients
    variationUnit {
      ...UnitShort
    }
    variationValue
    total
    onHand
    reserved
    missed
    unitCost @include(if: $showCost)
    totalCost @include(if: $showCost)
  }
  ${ItemShortFragmentDoc}
  ${WarehouseLocalityFragmentDoc}
  ${UnitShortFragmentDoc}
`;
export const TokenSummaryFragmentDoc = gql`
  fragment TokenSummary on TokenSummary {
    variant {
      ...VariantShort
    }
    quantity
    startingStock
    endingStock
    paidTokens
    harvestedAmount
    unitAbbr
    redeemedTokens
    employeeTokens
    missingTokens
    unitCost @include(if: $showCost)
    value @include(if: $showCost)
    circulationValue @include(if: $showCost)
  }
  ${VariantShortFragmentDoc}
`;
export const TokenPaidFragmentDoc = gql`
  fragment TokenPaid on TokenPaid {
    id
    documentDate
    activityName
    cycleNumber
    denominations {
      denomination
      amount
    }
    harvestedAmount
    unitAbbr
    unitCost @include(if: $showCost)
    value @include(if: $showCost)
  }
`;
export const TokenRedeemedFragmentDoc = gql`
  fragment TokenRedeemed on TokenRedeemed {
    id
    documentDate
    denominations {
      denomination
      amount
    }
    unitAbbr
  }
`;
export const TrialBalanceReportFragmentDoc = gql`
  fragment TrialBalanceReport on TrialBalance {
    id
    kind {
      ...AccountKindShort
    }
    accountNumber
    name
    credit
    debit
    currencyCode
  }
  ${AccountKindShortFragmentDoc}
`;
export const WorkOrderDailyFragmentDoc = gql`
  fragment WorkOrderDaily on WorkOrderDaily {
    id
    documentDate
    internalId
    locality {
      ...LocalityName
    }
    cropCycle {
      ...CropCycleName
    }
    cycleNumber
    weekNumber
    cropFieldGroup
    costCenter {
      id
      costCenter {
        name
      }
      profitableName
    }
    activity {
      ...ActivityName
      internalId
      progressUnit {
        abbr
      }
      category {
        name
      }
      laborForceType
    }
    progress
    laborQty
    hours
    overtimeHours
    laborEfficiency
    machineQty
    machineHours
    machineEfficiency
    laborCost @include(if: $showCost)
    inventoryCost @include(if: $showCost)
    totalCost @include(if: $showCost)
    metrics {
      ...MetricValue
    }
  }
  ${LocalityNameFragmentDoc}
  ${CropCycleNameFragmentDoc}
  ${ActivityNameFragmentDoc}
  ${MetricValueFragmentDoc}
`;
export const TimeUnitDocument = gql`
  query TimeUnit {
    __type(name: "TimeUnit") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type TimeUnitQueryResult = Apollo.QueryResult<
  TimeUnitQuery,
  TimeUnitQueryVariables
>;
export const WorkUnitDocument = gql`
  query WorkUnit {
    __type(name: "WorkUnit") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type WorkUnitQueryResult = Apollo.QueryResult<
  WorkUnitQuery,
  WorkUnitQueryVariables
>;
export const WorkOrderCategoriesDocument = gql`
  query WorkOrderCategories {
    workOrderCategories {
      name
      workOrderTypes
    }
  }
`;
export type WorkOrderCategoriesQueryResult = Apollo.QueryResult<
  WorkOrderCategoriesQuery,
  WorkOrderCategoriesQueryVariables
>;
export const ActivityProgressMeasureTypeDocument = gql`
  query ActivityProgressMeasureType {
    __type(name: "ActivityProgressMeasureType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type ActivityProgressMeasureTypeQueryResult = Apollo.QueryResult<
  ActivityProgressMeasureTypeQuery,
  ActivityProgressMeasureTypeQueryVariables
>;
export const ActivityLaborForceTypeDocument = gql`
  query ActivityLaborForceType {
    __type(name: "ActivityLaborForceType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type ActivityLaborForceTypeQueryResult = Apollo.QueryResult<
  ActivityLaborForceTypeQuery,
  ActivityLaborForceTypeQueryVariables
>;
export const ActivitiesDocument = gql`
  query Activities(
    $page: Int
    $pageSize: Int
    $filter: ActivitiesFilterInput
    $sort: ActivitiesSortInput
  ) {
    activities(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Activity
      }
      totalCount
    }
  }
  ${ActivityFragmentDoc}
`;
export type ActivitiesQueryResult = Apollo.QueryResult<
  ActivitiesQuery,
  ActivitiesQueryVariables
>;
export const ActivityDocument = gql`
  query Activity($id: ID!, $showWage: Boolean = false) {
    activity(id: $id) {
      ...ActivityDetails
    }
  }
  ${ActivityDetailsFragmentDoc}
`;
export type ActivityQueryResult = Apollo.QueryResult<
  ActivityQuery,
  ActivityQueryVariables
>;
export const ActivityCreateDocument = gql`
  mutation ActivityCreate(
    $fields: ActivityFields!
    $showWage: Boolean = false
  ) {
    activityCreate(fields: $fields) {
      result {
        ...ActivityDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityCreateMutationResult =
  Apollo.MutationResult<ActivityCreateMutation>;
export type ActivityCreateMutationOptions = Apollo.BaseMutationOptions<
  ActivityCreateMutation,
  ActivityCreateMutationVariables
>;
export const ActivityUpdateDocument = gql`
  mutation ActivityUpdate(
    $id: ID!
    $changes: ActivityChanges!
    $showWage: Boolean = false
  ) {
    activityUpdate(id: $id, changes: $changes) {
      result {
        ...ActivityDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityUpdateMutationResult =
  Apollo.MutationResult<ActivityUpdateMutation>;
export type ActivityUpdateMutationOptions = Apollo.BaseMutationOptions<
  ActivityUpdateMutation,
  ActivityUpdateMutationVariables
>;
export const ActivityDiscardDocument = gql`
  mutation ActivityDiscard($id: ID!) {
    activityDiscard(id: $id) {
      result {
        ...Activity
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityDiscardMutationResult =
  Apollo.MutationResult<ActivityDiscardMutation>;
export type ActivityDiscardMutationOptions = Apollo.BaseMutationOptions<
  ActivityDiscardMutation,
  ActivityDiscardMutationVariables
>;
export const ActivityDestroyDocument = gql`
  mutation ActivityDestroy($id: ID!, $dryRun: Boolean) {
    activityDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ActivityDestroyMutationResult =
  Apollo.MutationResult<ActivityDestroyMutation>;
export type ActivityDestroyMutationOptions = Apollo.BaseMutationOptions<
  ActivityDestroyMutation,
  ActivityDestroyMutationVariables
>;
export const ActivityCategoriesDocument = gql`
  query ActivityCategories(
    $page: Int
    $pageSize: Int
    $filter: ActivityCategoriesFilterInput
    $sort: ActivityCategoriesSortInput
  ) {
    activityCategories(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...ActivityCategory
      }
      totalCount
    }
  }
  ${ActivityCategoryFragmentDoc}
`;
export type ActivityCategoriesQueryResult = Apollo.QueryResult<
  ActivityCategoriesQuery,
  ActivityCategoriesQueryVariables
>;
export const ActivityCategoryDocument = gql`
  query ActivityCategory($id: ID!) {
    activityCategory(id: $id) {
      ...ActivityCategory
    }
  }
  ${ActivityCategoryFragmentDoc}
`;
export type ActivityCategoryQueryResult = Apollo.QueryResult<
  ActivityCategoryQuery,
  ActivityCategoryQueryVariables
>;
export const ActivityCategoryCreateDocument = gql`
  mutation ActivityCategoryCreate($fields: ActivityCategoryFields!) {
    activityCategoryCreate(fields: $fields) {
      result {
        ...ActivityCategory
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityCategoryFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityCategoryCreateMutationResult =
  Apollo.MutationResult<ActivityCategoryCreateMutation>;
export type ActivityCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  ActivityCategoryCreateMutation,
  ActivityCategoryCreateMutationVariables
>;
export const ActivityCategoryUpdateDocument = gql`
  mutation ActivityCategoryUpdate(
    $id: ID!
    $changes: ActivityCategoryChanges!
  ) {
    activityCategoryUpdate(id: $id, changes: $changes) {
      result {
        ...ActivityCategory
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityCategoryFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityCategoryUpdateMutationResult =
  Apollo.MutationResult<ActivityCategoryUpdateMutation>;
export type ActivityCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  ActivityCategoryUpdateMutation,
  ActivityCategoryUpdateMutationVariables
>;
export const ActivityCategoryDiscardDocument = gql`
  mutation ActivityCategoryDiscard($id: ID!) {
    activityCategoryDiscard(id: $id) {
      result {
        ...ActivityCategory
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityCategoryFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityCategoryDiscardMutationResult =
  Apollo.MutationResult<ActivityCategoryDiscardMutation>;
export type ActivityCategoryDiscardMutationOptions = Apollo.BaseMutationOptions<
  ActivityCategoryDiscardMutation,
  ActivityCategoryDiscardMutationVariables
>;
export const ActivityCategoryUnlockDocument = gql`
  mutation ActivityCategoryUnlock($id: ID!) {
    activityCategoryUnlock(id: $id) {
      result {
        ...ActivityCategory
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityCategoryFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityCategoryUnlockMutationResult =
  Apollo.MutationResult<ActivityCategoryUnlockMutation>;
export type ActivityCategoryUnlockMutationOptions = Apollo.BaseMutationOptions<
  ActivityCategoryUnlockMutation,
  ActivityCategoryUnlockMutationVariables
>;
export const ActivityCategoryDestroyDocument = gql`
  mutation ActivityCategoryDestroy($id: ID!, $dryRun: Boolean) {
    activityCategoryDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ActivityCategoryDestroyMutationResult =
  Apollo.MutationResult<ActivityCategoryDestroyMutation>;
export type ActivityCategoryDestroyMutationOptions = Apollo.BaseMutationOptions<
  ActivityCategoryDestroyMutation,
  ActivityCategoryDestroyMutationVariables
>;
export const ActivityPaymentCreateDocument = gql`
  mutation ActivityPaymentCreate(
    $fields: ActivityPaymentFields!
    $showWage: Boolean = true
  ) {
    activityPaymentCreate(fields: $fields) {
      result {
        ...ActivityPayment
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityPaymentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityPaymentCreateMutationResult =
  Apollo.MutationResult<ActivityPaymentCreateMutation>;
export type ActivityPaymentCreateMutationOptions = Apollo.BaseMutationOptions<
  ActivityPaymentCreateMutation,
  ActivityPaymentCreateMutationVariables
>;
export const ActivityPaymentUpdateDocument = gql`
  mutation ActivityPaymentUpdate(
    $id: ID!
    $changes: ActivityPaymentChanges!
    $showWage: Boolean = true
  ) {
    activityPaymentUpdate(id: $id, changes: $changes) {
      result {
        ...ActivityPayment
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityPaymentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityPaymentUpdateMutationResult =
  Apollo.MutationResult<ActivityPaymentUpdateMutation>;
export type ActivityPaymentUpdateMutationOptions = Apollo.BaseMutationOptions<
  ActivityPaymentUpdateMutation,
  ActivityPaymentUpdateMutationVariables
>;
export const ActivityPaymentDiscardDocument = gql`
  mutation ActivityPaymentDiscard($id: ID!, $showWage: Boolean = true) {
    activityPaymentDiscard(id: $id) {
      result {
        ...ActivityPayment
      }
      errors {
        ...Error
      }
    }
  }
  ${ActivityPaymentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ActivityPaymentDiscardMutationResult =
  Apollo.MutationResult<ActivityPaymentDiscardMutation>;
export type ActivityPaymentDiscardMutationOptions = Apollo.BaseMutationOptions<
  ActivityPaymentDiscardMutation,
  ActivityPaymentDiscardMutationVariables
>;
export const CropCyclesDocument = gql`
  query CropCycles(
    $page: Int
    $pageSize: Int
    $filter: CropCyclesFilterInput
    $sort: CropCyclesSortInput
  ) {
    cropCycles(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...CropCycle
      }
      totalCount
    }
  }
  ${CropCycleFragmentDoc}
`;
export type CropCyclesQueryResult = Apollo.QueryResult<
  CropCyclesQuery,
  CropCyclesQueryVariables
>;
export const CropCycleDocument = gql`
  query CropCycle($id: ID!) {
    cropCycle(id: $id) {
      ...CropCycle
    }
  }
  ${CropCycleFragmentDoc}
`;
export type CropCycleQueryResult = Apollo.QueryResult<
  CropCycleQuery,
  CropCycleQueryVariables
>;
export const CropCycleCreateDocument = gql`
  mutation CropCycleCreate($fields: CropCycleFields!) {
    cropCycleCreate(fields: $fields) {
      result {
        ...CropCycle
      }
      errors {
        ...Error
      }
    }
  }
  ${CropCycleFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropCycleCreateMutationResult =
  Apollo.MutationResult<CropCycleCreateMutation>;
export type CropCycleCreateMutationOptions = Apollo.BaseMutationOptions<
  CropCycleCreateMutation,
  CropCycleCreateMutationVariables
>;
export const CropCycleUpdateDocument = gql`
  mutation CropCycleUpdate($id: ID!, $changes: CropCycleChanges!) {
    cropCycleUpdate(id: $id, changes: $changes) {
      result {
        ...CropCycle
      }
      errors {
        ...Error
      }
    }
  }
  ${CropCycleFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropCycleUpdateMutationResult =
  Apollo.MutationResult<CropCycleUpdateMutation>;
export type CropCycleUpdateMutationOptions = Apollo.BaseMutationOptions<
  CropCycleUpdateMutation,
  CropCycleUpdateMutationVariables
>;
export const CropCycleDiscardDocument = gql`
  mutation CropCycleDiscard($id: ID!) {
    cropCycleDiscard(id: $id) {
      result {
        ...CropCycle
      }
      errors {
        ...Error
      }
    }
  }
  ${CropCycleFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropCycleDiscardMutationResult =
  Apollo.MutationResult<CropCycleDiscardMutation>;
export type CropCycleDiscardMutationOptions = Apollo.BaseMutationOptions<
  CropCycleDiscardMutation,
  CropCycleDiscardMutationVariables
>;
export const CropCycleDestroyDocument = gql`
  mutation CropCycleDestroy($id: ID!, $dryRun: Boolean) {
    cropCycleDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CropCycleDestroyMutationResult =
  Apollo.MutationResult<CropCycleDestroyMutation>;
export type CropCycleDestroyMutationOptions = Apollo.BaseMutationOptions<
  CropCycleDestroyMutation,
  CropCycleDestroyMutationVariables
>;
export const CropCycleCloseDocument = gql`
  mutation CropCycleClose(
    $id: ID!
    $closedAt: ISO8601DateTime
    $dryRun: Boolean = true
  ) {
    cropCycleClose(id: $id, closedAt: $closedAt, dryRun: $dryRun) {
      activeDocuments {
        ...ActiveDocument
      }
      changes {
        ...CropStageChange
      }
      errors {
        ...Error
      }
    }
  }
  ${ActiveDocumentFragmentDoc}
  ${CropStageChangeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropCycleCloseMutationResult =
  Apollo.MutationResult<CropCycleCloseMutation>;
export type CropCycleCloseMutationOptions = Apollo.BaseMutationOptions<
  CropCycleCloseMutation,
  CropCycleCloseMutationVariables
>;
export const CropCycleReopenDocument = gql`
  mutation CropCycleReopen($id: ID!) {
    cropCycleReopen(id: $id) {
      result {
        ...CropCycle
      }
      errors {
        ...Error
      }
    }
  }
  ${CropCycleFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropCycleReopenMutationResult =
  Apollo.MutationResult<CropCycleReopenMutation>;
export type CropCycleReopenMutationOptions = Apollo.BaseMutationOptions<
  CropCycleReopenMutation,
  CropCycleReopenMutationVariables
>;
export const CropFieldNoteTypeDocument = gql`
  query CropFieldNoteType {
    __type(name: "CropFieldNoteType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type CropFieldNoteTypeQueryResult = Apollo.QueryResult<
  CropFieldNoteTypeQuery,
  CropFieldNoteTypeQueryVariables
>;
export const SampleSectionDocument = gql`
  query SampleSection {
    __type(name: "SampleSection") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type SampleSectionQueryResult = Apollo.QueryResult<
  SampleSectionQuery,
  SampleSectionQueryVariables
>;
export const CropFieldNotesDocument = gql`
  query CropFieldNotes(
    $page: Int
    $pageSize: Int
    $filter: CropFieldNotesFilterInput
    $sort: CropFieldNotesSortInput
  ) {
    cropFieldNotes(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...CropFieldNote
      }
      totalCount
    }
  }
  ${CropFieldNoteFragmentDoc}
`;
export type CropFieldNotesQueryResult = Apollo.QueryResult<
  CropFieldNotesQuery,
  CropFieldNotesQueryVariables
>;
export const CropFieldNoteDocument = gql`
  query CropFieldNote($id: ID!) {
    cropFieldNote(id: $id) {
      ...CropFieldNote
    }
  }
  ${CropFieldNoteFragmentDoc}
`;
export type CropFieldNoteQueryResult = Apollo.QueryResult<
  CropFieldNoteQuery,
  CropFieldNoteQueryVariables
>;
export const CropFieldNoteCreateDocument = gql`
  mutation CropFieldNoteCreate($fields: CropFieldNoteFields!) {
    cropFieldNoteCreate(fields: $fields) {
      result {
        ...CropFieldNote
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFieldNoteFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropFieldNoteCreateMutationResult =
  Apollo.MutationResult<CropFieldNoteCreateMutation>;
export type CropFieldNoteCreateMutationOptions = Apollo.BaseMutationOptions<
  CropFieldNoteCreateMutation,
  CropFieldNoteCreateMutationVariables
>;
export const CropFieldNoteUpdateDocument = gql`
  mutation CropFieldNoteUpdate($id: ID!, $changes: CropFieldNoteChanges!) {
    cropFieldNoteUpdate(id: $id, changes: $changes) {
      result {
        ...CropFieldNote
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFieldNoteFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropFieldNoteUpdateMutationResult =
  Apollo.MutationResult<CropFieldNoteUpdateMutation>;
export type CropFieldNoteUpdateMutationOptions = Apollo.BaseMutationOptions<
  CropFieldNoteUpdateMutation,
  CropFieldNoteUpdateMutationVariables
>;
export const CropFieldNoteDiscardDocument = gql`
  mutation CropFieldNoteDiscard($id: ID!) {
    cropFieldNoteDiscard(id: $id) {
      result {
        ...CropFieldNote
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFieldNoteFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropFieldNoteDiscardMutationResult =
  Apollo.MutationResult<CropFieldNoteDiscardMutation>;
export type CropFieldNoteDiscardMutationOptions = Apollo.BaseMutationOptions<
  CropFieldNoteDiscardMutation,
  CropFieldNoteDiscardMutationVariables
>;
export const CropFieldsDocument = gql`
  query CropFields(
    $page: Int
    $pageSize: Int
    $filter: CropFieldsFilterInput
    $sort: CropFieldsSortInput
    $progressFilter: CropFieldProgressFilterInput
    $estimationInput: CropFieldHarvestEstimationInput
  ) {
    cropFields(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...CropFieldProgress
      }
      totalCount
    }
  }
  ${CropFieldProgressFragmentDoc}
`;
export type CropFieldsQueryResult = Apollo.QueryResult<
  CropFieldsQuery,
  CropFieldsQueryVariables
>;
export const CropFieldGroupsDocument = gql`
  query CropFieldGroups($localityId: [ID!]) {
    cropFieldGroups(localityId: $localityId)
  }
`;
export type CropFieldGroupsQueryResult = Apollo.QueryResult<
  CropFieldGroupsQuery,
  CropFieldGroupsQueryVariables
>;
export const CropFieldDocument = gql`
  query CropField($id: ID!) {
    cropField(id: $id) {
      ...CropFieldDetails
    }
  }
  ${CropFieldDetailsFragmentDoc}
`;
export type CropFieldQueryResult = Apollo.QueryResult<
  CropFieldQuery,
  CropFieldQueryVariables
>;
export const CropFieldCreateDocument = gql`
  mutation CropFieldCreate($fields: CropFieldFields!) {
    cropFieldCreate(fields: $fields) {
      result {
        ...CropFieldDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFieldDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropFieldCreateMutationResult =
  Apollo.MutationResult<CropFieldCreateMutation>;
export type CropFieldCreateMutationOptions = Apollo.BaseMutationOptions<
  CropFieldCreateMutation,
  CropFieldCreateMutationVariables
>;
export const CropFieldUpdateDocument = gql`
  mutation CropFieldUpdate($id: ID!, $changes: CropFieldChanges!) {
    cropFieldUpdate(id: $id, changes: $changes) {
      result {
        ...CropFieldDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFieldDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropFieldUpdateMutationResult =
  Apollo.MutationResult<CropFieldUpdateMutation>;
export type CropFieldUpdateMutationOptions = Apollo.BaseMutationOptions<
  CropFieldUpdateMutation,
  CropFieldUpdateMutationVariables
>;
export const CropFieldBulkUpdateDocument = gql`
  mutation CropFieldBulkUpdate($ids: [ID!]!, $changes: CropFieldBulkChanges!) {
    cropFieldBulkUpdate(ids: $ids, changes: $changes) {
      result {
        id
        result
        errors {
          ...Error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CropFieldBulkUpdateMutationResult =
  Apollo.MutationResult<CropFieldBulkUpdateMutation>;
export type CropFieldBulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  CropFieldBulkUpdateMutation,
  CropFieldBulkUpdateMutationVariables
>;
export const CropFieldDiscardDocument = gql`
  mutation CropFieldDiscard($id: ID!) {
    cropFieldDiscard(id: $id) {
      result {
        ...CropField
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFieldFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropFieldDiscardMutationResult =
  Apollo.MutationResult<CropFieldDiscardMutation>;
export type CropFieldDiscardMutationOptions = Apollo.BaseMutationOptions<
  CropFieldDiscardMutation,
  CropFieldDiscardMutationVariables
>;
export const CropFieldDestroyDocument = gql`
  mutation CropFieldDestroy($id: ID!, $dryRun: Boolean) {
    cropFieldDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CropFieldDestroyMutationResult =
  Apollo.MutationResult<CropFieldDestroyMutation>;
export type CropFieldDestroyMutationOptions = Apollo.BaseMutationOptions<
  CropFieldDestroyMutation,
  CropFieldDestroyMutationVariables
>;
export const CropStageChangeEventsDocument = gql`
  query CropStageChangeEvents {
    __type(name: "CropStageChangeEvent") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type CropStageChangeEventsQueryResult = Apollo.QueryResult<
  CropStageChangeEventsQuery,
  CropStageChangeEventsQueryVariables
>;
export const CropStagesDocument = gql`
  query CropStages(
    $page: Int
    $pageSize: Int
    $filter: CropStagesFilterInput
    $sort: CropStagesSortInput
  ) {
    cropStages(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...CropStage
      }
    }
  }
  ${CropStageFragmentDoc}
`;
export type CropStagesQueryResult = Apollo.QueryResult<
  CropStagesQuery,
  CropStagesQueryVariables
>;
export const CropStageDocument = gql`
  query CropStage($id: ID!) {
    cropStage(id: $id) {
      ...CropStage
    }
  }
  ${CropStageFragmentDoc}
`;
export type CropStageQueryResult = Apollo.QueryResult<
  CropStageQuery,
  CropStageQueryVariables
>;
export const CropStageCreateDocument = gql`
  mutation CropStageCreate($fields: CropStageFields!) {
    cropStageCreate(fields: $fields) {
      result {
        ...CropStage
      }
      errors {
        ...Error
      }
    }
  }
  ${CropStageFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropStageCreateMutationResult =
  Apollo.MutationResult<CropStageCreateMutation>;
export type CropStageCreateMutationOptions = Apollo.BaseMutationOptions<
  CropStageCreateMutation,
  CropStageCreateMutationVariables
>;
export const CropStageUpdateDocument = gql`
  mutation CropStageUpdate($id: ID!, $changes: CropStageChanges!) {
    cropStageUpdate(id: $id, changes: $changes) {
      result {
        ...CropStage
      }
      errors {
        ...Error
      }
    }
  }
  ${CropStageFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropStageUpdateMutationResult =
  Apollo.MutationResult<CropStageUpdateMutation>;
export type CropStageUpdateMutationOptions = Apollo.BaseMutationOptions<
  CropStageUpdateMutation,
  CropStageUpdateMutationVariables
>;
export const CropStageDiscardDocument = gql`
  mutation CropStageDiscard($id: ID!) {
    cropStageDiscard(id: $id) {
      result {
        ...CropStage
      }
      errors {
        ...Error
      }
    }
  }
  ${CropStageFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropStageDiscardMutationResult =
  Apollo.MutationResult<CropStageDiscardMutation>;
export type CropStageDiscardMutationOptions = Apollo.BaseMutationOptions<
  CropStageDiscardMutation,
  CropStageDiscardMutationVariables
>;
export const CropStageDestroyDocument = gql`
  mutation CropStageDestroy($id: ID!, $dryRun: Boolean) {
    cropStageDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CropStageDestroyMutationResult =
  Apollo.MutationResult<CropStageDestroyMutation>;
export type CropStageDestroyMutationOptions = Apollo.BaseMutationOptions<
  CropStageDestroyMutation,
  CropStageDestroyMutationVariables
>;
export const CropVarietiesDocument = gql`
  query CropVarieties(
    $page: Int
    $pageSize: Int
    $filter: CropVarietiesFilterInput
    $sort: CropVarietiesSortInput
  ) {
    cropVarieties(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...CropVariety
      }
      totalCount
    }
  }
  ${CropVarietyFragmentDoc}
`;
export type CropVarietiesQueryResult = Apollo.QueryResult<
  CropVarietiesQuery,
  CropVarietiesQueryVariables
>;
export const CropVarietyDocument = gql`
  query CropVariety($id: ID!) {
    cropVariety(id: $id) {
      ...CropVariety
    }
  }
  ${CropVarietyFragmentDoc}
`;
export type CropVarietyQueryResult = Apollo.QueryResult<
  CropVarietyQuery,
  CropVarietyQueryVariables
>;
export const CropVarietyCreateDocument = gql`
  mutation CropVarietyCreate($fields: CropVarietyFields!) {
    cropVarietyCreate(fields: $fields) {
      result {
        ...CropVariety
      }
      errors {
        ...Error
      }
    }
  }
  ${CropVarietyFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropVarietyCreateMutationResult =
  Apollo.MutationResult<CropVarietyCreateMutation>;
export type CropVarietyCreateMutationOptions = Apollo.BaseMutationOptions<
  CropVarietyCreateMutation,
  CropVarietyCreateMutationVariables
>;
export const CropVarietyUpdateDocument = gql`
  mutation CropVarietyUpdate($id: ID!, $changes: CropVarietyChanges!) {
    cropVarietyUpdate(id: $id, changes: $changes) {
      result {
        ...CropVariety
      }
      errors {
        ...Error
      }
    }
  }
  ${CropVarietyFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropVarietyUpdateMutationResult =
  Apollo.MutationResult<CropVarietyUpdateMutation>;
export type CropVarietyUpdateMutationOptions = Apollo.BaseMutationOptions<
  CropVarietyUpdateMutation,
  CropVarietyUpdateMutationVariables
>;
export const CropVarietyDiscardDocument = gql`
  mutation CropVarietyDiscard($id: ID!) {
    cropVarietyDiscard(id: $id) {
      result {
        ...CropVariety
      }
      errors {
        ...Error
      }
    }
  }
  ${CropVarietyFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropVarietyDiscardMutationResult =
  Apollo.MutationResult<CropVarietyDiscardMutation>;
export type CropVarietyDiscardMutationOptions = Apollo.BaseMutationOptions<
  CropVarietyDiscardMutation,
  CropVarietyDiscardMutationVariables
>;
export const CropVarietyDestroyDocument = gql`
  mutation CropVarietyDestroy($id: ID!, $dryRun: Boolean) {
    cropVarietyDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CropVarietyDestroyMutationResult =
  Apollo.MutationResult<CropVarietyDestroyMutation>;
export type CropVarietyDestroyMutationOptions = Apollo.BaseMutationOptions<
  CropVarietyDestroyMutation,
  CropVarietyDestroyMutationVariables
>;
export const CropsDocument = gql`
  query Crops(
    $page: Int
    $pageSize: Int
    $filter: CropsFilterInput
    $sort: CropsSortInput
  ) {
    crops(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Crop
      }
      totalCount
    }
  }
  ${CropFragmentDoc}
`;
export type CropsQueryResult = Apollo.QueryResult<
  CropsQuery,
  CropsQueryVariables
>;
export const CropOptionsDocument = gql`
  query CropOptions(
    $page: Int
    $pageSize: Int
    $filter: CropsFilterInput
    $sort: CropsSortInput
  ) {
    crops(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...CropShort
      }
      totalCount
    }
  }
  ${CropShortFragmentDoc}
`;
export type CropOptionsQueryResult = Apollo.QueryResult<
  CropOptionsQuery,
  CropOptionsQueryVariables
>;
export const CropDocument = gql`
  query Crop($id: ID!) {
    crop(id: $id) {
      ...Crop
    }
  }
  ${CropFragmentDoc}
`;
export type CropQueryResult = Apollo.QueryResult<CropQuery, CropQueryVariables>;
export const CropCreateDocument = gql`
  mutation CropCreate($fields: CropInputFields!) {
    cropCreate(fields: $fields) {
      result {
        ...Crop
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropCreateMutationResult =
  Apollo.MutationResult<CropCreateMutation>;
export type CropCreateMutationOptions = Apollo.BaseMutationOptions<
  CropCreateMutation,
  CropCreateMutationVariables
>;
export const CropUpdateDocument = gql`
  mutation CropUpdate($id: ID!, $changes: CropChanges!) {
    cropUpdate(id: $id, changes: $changes) {
      result {
        ...Crop
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropUpdateMutationResult =
  Apollo.MutationResult<CropUpdateMutation>;
export type CropUpdateMutationOptions = Apollo.BaseMutationOptions<
  CropUpdateMutation,
  CropUpdateMutationVariables
>;
export const CropDiscardDocument = gql`
  mutation CropDiscard($id: ID!) {
    cropDiscard(id: $id) {
      result {
        ...Crop
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropDiscardMutationResult =
  Apollo.MutationResult<CropDiscardMutation>;
export type CropDiscardMutationOptions = Apollo.BaseMutationOptions<
  CropDiscardMutation,
  CropDiscardMutationVariables
>;
export const CropUnlockDocument = gql`
  mutation CropUnlock($id: ID!) {
    cropUnlock(id: $id) {
      result {
        ...Crop
      }
      errors {
        ...Error
      }
    }
  }
  ${CropFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CropUnlockMutationResult =
  Apollo.MutationResult<CropUnlockMutation>;
export type CropUnlockMutationOptions = Apollo.BaseMutationOptions<
  CropUnlockMutation,
  CropUnlockMutationVariables
>;
export const DiseaseTypeDocument = gql`
  query DiseaseType {
    __type(name: "DiseaseType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type DiseaseTypeQueryResult = Apollo.QueryResult<
  DiseaseTypeQuery,
  DiseaseTypeQueryVariables
>;
export const DiseaseSourceDocument = gql`
  query DiseaseSource {
    __type(name: "DiseaseSource") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type DiseaseSourceQueryResult = Apollo.QueryResult<
  DiseaseSourceQuery,
  DiseaseSourceQueryVariables
>;
export const DiseasesDocument = gql`
  query Diseases(
    $page: Int
    $pageSize: Int
    $filter: DiseasesFilterInput
    $sort: DiseasesSortInput
  ) {
    diseases(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Disease
      }
      totalCount
    }
  }
  ${DiseaseFragmentDoc}
`;
export type DiseasesQueryResult = Apollo.QueryResult<
  DiseasesQuery,
  DiseasesQueryVariables
>;
export const DiseaseDocument = gql`
  query Disease($id: ID!) {
    disease(id: $id) {
      ...Disease
    }
  }
  ${DiseaseFragmentDoc}
`;
export type DiseaseQueryResult = Apollo.QueryResult<
  DiseaseQuery,
  DiseaseQueryVariables
>;
export const DiseaseCreateDocument = gql`
  mutation DiseaseCreate($fields: DiseaseFields!) {
    diseaseCreate(fields: $fields) {
      result {
        ...Disease
      }
      errors {
        ...Error
      }
    }
  }
  ${DiseaseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type DiseaseCreateMutationResult =
  Apollo.MutationResult<DiseaseCreateMutation>;
export type DiseaseCreateMutationOptions = Apollo.BaseMutationOptions<
  DiseaseCreateMutation,
  DiseaseCreateMutationVariables
>;
export const DiseaseUpdateDocument = gql`
  mutation DiseaseUpdate($id: ID!, $changes: DiseaseChanges!) {
    diseaseUpdate(id: $id, changes: $changes) {
      result {
        ...Disease
      }
      errors {
        ...Error
      }
    }
  }
  ${DiseaseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type DiseaseUpdateMutationResult =
  Apollo.MutationResult<DiseaseUpdateMutation>;
export type DiseaseUpdateMutationOptions = Apollo.BaseMutationOptions<
  DiseaseUpdateMutation,
  DiseaseUpdateMutationVariables
>;
export const DiseaseUpdateRisksDocument = gql`
  mutation DiseaseUpdateRisks($id: ID!, $changes: DiseaseRiskChanges!) {
    diseaseRiskUpdate(id: $id, changes: $changes) {
      result {
        ...DiseaseRisk
      }
      errors {
        ...Error
      }
    }
  }
  ${DiseaseRiskFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type DiseaseUpdateRisksMutationResult =
  Apollo.MutationResult<DiseaseUpdateRisksMutation>;
export type DiseaseUpdateRisksMutationOptions = Apollo.BaseMutationOptions<
  DiseaseUpdateRisksMutation,
  DiseaseUpdateRisksMutationVariables
>;
export const DiseaseDiscardDocument = gql`
  mutation DiseaseDiscard($id: ID!) {
    diseaseDiscard(id: $id) {
      result {
        ...Disease
      }
      errors {
        ...Error
      }
    }
  }
  ${DiseaseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type DiseaseDiscardMutationResult =
  Apollo.MutationResult<DiseaseDiscardMutation>;
export type DiseaseDiscardMutationOptions = Apollo.BaseMutationOptions<
  DiseaseDiscardMutation,
  DiseaseDiscardMutationVariables
>;
export const DiseaseUnlockDocument = gql`
  mutation DiseaseUnlock($id: ID!) {
    diseaseUnlock(id: $id) {
      result {
        ...Disease
      }
      errors {
        ...Error
      }
    }
  }
  ${DiseaseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type DiseaseUnlockMutationResult =
  Apollo.MutationResult<DiseaseUnlockMutation>;
export type DiseaseUnlockMutationOptions = Apollo.BaseMutationOptions<
  DiseaseUnlockMutation,
  DiseaseUnlockMutationVariables
>;
export const DiseaseDestroyDocument = gql`
  mutation DiseaseDestroy($id: ID!, $dryRun: Boolean) {
    diseaseDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type DiseaseDestroyMutationResult =
  Apollo.MutationResult<DiseaseDestroyMutation>;
export type DiseaseDestroyMutationOptions = Apollo.BaseMutationOptions<
  DiseaseDestroyMutation,
  DiseaseDestroyMutationVariables
>;
export const HarvestEstimatesDocument = gql`
  query HarvestEstimates(
    $page: Int
    $pageSize: Int
    $filter: HarvestEstimatesFilterInput
    $sort: HarvestEstimatesSortInput
  ) {
    harvestEstimates(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...HarvestEstimate
      }
      totalCount
    }
  }
  ${HarvestEstimateFragmentDoc}
`;
export type HarvestEstimatesQueryResult = Apollo.QueryResult<
  HarvestEstimatesQuery,
  HarvestEstimatesQueryVariables
>;
export const HarvestEstimateDocument = gql`
  query HarvestEstimate($id: ID!) {
    harvestEstimate(id: $id) {
      ...HarvestEstimateDetails
    }
  }
  ${HarvestEstimateDetailsFragmentDoc}
`;
export type HarvestEstimateQueryResult = Apollo.QueryResult<
  HarvestEstimateQuery,
  HarvestEstimateQueryVariables
>;
export const HarvestEstimateCreateDocument = gql`
  mutation HarvestEstimateCreate($fields: HarvestEstimateFields!) {
    harvestEstimateCreate(fields: $fields) {
      result {
        ...HarvestEstimateDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${HarvestEstimateDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type HarvestEstimateCreateMutationResult =
  Apollo.MutationResult<HarvestEstimateCreateMutation>;
export type HarvestEstimateCreateMutationOptions = Apollo.BaseMutationOptions<
  HarvestEstimateCreateMutation,
  HarvestEstimateCreateMutationVariables
>;
export const HarvestEstimateUpdateDocument = gql`
  mutation HarvestEstimateUpdate($id: ID!, $changes: HarvestEstimateChanges!) {
    harvestEstimateUpdate(id: $id, changes: $changes) {
      result {
        ...HarvestEstimateDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${HarvestEstimateDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type HarvestEstimateUpdateMutationResult =
  Apollo.MutationResult<HarvestEstimateUpdateMutation>;
export type HarvestEstimateUpdateMutationOptions = Apollo.BaseMutationOptions<
  HarvestEstimateUpdateMutation,
  HarvestEstimateUpdateMutationVariables
>;
export const HarvestEstimateDestroyDocument = gql`
  mutation HarvestEstimateDestroy($id: ID!, $dryRun: Boolean) {
    harvestEstimateDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type HarvestEstimateDestroyMutationResult =
  Apollo.MutationResult<HarvestEstimateDestroyMutation>;
export type HarvestEstimateDestroyMutationOptions = Apollo.BaseMutationOptions<
  HarvestEstimateDestroyMutation,
  HarvestEstimateDestroyMutationVariables
>;
export const IngredientsDocument = gql`
  query Ingredients(
    $page: Int
    $pageSize: Int
    $filter: IngredientsFilterInput
    $sort: IngredientsSortInput
  ) {
    ingredients(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...Ingredient
      }
      totalCount
    }
  }
  ${IngredientFragmentDoc}
`;
export type IngredientsQueryResult = Apollo.QueryResult<
  IngredientsQuery,
  IngredientsQueryVariables
>;
export const IngredientDocument = gql`
  query Ingredient($id: ID!) {
    ingredient(id: $id) {
      ...Ingredient
    }
  }
  ${IngredientFragmentDoc}
`;
export type IngredientQueryResult = Apollo.QueryResult<
  IngredientQuery,
  IngredientQueryVariables
>;
export const IngredientCreateDocument = gql`
  mutation IngredientCreate($fields: IngredientFields!) {
    ingredientCreate(fields: $fields) {
      result {
        ...Ingredient
      }
      errors {
        ...Error
      }
    }
  }
  ${IngredientFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type IngredientCreateMutationResult =
  Apollo.MutationResult<IngredientCreateMutation>;
export type IngredientCreateMutationOptions = Apollo.BaseMutationOptions<
  IngredientCreateMutation,
  IngredientCreateMutationVariables
>;
export const IngredientUpdateDocument = gql`
  mutation IngredientUpdate($id: ID!, $changes: IngredientChanges!) {
    ingredientUpdate(id: $id, changes: $changes) {
      result {
        ...Ingredient
      }
      errors {
        ...Error
      }
    }
  }
  ${IngredientFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type IngredientUpdateMutationResult =
  Apollo.MutationResult<IngredientUpdateMutation>;
export type IngredientUpdateMutationOptions = Apollo.BaseMutationOptions<
  IngredientUpdateMutation,
  IngredientUpdateMutationVariables
>;
export const IngredientDiscardDocument = gql`
  mutation IngredientDiscard($id: ID!) {
    ingredientDiscard(id: $id) {
      result {
        ...Ingredient
      }
      errors {
        ...Error
      }
    }
  }
  ${IngredientFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type IngredientDiscardMutationResult =
  Apollo.MutationResult<IngredientDiscardMutation>;
export type IngredientDiscardMutationOptions = Apollo.BaseMutationOptions<
  IngredientDiscardMutation,
  IngredientDiscardMutationVariables
>;
export const InventoryRequestsDocument = gql`
  query InventoryRequests(
    $page: Int
    $pageSize: Int
    $filter: InventoryRequestsFilterInput
    $sort: InventoryRequestsSortInput
  ) {
    inventoryRequests(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...InventoryRequest
      }
      totalCount
    }
  }
  ${InventoryRequestFragmentDoc}
`;
export type InventoryRequestsQueryResult = Apollo.QueryResult<
  InventoryRequestsQuery,
  InventoryRequestsQueryVariables
>;
export const InventoryRequestDocument = gql`
  query InventoryRequest($id: ID!) {
    inventoryRequest(id: $id) {
      ...InventoryRequestDetails
    }
  }
  ${InventoryRequestDetailsFragmentDoc}
`;
export type InventoryRequestQueryResult = Apollo.QueryResult<
  InventoryRequestQuery,
  InventoryRequestQueryVariables
>;
export const InventoryRequestUpdateDocument = gql`
  mutation InventoryRequestUpdate(
    $id: ID!
    $changes: InventoryRequestChanges!
  ) {
    inventoryRequestUpdate(id: $id, changes: $changes) {
      result {
        ...InventoryRequestDetails
      }
      errors {
        ...Error
      }
      jobId
    }
  }
  ${InventoryRequestDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type InventoryRequestUpdateMutationResult =
  Apollo.MutationResult<InventoryRequestUpdateMutation>;
export type InventoryRequestUpdateMutationOptions = Apollo.BaseMutationOptions<
  InventoryRequestUpdateMutation,
  InventoryRequestUpdateMutationVariables
>;
export const MetricsDocument = gql`
  query Metrics(
    $page: Int
    $pageSize: Int
    $filter: MetricsFilterInput
    $sort: MetricsSortInput
  ) {
    metrics(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Metric
      }
      totalCount
    }
  }
  ${MetricFragmentDoc}
`;
export type MetricsQueryResult = Apollo.QueryResult<
  MetricsQuery,
  MetricsQueryVariables
>;
export const MetricDocument = gql`
  query Metric($id: ID!) {
    metric(id: $id) {
      ...Metric
    }
  }
  ${MetricFragmentDoc}
`;
export type MetricQueryResult = Apollo.QueryResult<
  MetricQuery,
  MetricQueryVariables
>;
export const MetricCreateDocument = gql`
  mutation MetricCreate($fields: MetricFields!) {
    metricCreate(fields: $fields) {
      result {
        ...Metric
      }
      errors {
        ...Error
      }
    }
  }
  ${MetricFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MetricCreateMutationResult =
  Apollo.MutationResult<MetricCreateMutation>;
export type MetricCreateMutationOptions = Apollo.BaseMutationOptions<
  MetricCreateMutation,
  MetricCreateMutationVariables
>;
export const MetricUpdateDocument = gql`
  mutation MetricUpdate($id: ID!, $changes: MetricChanges!) {
    metricUpdate(id: $id, changes: $changes) {
      result {
        ...Metric
      }
      errors {
        ...Error
      }
    }
  }
  ${MetricFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MetricUpdateMutationResult =
  Apollo.MutationResult<MetricUpdateMutation>;
export type MetricUpdateMutationOptions = Apollo.BaseMutationOptions<
  MetricUpdateMutation,
  MetricUpdateMutationVariables
>;
export const MetricDiscardDocument = gql`
  mutation MetricDiscard($id: ID!) {
    metricDiscard(id: $id) {
      result {
        ...Metric
      }
      errors {
        ...Error
      }
    }
  }
  ${MetricFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MetricDiscardMutationResult =
  Apollo.MutationResult<MetricDiscardMutation>;
export type MetricDiscardMutationOptions = Apollo.BaseMutationOptions<
  MetricDiscardMutation,
  MetricDiscardMutationVariables
>;
export const MetricDestroyDocument = gql`
  mutation MetricDestroy($id: ID!, $dryRun: Boolean) {
    metricDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MetricDestroyMutationResult =
  Apollo.MutationResult<MetricDestroyMutation>;
export type MetricDestroyMutationOptions = Apollo.BaseMutationOptions<
  MetricDestroyMutation,
  MetricDestroyMutationVariables
>;
export const PlanningStatusDocument = gql`
  query PlanningStatus {
    __type(name: "PlanningStatus") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type PlanningStatusQueryResult = Apollo.QueryResult<
  PlanningStatusQuery,
  PlanningStatusQueryVariables
>;
export const PlanningsDocument = gql`
  query Plannings(
    $page: Int
    $pageSize: Int
    $filter: PlanningsFilterInput
    $sort: PlanningsSortInput
  ) {
    plannings(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Planning
      }
      totalCount
    }
  }
  ${PlanningFragmentDoc}
`;
export type PlanningsQueryResult = Apollo.QueryResult<
  PlanningsQuery,
  PlanningsQueryVariables
>;
export const PlanningDocument = gql`
  query Planning($id: ID!, $showWage: Boolean = false) {
    planning(id: $id) {
      ...PlanningDetails
    }
  }
  ${PlanningDetailsFragmentDoc}
`;
export type PlanningQueryResult = Apollo.QueryResult<
  PlanningQuery,
  PlanningQueryVariables
>;
export const PlanningCreateDocument = gql`
  mutation PlanningCreate($fields: PlanningFields!) {
    planningCreate(fields: $fields) {
      result {
        id
        status
      }
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type PlanningCreateMutationResult =
  Apollo.MutationResult<PlanningCreateMutation>;
export type PlanningCreateMutationOptions = Apollo.BaseMutationOptions<
  PlanningCreateMutation,
  PlanningCreateMutationVariables
>;
export const PlanningUpdateDocument = gql`
  mutation PlanningUpdate(
    $id: ID!
    $changes: PlanningChanges!
    $showWage: Boolean = false
  ) {
    planningUpdate(id: $id, changes: $changes) {
      result {
        ...PlanningDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${PlanningDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PlanningUpdateMutationResult =
  Apollo.MutationResult<PlanningUpdateMutation>;
export type PlanningUpdateMutationOptions = Apollo.BaseMutationOptions<
  PlanningUpdateMutation,
  PlanningUpdateMutationVariables
>;
export const PlanningCloneDocument = gql`
  mutation PlanningClone(
    $id: ID!
    $changes: PlanningChanges!
    $showWage: Boolean = false
  ) {
    planningClone(id: $id, changes: $changes) {
      result {
        ...PlanningDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${PlanningDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PlanningCloneMutationResult =
  Apollo.MutationResult<PlanningCloneMutation>;
export type PlanningCloneMutationOptions = Apollo.BaseMutationOptions<
  PlanningCloneMutation,
  PlanningCloneMutationVariables
>;
export const PlanningDestroyDocument = gql`
  mutation PlanningDestroy($id: ID!, $dryRun: Boolean) {
    planningDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type PlanningDestroyMutationResult =
  Apollo.MutationResult<PlanningDestroyMutation>;
export type PlanningDestroyMutationOptions = Apollo.BaseMutationOptions<
  PlanningDestroyMutation,
  PlanningDestroyMutationVariables
>;
export const WaterSourcesDocument = gql`
  query WaterSources(
    $page: Int
    $pageSize: Int
    $filter: WaterSourcesFilterInput
    $sort: WaterSourcesSortInput
  ) {
    waterSources(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...WaterSourceShort
      }
    }
  }
  ${WaterSourceShortFragmentDoc}
`;
export type WaterSourcesQueryResult = Apollo.QueryResult<
  WaterSourcesQuery,
  WaterSourcesQueryVariables
>;
export const WeatherMeasurementsDocument = gql`
  query WeatherMeasurements(
    $page: Int
    $pageSize: Int
    $filter: WeatherMeasurementsFilterInput
    $sort: WeatherMeasurementsSortInput
  ) {
    weatherMeasurements(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...WeatherMeasurement
      }
      totalCount
    }
  }
  ${WeatherMeasurementFragmentDoc}
`;
export type WeatherMeasurementsQueryResult = Apollo.QueryResult<
  WeatherMeasurementsQuery,
  WeatherMeasurementsQueryVariables
>;
export const WeatherMeasurementDocument = gql`
  query WeatherMeasurement($id: ID!) {
    weatherMeasurement(id: $id) {
      ...WeatherMeasurement
    }
  }
  ${WeatherMeasurementFragmentDoc}
`;
export type WeatherMeasurementQueryResult = Apollo.QueryResult<
  WeatherMeasurementQuery,
  WeatherMeasurementQueryVariables
>;
export const WeatherMeasurementCreateDocument = gql`
  mutation WeatherMeasurementCreate($fields: WeatherMeasurementFields!) {
    weatherMeasurementCreate(fields: $fields) {
      result {
        ...WeatherMeasurement
      }
      errors {
        ...Error
      }
    }
  }
  ${WeatherMeasurementFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type WeatherMeasurementCreateMutationResult =
  Apollo.MutationResult<WeatherMeasurementCreateMutation>;
export type WeatherMeasurementCreateMutationOptions =
  Apollo.BaseMutationOptions<
    WeatherMeasurementCreateMutation,
    WeatherMeasurementCreateMutationVariables
  >;
export const WeatherMeasurementUpdateDocument = gql`
  mutation WeatherMeasurementUpdate(
    $id: ID!
    $changes: WeatherMeasurementChanges!
  ) {
    weatherMeasurementUpdate(id: $id, changes: $changes) {
      result {
        ...WeatherMeasurement
      }
      errors {
        ...Error
      }
    }
  }
  ${WeatherMeasurementFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type WeatherMeasurementUpdateMutationResult =
  Apollo.MutationResult<WeatherMeasurementUpdateMutation>;
export type WeatherMeasurementUpdateMutationOptions =
  Apollo.BaseMutationOptions<
    WeatherMeasurementUpdateMutation,
    WeatherMeasurementUpdateMutationVariables
  >;
export const WeatherMeasurementDestroyDocument = gql`
  mutation WeatherMeasurementDestroy($id: ID!, $dryRun: Boolean) {
    weatherMeasurementDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type WeatherMeasurementDestroyMutationResult =
  Apollo.MutationResult<WeatherMeasurementDestroyMutation>;
export type WeatherMeasurementDestroyMutationOptions =
  Apollo.BaseMutationOptions<
    WeatherMeasurementDestroyMutation,
    WeatherMeasurementDestroyMutationVariables
  >;
export const WeatherOverviewDocument = gql`
  query WeatherOverview($filter: WeatherMeasurementsFilterInput) {
    weatherOverview(filter: $filter) {
      ...WeatherOverview
    }
  }
  ${WeatherOverviewFragmentDoc}
`;
export type WeatherOverviewQueryResult = Apollo.QueryResult<
  WeatherOverviewQuery,
  WeatherOverviewQueryVariables
>;
export const WeatherMeasurementsAggregatedDocument = gql`
  query WeatherMeasurementsAggregated(
    $filter: WeatherMeasurementsAggregatedFilterInput
  ) {
    weatherMeasurementsAggregated(filter: $filter) {
      items {
        measuredAt
        rainfall
      }
      minYear
    }
  }
`;
export type WeatherMeasurementsAggregatedQueryResult = Apollo.QueryResult<
  WeatherMeasurementsAggregatedQuery,
  WeatherMeasurementsAggregatedQueryVariables
>;
export const WorkOrderStatusDocument = gql`
  query WorkOrderStatus {
    __type(name: "WorkOrderStatus") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type WorkOrderStatusQueryResult = Apollo.QueryResult<
  WorkOrderStatusQuery,
  WorkOrderStatusQueryVariables
>;
export const InventoryStatusFilterDocument = gql`
  query InventoryStatusFilter {
    __type(name: "InventoryStatusFilter") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type InventoryStatusFilterQueryResult = Apollo.QueryResult<
  InventoryStatusFilterQuery,
  InventoryStatusFilterQueryVariables
>;
export const WorkOrdersDocument = gql`
  query WorkOrders(
    $page: Int
    $pageSize: Int
    $filter: WorkOrdersFilterInput
    $sort: WorkOrdersSortInput
  ) {
    workOrders(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...WorkOrderShort
      }
      totalCount
    }
  }
  ${WorkOrderShortFragmentDoc}
`;
export type WorkOrdersQueryResult = Apollo.QueryResult<
  WorkOrdersQuery,
  WorkOrdersQueryVariables
>;
export const WorkOrderOutputsDocument = gql`
  query WorkOrderOutputs(
    $localityId: ID
    $locationId: ID
    $locationType: LocationType
    $virtual: VirtualWarehouse
    $fetchCost: Boolean = false
    $date: ISO8601DateTime
    $page: Int
    $pageSize: Int
    $filter: WorkOrdersFilterInput
    $sort: WorkOrdersSortInput
  ) {
    workOrders(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...WorkOrderInfo
        outputs {
          ...WorkOrderVariant
          variant {
            ...StockVariant
          }
        }
      }
      totalCount
    }
  }
  ${WorkOrderInfoFragmentDoc}
  ${WorkOrderVariantFragmentDoc}
  ${StockVariantFragmentDoc}
`;
export type WorkOrderOutputsQueryResult = Apollo.QueryResult<
  WorkOrderOutputsQuery,
  WorkOrderOutputsQueryVariables
>;
export const WorkOrderDocument = gql`
  query WorkOrder($id: ID!, $showWage: Boolean = false) {
    workOrder(id: $id) {
      ...WorkOrderDetails
    }
  }
  ${WorkOrderDetailsFragmentDoc}
`;
export type WorkOrderQueryResult = Apollo.QueryResult<
  WorkOrderQuery,
  WorkOrderQueryVariables
>;
export const WorkOrderCreateDocument = gql`
  mutation WorkOrderCreate(
    $fields: WorkOrderFields!
    $showWage: Boolean = false
  ) {
    workOrderCreate(fields: $fields) {
      result {
        ...WorkOrderDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${WorkOrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type WorkOrderCreateMutationResult =
  Apollo.MutationResult<WorkOrderCreateMutation>;
export type WorkOrderCreateMutationOptions = Apollo.BaseMutationOptions<
  WorkOrderCreateMutation,
  WorkOrderCreateMutationVariables
>;
export const WorkOrderUpdateDocument = gql`
  mutation WorkOrderUpdate(
    $id: ID!
    $changes: WorkOrderChanges!
    $showWage: Boolean = false
  ) {
    workOrderUpdate(id: $id, changes: $changes) {
      result {
        ...WorkOrderDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${WorkOrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type WorkOrderUpdateMutationResult =
  Apollo.MutationResult<WorkOrderUpdateMutation>;
export type WorkOrderUpdateMutationOptions = Apollo.BaseMutationOptions<
  WorkOrderUpdateMutation,
  WorkOrderUpdateMutationVariables
>;
export const WorkOrderCloneDocument = gql`
  mutation WorkOrderClone(
    $id: ID!
    $changes: WorkOrderChanges!
    $copyProgress: Boolean = false
  ) {
    workOrderClone(id: $id, changes: $changes, copyProgress: $copyProgress) {
      jobId
    }
  }
`;
export type WorkOrderCloneMutationResult =
  Apollo.MutationResult<WorkOrderCloneMutation>;
export type WorkOrderCloneMutationOptions = Apollo.BaseMutationOptions<
  WorkOrderCloneMutation,
  WorkOrderCloneMutationVariables
>;
export const DateRangeTypeDocument = gql`
  query DateRangeType {
    __type(name: "DateRangeType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type DateRangeTypeQueryResult = Apollo.QueryResult<
  DateRangeTypeQuery,
  DateRangeTypeQueryVariables
>;
export const BrandsDocument = gql`
  query Brands(
    $page: Int
    $pageSize: Int
    $filter: BrandsFilterInput
    $sort: BrandsSortInput
  ) {
    brands(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Brand
      }
    }
  }
  ${BrandFragmentDoc}
`;
export type BrandsQueryResult = Apollo.QueryResult<
  BrandsQuery,
  BrandsQueryVariables
>;
export const BrandDocument = gql`
  query Brand($id: ID!) {
    brand(id: $id) {
      ...Brand
    }
  }
  ${BrandFragmentDoc}
`;
export type BrandQueryResult = Apollo.QueryResult<
  BrandQuery,
  BrandQueryVariables
>;
export const BrandCreateDocument = gql`
  mutation BrandCreate($fields: BrandFields!) {
    brandCreate(fields: $fields) {
      result {
        ...Brand
      }
      errors {
        ...Error
      }
    }
  }
  ${BrandFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BrandCreateMutationResult =
  Apollo.MutationResult<BrandCreateMutation>;
export type BrandCreateMutationOptions = Apollo.BaseMutationOptions<
  BrandCreateMutation,
  BrandCreateMutationVariables
>;
export const BrandUpdateDocument = gql`
  mutation BrandUpdate($id: ID!, $changes: BrandChanges!) {
    brandUpdate(id: $id, changes: $changes) {
      result {
        ...Brand
      }
      errors {
        ...Error
      }
    }
  }
  ${BrandFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BrandUpdateMutationResult =
  Apollo.MutationResult<BrandUpdateMutation>;
export type BrandUpdateMutationOptions = Apollo.BaseMutationOptions<
  BrandUpdateMutation,
  BrandUpdateMutationVariables
>;
export const BrandDiscardDocument = gql`
  mutation BrandDiscard($id: ID!) {
    brandDiscard(id: $id) {
      result {
        ...Brand
      }
      errors {
        ...Error
      }
    }
  }
  ${BrandFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BrandDiscardMutationResult =
  Apollo.MutationResult<BrandDiscardMutation>;
export type BrandDiscardMutationOptions = Apollo.BaseMutationOptions<
  BrandDiscardMutation,
  BrandDiscardMutationVariables
>;
export const BrandUnlockDocument = gql`
  mutation BrandUnlock($id: ID!) {
    brandUnlock(id: $id) {
      result {
        ...Brand
      }
      errors {
        ...Error
      }
    }
  }
  ${BrandFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BrandUnlockMutationResult =
  Apollo.MutationResult<BrandUnlockMutation>;
export type BrandUnlockMutationOptions = Apollo.BaseMutationOptions<
  BrandUnlockMutation,
  BrandUnlockMutationVariables
>;
export const BulkDestroyDocument = gql`
  mutation BulkDestroy(
    $destroyableType: DestroyableType!
    $ids: [ID!]!
    $dryRun: Boolean
  ) {
    bulkDestroy(destroyableType: $destroyableType, ids: $ids, dryRun: $dryRun) {
      result {
        id
        result
        errors {
          ...Error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type BulkDestroyMutationResult =
  Apollo.MutationResult<BulkDestroyMutation>;
export type BulkDestroyMutationOptions = Apollo.BaseMutationOptions<
  BulkDestroyMutation,
  BulkDestroyMutationVariables
>;
export const BulkDiscardDocument = gql`
  mutation BulkDiscard(
    $discardableType: DiscardableType!
    $ids: [ID!]!
    $discard: Boolean!
    $dryRun: Boolean
  ) {
    bulkDiscard(
      discardableType: $discardableType
      ids: $ids
      dryRun: $dryRun
      discard: $discard
    ) {
      result {
        id
        result
        errors {
          ...Error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type BulkDiscardMutationResult =
  Apollo.MutationResult<BulkDiscardMutation>;
export type BulkDiscardMutationOptions = Apollo.BaseMutationOptions<
  BulkDiscardMutation,
  BulkDiscardMutationVariables
>;
export const ChangeLogsDocument = gql`
  query ChangeLogs(
    $page: Int
    $pageSize: Int
    $filter: ChangeLogsFilterInput
    $sort: ChangeLogsSortInput
  ) {
    changeLogs(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...ChangeLog
      }
    }
  }
  ${ChangeLogFragmentDoc}
`;
export type ChangeLogsQueryResult = Apollo.QueryResult<
  ChangeLogsQuery,
  ChangeLogsQueryVariables
>;
export const CommentsDocument = gql`
  query Comments(
    $page: Int
    $pageSize: Int
    $filter: CommentsFilterInput
    $sort: CommentsSortInput
  ) {
    comments(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Comment
      }
    }
  }
  ${CommentFragmentDoc}
`;
export type CommentsQueryResult = Apollo.QueryResult<
  CommentsQuery,
  CommentsQueryVariables
>;
export const CommentDocument = gql`
  query Comment($id: ID!) {
    comment(id: $id) {
      ...Comment
    }
  }
  ${CommentFragmentDoc}
`;
export type CommentQueryResult = Apollo.QueryResult<
  CommentQuery,
  CommentQueryVariables
>;
export const CommentCreateDocument = gql`
  mutation CommentCreate($fields: CommentFields!) {
    commentCreate(fields: $fields) {
      result {
        ...Comment
      }
      errors {
        ...Error
      }
    }
  }
  ${CommentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CommentCreateMutationResult =
  Apollo.MutationResult<CommentCreateMutation>;
export type CommentCreateMutationOptions = Apollo.BaseMutationOptions<
  CommentCreateMutation,
  CommentCreateMutationVariables
>;
export const CommentUpdateDocument = gql`
  mutation CommentUpdate($id: ID!, $changes: CommentChanges!) {
    commentUpdate(id: $id, changes: $changes) {
      result {
        ...Comment
      }
      errors {
        ...Error
      }
    }
  }
  ${CommentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CommentUpdateMutationResult =
  Apollo.MutationResult<CommentUpdateMutation>;
export type CommentUpdateMutationOptions = Apollo.BaseMutationOptions<
  CommentUpdateMutation,
  CommentUpdateMutationVariables
>;
export const CommentDiscardDocument = gql`
  mutation CommentDiscard($id: ID!) {
    commentDiscard(id: $id) {
      result {
        ...Comment
      }
      errors {
        ...Error
      }
    }
  }
  ${CommentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CommentDiscardMutationResult =
  Apollo.MutationResult<CommentDiscardMutation>;
export type CommentDiscardMutationOptions = Apollo.BaseMutationOptions<
  CommentDiscardMutation,
  CommentDiscardMutationVariables
>;
export const CounterpartiesDocument = gql`
  query Counterparties(
    $page: Int
    $pageSize: Int
    $filter: CounterpartiesFilterInput
    $sort: CounterpartiesSortInput
    $fetchTaxPlan: Boolean = false
  ) {
    counterparties(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...Counterparty
        taxPlan @include(if: $fetchTaxPlan) {
          ...TaxPlanWithComponents
        }
      }
    }
  }
  ${CounterpartyFragmentDoc}
  ${TaxPlanWithComponentsFragmentDoc}
`;
export type CounterpartiesQueryResult = Apollo.QueryResult<
  CounterpartiesQuery,
  CounterpartiesQueryVariables
>;
export const CounterpartyDocument = gql`
  query Counterparty($id: ID!) {
    counterparty(id: $id) {
      ...CounterpartyDetails
    }
  }
  ${CounterpartyDetailsFragmentDoc}
`;
export type CounterpartyQueryResult = Apollo.QueryResult<
  CounterpartyQuery,
  CounterpartyQueryVariables
>;
export const CounterpartyCreateDocument = gql`
  mutation CounterpartyCreate($fields: CounterpartyFields!) {
    counterpartyCreate(fields: $fields) {
      result {
        ...CounterpartyDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${CounterpartyDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CounterpartyCreateMutationResult =
  Apollo.MutationResult<CounterpartyCreateMutation>;
export type CounterpartyCreateMutationOptions = Apollo.BaseMutationOptions<
  CounterpartyCreateMutation,
  CounterpartyCreateMutationVariables
>;
export const CounterpartyUpdateDocument = gql`
  mutation CounterpartyUpdate($id: ID!, $changes: CounterpartyChanges!) {
    counterpartyUpdate(id: $id, changes: $changes) {
      result {
        ...CounterpartyDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${CounterpartyDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CounterpartyUpdateMutationResult =
  Apollo.MutationResult<CounterpartyUpdateMutation>;
export type CounterpartyUpdateMutationOptions = Apollo.BaseMutationOptions<
  CounterpartyUpdateMutation,
  CounterpartyUpdateMutationVariables
>;
export const CounterpartyDiscardDocument = gql`
  mutation CounterpartyDiscard($id: ID!) {
    counterpartyDiscard(id: $id) {
      result {
        ...Counterparty
      }
      errors {
        ...Error
      }
    }
  }
  ${CounterpartyFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CounterpartyDiscardMutationResult =
  Apollo.MutationResult<CounterpartyDiscardMutation>;
export type CounterpartyDiscardMutationOptions = Apollo.BaseMutationOptions<
  CounterpartyDiscardMutation,
  CounterpartyDiscardMutationVariables
>;
export const CounterpartyDestroyDocument = gql`
  mutation CounterpartyDestroy($id: ID!, $dryRun: Boolean) {
    counterpartyDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CounterpartyDestroyMutationResult =
  Apollo.MutationResult<CounterpartyDestroyMutation>;
export type CounterpartyDestroyMutationOptions = Apollo.BaseMutationOptions<
  CounterpartyDestroyMutation,
  CounterpartyDestroyMutationVariables
>;
export const CounterpartyBulkUpdateDocument = gql`
  mutation CounterpartyBulkUpdate(
    $ids: [ID!]!
    $changes: CounterpartyBulkChanges!
  ) {
    counterpartyBulkUpdate(ids: $ids, changes: $changes) {
      result {
        id
        result
        errors {
          ...Error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CounterpartyBulkUpdateMutationResult =
  Apollo.MutationResult<CounterpartyBulkUpdateMutation>;
export type CounterpartyBulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  CounterpartyBulkUpdateMutation,
  CounterpartyBulkUpdateMutationVariables
>;
export const CountriesDocument = gql`
  query Countries {
    countries {
      ...Country
      currency {
        ...Currency
      }
      regions
    }
  }
  ${CountryFragmentDoc}
  ${CurrencyFragmentDoc}
`;
export type CountriesQueryResult = Apollo.QueryResult<
  CountriesQuery,
  CountriesQueryVariables
>;
export const EmployeeGroupsDocument = gql`
  query EmployeeGroups(
    $page: Int
    $pageSize: Int
    $filter: EmployeeGroupsFilterInput
    $sort: EmployeeGroupsSortInput
  ) {
    employeeGroups(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...EmployeeGroup
      }
    }
  }
  ${EmployeeGroupFragmentDoc}
`;
export type EmployeeGroupsQueryResult = Apollo.QueryResult<
  EmployeeGroupsQuery,
  EmployeeGroupsQueryVariables
>;
export const EmployeeGroupOptionsDocument = gql`
  query EmployeeGroupOptions(
    $page: Int
    $pageSize: Int
    $filter: EmployeeGroupsFilterInput
    $sort: EmployeeGroupsSortInput
  ) {
    employeeGroups(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...EmployeeGroupShort
        locality {
          ...LocalityName
        }
      }
    }
  }
  ${EmployeeGroupShortFragmentDoc}
  ${LocalityNameFragmentDoc}
`;
export type EmployeeGroupOptionsQueryResult = Apollo.QueryResult<
  EmployeeGroupOptionsQuery,
  EmployeeGroupOptionsQueryVariables
>;
export const EmployeeGroupOptionsWithWagesDocument = gql`
  query EmployeeGroupOptionsWithWages(
    $pageSize: Int = 25
    $filter: EmployeeGroupsFilterInput
    $sort: EmployeeGroupsSortInput = { name: asc }
    $wagePaymentFilter: EmployeeWageFilterInput
    $documentDate: ISO8601DateTime
    $showWage: Boolean = false
  ) {
    employeeGroups(pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...EmployeeGroupShort
        employees {
          ...EmployeeShortWithWage
        }
      }
    }
  }
  ${EmployeeGroupShortFragmentDoc}
  ${EmployeeShortWithWageFragmentDoc}
`;
export type EmployeeGroupOptionsWithWagesQueryResult = Apollo.QueryResult<
  EmployeeGroupOptionsWithWagesQuery,
  EmployeeGroupOptionsWithWagesQueryVariables
>;
export const EmployeeGroupDocument = gql`
  query EmployeeGroup($id: ID!) {
    employeeGroup(id: $id) {
      ...EmployeeGroup
    }
  }
  ${EmployeeGroupFragmentDoc}
`;
export type EmployeeGroupQueryResult = Apollo.QueryResult<
  EmployeeGroupQuery,
  EmployeeGroupQueryVariables
>;
export const EmployeeGroupCreateDocument = gql`
  mutation EmployeeGroupCreate($fields: EmployeeGroupFields!) {
    employeeGroupCreate(fields: $fields) {
      result {
        ...EmployeeGroup
      }
      errors {
        ...Error
      }
    }
  }
  ${EmployeeGroupFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EmployeeGroupCreateMutationResult =
  Apollo.MutationResult<EmployeeGroupCreateMutation>;
export type EmployeeGroupCreateMutationOptions = Apollo.BaseMutationOptions<
  EmployeeGroupCreateMutation,
  EmployeeGroupCreateMutationVariables
>;
export const EmployeeGroupUpdateDocument = gql`
  mutation EmployeeGroupUpdate($id: ID!, $changes: EmployeeGroupChanges!) {
    employeeGroupUpdate(id: $id, changes: $changes) {
      result {
        ...EmployeeGroup
      }
      errors {
        ...Error
      }
    }
  }
  ${EmployeeGroupFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EmployeeGroupUpdateMutationResult =
  Apollo.MutationResult<EmployeeGroupUpdateMutation>;
export type EmployeeGroupUpdateMutationOptions = Apollo.BaseMutationOptions<
  EmployeeGroupUpdateMutation,
  EmployeeGroupUpdateMutationVariables
>;
export const EmployeeGroupDiscardDocument = gql`
  mutation EmployeeGroupDiscard($id: ID!) {
    employeeGroupDiscard(id: $id) {
      result {
        ...EmployeeGroup
      }
      errors {
        ...Error
      }
    }
  }
  ${EmployeeGroupFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EmployeeGroupDiscardMutationResult =
  Apollo.MutationResult<EmployeeGroupDiscardMutation>;
export type EmployeeGroupDiscardMutationOptions = Apollo.BaseMutationOptions<
  EmployeeGroupDiscardMutation,
  EmployeeGroupDiscardMutationVariables
>;
export const EmployeesDocument = gql`
  query Employees(
    $page: Int
    $pageSize: Int
    $filter: EmployeesFilterInput
    $sort: EmployeesSortInput
    $showWage: Boolean = false
  ) {
    employees(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Employee
      }
    }
  }
  ${EmployeeFragmentDoc}
`;
export type EmployeesQueryResult = Apollo.QueryResult<
  EmployeesQuery,
  EmployeesQueryVariables
>;
export const EmployeeDocument = gql`
  query Employee($id: ID!, $showWage: Boolean = false) {
    employee(id: $id) {
      ...Employee
    }
  }
  ${EmployeeFragmentDoc}
`;
export type EmployeeQueryResult = Apollo.QueryResult<
  EmployeeQuery,
  EmployeeQueryVariables
>;
export const EmployeeOptionsWithWagesDocument = gql`
  query EmployeeOptionsWithWages(
    $pageSize: Int = 25
    $filter: EmployeesFilterInput
    $sort: EmployeesSortInput = { firstName: asc }
    $wagePaymentFilter: EmployeeWageFilterInput
    $documentDate: ISO8601DateTime
    $showWage: Boolean = false
  ) {
    employees(pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...EmployeeShortWithWage
      }
    }
  }
  ${EmployeeShortWithWageFragmentDoc}
`;
export type EmployeeOptionsWithWagesQueryResult = Apollo.QueryResult<
  EmployeeOptionsWithWagesQuery,
  EmployeeOptionsWithWagesQueryVariables
>;
export const EmployeeCreateDocument = gql`
  mutation EmployeeCreate(
    $fields: EmployeeFields!
    $showWage: Boolean = false
  ) {
    employeeCreate(fields: $fields) {
      result {
        ...Employee
      }
      errors {
        ...Error
      }
    }
  }
  ${EmployeeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EmployeeCreateMutationResult =
  Apollo.MutationResult<EmployeeCreateMutation>;
export type EmployeeCreateMutationOptions = Apollo.BaseMutationOptions<
  EmployeeCreateMutation,
  EmployeeCreateMutationVariables
>;
export const EmployeeUpdateDocument = gql`
  mutation EmployeeUpdate(
    $id: ID!
    $changes: EmployeeChanges!
    $showWage: Boolean = false
  ) {
    employeeUpdate(id: $id, changes: $changes) {
      result {
        ...Employee
      }
      errors {
        ...Error
      }
    }
  }
  ${EmployeeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EmployeeUpdateMutationResult =
  Apollo.MutationResult<EmployeeUpdateMutation>;
export type EmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<
  EmployeeUpdateMutation,
  EmployeeUpdateMutationVariables
>;
export const EmployeeDiscardDocument = gql`
  mutation EmployeeDiscard($id: ID!, $showWage: Boolean = false) {
    employeeDiscard(id: $id) {
      result {
        ...Employee
      }
      errors {
        ...Error
      }
    }
  }
  ${EmployeeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type EmployeeDiscardMutationResult =
  Apollo.MutationResult<EmployeeDiscardMutation>;
export type EmployeeDiscardMutationOptions = Apollo.BaseMutationOptions<
  EmployeeDiscardMutation,
  EmployeeDiscardMutationVariables
>;
export const EmployeeDestroyDocument = gql`
  mutation EmployeeDestroy($id: ID!, $dryRun: Boolean) {
    employeeDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EmployeeDestroyMutationResult =
  Apollo.MutationResult<EmployeeDestroyMutation>;
export type EmployeeDestroyMutationOptions = Apollo.BaseMutationOptions<
  EmployeeDestroyMutation,
  EmployeeDestroyMutationVariables
>;
export const EmployeeBulkUpdateDocument = gql`
  mutation EmployeeBulkUpdate($ids: [ID!]!, $changes: EmployeeBulkChanges!) {
    employeeBulkUpdate(ids: $ids, changes: $changes) {
      result {
        id
        result
        errors {
          ...Error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type EmployeeBulkUpdateMutationResult =
  Apollo.MutationResult<EmployeeBulkUpdateMutation>;
export type EmployeeBulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  EmployeeBulkUpdateMutation,
  EmployeeBulkUpdateMutationVariables
>;
export const ExchangeRateDocument = gql`
  query ExchangeRate($from: String!, $to: String!, $date: ISO8601Date) {
    exchangeRate(from: $from, to: $to, date: $date)
  }
`;
export type ExchangeRateQueryResult = Apollo.QueryResult<
  ExchangeRateQuery,
  ExchangeRateQueryVariables
>;
export const ImportLogsDocument = gql`
  query ImportLogs(
    $page: Int
    $pageSize: Int
    $filter: ImportLogsFilterInput
    $sort: ImportLogsSortInput
  ) {
    importLogs(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...ImportLog
      }
    }
  }
  ${ImportLogFragmentDoc}
`;
export type ImportLogsQueryResult = Apollo.QueryResult<
  ImportLogsQuery,
  ImportLogsQueryVariables
>;
export const ImportStatusTypeDocument = gql`
  query ImportStatusType {
    __type(name: "ImportStatusType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type ImportStatusTypeQueryResult = Apollo.QueryResult<
  ImportStatusTypeQuery,
  ImportStatusTypeQueryVariables
>;
export const ImportsDocument = gql`
  query Imports(
    $page: Int
    $pageSize: Int
    $filter: ImportsFilterInput
    $sort: ImportsSortInput
  ) {
    imports(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Import
      }
    }
  }
  ${ImportFragmentDoc}
`;
export type ImportsQueryResult = Apollo.QueryResult<
  ImportsQuery,
  ImportsQueryVariables
>;
export const ImportDocument = gql`
  query Import($id: ID!) {
    import(id: $id) {
      ...Import
      missingRequiredEntities
    }
  }
  ${ImportFragmentDoc}
`;
export type ImportQueryResult = Apollo.QueryResult<
  ImportQuery,
  ImportQueryVariables
>;
export const ImportCreateDocument = gql`
  mutation ImportCreate($fields: ImportFields!) {
    importCreate(fields: $fields) {
      result {
        ...Import
      }
      errors {
        ...Error
      }
    }
  }
  ${ImportFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ImportCreateMutationResult =
  Apollo.MutationResult<ImportCreateMutation>;
export type ImportCreateMutationOptions = Apollo.BaseMutationOptions<
  ImportCreateMutation,
  ImportCreateMutationVariables
>;
export const ImportUpdateDocument = gql`
  mutation ImportUpdate($id: ID!, $changes: ImportChanges!) {
    importUpdate(id: $id, changes: $changes) {
      result {
        ...Import
      }
      errors {
        ...Error
      }
    }
  }
  ${ImportFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ImportUpdateMutationResult =
  Apollo.MutationResult<ImportUpdateMutation>;
export type ImportUpdateMutationOptions = Apollo.BaseMutationOptions<
  ImportUpdateMutation,
  ImportUpdateMutationVariables
>;
export const ImportFireEventDocument = gql`
  mutation ImportFireEvent($id: ID!, $event: ImportEventType!) {
    importFireEvent(id: $id, event: $event) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ImportFireEventMutationResult =
  Apollo.MutationResult<ImportFireEventMutation>;
export type ImportFireEventMutationOptions = Apollo.BaseMutationOptions<
  ImportFireEventMutation,
  ImportFireEventMutationVariables
>;
export const InfrastructureKindsDocument = gql`
  query InfrastructureKinds(
    $filter: InfrastructureKindsFilterInput
    $sort: InfrastructureKindsSortInput
  ) {
    infrastructureKinds(filter: $filter, sort: $sort) {
      totalCount
      items {
        ...InfrastructureKind
      }
    }
  }
  ${InfrastructureKindFragmentDoc}
`;
export type InfrastructureKindsQueryResult = Apollo.QueryResult<
  InfrastructureKindsQuery,
  InfrastructureKindsQueryVariables
>;
export const InfrastructuresDocument = gql`
  query Infrastructures(
    $page: Int
    $pageSize: Int
    $filter: InfrastructuresFilterInput
    $sort: InfrastructuresSortInput
    $fetchActions: Boolean = true
  ) {
    infrastructures(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...Infrastructure
        mayDiscard @include(if: $fetchActions)
        mayDestroy @include(if: $fetchActions)
      }
    }
  }
  ${InfrastructureFragmentDoc}
`;
export type InfrastructuresQueryResult = Apollo.QueryResult<
  InfrastructuresQuery,
  InfrastructuresQueryVariables
>;
export const InfrastructureDocument = gql`
  query Infrastructure($id: ID!) {
    infrastructure(id: $id) {
      ...Infrastructure
    }
  }
  ${InfrastructureFragmentDoc}
`;
export type InfrastructureQueryResult = Apollo.QueryResult<
  InfrastructureQuery,
  InfrastructureQueryVariables
>;
export const InfrastructureCreateDocument = gql`
  mutation InfrastructureCreate($fields: InfrastructureFields!) {
    infrastructureCreate(fields: $fields) {
      result {
        ...Infrastructure
      }
      errors {
        ...Error
      }
    }
  }
  ${InfrastructureFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type InfrastructureCreateMutationResult =
  Apollo.MutationResult<InfrastructureCreateMutation>;
export type InfrastructureCreateMutationOptions = Apollo.BaseMutationOptions<
  InfrastructureCreateMutation,
  InfrastructureCreateMutationVariables
>;
export const InfrastructureUpdateDocument = gql`
  mutation InfrastructureUpdate($id: ID!, $changes: InfrastructureChanges!) {
    infrastructureUpdate(id: $id, changes: $changes) {
      result {
        ...Infrastructure
      }
      errors {
        ...Error
      }
    }
  }
  ${InfrastructureFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type InfrastructureUpdateMutationResult =
  Apollo.MutationResult<InfrastructureUpdateMutation>;
export type InfrastructureUpdateMutationOptions = Apollo.BaseMutationOptions<
  InfrastructureUpdateMutation,
  InfrastructureUpdateMutationVariables
>;
export const InfrastructureDiscardDocument = gql`
  mutation InfrastructureDiscard($id: ID!) {
    infrastructureDiscard(id: $id) {
      result {
        ...Infrastructure
      }
      errors {
        ...Error
      }
    }
  }
  ${InfrastructureFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type InfrastructureDiscardMutationResult =
  Apollo.MutationResult<InfrastructureDiscardMutation>;
export type InfrastructureDiscardMutationOptions = Apollo.BaseMutationOptions<
  InfrastructureDiscardMutation,
  InfrastructureDiscardMutationVariables
>;
export const InfrastructureDestroyDocument = gql`
  mutation InfrastructureDestroy($id: ID!, $dryRun: Boolean) {
    infrastructureDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type InfrastructureDestroyMutationResult =
  Apollo.MutationResult<InfrastructureDestroyMutation>;
export type InfrastructureDestroyMutationOptions = Apollo.BaseMutationOptions<
  InfrastructureDestroyMutation,
  InfrastructureDestroyMutationVariables
>;
export const JobStatusDocument = gql`
  query JobStatus($jobId: ID!) {
    jobStatus(jobId: $jobId)
  }
`;
export type JobStatusQueryResult = Apollo.QueryResult<
  JobStatusQuery,
  JobStatusQueryVariables
>;
export const LeaveKindDocument = gql`
  query LeaveKind {
    __type(name: "LeaveKind") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type LeaveKindQueryResult = Apollo.QueryResult<
  LeaveKindQuery,
  LeaveKindQueryVariables
>;
export const LeaveTypesDocument = gql`
  query LeaveTypes(
    $page: Int
    $pageSize: Int
    $filter: LeaveTypesFilterInput
    $sort: LeaveTypesSortInput
  ) {
    leaveTypes(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...LeaveType
      }
    }
  }
  ${LeaveTypeFragmentDoc}
`;
export type LeaveTypesQueryResult = Apollo.QueryResult<
  LeaveTypesQuery,
  LeaveTypesQueryVariables
>;
export const LeaveTypeDocument = gql`
  query LeaveType($id: ID!) {
    leaveType(id: $id) {
      ...LeaveType
    }
  }
  ${LeaveTypeFragmentDoc}
`;
export type LeaveTypeQueryResult = Apollo.QueryResult<
  LeaveTypeQuery,
  LeaveTypeQueryVariables
>;
export const LeaveTypeCreateDocument = gql`
  mutation LeaveTypeCreate($fields: LeaveTypeFields!) {
    leaveTypeCreate(fields: $fields) {
      result {
        ...LeaveType
      }
      errors {
        ...Error
      }
    }
  }
  ${LeaveTypeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type LeaveTypeCreateMutationResult =
  Apollo.MutationResult<LeaveTypeCreateMutation>;
export type LeaveTypeCreateMutationOptions = Apollo.BaseMutationOptions<
  LeaveTypeCreateMutation,
  LeaveTypeCreateMutationVariables
>;
export const LeaveTypeUpdateDocument = gql`
  mutation LeaveTypeUpdate($id: ID!, $changes: LeaveTypeChanges!) {
    leaveTypeUpdate(id: $id, changes: $changes) {
      result {
        ...LeaveType
      }
      errors {
        ...Error
      }
    }
  }
  ${LeaveTypeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type LeaveTypeUpdateMutationResult =
  Apollo.MutationResult<LeaveTypeUpdateMutation>;
export type LeaveTypeUpdateMutationOptions = Apollo.BaseMutationOptions<
  LeaveTypeUpdateMutation,
  LeaveTypeUpdateMutationVariables
>;
export const LeaveTypeDiscardDocument = gql`
  mutation LeaveTypeDiscard($id: ID!) {
    leaveTypeDiscard(id: $id) {
      result {
        ...LeaveType
      }
      errors {
        ...Error
      }
    }
  }
  ${LeaveTypeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type LeaveTypeDiscardMutationResult =
  Apollo.MutationResult<LeaveTypeDiscardMutation>;
export type LeaveTypeDiscardMutationOptions = Apollo.BaseMutationOptions<
  LeaveTypeDiscardMutation,
  LeaveTypeDiscardMutationVariables
>;
export const LocalitiesDocument = gql`
  query Localities(
    $page: Int
    $pageSize: Int
    $filter: LocalitiesFilterInput
    $sort: LocalitiesSortInput
    $index: Boolean = false
  ) {
    localities(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Locality
        mayDiscard @include(if: $index)
        mayDestroy @include(if: $index)
      }
    }
  }
  ${LocalityFragmentDoc}
`;
export type LocalitiesQueryResult = Apollo.QueryResult<
  LocalitiesQuery,
  LocalitiesQueryVariables
>;
export const LocalityDocument = gql`
  query Locality($id: ID!) {
    locality(id: $id) {
      ...Locality
    }
  }
  ${LocalityFragmentDoc}
`;
export type LocalityQueryResult = Apollo.QueryResult<
  LocalityQuery,
  LocalityQueryVariables
>;
export const LocalityCreateDocument = gql`
  mutation LocalityCreate($fields: LocalityFields!) {
    localityCreate(fields: $fields) {
      result {
        ...Locality
      }
      errors {
        ...Error
      }
    }
  }
  ${LocalityFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type LocalityCreateMutationResult =
  Apollo.MutationResult<LocalityCreateMutation>;
export type LocalityCreateMutationOptions = Apollo.BaseMutationOptions<
  LocalityCreateMutation,
  LocalityCreateMutationVariables
>;
export const LocalityUpdateDocument = gql`
  mutation LocalityUpdate($id: ID!, $changes: LocalityChanges!) {
    localityUpdate(id: $id, changes: $changes) {
      result {
        ...Locality
      }
      errors {
        ...Error
      }
    }
  }
  ${LocalityFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type LocalityUpdateMutationResult =
  Apollo.MutationResult<LocalityUpdateMutation>;
export type LocalityUpdateMutationOptions = Apollo.BaseMutationOptions<
  LocalityUpdateMutation,
  LocalityUpdateMutationVariables
>;
export const LocalityDiscardDocument = gql`
  mutation LocalityDiscard($id: ID!) {
    localityDiscard(id: $id) {
      result {
        ...Locality
      }
      errors {
        ...Error
      }
    }
  }
  ${LocalityFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type LocalityDiscardMutationResult =
  Apollo.MutationResult<LocalityDiscardMutation>;
export type LocalityDiscardMutationOptions = Apollo.BaseMutationOptions<
  LocalityDiscardMutation,
  LocalityDiscardMutationVariables
>;
export const LocalityDestroyDocument = gql`
  mutation LocalityDestroy($id: ID!, $dryRun: Boolean) {
    localityDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type LocalityDestroyMutationResult =
  Apollo.MutationResult<LocalityDestroyMutation>;
export type LocalityDestroyMutationOptions = Apollo.BaseMutationOptions<
  LocalityDestroyMutation,
  LocalityDestroyMutationVariables
>;
export const MachineCategoryDocument = gql`
  query MachineCategory {
    __type(name: "MachineCategory") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type MachineCategoryQueryResult = Apollo.QueryResult<
  MachineCategoryQuery,
  MachineCategoryQueryVariables
>;
export const MachineKindsDocument = gql`
  query MachineKinds(
    $page: Int
    $pageSize: Int
    $filter: MachineKindsFilterInput
    $sort: MachineKindsSortInput
  ) {
    machineKinds(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...MachineKind
      }
    }
  }
  ${MachineKindFragmentDoc}
`;
export type MachineKindsQueryResult = Apollo.QueryResult<
  MachineKindsQuery,
  MachineKindsQueryVariables
>;
export const MachineKindDocument = gql`
  query MachineKind($id: ID!) {
    machineKind(id: $id) {
      ...MachineKind
    }
  }
  ${MachineKindFragmentDoc}
`;
export type MachineKindQueryResult = Apollo.QueryResult<
  MachineKindQuery,
  MachineKindQueryVariables
>;
export const MachineKindCreateDocument = gql`
  mutation MachineKindCreate($fields: MachineKindFields!) {
    machineKindCreate(fields: $fields) {
      result {
        ...MachineKind
      }
      errors {
        ...Error
      }
    }
  }
  ${MachineKindFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MachineKindCreateMutationResult =
  Apollo.MutationResult<MachineKindCreateMutation>;
export type MachineKindCreateMutationOptions = Apollo.BaseMutationOptions<
  MachineKindCreateMutation,
  MachineKindCreateMutationVariables
>;
export const MachineKindUpdateDocument = gql`
  mutation MachineKindUpdate($id: ID!, $changes: MachineKindChanges!) {
    machineKindUpdate(id: $id, changes: $changes) {
      result {
        ...MachineKind
      }
      errors {
        ...Error
      }
    }
  }
  ${MachineKindFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MachineKindUpdateMutationResult =
  Apollo.MutationResult<MachineKindUpdateMutation>;
export type MachineKindUpdateMutationOptions = Apollo.BaseMutationOptions<
  MachineKindUpdateMutation,
  MachineKindUpdateMutationVariables
>;
export const MachineKindDiscardDocument = gql`
  mutation MachineKindDiscard($id: ID!) {
    machineKindDiscard(id: $id) {
      result {
        ...MachineKind
      }
      errors {
        ...Error
      }
    }
  }
  ${MachineKindFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MachineKindDiscardMutationResult =
  Apollo.MutationResult<MachineKindDiscardMutation>;
export type MachineKindDiscardMutationOptions = Apollo.BaseMutationOptions<
  MachineKindDiscardMutation,
  MachineKindDiscardMutationVariables
>;
export const MachineKindUnlockDocument = gql`
  mutation MachineKindUnlock($id: ID!) {
    machineKindUnlock(id: $id) {
      result {
        ...MachineKind
      }
      errors {
        ...Error
      }
    }
  }
  ${MachineKindFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MachineKindUnlockMutationResult =
  Apollo.MutationResult<MachineKindUnlockMutation>;
export type MachineKindUnlockMutationOptions = Apollo.BaseMutationOptions<
  MachineKindUnlockMutation,
  MachineKindUnlockMutationVariables
>;
export const FuelTypesDocument = gql`
  query FuelTypes {
    __type(name: "FuelType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type FuelTypesQueryResult = Apollo.QueryResult<
  FuelTypesQuery,
  FuelTypesQueryVariables
>;
export const MachinesDocument = gql`
  query Machines(
    $page: Int
    $pageSize: Int
    $filter: MachinesFilterInput
    $sort: MachinesSortInput
    $fetchCostCenter: Boolean = false
    $localityId: ID
  ) {
    machines(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Machine
        costCenter(localityId: $localityId) @include(if: $fetchCostCenter) {
          ...CostCenterShort
        }
      }
    }
  }
  ${MachineFragmentDoc}
  ${CostCenterShortFragmentDoc}
`;
export type MachinesQueryResult = Apollo.QueryResult<
  MachinesQuery,
  MachinesQueryVariables
>;
export const MachineDocument = gql`
  query Machine($id: ID!) {
    machine(id: $id) {
      ...MachineDetails
    }
  }
  ${MachineDetailsFragmentDoc}
`;
export type MachineQueryResult = Apollo.QueryResult<
  MachineQuery,
  MachineQueryVariables
>;
export const MachineCreateDocument = gql`
  mutation MachineCreate($fields: MachineFields!) {
    machineCreate(fields: $fields) {
      result {
        ...MachineDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${MachineDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MachineCreateMutationResult =
  Apollo.MutationResult<MachineCreateMutation>;
export type MachineCreateMutationOptions = Apollo.BaseMutationOptions<
  MachineCreateMutation,
  MachineCreateMutationVariables
>;
export const MachineUpdateDocument = gql`
  mutation MachineUpdate($id: ID!, $changes: MachineChanges!) {
    machineUpdate(id: $id, changes: $changes) {
      result {
        ...MachineDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${MachineDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MachineUpdateMutationResult =
  Apollo.MutationResult<MachineUpdateMutation>;
export type MachineUpdateMutationOptions = Apollo.BaseMutationOptions<
  MachineUpdateMutation,
  MachineUpdateMutationVariables
>;
export const MachineDiscardDocument = gql`
  mutation MachineDiscard($id: ID!) {
    machineDiscard(id: $id) {
      result {
        ...MachineDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${MachineDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type MachineDiscardMutationResult =
  Apollo.MutationResult<MachineDiscardMutation>;
export type MachineDiscardMutationOptions = Apollo.BaseMutationOptions<
  MachineDiscardMutation,
  MachineDiscardMutationVariables
>;
export const MachineDestroyDocument = gql`
  mutation MachineDestroy($id: ID!, $dryRun: Boolean) {
    machineDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type MachineDestroyMutationResult =
  Apollo.MutationResult<MachineDestroyMutation>;
export type MachineDestroyMutationOptions = Apollo.BaseMutationOptions<
  MachineDestroyMutation,
  MachineDestroyMutationVariables
>;
export const OnboardingStatusDocument = gql`
  query OnboardingStatus {
    onboardingStatus {
      status {
        finished
        step
        permission
      }
    }
  }
`;
export type OnboardingStatusQueryResult = Apollo.QueryResult<
  OnboardingStatusQuery,
  OnboardingStatusQueryVariables
>;
export const PositionTypeDocument = gql`
  query PositionType {
    __type(name: "PositionType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type PositionTypeQueryResult = Apollo.QueryResult<
  PositionTypeQuery,
  PositionTypeQueryVariables
>;
export const PositionsDocument = gql`
  query Positions(
    $page: Int
    $pageSize: Int
    $filter: PositionsFilterInput
    $sort: PositionsSortInput
    $showWage: Boolean = false
  ) {
    positions(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Position
      }
    }
  }
  ${PositionFragmentDoc}
`;
export type PositionsQueryResult = Apollo.QueryResult<
  PositionsQuery,
  PositionsQueryVariables
>;
export const PositionDocument = gql`
  query Position($id: ID!, $showWage: Boolean = false) {
    position(id: $id) {
      ...Position
    }
  }
  ${PositionFragmentDoc}
`;
export type PositionQueryResult = Apollo.QueryResult<
  PositionQuery,
  PositionQueryVariables
>;
export const PositionCreateDocument = gql`
  mutation PositionCreate(
    $fields: PositionFields!
    $showWage: Boolean = false
  ) {
    positionCreate(fields: $fields) {
      result {
        ...Position
      }
      errors {
        ...Error
      }
    }
  }
  ${PositionFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PositionCreateMutationResult =
  Apollo.MutationResult<PositionCreateMutation>;
export type PositionCreateMutationOptions = Apollo.BaseMutationOptions<
  PositionCreateMutation,
  PositionCreateMutationVariables
>;
export const PositionUpdateDocument = gql`
  mutation PositionUpdate(
    $id: ID!
    $changes: PositionChanges!
    $showWage: Boolean = false
  ) {
    positionUpdate(id: $id, changes: $changes) {
      result {
        ...Position
      }
      errors {
        ...Error
      }
    }
  }
  ${PositionFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PositionUpdateMutationResult =
  Apollo.MutationResult<PositionUpdateMutation>;
export type PositionUpdateMutationOptions = Apollo.BaseMutationOptions<
  PositionUpdateMutation,
  PositionUpdateMutationVariables
>;
export const PositionDiscardDocument = gql`
  mutation PositionDiscard($id: ID!, $showWage: Boolean = false) {
    positionDiscard(id: $id) {
      result {
        ...Position
      }
      errors {
        ...Error
      }
    }
  }
  ${PositionFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PositionDiscardMutationResult =
  Apollo.MutationResult<PositionDiscardMutation>;
export type PositionDiscardMutationOptions = Apollo.BaseMutationOptions<
  PositionDiscardMutation,
  PositionDiscardMutationVariables
>;
export const PositionDestroyDocument = gql`
  mutation PositionDestroy($id: ID!, $dryRun: Boolean) {
    positionDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type PositionDestroyMutationResult =
  Apollo.MutationResult<PositionDestroyMutation>;
export type PositionDestroyMutationOptions = Apollo.BaseMutationOptions<
  PositionDestroyMutation,
  PositionDestroyMutationVariables
>;
export const RolesDocument = gql`
  query Roles(
    $page: Int
    $pageSize: Int
    $filter: RolesFilterInput
    $sort: RolesSortInput
    $public: Boolean = false
  ) {
    roles(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...RoleShort
        ...RoleAuthorized @skip(if: $public)
      }
      totalCount
    }
  }
  ${RoleShortFragmentDoc}
  ${RoleAuthorizedFragmentDoc}
`;
export type RolesQueryResult = Apollo.QueryResult<
  RolesQuery,
  RolesQueryVariables
>;
export const RoleDocument = gql`
  query Role($id: ID!) {
    role(id: $id) {
      ...Role
    }
  }
  ${RoleFragmentDoc}
`;
export type RoleQueryResult = Apollo.QueryResult<RoleQuery, RoleQueryVariables>;
export const RoleCreateDocument = gql`
  mutation RoleCreate($fields: RoleFields!) {
    roleCreate(fields: $fields) {
      result {
        ...Role
      }
      errors {
        ...Error
      }
    }
  }
  ${RoleFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type RoleCreateMutationResult =
  Apollo.MutationResult<RoleCreateMutation>;
export type RoleCreateMutationOptions = Apollo.BaseMutationOptions<
  RoleCreateMutation,
  RoleCreateMutationVariables
>;
export const RoleUpdateDocument = gql`
  mutation RoleUpdate($id: ID!, $changes: RoleChanges!) {
    roleUpdate(id: $id, changes: $changes) {
      result {
        ...Role
      }
      errors {
        ...Error
      }
    }
  }
  ${RoleFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type RoleUpdateMutationResult =
  Apollo.MutationResult<RoleUpdateMutation>;
export type RoleUpdateMutationOptions = Apollo.BaseMutationOptions<
  RoleUpdateMutation,
  RoleUpdateMutationVariables
>;
export const RoleDiscardDocument = gql`
  mutation RoleDiscard($id: ID!) {
    roleDiscard(id: $id) {
      result {
        ...Role
      }
      errors {
        ...Error
      }
    }
  }
  ${RoleFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type RoleDiscardMutationResult =
  Apollo.MutationResult<RoleDiscardMutation>;
export type RoleDiscardMutationOptions = Apollo.BaseMutationOptions<
  RoleDiscardMutation,
  RoleDiscardMutationVariables
>;
export const TagsDocument = gql`
  query Tags(
    $page: Int
    $pageSize: Int
    $filter: TagsFilterInput
    $sort: TagsSortInput
  ) {
    tags(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Tag
      }
    }
  }
  ${TagFragmentDoc}
`;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const TenantsDocument = gql`
  query Tenants(
    $page: Int
    $pageSize: Int
    $filter: TenantsFilterInput
    $sort: TenantsSortInput
  ) {
    tenants(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Tenant
      }
      totalCount
    }
  }
  ${TenantFragmentDoc}
`;
export type TenantsQueryResult = Apollo.QueryResult<
  TenantsQuery,
  TenantsQueryVariables
>;
export const TenantStatsDocument = gql`
  query TenantStats(
    $page: Int
    $pageSize: Int
    $filter: TenantsFilterInput
    $sort: TenantsSortInput
  ) {
    tenants(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Tenant
        stats {
          lastSignIn
          workOrderCount
          lastWorkOrderCreatedAt
        }
      }
      totalCount
    }
  }
  ${TenantFragmentDoc}
`;
export type TenantStatsQueryResult = Apollo.QueryResult<
  TenantStatsQuery,
  TenantStatsQueryVariables
>;
export const TenantDocument = gql`
  query Tenant(
    $id: ID!
    $withLocalities: Boolean = true
    $accountingSettings: Boolean = false
    $bankAccounts: Boolean = false
  ) {
    tenant(id: $id) {
      ...TenantFormValue
    }
  }
  ${TenantFormValueFragmentDoc}
`;
export type TenantQueryResult = Apollo.QueryResult<
  TenantQuery,
  TenantQueryVariables
>;
export const TenantCreateDocument = gql`
  mutation TenantCreate($fields: TenantFields!) {
    tenantCreate(fields: $fields) {
      result {
        ...Tenant
      }
      errors {
        ...Error
      }
    }
  }
  ${TenantFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TenantCreateMutationResult =
  Apollo.MutationResult<TenantCreateMutation>;
export type TenantCreateMutationOptions = Apollo.BaseMutationOptions<
  TenantCreateMutation,
  TenantCreateMutationVariables
>;
export const TenantUpdateDocument = gql`
  mutation TenantUpdate($id: ID!, $changes: TenantChanges!) {
    tenantUpdate(id: $id, changes: $changes) {
      result {
        ...Tenant
      }
      errors {
        ...Error
      }
    }
  }
  ${TenantFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TenantUpdateMutationResult =
  Apollo.MutationResult<TenantUpdateMutation>;
export type TenantUpdateMutationOptions = Apollo.BaseMutationOptions<
  TenantUpdateMutation,
  TenantUpdateMutationVariables
>;
export const TenantDiscardDocument = gql`
  mutation TenantDiscard($id: ID!) {
    tenantDiscard(id: $id) {
      result {
        ...Tenant
      }
      errors {
        ...Error
      }
    }
  }
  ${TenantFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TenantDiscardMutationResult =
  Apollo.MutationResult<TenantDiscardMutation>;
export type TenantDiscardMutationOptions = Apollo.BaseMutationOptions<
  TenantDiscardMutation,
  TenantDiscardMutationVariables
>;
export const TenantCloneDocument = gql`
  mutation TenantClone($reset: Boolean = false) {
    tenantClone(reset: $reset) {
      jobId
    }
  }
`;
export type TenantCloneMutationResult =
  Apollo.MutationResult<TenantCloneMutation>;
export type TenantCloneMutationOptions = Apollo.BaseMutationOptions<
  TenantCloneMutation,
  TenantCloneMutationVariables
>;
export const GenerateBillingPortalLinkDocument = gql`
  mutation GenerateBillingPortalLink {
    generateBillingPortalLink {
      link
    }
  }
`;
export type GenerateBillingPortalLinkMutationResult =
  Apollo.MutationResult<GenerateBillingPortalLinkMutation>;
export type GenerateBillingPortalLinkMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateBillingPortalLinkMutation,
    GenerateBillingPortalLinkMutationVariables
  >;
export const UnitTypeDocument = gql`
  query UnitType {
    __type(name: "UnitType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type UnitTypeQueryResult = Apollo.QueryResult<
  UnitTypeQuery,
  UnitTypeQueryVariables
>;
export const UnitsDocument = gql`
  query Units(
    $page: Int
    $pageSize: Int
    $filter: UnitsFilterInput
    $sort: UnitsSortInput
  ) {
    units(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Unit
      }
      totalCount
    }
  }
  ${UnitFragmentDoc}
`;
export type UnitsQueryResult = Apollo.QueryResult<
  UnitsQuery,
  UnitsQueryVariables
>;
export const UnitDocument = gql`
  query Unit($id: ID!) {
    unit(id: $id) {
      ...Unit
    }
  }
  ${UnitFragmentDoc}
`;
export type UnitQueryResult = Apollo.QueryResult<UnitQuery, UnitQueryVariables>;
export const UnitCreateDocument = gql`
  mutation UnitCreate($fields: UnitFields!) {
    unitCreate(fields: $fields) {
      result {
        ...Unit
      }
      errors {
        ...Error
      }
    }
  }
  ${UnitFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UnitCreateMutationResult =
  Apollo.MutationResult<UnitCreateMutation>;
export type UnitCreateMutationOptions = Apollo.BaseMutationOptions<
  UnitCreateMutation,
  UnitCreateMutationVariables
>;
export const UnitUpdateDocument = gql`
  mutation UnitUpdate($id: ID!, $changes: UnitChanges!) {
    unitUpdate(id: $id, changes: $changes) {
      result {
        ...Unit
      }
      errors {
        ...Error
      }
    }
  }
  ${UnitFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UnitUpdateMutationResult =
  Apollo.MutationResult<UnitUpdateMutation>;
export type UnitUpdateMutationOptions = Apollo.BaseMutationOptions<
  UnitUpdateMutation,
  UnitUpdateMutationVariables
>;
export const UnitDiscardDocument = gql`
  mutation UnitDiscard($id: ID!) {
    unitDiscard(id: $id) {
      result {
        ...Unit
      }
      errors {
        ...Error
      }
    }
  }
  ${UnitFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UnitDiscardMutationResult =
  Apollo.MutationResult<UnitDiscardMutation>;
export type UnitDiscardMutationOptions = Apollo.BaseMutationOptions<
  UnitDiscardMutation,
  UnitDiscardMutationVariables
>;
export const UnitUnlockDocument = gql`
  mutation UnitUnlock($id: ID!) {
    unitUnlock(id: $id) {
      result {
        ...Unit
      }
      errors {
        ...Error
      }
    }
  }
  ${UnitFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UnitUnlockMutationResult =
  Apollo.MutationResult<UnitUnlockMutation>;
export type UnitUnlockMutationOptions = Apollo.BaseMutationOptions<
  UnitUnlockMutation,
  UnitUnlockMutationVariables
>;
export const MeDocument = gql`
  query Me {
    me {
      ...CurrentUser
    }
  }
  ${CurrentUserFragmentDoc}
`;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const UsersDocument = gql`
  query Users(
    $page: Int
    $pageSize: Int
    $filter: UsersFilterInput
    $sort: UsersSortInput
  ) {
    users(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...User
      }
      totalCount
    }
  }
  ${UserFragmentDoc}
`;
export type UsersQueryResult = Apollo.QueryResult<
  UsersQuery,
  UsersQueryVariables
>;
export const UserOptionsDocument = gql`
  query UserOptions($page: Int, $pageSize: Int, $filter: UsersFilterInput) {
    users(page: $page, pageSize: $pageSize, filter: $filter) {
      items {
        ...UserShort
      }
    }
  }
  ${UserShortFragmentDoc}
`;
export type UserOptionsQueryResult = Apollo.QueryResult<
  UserOptionsQuery,
  UserOptionsQueryVariables
>;
export const UserDocument = gql`
  query User($id: ID!) {
    user(id: $id) {
      ...User
    }
  }
  ${UserFragmentDoc}
`;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const SignInDocument = gql`
  mutation SignIn($email: String!, $password: String!, $rememberMe: Boolean!) {
    signIn(email: $email, password: $password, rememberMe: $rememberMe) {
      user {
        ...CurrentUser
      }
      errors {
        ...Error
      }
    }
  }
  ${CurrentUserFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<
  SignInMutation,
  SignInMutationVariables
>;
export const SignUpDocument = gql`
  mutation SignUp(
    $email: String!
    $password: String!
    $passwordConfirmation: String!
    $countryCode: String!
    $firstName: String!
    $lastName: String!
    $name: String!
    $invitationToken: String
    $tenant: String
  ) {
    signUp(
      email: $email
      firstName: $firstName
      lastName: $lastName
      name: $name
      password: $password
      passwordConfirmation: $passwordConfirmation
      countryCode: $countryCode
      invitationToken: $invitationToken
      tenant: $tenant
    ) {
      user {
        ...CurrentUser
      }
      errors {
        ...Error
      }
    }
  }
  ${CurrentUserFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<
  SignUpMutation,
  SignUpMutationVariables
>;
export const SignOutDocument = gql`
  mutation SignOut {
    signOut {
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SignOutMutationResult = Apollo.MutationResult<SignOutMutation>;
export type SignOutMutationOptions = Apollo.BaseMutationOptions<
  SignOutMutation,
  SignOutMutationVariables
>;
export const SendResetPasswordDocument = gql`
  mutation SendResetPassword($email: String!) {
    sendResetPassword(email: $email) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SendResetPasswordMutationResult =
  Apollo.MutationResult<SendResetPasswordMutation>;
export type SendResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  SendResetPasswordMutation,
  SendResetPasswordMutationVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword(
    $password: String!
    $passwordConfirmation: String!
    $resetPasswordToken: String!
  ) {
    resetPassword(
      password: $password
      passwordConfirmation: $passwordConfirmation
      resetPasswordToken: $resetPasswordToken
    ) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const UserUpdateDocument = gql`
  mutation UserUpdate($id: ID!, $changes: UserChanges!) {
    userUpdate(id: $id, changes: $changes) {
      result {
        ...User
      }
      errors {
        ...Error
      }
    }
  }
  ${UserFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UserUpdateMutationResult =
  Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateMutation,
  UserUpdateMutationVariables
>;
export const UserCreateDocument = gql`
  mutation UserCreate($fields: UserFields!) {
    userCreate(fields: $fields) {
      result {
        ...User
      }
      errors {
        ...Error
      }
    }
  }
  ${UserFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UserCreateMutationResult =
  Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<
  UserCreateMutation,
  UserCreateMutationVariables
>;
export const UserDiscardDocument = gql`
  mutation UserDiscard($id: ID!) {
    userDiscard(id: $id) {
      result {
        ...User
      }
      errors {
        ...Error
      }
    }
  }
  ${UserFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type UserDiscardMutationResult =
  Apollo.MutationResult<UserDiscardMutation>;
export type UserDiscardMutationOptions = Apollo.BaseMutationOptions<
  UserDiscardMutation,
  UserDiscardMutationVariables
>;
export const AcceptInviteDocument = gql`
  mutation AcceptInvite(
    $password: String!
    $passwordConfirmation: String!
    $invitationToken: String!
  ) {
    acceptInvite(
      password: $password
      passwordConfirmation: $passwordConfirmation
      invitationToken: $invitationToken
    ) {
      result {
        ...User
      }
      errors {
        ...Error
      }
    }
  }
  ${UserFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AcceptInviteMutationResult =
  Apollo.MutationResult<AcceptInviteMutation>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;
export const ResendInviteDocument = gql`
  mutation ResendInvite($id: ID!) {
    resendInvite(id: $id) {
      result {
        ...User
      }
      errors {
        ...Error
      }
    }
  }
  ${UserFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ResendInviteMutationResult =
  Apollo.MutationResult<ResendInviteMutation>;
export type ResendInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendInviteMutation,
  ResendInviteMutationVariables
>;
export const GenerateInvitationLinkDocument = gql`
  mutation GenerateInvitationLink($id: ID!) {
    generateInvitationLink(id: $id) {
      link
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GenerateInvitationLinkMutationResult =
  Apollo.MutationResult<GenerateInvitationLinkMutation>;
export type GenerateInvitationLinkMutationOptions = Apollo.BaseMutationOptions<
  GenerateInvitationLinkMutation,
  GenerateInvitationLinkMutationVariables
>;
export const GenerateResetPasswordLinkDocument = gql`
  mutation GenerateResetPasswordLink($id: ID!) {
    generateResetPasswordLink(id: $id) {
      link
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type GenerateResetPasswordLinkMutationResult =
  Apollo.MutationResult<GenerateResetPasswordLinkMutation>;
export type GenerateResetPasswordLinkMutationOptions =
  Apollo.BaseMutationOptions<
    GenerateResetPasswordLinkMutation,
    GenerateResetPasswordLinkMutationVariables
  >;
export const AccountKindsDocument = gql`
  query AccountKinds {
    accountKinds {
      items {
        ...AccountKind
      }
      totalCount
    }
  }
  ${AccountKindFragmentDoc}
`;
export type AccountKindsQueryResult = Apollo.QueryResult<
  AccountKindsQuery,
  AccountKindsQueryVariables
>;
export const AccountsDocument = gql`
  query Accounts(
    $page: Int
    $pageSize: Int
    $filter: AccountsFilterInput
    $sort: AccountsSortInput
  ) {
    accounts(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...Account
      }
      totalCount
    }
  }
  ${AccountFragmentDoc}
`;
export type AccountsQueryResult = Apollo.QueryResult<
  AccountsQuery,
  AccountsQueryVariables
>;
export const AccountOptionsDocument = gql`
  query AccountOptions(
    $page: Int
    $pageSize: Int
    $filter: AccountsFilterInput
    $sort: AccountsSortInput
  ) {
    accounts(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...AccountShort
      }
    }
  }
  ${AccountShortFragmentDoc}
`;
export type AccountOptionsQueryResult = Apollo.QueryResult<
  AccountOptionsQuery,
  AccountOptionsQueryVariables
>;
export const AccountDocument = gql`
  query Account($id: ID!) {
    account(id: $id) {
      ...Account
      subaccounts {
        ...Account
      }
    }
  }
  ${AccountFragmentDoc}
`;
export type AccountQueryResult = Apollo.QueryResult<
  AccountQuery,
  AccountQueryVariables
>;
export const AccountCreateDocument = gql`
  mutation AccountCreate($fields: AccountFields!) {
    accountCreate(fields: $fields) {
      result {
        ...Account
      }
      errors {
        ...Error
      }
    }
  }
  ${AccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AccountCreateMutationResult =
  Apollo.MutationResult<AccountCreateMutation>;
export type AccountCreateMutationOptions = Apollo.BaseMutationOptions<
  AccountCreateMutation,
  AccountCreateMutationVariables
>;
export const AccountUpdateDocument = gql`
  mutation AccountUpdate($id: ID!, $changes: AccountChanges!) {
    accountUpdate(id: $id, changes: $changes) {
      result {
        ...Account
      }
      errors {
        ...Error
      }
    }
  }
  ${AccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AccountUpdateMutationResult =
  Apollo.MutationResult<AccountUpdateMutation>;
export type AccountUpdateMutationOptions = Apollo.BaseMutationOptions<
  AccountUpdateMutation,
  AccountUpdateMutationVariables
>;
export const AccountDiscardDocument = gql`
  mutation AccountDiscard($id: ID!) {
    accountDiscard(id: $id) {
      result {
        ...Account
      }
      errors {
        ...Error
      }
    }
  }
  ${AccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AccountDiscardMutationResult =
  Apollo.MutationResult<AccountDiscardMutation>;
export type AccountDiscardMutationOptions = Apollo.BaseMutationOptions<
  AccountDiscardMutation,
  AccountDiscardMutationVariables
>;
export const AccountDestroyDocument = gql`
  mutation AccountDestroy($id: ID!, $dryRun: Boolean) {
    accountDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AccountDestroyMutationResult =
  Apollo.MutationResult<AccountDestroyMutation>;
export type AccountDestroyMutationOptions = Apollo.BaseMutationOptions<
  AccountDestroyMutation,
  AccountDestroyMutationVariables
>;
export const BankAccountCreateDocument = gql`
  mutation BankAccountCreate($fields: BankAccountFields!) {
    bankAccountCreate(fields: $fields) {
      result {
        ...BankAccount
      }
      errors {
        ...Error
      }
    }
  }
  ${BankAccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BankAccountCreateMutationResult =
  Apollo.MutationResult<BankAccountCreateMutation>;
export type BankAccountCreateMutationOptions = Apollo.BaseMutationOptions<
  BankAccountCreateMutation,
  BankAccountCreateMutationVariables
>;
export const BankAccountUpdateDocument = gql`
  mutation BankAccountUpdate($id: ID!, $changes: BankAccountChanges!) {
    bankAccountUpdate(id: $id, changes: $changes) {
      result {
        ...BankAccount
      }
      errors {
        ...Error
      }
    }
  }
  ${BankAccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BankAccountUpdateMutationResult =
  Apollo.MutationResult<BankAccountUpdateMutation>;
export type BankAccountUpdateMutationOptions = Apollo.BaseMutationOptions<
  BankAccountUpdateMutation,
  BankAccountUpdateMutationVariables
>;
export const BankAccountDiscardDocument = gql`
  mutation BankAccountDiscard($id: ID!) {
    bankAccountDiscard(id: $id) {
      result {
        ...BankAccount
      }
      errors {
        ...Error
      }
    }
  }
  ${BankAccountFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BankAccountDiscardMutationResult =
  Apollo.MutationResult<BankAccountDiscardMutation>;
export type BankAccountDiscardMutationOptions = Apollo.BaseMutationOptions<
  BankAccountDiscardMutation,
  BankAccountDiscardMutationVariables
>;
export const BankAccountDestroyDocument = gql`
  mutation BankAccountDestroy($id: ID!, $dryRun: Boolean) {
    bankAccountDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type BankAccountDestroyMutationResult =
  Apollo.MutationResult<BankAccountDestroyMutation>;
export type BankAccountDestroyMutationOptions = Apollo.BaseMutationOptions<
  BankAccountDestroyMutation,
  BankAccountDestroyMutationVariables
>;
export const BanksDocument = gql`
  query Banks(
    $page: Int
    $pageSize: Int
    $filter: BanksFilterInput
    $sort: BanksSortInput
  ) {
    banks(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Bank
      }
    }
  }
  ${BankFragmentDoc}
`;
export type BanksQueryResult = Apollo.QueryResult<
  BanksQuery,
  BanksQueryVariables
>;
export const BankCreateDocument = gql`
  mutation BankCreate($fields: BankFields!) {
    bankCreate(fields: $fields) {
      result {
        ...Bank
      }
      errors {
        ...Error
      }
    }
  }
  ${BankFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BankCreateMutationResult =
  Apollo.MutationResult<BankCreateMutation>;
export type BankCreateMutationOptions = Apollo.BaseMutationOptions<
  BankCreateMutation,
  BankCreateMutationVariables
>;
export const BankUpdateDocument = gql`
  mutation BankUpdate($id: ID!, $changes: BankChanges!) {
    bankUpdate(id: $id, changes: $changes) {
      result {
        ...Bank
      }
      errors {
        ...Error
      }
    }
  }
  ${BankFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BankUpdateMutationResult =
  Apollo.MutationResult<BankUpdateMutation>;
export type BankUpdateMutationOptions = Apollo.BaseMutationOptions<
  BankUpdateMutation,
  BankUpdateMutationVariables
>;
export const BankDiscardDocument = gql`
  mutation BankDiscard($id: ID!) {
    bankDiscard(id: $id) {
      result {
        ...Bank
      }
      errors {
        ...Error
      }
    }
  }
  ${BankFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BankDiscardMutationResult =
  Apollo.MutationResult<BankDiscardMutation>;
export type BankDiscardMutationOptions = Apollo.BaseMutationOptions<
  BankDiscardMutation,
  BankDiscardMutationVariables
>;
export const BankDestroyDocument = gql`
  mutation BankDestroy($id: ID!, $dryRun: Boolean) {
    bankDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type BankDestroyMutationResult =
  Apollo.MutationResult<BankDestroyMutation>;
export type BankDestroyMutationOptions = Apollo.BaseMutationOptions<
  BankDestroyMutation,
  BankDestroyMutationVariables
>;
export const BankUnlockDocument = gql`
  mutation BankUnlock($id: ID!) {
    bankUnlock(id: $id) {
      result {
        ...Bank
      }
      errors {
        ...Error
      }
    }
  }
  ${BankFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type BankUnlockMutationResult =
  Apollo.MutationResult<BankUnlockMutation>;
export type BankUnlockMutationOptions = Apollo.BaseMutationOptions<
  BankUnlockMutation,
  BankUnlockMutationVariables
>;
export const ProfitableTypeDocument = gql`
  query ProfitableType {
    __type(name: "ProfitableType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type ProfitableTypeQueryResult = Apollo.QueryResult<
  ProfitableTypeQuery,
  ProfitableTypeQueryVariables
>;
export const CostCentersDocument = gql`
  query CostCenters(
    $page: Int
    $pageSize: Int
    $filter: CostCentersFilterInput
    $sort: CostCentersSortInput
  ) {
    costCenters(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...CostCenterTree
      }
      totalCount
    }
  }
  ${CostCenterTreeFragmentDoc}
`;
export type CostCentersQueryResult = Apollo.QueryResult<
  CostCentersQuery,
  CostCentersQueryVariables
>;
export const CostCenterDocument = gql`
  query CostCenter($id: ID!) {
    costCenter(id: $id) {
      ...CostCenter
      subcostCenters {
        ...CostCenter
      }
    }
  }
  ${CostCenterFragmentDoc}
`;
export type CostCenterQueryResult = Apollo.QueryResult<
  CostCenterQuery,
  CostCenterQueryVariables
>;
export const CostCenterCreateDocument = gql`
  mutation CostCenterCreate($fields: CostCenterFields!) {
    costCenterCreate(fields: $fields) {
      result {
        ...CostCenterTree
      }
      errors {
        ...Error
      }
    }
  }
  ${CostCenterTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CostCenterCreateMutationResult =
  Apollo.MutationResult<CostCenterCreateMutation>;
export type CostCenterCreateMutationOptions = Apollo.BaseMutationOptions<
  CostCenterCreateMutation,
  CostCenterCreateMutationVariables
>;
export const CostCenterUpdateDocument = gql`
  mutation CostCenterUpdate($id: ID!, $changes: CostCenterChanges!) {
    costCenterUpdate(id: $id, changes: $changes) {
      result {
        ...CostCenterTree
      }
      errors {
        ...Error
      }
    }
  }
  ${CostCenterTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CostCenterUpdateMutationResult =
  Apollo.MutationResult<CostCenterUpdateMutation>;
export type CostCenterUpdateMutationOptions = Apollo.BaseMutationOptions<
  CostCenterUpdateMutation,
  CostCenterUpdateMutationVariables
>;
export const CostCenterDiscardDocument = gql`
  mutation CostCenterDiscard($id: ID!) {
    costCenterDiscard(id: $id) {
      result {
        ...CostCenterTree
      }
      errors {
        ...Error
      }
    }
  }
  ${CostCenterTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type CostCenterDiscardMutationResult =
  Apollo.MutationResult<CostCenterDiscardMutation>;
export type CostCenterDiscardMutationOptions = Apollo.BaseMutationOptions<
  CostCenterDiscardMutation,
  CostCenterDiscardMutationVariables
>;
export const CostCenterDestroyDocument = gql`
  mutation CostCenterDestroy($id: ID!, $dryRun: Boolean) {
    costCenterDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type CostCenterDestroyMutationResult =
  Apollo.MutationResult<CostCenterDestroyMutation>;
export type CostCenterDestroyMutationOptions = Apollo.BaseMutationOptions<
  CostCenterDestroyMutation,
  CostCenterDestroyMutationVariables
>;
export const ExpenseCategoriesDocument = gql`
  query ExpenseCategories(
    $page: Int
    $pageSize: Int
    $filter: ExpenseCategoriesFilterInput
    $sort: ExpenseCategoriesSortInput
  ) {
    expenseCategories(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...ExpenseCategoryTree
      }
      totalCount
    }
  }
  ${ExpenseCategoryTreeFragmentDoc}
`;
export type ExpenseCategoriesQueryResult = Apollo.QueryResult<
  ExpenseCategoriesQuery,
  ExpenseCategoriesQueryVariables
>;
export const ExpenseCategoryDocument = gql`
  query ExpenseCategory($id: ID!) {
    expenseCategory(id: $id) {
      ...ExpenseCategory
      subcategories {
        ...ExpenseCategory
      }
    }
  }
  ${ExpenseCategoryFragmentDoc}
`;
export type ExpenseCategoryQueryResult = Apollo.QueryResult<
  ExpenseCategoryQuery,
  ExpenseCategoryQueryVariables
>;
export const ExpenseCategoryCreateDocument = gql`
  mutation ExpenseCategoryCreate($fields: ExpenseCategoryFields!) {
    expenseCategoryCreate(fields: $fields) {
      result {
        ...ExpenseCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ExpenseCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ExpenseCategoryCreateMutationResult =
  Apollo.MutationResult<ExpenseCategoryCreateMutation>;
export type ExpenseCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  ExpenseCategoryCreateMutation,
  ExpenseCategoryCreateMutationVariables
>;
export const ExpenseCategoryUpdateDocument = gql`
  mutation ExpenseCategoryUpdate($id: ID!, $changes: ExpenseCategoryChanges!) {
    expenseCategoryUpdate(id: $id, changes: $changes) {
      result {
        ...ExpenseCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ExpenseCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ExpenseCategoryUpdateMutationResult =
  Apollo.MutationResult<ExpenseCategoryUpdateMutation>;
export type ExpenseCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  ExpenseCategoryUpdateMutation,
  ExpenseCategoryUpdateMutationVariables
>;
export const ExpenseCategoryDiscardDocument = gql`
  mutation ExpenseCategoryDiscard($id: ID!) {
    expenseCategoryDiscard(id: $id) {
      result {
        ...ExpenseCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ExpenseCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ExpenseCategoryDiscardMutationResult =
  Apollo.MutationResult<ExpenseCategoryDiscardMutation>;
export type ExpenseCategoryDiscardMutationOptions = Apollo.BaseMutationOptions<
  ExpenseCategoryDiscardMutation,
  ExpenseCategoryDiscardMutationVariables
>;
export const ExpenseCategoryDestroyDocument = gql`
  mutation ExpenseCategoryDestroy($id: ID!, $dryRun: Boolean) {
    expenseCategoryDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ExpenseCategoryDestroyMutationResult =
  Apollo.MutationResult<ExpenseCategoryDestroyMutation>;
export type ExpenseCategoryDestroyMutationOptions = Apollo.BaseMutationOptions<
  ExpenseCategoryDestroyMutation,
  ExpenseCategoryDestroyMutationVariables
>;
export const ExpenseCategoryUnlockDocument = gql`
  mutation ExpenseCategoryUnlock($id: ID!) {
    expenseCategoryUnlock(id: $id) {
      result {
        ...ExpenseCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ExpenseCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ExpenseCategoryUnlockMutationResult =
  Apollo.MutationResult<ExpenseCategoryUnlockMutation>;
export type ExpenseCategoryUnlockMutationOptions = Apollo.BaseMutationOptions<
  ExpenseCategoryUnlockMutation,
  ExpenseCategoryUnlockMutationVariables
>;
export const ExpenseItemsDocument = gql`
  query ExpenseItems(
    $page: Int
    $pageSize: Int
    $filter: ExpenseItemsFilterInput
    $sort: ExpenseItemsSortInput
  ) {
    expenseItems(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...ExpenseItem
      }
    }
  }
  ${ExpenseItemFragmentDoc}
`;
export type ExpenseItemsQueryResult = Apollo.QueryResult<
  ExpenseItemsQuery,
  ExpenseItemsQueryVariables
>;
export const ExpenseItemDocument = gql`
  query ExpenseItem($id: ID!) {
    expenseItem(id: $id) {
      ...ExpenseItem
    }
  }
  ${ExpenseItemFragmentDoc}
`;
export type ExpenseItemQueryResult = Apollo.QueryResult<
  ExpenseItemQuery,
  ExpenseItemQueryVariables
>;
export const ExpenseItemCreateDocument = gql`
  mutation ExpenseItemCreate($fields: ExpenseItemFields!) {
    expenseItemCreate(fields: $fields) {
      result {
        ...ExpenseItem
      }
      errors {
        ...Error
      }
    }
  }
  ${ExpenseItemFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ExpenseItemCreateMutationResult =
  Apollo.MutationResult<ExpenseItemCreateMutation>;
export type ExpenseItemCreateMutationOptions = Apollo.BaseMutationOptions<
  ExpenseItemCreateMutation,
  ExpenseItemCreateMutationVariables
>;
export const ExpenseItemUpdateDocument = gql`
  mutation ExpenseItemUpdate($id: ID!, $changes: ExpenseItemChanges!) {
    expenseItemUpdate(id: $id, changes: $changes) {
      result {
        ...ExpenseItem
      }
      errors {
        ...Error
      }
    }
  }
  ${ExpenseItemFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ExpenseItemUpdateMutationResult =
  Apollo.MutationResult<ExpenseItemUpdateMutation>;
export type ExpenseItemUpdateMutationOptions = Apollo.BaseMutationOptions<
  ExpenseItemUpdateMutation,
  ExpenseItemUpdateMutationVariables
>;
export const ExpenseItemDiscardDocument = gql`
  mutation ExpenseItemDiscard($id: ID!, $dryRun: Boolean) {
    expenseItemDiscard(id: $id, dryRun: $dryRun) {
      result {
        ...ExpenseItem
      }
      errors {
        ...Error
      }
    }
  }
  ${ExpenseItemFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ExpenseItemDiscardMutationResult =
  Apollo.MutationResult<ExpenseItemDiscardMutation>;
export type ExpenseItemDiscardMutationOptions = Apollo.BaseMutationOptions<
  ExpenseItemDiscardMutation,
  ExpenseItemDiscardMutationVariables
>;
export const ExpenseItemDestroyDocument = gql`
  mutation ExpenseItemDestroy($id: ID!, $dryRun: Boolean) {
    expenseItemDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ExpenseItemDestroyMutationResult =
  Apollo.MutationResult<ExpenseItemDestroyMutation>;
export type ExpenseItemDestroyMutationOptions = Apollo.BaseMutationOptions<
  ExpenseItemDestroyMutation,
  ExpenseItemDestroyMutationVariables
>;
export const InvoiceStatusDocument = gql`
  query InvoiceStatus {
    __type(name: "InvoiceStatus") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type InvoiceStatusQueryResult = Apollo.QueryResult<
  InvoiceStatusQuery,
  InvoiceStatusQueryVariables
>;
export const FinanceInvoicesDocument = gql`
  query FinanceInvoices(
    $page: Int
    $pageSize: Int
    $filter: FinanceInvoicesFilterInput
    $sort: FinanceInvoicesSortInput
    $includePaymentMethods: Boolean = false
    $includePayments: Boolean = false
  ) {
    financeInvoices(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...FinanceInvoice
        paymentMethod @include(if: $includePaymentMethods) {
          id
          name
        }
        payments @include(if: $includePayments) {
          ...FinanceInvoicePaymentDetails
        }
      }
    }
  }
  ${FinanceInvoiceFragmentDoc}
  ${FinanceInvoicePaymentDetailsFragmentDoc}
`;
export type FinanceInvoicesQueryResult = Apollo.QueryResult<
  FinanceInvoicesQuery,
  FinanceInvoicesQueryVariables
>;
export const FinanceInvoiceDocument = gql`
  query FinanceInvoice($id: ID!) {
    financeInvoice(id: $id) {
      ...FinanceInvoiceDetails
    }
  }
  ${FinanceInvoiceDetailsFragmentDoc}
`;
export type FinanceInvoiceQueryResult = Apollo.QueryResult<
  FinanceInvoiceQuery,
  FinanceInvoiceQueryVariables
>;
export const FinanceInvoiceUpdateDocument = gql`
  mutation FinanceInvoiceUpdate($id: ID!, $changes: InvoiceChanges!) {
    financeInvoiceUpdate(id: $id, changes: $changes) {
      result {
        ...FinanceInvoiceDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${FinanceInvoiceDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FinanceInvoiceUpdateMutationResult =
  Apollo.MutationResult<FinanceInvoiceUpdateMutation>;
export type FinanceInvoiceUpdateMutationOptions = Apollo.BaseMutationOptions<
  FinanceInvoiceUpdateMutation,
  FinanceInvoiceUpdateMutationVariables
>;
export const FinanceOrdersDocument = gql`
  query FinanceOrders(
    $page: Int
    $pageSize: Int
    $filter: FinanceOrdersFilterInput
    $sort: FinanceOrdersSortInput
  ) {
    financeOrders(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...FinanceOrder
      }
    }
  }
  ${FinanceOrderFragmentDoc}
`;
export type FinanceOrdersQueryResult = Apollo.QueryResult<
  FinanceOrdersQuery,
  FinanceOrdersQueryVariables
>;
export const FinanceOrderDocument = gql`
  query FinanceOrder($id: ID!, $nextDocuments: Boolean = false) {
    financeOrder(id: $id) {
      ...FinanceOrderDetails
      invoices @include(if: $nextDocuments) {
        id
        documentDate
        invoiceStatus
      }
    }
  }
  ${FinanceOrderDetailsFragmentDoc}
`;
export type FinanceOrderQueryResult = Apollo.QueryResult<
  FinanceOrderQuery,
  FinanceOrderQueryVariables
>;
export const FinanceOrderCreateDocument = gql`
  mutation FinanceOrderCreate($fields: FinanceOrderFields!) {
    financeOrderCreate(fields: $fields) {
      result {
        ...FinanceOrderDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${FinanceOrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FinanceOrderCreateMutationResult =
  Apollo.MutationResult<FinanceOrderCreateMutation>;
export type FinanceOrderCreateMutationOptions = Apollo.BaseMutationOptions<
  FinanceOrderCreateMutation,
  FinanceOrderCreateMutationVariables
>;
export const FinanceOrderUpdateDocument = gql`
  mutation FinanceOrderUpdate($id: ID!, $changes: FinanceOrderChanges!) {
    financeOrderUpdate(id: $id, changes: $changes) {
      result {
        ...FinanceOrderDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${FinanceOrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FinanceOrderUpdateMutationResult =
  Apollo.MutationResult<FinanceOrderUpdateMutation>;
export type FinanceOrderUpdateMutationOptions = Apollo.BaseMutationOptions<
  FinanceOrderUpdateMutation,
  FinanceOrderUpdateMutationVariables
>;
export const FinanceOrderCloneDocument = gql`
  mutation FinanceOrderClone($id: ID!, $changes: FinanceOrderChanges!) {
    financeOrderClone(id: $id, changes: $changes) {
      result {
        ...FinanceOrderDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${FinanceOrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FinanceOrderCloneMutationResult =
  Apollo.MutationResult<FinanceOrderCloneMutation>;
export type FinanceOrderCloneMutationOptions = Apollo.BaseMutationOptions<
  FinanceOrderCloneMutation,
  FinanceOrderCloneMutationVariables
>;
export const FinancePaymentDocument = gql`
  query FinancePayment($id: ID!) {
    financePayment(id: $id) {
      ...FinancePayment
      invoices {
        id
      }
    }
  }
  ${FinancePaymentFragmentDoc}
`;
export type FinancePaymentQueryResult = Apollo.QueryResult<
  FinancePaymentQuery,
  FinancePaymentQueryVariables
>;
export const FinancePaymentCreateDocument = gql`
  mutation FinancePaymentCreate($fields: PaymentFields!) {
    financePaymentCreate(fields: $fields) {
      result {
        ...FinancePayment
      }
      errors {
        path
        message
      }
    }
  }
  ${FinancePaymentFragmentDoc}
`;
export type FinancePaymentCreateMutationResult =
  Apollo.MutationResult<FinancePaymentCreateMutation>;
export type FinancePaymentCreateMutationOptions = Apollo.BaseMutationOptions<
  FinancePaymentCreateMutation,
  FinancePaymentCreateMutationVariables
>;
export const FinancePaymentUpdateDocument = gql`
  mutation FinancePaymentUpdate($id: ID!, $changes: PaymentChanges!) {
    financePaymentUpdate(id: $id, changes: $changes) {
      result {
        ...FinancePayment
      }
      errors {
        ...Error
      }
    }
  }
  ${FinancePaymentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FinancePaymentUpdateMutationResult =
  Apollo.MutationResult<FinancePaymentUpdateMutation>;
export type FinancePaymentUpdateMutationOptions = Apollo.BaseMutationOptions<
  FinancePaymentUpdateMutation,
  FinancePaymentUpdateMutationVariables
>;
export const FinancePeriodsDocument = gql`
  query FinancePeriods(
    $page: Int
    $pageSize: Int
    $filter: FinancePeriodsFilterInput
    $sort: FinancePeriodsSortInput
  ) {
    financePeriods(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...FinancePeriod
      }
    }
  }
  ${FinancePeriodFragmentDoc}
`;
export type FinancePeriodsQueryResult = Apollo.QueryResult<
  FinancePeriodsQuery,
  FinancePeriodsQueryVariables
>;
export const FinancePeriodCloseDocument = gql`
  mutation FinancePeriodClose($id: ID!, $dryRun: Boolean = true) {
    financePeriodClose(id: $id, dryRun: $dryRun) {
      result {
        ...FinancePeriod
      }
      errors {
        ...Error
      }
      activeDocuments {
        ...ActiveDocument
      }
    }
  }
  ${FinancePeriodFragmentDoc}
  ${ErrorFragmentDoc}
  ${ActiveDocumentFragmentDoc}
`;
export type FinancePeriodCloseMutationResult =
  Apollo.MutationResult<FinancePeriodCloseMutation>;
export type FinancePeriodCloseMutationOptions = Apollo.BaseMutationOptions<
  FinancePeriodCloseMutation,
  FinancePeriodCloseMutationVariables
>;
export const FinancePeriodOpenDocument = gql`
  mutation FinancePeriodOpen($id: ID!) {
    financePeriodOpen(id: $id) {
      result {
        ...FinancePeriod
      }
      errors {
        ...Error
      }
    }
  }
  ${FinancePeriodFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FinancePeriodOpenMutationResult =
  Apollo.MutationResult<FinancePeriodOpenMutation>;
export type FinancePeriodOpenMutationOptions = Apollo.BaseMutationOptions<
  FinancePeriodOpenMutation,
  FinancePeriodOpenMutationVariables
>;
export const FiscalYearsDocument = gql`
  query FiscalYears(
    $page: Int
    $pageSize: Int
    $filter: FiscalYearsFilterInput
    $sort: FiscalYearsSortInput
  ) {
    fiscalYears(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...FiscalYear
      }
    }
  }
  ${FiscalYearFragmentDoc}
`;
export type FiscalYearsQueryResult = Apollo.QueryResult<
  FiscalYearsQuery,
  FiscalYearsQueryVariables
>;
export const FiscalYearDocument = gql`
  query FiscalYear($id: ID!) {
    fiscalYear(id: $id) {
      ...FiscalYear
    }
  }
  ${FiscalYearFragmentDoc}
`;
export type FiscalYearQueryResult = Apollo.QueryResult<
  FiscalYearQuery,
  FiscalYearQueryVariables
>;
export const FiscalYearCreateDocument = gql`
  mutation FiscalYearCreate($fields: FiscalYearFields!) {
    fiscalYearCreate(fields: $fields) {
      result {
        ...FiscalYear
      }
      errors {
        ...Error
      }
    }
  }
  ${FiscalYearFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FiscalYearCreateMutationResult =
  Apollo.MutationResult<FiscalYearCreateMutation>;
export type FiscalYearCreateMutationOptions = Apollo.BaseMutationOptions<
  FiscalYearCreateMutation,
  FiscalYearCreateMutationVariables
>;
export const FiscalYearUpdateDocument = gql`
  mutation FiscalYearUpdate($id: ID!, $changes: FiscalYearChanges!) {
    fiscalYearUpdate(id: $id, changes: $changes) {
      result {
        ...FiscalYear
      }
      errors {
        ...Error
      }
    }
  }
  ${FiscalYearFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type FiscalYearUpdateMutationResult =
  Apollo.MutationResult<FiscalYearUpdateMutation>;
export type FiscalYearUpdateMutationOptions = Apollo.BaseMutationOptions<
  FiscalYearUpdateMutation,
  FiscalYearUpdateMutationVariables
>;
export const FiscalYearDestroyDocument = gql`
  mutation FiscalYearDestroy($id: ID!, $dryRun: Boolean) {
    fiscalYearDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type FiscalYearDestroyMutationResult =
  Apollo.MutationResult<FiscalYearDestroyMutation>;
export type FiscalYearDestroyMutationOptions = Apollo.BaseMutationOptions<
  FiscalYearDestroyMutation,
  FiscalYearDestroyMutationVariables
>;
export const JournalEntriesDocument = gql`
  query JournalEntries(
    $page: Int
    $pageSize: Int
    $filter: JournalEntriesFilterInput
    $sort: JournalEntriesSortInput
  ) {
    journalEntries(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...JournalEntry
        entryLines {
          id
          account {
            ...AccountShort
          }
        }
      }
    }
  }
  ${JournalEntryFragmentDoc}
  ${AccountShortFragmentDoc}
`;
export type JournalEntriesQueryResult = Apollo.QueryResult<
  JournalEntriesQuery,
  JournalEntriesQueryVariables
>;
export const JournalEntryDocument = gql`
  query JournalEntry($id: ID!) {
    journalEntry(id: $id) {
      ...JournalEntryDetails
    }
  }
  ${JournalEntryDetailsFragmentDoc}
`;
export type JournalEntryQueryResult = Apollo.QueryResult<
  JournalEntryQuery,
  JournalEntryQueryVariables
>;
export const JournalEntryCreateDocument = gql`
  mutation JournalEntryCreate($fields: JournalEntryFields!) {
    journalEntryCreate(fields: $fields) {
      result {
        ...JournalEntryDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${JournalEntryDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type JournalEntryCreateMutationResult =
  Apollo.MutationResult<JournalEntryCreateMutation>;
export type JournalEntryCreateMutationOptions = Apollo.BaseMutationOptions<
  JournalEntryCreateMutation,
  JournalEntryCreateMutationVariables
>;
export const JournalEntryUpdateDocument = gql`
  mutation JournalEntryUpdate($id: ID!, $changes: JournalEntryChanges!) {
    journalEntryUpdate(id: $id, changes: $changes) {
      result {
        ...JournalEntryDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${JournalEntryDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type JournalEntryUpdateMutationResult =
  Apollo.MutationResult<JournalEntryUpdateMutation>;
export type JournalEntryUpdateMutationOptions = Apollo.BaseMutationOptions<
  JournalEntryUpdateMutation,
  JournalEntryUpdateMutationVariables
>;
export const JournalEntryCloneDocument = gql`
  mutation JournalEntryClone($id: ID!, $changes: JournalEntryChanges!) {
    journalEntryClone(id: $id, changes: $changes) {
      result {
        ...JournalEntryDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${JournalEntryDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type JournalEntryCloneMutationResult =
  Apollo.MutationResult<JournalEntryCloneMutation>;
export type JournalEntryCloneMutationOptions = Apollo.BaseMutationOptions<
  JournalEntryCloneMutation,
  JournalEntryCloneMutationVariables
>;
export const TaxComponentsDocument = gql`
  query TaxComponents(
    $page: Int
    $pageSize: Int
    $filter: TaxComponentsFilterInput
    $sort: TaxComponentsSortInput
  ) {
    taxComponents(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...TaxComponent
      }
      totalCount
    }
  }
  ${TaxComponentFragmentDoc}
`;
export type TaxComponentsQueryResult = Apollo.QueryResult<
  TaxComponentsQuery,
  TaxComponentsQueryVariables
>;
export const TaxComponentDocument = gql`
  query TaxComponent($id: ID!) {
    taxComponent(id: $id) {
      ...TaxComponent
    }
  }
  ${TaxComponentFragmentDoc}
`;
export type TaxComponentQueryResult = Apollo.QueryResult<
  TaxComponentQuery,
  TaxComponentQueryVariables
>;
export const TaxComponentCreateDocument = gql`
  mutation TaxComponentCreate($fields: TaxComponentFields!) {
    taxComponentCreate(fields: $fields) {
      result {
        ...TaxComponent
      }
      errors {
        ...Error
      }
    }
  }
  ${TaxComponentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TaxComponentCreateMutationResult =
  Apollo.MutationResult<TaxComponentCreateMutation>;
export type TaxComponentCreateMutationOptions = Apollo.BaseMutationOptions<
  TaxComponentCreateMutation,
  TaxComponentCreateMutationVariables
>;
export const TaxComponentUpdateDocument = gql`
  mutation TaxComponentUpdate($id: ID!, $changes: TaxComponentChanges!) {
    taxComponentUpdate(id: $id, changes: $changes) {
      result {
        ...TaxComponent
      }
      errors {
        ...Error
      }
    }
  }
  ${TaxComponentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TaxComponentUpdateMutationResult =
  Apollo.MutationResult<TaxComponentUpdateMutation>;
export type TaxComponentUpdateMutationOptions = Apollo.BaseMutationOptions<
  TaxComponentUpdateMutation,
  TaxComponentUpdateMutationVariables
>;
export const TaxComponentDiscardDocument = gql`
  mutation TaxComponentDiscard($id: ID!) {
    taxComponentDiscard(id: $id) {
      result {
        ...TaxComponent
      }
      errors {
        ...Error
      }
    }
  }
  ${TaxComponentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TaxComponentDiscardMutationResult =
  Apollo.MutationResult<TaxComponentDiscardMutation>;
export type TaxComponentDiscardMutationOptions = Apollo.BaseMutationOptions<
  TaxComponentDiscardMutation,
  TaxComponentDiscardMutationVariables
>;
export const TaxComponentDestroyDocument = gql`
  mutation TaxComponentDestroy($id: ID!, $dryRun: Boolean) {
    taxComponentDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type TaxComponentDestroyMutationResult =
  Apollo.MutationResult<TaxComponentDestroyMutation>;
export type TaxComponentDestroyMutationOptions = Apollo.BaseMutationOptions<
  TaxComponentDestroyMutation,
  TaxComponentDestroyMutationVariables
>;
export const TaxPlansDocument = gql`
  query TaxPlans(
    $page: Int
    $pageSize: Int
    $filter: TaxPlansFilterInput
    $sort: TaxPlansSortInput
  ) {
    taxPlans(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      items {
        ...TaxPlan
      }
      totalCount
    }
  }
  ${TaxPlanFragmentDoc}
`;
export type TaxPlansQueryResult = Apollo.QueryResult<
  TaxPlansQuery,
  TaxPlansQueryVariables
>;
export const TaxPlanDocument = gql`
  query TaxPlan($id: ID!, $inputVariables: TaxPlanVariables!) {
    taxPlan(id: $id) {
      ...TaxPlanWithSample
    }
  }
  ${TaxPlanWithSampleFragmentDoc}
`;
export type TaxPlanQueryResult = Apollo.QueryResult<
  TaxPlanQuery,
  TaxPlanQueryVariables
>;
export const TaxPlanCreateDocument = gql`
  mutation TaxPlanCreate($fields: TaxPlanFields!) {
    taxPlanCreate(fields: $fields) {
      result {
        ...TaxPlan
      }
      errors {
        ...Error
      }
    }
  }
  ${TaxPlanFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TaxPlanCreateMutationResult =
  Apollo.MutationResult<TaxPlanCreateMutation>;
export type TaxPlanCreateMutationOptions = Apollo.BaseMutationOptions<
  TaxPlanCreateMutation,
  TaxPlanCreateMutationVariables
>;
export const TaxPlanUpdateDocument = gql`
  mutation TaxPlanUpdate(
    $id: ID!
    $inputVariables: TaxPlanVariables!
    $changes: TaxPlanChanges!
  ) {
    taxPlanUpdate(id: $id, changes: $changes, inputVariables: $inputVariables) {
      result {
        ...TaxPlanWithSample
      }
      errors {
        ...Error
      }
    }
  }
  ${TaxPlanWithSampleFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TaxPlanUpdateMutationResult =
  Apollo.MutationResult<TaxPlanUpdateMutation>;
export type TaxPlanUpdateMutationOptions = Apollo.BaseMutationOptions<
  TaxPlanUpdateMutation,
  TaxPlanUpdateMutationVariables
>;
export const TaxPlanDiscardDocument = gql`
  mutation TaxPlanDiscard($id: ID!) {
    taxPlanDiscard(id: $id) {
      result {
        ...TaxPlan
      }
      errors {
        ...Error
      }
    }
  }
  ${TaxPlanFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TaxPlanDiscardMutationResult =
  Apollo.MutationResult<TaxPlanDiscardMutation>;
export type TaxPlanDiscardMutationOptions = Apollo.BaseMutationOptions<
  TaxPlanDiscardMutation,
  TaxPlanDiscardMutationVariables
>;
export const TaxPlanDestroyDocument = gql`
  mutation TaxPlanDestroy($id: ID!, $dryRun: Boolean) {
    taxPlanDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type TaxPlanDestroyMutationResult =
  Apollo.MutationResult<TaxPlanDestroyMutation>;
export type TaxPlanDestroyMutationOptions = Apollo.BaseMutationOptions<
  TaxPlanDestroyMutation,
  TaxPlanDestroyMutationVariables
>;
export const TokensDocument = gql`
  query Tokens(
    $page: Int
    $pageSize: Int
    $filter: TokensFilterInput
    $sort: TokensSortInput
  ) {
    tokens(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Token
      }
    }
  }
  ${TokenFragmentDoc}
`;
export type TokensQueryResult = Apollo.QueryResult<
  TokensQuery,
  TokensQueryVariables
>;
export const TokenDocument = gql`
  query Token($id: ID!) {
    token(id: $id) {
      ...Token
    }
  }
  ${TokenFragmentDoc}
`;
export type TokenQueryResult = Apollo.QueryResult<
  TokenQuery,
  TokenQueryVariables
>;
export const TokenCreateDocument = gql`
  mutation TokenCreate($fields: TokenFields!) {
    tokenCreate(fields: $fields) {
      result {
        ...Token
      }
      errors {
        ...Error
      }
    }
  }
  ${TokenFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TokenCreateMutationResult =
  Apollo.MutationResult<TokenCreateMutation>;
export type TokenCreateMutationOptions = Apollo.BaseMutationOptions<
  TokenCreateMutation,
  TokenCreateMutationVariables
>;
export const TokenUpdateDocument = gql`
  mutation TokenUpdate($id: ID!, $changes: TokenChanges!) {
    tokenUpdate(id: $id, changes: $changes) {
      result {
        ...Token
      }
      errors {
        ...Error
      }
    }
  }
  ${TokenFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TokenUpdateMutationResult =
  Apollo.MutationResult<TokenUpdateMutation>;
export type TokenUpdateMutationOptions = Apollo.BaseMutationOptions<
  TokenUpdateMutation,
  TokenUpdateMutationVariables
>;
export const TokenDiscardDocument = gql`
  mutation TokenDiscard($id: ID!) {
    tokenDiscard(id: $id) {
      result {
        ...Token
      }
      errors {
        ...Error
      }
    }
  }
  ${TokenFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TokenDiscardMutationResult =
  Apollo.MutationResult<TokenDiscardMutation>;
export type TokenDiscardMutationOptions = Apollo.BaseMutationOptions<
  TokenDiscardMutation,
  TokenDiscardMutationVariables
>;
export const HolidaysDocument = gql`
  query Holidays(
    $page: Int
    $pageSize: Int
    $filter: HolidaysFilterInput
    $sort: HolidaysSortInput
  ) {
    holidays(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Holiday
      }
    }
  }
  ${HolidayFragmentDoc}
`;
export type HolidaysQueryResult = Apollo.QueryResult<
  HolidaysQuery,
  HolidaysQueryVariables
>;
export const HolidayListDocument = gql`
  query HolidayList($id: ID!) {
    holidayList(id: $id) {
      ...HolidayList
    }
  }
  ${HolidayListFragmentDoc}
`;
export type HolidayListQueryResult = Apollo.QueryResult<
  HolidayListQuery,
  HolidayListQueryVariables
>;
export const HolidayListsDocument = gql`
  query HolidayLists(
    $page: Int
    $pageSize: Int
    $filter: HolidayListsFilterInput
    $sort: HolidayListsSortInput
  ) {
    holidayLists(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...HolidayList
      }
    }
  }
  ${HolidayListFragmentDoc}
`;
export type HolidayListsQueryResult = Apollo.QueryResult<
  HolidayListsQuery,
  HolidayListsQueryVariables
>;
export const HolidayListCreateDocument = gql`
  mutation HolidayListCreate($fields: HolidayListFields!) {
    holidayListCreate(fields: $fields) {
      result {
        ...HolidayList
      }
      errors {
        ...Error
      }
    }
  }
  ${HolidayListFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type HolidayListCreateMutationResult =
  Apollo.MutationResult<HolidayListCreateMutation>;
export type HolidayListCreateMutationOptions = Apollo.BaseMutationOptions<
  HolidayListCreateMutation,
  HolidayListCreateMutationVariables
>;
export const HolidayListUpdateDocument = gql`
  mutation HolidayListUpdate($id: ID!, $changes: HolidayListChanges!) {
    holidayListUpdate(id: $id, changes: $changes) {
      result {
        ...HolidayList
      }
      errors {
        ...Error
      }
    }
  }
  ${HolidayListFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type HolidayListUpdateMutationResult =
  Apollo.MutationResult<HolidayListUpdateMutation>;
export type HolidayListUpdateMutationOptions = Apollo.BaseMutationOptions<
  HolidayListUpdateMutation,
  HolidayListUpdateMutationVariables
>;
export const HolidayListDestroyDocument = gql`
  mutation HolidayListDestroy($id: ID!, $dryRun: Boolean) {
    holidayListDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type HolidayListDestroyMutationResult =
  Apollo.MutationResult<HolidayListDestroyMutation>;
export type HolidayListDestroyMutationOptions = Apollo.BaseMutationOptions<
  HolidayListDestroyMutation,
  HolidayListDestroyMutationVariables
>;
export const LeaveAssignmentStatusDocument = gql`
  query LeaveAssignmentStatus {
    __type(name: "LeaveAssignmentStatus") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type LeaveAssignmentStatusQueryResult = Apollo.QueryResult<
  LeaveAssignmentStatusQuery,
  LeaveAssignmentStatusQueryVariables
>;
export const LeaveAssignmentsDocument = gql`
  query LeaveAssignments(
    $page: Int
    $pageSize: Int
    $filter: LeaveAssignmentsFilterInput
    $sort: LeaveAssignmentsSortInput
  ) {
    leaveAssignments(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...LeaveAssignment
      }
      totalCount
    }
  }
  ${LeaveAssignmentFragmentDoc}
`;
export type LeaveAssignmentsQueryResult = Apollo.QueryResult<
  LeaveAssignmentsQuery,
  LeaveAssignmentsQueryVariables
>;
export const LeaveAssignmentDocument = gql`
  query LeaveAssignment($id: ID!) {
    leaveAssignment(id: $id) {
      ...LeaveAssignmentDetails
    }
  }
  ${LeaveAssignmentDetailsFragmentDoc}
`;
export type LeaveAssignmentQueryResult = Apollo.QueryResult<
  LeaveAssignmentQuery,
  LeaveAssignmentQueryVariables
>;
export const LeaveAssignmentCreateDocument = gql`
  mutation LeaveAssignmentCreate($fields: LeaveAssignmentFields!) {
    leaveAssignmentCreate(fields: $fields) {
      result {
        id
        status
      }
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type LeaveAssignmentCreateMutationResult =
  Apollo.MutationResult<LeaveAssignmentCreateMutation>;
export type LeaveAssignmentCreateMutationOptions = Apollo.BaseMutationOptions<
  LeaveAssignmentCreateMutation,
  LeaveAssignmentCreateMutationVariables
>;
export const LeaveAssignmentUpdateDocument = gql`
  mutation LeaveAssignmentUpdate($id: ID!, $changes: LeaveAssignmentChanges!) {
    leaveAssignmentUpdate(id: $id, changes: $changes) {
      result {
        ...LeaveAssignmentDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${LeaveAssignmentDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type LeaveAssignmentUpdateMutationResult =
  Apollo.MutationResult<LeaveAssignmentUpdateMutation>;
export type LeaveAssignmentUpdateMutationOptions = Apollo.BaseMutationOptions<
  LeaveAssignmentUpdateMutation,
  LeaveAssignmentUpdateMutationVariables
>;
export const PayrollEntriesDocument = gql`
  query PayrollEntries(
    $page: Int
    $pageSize: Int
    $filter: PayrollEntriesFilterInput
    $sort: PayrollEntriesSortInput
  ) {
    payrollEntries(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...PayrollEntry
      }
    }
  }
  ${PayrollEntryFragmentDoc}
`;
export type PayrollEntriesQueryResult = Apollo.QueryResult<
  PayrollEntriesQuery,
  PayrollEntriesQueryVariables
>;
export const PayrollEntryDocument = gql`
  query PayrollEntry($id: ID!) {
    payrollEntry(id: $id) {
      ...PayrollEntryDetails
    }
  }
  ${PayrollEntryDetailsFragmentDoc}
`;
export type PayrollEntryQueryResult = Apollo.QueryResult<
  PayrollEntryQuery,
  PayrollEntryQueryVariables
>;
export const PayrollEntryWorkOrdersDocument = gql`
  query PayrollEntryWorkOrders($id: ID!) {
    payrollEntry(id: $id) {
      id
      workOrders {
        ...PayrollEntryWorkOrder
      }
    }
  }
  ${PayrollEntryWorkOrderFragmentDoc}
`;
export type PayrollEntryWorkOrdersQueryResult = Apollo.QueryResult<
  PayrollEntryWorkOrdersQuery,
  PayrollEntryWorkOrdersQueryVariables
>;
export const PayrollEntryAccountingComponentsDocument = gql`
  query PayrollEntryAccountingComponents($id: ID!) {
    payrollEntry(id: $id) {
      id
      accountingComponents {
        component {
          ...SalaryComponentShort
          salaryDebitAccount {
            ...AccountShort
          }
          salaryCreditAccount {
            ...AccountShort
          }
        }
        value
      }
    }
  }
  ${SalaryComponentShortFragmentDoc}
  ${AccountShortFragmentDoc}
`;
export type PayrollEntryAccountingComponentsQueryResult = Apollo.QueryResult<
  PayrollEntryAccountingComponentsQuery,
  PayrollEntryAccountingComponentsQueryVariables
>;
export const PayrollEntryCreateDocument = gql`
  mutation PayrollEntryCreate($fields: PayrollEntryFields!) {
    payrollEntryCreate(fields: $fields) {
      result {
        ...PayrollEntryDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${PayrollEntryDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PayrollEntryCreateMutationResult =
  Apollo.MutationResult<PayrollEntryCreateMutation>;
export type PayrollEntryCreateMutationOptions = Apollo.BaseMutationOptions<
  PayrollEntryCreateMutation,
  PayrollEntryCreateMutationVariables
>;
export const PayrollEntryUpdateDocument = gql`
  mutation PayrollEntryUpdate($id: ID!, $changes: PayrollEntryChanges!) {
    payrollEntryUpdate(id: $id, changes: $changes) {
      result {
        ...PayrollEntryDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${PayrollEntryDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PayrollEntryUpdateMutationResult =
  Apollo.MutationResult<PayrollEntryUpdateMutation>;
export type PayrollEntryUpdateMutationOptions = Apollo.BaseMutationOptions<
  PayrollEntryUpdateMutation,
  PayrollEntryUpdateMutationVariables
>;
export const PayrollEntryGenerateDocument = gql`
  mutation PayrollEntryGenerate($id: ID!) {
    payrollEntryGenerate(id: $id) {
      jobId
    }
  }
`;
export type PayrollEntryGenerateMutationResult =
  Apollo.MutationResult<PayrollEntryGenerateMutation>;
export type PayrollEntryGenerateMutationOptions = Apollo.BaseMutationOptions<
  PayrollEntryGenerateMutation,
  PayrollEntryGenerateMutationVariables
>;
export const PayrollEntryDestroyDocument = gql`
  mutation PayrollEntryDestroy($id: ID!, $dryRun: Boolean) {
    payrollEntryDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type PayrollEntryDestroyMutationResult =
  Apollo.MutationResult<PayrollEntryDestroyMutation>;
export type PayrollEntryDestroyMutationOptions = Apollo.BaseMutationOptions<
  PayrollEntryDestroyMutation,
  PayrollEntryDestroyMutationVariables
>;
export const SalaryComponentsDocument = gql`
  query SalaryComponents(
    $page: Int
    $pageSize: Int
    $filter: SalaryComponentsFilterInput
    $sort: SalaryComponentsSortInput
  ) {
    salaryComponents(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...SalaryComponent
      }
      totalCount
    }
  }
  ${SalaryComponentFragmentDoc}
`;
export type SalaryComponentsQueryResult = Apollo.QueryResult<
  SalaryComponentsQuery,
  SalaryComponentsQueryVariables
>;
export const SalaryComponentDocument = gql`
  query SalaryComponent($id: ID!) {
    salaryComponent(id: $id) {
      ...SalaryComponent
    }
  }
  ${SalaryComponentFragmentDoc}
`;
export type SalaryComponentQueryResult = Apollo.QueryResult<
  SalaryComponentQuery,
  SalaryComponentQueryVariables
>;
export const SalaryComponentCreateDocument = gql`
  mutation SalaryComponentCreate($fields: SalaryComponentFields!) {
    salaryComponentCreate(fields: $fields) {
      result {
        ...SalaryComponent
      }
      errors {
        ...Error
      }
    }
  }
  ${SalaryComponentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SalaryComponentCreateMutationResult =
  Apollo.MutationResult<SalaryComponentCreateMutation>;
export type SalaryComponentCreateMutationOptions = Apollo.BaseMutationOptions<
  SalaryComponentCreateMutation,
  SalaryComponentCreateMutationVariables
>;
export const SalaryComponentUpdateDocument = gql`
  mutation SalaryComponentUpdate($id: ID!, $changes: SalaryComponentChanges!) {
    salaryComponentUpdate(id: $id, changes: $changes) {
      result {
        ...SalaryComponent
      }
      errors {
        ...Error
      }
    }
  }
  ${SalaryComponentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SalaryComponentUpdateMutationResult =
  Apollo.MutationResult<SalaryComponentUpdateMutation>;
export type SalaryComponentUpdateMutationOptions = Apollo.BaseMutationOptions<
  SalaryComponentUpdateMutation,
  SalaryComponentUpdateMutationVariables
>;
export const SalaryComponentDiscardDocument = gql`
  mutation SalaryComponentDiscard($id: ID!) {
    salaryComponentDiscard(id: $id) {
      result {
        ...SalaryComponent
      }
      errors {
        ...Error
      }
    }
  }
  ${SalaryComponentFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SalaryComponentDiscardMutationResult =
  Apollo.MutationResult<SalaryComponentDiscardMutation>;
export type SalaryComponentDiscardMutationOptions = Apollo.BaseMutationOptions<
  SalaryComponentDiscardMutation,
  SalaryComponentDiscardMutationVariables
>;
export const SalaryComponentDestroyDocument = gql`
  mutation SalaryComponentDestroy($id: ID!, $dryRun: Boolean) {
    salaryComponentDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SalaryComponentDestroyMutationResult =
  Apollo.MutationResult<SalaryComponentDestroyMutation>;
export type SalaryComponentDestroyMutationOptions = Apollo.BaseMutationOptions<
  SalaryComponentDestroyMutation,
  SalaryComponentDestroyMutationVariables
>;
export const SalaryStructuresDocument = gql`
  query SalaryStructures(
    $page: Int
    $pageSize: Int
    $filter: SalaryStructuresFilterInput
    $sort: SalaryStructuresSortInput
  ) {
    salaryStructures(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...SalaryStructure
      }
      totalCount
    }
  }
  ${SalaryStructureFragmentDoc}
`;
export type SalaryStructuresQueryResult = Apollo.QueryResult<
  SalaryStructuresQuery,
  SalaryStructuresQueryVariables
>;
export const SalaryStructureDocument = gql`
  query SalaryStructure($id: ID!, $inputVariables: PayrollInputVariables!) {
    salaryStructure(id: $id) {
      ...SalaryStructureWithPayroll
    }
  }
  ${SalaryStructureWithPayrollFragmentDoc}
`;
export type SalaryStructureQueryResult = Apollo.QueryResult<
  SalaryStructureQuery,
  SalaryStructureQueryVariables
>;
export const SalaryStructureCreateDocument = gql`
  mutation SalaryStructureCreate($fields: SalaryStructureFields!) {
    salaryStructureCreate(fields: $fields) {
      result {
        ...SalaryStructure
      }
      errors {
        ...Error
      }
    }
  }
  ${SalaryStructureFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SalaryStructureCreateMutationResult =
  Apollo.MutationResult<SalaryStructureCreateMutation>;
export type SalaryStructureCreateMutationOptions = Apollo.BaseMutationOptions<
  SalaryStructureCreateMutation,
  SalaryStructureCreateMutationVariables
>;
export const SalaryStructureUpdateDocument = gql`
  mutation SalaryStructureUpdate(
    $id: ID!
    $inputVariables: PayrollInputVariables!
    $changes: SalaryStructureChanges!
  ) {
    salaryStructureUpdate(
      id: $id
      changes: $changes
      inputVariables: $inputVariables
    ) {
      result {
        ...SalaryStructureWithPayroll
      }
      errors {
        ...Error
      }
    }
  }
  ${SalaryStructureWithPayrollFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SalaryStructureUpdateMutationResult =
  Apollo.MutationResult<SalaryStructureUpdateMutation>;
export type SalaryStructureUpdateMutationOptions = Apollo.BaseMutationOptions<
  SalaryStructureUpdateMutation,
  SalaryStructureUpdateMutationVariables
>;
export const SalaryStructureDiscardDocument = gql`
  mutation SalaryStructureDiscard($id: ID!) {
    salaryStructureDiscard(id: $id) {
      result {
        ...SalaryStructure
      }
      errors {
        ...Error
      }
    }
  }
  ${SalaryStructureFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type SalaryStructureDiscardMutationResult =
  Apollo.MutationResult<SalaryStructureDiscardMutation>;
export type SalaryStructureDiscardMutationOptions = Apollo.BaseMutationOptions<
  SalaryStructureDiscardMutation,
  SalaryStructureDiscardMutationVariables
>;
export const SalaryStructureDestroyDocument = gql`
  mutation SalaryStructureDestroy($id: ID!, $dryRun: Boolean) {
    salaryStructureDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type SalaryStructureDestroyMutationResult =
  Apollo.MutationResult<SalaryStructureDestroyMutation>;
export type SalaryStructureDestroyMutationOptions = Apollo.BaseMutationOptions<
  SalaryStructureDestroyMutation,
  SalaryStructureDestroyMutationVariables
>;
export const WeekDaysDocument = gql`
  query WeekDays(
    $page: Int
    $pageSize: Int
    $filter: WeekDaysFilterInput
    $sort: WeekDaysSortInput
  ) {
    weekDays(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...WeekDay
      }
    }
  }
  ${WeekDayFragmentDoc}
`;
export type WeekDaysQueryResult = Apollo.QueryResult<
  WeekDaysQuery,
  WeekDaysQueryVariables
>;
export const AdjustmentKindDocument = gql`
  query AdjustmentKind {
    __type(name: "AdjustmentKind") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type AdjustmentKindQueryResult = Apollo.QueryResult<
  AdjustmentKindQuery,
  AdjustmentKindQueryVariables
>;
export const AdjustmentTypesDocument = gql`
  query AdjustmentTypes(
    $page: Int
    $pageSize: Int
    $filter: AdjustmentTypesFilterInput
    $sort: AdjustmentTypesSortInput
  ) {
    adjustmentTypes(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...AdjustmentType
      }
    }
  }
  ${AdjustmentTypeFragmentDoc}
`;
export type AdjustmentTypesQueryResult = Apollo.QueryResult<
  AdjustmentTypesQuery,
  AdjustmentTypesQueryVariables
>;
export const AdjustmentTypeDocument = gql`
  query AdjustmentType($id: ID!) {
    adjustmentType(id: $id) {
      ...AdjustmentType
    }
  }
  ${AdjustmentTypeFragmentDoc}
`;
export type AdjustmentTypeQueryResult = Apollo.QueryResult<
  AdjustmentTypeQuery,
  AdjustmentTypeQueryVariables
>;
export const AdjustmentTypeCreateDocument = gql`
  mutation AdjustmentTypeCreate($fields: AdjustmentTypeFields!) {
    adjustmentTypeCreate(fields: $fields) {
      result {
        ...AdjustmentType
      }
      errors {
        ...Error
      }
    }
  }
  ${AdjustmentTypeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AdjustmentTypeCreateMutationResult =
  Apollo.MutationResult<AdjustmentTypeCreateMutation>;
export type AdjustmentTypeCreateMutationOptions = Apollo.BaseMutationOptions<
  AdjustmentTypeCreateMutation,
  AdjustmentTypeCreateMutationVariables
>;
export const AdjustmentTypeUpdateDocument = gql`
  mutation AdjustmentTypeUpdate($id: ID!, $changes: AdjustmentTypeChanges!) {
    adjustmentTypeUpdate(id: $id, changes: $changes) {
      result {
        ...AdjustmentType
      }
      errors {
        ...Error
      }
    }
  }
  ${AdjustmentTypeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AdjustmentTypeUpdateMutationResult =
  Apollo.MutationResult<AdjustmentTypeUpdateMutation>;
export type AdjustmentTypeUpdateMutationOptions = Apollo.BaseMutationOptions<
  AdjustmentTypeUpdateMutation,
  AdjustmentTypeUpdateMutationVariables
>;
export const AdjustmentTypeDiscardDocument = gql`
  mutation AdjustmentTypeDiscard($id: ID!) {
    adjustmentTypeDiscard(id: $id) {
      result {
        ...AdjustmentType
      }
      errors {
        ...Error
      }
    }
  }
  ${AdjustmentTypeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type AdjustmentTypeDiscardMutationResult =
  Apollo.MutationResult<AdjustmentTypeDiscardMutation>;
export type AdjustmentTypeDiscardMutationOptions = Apollo.BaseMutationOptions<
  AdjustmentTypeDiscardMutation,
  AdjustmentTypeDiscardMutationVariables
>;
export const AdjustmentTypeDestroyDocument = gql`
  mutation adjustmentTypeDestroy($id: ID!, $dryRun: Boolean) {
    adjustmentTypeDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type AdjustmentTypeDestroyMutationResult =
  Apollo.MutationResult<AdjustmentTypeDestroyMutation>;
export type AdjustmentTypeDestroyMutationOptions = Apollo.BaseMutationOptions<
  AdjustmentTypeDestroyMutation,
  AdjustmentTypeDestroyMutationVariables
>;
export const EquipmentListDocument = gql`
  query EquipmentList(
    $page: Int
    $pageSize: Int
    $filter: EquipmentListFilterInput
    $sort: EquipmentListSortInput
  ) {
    equipmentList(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...EquipmentShort
      }
    }
  }
  ${EquipmentShortFragmentDoc}
`;
export type EquipmentListQueryResult = Apollo.QueryResult<
  EquipmentListQuery,
  EquipmentListQueryVariables
>;
export const InventoryOrdersDocument = gql`
  query InventoryOrders(
    $page: Int
    $pageSize: Int
    $filter: InventoryOrdersFilterInput
    $sort: InventoryOrdersSortInput
  ) {
    inventoryOrders(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...InventoryOrder
      }
    }
  }
  ${InventoryOrderFragmentDoc}
`;
export type InventoryOrdersQueryResult = Apollo.QueryResult<
  InventoryOrdersQuery,
  InventoryOrdersQueryVariables
>;
export const InventoryOrderDocument = gql`
  query InventoryOrder($id: ID!, $nextDocuments: Boolean = false) {
    inventoryOrder(id: $id) {
      ...InventoryOrderDetails
      nextDocuments @include(if: $nextDocuments) {
        documentId
        documentType
        documentDate
      }
      invoices @include(if: $nextDocuments) {
        id
        documentDate
        invoiceStatus
      }
    }
  }
  ${InventoryOrderDetailsFragmentDoc}
`;
export type InventoryOrderQueryResult = Apollo.QueryResult<
  InventoryOrderQuery,
  InventoryOrderQueryVariables
>;
export const InventoryOrderCreateDocument = gql`
  mutation InventoryOrderCreate($fields: InventoryOrderFields!) {
    inventoryOrderCreate(fields: $fields) {
      result {
        ...InventoryOrderDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${InventoryOrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type InventoryOrderCreateMutationResult =
  Apollo.MutationResult<InventoryOrderCreateMutation>;
export type InventoryOrderCreateMutationOptions = Apollo.BaseMutationOptions<
  InventoryOrderCreateMutation,
  InventoryOrderCreateMutationVariables
>;
export const InventoryOrderUpdateDocument = gql`
  mutation InventoryOrderUpdate($id: ID!, $changes: InventoryOrderChanges!) {
    inventoryOrderUpdate(id: $id, changes: $changes) {
      result {
        ...InventoryOrderDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${InventoryOrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type InventoryOrderUpdateMutationResult =
  Apollo.MutationResult<InventoryOrderUpdateMutation>;
export type InventoryOrderUpdateMutationOptions = Apollo.BaseMutationOptions<
  InventoryOrderUpdateMutation,
  InventoryOrderUpdateMutationVariables
>;
export const InventoryOrderCloneDocument = gql`
  mutation InventoryOrderClone($id: ID!, $changes: InventoryOrderChanges!) {
    inventoryOrderClone(id: $id, changes: $changes) {
      result {
        ...InventoryOrderDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${InventoryOrderDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type InventoryOrderCloneMutationResult =
  Apollo.MutationResult<InventoryOrderCloneMutation>;
export type InventoryOrderCloneMutationOptions = Apollo.BaseMutationOptions<
  InventoryOrderCloneMutation,
  InventoryOrderCloneMutationVariables
>;
export const ItemCategoriesDocument = gql`
  query ItemCategories(
    $page: Int
    $pageSize: Int
    $filter: ItemCategoriesFilterInput
    $sort: ItemCategoriesSortInput
  ) {
    itemCategories(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...ItemCategoryTree
      }
      totalCount
    }
  }
  ${ItemCategoryTreeFragmentDoc}
`;
export type ItemCategoriesQueryResult = Apollo.QueryResult<
  ItemCategoriesQuery,
  ItemCategoriesQueryVariables
>;
export const ItemCategoryOptionsDocument = gql`
  query ItemCategoryOptions(
    $filter: ItemCategoriesFilterInput
    $sort: ItemCategoriesSortInput
  ) {
    itemCategories(page: 1, pageSize: 500, filter: $filter, sort: $sort) {
      items {
        ...ItemCategoryOption
      }
    }
  }
  ${ItemCategoryOptionFragmentDoc}
`;
export type ItemCategoryOptionsQueryResult = Apollo.QueryResult<
  ItemCategoryOptionsQuery,
  ItemCategoryOptionsQueryVariables
>;
export const ItemCategoryDocument = gql`
  query ItemCategory($id: ID!) {
    itemCategory(id: $id) {
      ...ItemCategory
      subcategories {
        ...ItemCategory
      }
    }
  }
  ${ItemCategoryFragmentDoc}
`;
export type ItemCategoryQueryResult = Apollo.QueryResult<
  ItemCategoryQuery,
  ItemCategoryQueryVariables
>;
export const ItemCategoryCreateDocument = gql`
  mutation ItemCategoryCreate($fields: ItemCategoryFields!) {
    itemCategoryCreate(fields: $fields) {
      result {
        ...ItemCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ItemCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ItemCategoryCreateMutationResult =
  Apollo.MutationResult<ItemCategoryCreateMutation>;
export type ItemCategoryCreateMutationOptions = Apollo.BaseMutationOptions<
  ItemCategoryCreateMutation,
  ItemCategoryCreateMutationVariables
>;
export const ItemCategoryUpdateDocument = gql`
  mutation ItemCategoryUpdate($id: ID!, $changes: ItemCategoryChanges!) {
    itemCategoryUpdate(id: $id, changes: $changes) {
      result {
        ...ItemCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ItemCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ItemCategoryUpdateMutationResult =
  Apollo.MutationResult<ItemCategoryUpdateMutation>;
export type ItemCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<
  ItemCategoryUpdateMutation,
  ItemCategoryUpdateMutationVariables
>;
export const ItemCategoryLocalUpdateDocument = gql`
  mutation ItemCategoryLocalUpdate(
    $id: ID!
    $changes: LocalItemCategoryChanges!
  ) {
    itemCategoryLocalUpdate(id: $id, changes: $changes) {
      result {
        ...ItemCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ItemCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ItemCategoryLocalUpdateMutationResult =
  Apollo.MutationResult<ItemCategoryLocalUpdateMutation>;
export type ItemCategoryLocalUpdateMutationOptions = Apollo.BaseMutationOptions<
  ItemCategoryLocalUpdateMutation,
  ItemCategoryLocalUpdateMutationVariables
>;
export const ItemCategoryDiscardDocument = gql`
  mutation ItemCategoryDiscard($id: ID!) {
    itemCategoryDiscard(id: $id) {
      result {
        ...ItemCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ItemCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ItemCategoryDiscardMutationResult =
  Apollo.MutationResult<ItemCategoryDiscardMutation>;
export type ItemCategoryDiscardMutationOptions = Apollo.BaseMutationOptions<
  ItemCategoryDiscardMutation,
  ItemCategoryDiscardMutationVariables
>;
export const ItemCategoryDestroyDocument = gql`
  mutation ItemCategoryDestroy($id: ID!, $dryRun: Boolean) {
    itemCategoryDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ItemCategoryDestroyMutationResult =
  Apollo.MutationResult<ItemCategoryDestroyMutation>;
export type ItemCategoryDestroyMutationOptions = Apollo.BaseMutationOptions<
  ItemCategoryDestroyMutation,
  ItemCategoryDestroyMutationVariables
>;
export const ItemCategoryUnlockDocument = gql`
  mutation ItemCategoryUnlock($id: ID!) {
    itemCategoryUnlock(id: $id) {
      result {
        ...ItemCategoryTree
      }
      errors {
        ...Error
      }
    }
  }
  ${ItemCategoryTreeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ItemCategoryUnlockMutationResult =
  Apollo.MutationResult<ItemCategoryUnlockMutation>;
export type ItemCategoryUnlockMutationOptions = Apollo.BaseMutationOptions<
  ItemCategoryUnlockMutation,
  ItemCategoryUnlockMutationVariables
>;
export const ItemKindDocument = gql`
  query ItemKind {
    __type(name: "ItemKind") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type ItemKindQueryResult = Apollo.QueryResult<
  ItemKindQuery,
  ItemKindQueryVariables
>;
export const ToxicityCategoryDocument = gql`
  query ToxicityCategory {
    __type(name: "ToxicityCategory") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type ToxicityCategoryQueryResult = Apollo.QueryResult<
  ToxicityCategoryQuery,
  ToxicityCategoryQueryVariables
>;
export const ItemsDocument = gql`
  query Items(
    $page: Int
    $pageSize: Int
    $filter: ItemsFilterInput
    $sort: ItemsSortInput
  ) {
    items(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Item
        variants {
          ...VariantShort
          discardedAt
        }
      }
    }
  }
  ${ItemFragmentDoc}
  ${VariantShortFragmentDoc}
`;
export type ItemsQueryResult = Apollo.QueryResult<
  ItemsQuery,
  ItemsQueryVariables
>;
export const ItemDocument = gql`
  query Item($id: ID!) {
    item(id: $id) {
      ...ItemDetails
    }
  }
  ${ItemDetailsFragmentDoc}
`;
export type ItemQueryResult = Apollo.QueryResult<ItemQuery, ItemQueryVariables>;
export const ItemCreateDocument = gql`
  mutation ItemCreate($fields: ItemFields!) {
    itemCreate(fields: $fields) {
      result {
        ...ItemDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${ItemDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ItemCreateMutationResult =
  Apollo.MutationResult<ItemCreateMutation>;
export type ItemCreateMutationOptions = Apollo.BaseMutationOptions<
  ItemCreateMutation,
  ItemCreateMutationVariables
>;
export const ItemUpdateDocument = gql`
  mutation ItemUpdate($id: ID!, $changes: ItemChanges!) {
    itemUpdate(id: $id, changes: $changes) {
      result {
        ...ItemDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${ItemDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ItemUpdateMutationResult =
  Apollo.MutationResult<ItemUpdateMutation>;
export type ItemUpdateMutationOptions = Apollo.BaseMutationOptions<
  ItemUpdateMutation,
  ItemUpdateMutationVariables
>;
export const ItemDiscardDocument = gql`
  mutation ItemDiscard($id: ID!, $dryRun: Boolean) {
    itemDiscard(id: $id, dryRun: $dryRun) {
      result {
        ...ItemDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${ItemDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type ItemDiscardMutationResult =
  Apollo.MutationResult<ItemDiscardMutation>;
export type ItemDiscardMutationOptions = Apollo.BaseMutationOptions<
  ItemDiscardMutation,
  ItemDiscardMutationVariables
>;
export const ItemDestroyDocument = gql`
  mutation ItemDestroy($id: ID!, $dryRun: Boolean) {
    itemDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ItemDestroyMutationResult =
  Apollo.MutationResult<ItemDestroyMutation>;
export type ItemDestroyMutationOptions = Apollo.BaseMutationOptions<
  ItemDestroyMutation,
  ItemDestroyMutationVariables
>;
export const ItemBulkUpdateDocument = gql`
  mutation ItemBulkUpdate($ids: [ID!]!, $changes: ItemBulkChanges!) {
    itemBulkUpdate(ids: $ids, changes: $changes) {
      result {
        id
        result
        errors {
          ...Error
        }
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type ItemBulkUpdateMutationResult =
  Apollo.MutationResult<ItemBulkUpdateMutation>;
export type ItemBulkUpdateMutationOptions = Apollo.BaseMutationOptions<
  ItemBulkUpdateMutation,
  ItemBulkUpdateMutationVariables
>;
export const PaymentMethodTypeDocument = gql`
  query PaymentMethodType {
    __type(name: "PaymentMethodType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type PaymentMethodTypeQueryResult = Apollo.QueryResult<
  PaymentMethodTypeQuery,
  PaymentMethodTypeQueryVariables
>;
export const PaymentMethodsDocument = gql`
  query PaymentMethods(
    $page: Int
    $pageSize: Int
    $filter: PaymentMethodsFilterInput
    $sort: PaymentMethodsSortInput
  ) {
    paymentMethods(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      items {
        ...PaymentMethod
      }
      totalCount
    }
  }
  ${PaymentMethodFragmentDoc}
`;
export type PaymentMethodsQueryResult = Apollo.QueryResult<
  PaymentMethodsQuery,
  PaymentMethodsQueryVariables
>;
export const PaymentMethodDocument = gql`
  query PaymentMethod($id: ID!) {
    paymentMethod(id: $id) {
      ...PaymentMethod
    }
  }
  ${PaymentMethodFragmentDoc}
`;
export type PaymentMethodQueryResult = Apollo.QueryResult<
  PaymentMethodQuery,
  PaymentMethodQueryVariables
>;
export const PaymentMethodCreateDocument = gql`
  mutation PaymentMethodCreate($fields: PaymentMethodFields!) {
    paymentMethodCreate(fields: $fields) {
      result {
        ...PaymentMethod
      }
      errors {
        ...Error
      }
    }
  }
  ${PaymentMethodFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PaymentMethodCreateMutationResult =
  Apollo.MutationResult<PaymentMethodCreateMutation>;
export type PaymentMethodCreateMutationOptions = Apollo.BaseMutationOptions<
  PaymentMethodCreateMutation,
  PaymentMethodCreateMutationVariables
>;
export const PaymentMethodUpdateDocument = gql`
  mutation PaymentMethodUpdate($id: ID!, $changes: PaymentMethodChanges!) {
    paymentMethodUpdate(id: $id, changes: $changes) {
      result {
        ...PaymentMethod
      }
      errors {
        ...Error
      }
    }
  }
  ${PaymentMethodFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PaymentMethodUpdateMutationResult =
  Apollo.MutationResult<PaymentMethodUpdateMutation>;
export type PaymentMethodUpdateMutationOptions = Apollo.BaseMutationOptions<
  PaymentMethodUpdateMutation,
  PaymentMethodUpdateMutationVariables
>;
export const PaymentMethodDiscardDocument = gql`
  mutation PaymentMethodDiscard($id: ID!) {
    paymentMethodDiscard(id: $id) {
      result {
        ...PaymentMethod
      }
      errors {
        ...Error
      }
    }
  }
  ${PaymentMethodFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PaymentMethodDiscardMutationResult =
  Apollo.MutationResult<PaymentMethodDiscardMutation>;
export type PaymentMethodDiscardMutationOptions = Apollo.BaseMutationOptions<
  PaymentMethodDiscardMutation,
  PaymentMethodDiscardMutationVariables
>;
export const PaymentMethodUnlockDocument = gql`
  mutation PaymentMethodUnlock($id: ID!) {
    paymentMethodUnlock(id: $id) {
      result {
        ...PaymentMethod
      }
      errors {
        ...Error
      }
    }
  }
  ${PaymentMethodFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type PaymentMethodUnlockMutationResult =
  Apollo.MutationResult<PaymentMethodUnlockMutation>;
export type PaymentMethodUnlockMutationOptions = Apollo.BaseMutationOptions<
  PaymentMethodUnlockMutation,
  PaymentMethodUnlockMutationVariables
>;
export const RecipesDocument = gql`
  query Recipes(
    $page: Int
    $pageSize: Int
    $filter: RecipesFilterInput
    $sort: RecipesSortInput
  ) {
    recipes(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Recipe
      }
    }
  }
  ${RecipeFragmentDoc}
`;
export type RecipesQueryResult = Apollo.QueryResult<
  RecipesQuery,
  RecipesQueryVariables
>;
export const RecipeDocument = gql`
  query Recipe($id: ID!) {
    recipe(id: $id) {
      ...Recipe
    }
  }
  ${RecipeFragmentDoc}
`;
export type RecipeQueryResult = Apollo.QueryResult<
  RecipeQuery,
  RecipeQueryVariables
>;
export const RecipeCreateDocument = gql`
  mutation RecipeCreate($fields: RecipeFields!) {
    recipeCreate(fields: $fields) {
      result {
        ...Recipe
      }
      errors {
        ...Error
      }
    }
  }
  ${RecipeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type RecipeCreateMutationResult =
  Apollo.MutationResult<RecipeCreateMutation>;
export type RecipeCreateMutationOptions = Apollo.BaseMutationOptions<
  RecipeCreateMutation,
  RecipeCreateMutationVariables
>;
export const RecipeUpdateDocument = gql`
  mutation RecipeUpdate($id: ID!, $changes: RecipeChanges!) {
    recipeUpdate(id: $id, changes: $changes) {
      result {
        ...Recipe
      }
      errors {
        ...Error
      }
    }
  }
  ${RecipeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type RecipeUpdateMutationResult =
  Apollo.MutationResult<RecipeUpdateMutation>;
export type RecipeUpdateMutationOptions = Apollo.BaseMutationOptions<
  RecipeUpdateMutation,
  RecipeUpdateMutationVariables
>;
export const RecipeDiscardDocument = gql`
  mutation RecipeDiscard($id: ID!, $dryRun: Boolean) {
    recipeDiscard(id: $id, dryRun: $dryRun) {
      result {
        ...Recipe
      }
      errors {
        ...Error
      }
    }
  }
  ${RecipeFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type RecipeDiscardMutationResult =
  Apollo.MutationResult<RecipeDiscardMutation>;
export type RecipeDiscardMutationOptions = Apollo.BaseMutationOptions<
  RecipeDiscardMutation,
  RecipeDiscardMutationVariables
>;
export const RecipeDestroyDocument = gql`
  mutation RecipeDestroy($id: ID!, $dryRun: Boolean) {
    recipeDestroy(id: $id, dryRun: $dryRun) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type RecipeDestroyMutationResult =
  Apollo.MutationResult<RecipeDestroyMutation>;
export type RecipeDestroyMutationOptions = Apollo.BaseMutationOptions<
  RecipeDestroyMutation,
  RecipeDestroyMutationVariables
>;
export const TransfersDocument = gql`
  query Transfers(
    $page: Int
    $pageSize: Int
    $filter: TransfersFilterInput
    $sort: TransfersSortInput
  ) {
    stockTransfers(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...Transfer
      }
    }
  }
  ${TransferFragmentDoc}
`;
export type TransfersQueryResult = Apollo.QueryResult<
  TransfersQuery,
  TransfersQueryVariables
>;
export const TransferDocument = gql`
  query Transfer($id: ID!) {
    stockTransfer(id: $id) {
      ...TransferDetails
    }
  }
  ${TransferDetailsFragmentDoc}
`;
export type TransferQueryResult = Apollo.QueryResult<
  TransferQuery,
  TransferQueryVariables
>;
export const StockTransferDocument = gql`
  query StockTransfer($id: ID!) {
    stockTransfer(id: $id) {
      ...TransferDetails
      transferVariants {
        ...StockTransferVariant
      }
    }
  }
  ${TransferDetailsFragmentDoc}
  ${StockTransferVariantFragmentDoc}
`;
export type StockTransferQueryResult = Apollo.QueryResult<
  StockTransferQuery,
  StockTransferQueryVariables
>;
export const TransferCreateDocument = gql`
  mutation TransferCreate($fields: TransferFields!) {
    stockTransferCreate(fields: $fields) {
      result {
        id
      }
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type TransferCreateMutationResult =
  Apollo.MutationResult<TransferCreateMutation>;
export type TransferCreateMutationOptions = Apollo.BaseMutationOptions<
  TransferCreateMutation,
  TransferCreateMutationVariables
>;
export const TransferUpdateDocument = gql`
  mutation TransferUpdate($id: ID!, $changes: TransferChanges!) {
    stockTransferUpdate(id: $id, changes: $changes) {
      result {
        ...TransferDetails
        transferVariants {
          ...StockTransferVariant
        }
      }
      errors {
        ...Error
      }
    }
  }
  ${TransferDetailsFragmentDoc}
  ${StockTransferVariantFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TransferUpdateMutationResult =
  Apollo.MutationResult<TransferUpdateMutation>;
export type TransferUpdateMutationOptions = Apollo.BaseMutationOptions<
  TransferUpdateMutation,
  TransferUpdateMutationVariables
>;
export const TransferCloneDocument = gql`
  mutation TransferClone($id: ID!, $changes: TransferChanges!) {
    stockTransferClone(id: $id, changes: $changes) {
      result {
        ...TransferDetails
      }
      errors {
        ...Error
      }
    }
  }
  ${TransferDetailsFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type TransferCloneMutationResult =
  Apollo.MutationResult<TransferCloneMutation>;
export type TransferCloneMutationOptions = Apollo.BaseMutationOptions<
  TransferCloneMutation,
  TransferCloneMutationVariables
>;
export const VariantsDocument = gql`
  query Variants(
    $page: Int
    $pageSize: Int
    $filter: VariantsFilterInput
    $sort: VariantsSortInput
  ) {
    variants(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...VariantShort
        item {
          ...Item
        }
      }
    }
  }
  ${VariantShortFragmentDoc}
  ${ItemFragmentDoc}
`;
export type VariantsQueryResult = Apollo.QueryResult<
  VariantsQuery,
  VariantsQueryVariables
>;
export const StockVariantsDocument = gql`
  query StockVariants(
    $localityId: ID
    $locationId: ID
    $locationType: LocationType
    $page: Int
    $pageSize: Int
    $filter: VariantsFilterInput
    $sort: VariantsSortInput
    $token: Boolean = false
    $virtual: VirtualWarehouse
    $fetchCost: Boolean = false
    $fetchTaxPlan: Boolean = false
    $fetchEquipment: Boolean = false
    $date: ISO8601DateTime
  ) {
    variants(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...StockVariant
        token @include(if: $token) {
          quantity
        }
        item {
          defaultTaxPlan @include(if: $fetchTaxPlan) {
            ...TaxPlanWithComponents
          }
          equipment @include(if: $fetchEquipment) {
            ...EquipmentShort
          }
        }
      }
    }
  }
  ${StockVariantFragmentDoc}
  ${TaxPlanWithComponentsFragmentDoc}
  ${EquipmentShortFragmentDoc}
`;
export type StockVariantsQueryResult = Apollo.QueryResult<
  StockVariantsQuery,
  StockVariantsQueryVariables
>;
export const VariantDocument = gql`
  query Variant($id: ID!) {
    variant(id: $id) {
      ...VariantDetails
      item {
        ...Item
      }
    }
  }
  ${VariantDetailsFragmentDoc}
  ${ItemFragmentDoc}
`;
export type VariantQueryResult = Apollo.QueryResult<
  VariantQuery,
  VariantQueryVariables
>;
export const WarehousesDocument = gql`
  query Warehouses(
    $page: Int
    $pageSize: Int
    $filter: WarehousesFilterInput
    $sort: WarehousesSortInput
  ) {
    warehouses(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Warehouse
      }
    }
  }
  ${WarehouseFragmentDoc}
`;
export type WarehousesQueryResult = Apollo.QueryResult<
  WarehousesQuery,
  WarehousesQueryVariables
>;
export const StockWarehousesDocument = gql`
  query StockWarehouses(
    $variantId: ID!
    $unitId: ID
    $date: ISO8601DateTime
    $page: Int
    $pageSize: Int
    $filter: WarehousesFilterInput
    $sort: WarehousesSortInput
  ) {
    warehouses(page: $page, pageSize: $pageSize, filter: $filter, sort: $sort) {
      totalCount
      items {
        ...Warehouse
        stock(variantId: $variantId, unitId: $unitId, date: $date) {
          onHand
        }
      }
    }
  }
  ${WarehouseFragmentDoc}
`;
export type StockWarehousesQueryResult = Apollo.QueryResult<
  StockWarehousesQuery,
  StockWarehousesQueryVariables
>;
export const WarehouseDocument = gql`
  query Warehouse($id: ID!) {
    warehouse(id: $id) {
      ...Warehouse
    }
  }
  ${WarehouseFragmentDoc}
`;
export type WarehouseQueryResult = Apollo.QueryResult<
  WarehouseQuery,
  WarehouseQueryVariables
>;
export const WarehouseCreateDocument = gql`
  mutation WarehouseCreate($fields: WarehouseFields!) {
    warehouseCreate(fields: $fields) {
      result {
        ...Warehouse
      }
      errors {
        ...Error
      }
    }
  }
  ${WarehouseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type WarehouseCreateMutationResult =
  Apollo.MutationResult<WarehouseCreateMutation>;
export type WarehouseCreateMutationOptions = Apollo.BaseMutationOptions<
  WarehouseCreateMutation,
  WarehouseCreateMutationVariables
>;
export const WarehouseUpdateDocument = gql`
  mutation WarehouseUpdate($id: ID!, $changes: WarehouseChanges!) {
    warehouseUpdate(id: $id, changes: $changes) {
      result {
        ...Warehouse
      }
      errors {
        ...Error
      }
    }
  }
  ${WarehouseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type WarehouseUpdateMutationResult =
  Apollo.MutationResult<WarehouseUpdateMutation>;
export type WarehouseUpdateMutationOptions = Apollo.BaseMutationOptions<
  WarehouseUpdateMutation,
  WarehouseUpdateMutationVariables
>;
export const WarehouseDiscardDocument = gql`
  mutation WarehouseDiscard($id: ID!) {
    warehouseDiscard(id: $id) {
      result {
        ...Warehouse
      }
      errors {
        ...Error
      }
    }
  }
  ${WarehouseFragmentDoc}
  ${ErrorFragmentDoc}
`;
export type WarehouseDiscardMutationResult =
  Apollo.MutationResult<WarehouseDiscardMutation>;
export type WarehouseDiscardMutationOptions = Apollo.BaseMutationOptions<
  WarehouseDiscardMutation,
  WarehouseDiscardMutationVariables
>;
export const OnboardingSeedsActivitiesDocument = gql`
  query OnboardingSeedsActivities {
    onboardingSeedsActivities {
      ...ActivitySeed
    }
  }
  ${ActivitySeedFragmentDoc}
`;
export type OnboardingSeedsActivitiesQueryResult = Apollo.QueryResult<
  OnboardingSeedsActivitiesQuery,
  OnboardingSeedsActivitiesQueryVariables
>;
export const OnboardingSeedActivitiesDocument = gql`
  mutation OnboardingSeedActivities($names: [String!]!) {
    onboardingSeedActivities(names: $names) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type OnboardingSeedActivitiesMutationResult =
  Apollo.MutationResult<OnboardingSeedActivitiesMutation>;
export type OnboardingSeedActivitiesMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingSeedActivitiesMutation,
    OnboardingSeedActivitiesMutationVariables
  >;
export const OnboardingSeedsItemsDocument = gql`
  query OnboardingSeedsItems(
    $page: Int
    $pageSize: Int
    $sort: OnboardingSeedsItemsSortInput
    $filter: OnboardingSeedsItemsFilterInput
  ) {
    onboardingSeedsItems(
      page: $page
      pageSize: $pageSize
      sort: $sort
      filter: $filter
    ) {
      totalCount
      items {
        ...ItemSeed
      }
    }
  }
  ${ItemSeedFragmentDoc}
`;
export type OnboardingSeedsItemsQueryResult = Apollo.QueryResult<
  OnboardingSeedsItemsQuery,
  OnboardingSeedsItemsQueryVariables
>;
export const OnboardingSeedItemsDocument = gql`
  mutation OnboardingSeedItems($ids: [ID!]!) {
    onboardingSeedItems(ids: $ids) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type OnboardingSeedItemsMutationResult =
  Apollo.MutationResult<OnboardingSeedItemsMutation>;
export type OnboardingSeedItemsMutationOptions = Apollo.BaseMutationOptions<
  OnboardingSeedItemsMutation,
  OnboardingSeedItemsMutationVariables
>;
export const OnboardingSeedsSalaryStructuresDocument = gql`
  query OnboardingSeedsSalaryStructures(
    $inputVariables: PayrollInputVariables!
  ) {
    onboardingSeedsSalaryStructures {
      ...SalaryStructureSeed
    }
  }
  ${SalaryStructureSeedFragmentDoc}
`;
export type OnboardingSeedsSalaryStructuresQueryResult = Apollo.QueryResult<
  OnboardingSeedsSalaryStructuresQuery,
  OnboardingSeedsSalaryStructuresQueryVariables
>;
export const OnboardingSeedSalaryStructuresDocument = gql`
  mutation OnboardingSeedSalaryStructures($names: [String!]!) {
    onboardingSeedSalaryStructures(names: $names) {
      result
      errors {
        ...Error
      }
    }
  }
  ${ErrorFragmentDoc}
`;
export type OnboardingSeedSalaryStructuresMutationResult =
  Apollo.MutationResult<OnboardingSeedSalaryStructuresMutation>;
export type OnboardingSeedSalaryStructuresMutationOptions =
  Apollo.BaseMutationOptions<
    OnboardingSeedSalaryStructuresMutation,
    OnboardingSeedSalaryStructuresMutationVariables
  >;
export const ActivityOverviewReportDocument = gql`
  query ActivityOverviewReport(
    $filter: ActivityReportFilterInput
    $showCost: Boolean = false
  ) {
    activityOverviewReport(filter: $filter) {
      ...ActivityOverviewReport
    }
  }
  ${ActivityOverviewReportFragmentDoc}
`;
export type ActivityOverviewReportQueryResult = Apollo.QueryResult<
  ActivityOverviewReportQuery,
  ActivityOverviewReportQueryVariables
>;
export const ActivitySummaryReportDocument = gql`
  query ActivitySummaryReport(
    $page: Int
    $pageSize: Int
    $filter: ActivityReportFilterInput
    $sort: ActivitySummaryReportSortInput
    $showCost: Boolean = false
  ) {
    activitySummaryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...ActivitySummaryReport
      }
    }
  }
  ${ActivitySummaryReportFragmentDoc}
`;
export type ActivitySummaryReportQueryResult = Apollo.QueryResult<
  ActivitySummaryReportQuery,
  ActivitySummaryReportQueryVariables
>;
export const ActivityCostCenterReportDocument = gql`
  query ActivityCostCenterReport(
    $filter: ActivityReportFilterInput
    $sort: ActivityCostCenterReportSortInput
    $page: Int
    $pageSize: Int
    $showCost: Boolean = false
  ) {
    activityCostCenterReport(
      filter: $filter
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        ...ActivityCostCenterReport
      }
    }
  }
  ${ActivityCostCenterReportFragmentDoc}
`;
export type ActivityCostCenterReportQueryResult = Apollo.QueryResult<
  ActivityCostCenterReportQuery,
  ActivityCostCenterReportQueryVariables
>;
export const BalanceSheetReportDocument = gql`
  query BalanceSheetReport(
    $filter: BalanceSheetReportFilterInput
    $sort: BalanceSheetReportSortInput
  ) {
    balanceSheetReport(filter: $filter, sort: $sort) {
      totalCount
      items {
        ...BalanceSheetReport
      }
    }
  }
  ${BalanceSheetReportFragmentDoc}
`;
export type BalanceSheetReportQueryResult = Apollo.QueryResult<
  BalanceSheetReportQuery,
  BalanceSheetReportQueryVariables
>;
export const CropFieldSummaryReportDocument = gql`
  query CropFieldSummaryReport(
    $page: Int
    $pageSize: Int
    $filter: CropFieldReportFilterInput
    $sort: CropFieldSummaryReportSortInput
    $showCost: Boolean = false
  ) {
    cropFieldSummaryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...CropFieldSummaryReport
      }
    }
  }
  ${CropFieldSummaryReportFragmentDoc}
`;
export type CropFieldSummaryReportQueryResult = Apollo.QueryResult<
  CropFieldSummaryReportQuery,
  CropFieldSummaryReportQueryVariables
>;
export const CropFieldActivityReportDocument = gql`
  query CropFieldActivityReport(
    $page: Int
    $pageSize: Int
    $filter: CropFieldReportFilterInput
    $sort: CropFieldActivityReportSortInput
    $showCost: Boolean = false
  ) {
    cropFieldActivityReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...CropFieldActivityReport
      }
    }
  }
  ${CropFieldActivityReportFragmentDoc}
`;
export type CropFieldActivityReportQueryResult = Apollo.QueryResult<
  CropFieldActivityReportQuery,
  CropFieldActivityReportQueryVariables
>;
export const DashboardReportDocument = gql`
  query DashboardReport(
    $cropCycleId: ID
    $localityId: ID
    $currencyCode: String
    $showCost: Boolean = false
  ) {
    dashboardReport(
      cropCycleId: $cropCycleId
      localityId: $localityId
      currencyCode: $currencyCode
    ) {
      ...DashboardReport
    }
  }
  ${DashboardReportFragmentDoc}
`;
export type DashboardReportQueryResult = Apollo.QueryResult<
  DashboardReportQuery,
  DashboardReportQueryVariables
>;
export const ExpenseReportDocument = gql`
  query ExpenseReport($filter: ExpenseReportFilterInput) {
    expenseReport(filter: $filter) {
      totalCount
      items {
        ...ExpenseRecord
      }
    }
  }
  ${ExpenseRecordFragmentDoc}
`;
export type ExpenseReportQueryResult = Apollo.QueryResult<
  ExpenseReportQuery,
  ExpenseReportQueryVariables
>;
export const ExpenseSalaryReportDocument = gql`
  query ExpenseSalaryReport($filter: ExpenseSalaryReportFilterInput) {
    expenseSalaryReport(filter: $filter) {
      totalCount
      items {
        ...ExpenseRecord
      }
    }
  }
  ${ExpenseRecordFragmentDoc}
`;
export type ExpenseSalaryReportQueryResult = Apollo.QueryResult<
  ExpenseSalaryReportQuery,
  ExpenseSalaryReportQueryVariables
>;
export const ExpenseInventoryReportDocument = gql`
  query ExpenseInventoryReport($filter: ExpenseInventoryReportFilterInput) {
    expenseInventoryReport(filter: $filter) {
      totalCount
      items {
        ...ExpenseRecord
      }
    }
  }
  ${ExpenseRecordFragmentDoc}
`;
export type ExpenseInventoryReportQueryResult = Apollo.QueryResult<
  ExpenseInventoryReportQuery,
  ExpenseInventoryReportQueryVariables
>;
export const ExpenseServiceReportDocument = gql`
  query ExpenseServiceReport($filter: ExpenseServiceReportFilterInput) {
    expenseServiceReport(filter: $filter) {
      totalCount
      items {
        ...ExpenseRecord
      }
    }
  }
  ${ExpenseRecordFragmentDoc}
`;
export type ExpenseServiceReportQueryResult = Apollo.QueryResult<
  ExpenseServiceReportQuery,
  ExpenseServiceReportQueryVariables
>;
export const InvoiceSummaryReportDocument = gql`
  query InvoiceSummaryReport(
    $page: Int
    $pageSize: Int
    $filter: InvoiceReportFilterInput
    $sort: InvoiceSummaryReportSortInput
  ) {
    invoiceSummaryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...InvoiceSummaryReportItem
      }
    }
  }
  ${InvoiceSummaryReportItemFragmentDoc}
`;
export type InvoiceSummaryReportQueryResult = Apollo.QueryResult<
  InvoiceSummaryReportQuery,
  InvoiceSummaryReportQueryVariables
>;
export const InvoiceOverviewReportDocument = gql`
  query InvoiceOverviewReport($filter: InvoiceReportFilterInput) {
    invoiceOverviewReport(filter: $filter) {
      totalAmount
      totalAmountPaid
    }
  }
`;
export type InvoiceOverviewReportQueryResult = Apollo.QueryResult<
  InvoiceOverviewReportQuery,
  InvoiceOverviewReportQueryVariables
>;
export const GeneralLedgerReportDocument = gql`
  query GeneralLedgerReport(
    $page: Int
    $pageSize: Int
    $filter: GeneralLedgerReportFilterInput
    $sort: GeneralLedgerReportSortInput
  ) {
    generalLedgerReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...GeneralLedgerReport
      }
    }
  }
  ${GeneralLedgerReportFragmentDoc}
`;
export type GeneralLedgerReportQueryResult = Apollo.QueryResult<
  GeneralLedgerReportQuery,
  GeneralLedgerReportQueryVariables
>;
export const HarvestSummaryReportDocument = gql`
  query HarvestSummaryReport(
    $page: Int
    $pageSize: Int
    $filter: HarvestReportFilterInput
    $sort: HarvestSummaryReportSortInput
  ) {
    harvestSummaryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...HarvestSummary
      }
    }
  }
  ${HarvestSummaryFragmentDoc}
`;
export type HarvestSummaryReportQueryResult = Apollo.QueryResult<
  HarvestSummaryReportQuery,
  HarvestSummaryReportQueryVariables
>;
export const HarvestCropFieldReportDocument = gql`
  query HarvestCropFieldReport(
    $page: Int
    $pageSize: Int
    $filter: HarvestCropFieldReportFilterInput
    $sort: HarvestCropFieldReportSortInput
  ) {
    harvestCropFieldReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...HarvestCropField
      }
    }
  }
  ${HarvestCropFieldFragmentDoc}
`;
export type HarvestCropFieldReportQueryResult = Apollo.QueryResult<
  HarvestCropFieldReportQuery,
  HarvestCropFieldReportQueryVariables
>;
export const HarvestDailyReportDocument = gql`
  query HarvestDailyReport(
    $page: Int
    $pageSize: Int
    $filter: HarvestDailyReportFilterInput
    $sort: HarvestDailyReportSortInput
  ) {
    harvestDailyReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...HarvestDaily
      }
    }
  }
  ${HarvestDailyFragmentDoc}
`;
export type HarvestDailyReportQueryResult = Apollo.QueryResult<
  HarvestDailyReportQuery,
  HarvestDailyReportQueryVariables
>;
export const HarvestOverviewReportDocument = gql`
  query HarvestOverviewReport($filter: HarvestReportFilterInput) {
    harvestOverviewReport(filter: $filter) {
      ...HarvestOverview
    }
  }
  ${HarvestOverviewFragmentDoc}
`;
export type HarvestOverviewReportQueryResult = Apollo.QueryResult<
  HarvestOverviewReportQuery,
  HarvestOverviewReportQueryVariables
>;
export const IncomeStatementReportDocument = gql`
  query IncomeStatementReport($filter: IncomeStatementReportFilterInput) {
    incomeStatementReport(filter: $filter) {
      items {
        ...IncomeStatementRecord
      }
    }
  }
  ${IncomeStatementRecordFragmentDoc}
`;
export type IncomeStatementReportQueryResult = Apollo.QueryResult<
  IncomeStatementReportQuery,
  IncomeStatementReportQueryVariables
>;
export const InputCostCenterReportDocument = gql`
  query InputCostCenterReport(
    $page: Int
    $pageSize: Int
    $filter: InputCostCenterReportFilterInput
    $sort: InputCostCenterReportSortInput
    $showCost: Boolean = false
  ) {
    inputCostCenterReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...InputCostCenter
      }
    }
  }
  ${InputCostCenterFragmentDoc}
`;
export type InputCostCenterReportQueryResult = Apollo.QueryResult<
  InputCostCenterReportQuery,
  InputCostCenterReportQueryVariables
>;
export const InventoryEntryCodeTypeDocument = gql`
  query InventoryEntryCodeType {
    __type(name: "InventoryEntryCodeType") {
      name
      enumValues {
        name
        description
      }
    }
  }
`;
export type InventoryEntryCodeTypeQueryResult = Apollo.QueryResult<
  InventoryEntryCodeTypeQuery,
  InventoryEntryCodeTypeQueryVariables
>;
export const InventoryEntryReportDocument = gql`
  query InventoryEntryReport(
    $page: Int
    $pageSize: Int
    $filter: InventoryEntryReportFilterInput
    $sort: InventoryEntryReportSortInput
    $showCost: Boolean = false
  ) {
    inventoryEntryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...InventoryEntry
      }
    }
  }
  ${InventoryEntryFragmentDoc}
`;
export type InventoryEntryReportQueryResult = Apollo.QueryResult<
  InventoryEntryReportQuery,
  InventoryEntryReportQueryVariables
>;
export const InventoryMovementReportDocument = gql`
  query InventoryMovementReport(
    $page: Int
    $pageSize: Int
    $filter: InventoryMovementReportFilterInput
    $sort: InventoryMovementReportSortInput
    $showCost: Boolean = false
  ) {
    inventoryMovementReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...InventoryMovement
      }
    }
  }
  ${InventoryMovementFragmentDoc}
`;
export type InventoryMovementReportQueryResult = Apollo.QueryResult<
  InventoryMovementReportQuery,
  InventoryMovementReportQueryVariables
>;
export const JournalReportDocument = gql`
  query JournalReport(
    $page: Int
    $pageSize: Int
    $filter: JournalReportFilterInput
    $sort: JournalReportSortInput
  ) {
    journalReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...JournalReport
      }
    }
  }
  ${JournalReportFragmentDoc}
`;
export type JournalReportQueryResult = Apollo.QueryResult<
  JournalReportQuery,
  JournalReportQueryVariables
>;
export const LaborSummaryReportDocument = gql`
  query LaborSummaryReport(
    $page: Int
    $pageSize: Int
    $filter: LaborReportFilterInput
    $sort: LaborSummaryReportSortInput
    $showCost: Boolean = false
  ) {
    laborSummaryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...LaborSummaryReport
      }
    }
  }
  ${LaborSummaryReportFragmentDoc}
`;
export type LaborSummaryReportQueryResult = Apollo.QueryResult<
  LaborSummaryReportQuery,
  LaborSummaryReportQueryVariables
>;
export const LaborActivityReportDocument = gql`
  query LaborActivityReport(
    $page: Int
    $pageSize: Int
    $filter: LaborReportFilterInput
    $sort: LaborActivityReportSortInput
    $showCost: Boolean = false
  ) {
    laborActivityReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...LaborActivityReport
      }
    }
  }
  ${LaborActivityReportFragmentDoc}
`;
export type LaborActivityReportQueryResult = Apollo.QueryResult<
  LaborActivityReportQuery,
  LaborActivityReportQueryVariables
>;
export const LedgerReportDocument = gql`
  query LedgerReport(
    $page: Int
    $pageSize: Int
    $filter: LedgerReportFilterInput
    $sort: LedgerReportSortInput
  ) {
    ledgerReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...LedgerEntryReport
      }
    }
  }
  ${LedgerEntryReportFragmentDoc}
`;
export type LedgerReportQueryResult = Apollo.QueryResult<
  LedgerReportQuery,
  LedgerReportQueryVariables
>;
export const MachineSummaryReportDocument = gql`
  query MachineSummaryReport(
    $page: Int
    $pageSize: Int
    $filter: MachineReportFilterInput
    $sort: MachineSummaryReportSortInput
  ) {
    machineSummaryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...MachineSummaryReport
      }
    }
  }
  ${MachineSummaryReportFragmentDoc}
`;
export type MachineSummaryReportQueryResult = Apollo.QueryResult<
  MachineSummaryReportQuery,
  MachineSummaryReportQueryVariables
>;
export const MachineActivityReportDocument = gql`
  query MachineActivityReport(
    $page: Int
    $pageSize: Int
    $filter: MachineReportFilterInput
    $sort: MachineActivityReportSortInput
  ) {
    machineActivityReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...MachineActivityReport
      }
    }
  }
  ${MachineActivityReportFragmentDoc}
`;
export type MachineActivityReportQueryResult = Apollo.QueryResult<
  MachineActivityReportQuery,
  MachineActivityReportQueryVariables
>;
export const PrePayrollOverviewReportDocument = gql`
  query PrePayrollOverviewReport(
    $filter: PrePayrollReportFilterInput
    $showWage: Boolean = false
  ) {
    prePayrollOverviewReport(filter: $filter) {
      ...PrePayrollOverviewRecord
    }
  }
  ${PrePayrollOverviewRecordFragmentDoc}
`;
export type PrePayrollOverviewReportQueryResult = Apollo.QueryResult<
  PrePayrollOverviewReportQuery,
  PrePayrollOverviewReportQueryVariables
>;
export const PrePayrollSummaryReportDocument = gql`
  query PrePayrollSummaryReport(
    $page: Int
    $pageSize: Int
    $filter: PrePayrollReportFilterInput
    $sort: PrePayrollSummaryReportSortInput
    $showWage: Boolean = false
  ) {
    prePayrollSummaryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...PrePayrollSummaryRecord
      }
    }
  }
  ${PrePayrollSummaryRecordFragmentDoc}
`;
export type PrePayrollSummaryReportQueryResult = Apollo.QueryResult<
  PrePayrollSummaryReportQuery,
  PrePayrollSummaryReportQueryVariables
>;
export const PrePayrollReportDocument = gql`
  query PrePayrollReport(
    $page: Int
    $pageSize: Int
    $filter: PrePayrollReportFilterInput
    $sort: PrePayrollReportSortInput
    $showWage: Boolean = false
  ) {
    prePayrollReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...PrePayrollRecord
      }
    }
  }
  ${PrePayrollRecordFragmentDoc}
`;
export type PrePayrollReportQueryResult = Apollo.QueryResult<
  PrePayrollReportQuery,
  PrePayrollReportQueryVariables
>;
export const StockReportDocument = gql`
  query StockReport(
    $page: Int
    $pageSize: Int
    $filter: StockReportFilterInput
    $sort: StockReportSortInput
    $showCost: Boolean = false
  ) {
    stockReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...StockReportRecord
      }
    }
  }
  ${StockReportRecordFragmentDoc}
`;
export type StockReportQueryResult = Apollo.QueryResult<
  StockReportQuery,
  StockReportQueryVariables
>;
export const TokenSummaryReportDocument = gql`
  query TokenSummaryReport(
    $page: Int
    $pageSize: Int
    $filter: TokenSummaryReportFilterInput
    $sort: TokenSummaryReportSortInput
    $showCost: Boolean = false
  ) {
    tokenSummaryReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...TokenSummary
      }
    }
  }
  ${TokenSummaryFragmentDoc}
`;
export type TokenSummaryReportQueryResult = Apollo.QueryResult<
  TokenSummaryReportQuery,
  TokenSummaryReportQueryVariables
>;
export const TokenPaidReportDocument = gql`
  query TokenPaidReport(
    $page: Int
    $pageSize: Int
    $filter: TokenPaidReportFilterInput
    $sort: TokenPaidReportSortInput
    $showCost: Boolean = false
  ) {
    tokenPaidReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...TokenPaid
      }
    }
  }
  ${TokenPaidFragmentDoc}
`;
export type TokenPaidReportQueryResult = Apollo.QueryResult<
  TokenPaidReportQuery,
  TokenPaidReportQueryVariables
>;
export const TokenRedeemedReportDocument = gql`
  query TokenRedeemedReport(
    $page: Int
    $pageSize: Int
    $filter: TokenRedeemedReportFilterInput
    $sort: TokenRedeemedReportSortInput
  ) {
    tokenRedeemedReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...TokenRedeemed
      }
    }
  }
  ${TokenRedeemedFragmentDoc}
`;
export type TokenRedeemedReportQueryResult = Apollo.QueryResult<
  TokenRedeemedReportQuery,
  TokenRedeemedReportQueryVariables
>;
export const TrialBalanceReportDocument = gql`
  query TrialBalanceReport(
    $page: Int
    $pageSize: Int
    $filter: TrialBalanceReportFilterInput
    $sort: TrialBalanceReportSortInput
  ) {
    trialBalanceReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...TrialBalanceReport
      }
    }
  }
  ${TrialBalanceReportFragmentDoc}
`;
export type TrialBalanceReportQueryResult = Apollo.QueryResult<
  TrialBalanceReportQuery,
  TrialBalanceReportQueryVariables
>;
export const WorkOrderDailyReportDocument = gql`
  query WorkOrderDailyReport(
    $page: Int
    $pageSize: Int
    $filter: WorkOrderDailyReportFilterInput
    $sort: WorkOrderDailyReportSortInput
    $showCost: Boolean = false
  ) {
    workOrderDailyReport(
      page: $page
      pageSize: $pageSize
      filter: $filter
      sort: $sort
    ) {
      totalCount
      items {
        ...WorkOrderDaily
      }
    }
  }
  ${WorkOrderDailyFragmentDoc}
`;
export type WorkOrderDailyReportQueryResult = Apollo.QueryResult<
  WorkOrderDailyReportQuery,
  WorkOrderDailyReportQueryVariables
>;
